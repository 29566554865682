import React from 'react';

import cx from '../../services/helpers/classNames';

import './progressCircle.scss';

type ProgressCircleProps = {
  radius?: number,
  stroke?: number,
  percent: number,
  color?: string,
  className?: string
}

export default function ProgressRing({
  percent,
  radius = 20,
  stroke = 1,
  color = '#21201F',
  className = '',
}: ProgressCircleProps) {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - percent / 100 * circumference;

  const classes = cx("progress-ring", className);
  return (
    <svg
      className={classes}
      height={radius * 2}
      width={radius * 2}
      >
      <circle
        className="progress-ring__circle"
        stroke={color}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        />
    </svg>
  );
}
