import { SearchRequest } from "~/api-types/backend-py3/services/supportai-history/docs/yaml/definitions";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";
import { DialogsHistorySearchFilter, DialogStatus } from "../models/Dialog";

export function parseFiltersToBackend(filter: DialogsHistorySearchFilter): SearchRequest {
  return {
    ...(filter.dateFrom && { start_dt: dateLocalToMsc(new Date(filter.dateFrom)) }),
    ...(filter.dateTo && { end_dt: dateLocalToMsc(new Date(filter.dateTo)) }),
    ...(typeof filter.mode === 'boolean' && { simulated: filter.mode }),
    ...(filter.chatId && { chat_ids: [filter.chatId] }),
    ...(filter.chatIds && { chat_ids: filter.chatIds }),
    ...(filter.chatUser && { chat_user: filter.chatUser }),
    ...(filter.version && { version: filter.version }),
    ...(filter.tags?.length && { tags: filter.tags }),
    ...(filter.searchText && { search_text: filter.searchText }),
    ...(Array.isArray(filter.scenarios) && filter.scenarios.length > 0 && {
      scenarios: filter.scenarios.map(s => typeof s === "string" ? s : s.slug),
    }),
    // @ts-ignore
    ...(filter.withErrors && { with_errors: filter.withErrors }),
    // @ts-ignore
    ...(filter.isHidden && { is_hidden: filter.isHidden }),
    // @ts-ignore
    ...(filter.replied && { replied: filter.replied }),
    ...parseDialogStatusParams(filter.status),
    ...(filter.minUserScore && { min_csat: filter.minUserScore }),
    ...(filter.maxUserScore && { max_csat: filter.maxUserScore }),
    ...(filter.iterations && { min_iteration: Number(filter.iterations), max_iteration: Number(filter.iterations) }),
  };
}

export function parseDialogStatusParams(status: DialogStatus | null) {
  switch (status) {
    case DialogStatus.notAnswered:
      return {
        is_hidden: false,
        replied: false,
      };
    case DialogStatus.withErrors:
      return {
        is_hidden: false,
        with_errors: true,
      };
    default:
      return {};
  }
}
