import { differenceInDays } from "date-fns";

import { Project } from "../../types/models/Project";
import { Version } from "../../types/models/Version";
import { GetScenarioGraphsResponse, GetScenarioGraphsRequest } from "../../types/requests/ScenarioGraphs";

import apiService, { ApiResponse } from "../../services/api";
import { ScenarioGraphMeta } from "../../types/models/ScenarioGraphs";
import { ScenarioGraphMetaBackend } from "../../types/backendModels/ScenarioGraphsBackend";
import { DRAFT_VERSION } from "../../redux/versions/reducer";

const SCENARIOS_TO_VERSIONS_KEY = "scenarios_to_versions_map";

type ScenariosToVersionsMap = Record<
  Project['slug'],
  Record<
    Version['id'],
    {
      scenarios: Array<ScenarioGraphMeta['slug']>,
      lastCheck: string,
    }
  >
>;

export async function isScenarioExistInVersion(
  projectSlug: Project['slug'],
  versionId: Version['id'],
  scenarioSlug: ScenarioGraphMeta['slug'],
): Promise<boolean> {
  const cached = isScenarioExistInVersionCached(projectSlug, scenarioSlug, versionId);
  if (cached !== null) {
    refreshVersionExpireTimer(
      projectSlug,
      versionId,
    );

    return cached;
  }

  const versionScenarios = await getVersionScenarios(versionId);
  const versionScenariosSlugs = versionScenarios
    .map(s => s.slug)
    .filter(s => !!s) as string[];

  if (versionScenarios.length > 0 && versionId) {
    cacheScenariosExistence(
      projectSlug,
      versionId,
      versionScenariosSlugs,
    );
  }

  return versionScenariosSlugs.includes(scenarioSlug);
}

// Вернёт boolean или null
// boolean - значит фронт уверен, что такого сценария не сущещствует (раньше отправлялся запрос)
// true - да, сценарий существует в этой версии, false - нет, сценарий не существует в этой версии
// null - если фронт не знает, нужно спрашивать через сервер
function isScenarioExistInVersionCached(
  projectSlug: Project['slug'],
  scenarioSlug: ScenarioGraphMeta['slug'],
  versionId: Version['id'],
): boolean | null {
  const items: ScenariosToVersionsMap = JSON.parse(localStorage.getItem(SCENARIOS_TO_VERSIONS_KEY) || "{}");

  if (versionId === DRAFT_VERSION.id) return null;
  if (!items) return null;
  if (!items[projectSlug]) return null;
  if (!items[projectSlug][versionId]) return null;
  if (!Array.isArray(items[projectSlug][versionId].scenarios)) return null;

  return items[projectSlug][versionId].scenarios.includes(scenarioSlug);
}

export async function getVersionScenarios(
  versionId: Version['id'],
): Promise<Array<ScenarioGraphMetaBackend>> {
  const request: GetScenarioGraphsRequest = {
    params: {
      version: versionId || undefined,
    },
  };

  try {
    const response: ApiResponse<GetScenarioGraphsResponse> = await apiService.getScenarioGraphs(request);
    if (response.status === 200) {
      const versionScenarios = response.data.scenarios;

      return versionScenarios;
    }

    return [];
  } catch {
    return [];
  }
}

function cacheScenariosExistence(
  projectSlug: Project['slug'],
  versionId: Version['id'],
  scenarioSlugs: Array<ScenarioGraphMeta['slug']>,
) {
  let items: ScenariosToVersionsMap = JSON.parse(localStorage.getItem(SCENARIOS_TO_VERSIONS_KEY) || "{}");

  if (!items) items = {};
  if (!items[projectSlug]) items[projectSlug] = {};
  // @ts-ignore
  if (!items[projectSlug][versionId]) items[projectSlug][versionId] = {};

  items[projectSlug][versionId].lastCheck = new Date().toString();
  items[projectSlug][versionId].scenarios = scenarioSlugs;

  localStorage.setItem(SCENARIOS_TO_VERSIONS_KEY, JSON.stringify(items));

  return;
}

function refreshVersionExpireTimer(
  projectSlug: Project['slug'],
  versionId: Version['id'],
) {
  let items: ScenariosToVersionsMap = JSON.parse(localStorage.getItem(SCENARIOS_TO_VERSIONS_KEY) || "{}");

  if (!items) return;
  if (!items[projectSlug]) return;
  if (!items[projectSlug][versionId]) return;

  items[projectSlug][versionId].lastCheck = new Date().toString();

  localStorage.setItem(SCENARIOS_TO_VERSIONS_KEY, JSON.stringify(items));
}

function removeExpiredVersions() {
  let items: ScenariosToVersionsMap = JSON.parse(localStorage.getItem(SCENARIOS_TO_VERSIONS_KEY) || "{}");

  if (!items) return;

  const expiredVersions: Record<
    Project['slug'],
    Array<Version['id']>
  > = {};
  Object.keys(items).forEach(projectSlug => {
    Object.keys(items[projectSlug]).forEach(versionId => {
      const dayDiff = Math.abs(differenceInDays(new Date(), new Date(items[projectSlug][versionId].lastCheck)));

      if (dayDiff > 7) {
        if (!expiredVersions[projectSlug]) expiredVersions[projectSlug] = [];
        expiredVersions[projectSlug].push(versionId);
      }
    });
  });

  const needToResave = Object.keys(expiredVersions).length > 0;

  if (needToResave) {
    Object.keys(expiredVersions).forEach(project => {
      expiredVersions[project].forEach(version => {
        delete items[project][version];
      });
    });

    localStorage.setItem(SCENARIOS_TO_VERSIONS_KEY, JSON.stringify(items));
  }
}

removeExpiredVersions();
