import { generateId } from "../../services/helpers/generateId";
import { getTranslationParams, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import i18n from "../../services/i18n";
import { GraphDebugBlockBackend } from "../backendModels/GraphDebugBlockBackend";
import { DebugKeyValue, GraphDebugBlock } from "../models/GraphDebugBlock";

export function parseGraphDebugBlock({
  template,
  template_params: templateParams,
  ...rest
}: GraphDebugBlockBackend): GraphDebugBlock {
  const translationKey = `GRAPH.DEBUG.TEMPLATES.${template}`;
  const translationExist = i18n.exists(translationKey);
  const descriptions: string[] = [];

  if (translationExist) {
    const translation = i18n.t(`GRAPH.DEBUG.TEMPLATES.${template}`);
    const translationParams = getTranslationParams(translation);
    const templateParamsKeys = templateParams?.map(p => p.key);
    const allParamsExist = (!translationParams && !templateParamsKeys?.length) ||
      translationParams?.every(p => templateParamsKeys?.includes(p));

    if (allParamsExist) {
      const mappedTemplateParams = templateParams?.reduce<Record<string, string>>((res, param) => {
        res[param.key] = parseTemplateParamToString(param.value);
        return res;
      }, {});
      descriptions.push(i18n.t(translationKey, mappedTemplateParams).replace(/&quot;/g, '"'));
    }
  }

  return {
    ...objSnakeToCamelDeep(rest),
    id: generateId(),
    template,
    templateParams,
    descriptions,
    nodeId: rest.node_id ?? "entry",
  };
}

function parseTemplateParamToString(value: DebugKeyValue['value']): string {
  if (Array.isArray(value)) {
    return value.join(", ");
  }

  if (value === null) {
    return "null";
  }

  return value.toString();
}
