import { GetScenarioUsageResponse } from "../../types/requests/ScenarioUsage";

export const usageMock: GetScenarioUsageResponse = {
  select_scenario_nodes: [
    {
      id: "1",
      scenario_id: "2724",
      node_id: "1",
      title: "Mocked title 1",
      is_ref_scenario_included: false,
    },
    {
      id: "2",
      scenario_id: "2724",
      node_id: "2",
      title: "Mocked title 2",
      is_ref_scenario_included: false,
    },
    {
      id: "3",
      scenario_id: "2724",
      node_id: "3",
      title: "Mocked title 3",
      is_ref_scenario_included: true,
    },
    {
      id: "4",
      scenario_id: "2724",
      node_id: "4",
      title: "Mocked title 4",
      is_ref_scenario_included: false,
    },
    {
      id: "5",
      scenario_id: "2724",
      node_id: "5",
      title: "Mocked title 5",
      is_ref_scenario_included: true,
    },
    {
      id: "6",
      scenario_id: "2724",
      node_id: "6",
      title: "Mocked title 6",
      is_ref_scenario_included: false,
    },
  ],
};
