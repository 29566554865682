import { getHours, getMinutes, setHours, setMinutes } from "date-fns";

export function parseDateToTimeValue(date: Date | null): string {
  if (date === null) return "";

  let hours: string = String(getHours(date));
  hours = hours.length === 1 ? `0${hours}` : hours;

  let minutes = String(getMinutes(date));
  minutes = minutes.length === 1 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
}

export function parseTimeValueToDate(
  originalDate: Date,
  value: string,
): Date | null {
  if (!value.trim()) return null;

  const [hours, minutes] = value.split(':');
  const hoursValid = !isNaN(Number(hours)) && Number(hours) >= 0 && Number(hours) <= 23;
  const minutesValid = !isNaN(Number(minutes)) && Number(minutes) >= 0 && Number(minutes) <= 59;

  if (!hoursValid || !minutesValid) return originalDate;

  let newDate = new Date(originalDate);

  newDate = setHours(newDate, Number(hours));
  newDate = setMinutes(newDate, Number(minutes));

  return newDate;
}
