import { Feature } from "../../types/models/Feature";
import { getEmptyIntegration, Integration, IntegrationId, IntegrationLabel } from "../../types/models/Integrations";
import { AppState } from "../reducers";
import { NEW_INTEGRATION_ID } from "./reducer";

export function getIntegrations(state: AppState) {
  const validIntegrations = state.integrations.integrations.list.filter(i => !i.isTemp);

  return {
    ...state.integrations.integrations,
    list: validIntegrations,
  };
}

export function getIntegrationsByIdList(
  state: AppState,
  idList: Array<Integration['id']>,
): Array<Integration> {
  const integrations = getIntegrations(state);

  return integrations.list.filter(integration => idList.includes(integration.id));
}

export function getActiveIntegration(state: AppState) {
  const activeIntegrationId = getActiveIntegrationId(state);

  return state.integrations.integrations.list
    .find(integration => integration.id === activeIntegrationId);
}

export function getActiveIntegrationId(state: AppState) {
  return state.integrations.activeIntegration.id;
}

export function getIntegrationById(state: AppState, id: IntegrationId) {
  if (id === NEW_INTEGRATION_ID) {
    return getEmptyIntegration();
  }

  return state.integrations.integrations.list.find(integration => integration.id === id);
}

export function getPanelOpenStatus(state: AppState): boolean {
  return state.integrations.isPanelOpened;
}

export function getActiveIntegrations(state: AppState): Integration[] {
  return state.integrations.integrations.list
    .filter(i => !i.isTemp)
    .filter(i => i.isActive);
}

export function getIntegrationsLoadingStatus(state: AppState): boolean {
  return state.integrations.integrations.loading;
}

export function getIntegrationTestingModalOpened(state: AppState): boolean {
  return state.integrations.testing.modalOpened;
}

export function getIntegrationInputFeatures(state: AppState): Array<Feature['slug']> {
  return state.integrations.testing.features;
}

export function getIntegrationTestingResults(state: AppState) {
  return {
    current: state.integrations.testing.state,
    prev: state.integrations.testing.prevState,
  };
}

export function getIntegrationToTest(state: AppState) {
  return state.integrations.integrations.list.find(i => (
    state.integrations.integrationToTest.id === i.id
  ));
}

export function getIntegrationToTestId(state: AppState) {
  return state.integrations.integrationToTest.id;
}

export function getIntegrationTestingStatus(state: AppState) {
  return state.integrations.testing.testProcessing;
}

export function getIntegrationsLabels(state: AppState) {
  return state.integrations.labels;
}

export function getIntegrationLabelById(state: AppState, id: IntegrationLabel['id']) {
  return state.integrations.labels.list.find(l => l.id === id);
}

export function getLabelIntegrations(state: AppState, id: Integration['id']) {
  const integrations = getIntegrations(state);

  return integrations.list
    .filter(integration => integration.labels.some(label => label.id === id));
}

export function isAddIntegrationsToLabelModalOpened(state: AppState) {
  return state.integrations.addIntegrationsModal.opened;
}

export function getDefaultIntegrationsAddToLabel(state: AppState) {
  return state.integrations.addIntegrationsModal.initIntegrations || [];
}

export function isEditIntegrationLabelModalOpened(state: AppState) {
  return state.integrations.editLabelModal.opened;
}

export function getIntegrationLabelToEdit(state: AppState) {
  return state.integrations.editLabelModal.id;
}

export function isDeleteIntegrationLabelModalOpened(state: AppState) {
  return state.integrations.deleteLabelModal.opened;
}

export function getIntegrationLabelToDelete(state: AppState) {
  return state.integrations.deleteLabelModal.id;
}

export function getLabelsUpdatingStatus(state: AppState) {
  return state.integrations.labels.updating;
}

export function getUsageModalOpenStatus(state: AppState) {
  return state.integrations.usageModal.opened;
}

export function getUsageIntegration(state: AppState) {
  const id = state.integrations.usageModal.id;

  return getIntegrationById(state, id || "");
}

export function getUsageLoadingStatus(state: AppState) {
  return state.integrations.usageModal.loading;
}

export function getUsageIntegrationInfo(state: AppState) {
  return state.integrations.usageModal.usageInfo;
}
