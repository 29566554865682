import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { block } from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AuthProviderMeta, AuthProviderType } from '../../types/models/AuthProvider';

import { copyToClipboard } from '../../services/helpers/utilities';
import { ModalProps } from '../../services/modal';

import { createUserByProvider, CreateUserByProviderParams } from '../../redux/accesses/actions';
import { getAllAuthProviders } from '../../redux/authProviders/selectors';

import { SelectConfig } from '../../components-new/select/configurable';
import Select from '../../components-new/select/configurable';
import Input from '../../components-new/input';
import Button from '../../components-new/button';

import Modal from '../components/ndModal';

export type UserEditorProps = {
  defaultUsername: string;
};

const b = block('accesses-page-new-user-editor');

const DEFAULT_PROVIDER = AuthProviderType.PASSWORD;

const UserEditor: FC<UserEditorProps & ModalProps> = ({ defaultUsername, close }) => {
  const { t } = useTranslation();

  const [provider, setProvider] = useState<AuthProviderMeta | undefined>(undefined);
  const [username, setUsername] = useState(defaultUsername);
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const providers = useSelector(getAllAuthProviders, shallowEqual);

  const filteredProviders = useMemo(
    () => providers.filter(({ manualSignup }) => !!manualSignup),
    [providers],
  );

  const handleSelectProvider = useCallback(
    (value: AuthProviderMeta['type']) => {
      const nextProvider = providers.find(({ type }) => type === value);
      setProvider(nextProvider);
    },
    [providers],
  );

  const handleInput = useCallback(
    (_, { name, value }) => {
      if (name === "password") {
        setPassword(value);
      } else {
        setUsername(value);

        if (provider && provider.type !== DEFAULT_PROVIDER) {
          setPassword('');
        }
      }
    },
    [provider],
  );

  const providersConfig: SelectConfig = useMemo(
    () => ({
      options: filteredProviders,
      labelFunction: ({ title }: AuthProviderMeta) => title,
      valueFunction: ({ type }: AuthProviderMeta) => type,
    }),
    [filteredProviders],
  );

  const handleCreate = useCallback(
    () => {
      if (!(username && provider)) return;

      const params: CreateUserByProviderParams = { username, provider };

      if (provider.type === AuthProviderType.PASSWORD) {
        copyToClipboard(`${username} ${password}`);
        params.password = password;
      }

      dispatch(createUserByProvider.request(params));
      close();
    },
    [dispatch, close, password, provider, username],
  );

  const handleGeneratePassword = useCallback(
    () => {
      setPassword(Math.random().toString(36).slice(-8));
    },
    [],
  );

  useEffect(
    () => {
      if (defaultUsername === '' || !filteredProviders.length) return;

      let nextProvider: AuthProviderMeta | undefined;

      if (/^.*@yandex\.ru$/gi.test(defaultUsername)) {
        nextProvider = filteredProviders.find(({ type }) => type === AuthProviderType.YANDEX);
      } else if (/^.*@yandex-team\.ru$/gi.test(defaultUsername)) {
        nextProvider = filteredProviders.find(({ type }) => type === AuthProviderType.YANDEX_TEAM);
      } else {
        nextProvider = filteredProviders.find(({ type }) => type === AuthProviderType.PASSWORD);
      }

      if (!nextProvider) return;
      setProvider(nextProvider);
    },
    [defaultUsername, filteredProviders],
  );

  return (
    <Modal
      title={t('PAGE_ACCESSES.USER_EDITOR.CREATE_USER_TITLE')}
      className={b()}
      confirmDisabled={!(username && provider)}
      confirmationText={t('COMMON.CREATE')}
      onConfirm={handleCreate}
      close={close}
    >
      <div className="column-2">
        <Select
          size="small"
          value={provider?.type || ''}
          config={providersConfig}
          placeholder="Provider"
          onChange={handleSelectProvider}
        />
        <Input
          name="username"
          placeholder="Username"
          floatingPlaceholder
          value={username}
          onChange={handleInput}
        />
        {provider?.type === DEFAULT_PROVIDER && (
          <>
            <div className="row-2">
              <Input
                name="password"
                placeholder={t('COMMON.PASSWORD')}
                type="password"
                value={password}
                fluid
                floatingPlaceholder
                onChange={handleInput}
              />
              <Button
                onClick={handleGeneratePassword}
              >
                {t('PAGE_ACCESSES.USER_EDITOR.GENERATE')}
              </Button>
            </div>
            <div className={b('message')}>
              {t('PAGE_ACCESSES.USER_EDITOR.USER_DATA_WILL_BE_COPIED')}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default memo(UserEditor);
