import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { NewSampling, Sampling } from '../../types/models/Sampling';
import * as actions from './actions';
import { Dialog } from '../../types/models/Dialog';
import { ResolutionType } from '../../types/models/Resolution';
import { SamplingStats, StatsByDayPlotItem, isPositiveResolution } from '../../types/models/QualityStats';
import { getDayMarked, getDayTotalMarked } from './helpers';
import { deepClone } from '../../services/helpers/utilities';

export type ActiveDialog = {
  id: Dialog['id'],
  originalIndex: number,
}

type SamplingsState = {
  list: {
    value: Sampling[];
    loading: boolean;
    creating: boolean,
    deleting: boolean;
  },
  newList: {
    value: NewSampling[];
    loading: boolean;
    creating: boolean,
    deleting: boolean;
  },
  markingModal: {
    opened: boolean;
    samplingId: NewSampling['id'];
    activeDialog: ActiveDialog,
    dialogs: Dialog[],
    loading: boolean;
    processing: boolean;
    remarking: boolean;
  },
  createAutomaticSamplingModal: {
    opened: boolean;
    creating: boolean;
    success: boolean;
  },
  resolutions: {
    list: ResolutionType[];
    loading: boolean;
  },
  tableStats: {
    loading: boolean;
    statistics: SamplingStats[];
    total: undefined | number;
  },
  chartStats: {
    loading: boolean;
    plotItems: StatsByDayPlotItem[];
    markedCount: number;
    okCount: number;
  }
}

const initialState: SamplingsState = {
  list: {
    value: [],
    loading: false,
    creating: false,
    deleting: false,
  },
  newList: {
    value: [],
    loading: false,
    creating: false,
    deleting: false,
  },
  markingModal: {
    opened: false,
    samplingId: '',
    activeDialog: {
      id: "",
      originalIndex: -1,
    },
    dialogs: [],
    loading: false,
    processing: false,
    remarking: false,
  },
  createAutomaticSamplingModal: {
    opened: false,
    creating: false,
    success: false,
  },
  resolutions: {
    list: [],
    loading: false,
  },
  tableStats: {
    loading: false,
    statistics: [],
    total: undefined,
  },
  chartStats: {
    loading: false,
    plotItems: [],
    markedCount: 0,
    okCount: 0,
  },
};

export const samplingsReducer:Reducer<SamplingsState> = createReducer(initialState)
  .handleAction(
    actions.loadSamplings.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        value: [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadSamplings.success,
    (state: SamplingsState, { payload }: ActionType<typeof actions.loadSamplings.success>): SamplingsState => {
      return {
        ...state,
        list: {
          ...state.list,
          value: payload,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadSamplings.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadNewSamplings.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        value: [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadNewSamplings.success,
    (state: SamplingsState, { payload }: ActionType<typeof actions.loadNewSamplings.success>): SamplingsState => {
      return {
        ...state,
        newList: {
          ...state.newList,
          value: payload,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadNewSamplings.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createSampling.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        creating: true,
      },
    }),
  )
  .handleAction(
    actions.createSampling.success,
    (state: SamplingsState, { payload }: ActionType<typeof actions.createSampling.success>): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        value: [payload, ...state.list.value],
        creating: false,
      },
    }),
  )
  .handleAction(
    actions.createSampling.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        creating: false,
      },
    }),
  )
  .handleAction(
    actions.createNewSampling.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        creating: true,
      },
    }),
  )
  .handleAction(
    actions.createNewSampling.success,
    (state: SamplingsState, { payload }: ActionType<typeof actions.createNewSampling.success>): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        value: [payload, ...state.newList.value],
        creating: false,
      },
    }),
  )
  .handleAction(
    actions.createNewSampling.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        creating: false,
      },
    }),
  )
  .handleAction(
    actions.deleteSampling.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        deleting: true,
      },
    }),
  )
  .handleAction(
    actions.deleteSampling.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.deleteSampling.success>,
    ): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        value: state.list.value.filter(s => s.slug !== payload),
        deleting: false,
      },
    }),
  )
  .handleAction(
    actions.deleteSampling.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      list: {
        ...state.list,
        deleting: false,
      },
    }),
  )
  .handleAction(
    actions.deleteNewSampling.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        deleting: true,
      },
    }),
  )
  .handleAction(
    actions.deleteNewSampling.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.deleteNewSampling.success>,
    ): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        value: state.newList.value.filter(s => s.id !== payload),
        deleting: false,
      },
      tableStats: {
        ...state.tableStats,
        statistics: state.tableStats.statistics.filter(s => s.samplingId !== payload),
      },
    }),
  )
  .handleAction(
    actions.deleteNewSampling.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        deleting: false,
      },
    }),
  )
  .handleAction(
    actions.markDialog.request,
    (
      state: SamplingsState,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        processing: true,
      },
    }),
  )
  .handleAction(
    actions.markDialog.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.markDialog.success>,
    ): SamplingsState => {
      const activeDialog = state.markingModal.dialogs.find(d => d.id === state.markingModal.activeDialog.id);
      const oldResolution = activeDialog?.resolution?.value;

      let tableStatistics = deepClone(state.tableStats.statistics);

      if (oldResolution) {
        tableStatistics = decrementResolutionCounter(
          tableStatistics,
          state.markingModal.samplingId,
          oldResolution,
        );
      }

      tableStatistics = incrementResolutionCounter(
        tableStatistics,
        state.markingModal.samplingId,
        payload.resolution,
      );

      return {
        ...state,
        markingModal: {
          ...state.markingModal,
          processing: false,
          dialogs: state.markingModal.dialogs.map<Dialog>((dialog: Dialog) => {
            if (dialog.id !== state.markingModal.activeDialog.id) return dialog;
            return ({
              ...dialog,
              resolution: {
                value: payload.resolution,
                messageId: payload.badMessageId,
              },
            });
          }),
        },
        tableStats: {
          ...state.tableStats,
          statistics: tableStatistics,
        },
      };
    },
  )
  .handleAction(
    actions.markDialog.failure,
    (
      state: SamplingsState,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        processing: false,
      },
    }),
  )
  .handleAction(
    actions.closeMarkingModal,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...initialState.markingModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.startRemarkingDialog,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        remarking: true,
      },
    }),
  )
  .handleAction(
    actions.endRemarkingDialog,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        remarking: false,
      },
    }),
  )
  .handleAction(
    actions.openCreatingAutomaticSamplingModal,
    (
      state: SamplingsState,
    ): SamplingsState => ({
      ...state,
      createAutomaticSamplingModal: {
        ...state.createAutomaticSamplingModal,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeCreatingAutomaticSamplingModal,
    (
      state: SamplingsState,
    ): SamplingsState => ({
      ...state,
      createAutomaticSamplingModal: {
        ...initialState.createAutomaticSamplingModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.createAutomaticSampling.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      createAutomaticSamplingModal: {
        ...state.createAutomaticSamplingModal,
        creating: true,
      },
    }),
  )
  .handleAction(
    actions.createAutomaticSampling.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.createAutomaticSampling.success>,
    ): SamplingsState => ({
      ...state,
      newList: {
        ...state.newList,
        value: [
          ...state.newList.value,
          payload,
        ],
      },
      createAutomaticSamplingModal: {
        ...state.createAutomaticSamplingModal,
        creating: false,
        success: true,
      },
    }),
  )
  .handleAction(
    actions.createAutomaticSampling.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      createAutomaticSamplingModal: {
        ...state.createAutomaticSamplingModal,
        creating: false,
        success: false,
      },
    }),
  )
  .handleAction(
    actions.openMarkingModal.request,
    (
      state: SamplingsState,
      { payload: { samplingId } }: ActionType<typeof actions.openMarkingModal.request>,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        samplingId,
        opened: true,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.openMarkingModal.success,
    (
      state: SamplingsState,
      { payload: { dialogs, activeDialog } }: ActionType<typeof actions.openMarkingModal.success>,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        dialogs,
        activeDialog,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.openMarkingModal.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        opened: false,
        loading: false,
      },
    }),
  )
  .handleAction(
    [
      actions.openNextDialogToMark.request,
      actions.openPrevDialogToMark.request,
    ],
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        loading: true,
      },
    }),
  )
  .handleAction(
    [
      actions.openNextDialogToMark.failure,
      actions.openPrevDialogToMark.failure,
    ],
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.openNextDialogToMark.success,
    (
      state: SamplingsState,
      { payload: { nextDialogs, activeDialog } }: ActionType<typeof actions.openNextDialogToMark.success>,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        activeDialog,
        dialogs: nextDialogs ?
          [...state.markingModal.dialogs, ...nextDialogs] :
          state.markingModal.dialogs,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.openPrevDialogToMark.success,
    (
      state: SamplingsState,
      { payload: { prevDialogs, activeDialog } }: ActionType<typeof actions.openPrevDialogToMark.success>,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        activeDialog,
        dialogs: prevDialogs ?
          [...prevDialogs, ...state.markingModal.dialogs] :
          state.markingModal.dialogs,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadProjectResolutions.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      resolutions: {
        ...state.resolutions,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadProjectResolutions.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.loadProjectResolutions.success>,
    ): SamplingsState => ({
      ...state,
      resolutions: {
        ...state.resolutions,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadProjectResolutions.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      resolutions: {
        ...state.resolutions,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadQualityStatsBySamplings.request,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.loadQualityStatsBySamplings.request>,
    ): SamplingsState => ({
      ...state,
      tableStats: {
        ...state.tableStats,
        total: undefined,
        statistics: payload.offset ? state.tableStats.statistics : [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadQualityStatsBySamplings.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.loadQualityStatsBySamplings.success>,
    ): SamplingsState => {
      const newList = [
        ...state.tableStats.statistics,
        ...payload,
      ];

      return {
        ...state,
        tableStats: {
          ...state.tableStats,
          statistics: newList,
          total: payload.length === 0 ? newList.length : state.tableStats.total,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadQualityStatsBySamplings.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      tableStats: {
        ...state.tableStats,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadQualityStatsByDay.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      chartStats: {
        ...state.chartStats,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadQualityStatsByDay.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.loadQualityStatsByDay.success>,
    ): SamplingsState => {
      const positiveResolutions = state.resolutions.list
        .filter(isPositiveResolution)
        .map(r => r.id);
      const markedCount = payload.reduce((res, item) => res + getDayTotalMarked(item), 0);
      const okCount = payload.reduce((res, item) => res + getDayMarked(item, positiveResolutions), 0);
      return {
        ...state,
        chartStats: {
          ...state.chartStats,
          plotItems: payload,
          markedCount: markedCount,
          okCount,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadQualityStatsByDay.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      chartStats: {
        ...state.chartStats,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentDialogDetails.request,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentDialogDetails.success,
    (
      state: SamplingsState,
      { payload }: ActionType<typeof actions.loadCurrentDialogDetails.success>,
    ): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        dialogs: state.markingModal.dialogs.map(d =>
          d.id === payload.id ? payload : d,
        ),
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentDialogDetails.failure,
    (state: SamplingsState): SamplingsState => ({
      ...state,
      markingModal: {
        ...state.markingModal,
        loading: false,
      },
    }),
  );

function incrementResolutionCounter(
  stats: SamplingStats[],
  samplingId: NewSampling['id'],
  resolutionTypeId: ResolutionType['id'],
): SamplingStats[] {
  return stats.map(s => {
    if (s.samplingId !== samplingId) return s;

    let resolutionItem = s.stats.find(r => r.resolutionTypeId === resolutionTypeId);
    if (!resolutionItem) {
      resolutionItem = {
        resolutionTypeId,
        count: 0,
      };
      s.stats.push(resolutionItem);
    }

    resolutionItem.count += 1;

    return s;
  });
}

function decrementResolutionCounter(
  stats: SamplingStats[],
  samplingId: NewSampling['id'],
  resolutionTypeId: ResolutionType['id'],
): SamplingStats[] {
  return stats.map(s => {
    if (s.samplingId !== samplingId) return s;

    const resolutionItem = s.stats.find(r => r.resolutionTypeId === resolutionTypeId);
    if (!resolutionItem) return s;

    resolutionItem.count -= 1;

    return s;
  });
}
