import React from 'react';

import { SvgProps } from '../types';

function ArrowDashboardIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M20.6848 4.04083C20.8381 3.99631 20.9997 3.98806 21.1567 4.01675C21.3137 4.04543 21.462 4.11026 21.5896 4.2061C21.7173 4.30194 21.8209 4.42617 21.8923 4.56897C21.9636 4.71176 22.0008 4.8692 22.0008 5.02883L21.9998 10.7408C21.9998 11.5888 21.0308 12.0728 20.3528 11.5638L18.7108 10.3288L14.5308 15.8968C14.2872 16.2209 13.9327 16.4437 13.5351 16.5227C13.1375 16.6016 12.7247 16.5312 12.3758 16.3248L12.2258 16.2248L8.88881 13.7178L4.47082 19.6028C4.05082 20.1628 3.28582 20.3098 2.69382 19.9708L2.54982 19.8758C2.28288 19.6753 2.09824 19.3843 2.03049 19.0573C1.96275 18.7304 2.01655 18.3899 2.18182 18.0998L2.27682 17.9558L7.35382 11.1938C7.59728 10.8694 7.952 10.6462 8.3499 10.5672C8.74781 10.4882 9.16086 10.559 9.50982 10.7658L9.65882 10.8658L12.9948 13.3718L16.5168 8.68183L14.8698 7.44483C14.7273 7.33731 14.6151 7.19457 14.5443 7.03062C14.4736 6.86667 14.4467 6.68715 14.4662 6.50966C14.4858 6.33217 14.5512 6.16281 14.6559 6.01821C14.7607 5.87361 14.9013 5.75873 15.0638 5.68483L15.2008 5.63483L20.6858 4.03983L20.6848 4.04083Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowDashboardIcon;
