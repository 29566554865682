import React from 'react';

import { SvgProps } from '../types';

function UploadIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0099 15.3274C12.5171 15.3274 12.9348 14.9241 12.9348 14.4439V4.7835L12.8652 3.36229L13.4718 4.06329L14.8641 5.49411C15.0232 5.66696 15.2519 5.75338 15.4807 5.75338C15.9381 5.75338 16.316 5.43649 16.316 4.98516C16.316 4.74509 16.2166 4.57224 16.0475 4.40899L12.716 1.30729C12.4773 1.07682 12.2486 1 12.0099 1C11.7613 1 11.5425 1.07682 11.3039 1.30729L7.96243 4.40899C7.79337 4.57224 7.69392 4.74509 7.69392 4.98516C7.69392 5.43649 8.06188 5.75338 8.51934 5.75338C8.74807 5.75338 8.98674 5.66696 9.14586 5.49411L10.5381 4.06329L11.1547 3.35268L11.0751 4.7835V14.4439C11.0751 14.9241 11.4928 15.3274 12.0099 15.3274ZM6.27182 23H17.7282C19.8862 23 21 21.9245 21 19.8695V10.4299C21 8.37495 19.8862 7.29943 17.7282 7.29943H15.0431V9.21039H17.5989C18.5039 9.21039 19.021 9.67132 19.021 10.5932V19.6966C19.021 20.6185 18.5039 21.0794 17.5989 21.0794H6.4011C5.48619 21.0794 4.97901 20.6185 4.97901 19.6966V10.5932C4.97901 9.67132 5.48619 9.21039 6.4011 9.21039H8.98674V7.29943H6.27182C4.12376 7.29943 3 8.36534 3 10.4299V19.8695C3 21.9245 4.12376 23 6.27182 23Z"
        fill={color}
      />
    </svg>
  );
}

export default UploadIcon;
