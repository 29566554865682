import React, { useCallback } from 'react';
import { block } from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { ModalProps } from '../../services/modal';
import Modal from '../components/ndModal';

import './styles.scss';

const b = block("export-call-statistics-modal");

type CallStatisticsFilters = {
  olderThan?: number;
  newerThan?: number;
}

export type ExportCallStatisticsModalProps = {
  filters: CallStatisticsFilters;
  onSubmit: (filters: CallStatisticsFilters) => void;
}

export default function ExportCallStatisticsModal({
  filters,
  onSubmit,
  close,
}: ExportCallStatisticsModalProps & ModalProps) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmit(filters);
  }, [filters, onSubmit]);

  return (
    <Modal
      className={b()}
      title={t("MODALS.EXPORT_CALL_STATISTICS.TITLE")}
      cancelText={t("COMMON.CANCEL")}
      confirmationText={t("MODALS.EXPORT_CALL_STATISTICS.EXPORT")}
      close={close}
      onConfirm={handleSubmit}
    >
      <div className="column-2">
        <span>{t("MODALS.EXPORT_CALL_STATISTICS.FILTERS")}</span>
        <div className={b("filters")}>
          <div>
            <span className={b("label")}>{t("PAGE_VOICE.LATER_THAN")}</span>
            <span>{filters.newerThan ? format(new Date(filters.newerThan), "HH:mm yyyy MMM dd") : "-"}</span>
          </div>
          <div>
            <span className={b("label")}>{t("PAGE_VOICE.EARLIER_THAN")}</span>
            <span>{filters.olderThan ? format(new Date(filters.olderThan), "HH:mm yyyy MMM dd") : "-"}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
