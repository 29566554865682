/* eslint-disable camelcase */
import { generateId } from "../../services/helpers/generateId";
import { KeyMetric } from "./Metric";

export type Model = {
  title: string,
  slug: string,
  version: number,
  useOldThresholds?: boolean,
}

export type ModelTopic = {
  id: string;
  slug: string,
  keyMetric: KeyMetric,
  threshold?: number,
  title?: string, // добавляется в саге, по списку сценариев
}

export type ModelDraft = {
  threshold: number;
  negativeThreshold: number;
  translate: boolean;
  isEnabled: boolean;
}

export type PlotsPoint = {
  automatization: number,
  automatization_topic: number,
  precision: number,
  recall: number,
  threshold: number,
}

export function getEmptyModel(): Model {
  return {
    title: '',
    slug: '',
    version: 1,
  };
}

export function getEmptyModelTopic(): ModelTopic {
  return {
    id: generateId(),
    slug: '',
    keyMetric: KeyMetric.precision,
  };
}

export function isModelEmpty(value: Model): boolean {
  return value.slug === getEmptyModel().slug;
}

export function isModelTopicEmpty(value: ModelTopic): boolean {
  return value.slug === getEmptyModelTopic().slug;
}
