import { createAsyncAction, createAction } from "typesafe-actions";
import { Entity, EntityCustomExtractor } from "../../types/models/Entity";
import { ErrorAction } from "../reducers";

export const loadEntities = createAsyncAction(
  'entities/load/requested',
  'entities/load/succeeded',
  'entities/load/failed',
)<undefined, Entity[], ErrorAction>();

export const createEntity = createAsyncAction(
  'entities/create/requested',
  'entities/create/succeeded',
  'entities/create/failed',
)<Entity, Entity, ErrorAction<Entity>>();

export const updateEntity = createAsyncAction(
  'entities/update/requested',
  'entities/update/succeeded',
  'entities/update/failed',
)<Entity, Entity, ErrorAction<Entity>>();

export const deleteEntity = createAsyncAction(
  'entities/delete/requested',
  'entities/delete/succeeded',
  'entities/delete/failed',
)<Entity, Entity, ErrorAction<Entity>>();

export const clearEmptyEntities = createAction(
  'entities/clearLocal',
)<undefined>();

export const removeEntityById = createAction(
  'entities/remove',
)<string>();

export const createEmptyEntity = createAction(
  'entities/createEmpty',
)<undefined>();

export const loadCustomExtractors = createAsyncAction(
  'entities/loadCustomExtractors/requested',
  'entities/loadCustomExtractors/succeeded',
  'entities/loadCustomExtractors/failed',
)<undefined, EntityCustomExtractor[], ErrorAction>();
