import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { getEmptyProject, Project } from '../../types/models/Project';
import * as actions from './actions';
import * as userActions from '../user/actions';
import { getEmptyProjectConfig, ProjectConfig } from '../../types/models/ProjectConfig';
import { DialogsStatisticsGroup } from '../../types/models/AutomationStatistics';
import { CallsDetailsStatisticsItem, CallsStatisticsItem } from '../../types/models/CallsStatistics';
import { addItemsToGroup, StatisticsTab } from './helpers';
import { FiltersWithDirection } from '../../pages/account/components/callsTab';
import cacheService from '../../services/cache/cacheService';
import { CallDirection } from '../../types/models/OutgoingCall';
import { StatisticsPeriod } from '../../types/models/StatisticsPeriod';
import { getDefaultDate } from '../../pages/account/helpers';
import { CacheItem } from '../../services/cache/cacheItem';
import { getDefaultDialogsMetrics } from '../../pages/account/components/overviewTab/helpers';

export type ProjectsState = {
  selectedProject: Project,
  chartStatistics: DialogsStatisticsGroup[],
  tableTopicsStatistics: DialogsStatisticsGroup[],
  callsTableStatistics: CallsStatisticsItem | null,
  callsChartStatistics: CallsStatisticsItem[] | null,
  callsDetailsStatistics: CallsDetailsStatisticsItem[] | null,
  callsStatisticsLoading: boolean;
  callsDetailsStatisticsLoading: boolean,
  list: Array<Project>,
  loading: boolean,
  statisticsLoading: boolean,
  config: ProjectConfig,
  creating: boolean,
  statisticsFilters: FiltersWithDirection,
  statisticsTab: StatisticsTab,
  exportHistoryOpened: boolean,
}

const [defaultDateFrom, defaultDateTo] = getDefaultDate(StatisticsPeriod.Day);

export const defaulDialogsMetrics = getDefaultDialogsMetrics();

export const defaultStatisticsFilters: FiltersWithDirection = {
  direction: CallDirection.outgoing,
  dateFrom: defaultDateFrom,
  dateTo: defaultDateTo,
  topics: [],
  scenarios: [],
  target: "scenario",
  period: StatisticsPeriod.Day,
  tags: [],
  metrics: defaulDialogsMetrics,
};

const statisticsFilters = {
  ...defaultStatisticsFilters,
  ...cacheService.getItem<FiltersWithDirection>(
    CacheItem.PROJECT_STATISTICS,
    defaultStatisticsFilters,
  ),
  dateFrom: defaultStatisticsFilters.dateFrom,
  dateTo: defaultStatisticsFilters.dateTo,
};

const initialState: ProjectsState = {
  selectedProject: getEmptyProject(),
  chartStatistics: [],
  tableTopicsStatistics: [],
  callsChartStatistics: null,
  callsTableStatistics: null,
  callsStatisticsLoading: false,
  callsDetailsStatistics: null,
  callsDetailsStatisticsLoading: false,
  list: [],
  loading: false,
  statisticsLoading: false,
  config: getEmptyProjectConfig(),
  creating: false,
  statisticsFilters,
  statisticsTab: StatisticsTab.Dialogs,
  exportHistoryOpened: false,
};

export const projectsReducer: Reducer<ProjectsState> = createReducer<ProjectsState>(initialState)
  .handleAction(
    actions.loadProjects.request,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadProjects.success,
    (
      state: ProjectsState,
      { payload = [] }: ActionType<typeof actions.loadProjects.success>,
    ): ProjectsState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadProjects.failure,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.selectProject,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.selectProject>,
    ): ProjectsState => ({
      ...state,
      selectedProject: payload,
      statisticsFilters: {
        ...defaultStatisticsFilters,
        dateFrom: state.statisticsFilters.dateFrom,
        dateTo: state.statisticsFilters.dateTo,
      },
      statisticsTab: StatisticsTab.Dialogs,
      exportHistoryOpened: false,
    }),
  )
  .handleAction(
    actions.loadDialogsStatistics.request,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      chartStatistics: [...initialState.chartStatistics],
      tableTopicsStatistics: [...initialState.tableTopicsStatistics],
      statisticsLoading: true,
    }),
  )
  .handleAction(
    actions.loadDialogsStatistics.success,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.loadDialogsStatistics.success>,
    ): ProjectsState => ({
      ...state,
      tableTopicsStatistics: payload.topics || [],
      chartStatistics: payload.chart,
      statisticsLoading: false,
    }),
  )
  .handleAction(
    actions.loadDialogsStatistics.failure,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      statisticsLoading: false,
    }),
  )
  .handleAction(
    actions.loadCallsStatistics.request,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.loadCallsStatistics.request>,
    ): ProjectsState => ({
      ...state,
      callsStatisticsLoading: !payload.groupId,
    }),
  )
  .handleAction(
    actions.loadCallsStatistics.success,
    addItemsToGroup,
  )
  .handleAction(
    actions.loadCallsStatistics.failure,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      callsChartStatistics: initialState.callsChartStatistics,
      callsTableStatistics: initialState.callsTableStatistics,
      callsStatisticsLoading: false,
    }),
  )
  .handleAction(
    actions.loadCallsDetailsStatistics.request,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      callsDetailsStatistics: null,
      callsDetailsStatisticsLoading: true,
    }),
  )
  .handleAction(
    actions.loadCallsDetailsStatistics.success,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.loadCallsDetailsStatistics.success>,
    ): ProjectsState => ({
      ...state,
      callsDetailsStatistics: payload,
      callsDetailsStatisticsLoading: false,
    }),
  )
  .handleAction(
    actions.loadCallsDetailsStatistics.failure,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      callsDetailsStatisticsLoading: false,
    }),
  )
  .handleAction(
    actions.resetCallsDetailsStatistics,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      callsDetailsStatistics: null,
      callsDetailsStatisticsLoading: false,
    }),
  )
  .handleAction(
    actions.loadProjectConfig.success,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.loadProjectConfig.success>,
    ): ProjectsState => ({
      ...state,
      config: payload,
    }),
  )
  .handleAction(
    actions.updateProjectConfig.success,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.updateProjectConfig.success>,
    ): ProjectsState => ({
      ...state,
      config: payload,
    }),
  )
  .handleAction(
    actions.createProject.request,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      creating: true,
    }),
  )
  .handleAction(
    actions.createProject.success,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.createProject.success>,
    ): ProjectsState => ({
      ...state,
      list: [...state.list, payload],
      creating: false,
    }),
  )
  .handleAction(
    actions.createProject.failure,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      creating: false,
    }),
  )
  .handleAction(
    userActions.clearUser,
    () => ({
      ...initialState,
    }),
  )
  .handleAction(
    actions.updateFilters,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.updateFilters>,
    ): ProjectsState => ({
      ...state,
      statisticsFilters: {
        ...state.statisticsFilters,
        ...payload,
      },
    }),
  )
  .handleAction(
    actions.setStatisticsTab,
    (
      state: ProjectsState,
      { payload }: ActionType<typeof actions.setStatisticsTab>,
    ): ProjectsState => ({
      ...state,
      statisticsTab: payload,
      statisticsFilters: {
        ...defaultStatisticsFilters,
        dateFrom: state.statisticsFilters.dateFrom,
        dateTo: state.statisticsFilters.dateTo,
      },
      exportHistoryOpened: false,
    }),
  )
  .handleAction(
    actions.openExportHistory,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      exportHistoryOpened: true,
    }),
  )
  .handleAction(
    actions.closeExportHistory,
    (state: ProjectsState): ProjectsState => ({
      ...state,
      exportHistoryOpened: false,
    }),
  );
