import { TagBackend } from '../backendModels/TagBackend';
import { Tag } from '../models/Tag';

export function parseTag(tag: TagBackend): Tag {
  return {
    id: tag.id,
    slug: tag.slug,
    countUses: tag.usage_counter,
  };
}

export function parseTagToBackend(tag: Tag): TagBackend {
  return {
    id: tag.id,
    slug: tag.slug,
    usage_counter: tag.countUses || 0,
  };
}
