import {
  useEffect,
  useMemo,
  useState,
  useRef,
  ReactNode,
  useCallback,
} from 'react';

import { block } from 'bem-cn';

import { getMediaErrorMessage } from '../../services/helpers/utilities';

import { useForceUpdate } from '../../hooks/useForceUpdate';

import ProgressRing from '../../components/progressRing';

import Icon from '../icon';

import './audio.scss';

const b = block("audio");

type AudioProps = {
  audio: HTMLAudioElement,
  countReloadOnError?: number,
  children?: ReactNode;
  className?: string;
  onError?: () => void;
}

const RELOAD_TIMEOUT = 1000;

export default function AudioFiles({
  audio,
  countReloadOnError = 2,
  className = '',
  onError,
}: AudioProps) {
  const [playing, setPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const reloadCounter = useRef<number>(0);

  const rerender = useForceUpdate();

  const startPlaying = useCallback(
    () => {
      if (audio) {
        audio.play();
      }
    },
    [audio],
  );

  const stopPlaying = useCallback(
    () => {
      if (audio) {
        audio.pause();
      }
    },
    [audio],
  );

  const togglePlaying = useCallback(
    () => {
      if (playing) {
        stopPlaying();
      } else {
        startPlaying();
      }
    },
    [playing, startPlaying, stopPlaying],
  );

  useEffect(() => {
    audio.addEventListener('play', () => {
      setPlaying(true);
    });

    audio.addEventListener('pause', () => {
      setPlaying(false);
    });

    audio.addEventListener('ended', () => {
      setPlaying(false);
    });

    audio.addEventListener('timeupdate', () => {
      setProgress(Math.round((audio.currentTime / audio.duration) * 100) || 0);
    });

    let reloadTimer: ReturnType<typeof setTimeout>;
    audio.addEventListener('error', event => {
      console.error("Error when trying to load audio: ", audio, event);
      rerender();
      if (reloadCounter.current < countReloadOnError) {
        reloadCounter.current++;
        reloadTimer = setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log('reload', reloadCounter, countReloadOnError);
          audio.load();
        }, RELOAD_TIMEOUT);
      }
    });

    return () => clearTimeout(reloadTimer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  const StatusIcon = useMemo(() => {
    if (audio.error) {
      const errorMessage = getMediaErrorMessage(audio.error);
      return <Icon title={errorMessage} type="cross" />;
    }

    if (playing) {
      return <Icon type="pause" />;
    }

    return <Icon type="play" />;
  }, [audio.error, playing]);

  useEffect(
    () => {
      if (audio.error && onError) {
        onError();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [audio.error],
  );

  return (
    <div className={`${b()} ${className}`}>
      <ProgressRing className={b("progress-bar").toString()} percent={progress} />
      <ProgressRing className={b("progress-background").toString()} color="#D2D0CC" percent={100} />
      <button
        type="button"
        className={b("wrapper")}
        onClick={togglePlaying}
      >
        {StatusIcon}
      </button>
    </div>
  );
}
