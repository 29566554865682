import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { block } from 'bem-cn';

import * as actions from '../../redux/sidebar/actions';
import { getSidebarOpenStatus } from '../../redux/sidebar/selectors';

import Icon from '../../components-new/icon';
import ProjectSelector from '../../components-new/projectSelector';

import SidebarHeader from './header';
import PagesList from './pagesList';
import ReleaseButton from './releaseButton';
import FeedbackButton from './feedbackButton';

import {
  sidebarHierarchy,
  sidebarHierarchyAsPlainArray,
  SidebarItem as SidebarItemModel,
} from './config';

import './styles.scss';

const b = block("sidebar");

function Sidebar() {
  const dispatch = useDispatch();

  const opened = useSelector(
    getSidebarOpenStatus,
    shallowEqual,
  );

  const [activeItem, setActiveItem] = useState<SidebarItemModel['id'] | undefined>(
    sidebarHierarchyAsPlainArray
      .find(item => item.url === document.location.pathname)?.id,
  );

  const toggleExpand = useCallback(
    () => {
      if (!opened) {
        dispatch(actions.openSidebar());
      } else {
        dispatch(actions.closeSidebar());
      }
    },
    [dispatch, opened],
  );

  useEffect(() => {
    let oldPathname = document.location.pathname;

    const bodyList = document.body;

    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        if (oldPathname !== document.location.pathname) {
          oldPathname = document.location.pathname;
          /* href changed */
          const newActiveItem = sidebarHierarchyAsPlainArray
            .find(item => item.url === document.location.pathname);
          if (newActiveItem) {
            setActiveItem(newActiveItem.id);
          }
        }
      });
    });

    const config = {
      childList: true,
      subtree: true,
    };

    observer.observe(bodyList, config);

    const handleWindowResize = () => {
      if (window.innerWidth < 1200) dispatch(actions.closeSidebar());
      if (window.innerWidth > 1200) dispatch(actions.openSidebar());
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      observer.disconnect();
    };
  }, [dispatch]);

  return (
    <div className={b({ expanded: opened })}>
      <div className={b("header")}>
        <SidebarHeader />
      </div>
      <ProjectSelector />
      <div className={b("content")}>
        <PagesList
          items={sidebarHierarchy}
          activeItem={activeItem}
        />
        <div className={b("extra-items")}>
          <FeedbackButton />
          <ReleaseButton />
        </div>
      </div>
      <button
        type="button"
        className={b("toggle-button")}
        onClick={toggleExpand}
      >
        <Icon className={b("toggle-icon")} type="chevronRight" />
      </button>
    </div>
  );
}

export default Sidebar;
