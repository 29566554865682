import { ResponseMessage, ResponseMetric, ScenariosWayPoint, isRequestMessage } from "../../../../types/models/Dialog";
import { ScenarioGraph } from "../../../../types/models/ScenarioGraph";
import { Version } from "../../../../types/models/Version";
import { GroupedSteps, ScenarioStep, StepType } from './types';

import { store } from "../../../../redux/store";
import { getCurrentDialog } from "../../../../redux/dialogs/selectors";

import apiService from "../../../../services/api";

export const parseStep = (data: ScenariosWayPoint) => {
  const { scenarioId, scenarioSlug, metric, scenarioTitle, ...node } = data;

  return {
    scenario: { scenarioId, scenarioSlug, scenarioTitle },
    node: {
      ...node,
      metric,
    },
  };
};

export const groupStepsByScenario = (scenarioPath: ScenariosWayPoint[]) => {
  if (!scenarioPath || !scenarioPath.length) return [];

  const result: GroupedSteps[] = [];

  scenarioPath?.forEach(item => {
    if (isPhantomNode(item)) {
      result.push({
        type: StepType.phantom,
        nodeTitle: item.nodeTitle,
        metric: item.metric,
      });
    } else {
      const lastGroup = result[result.length - 1];

      if (item.scenarioId !== (lastGroup as ScenarioStep)?.scenarioId) {
        const { node, scenario } = parseStep(item);
        const step: ScenarioStep = {
          ...scenario,
          nodes: [node],
          type: StepType.step,
          metric: node.metric,
        };
        result.push(step);
      } else {
        const { node } = parseStep(item);
        (lastGroup as ScenarioStep).nodes.push(node);
        (lastGroup as ScenarioStep).metric = sumMetrics(lastGroup.metric, node.metric);
      }
    }
  });

  return result;
};

export const checkIfGraphExist = (
  id: ScenarioGraph['id'],
  versionId?: Version['id'],
): Promise<boolean> => {
  return new Promise(resolve => {
    apiService.getGraph({
      path: {
        scenario_id: id,
      },
      params: {
        version_id: versionId,
      },
    })
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
};

export enum PhantomNodes {
  entry = "ENTRY",
  responseCheck = "RESPONSE_CHECK",
}

export const PHANTOM_NODES = [
  PhantomNodes.entry,
  PhantomNodes.responseCheck,
];

export const isPhantomNode = (value: ScenariosWayPoint) => {
  return (PHANTOM_NODES as string[]).includes(value.nodeTitle || "");
};

export function getPreviousResponseMessages(debugId: ResponseMessage['debugId']): ResponseMessage[] {
  const state = store.getState();

  const currentDialog = getCurrentDialog(state);
  const responses = currentDialog.messages.filter(m => !isRequestMessage(m)) as ResponseMessage[];

  const endMessageIndex = responses.findIndex(message => message.debugId === debugId);

  if (endMessageIndex === -1) return [];

  return responses.slice(0, endMessageIndex);
}

function sumMetrics(
  metricA?: ResponseMetric,
  metricB?: ResponseMetric,
): ResponseMetric {
  if (typeof metricA?.totalTime !== 'number') {
    return {
      totalTime: metricB?.totalTime,
    };
  }

  if (typeof metricB?.totalTime !== 'number') {
    return {
      totalTime: metricA.totalTime,
    };
  }

  return {
    totalTime: metricA?.totalTime + metricB?.totalTime,
  };
}
