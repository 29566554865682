import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getConfigs, getConfig } from "../redux/user/selectors";
import { AppState } from "../redux/reducers";

export function useUserConfigs() {
  return useSelector(getConfigs);
}

export function useUserConfig(key: keyof AppState['user']['configs']) {
  const selector = useMemo(() => (state: AppState) => getConfig(state, key), [key]);

  return useSelector(selector);
}
