import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { block } from 'bem-cn';

import Button from '../../components-new/button';
import { ModalProps } from '../../services/modal';

import * as actions from '../../redux/slots/actions';
import { getCommonModelById } from '../../redux/models/selectors';
import { AppState } from '../../redux/reducers';
import { Model } from '../../types/models/CommonModel';
import { getFreeSlots, getModelSlots } from '../../redux/slots/selectors';
import SlotsTable from './slotsTable';
import { getEmptySlot, isSlotEmpty, Slot } from '../../types/models/Slots';
import Select, { SelectConfig } from '../../components-new/select/configurable';
import LoadingRow from '../../components/loadingRow';
import SideModal from '../../components-new/sideModal';

import './styles.scss';

export const b = block("manage-model-slots-modal");

export type ManageModelSlotsModalProps = {
  modelId: string,
}

export default function ManageModelSlotsModal({
  modelId,
  close,
}: ManageModelSlotsModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const model = useSelector((state: AppState) => getCommonModelById(state, modelId)) as Model;
  const modelSlots = useSelector((state: AppState) => getModelSlots(state, modelId));
  const freeSlots = useSelector(getFreeSlots);

  const [slot, setSlot] = useState<Slot>(getEmptySlot());

  const unlockSlot = useCallback(
    (s: Slot) => {
      dispatch(actions.unlockSlot.request(s));
    },
    [dispatch],
  );

  const handleSlotChange = useCallback(
    (slotInfo: string) => {
      const [shardId, workerId] = slotInfo.split('_');
      const newSlot = freeSlots.list
        .find(s => s.shardId === shardId && s.workerId === +workerId);
      if (!newSlot) return;

      setSlot(newSlot);
    },
    [freeSlots.list],
  );

  const handleAddSlotClick = useCallback(
    () => {
      dispatch(actions.lockSlot.request({
        ...slot,
        modelId,
      }));
    },
    [dispatch, modelId, slot],
  );

  const slotsConfig: SelectConfig = {
    options: freeSlots.list,
    labelFunction: (s: Slot) => `${t("MODALS.MANAGE_SLOTS.SHARD")}: ${s.shardId}, ${t("MODALS.MANAGE_SLOTS.WORKER")}: ${s.workerId}`,
    valueFunction: (s: Slot) => `${s.shardId}_${s.workerId}`,
  };

  useEffect(() => {
    setSlot(getEmptySlot());
  }, [freeSlots]);

  useEffect(() => {
    dispatch(actions.loadSlots.request({ modelId }));
    dispatch(actions.loadFreeSlots.request());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SideModal
      open
      className={b()}
      title={t("MODALS.MANAGE_SLOTS.TITLE")}
      onClose={close}
    >
      <div className={b("content").mix("column-4")}>
        <span className="text-secondary">
          {t("MODALS.MANAGE_SLOTS.MSG_SLOTS_IN_USE", { model: model.title })}
          :
        </span>
        {freeSlots.list.length === 0 ? (
          <span>{t("MODALS.MANAGE_SLOTS.MSG_NO_FREE_SLOTS")}</span>
        ) : (
          <div className="row-2">
            <Select
              config={slotsConfig}
              value={isSlotEmpty(slot) ? "" : `${slot.shardId}_${slot.workerId}`}
              menuPortalTarget={document.getElementById("modal-container")}
              placeholder={t("MODALS.MANAGE_SLOTS.SELECT_SLOT")}
              menuPosition="fixed"
              onChange={handleSlotChange}
              usePortal
            />
            <Button
              primary
              disabled={!slot || isSlotEmpty(slot)}
              onClick={handleAddSlotClick}
            >
              <span>{t("COMMON.ADD")}</span>
            </Button>
          </div>
        )}
        {/* eslint-disable-next-line no-nested-ternary */ }
        {modelSlots.loading ? (
          <LoadingRow />
        ) : modelSlots.list.length === 0 ? (
          <span>{t("MODALS.MANAGE_SLOTS.MSG_NO_ACTIVE_SLOTS")}</span>
        ) : (
          <SlotsTable
            slots={modelSlots.list}
            onUnlockSlotClick={unlockSlot}
          />
        )}
      </div>
    </SideModal>
  );
}
