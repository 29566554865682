import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';

import modalService from '../../services/modal';

import * as actions from '../../redux/projects/actions';

import Button from '../button';
import Icon from '../icon';

import { b } from './index';
import accessService from '../../services/access';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ProjectSelectorMenu(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSuperAdmin = accessService.checkIfCurrentUserSuperadmin();

  const handleCreateProject = useCallback(() => {
    const modalId = modalService.openCreateProject({
      onConfirm: data => {
        dispatch(actions.createProject.request({
          ...data,
          modalId,
        }));
      },
    });
  }, [dispatch]);

  return (
    <components.Menu {...props}>
      {props.children}
      {isSuperAdmin && (
        <>
          <div className={b("delimiter")} />
          <Button
            className={b("create-project-button")}
            onClick={handleCreateProject}
          >
            <Icon className={b("project-icon")} type="plus" />
            <span>{t("ROBOTS.CREATE")}</span>
          </Button>
        </>
      )}
    </components.Menu>
  );
}

export default ProjectSelectorMenu;
