import { SvgProps } from "../types";

function EyeOffOutlineIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.826 18.9021L18 22.0078H20.5L6.5 2.00781H4L6.59881 5.7204C6.26269 5.88054 5.93303 6.05593 5.6109 6.24628C3.67591 7.38969 2.08349 9.03084 1 11V13C2.08463 14.968 3.67738 16.6099 5.61216 17.7527C7.54694 18.8956 9.7529 19.5 12 19.5C13.3042 19.5 14.5945 19.2964 15.826 18.9021ZM14.614 17.1707L13.331 15.3379C12.9048 15.4438 12.459 15.5 12 15.5C8.96243 15.5 6.5 13.0376 6.5 10C6.5 9.20833 6.66726 8.45573 6.96838 7.77561C6.854 7.83761 6.74063 7.90179 6.62836 7.96814C5.14318 8.84574 3.89838 10.0719 3 11.5378L2.75 11.9996L3 12.4615C3.89959 13.9273 5.14488 15.1539 6.62933 16.0307C8.25684 16.9921 10.1117 17.5 12 17.5C12.8854 17.5 13.7633 17.3883 14.614 17.1707ZM12.0443 13.4997L8.72619 8.75951C8.58002 9.14508 8.5 9.5632 8.5 10C8.5 11.933 10.067 13.5 12 13.5C12.0148 13.5 12.0296 13.4999 12.0443 13.4997Z"
        fill={color}
      />
      <path
        d="M23 13C21.9991 14.816 20.5656 16.3543 18.8299 17.4791L17.6821 15.8394C19.0296 14.9791 20.1641 13.8236 21 12.4615L21.25 11.9996L21 11.5378C20.1016 10.0719 18.8568 8.84574 17.3716 7.96814C17.2594 7.90179 17.146 7.83761 17.0316 7.77561C17.3327 8.45573 17.5 9.20833 17.5 10C17.5 11.39 16.9844 12.6595 16.134 13.6278L14.9325 11.9114C15.2914 11.3619 15.5 10.7053 15.5 10C15.5 8.06786 13.9344 6.50139 12.0026 6.5L12 6.5C11.7275 6.5 11.4622 6.53115 11.2076 6.59008L9.87142 4.68126C10.5718 4.56086 11.2838 4.49974 12 4.5C14.2476 4.49919 16.4541 5.10288 18.3891 6.24628C20.3241 7.38969 21.9165 9.03084 23 11V13Z"
        fill={color}
      />
    </svg>
  );
}

export default EyeOffOutlineIcon;
