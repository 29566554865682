import { SvgProps } from "../types";

function FrameIcon({
  color = 'currentColor',
  width = '24',
  height = '24',
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6H20V18H4L4 11H9C10.1046 11 11 10.1046 11 9V6ZM9 6H4L4 9H9V6ZM9 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V9V6C2 4.89543 2.89543 4 4 4H9Z"
        fill={color}
      />
    </svg>
  );
}

export default FrameIcon;
