import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { components, ValueContainerProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { block } from 'bem-cn';

import {
  getFirstOverflowItemPosition,
  getOverflowItemsCount,
} from './helpers';

import PopupWithTrigger from '../../popupWithTrigger';
import Card from '../../card';
import Icon from '../../icon';

import './valueContainerFloating.scss';

const b = block("value-container-floatin");

export const OVERFLOW_BADGE_WIDTH_PX = 36;

function ValueContainerFloating({
  children,
  ...rest
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ValueContainerProps<any>) {
  const { t } = useTranslation();
  const valueWrapperRef = useRef<HTMLDivElement>(null);

  const [overflowBadgePositionX, setOverflowBadgePositionX] = useState<number>(0);
  const [overflowCount, setOverflowCount] = useState<number>(0);

  const placeholder = rest.selectProps.placeholder;
  const value = rest.getValue();
  const isAllSelected = rest.selectProps.isMulti && rest.options.length === value.length && rest.options.length !== 0;

  const valueExist = useMemo(
    () => {
      if (!rest.selectProps.controlShouldRenderValue) {
        return false;
      }

      if (Array.isArray(value)) {
        return value.length > 0;
      }

      return !!value;
    },
    [rest.selectProps.controlShouldRenderValue, value],
  );

  const scrollContainer = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => {
      if (!valueWrapperRef.current) return;

      setTimeout(() => (valueWrapperRef.current as HTMLDivElement).scrollLeft = 0);
    },
    [],
  );

  useEffect(
    () => {
      if (!valueWrapperRef.current) return;

      if (!rest.selectProps.isMulti) return;

      let overflowItemsC = getOverflowItemsCount(valueWrapperRef.current, "nd-custom-select__multi-value-floating");

      // Ниже проверяем, чтобы в случае, если первый элемент тоже не
      // влез в родительский контейнер, то его не учитывать (т.к. первый элемент всегда
      // отображается, даже если не полностью помещается)
      overflowItemsC = Math.min(
        overflowItemsC,
        Math.max(value.length - 1, 0),
      );

      const firstOverflowItemPosition = getFirstOverflowItemPosition(valueWrapperRef.current);

      if (firstOverflowItemPosition) {
        const gap = 4;
        setOverflowBadgePositionX(firstOverflowItemPosition - gap);
      }
      setOverflowCount(overflowItemsC);
    },
    [overflowCount, rest.selectProps.isMulti, children, value.length],
  );

  const showOverflowBadge = rest.selectProps.isMulti &&
    overflowCount > 0 &&
    Array.isArray(value) &&
    value.length > 1 &&
    !isAllSelected;

  const showPlaceholder = useMemo(
    () => {
      if (!placeholder) return false;

      if (rest.selectProps.floatingPlaceholder) return true;

      return value.length === 0;
    },
    [placeholder, rest.selectProps.floatingPlaceholder, value],
  );

  const icon = useMemo(
    () => {
      return !rest.selectProps.isMulti && value[0] && value[0].icon;
    },
    [rest.selectProps.isMulti, value],
  );

  return (
    <components.ValueContainer {...rest}>
      <div
        onMouseDown={scrollContainer}
        className={b({
          "with-value": valueExist,
          "with-icon": !!icon,
        })}
      >
        {icon && <Icon className={b("icon")} type={icon} />}
        {showPlaceholder && <span className={b("placeholder")}>{placeholder}</span>}
        {isAllSelected &&
          <div className={b("all-values")}>
            {`${t("COMMON.ALL_VALUES")} (${value.length} ${rest.selectProps.allSelectedLabel ? rest.selectProps.allSelectedLabel(value.length) : ''})`}
          </div>}
        <div
          className={b("value-wrapper", { "all-selected": isAllSelected })}
          ref={valueWrapperRef}
          onKeyDown={scrollContainer}
        >
          {children}
          {!!showOverflowBadge && (
            <div className={b("overflow-wrapper")}>
              <PopupWithTrigger
                withWrapper
                placement="bottom-start"
                wrapperClassName="Lol"
                trigger={
                  <div
                    style={{ left: overflowBadgePositionX + "px" }}
                    className={b("overflow-counter")}
                  >
                    +{overflowCount}
                  </div>
                }
              >
                <Card className={b("total-list")}>
                  <span className={b("hint")}>{t("COMMON.SELECTED")}:</span>
                  {Array.isArray(value) && (
                    value.map(item => <span key={item.value}>{item.label}</span>)
                  )}
                </Card>
              </PopupWithTrigger>
            </div>
          )}
        </div>
      </div>
    </components.ValueContainer>
  );
}

export default memo(ValueContainerFloating);
