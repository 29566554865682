import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureClarificationType } from '../../../../types/models/Feature';

import Select, { SelectConfig } from '../../../../components-new/select/configurable';

type FeatureCalculationTypeSelectorProps = {
  value: FeatureClarificationType,
  disabled?: boolean,
  floatingPlaceholder?: boolean,
  onChange: (value: FeatureClarificationType) => void,
}

const options = [
  { value: FeatureClarificationType.external, label: "PAGE_FEATURES.SOURCE_EXTERNAL" },
  { value: FeatureClarificationType.fromText, label: "PAGE_FEATURES.SOURCE_FROM_TEXT" },
];

export default function FeatureCalculationTypeSelector({
  value,
  disabled = false,
  floatingPlaceholder = false,
  onChange,
}: FeatureCalculationTypeSelectorProps): ReactElement {
  const { t } = useTranslation();

  const config: SelectConfig = useMemo(
    () => {
      return {
        options,
        valueFunction: option => option.value,
        labelFunction: option => t(option.label),
      };
    },
    [t],
  );

  return <Select
    config={config}
    value={value}
    isDisabled={disabled}
    floatingPlaceholder={floatingPlaceholder}
    onChange={onChange}
  />;
}
