import React, { FC } from 'react';
import { SvgProps } from '../types';

const SearchListIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 5.00016C10.659 5.00016 9.16667 6.49255 9.16667 8.3335C9.16667 10.1744 10.659 11.6668 12.5 11.6668C14.3409 11.6668 15.8333 10.1744 15.8333 8.3335C15.8333 6.49255 14.3409 5.00016 12.5 5.00016ZM7.5 8.3335C7.5 5.57207 9.73858 3.3335 12.5 3.3335C15.2614 3.3335 17.5 5.57207 17.5 8.3335C17.5 9.41336 17.1577 10.4133 16.5756 11.2306L18.8807 13.5357L17.7022 14.7142L15.3971 12.4091C14.5797 12.9912 13.5799 13.3335 12.5 13.3335C9.73858 13.3335 7.5 11.0949 7.5 8.3335ZM10 14.1668H2.5V15.8335H10V14.1668ZM2.5 5.8335H5.83333V7.50016H2.5V5.8335ZM5.83333 10.0002H2.5V11.6668H5.83333V10.0002Z"
      fill={color}
    />
  </svg>
);

export default SearchListIcon;
