import { AppState } from "../reducers";

export function getVoiceConfigModalOpened(state: AppState) {
  return state.projectVoiceConfig.modalOpened;
}

export function getVoiceConfig(state: AppState) {
  return state.projectVoiceConfig.config;
}

export function getVoiceConfigLoading(state: AppState) {
  return state.projectVoiceConfig.loading;
}

export function getVoiceConfigUpdating(state: AppState) {
  return state.projectVoiceConfig.updatingConfig;
}
