import { createAction, createAsyncAction } from "typesafe-actions";
import { Dialog, DialogsHistorySearchFilter } from "../../types/models/Dialog";
import { NewSampling, Sampling } from "../../types/models/Sampling";
import { ErrorAction } from "../reducers";
import { ActiveDialog } from "./reducer";
import { ResolutionType } from "../../types/models/Resolution";
import { SamplingStats, StatsByDayPlotItem } from "../../types/models/QualityStats";

export const loadSamplings = createAsyncAction(
  'samplings/load/requested',
  'samplings/load/succeeded',
  'samplings/load/failed',
)<undefined, Sampling[], ErrorAction>();

export const loadNewSamplings = createAsyncAction(
  'samplingsNew/load/requested',
  'samplingsNew/load/succeeded',
  'samplingsNew/load/failed',
)<undefined, NewSampling[], ErrorAction>();

export const createSampling = createAsyncAction(
  'samplings/create/requested',
  'samplings/create/succeeded',
  'samplings/create/failed',
)<{ name: string, quantity: number, filters: DialogsHistorySearchFilter }, Sampling, ErrorAction>();

export const createNewSampling = createAsyncAction(
  'samplingsNew/create/requested',
  'samplingsNew/create/succeeded',
  'samplingsNew/create/failed',
)<{
  name: string,
  filters: DialogsHistorySearchFilter,
  quantity?: number,
}, NewSampling, ErrorAction>();

export const deleteSampling = createAsyncAction(
  'samplings/deleteSampling/requested',
  'samplings/deleteSampling/succeeded',
  'samplings/deleteSampling/failed',
)<Sampling['slug'], Sampling['slug'], ErrorAction>();

export const deleteNewSampling = createAsyncAction(
  'samplingsNew/deleteSampling/requested',
  'samplingsNew/deleteSampling/succeeded',
  'samplingsNew/deleteSampling/failed',
)<NewSampling['id'], NewSampling['id'], ErrorAction>();

export const navigateMarkSampling = createAction('samplings/navigateMark')<string>();

export const closeMarkingModal = createAction('samplings/closeMarkingModal')();

export const openMarkingModal = createAsyncAction(
  'samplings/openMarkingModal/requested',
  'samplings/openMarkingModal/succeeded',
  'samplings/openMarkingModal/failed',
)<
  { startIndex: number, samplingId: NewSampling['id'] },
  { dialogs: Dialog[], activeDialog: ActiveDialog },
  ErrorAction
>();

export const openNextDialogToMark = createAsyncAction(
  'samplings/openNextDialogToMark/requested',
  'samplings/openNextDialogToMark/succeeded',
  'samplings/openNextDialogToMark/failed',
)<
  undefined,
  { activeDialog: ActiveDialog, nextDialogs?: Dialog[] },
  ErrorAction
>();

export const openPrevDialogToMark = createAsyncAction(
  'samplings/openPrevDialogToMark/requested',
  'samplings/openPrevDialogToMark/succeeded',
  'samplings/openPrevDialogToMark/failed',
)<
  undefined,
  { activeDialog: ActiveDialog, prevDialogs?: Dialog[] },
  ErrorAction
>();

export const startRemarkingDialog = createAction('samplings/startRemarkingDialog')();
export const endRemarkingDialog = createAction('samplings/endRemarkingDialog')();

export const markDialog = createAsyncAction(
  'samplings/markDialog/requested',
  'samplings/markDialog/succeeded',
  'samplings/markDialog/failed',
)<
  { badMessageSaiId?: string, resolution: ResolutionType['id'] },
  { badMessageId?: string, resolution: ResolutionType['id'] },
  ErrorAction
>();

export const openCreatingAutomaticSamplingModal = createAction('samplings/openCreatingAutomaticSamplingModal')();
export const closeCreatingAutomaticSamplingModal = createAction('samplings/closeCreatingAutomaticSamplingModal')();

export const createAutomaticSampling = createAsyncAction(
  'samplings/createAutomaticSampling/requested',
  'samplings/createAutomaticSampling/succeeded',
  'samplings/createAutomaticSampling/failed',
)<undefined, NewSampling, ErrorAction>();

export const navigateToSamplingDialogs = createAction(
  'samplings/navigateToSamplingDialogs',
)<{
  samplingId: NewSampling['id'];
  resolutionId?: ResolutionType['id'];
}>();

export const loadProjectResolutions = createAsyncAction(
  'samplings/loadProjectResolutions/requested',
  'samplings/loadProjectResolutions/succeeded',
  'samplings/loadProjectResolutions/failed',
)<
  undefined,
  ResolutionType[],
  ErrorAction
>();

export const loadQualityStatsByDay = createAsyncAction(
  'samplings/loadQualityStatsByDay/requested',
  'samplings/loadQualityStatsByDay/succeeded',
  'samplings/loadQualityStatsByDay/failed',
)<
  { startDate: Date, endDate: Date },
  StatsByDayPlotItem[],
  ErrorAction
>();

export const loadCurrentDialogDetails = createAsyncAction(
  'samplings/loadCurrentDialogDetails/requested',
  'samplings/loadCurrentDialogDetails/succeeded',
  'samplings/loadCurrentDialogDetails/failed',
)<undefined, Dialog, ErrorAction>();

export const loadQualityStatsBySamplings = createAsyncAction(
  'samplings/loadQualityStatsBySamplings/requested',
  'samplings/loadQualityStatsBySamplings/succeeded',
  'samplings/loadQualityStatsBySamplings/failed',
)<
  { offset?: number },
  SamplingStats[],
  ErrorAction
>();
