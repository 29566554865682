import { SagaIterator } from "redux-saga";
import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";

import { GetGraphDebugByIdRequest, GetGraphDebugByIdResponse } from "../../types/requests/Debug";
import { parseGraphDebugBlock } from "../../types/parsers/GraphDebugBlockParser";
import { GraphDebugBlock } from "../../types/models/GraphDebugBlock";

import apiService, { ApiResponse } from "../../services/api";
import i18n from "../../services/i18n";
import { generateId } from "../../services/helpers/generateId";
import * as dialogActions from "../dialogs/actions";

import { ErrorAction } from "../reducers";

import { mergeGraphDebugBlocks, concatBlocks } from './helpers';
import { getDebugBlocks } from './selectors';
import * as actions from './actions';
import { DebugStep } from "./types";

function* loadDebugById(): SagaIterator {
  yield takeLatest(actions.loadDebugById.request, function* ({ payload: debugId }) {
    try {
      const request: GetGraphDebugByIdRequest = {
        query: {
          debug_id: debugId,
          is_link_to_graph: false,
        },
      };

      const response: ApiResponse<GetGraphDebugByIdResponse> = yield call(
        apiService.getGraphDebugById,
        request,
      );

      if (response.status === 204) {
        yield put(actions.loadDebugById.success({ debugId, debugBlocks: [] }));
        return;
      }

      const allDebugBlocks = (response.data.blocks || []).map(parseGraphDebugBlock);
      let debugBlocks = allDebugBlocks.filter(item => item && item.nodeId && item.nodeId !== "entry");
      yield put(dialogActions.loadDebugBlocksById.success({ debugId, debugBlocks: allDebugBlocks }));

      // С бека блоки приходят несгруппированными. Могут идти несколько записей подряд
      // С одним и тем же id ноды. Такие блоки считаются одним шагом и для фронта
      // имеет смысл объединять в один "шаг"
      let currentNodeId: string | undefined = debugBlocks[0]?.nodeId;
      let currentStep = 1;
      debugBlocks = debugBlocks.reduce<GraphDebugBlock[]>((res, block) => {
        if (!block) return res;

        if (res.length === 0) {
          res.push({
            ...block,
            step: currentStep,
          });
          return res;
        }

        if (currentNodeId === block.nodeId) {
          res[res.length - 1] = mergeGraphDebugBlocks(res[res.length - 1], block);
        } else {
          currentNodeId = block.nodeId;
          currentStep += 1;
          res.push({
            ...block,
            step: currentStep,
          });
        }

        return res;
      }, []);

      const oldBlocks: GraphDebugBlock[] = yield select(getDebugBlocks);

      debugBlocks = concatBlocks(oldBlocks, debugBlocks);

      yield put(actions.resetAllDebugInfo());

      const nodeBlocks = debugBlocks.filter(block => !!block?.nodeId);

      let stepIndex: number = 1;
      // eslint-disable-next-line no-restricted-syntax
      for (const block of nodeBlocks) {
        if (!block) continue;

        const step: DebugStep = {
          id: generateId(),
          nodeId: block.nodeId,
          debugId: block.id,
          type: block.type,
          step: stepIndex,
        };
        block.step = stepIndex;

        stepIndex += 1;

        yield put(actions.createDebugStep(step));
      }

      yield put(actions.loadDebugById.success({ debugId, debugBlocks: nodeBlocks }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DIALOGS.DEBUG_LOAD");
      yield put(actions.loadDebugById.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadDebugs(): SagaIterator {
  yield takeLatest(
    actions.loadDebugs,
    function* (action) {
      const ids = action.payload;

      for (let id of ids) {
        yield put(actions.loadDebugById.request(id));
        yield take([
          actions.loadDebugById.success,
          actions.loadDebugById.failure,
        ]);
      }
    },
  );
}

export default function* graphDebugSagas() {
  yield fork(loadDebugById);
  yield fork(loadDebugs);
}
