import { Tag, TagInfo } from "../../types/models/Tag";

import { AppState } from "../reducers";

export function getTags(state: AppState): Tag[] {
  return state.tags.list;
}

export function getTagsSorted(state: AppState): Tag[] {
  return state.tags.list
    .sort((a, b) => a.slug.toUpperCase() > b.slug.toUpperCase() ? 1 : -1);
}

export function getTagsSlugs(state: AppState): string[] {
  return state.tags.list.map(({ slug }) => slug);
}

export function getTagsLoadingStatus(state: AppState): boolean {
  return state.tags.loading;
}

export function getTagBySlug(state: AppState, slug: Tag['slug']): Tag | undefined {
  return state.tags.list.find(tag => tag.slug === slug);
}

export function getDeleteTagModalOpenStatus(state: AppState): boolean {
  return state.tags.deleteTagModal.opened;
}

export function getTagToDeleteInfo(state: AppState): TagInfo {
  return state.tags.deleteTagModal.data;
}

export function getTagToDeleteInfoLoadingStatus(state: AppState): boolean {
  return state.tags.deleteTagModal.loading;
}

export function getTagToDelete(state: AppState): Tag | undefined {
  const tagId = state.tags.deleteTagModal.tagId;
  return state.tags.list.find(tag => tag.id === tagId);
}
