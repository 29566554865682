import { generateId } from "../../services/helpers/generateId";
import { Tag } from "./Tag";

export type DialogsStatisticsGroup = {
  id: string;
  title: string;
  totalNumber: PercentMetrics;
  automatedTemp: PercentMetrics;
  automatedForwarded: PercentMetrics;
  automatedNotForwarded: PercentMetrics;
  notAutomated: PercentMetrics;
  notAutomatedForwarded: PercentMetrics;
  notAutomatedNotForwarded: PercentMetrics;
  opened: PercentMetrics;
  closed: PercentMetrics;
  forwarded: PercentMetrics;
  reopened: PercentMetrics
  messagesNumber: PercentMetrics;
  messagesAutomatedNumber: PercentMetrics;
  messagesAutomatedTempNumber: PercentMetrics;
  resolvedByBot: PercentMetrics;
  minutes: number;
  csat?: number;
  cdsat?: number;
  nps?: number;
  tagCounters: Record<Tag['slug'], PercentMetrics>;
  meanUserScore?: number;
  userScoresTotalCount?: number;
  userScoreCounters?: Record<string, PercentMetrics>;
  items?: Array<DialogsStatisticsGroup>;
  isTotals?: boolean;
}

export type PercentMetrics = {
  number: number;
  percent?: number;
}

export enum MessageMetrics {
  automatizationNew = 'automatization',
  noAutomatization = 'no_automatization',
  tags = 'tags',
  userRates = 'user_rates',
  averageDialogTime = 'average_dialog_time',
  averageScenarioPerDialog = 'average_scenario_per_dialog',

  dialogs = 'total_number',
  automatization = 'automated_number',
  automatizationNewTemp = 'automated_temp_number',
  closed = 'closed_number',
  forwardedToOperator = 'forwarded_number',
  clientNotAnswered = 'not_answered_number',
  reopened = 'reopened_number',
  messagesAutomatedNumber = 'messages_automated_number',
  byMinutes = 'minutes',
  byMessages = 'messages_number',
  tag = 'tag',
  csat = 'csat',
  cdsat = 'cdsat',
  nps = 'nps',
  resolvedByBot = 'resolved_by_bot_number',
}

export const defaultMetrics = [
  MessageMetrics.automatizationNew,
  MessageMetrics.noAutomatization,
  MessageMetrics.tags,
  MessageMetrics.userRates,
  MessageMetrics.averageDialogTime,
  MessageMetrics.averageScenarioPerDialog,
];

export const additionalMetrics = [
  MessageMetrics.csat,
  MessageMetrics.cdsat,
  MessageMetrics.nps,
];

export const PREDEFINED_MESSAGE_METRICS: MessageMetrics[] = [
  MessageMetrics.automatizationNew,
  MessageMetrics.noAutomatization,
  MessageMetrics.tags,
  MessageMetrics.userRates,
  MessageMetrics.averageDialogTime,
  MessageMetrics.averageScenarioPerDialog,
];

export function getEmptyPercentMetrics(): PercentMetrics {
  return {
    number: 0,
    percent: 0,
  };
}

export function getEmptyDialogsStatisticsGroup(
  title: string,
): DialogsStatisticsGroup {
  return {
    id: generateId(),
    title,
    totalNumber: getEmptyPercentMetrics(),
    automatedTemp: getEmptyPercentMetrics(),
    automatedForwarded: getEmptyPercentMetrics(),
    automatedNotForwarded: getEmptyPercentMetrics(),
    notAutomated: getEmptyPercentMetrics(),
    notAutomatedForwarded: getEmptyPercentMetrics(),
    notAutomatedNotForwarded: getEmptyPercentMetrics(),
    opened: getEmptyPercentMetrics(),
    closed: getEmptyPercentMetrics(),
    forwarded: getEmptyPercentMetrics(),
    reopened: getEmptyPercentMetrics(),
    messagesNumber: getEmptyPercentMetrics(),
    messagesAutomatedNumber: getEmptyPercentMetrics(),
    messagesAutomatedTempNumber: getEmptyPercentMetrics(),
    resolvedByBot: getEmptyPercentMetrics(),
    minutes: 0,
    tagCounters: {},
  };
}
