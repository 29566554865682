import React, { FC } from 'react';
import { SvgProps } from '../types';

const PlayIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.30322 3.22708C8.07426 3.08434 7.81187 3.00607 7.54311 3.00034C7.27435 2.99461 7.00895 3.06161 6.77426 3.19446C6.53958 3.3273 6.34411 3.52117 6.20801 3.75608C6.07191 3.99098 6.0001 4.25842 6 4.53082V19.4692C6.0001 19.7416 6.07191 20.009 6.20801 20.2439C6.34411 20.4788 6.53958 20.6727 6.77426 20.8055C7.00895 20.9384 7.27435 21.0054 7.54311 20.9997C7.81187 20.9939 8.07426 20.9157 8.30322 20.7729L20.2807 13.3037C20.5006 13.1667 20.6821 12.9748 20.808 12.7465C20.9339 12.5182 21 12.261 21 11.9995C21 11.7379 20.9339 11.4808 20.808 11.2525C20.6821 11.0241 20.5006 10.8323 20.2807 10.6952L8.30322 3.22708Z"
      fill={color}
    />
  </svg>
);

export default PlayIcon;
