import { AppState } from "../reducers";

export function getLastReleaseNote({ releaseNotes }: AppState) {
  return releaseNotes.last;
}

export function getReleaseNotes({ releaseNotes }: AppState) {
  return releaseNotes.list;
}

export function showReleaseNotesModal({ releaseNotes }: AppState) {
  return releaseNotes.showList;
}

export function getReleaseNotesLoading({ releaseNotes }: AppState) {
  return releaseNotes.listLoading;
}

export function getReleaseNoteToEdit({ releaseNotes }: AppState) {
  return releaseNotes.noteToEdit;
}
