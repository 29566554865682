import { SagaIterator } from "redux-saga";
import { call, put, takeLatest, fork, select } from "redux-saga/effects";

import NotificationManager from "../../services/notifications";
import i18n from '../../services/i18n';
import apiService, { ApiResponse } from "../../services/api";
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";

import * as projectActions from '../projects/actions';
import * as actions from './actions';
import { getSelectedProject } from "../projects/selectors";

import { ErrorAction } from "../reducers";

import { Project } from "../../types/models/Project";
import {
  BalanceClient,
  EMPTY_CLIENT_ID,
  getEmptyPerson,
} from "../../types/models/Billing";
import {
  CreateClientRequest,
  CreateClientResponse,
  CreatePersonRequest,
  CreatePersonResponse,
  GetClientPersonRequest,
  GetClientPersonResponse,
  GetClientsResponse,
  LinkClientAndUserRequest,
  LinkClientAndUserResponse,
  UpdateClientDetailsRequest,
  UpdateClientDetailsResponse,
  UpdatePersonRequest,
  UpdatePersonResponse,
} from "../../types/requests/Billing";

import { getActiveClient } from "./selectors";
import { getClientForProject, setClientForProject } from "./helpers";
import { parsePerson, parsePersonToBackend } from "../../types/parsers/Billing";
import { PersonBackend } from "../../types/backendModels/BillingBackend";

function* loadClients(): SagaIterator {
  yield takeLatest(actions.loadClients.request, function* () {
    try {
      const response: ApiResponse<GetClientsResponse> = yield call(apiService.getClients);

      yield put(actions.loadClients.success(response.data.clients.map<BalanceClient>(objSnakeToCamelDeep)));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.CLIENTS.MULTIPLE") });
      yield put(actions.loadClients.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadActiveClientPerson(): SagaIterator {
  yield takeLatest(actions.loadActiveClientPerson.request, function* () {
    try {
      const activeClient: BalanceClient = yield select(getActiveClient);

      if (!activeClient.id) {
        throw new Error("No person");
      }
      const request: GetClientPersonRequest = {
        path: {
          client_id: activeClient.id,
        },
      };
      const response: ApiResponse<GetClientPersonResponse> = yield call(apiService.getClientPerson, request);

      if (!response || response.status === 404) {
        yield put(actions.loadActiveClientPerson.success(getEmptyPerson()));
      } else {
        yield put(actions.loadActiveClientPerson.success(parsePerson(response.data as PersonBackend)));
      }
    } catch (err) {
      yield put(actions.loadActiveClientPerson.failure(new ErrorAction(err)));
    }
  });
}

function* submitClient() {
  yield takeLatest(actions.submitClient, function* (action) {
    try {
      const activeClient: BalanceClient = yield select(getActiveClient);

      const newClient = activeClient.id === EMPTY_CLIENT_ID;

      yield put(actions.setModalLoadingStatus(true));
      if (newClient) {
        const params: CreateClientRequest = {
          body: objCamelToSnakeDeep(action.payload),
        };
        const response: ApiResponse<CreateClientResponse> = yield call(
          apiService.createClient,
          params,
        );
        yield put(actions.updateClient(objSnakeToCamelDeep(response.data)));
        yield put(actions.setActiveClient(response.data.id));
      } else {
        const params: UpdateClientDetailsRequest = {
          path: {
            client_id: activeClient.id,
          },
          body: objCamelToSnakeDeep(action.payload),
        };
        const response: ApiResponse<UpdateClientDetailsResponse> = yield call(
          apiService.updateClientDetails,
          params,
        );
        yield put(actions.updateClient(objSnakeToCamelDeep(response.data)));
        yield put(actions.setActiveClient(response.data.id));
      }
      yield put(actions.nextModalStep());
    } catch (err) {
      //@ts-ignore
      const { message } = err || {};
      const text = `${i18n.t("PAGE_BILLING.ERRORS.UPDATE_CLIENT")} ${message ? message : null}`;
      NotificationManager.error(text);
    } finally {
      yield put(actions.setModalLoadingStatus(false));
    }
  });
}

function* submitManager() {
  yield takeLatest(actions.submitManager, function* (action) {
    try {
      const activeClient: BalanceClient = yield select(getActiveClient);
      if (!activeClient.id) throw new Error("Client not found");

      yield put(actions.setModalLoadingStatus(true));

      const params: LinkClientAndUserRequest = {
        path: {
          client_id: activeClient.id,
        },
        query: {
          login: action.payload,
        },
      };

      const response: ApiResponse<LinkClientAndUserResponse> = yield call(apiService.linkClientAndUser, params);

      if (response.status === 404) {
        throw new Error("Can't find client");
      }

      if (response.status !== 200) {
        throw new Error("Can't link manager");
      }

      yield put(actions.updateClient({
        ...activeClient,
        isUserLinked: true,
      }));
      yield put(actions.nextModalStep());
    } catch (err) {
      //@ts-ignore
      const { message } = typeof err === "object" ? err : { message: err };
      const text = `${i18n.t("PAGE_BILLING.ERRORS.LINK_MANAGER")} ${message ?? null}`;
      NotificationManager.error(text);
    } finally {
      yield put(actions.setModalLoadingStatus(false));
    }
  });
}

function* submitPerson() {
  yield takeLatest(actions.submitPerson, function* (action) {
    try {
      const activeClient: BalanceClient = yield select(getActiveClient);
      if (!activeClient.id) throw new Error("No client");

      const newPerson = !activeClient.isPersonCreated;

      yield put(actions.setModalLoadingStatus(true));

      if (newPerson) {
        const params: CreatePersonRequest = {
          body: parsePersonToBackend(action.payload),
          path: {
            client_id: activeClient.id,
          },
        };

        const response: ApiResponse<CreatePersonResponse> = yield call(
          apiService.createPerson,
          params,
        );
        yield put(actions.updatePerson(objSnakeToCamelDeep(response.data)));
      } else {
        const params: UpdatePersonRequest = {
          body: parsePersonToBackend(action.payload),
          path: {
            client_id: activeClient.id,
          },
        };

        const response: ApiResponse<UpdatePersonResponse> = yield call(
          apiService.updatePerson,
          params,
        );
        yield put(actions.updatePerson(objSnakeToCamelDeep(response.data)));
      }
      yield put(actions.closeActiveClientEditModal());
    } catch (err) {
      // @ts-ignore
      const { message } = err || {};
      const text = `${i18n.t("PAGE_BILLING.ERRORS.UPDATE_PERSON")} ${message ? message : ""}`;
      NotificationManager.error(text);
    } finally {
      yield put(actions.setModalLoadingStatus(false));
    }
  });
}

function* autosaveClient() {
  yield takeLatest(actions.setActiveClient, function* (action) {
    const selectedProject: Project = yield select(getSelectedProject);
    setClientForProject(selectedProject.slug, action.payload);
  });
}

function* autoselectClient() {
  yield takeLatest(projectActions.selectProject, autoSelectHandler);
  yield takeLatest(actions.loadClients.success, autoSelectHandler);
}

function* autoSelectHandler() {
  const selectedProject: Project = yield select(getSelectedProject);
  const selectedClient = getClientForProject(selectedProject.slug);
  if (selectedClient !== -1) {
    yield put(actions.setActiveClient(selectedClient));
  }
}

export default function* billingSagas() {
  yield fork(loadClients);
  yield fork(loadActiveClientPerson);
  yield fork(submitClient);
  yield fork(submitManager);
  yield fork(submitPerson);
  yield fork(autoselectClient);
  yield fork(autosaveClient);
}
