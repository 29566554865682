import {
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";
import { fork } from "redux-saga/effects";
import apiService, { ApiResponse } from "../../services/api";
import { parseEntity, parseEntityToBackend } from "../../types/parsers/EntityParser";
import { CreateEntityRequest, CreateEntityResponse, DeleteEntityRequest, GetCustomExtractorsRequest, GetCustomExtractorsResponse, GetEntitiesRequest, GetEntitiesResponse, UpdateEntityRequest, UpdateEntityResponse } from "../../types/requests/Entities";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import i18n from "../../services/i18n";
import { ErrorAction } from "../reducers";

import * as actions from './actions';

function* loadEntities(): SagaIterator {
  yield takeLatest(actions.loadEntities.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: GetEntitiesRequest = {
        ...general,
      };

      const response: ApiResponse<GetEntitiesResponse> = yield call(apiService.getEntities, params);

      const parsedEntities = response.data.entities.map(entity => parseEntity(entity));
      yield put(actions.loadEntities.success(parsedEntities));
    } catch (err) {
      yield put(actions.loadEntities.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.ENTITIES.MULTIPLE") }))));
    }
  });
}

function* createEntity(): SagaIterator {
  yield takeLatest(actions.createEntity.request, function* (action) {
    const entity = action.payload;
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: CreateEntityRequest = {
        queryParams: { ...general },
        body: parseEntityToBackend(entity),
      };

      const response: ApiResponse<CreateEntityResponse> = yield call(apiService.createEntity, params);

      const parsedEntity = parseEntity(response.data);
      yield put(actions.createEntity.success(parsedEntity));
      yield put(actions.removeEntityById(entity.id)); // Remove local entity
    } catch (err) {
      yield put(actions.createEntity.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.ENTITIES.SINGLE") }),
        { ...entity },
      )));
    }
  });
}

function* updateEntity(): SagaIterator {
  yield takeLatest(actions.updateEntity.request, function* (action) {
    const entity = action.payload;
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: UpdateEntityRequest = {
        queryParams: { entity_id: entity.id, ...general },
        body: parseEntityToBackend(entity),
      };

      const response: ApiResponse<UpdateEntityResponse> = yield call(apiService.updateEntity, params);

      const parsedEntity = parseEntity(response.data);
      yield put(actions.updateEntity.success(parsedEntity));
    } catch (err) {
      yield put(actions.updateEntity.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.ENTITIES.SINGLE") }),
        { ...entity },
      )));
    }
  });
}

function* deleteEntity(): SagaIterator {
  yield takeLatest(actions.deleteEntity.request, function* (action) {
    const entity = action.payload;
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: DeleteEntityRequest = {
        queryParams: { entity_id: entity.id, ...general },
      };

      yield call(apiService.deleteEntity, params);

      yield put(actions.deleteEntity.success(entity));
    } catch (err) {
      yield put(actions.deleteEntity.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.ENTITIES.SINGLE") }),
        { ...entity },
      )));
    }
  });
}

function* loadCustomExtractors(): SagaIterator {
  yield takeLatest(actions.loadCustomExtractors.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: GetCustomExtractorsRequest = {
        ...general,
      };

      const response: ApiResponse<GetCustomExtractorsResponse> = yield call(apiService.getCustomExtractors, params);

      yield put(actions.loadCustomExtractors.success(response.data.extractors));
    } catch (err) {
      yield put(actions.loadCustomExtractors.failure(new ErrorAction(err, i18n.t("ERRORS.API.ENTITIES.LOAD_SPEC_VALUES"))));
    }
  });
}

export default function* entitiesSagas(): SagaIterator {
  yield fork(loadEntities);
  yield fork(createEntity);
  yield fork(updateEntity);
  yield fork(deleteEntity);
  yield fork(loadCustomExtractors);
}
