import { Task } from "../../types/models/Task";
import { AppState } from "../reducers";

export function getOverviewTasks(state: AppState) {
  return state.tasks.accountOverview;
}

export function getVoxExportsTasks(state: AppState) {
  return state.tasks.vox;
}

export function getGroupedCallsTasks(state: AppState) {
  return state.tasks.callsGrouped;
}

export function getSeparatedCallsTasks(state: AppState) {
  return state.tasks.callsSeparated;
}

export function getVoiceTasks(state: AppState) {
  return state.tasks.voice;
}

export function getVoiceTaskById(state: AppState, id: Task['id']) {
  return state.tasks.voice.list.find(task => task.id === id);
}

export function getScenarioExamplesUploadTasksLoading({ tasks }: AppState) {
  return tasks.scenarioExamplesUploadings.loading;
}

export function getScenarioExamplesUploadTasks({ tasks }: AppState) {
  return tasks.scenarioExamplesUploadings.list;
}

export function getScenarioExamplesUploadData({ tasks }: AppState) {
  return tasks.scenarioExamplesUploadings;
}

export function getScenarioExamplesUploadTaskById({ tasks }: AppState, id: Task['id']) {
  return tasks.scenarioExamplesUploadings.list.find(task => task.id === id);
}

export function getCustomModelLearningTasksLoading({ tasks }: AppState) {
  return tasks.customModelLearning.loading;
}

export function getCustomModelLearningTasks({ tasks }: AppState) {
  return tasks.customModelLearning.list;
}

export function getCustomModelLearningTotal({ tasks }: AppState) {
  return tasks.customModelLearning.total;
}

export function getClusteringLaunchTasksTasksLoading({ tasks }: AppState) {
  return tasks.clusteringLaunchTasks.loading;
}

export function getClusteringLaunchTasksTasks({ tasks }: AppState) {
  return tasks.clusteringLaunchTasks.list;
}

export function getClusteringLaunchTasksTotal({ tasks }: AppState) {
  return tasks.clusteringLaunchTasks.total;
}

export function getExportExamplesTasks({ tasks }: AppState) {
  return tasks.exportExamplesTasks;
}

export function getImportExamplesTasks({ tasks }: AppState) {
  return tasks.importExport;
}

export function getProcessExamplesTasks({ tasks }: AppState) {
  return tasks.processExamplesTasks;
}

export function getAllTasks({ tasks }: AppState) {
  return tasks.allTasks;
}

export function getTaskMeta(
  { tasks }: AppState,
  taskId: string,
) {
  return tasks.tasksMeta[taskId] || {
    loaded: false,
    loading: false,
    logs: [],
  };
}
