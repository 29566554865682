import i18n from '../../services/i18n';
import { DialogsHistorySearchFilter } from './Dialog';

export type Sampling = {
  slug: string;
  name: string;
  projectId: string;
  projectSlug?: string;
  quantity: number;
  percent: number;
  marked: boolean;
  markedPercent: number;
  type?: SamplingType;
  statistics?: SamplingStatistics;
  createdAt?: string;
}

export type NewSampling = {
  projectSlug?: string;
  id: string;
  title: string;
  filters: DialogsHistorySearchFilter;
  createdAt?: string;
  maxSize?: number;
}

export type SamplingStatistics = {
  totalCount?: number;
  markedCount?: number;
  okCount?: number;
  errorCount: number;
  integrationErrorCount?: number;
  modelErrorCount?: number;
  predicateErrorCount?: number;
  otherErrorCount?: number;
}

export type SamplingsStatistics = {
  markedCount: number;
  okCount: number;
  plotItems: SamplingStatisticsPlotItem[];
}

export type SamplingStatisticsPlotItem = {
  datetime: string;
  markedCount: number;
  okCount: number;
}

export enum SamplingType {
  auto = 'auto',
  general = 'general'
}

export enum ResolutionStatus {
  ok = 'ok',
  integrationError = 'integration_error',
  modelError = 'model_error',
  predicateError = 'predicate_error',
  otherError = 'other_error',
}

export function isAutomaticSampling(
  sampling: NewSampling,
): boolean {
  const automaticTitles = i18n.languages.map(lang => (
    i18n.t("NEW_PAGE_DIALOGS_HISTORY.FILTERS.AUTOMATIC_SAMPLING", { lng: lang })
  ));

  return automaticTitles.includes(sampling.title);
}
