import { Reducer } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import { OutgoingCall } from '../../types/models/OutgoingCall';

import { Task, TaskType } from '../../types/models/Task';
import * as actions from './actions';
import * as taskActions from '../tasks/actions';
import { isLastChunk } from '../tasks/reducer';

export const CALLS_CHUNK_SIZE: number = 30;

type CallDetailsState = {
  list: {
    loading: boolean,
    value: OutgoingCall[],
    totalCount: number, // Total number of calls. Needed for pagination.
                        // TODO Check maybe it's better to move entire pagination to redux state
  },
  tasks: {
    loading: boolean,
    value: Task[],
    total: number,
  },
}

const initialState: CallDetailsState = {
  list: {
    loading: false,
    value: [],
    totalCount: 0,
  },
  tasks: {
    loading: false,
    value: [],
    total: 0,
  },
};

export const callsDetailsReducer: Reducer<CallDetailsState> = createReducer<CallDetailsState>(initialState)
  .handleAction(
    actions.loadCallResult.request,
    (state: CallDetailsState, { payload }: ActionType<typeof actions.loadCallResult.request>): CallDetailsState => {
      const stateWithUpdatedCall = updateCall(state, payload.callId, call => ({
        ...call,
        result: {
          value: undefined,
          loading: true,
        },
      }));
      return stateWithUpdatedCall;
    },
  )
  .handleAction(
    actions.loadCallResult.success,
    (state: CallDetailsState, { payload }: ActionType<typeof actions.loadCallResult.success>): CallDetailsState => {
      const stateWithUpdatedCall = updateCall(state, payload.callId, call => ({
        ...call,
        result: {
          value: payload.result,
          loading: false,
        },
      }));
      return stateWithUpdatedCall;
    },
  )
  .handleAction(
    actions.loadCallResult.failure,
    (
      state: CallDetailsState,
      { payload: error }: ActionType<typeof actions.loadCallResult.failure>,
    ): CallDetailsState => {
      const stateWithUpdatedCall = updateCall(state, error.payload.callId, call => ({
        ...call,
        result: {
          value: undefined,
          loading: false,
        },
      }));
      return stateWithUpdatedCall;
    },
  )
  .handleAction(
    actions.loadTaskCalls.request,
    (state: CallDetailsState): CallDetailsState => ({
      ...state,
      list: {
        ...state.list,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadTaskCalls.success,
    (state: CallDetailsState, { payload }: ActionType<typeof actions.loadTaskCalls.success>): CallDetailsState => ({
      ...state,
      list: {
        ...state.list,
        value: payload.addChunk ?
          [...state.list.value, ...payload.calls] :
          payload.calls,
        totalCount: payload.totalCount,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadTaskCalls.failure,
    (state: CallDetailsState): CallDetailsState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCallExports.request,
    (
      state: CallDetailsState,
      { payload: { olderThan } }: ActionType<typeof actions.loadCallExports.request>,
    ): CallDetailsState => ({
      ...state,
      tasks: {
        ...initialState.tasks,
        value: olderThan ? [...state.tasks.value] : [],
        loading: true,
        total: olderThan ? state.tasks.total : 0,
      },
    }),
  )
  .handleAction(
    actions.loadCallExports.success,
    (
      state: CallDetailsState,
      { payload: { tasks, pagination } }: ActionType<typeof actions.loadCallExports.success>,
    ): CallDetailsState => {
      const currentTasks = pagination?.olderThan ? [...state.tasks.value, ...tasks] : tasks;
      const sortedTasks = currentTasks.sort((taskA, taskB) => +new Date(taskB.created) - +new Date(taskA.created));
      const noMoreTasks = isLastChunk(pagination, tasks);
      return {
        ...state,
        tasks: {
          ...state.tasks,
          value: sortedTasks,
          loading: false,
          total: noMoreTasks ? sortedTasks.length : state.tasks.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadCallExports.failure,
    (state: CallDetailsState): CallDetailsState => ({
      ...state,
      tasks: {
        ...state.tasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    taskActions.createImportExportTask.success,
    (
      state: CallDetailsState,
      { payload }: ActionType<typeof taskActions.createImportExportTask.success>,
    ): CallDetailsState => {
      let callTasks = [...state.tasks.value];

      if (payload.type === TaskType.callResults) {
        callTasks = [payload, ...callTasks];
      }

      return {
        ...state,
        tasks: {
          ...state.tasks,
          value: callTasks,
        },
      };
    },
  );

function updateCall(
  state: CallDetailsState,
  callId: string,
  callback: (call: OutgoingCall) => OutgoingCall,
): CallDetailsState {
  return {
    ...state,
    list: {
      ...state.list,
      value: (state.list.value || []).map(call => (call.id === callId ? callback(call) : call)),
    },
  };
}
