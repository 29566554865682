import { DispatcherParams, isVoiceLanguageValid, IVRDispatcherParams, ProjectVoice, SpeechKit, Voice, VoiceLanguage, VoximplantDispatcherParams, YaTelephonyDispatcherParams } from "../models/ProjectVoice";
import { DispatcherParamsBackend, IVRDispatcherParamsBackend, ProjectVoiceConfigBackend, SpeechKitBackend, VoiceBackend, VoiceLanguageBackend, VoximplantDispatcherParamsBackend, YaTelephonyDispatcherParamsBackend } from "../backendModels/ProjectVoiceBackend";
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import { generateId } from "../../services/helpers/generateId";
import { VoicePerson, VoiceRole } from "../../redux/projectVoiceConfig/helpers";
import { CallService } from "../models/ProjectConfig";

export function parseProjectVoice(value: ProjectVoiceConfigBackend): ProjectVoice {
  return {
    dispatcherParams: objSnakeToCamelDeep(value.dispatcher_params),
    speechKit: parseSpeechKit(value.speech_kit),
    separateCallsScheduleSettings: objSnakeToCamelDeep(value.separate_calls_schedule_settings),
  };
}

export function parseSpeechKit({
  default_tts_language: defaultTtsLanguage,
  voices,
  ...rest
}: SpeechKitBackend): SpeechKit {
  let voicesCopy = voices;
  const voicesIncludesDefault = voices.languages.some(l => l.language === defaultTtsLanguage);

  if (!voicesIncludesDefault) {
    voicesCopy.languages.push({
      language: defaultTtsLanguage as string,
      voice: {
        ...voices.default,
      },
    });
  }

  return {
    ...objSnakeToCamelDeep(rest),
    defaultTtsLanguage: defaultTtsLanguage as string,
    voices: [
      ...voices.languages.map(l => ({
        ...parseVoiceLanguage(l),
        id: generateId(),
      })),
    ],
  };
}

export function parseVoiceLanguage(value: VoiceLanguageBackend): VoiceLanguage {
  return {
    id: generateId(),
    ...value,
    voice: parseVoice(value.voice),
  };
}

export function parseVoice(value: VoiceBackend): Voice {
  return {
    name: value.name as VoicePerson,
    role: value.role as VoiceRole,
    speed: value.speed,
  };
}

export function parseProjectVoiceToBackend(value: ProjectVoice): ProjectVoiceConfigBackend {
  return {
    dispatcher_params: parseDispatcherParamsToBackend(value.dispatcherParams),
    speech_kit: parseSpeeckKitToBackend(value.speechKit),
    separate_calls_schedule_settings: objCamelToSnakeDeep(value.separateCallsScheduleSettings),
  };
}

export function parseDispatcherParamsToBackend(value: DispatcherParams): DispatcherParamsBackend {
  switch (value.callService) {
    case CallService.voximplant:
      return parseVoximplantDispatcherParamstoBackend(value as VoximplantDispatcherParams);
    case CallService.ya:
      return parseYaDispatcherParamsToBackend(value as YaTelephonyDispatcherParams);
    case CallService.ivr:
    default:
      return parseIVRDispatcherParamsToBackend(value as IVRDispatcherParams);
  }
}

export function parseVoximplantDispatcherParamstoBackend(
  value: VoximplantDispatcherParams,
): VoximplantDispatcherParamsBackend {
  return {
    call_service: CallService.voximplant,
    account_id: Number(value.accountId),
    api_key: value.apiKey,
    rule_id: value.ruleId,
    priority: value.priority,
    max_simultaneous: value.maxSimultaneous,
    num_attempts: value.numAttempts,
  };
}

export function parseIVRDispatcherParamsToBackend(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: IVRDispatcherParams,
): IVRDispatcherParamsBackend {
  return {
    call_service: CallService.ivr,
  };
}

export function parseYaDispatcherParamsToBackend(
  value: YaTelephonyDispatcherParams,
): YaTelephonyDispatcherParamsBackend {
  return {
    call_service: CallService.ya,
    speech_silence_timeout_ms: +value.speechSilenceTimeoutMs,
    store_records: !!value.storeRecords,
    enable_dtmf: !!value.enableDtmf,
    record_operator: !value.storeRecords ?
      false :
      !!value.recordOperator,
    fallback_operator: value.fallbackOperator,
    call_processing_start_pause_ms: +value.callProcessingStartPauseMs,
  };
}

export function parseSpeeckKitToBackend({
  voices: originVoices,
  defaultTtsLanguage,
  ...rest
}: SpeechKit): SpeechKitBackend {
  const voices = [...originVoices].filter(isVoiceLanguageValid).map(v => {
    if (!v.voice.role) {
      delete v.voice.role;
    }
    return v;
  });
  const defaultVoiceIndex = voices.findIndex(v => v.language === defaultTtsLanguage);

  if (defaultVoiceIndex === -1) throw new Error("Invalid voice");

  const defaultVoice = { ...voices[defaultVoiceIndex] };

  const sstLanguages = voices.map(v => v.language);

  return {
    ...objCamelToSnakeDeep(rest),
    default_tts_language: defaultTtsLanguage,
    stt_languages: sstLanguages,
    voices: {
      default: parseVoiceToBackend(defaultVoice.voice),
      languages: voices.map(parseVoiceLanguageToBackend),
    },
  };
}

export function parseVoiceLanguageToBackend({
  language,
  voice,
}: VoiceLanguage): VoiceLanguageBackend {
  return {
    language,
    voice: parseVoiceToBackend(voice),
  };
}

export function parseVoiceToBackend(value: Voice): VoiceBackend {
  return {
    name: value.name || "",
    role: value.role || "",
    speed: value.speed ? Number(value.speed) : undefined,
  };
}
