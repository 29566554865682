/* eslint-disable no-case-declarations */
import { startOfDay, startOfMonth, endOfDay, endOfMonth, subDays, subMonths } from 'date-fns';
import { StatisticsPeriod } from "../../types/models/StatisticsPeriod";

const PERIOD_MS_DAY = 86400000;

export function getPeriodRange(period: StatisticsPeriod): number {
  switch (period) {
    case StatisticsPeriod.Hour:
      return PERIOD_MS_DAY * 2;
    case StatisticsPeriod.Day:
      return PERIOD_MS_DAY * 62;
    case StatisticsPeriod.Month:
      return PERIOD_MS_DAY * 10000;
    default:
      return 0;
  }
}

export function getCalendarFormat(period: StatisticsPeriod): string {
  switch (period) {
    case StatisticsPeriod.Month:
      return 'MMM yyyy';
    case StatisticsPeriod.Day:
    case StatisticsPeriod.Hour:
      return 'DD.MM.yyyy';
    default:
      return '';
  }
}

export function getCalendarViews(period: StatisticsPeriod): ("year" | "month" | "date")[] {
  const result: ("year" | "month" | "date")[] = ["year", "month"];
  switch (period) {
    case StatisticsPeriod.Day:
    case StatisticsPeriod.Hour:
      return result.concat(['date']);
    default:
      return result;
  }
}

export function isDateFuture(date: Date) {
  return +date > +(new Date());
}

export function floorDate(date: Date, period: StatisticsPeriod): Date {
  switch (period) {
    case StatisticsPeriod.Day:
    case StatisticsPeriod.Hour:
      return startOfDay(date);
    case StatisticsPeriod.Month:
      return startOfMonth(date);
    default:
      return date;
  }
}

export function ceilDate(date: Date, period: StatisticsPeriod): Date {
  switch (period) {
    case StatisticsPeriod.Hour:
    case StatisticsPeriod.Day:
      return endOfDay(date);
    case StatisticsPeriod.Month:
      return endOfMonth(date);
    default:
      return date;
  }
}

export function getDefaultDate(period: StatisticsPeriod): [Date, Date] {
  let now = ceilDate(new Date(), period);
  let dateFrom = new Date(ceilDate(new Date(), period));

  switch (period) {
    case StatisticsPeriod.Hour:
      dateFrom = subDays(dateFrom, 3);
      break;
    case StatisticsPeriod.Day:
      dateFrom = subDays(dateFrom, 7);
      break;
    case StatisticsPeriod.Week:
      dateFrom = subDays(dateFrom, 14);
      break;
    case StatisticsPeriod.Month:
      dateFrom = subMonths(dateFrom, 3);
      break;
    default:
      // Nothing to do
      break;
  }

  return [floorDate(dateFrom, period), now];
}

export function msToSeconds(milliseconds: number): number {
  return Math.round(milliseconds / 1000);
}
