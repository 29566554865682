import { createReducer, ActionType } from "typesafe-actions";
import { Reducer } from "redux";

import * as actions from './actions';
import { Line } from "../../types/models/Line";

type LinesState = {
  list: Line[],
  loading: boolean,
}

const initialState: LinesState = {
  list: [],
  loading: false,
};

export const linesReducer: Reducer<LinesState> = createReducer(initialState)
  .handleAction(
    actions.loadLines.request,
    (state: LinesState): LinesState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadLines.success,
    (state: LinesState, { payload }: ActionType<typeof actions.loadLines.success>): LinesState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadLines.failure,
    (state: LinesState): LinesState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createLine.request,
    (state: LinesState): LinesState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.createLine.success,
    (state: LinesState, { payload }: ActionType<typeof actions.createLine.success>): LinesState => ({
      ...state,
      list: [...state.list, payload],
      loading: false,
    }),
  )
  .handleAction(
    actions.createLine.failure,
    (state: LinesState): LinesState => ({
      ...state,
      loading: false,
    }),
  );
