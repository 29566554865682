import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { DebugStep, DebugWindow } from "./types";
import { Node } from '../../pages/graphEditor/types';

import * as actions from './actions';
import * as graphChatActions from '../graphChat/actions';
import * as dialogsActions from '../dialogs/actions';
import { generateId } from "../../services/helpers/generateId";
import { GraphDebugBlock } from "../../types/models/GraphDebugBlock";
import { deepClone } from "../../services/helpers/utilities";

type GraphDebugState = {
  debugSteps: Record<Node['id'] | "entry", DebugStep | DebugStep[]>,
  debugWindows: DebugWindow[],
  debugBlocks: GraphDebugBlock[],
  debugId: string,
  loading: boolean;
}

const initialState: GraphDebugState = {
  debugSteps: {},
  debugWindows: [],
  debugBlocks: [],
  debugId: '',
  loading: false,
};

export const graphDebugReducer: Reducer<GraphDebugState> = createReducer<GraphDebugState>(
  initialState,
)
  .handleAction(
    actions.createDebugStep,
    (
      state: GraphDebugState,
      { payload }: ActionType<typeof actions.createDebugStep>,
    ): GraphDebugState => {
      const { nodeId } = payload;

      let result: DebugStep | DebugStep[] = payload;
      const currentNodeSteps = state.debugSteps[nodeId];

      if (!currentNodeSteps) {
        result = payload;
      } else if (Array.isArray(currentNodeSteps)) {
        result = [...currentNodeSteps, payload];
      } else {
        result = [currentNodeSteps, payload];
      }

      return {
        ...state,
        debugSteps: {
          ...state.debugSteps,
          [nodeId || "entry"]: result,
        },
      };
    },
  )
  .handleAction(
    actions.removeDebugSteps,
    (state: GraphDebugState): GraphDebugState => ({
      ...state,
      debugSteps: { ...initialState.debugSteps },
    }),
  )
  .handleAction(
    actions.createDebugWindow,
    (
      state: GraphDebugState,
      { payload }: ActionType<typeof actions.createDebugWindow>,
    ): GraphDebugState => {
      const windows = [...state.debugWindows];
      const {
        debugId,
        title,
        x,
        y,
      } = payload;

      const windowAlreadyExist = !!windows.find(w => w.debugId === debugId);
      if (!windowAlreadyExist) {
        windows.push({
          id: generateId(),
          debugId,
          title,
          initialX: x,
          initialY: y,
        });
      }

      return {
        ...state,
        debugWindows: windows,
      };
    },
  )
  .handleAction(
    actions.closeDebugWindow,
    (
      state: GraphDebugState,
      { payload: windowId }: ActionType<typeof actions.closeDebugWindow>,
    ): GraphDebugState => {
      let windows = [...state.debugWindows];

      const windowExist = windows.some(w => w.id === windowId);
      if (windowExist) {
        windows = windows.filter(w => w.id !== windowId);
      }

      return {
        ...state,
        debugWindows: windows,
      };
    },
  )
  .handleAction(
    actions.closeAllDebugWindows,
    (state: GraphDebugState): GraphDebugState => ({
      ...state,
      debugWindows: [...initialState.debugWindows],
    }),
  )
  .handleAction(
    actions.focusDebugWindow,
    (
      state: GraphDebugState,
      { payload: debugWindowId }: ActionType<typeof actions.focusDebugWindow>,
    ): GraphDebugState => {
      const windowIndex = state.debugWindows.findIndex(w => w.id === debugWindowId);

      if (windowIndex === -1) return state;

      const listCopy = deepClone(state.debugWindows);

      const focusedWindow = listCopy[windowIndex];
      listCopy.splice(windowIndex, 1);
      const newList = [...listCopy, focusedWindow];

      return {
        ...state,
        debugWindows: newList,
      };
    },
  )
  .handleAction(
    actions.loadDebugById.request,
    (
      state: GraphDebugState,
    ): GraphDebugState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadDebugById.success,
    (
      state: GraphDebugState,
      { payload: { debugBlocks, debugId } }: ActionType<typeof actions.loadDebugById.success>,
    ): GraphDebugState => ({
      ...state,
      loading: false,
      debugBlocks,
      debugId,
    }),
  )
  .handleAction(
    actions.loadDebugById.failure,
    (
      state: GraphDebugState,
    ): GraphDebugState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.resetAllDebugInfo,
    (): GraphDebugState => ({
      ...initialState,
    }),
  )
  .handleAction(
    graphChatActions.closeGraphTestingChat,
    (): GraphDebugState => ({
      ...initialState,
    }),
  )
  .handleAction(
    dialogsActions.clearLiveChat,
    (): GraphDebugState => ({
      ...initialState,
    }),
  );
