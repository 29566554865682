import React, { ReactElement } from 'react';
import { Route, RouteProps } from 'react-router';
import AuthService from '../auth';
import NavigationService from ".";
import unauthorizedRoutes from '../../routes/unauthorized';

const PrivateRoute = (props: RouteProps): ReactElement | null => {
  let isAuth = AuthService.isAuthorized();
  if (!isAuth) {
    NavigationService.navigateTo(unauthorizedRoutes.root);
    return null;
  }
  const { children, ...rest } = props;
  return (
    <Route {...rest}>{children}</Route>
  );
};

export default PrivateRoute;
