import { SagaIterator } from "redux-saga";
import {
  all,
  call, CallEffect, delay, fork, put, select, takeLatest,
} from "redux-saga/effects";

import NotificationManager from "../../services/notifications";
import i18n from "../../services/i18n";

import * as actions from './actions';
import * as graphActions from '../graph/actions';
import * as dialogActions from '../dialogs/actions';

import { ApiResponse } from '../../services/api';
import { objCamelToSnakeDeep, objSnakeToCamelDeep, onlyUnique } from "../../services/helpers/utilities";
import apiService from "../../services/api";
import { generateId } from "../../services/helpers/generateId";
import NotificationService from "../../services/notifications";

import { getActiveGraph } from "../graph/selectors";
import { ScenarioGraphsItem } from "../scenarioGraphs/types";

import { ScenarioGraphExample } from "../../types/models/ScenarioGraphExample";
import { ScenarioGraph } from "../../types/models/ScenarioGraph";
import {
  GetTrashPhrasesRequest,
  GetTrashPhrasesResponse,
  DeleteExamplesRequest,
  ToggleExampleReferenceStatusRequest,
  ToggleExampleReferenceStatusResponse,
} from "../../types/requests/ScenarioGraphs";

import { ErrorAction } from "../reducers";
import { getScenarioGraphs, getScenarioById } from "../scenarioGraphs/selectors";
import { CreateMarkupRequest, CreateMarkupResponse, ExamplesByScenario, GetAggregatedMarkupResponse, GetMarkupScenariosRequest, GetMarkupScenariosResponse, MarkupItem } from "../../types/requests/Markup";
import { User } from "../../types/models/User";
import { getCurrentUser } from "../user/selectors";
import { GetTasksRequest, GetTasksResponse } from "../../types/requests/Task";
import { TaskStatus, TaskType } from "../../types/models/Task";
import { parseTask } from "../../types/parsers/TaskParser";
import modalService from "../../services/modal";

function* checkAndUpdateTopicToEditByDialog(): SagaIterator {
  yield takeLatest(dialogActions.sendLiveMessage.success, function* ({ payload }) {
    const newGraphId = payload.current_graph_id;
    const activeGraph: ScenarioGraph = yield select(getActiveGraph);

    if (newGraphId === activeGraph.id || !newGraphId) return;

    yield put(graphActions.loadGraphById.request(newGraphId));
  });
}

function* loadScenarioGraphExamples(): SagaIterator {
  yield takeLatest(actions.loadScenarioGraphExamples.request, function* ({ payload }) {
    try {
      const { scenarioId, searchValue } = payload;

      const scenario: ScenarioGraphsItem | undefined = yield select(getScenarioById, scenarioId);

      const params: GetMarkupScenariosRequest = {
        scenario_slug: scenario?.slug || '',
        search_text: searchValue,
        ...payload,
      };

      const response: ApiResponse<GetMarkupScenariosResponse> = yield call(
        apiService.getScenarioGraphExamples,
        params,
      );
      const data = response.data.items;

      yield put(actions.loadScenarioGraphExamples.success({
        scenarioId: payload.scenarioId,
        examples: data.map<ScenarioGraphExample>(objSnakeToCamelDeep),
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.EXAMPLES.MULTIPLE") });
      yield put(actions.loadScenarioGraphExamples.failure(new ErrorAction(err, text)));
    }
  });
}

function* createScenarioGraphExample(): SagaIterator {
  yield takeLatest(actions.createScenarioGraphExample.request, function* ({ payload }) {
    try {
      const { scenarioId, text, type, modalId } = payload;

      const scenario: ScenarioGraphsItem | undefined = yield select(getScenarioById, scenarioId);
      const user: User = yield select(getCurrentUser);

      if (!scenario) throw new Error();

      const example: MarkupItem = {
        id: generateId(),
        text,
        type,
        user_id: user.id || '',
        supportai_request_id: null,
      };

      const params: CreateMarkupRequest = {
        query: {
          scenario_slug: scenario?.slug || '',
        },
        body: objCamelToSnakeDeep(example),
      };

      const response: ApiResponse<CreateMarkupResponse> = yield call(
        apiService.createScenarioGraphExample,
        params,
      );

      yield put(actions.createScenarioGraphExample.success({
        scenarioId,
        example: objSnakeToCamelDeep(response.data),
      }));
      NotificationManager.info(i18n.t('MODALS.ADD_EXAMPLE.NOTIFICATION_SUCCESS'));

      if (modalId) {
        modalService.close(modalId);
      }
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.EXAMPLES.SINGLE") });
      yield put(actions.createScenarioGraphExample.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteScenarioGraphExample(): SagaIterator {
  yield takeLatest(actions.deleteScenarioGraphExample.request, function* ({ payload }) {
    try {
      const { scenarioId, exampleId } = payload;

      yield call(apiService.deleteScenarioGraphExample, { id: exampleId });

      yield put(actions.deleteScenarioGraphExample.success({
        scenarioId,
        exampleId,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.EXAMPLES.SINGLE") });
      yield put(actions.deleteScenarioGraphExample.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadTrashPhrases(): SagaIterator {
  yield takeLatest(actions.loadTrashPhrases.request, function* (action) {
    try {
      const { offset } = action.payload;
      const request: GetTrashPhrasesRequest = {
        offset,
      };

      const response: ApiResponse<GetTrashPhrasesResponse> = yield call(
        apiService.getTrashPhrases,
        request,
      );

      yield put(actions.loadTrashPhrases.success({
        total: response.data.total,
        phrases: response.data.phrases.map(p => ({ phrase: p, id: generateId() })),
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SAVE", { name: i18n.t("ERRORS.API.TRAININGS_DATA") });
      yield put(actions.loadTrashPhrases.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadAllExamples() {
  yield takeLatest(actions.loadAllExamples.request, function* () {
    try {
      const response: ApiResponse<GetAggregatedMarkupResponse> = yield call(apiService.getProjectExamples);

      type ScenarioExamples = Record<ScenarioGraphsItem['id'], { totalAddition: number, totalException: number }>;

      const scenarios: ScenarioGraphsItem[] = yield select(getScenarioGraphs);

      const mapper = (examples: ExamplesByScenario[]) => (
        examples.reduce<Record<ScenarioGraphsItem['id'], number>>((result, example) => {
          // eslint-disable-next-line no-param-reassign
          const scenario = scenarios.find(s => s.slug === example.scenario_slug);

          if (scenario) {
            result[scenario.id] = example.total;
          }

          return result;
        }, {})
      );

      const additionExamples = mapper(response.data.addition_markup.by_scenarios);
      const exceptionExamples = mapper(response.data.exception_markup.by_scenarios);

      const scenarioSlugs = [
        ...Object.keys(additionExamples),
        ...Object.keys(exceptionExamples),
      ].filter(onlyUnique);

      const totalExamples = scenarioSlugs
        .reduce<ScenarioExamples>((result, slug) => {
          // eslint-disable-next-line no-param-reassign
          result[slug] = {
            totalAddition: additionExamples[slug] || 0,
            totalException: exceptionExamples[slug] || 0,
          };

          return result;
        }, {});

      yield put(actions.loadAllExamples.success(totalExamples));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.EXAMPLES.MULTIPLE") });
      yield put(actions.loadAllExamples.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteExamples(): SagaIterator {
  yield takeLatest(actions.deleteExamples.request, function* ({ payload }) {
    try {
      const { type, selectedExamples, scenarioId } = payload;

      if (type === "selected") {
        if (!selectedExamples) {
          yield put(actions.deleteExamples.failure(payload));
          return;
        }

        // eslint-disable-next-line camelcase
        const deleteAction = (exampleId: string, index: number) => {
          return call(function* () {
            yield delay(300 * index);
            yield call(apiService.deleteScenarioGraphExample, { id: exampleId });
          });
        };

        const requests = Object.keys(selectedExamples).reduce(
          (result, scenarioIdKey) => {
            const examples = selectedExamples[scenarioIdKey];

            return result.concat(
              ...Object.keys(examples)
                .filter(id => !!examples[id])
                .map((exampleId, i) => (
                  deleteAction(exampleId, i)
                )),
            );
          },
          [] as CallEffect<void>[],
        );

        yield all(requests);
        NotificationService.success(i18n.t("SCENARIO_GRAPHS.EXAMPLES_MODAL.DELETE_EXAMPLES_MSG"));
        yield put(actions.deleteExamples.success({ type, selectedExamples, scenarioId }));
        yield put(actions.loadAllExamples.request());
        return;
      }

      if (type === "all") {
        const allScenarios: ScenarioGraphsItem[] = yield select(getScenarioGraphs);
        const params: DeleteExamplesRequest = {
          body: {
            scenarios_slugs: allScenarios.map(scenario => scenario.slug),
          },
        };

        yield call(apiService.deleteMarkupExamples, params);
        NotificationService.success(i18n.t("SCENARIO_GRAPHS.EXAMPLES_MODAL.DELETE_EXAMPLES_MSG"));
        yield put(actions.deleteExamples.success({ type }));
        return;
      }

      const scenario: ScenarioGraphsItem = yield select(getScenarioById, scenarioId || '');

      const params: DeleteExamplesRequest = {
        body: {
          scenarios_slugs: [scenario.slug],
        },
      };

      yield call(apiService.deleteMarkupExamples, params);
      yield put(actions.deleteExamples.success({ type, selectedExamples, scenarioId }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.EXAMPLES.MULTIPLE") });
      NotificationManager.error(text);
      yield put(actions.deleteExamples.failure(payload));
    }
  });
}

function* toggleExampleReferenceStatus() {
  yield takeLatest(actions.toggleExampleReferenceStatus.request, function* (action) {
    try {
      const {
        scenarioId,
        exampleId,
      } = action.payload;

      const user = getCurrentUser();

      const request: ToggleExampleReferenceStatusRequest = {
        path: {
          scenario_id: scenarioId,
          example_id: exampleId,
        },
        params: {
          user_id: user.id || '',
        },
      };

      const response: ApiResponse<ToggleExampleReferenceStatusResponse> = yield call(
        apiService.toggleExampleReferenceStatus,
        request,
      );

      yield put(actions.toggleExampleReferenceStatus.success({
        scenarioId,
        exmaple: objSnakeToCamelDeep(response.data),
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.EXAMPLES.SINGLE") });
      NotificationManager.error(text);
      const error = new ErrorAction(err, text, {
        exampleId: action.payload.exampleId,
        scenarioId: action.payload.scenarioId,
      });
      yield put(actions.toggleExampleReferenceStatus.failure(error));
    }
  });
}

function* checkDirtyExamplesSearchAvailable(): SagaIterator {
  yield takeLatest(actions.checkDirtyExamplesSearchAvailable.request, function* () {
    try {
      const request: GetTasksRequest = {
        limit: 10,
        types: [TaskType.findLabelIssues],
      };

      const response: ApiResponse<GetTasksResponse> = yield call(apiService.getTasks, request);

      const tasks = response.data.tasks.map(parseTask);
      const processing = tasks.some(task => task.status === TaskStatus.processing);
      yield put(actions.checkDirtyExamplesSearchAvailable.success(!processing));
    } catch (err) {
      const text = i18n.t("ERROS.API.TASKS.LOAD");
      yield put(actions.checkDirtyExamplesSearchAvailable.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* scenariosExamplesSagas(): SagaIterator {
  yield fork(checkAndUpdateTopicToEditByDialog);
  yield fork(loadScenarioGraphExamples);
  yield fork(createScenarioGraphExample);
  yield fork(deleteScenarioGraphExample);
  yield fork(loadTrashPhrases);
  yield fork(loadAllExamples);
  yield fork(deleteExamples);
  yield fork(toggleExampleReferenceStatus);
  yield fork(checkDirtyExamplesSearchAvailable);
}
