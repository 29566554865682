import React from 'react';

import { SvgProps } from '../types';

function BellIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M11.1384 2.16985C11.2726 2.0599 11.4408 1.99987 11.6143 2H12.3843C12.5578 1.99987 12.7259 2.0599 12.8601 2.16985C12.9943 2.2798 13.0863 2.43287 13.1203 2.603L13.2623 3.315C13.3052 3.52959 13.3946 3.73219 13.5242 3.90856C13.6538 4.08494 13.8204 4.23083 14.0123 4.336L14.3123 4.5C15.1932 4.90048 15.9549 5.52292 16.5229 6.30633C17.0908 7.08975 17.4455 8.00726 17.5523 8.969L18.0923 13.834C18.1422 14.2822 18.2815 14.7159 18.5018 15.1094C18.7222 15.5029 19.0192 15.8482 19.3753 16.125L19.7283 16.4C19.9579 16.5784 20.1307 16.8197 20.2257 17.0945C20.3207 17.3694 20.3337 17.6659 20.2633 17.948L20.2493 18C20.1779 18.2855 20.0132 18.539 19.7813 18.7202C19.5494 18.9014 19.2636 18.9999 18.9693 19H14.9995C14.9995 19.7956 14.6834 20.5587 14.1208 21.1213C13.5582 21.6839 12.7952 22 11.9995 22C11.2039 22 10.4408 21.6839 9.87819 21.1213C9.31558 20.5587 8.99951 19.7956 8.99951 19H5.02928C4.42428 19 3.89628 18.588 3.74928 18L3.73628 17.948C3.6657 17.666 3.6786 17.3695 3.77341 17.0947C3.86821 16.8199 4.04083 16.5785 4.27028 16.4L4.62428 16.125C4.98017 15.8481 5.27698 15.5028 5.49717 15.1093C5.71736 14.7158 5.85647 14.2821 5.90628 13.834L6.44628 8.97C6.55284 8.00809 6.90749 7.09036 7.47547 6.30676C8.04345 5.52316 8.80527 4.90057 9.68628 4.5L9.98628 4.335C10.1781 4.22994 10.3446 4.0842 10.4742 3.90801C10.6037 3.73181 10.6932 3.52941 10.7363 3.315L10.8783 2.603C10.9123 2.43287 11.0042 2.2798 11.1384 2.16985Z"
        fill={color}
      />
    </svg>
  );
}

export default BellIcon;
