import React, { useCallback } from 'react';

import { Slot } from '../../types/models/Slots';

import Table from '../../components-new/tableNew';
import { ColumnsConfiguration } from '../../components-new/table/types';

import { getColumns } from './columns';

import './slotsTable.scss';

export type SlotsTableProps = {
  slots: Slot[],
  onUnlockSlotClick: (slot: Slot) => void,
}

export default function SlotsTable({
  slots = [],
  onUnlockSlotClick,
}: SlotsTableProps) {
  const handleUnlockSlotClick = useCallback(
    (e: React.MouseEvent) => {
      const slotId = (e.currentTarget as HTMLButtonElement).name;
      const slot = slots.find(s => s.id === slotId);

      if (!slot) return;

      onUnlockSlotClick(slot);
    },
    [onUnlockSlotClick, slots],
  );

  const columns: ColumnsConfiguration = getColumns({
    onUnlockSlotClick: handleUnlockSlotClick,
  });

  return (
    <Table
      data={slots}
      columns={columns}
    />
  );
}
