import React, { FC, memo, useCallback } from 'react';
import { block } from 'bem-cn';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from '@uiw/react-markdown-preview';
import { format } from 'date-fns';

import { SavedReleaseNote } from '../../../types/models/ReleaseNotes';
import Button from '../../../components-new/button';

type Props = {
  last?: boolean;
  releaseNote: SavedReleaseNote;
  onEdit?: (id: NonNullable<SavedReleaseNote['id']>) => void;
  onExpand?: () => void;
  onClose?: () => void;
};

const b = block('release-notes-item');

const Item: FC<Props> = ({
  last,
  releaseNote,
  onEdit,
  onExpand,
  onClose,
}) => {
  const { t } = useTranslation();

  const {
    id, description, title, timestamp, enabled,
  } = releaseNote;

  const handleEditClick = useCallback(
    () => {
      if (onEdit) {
        onEdit(id);
      }
    },
    [id, onEdit],
  );

  const dateTime = format(new Date(timestamp * 1000), 'dd MMMM yyyy в HH:mm');

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('header-texts')}>
          <h2 className={b('title')}>
            {`${title} ${enabled ? '' : '(draft)'}`}
          </h2>
          <span className="caption-1 text-minor">{dateTime}</span>
        </div>
        {onClose ?
          <Button simple icon="close" onClick={onClose} /> :
          !!onEdit && (
            <Button
              icon="pen"
              simple
              onClick={handleEditClick}
            />
          )}
      </div>
      <div className={b('content', { short: last })}>
        <ReactMarkdown source={description} />
      </div>
      {onExpand && (
        <Button fluid onClick={onExpand}>
          {t('COMMON.SHOW_DETAILS')}
        </Button>
      )}
    </div>
  );
};

export default memo(Item);
