import { ProjectUserBackend } from "../backendModels/ProjectUserBackend";
import { Permission } from "../models/Permission";
import { ProjectUser } from "../models/ProjectUser";

export function parseProjectUser(value: ProjectUserBackend): ProjectUser {
  return {
    permissions: value.permissions as Permission[],
    providerId: value.provider_id,
    isActive: value.is_active,
    login: value.login,
  };
}
