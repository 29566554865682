import React from "react";

import { SvgProps } from '../index';

function NewTabIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5H11V3H9C7.13623 3 6.20435 3 5.46926 3.30448C4.48915 3.71046 3.71046 4.48915 3.30448 5.46927C3 6.20435 3 7.13623 3 9V15C3 16.8638 3 17.7957 3.30448 18.5307C3.71046 19.5108 4.48915 20.2895 5.46926 20.6955C6.20435 21 7.13623 21 9 21H15C16.8638 21 17.7957 21 18.5307 20.6955C19.5108 20.2895 20.2895 19.5108 20.6955 18.5307C21 17.7957 21 16.8638 21 15V13H19V15C19 15.9592 18.9989 16.5785 18.9666 17.0525C18.9355 17.5085 18.8822 17.6821 18.8478 17.7654C18.6448 18.2554 18.2554 18.6448 17.7654 18.8478C17.6821 18.8822 17.5085 18.9355 17.0525 18.9666C16.5785 18.9989 15.9592 19 15 19H9C8.04075 19 7.42148 18.9989 6.94752 18.9666C6.49152 18.9355 6.31786 18.8822 6.23463 18.8478C5.74458 18.6448 5.35523 18.2554 5.15224 17.7654C5.11777 17.6821 5.06453 17.5085 5.03342 17.0525C5.00108 16.5785 5 15.9592 5 15V9C5 8.04075 5.00108 7.42148 5.03342 6.94752C5.06453 6.49152 5.11777 6.31786 5.15224 6.23463C5.35523 5.74458 5.74458 5.35523 6.23463 5.15224C6.31786 5.11777 6.49152 5.06453 6.94752 5.03342C7.42148 5.00108 8.04075 5 9 5Z"
        fill={color}
      />
      <path
        d="M14 3.00067V5.00016L17.5808 4.99982L9.67844 12.9022L11.0926 14.3164L18.9996 6.40947V10H20.9921V3.00269L14 3.00067Z"
        fill={color}
      />
    </svg>
  );
}

export default NewTabIcon;
