import React from 'react';
import { SvgProps } from '../types';

function InfoIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM13.5 15C13.2239 15 13 14.7761 13 14.5V10C13 9.72386 12.7761 9.5 12.5 9.5H10V10.5H10.5C10.7761 10.5 11 10.7239 11 11V14.5C11 14.7761 10.7761 15 10.5 15H10V16H14V15H13.5Z"
        fill={color}
      />
    </svg>
  );
}

export default InfoIcon;
