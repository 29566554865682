import { AppState } from "../reducers";

export const getAllowedDomains = ({ domains }: AppState) => (
  domains.list
);

export const getIsDomainsLoading = ({ domains }: AppState) => (
  domains.loading
);

export const getActiveDomain = ({ domains }: AppState) => (
  domains.list.find(d => d.id === domains.activeDomain)
);
