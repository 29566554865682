import {
  call, fork, put, select, takeLatest,
} from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import NotificationManager from "../../services/notifications";
import apiService, { ApiResponse } from "../../services/api";
import {
  AddProjectModelRequest,
  GetCurrentModelResponse,
  getModelDraftResponse,
  GetModelPlotsRequest,
  GetModelPlotsResponse,
  GetModelTopicsResponse,
  GetProjectModelsResponse,
  UpdateModelTopicRequest,
  UpdateModelTopicResponse,
} from "../../types/requests/ProjectModels";
import { ErrorAction } from "../reducers";
import {
  parseDraft,
  parseModelTopic,
  parseModelTopicToBackend,
  parsePlots,
} from "../../types/parsers/ProjectModelParser";
import { getCurrentModelTopicBySlug, getPlots } from "./selectors";
import { getEmptyModel, ModelTopic, PlotsPoint } from "../../types/models/ProjectModel";
import { findThresholdIndex, objCamelToSnake } from "../../services/helpers/utilities";
import { Metric } from "../../types/models/Metric";
import {
  GetThresholdDialogsRequest,
  GetThresholdDialogsResponse,
} from "../../types/requests/Tresholds";
import { parseDialog } from "../../types/parsers/DialogParser";
import modalService from "../../services/modal";
import {
  CreateCommonModelRequest,
  CreateCommonModelResponse,
  DeleteCommonModelRequest,
  GetCommonModelsResponse,
} from "../../types/requests/CommonModels";
import {
  parseModel,
  parseModelToBackend,
} from "../../types/parsers/CommonModelParser";
import i18n from "../../services/i18n";
import * as actions from './actions';
import { CreateTaskRequest, CreateTaskResponse, GetTasksRequest, GetTasksResponse } from "../../types/requests/Task";
import { TaskType } from "../../types/models/Task";
import { parseTask } from "../../types/parsers/TaskParser";
import { getSelectedProject } from "../projects/selectors";
import { Project } from "../../types/models/Project";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";
import { ModelDraftBackend } from "../../types/backendModels/ProjectModelBackend";

const CHUNK_SIZE = 10;

function* loadModels(): SagaIterator {
  yield takeLatest(actions.loadProjectModels.request, function* () {
    try {
      const response: ApiResponse<GetProjectModelsResponse> = yield call(
        apiService.getProjectModels,
      );
      yield put(actions.loadProjectModels.success(response.data.models));
    } catch (err) {
      yield put(actions.loadProjectModels.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.MODELS.MULTIPLE") }))));
    }
  });
}

function* loadOnlineModel(): SagaIterator {
  yield takeLatest(actions.loadOnlineModelDraft.request, function* () {
    try {
      const response: ApiResponse<getModelDraftResponse> = yield call(
        apiService.getModelDraft,
      );
      yield put(actions.loadOnlineModelDraft.success(parseDraft(response.data)));
    } catch (err) {
      yield put(actions.loadOnlineModelDraft.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.MODELS.SINGLE") }))));
    }
  });
}

function* updateOnlineModel(): SagaIterator {
  yield takeLatest(actions.updateOnlineModelDraft.request, function* (action) {
    try {
      const { threshold, translate, negativeThreshold, isEnabled } = action.payload;
      const params: ModelDraftBackend = {
        threshold,
        negative_threshold: negativeThreshold,
        translate,
        is_enabled: isEnabled,
      };
      yield call(
        apiService.udateModelConfig,
        params,
      );
      yield put(actions.updateOnlineModelDraft.success(action.payload));
    } catch (err) {
      yield put(actions.updateOnlineModelDraft.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.MODELS.SINGLE") }))));
    }
  });
}

function* loadCurrentModel(): SagaIterator {
  yield takeLatest(actions.loadCurrentModel.request, function* () {
    try {
      const response: ApiResponse<GetCurrentModelResponse> = yield call(
        apiService.getProjectCurrentModel,
      );
      yield put(actions.loadCurrentModel.success(response.data || getEmptyModel()));
    } catch (err) {
      yield put(actions.loadCurrentModel.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.MODELS.SINGLE") }))));
    }
  });
}

function* loadModelTopics(): SagaIterator {
  yield takeLatest(actions.loadCurrentModelTopics.request, function* () {
    try {
      const response: ApiResponse<GetModelTopicsResponse> = yield call(
        apiService.getProjectModelTopics,
      );
      const parsedModelTopics = response.data.model_topics.map(model => parseModelTopic(model));
      yield put(actions.loadCurrentModelTopics.success(parsedModelTopics));
    } catch (err) {
      yield put(actions.loadCurrentModelTopics.failure(new ErrorAction(err, i18n.t("ERRORS.API.MODELS.LOAD_TOPICS"))));
    }
  });
}

function* loadModelPlots(): SagaIterator {
  yield takeLatest(actions.loadModelPlots.request, function* (action) {
    try {
      const { topicSlug, keyMetric } = action.payload;
      const params: GetModelPlotsRequest = {
        query: {
          topic_slug: topicSlug,
          key_metric: keyMetric,
        },
      };
      const modelTopic: ModelTopic = yield select(getCurrentModelTopicBySlug, topicSlug);

      const response: ApiResponse<GetModelPlotsResponse> = yield call(
        apiService.getModelPlots,
        params,
      );
      const parsedPoints = (response.data.points || []).map(point => parsePlots(point));

      if (modelTopic.keyMetric === keyMetric && typeof modelTopic.threshold === 'number' && parsedPoints.length > 0) {
        const probabilities = parsedPoints.map(point => point.threshold);
        const thresholdIndex = findThresholdIndex(modelTopic.threshold, probabilities) as number;
        const plot = parsedPoints[thresholdIndex];

        const metrics = Object.keys(plot)
          .reduce<Array<{ metric: Metric, value: number }>>((accum, metricKey) => {
            const metricThreshold = {
              metric: metricKey as Metric,
              value: (metricKey as Metric) === Metric.probability ?
                (modelTopic.threshold || 0) :
                plot[metricKey as keyof PlotsPoint],
            };
            accum.push(metricThreshold);

            return accum;
          }, []);
        yield put(actions.setCurrentThresholds(metrics));
      } else {
        yield put(actions.setCurrentThresholds([{
          metric: Metric.probability,
          value: modelTopic.threshold || 0,
        }]));
      }

      yield put(actions.loadModelPlots.success(parsedPoints));
    } catch (err) {
      yield put(actions.loadModelPlots.failure(new ErrorAction(err, i18n.t("ERRORS.API.MODELS.LOAD_PLOTS"))));
    }
  });
}

function* loadThresholdDialogs(): SagaIterator {
  yield takeLatest(actions.loadThresholdDialogs.request, function* (action) {
    try {
      const { threshold, topicSlug, direction } = action.payload;
      const params: GetThresholdDialogsRequest = {
        mpt_probability: threshold,
        most_probable_topic_slug: topicSlug,
        mpt_probability_pos: direction,
      };

      const response: ApiResponse<GetThresholdDialogsResponse> = yield call(
        apiService.getThresholdDialogs,
        params,
      );
      const dialogs = response.data.dialogs.map(dialog => parseDialog(dialog));
      yield put(actions.loadThresholdDialogs.success(dialogs));
    } catch (err) {
      yield put(actions.loadThresholdDialogs.failure(new ErrorAction(err, i18n.t("ERRORS.API.MODELS.LOAD_DIALOGS"))));
    }
  });
}

function* updateThresholds(): SagaIterator {
  yield takeLatest(actions.updateThresholds.request, function* (action) {
    try {
      const model = action.payload;
      const params: UpdateModelTopicRequest = {
        body: parseModelTopicToBackend(model),
      };

      const response: ApiResponse<UpdateModelTopicResponse> = yield call(
        apiService.updateModelTopic,
        params,
      );
      const parsedModel = parseModelTopic(response.data);

      const probability = parsedModel.threshold;
      const plots: {
        list: PlotsPoint[],
        loading: boolean,
      } = yield select(getPlots);

      if (probability && plots.list.length > 0) {
        const thresholdIndex = findThresholdIndex(
          probability,
          plots.list.map(plot => plot.threshold),
        );

        const newThresholds: Array<{
          metric: Metric,
          value: number
        }> = [];
        if (thresholdIndex != null) {
          const point = plots.list[thresholdIndex];

          Object.keys(point).forEach(metric => {
            const value = metric === Metric.probability ?
              probability :
              point[metric as keyof PlotsPoint];
            newThresholds.push({
              metric: metric as Metric,
              value,
            });
          });
        }

        yield put(actions.setCurrentThresholds(newThresholds));
        yield put(actions.loadCurrentModelTopics.request());
      } else {
        yield put(actions.setCurrentThresholds([{
          metric: Metric.probability,
          value: probability || 0,
        }]));
      }
      yield put(actions.updateThresholds.success(parsedModel));
    } catch (err) {
      yield put(actions.updateThresholds.failure(new ErrorAction(err, i18n.t("ERRORS.API.MODELS.UPDATE_THRESHOLDS"))));
    }
  });
}

function* addProjectModel(): SagaIterator {
  yield takeLatest(actions.addProjectModel.request, function* (action) {
    try {
      const params: AddProjectModelRequest = {
        body: objCamelToSnake(action.payload),
      };

      yield call(apiService.addProjectModel, params);
      yield put(actions.addProjectModel.success(action.payload));
    } catch (err) {
      yield put(actions.addProjectModel.failure(new ErrorAction(err, i18n.t("ERRORS.API.MODELS.ADD_PROJECT_MODEL"))));
    }
  });
}

function* deleteProjectModel(): SagaIterator {
  yield takeLatest(actions.deleteProjectCurrentModel.request, function* () {
    try {
      yield call(apiService.deleteProjectModel);
      yield put(actions.deleteProjectCurrentModel.success());
    } catch (err) {
      yield put(actions.deleteProjectCurrentModel.failure(new ErrorAction(err, i18n.t("ERRORS.API.MODELS.DELETE_PROJECT_MODEL"))));
    }
  });
}

function* loadCommonModels(): SagaIterator {
  yield takeLatest(actions.loadCommonModels.request, function* () {
    try {
      const response: ApiResponse<GetCommonModelsResponse> = yield call(apiService.getCommonModels);
      const parsedModels = response.data.models.map(model => parseModel(model));
      yield put(actions.loadCommonModels.success(parsedModels));
    } catch (err) {
      yield put(actions.loadCommonModels.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.MODELS.MULTIPLE") }))));
    }
  });
}

function* createCommonModel(): SagaIterator {
  yield takeLatest(actions.createCommonModel.request, function* (action) {
    try {
      const params: CreateCommonModelRequest = {
        body: parseModelToBackend(action.payload),
      };

      const response: ApiResponse<CreateCommonModelResponse> = yield call(
        apiService.createCommonModel,
        params,
      );
      const newModel = parseModel(response.data);
      NotificationManager.info(i18n.t("PAGE_LEARN_MODELS.MSG_MODEL_CREATED"));
      yield put(actions.createCommonModel.success(newModel));
    } catch (err) {
      yield put(actions.createCommonModel.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.MODELS.SINGLE") }),
        action.payload,
      )));
    }
  });
}

function* deleteCommonModel(): SagaIterator {
  yield takeLatest(actions.deleteCommonModel.request, function* (action) {
    const { modelId } = action.payload;
    try {
      const params: DeleteCommonModelRequest = {
        params: {
          model_id: modelId,
        },
      };

      yield call(apiService.deleteCommonModel, params);
      yield put(actions.deleteCommonModel.success({ modelId }));
    } catch (err) {
      yield put(actions.deleteCommonModel.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.MODELS.SINGLE") }),
        { modelId },
      )));
    }
  });
}

function* loadModelsComparisons(): SagaIterator {
  yield takeLatest(actions.loadModelsComparisons.request, function* (action) {
    const { olderThan, limit = CHUNK_SIZE } = action.payload || {};
    try {
      const request: GetTasksRequest = {
        types: [
          TaskType.applyIntentDetectors,
        ],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(
        apiService.getTasks,
        request,
      );

      const tasks = response.data.tasks.map(parseTask);

      yield put(actions.loadModelsComparisons.success({
        tasks,
        pagination: {
          olderThan,
        },
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.TASKS.CREATE") });
      yield put(actions.loadModelsComparisons.failure(new ErrorAction(err, text)));
    }
  });
}

function* compareModels(): SagaIterator {
  yield takeLatest(actions.compareModels.request, function* (action) {
    try {
      const { models, sampling, modalId } = action.payload;
      const selectedProject: Project = yield select(getSelectedProject);

      const modelsToCompare: Array<[string, string]> = models.map(m => [m.slug, String(m.version)]);
      const dataType = sampling ? "sampling" : "markup";

      let comparisonTitle = modelsToCompare
        .map(model => `${model[0]} (${model[1]})`)
        .join(", ");

      if (dataType === "sampling") {
        comparisonTitle += `. ${i18n.t("ENTITIES.SAMPLING")} ${sampling?.title}`;
      }

      const params: CreateTaskRequest = {
        type: TaskType.applyIntentDetectors,
        name: `${i18n.t("MODALS.COMPARE_MODELS.TITLE")} ${comparisonTitle}`,
        project_slug: selectedProject.slug,
        params: {
          models_versions_to_compare: modelsToCompare,
          data_type: dataType,
          ...(dataType === "sampling" && { sampling_id: sampling?.id }),
        },
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(
        apiService.createTask,
        params,
      );

      const task = parseTask(response.data);
      yield put(actions.compareModels.success(task));

      modalService.close(modalId);
      yield put(actions.openComparisonModal());
      // Нужно для закрытия тултипа, из которого запускаем сравнение
      (document.activeElement as HTMLElement).blur();
    } catch (err) {
      const text = i18n.t("ERRORS.API.MODELS.COMPARISON");
      yield put(actions.compareModels.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* modelsSagas() {
  yield fork(loadModels);
  yield fork(loadOnlineModel);
  yield fork(updateOnlineModel);
  yield fork(loadCurrentModel);
  yield fork(loadModelTopics);
  yield fork(loadModelPlots);
  yield fork(loadThresholdDialogs);
  yield fork(updateThresholds);
  yield fork(addProjectModel);
  yield fork(deleteProjectModel);
  yield fork(loadCommonModels);
  yield fork(createCommonModel);
  yield fork(deleteCommonModel);
  yield fork(loadModelsComparisons);
  yield fork(compareModels);
}
