import { matchNewMacrosFormat, matchOldMacrosFormat } from "../../pages/graphEditor/components/nodeEditor/forms/response/components/responseTabs/mainTab/components/chatterboxMacros/helpers";

export type Macros = {
  texts: string[]
}

export const getEmptyMacros = (): Macros => ({
  texts: [''],
});

export type ChatterboxMacros = {
  id: string;
  macroId: string;
  title: string;
  comment: string;
}

export const guessIsChatterboxMacros = (value: string): boolean => (
  matchOldMacrosFormat(value) || matchNewMacrosFormat(value)
);

export type MacrosNew = {
  slug: string;
  title: string;
  text: string;
  tags: string[];
}
