import { SvgProps } from '../types';

function CrossBanIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.38398 3H15.616C15.8812 3 16.1356 3.10536 16.3231 3.29289L20.7071 7.67687C20.8946 7.86441 21 8.11877 21 8.38398V15.616C21 15.8812 20.8946 16.1356 20.7071 16.3231L16.3231 20.7071C16.1356 20.8946 15.8812 21 15.616 21H8.38398C8.11876 21 7.86441 20.8946 7.67688 20.7071L3.29289 16.3231C3.10536 16.1356 3 15.8812 3 15.616V8.38398C3 8.11877 3.10536 7.86441 3.29289 7.67688L2.58579 6.96977L3.29289 7.67688L7.67688 3.29289C7.86441 3.10536 8.11877 3 8.38398 3Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8812 9.51887C16.0765 9.32361 16.0765 9.00703 15.8812 8.81177L15.1883 8.1189C14.9931 7.92364 14.6765 7.92364 14.4812 8.1189L11.9997 10.6004L9.51753 8.1182C9.32227 7.92294 9.00569 7.92294 8.81043 8.1182L8.11753 8.8111C7.92227 9.00636 7.92227 9.32294 8.11753 9.5182L10.5997 12.0004L8.11723 14.4828C7.92196 14.6781 7.92196 14.9946 8.11722 15.1899L8.81012 15.8828C9.00538 16.0781 9.32196 16.0781 9.51723 15.8828L11.9997 13.4004L14.4815 15.8821C14.6767 16.0774 14.9933 16.0774 15.1886 15.8821L15.8815 15.1892C16.0767 14.994 16.0767 14.6774 15.8815 14.4821L13.3997 12.0004L15.8812 9.51887Z"
        fill={color}
      />
    </svg>
  );
}

export default CrossBanIcon;
