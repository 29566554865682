import React, { FC, memo } from 'react';

import Last from './components/last';
import List from './components/list';

import './styles.scss';

// Markdown editor подтягивает тему браузера,
// и это отключается только так..
document.documentElement.setAttribute('data-color-mode', 'light');

const ReleaseNotes: FC<{}> = () => (
  <>
    <Last />
    <List />
  </>
);

export default memo(ReleaseNotes);
