import React from 'react';

import { SvgProps } from '../types';

function DocumentDownloadIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2224 2.00002C13.1498 2 13.0757 2 13 2H9C7.13623 2 6.20435 2 5.46927 2.30448C4.48915 2.71046 3.71046 3.48915 3.30448 4.46927C3 5.20435 3 6.13623 3 8V16C3 17.8638 3 18.7956 3.30448 19.5307C3.71046 20.5108 4.48915 21.2895 5.46927 21.6955C6.20435 22 7.13623 22 9 22H13C14.8638 22 15.7956 22 16.5307 21.6955C17.5108 21.2895 18.2895 20.5108 18.6955 19.5307C19 18.7956 19 17.8638 19 16V12L18.9998 12.0002H17.95H17V16C17 16.9592 16.9989 17.5785 16.9666 18.0525C16.9355 18.5085 16.8822 18.6821 16.8478 18.7654C16.6448 19.2554 16.2554 19.6448 15.7654 19.8478C15.6821 19.8822 15.5085 19.9355 15.0525 19.9666C14.5785 19.9989 13.9592 20 13 20H9C8.04075 20 7.42148 19.9989 6.94752 19.9666C6.49152 19.9355 6.31786 19.8822 6.23463 19.8478C5.74458 19.6448 5.35523 19.2554 5.15224 18.7654C5.11777 18.6821 5.06453 18.5085 5.03342 18.0525C5.00108 17.5785 5 16.9592 5 16V8C5 7.04075 5.00108 6.42148 5.03342 5.94752C5.06453 5.49152 5.11777 5.31786 5.15224 5.23463C5.35523 4.74458 5.74458 4.35523 6.23463 4.15224C6.31786 4.11777 6.49152 4.06453 6.94752 4.03342C7.42148 4.00108 8.04075 4 9 4H11.2225L13.2224 2.00002ZM12.087 7H7V9H14.052L12.087 7ZM15 7.05047L14.9495 7H15V7.05047ZM17 9.05047L17.95 10.0004L19 8.95038V8C19 7.23572 19 6.62815 18.979 6.12659L18.8982 6.20682L18.8983 5.22068C18.8534 4.93771 18.7887 4.69427 18.6955 4.46927C18.357 3.65191 17.7592 2.97463 17.0016 2.53678L17.0015 6.21063L16.9799 6.18919C16.9992 6.63024 17 7.19558 17 8V9.05047ZM14.7995 4.01959C14.5832 4.01032 14.3372 4.00543 14.048 4.00285L14.4144 3.63646L14.7995 4.01959ZM7 13H15V11H7V13ZM11 17H7V15H11V17Z"
        fill={color}
      />
      <path
        d="M22.9001 5.05111L21.4858 3.6369L18.8986 6.20726L18.8984 1.0001L17.0016 1L17.0018 6.21106L14.4148 3.6369L13.0006 5.05111L17.9503 10.0009L22.9001 5.05111Z"
        fill={color}
      />
    </svg>
  );
}

export default DocumentDownloadIcon;
