import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { block } from 'bem-cn';
import { isAfter, startOfDay } from 'date-fns';

import { CallParams } from '../../types/models/Calls';

import { ModalProps } from '../../services/modal';

import HiddenArea from '../../components/hiddenArea';
import Toggle from '../../components/toggle';

import TimeInputRange from '../../components-new/timeInput/range';
import DatePicker from '../../components-new/datepicker';
import SideModal from '../../components-new/sideModal';
import Input from '../../components-new/input';
import Button from '../../components-new/button';
import Card from '../../components-new/card';

import './styles.scss';

const b = block("call-modal");

export type CallModalProps = {
  name: string;
  initialParams?: CallParams;
  onConfirm: (params: CallParams) => void;
}

const minDate = startOfDay(new Date());

export default function CallModal({
  name: propsName,
  initialParams,
  onConfirm,
  close,
}: CallModalProps & ModalProps) {
  const { t } = useTranslation();

  const isEditing = !!initialParams?.id;

  const [title, setTitle] = useState<string>(propsName || '');

  const [setupTime, setSetupTime] = useState<boolean>(
    !!initialParams?.dateStart ||
    false,
  );
  const [date, setDate] = useState<(Date | null)[]>([
    initialParams?.dateStart || null,
    initialParams?.dateEnd || null,
  ]);

  const [setupWorkTime, setSetupWorkTime] = useState<boolean>(
    initialParams?.work || false,
  );
  const [timeWork, setTimeWork] = useState<[string, string]>([
    initialParams?.workStart || '00:00',
    initialParams?.workEnd || '23:59',
  ]);

  const [setupWeekendTime, setSetupWeekendTime] = useState<boolean>(
    initialParams?.weekend || false,
  );
  const [timeWeekend, setTimeWeekend] = useState<[string, string]>([
    initialParams?.weekendStart || '00:00',
    initialParams?.weekendEnd || '23:59',
  ]);

  const [callBack, setCallBack] = useState<boolean>(
    !!initialParams?.numAttempts || !!initialParams?.callBackAfter,
  );
  const [numAttempts, setNumAttempts] = useState<number>(
    initialParams?.numAttempts || 1,
  );
  const [callBackAfter, setCallBackAfter] = useState<number>(
    initialParams?.callBackAfter || 60,
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | null) => {
      if (!event) {
        setTitle("");
        return;
      }

      setTitle(event.target.value);
    },
    [],
  );

  const handleSubmitClick = useCallback(
    () => {
      onConfirm({
        id: initialParams?.id,
        title,
        work: setupWorkTime,
        weekend: setupWeekendTime,
        ...(callBack && { numAttempts, callBackAfter }),
        ...(setupTime && { dateStart: date[0], dateEnd: date[1] }),
        ...(setupWorkTime && { workStart: timeWork[0], workEnd: timeWork[1] }),
        ...(setupWeekendTime && { weekendStart: timeWeekend[0], weekendEnd: timeWeekend[1] }),
      });
      close();
    },
    [
      callBack,
      callBackAfter,
      date,
      numAttempts,
      setupTime,
      title,
      close,
      onConfirm,
      setupWeekendTime,
      setupWorkTime,
      timeWeekend,
      timeWork,
      initialParams,
    ],
  );

  const handleCountRepeatCallsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | null) => {
      if (!event) {
        setNumAttempts(0);
        return;
      }
      setNumAttempts(+event.target.value);
    },
    [],
  );

  const handleIntervalChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | null) => {
      if (!event) {
        setCallBackAfter(0);
        return;
      }
      setCallBackAfter(+event.target.value);
    },
    [],
  );

  const handleDateChange = useCallback(
    (updates: Date | [Date, Date] | null) => {
      if (!Array.isArray(updates)) return;

      setDate(updates);
    },
    [],
  );

  useEffect(() => {
    if (timeWeekend[0] > timeWeekend[1]) {
      setTimeWeekend([timeWeekend[1], timeWeekend[1]]);
    }
    if (timeWork[0] > timeWork[1]) {
      setTimeWork([timeWork[1], timeWork[1]]);
    }
  }, [timeWork, timeWeekend],
  );

  const { valid, errors } = useMemo(
    () => {
      const result = {
        valid: true,
        errors: [],
      };

      if (title.length === 0) {
        result.valid = false;
        result.errors.push(t("MODALS.CALL.TITLE_INVALID"));
      }

      const [dateStart, dateEnd] = date;

      if (dateStart && dateEnd && isAfter(dateStart, dateEnd)) {
        result.valid = false;
        result.errors.push(t("MODALS.CALL.DATE_INVALID"));
      }

      if (numAttempts < 1) {
        result.valid = false;
        result.errors.push(t("MODALS.CALL.NUM_ATTEMPTS_INVALID"));
      }

      return result;
    },
    [
      title.length,
      numAttempts,
      date,
      t,
    ],
  );

  return (
    <SideModal
      open
      title={isEditing ?
        t("MODALS.CALL.EDIT_TITLE") :
        t("MODALS.CALL.INIT_TITLE")
      }
      onClose={close}
      size="normal"
      className={b()}
      footer={
        <>
          <Button
            size="large"
            primary
            fluid
            onClick={close}
          >
            <span>{t("COMMON.CANCEL")}</span>
          </Button>
          <Button
            size="large"
            disabled={!valid || (setupTime && !(setupWorkTime || setupWeekendTime))}
            fluid
            onClick={handleSubmitClick}
          >
            <span>{
              isEditing ?
                t("COMMON.SAVE") :
                t("MODALS.CALL.LAUNCH_CALL")}
            </span>
          </Button>
        </>
      }
    >
      <div className={b("content")}>
        <Input
          value={title}
          placeholder={t("MODALS.CALL.CALL_NAME")}
          floatingPlaceholder
          disabled={isEditing}
          name="title"
          onChange={handleNameChange}
        />
        <div>
          <div className={b("toggle-label")}>
            <span className={b("heading")}>{t("MODALS.CALL.TIME_SETUP")}</span>
            <Toggle
              value={setupTime}
              onChange={setSetupTime}
            />
          </div>
          <HiddenArea className={b("time-setup-container")} show={setupTime}>
            <Card className={b("details-wrapper")}>
              <div className={b("date-control")}>
                <DatePicker
                  className={b("date-picker")}
                  startDate={date[0]}
                  endDate={date[1]}
                  selectsRange
                  placeholder={t("COMMON.PERIOD")}
                  isClearable
                  minDate={minDate}
                  shouldCloseOnSelect={false}
                  popperPlacement="bottom-end"
                  onChange={handleDateChange}
                />
              </div>
              <div>
                <div className={b("toggle-label")}>
                  <span>{t("MODALS.CALL.TIME_SETUP_WORKING")}</span>
                  <Toggle
                    value={setupWorkTime}
                    onChange={setSetupWorkTime}
                  />
                </div>
                <HiddenArea className={b("time")} show={setupWorkTime}>
                  <TimeInputRange
                    fluid
                    placeholder={t("MODALS.CALL.CALL_TIME")}
                    value={timeWork}
                    onChange={setTimeWork}
                  />
                </HiddenArea>
              </div>
              <div>
                <div className={b("toggle-label")}>
                  <span>{t("MODALS.CALL.TIME_SETUP_WEEKEND")}</span>
                  <Toggle
                    value={setupWeekendTime}
                    onChange={setSetupWeekendTime}
                  />
                </div>
                <HiddenArea className={b("time")} show={setupWeekendTime}>
                  <TimeInputRange
                    fluid
                    placeholder={t("MODALS.CALL.CALL_TIME")}
                    value={timeWeekend}
                    onChange={setTimeWeekend}
                  />
                </HiddenArea>
              </div>
            </Card>
          </HiddenArea>
        </div>
        <div>
          <div
            className={b("toggle-label")}
            title={t("MODALS.CALL.CALL_BACK_HELP")}
          >
            <span className={b("heading")}>{t("MODALS.CALL.CALL_BACK")}</span>
            <Toggle
              value={callBack}
              onChange={setCallBack}
            />
          </div>
          <HiddenArea show={callBack}>
            <Card className={b("details-wrapper")}>
              <Input
                min={1}
                type="number"
                value={numAttempts}
                name="numAttempts"
                floatingPlaceholder
                placeholder={t("MODALS.CALL.NUM_ATTEMPTS")}
                onChange={handleCountRepeatCallsChange}
              />
              <Input
                min={1}
                type="number"
                value={callBackAfter}
                name="callBackAfter"
                floatingPlaceholder
                placeholder={t("MODALS.CALL.CALL_BACK_AFTER")}
                onChange={handleIntervalChange}
              />
            </Card>
          </HiddenArea>
        </div>
        {errors.map(error => (
          <span key={error} className={b("error")}>{error}</span>
        ))}
      </div>
    </SideModal>
  );
}
