import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import NotificationManager from '../../services/notifications';

import Modal from '../components/ndModal';
import { ModalProps } from '../../services/modal';
import Input from '../../components-new/input';
import Textarea from '../../components-new/textarea';
import { getAudioFiles } from '../../redux/files/selectors';

import './styles.scss';

export type CreateAudioRecordModalProps = {
  name?: string,
  onSubmit: (props: {
    name: string,
    comment?: string,
  }) => void,
}

export default function CreateAudioRecordModal({
  name: defaultName = '',
  onSubmit,
  close,
}: CreateAudioRecordModalProps & ModalProps) {
  const { t } = useTranslation();

  const audioFiles = useSelector(getAudioFiles).list;

  const [name, setName] = useState<string>(defaultName);
  const [comment, setComment] = useState<string>('');

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  useEffect(() => {
    const isAlreadyExist = audioFiles.some(file => file.userFilename === name.trim());
    if (isAlreadyExist) {
      NotificationManager.error(t('MODALS.CREATE_AUDIO_RECORD.EXISTS_ERROR'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, t]);

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.target.value);
    },
    [],
  );

  const handleSubmit = useCallback(
    () => {
      onSubmit({ name, comment });
    },
    [comment, name, onSubmit],
  );

  const handleClose = useCallback(
    () => {
      close();
      setName('');
    },
    [close],
  );

  return (
    <Modal
      title={t("MODALS.CREATE_AUDIO_RECORD.TITLE")}
      className="create-audio-record-modal"
      cancelText={t("COMMON.CANCEL")}
      confirmationText={t("COMMON.READY")}
      close={handleClose}
      onConfirm={handleSubmit}
    >
      <div className="column-2">
        <Input
          value={name}
          name="name"
          placeholder={t("COMMON.NAME")}
          floatingPlaceholder
          onChange={handleNameChange}
        />
        <Textarea
          rows={5}
          fluid
          name="comment"
          placeholder={t("COMMON.COMMENT")}
          value={comment}
          onChange={handleCommentChange}
        />
      </div>
    </Modal>
  );
}
