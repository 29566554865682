import { Reducer } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';

import { Tag, TagInfo, getEmptyTagInfo } from '../../types/models/Tag';
import { parseTag } from '../../types/parsers/TagParser';
import * as actions from './actions';

export type TagsState = {
  loading: boolean,
  list: Array<Tag>,
  deleteTagModal: {
    tagId: Tag['id'],
    opened: boolean,
    loading: boolean,
    data: TagInfo,
  }
}

const initialState: TagsState = {
  loading: false,
  list: [],
  deleteTagModal: {
    tagId: '',
    opened: false,
    loading: false,
    data: getEmptyTagInfo(),
  },
};

export const tagsReducer: Reducer<TagsState> = createReducer<TagsState>(initialState)
  .handleAction(
    actions.loadTags.request,
    (state: TagsState): TagsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadTags.success,
    (state: TagsState, { payload }: ActionType<typeof actions.loadTags.success>): TagsState => {
      const { tags = [] } = payload;
      const parsedTags = tags.map(tag => parseTag(tag));
      return {
        ...state,
        loading: false,
        list: parsedTags,
      };
    },
  )
  .handleAction(
    actions.loadTags.failure,
    (state: TagsState): TagsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createTag.request,
    (state: TagsState): TagsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.createTag.success,
    (state: TagsState, { payload }: ActionType<typeof actions.createTag.success>): TagsState => ({
      ...state,
      list: [...state.list, payload],
      loading: false,
    }),
  )
  .handleAction(
    actions.createTag.failure,
    (state: TagsState): TagsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.deleteTag.request,
    (state: TagsState): TagsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.deleteTag.success,
    (state: TagsState, { payload }: ActionType<typeof actions.deleteTag.success>): TagsState => ({
      ...state,
      list: [...state.list.filter(el => el.id !== payload)],
      loading: false,
    }),
  )
  .handleAction(
    actions.deleteTag.failure,
    (state: TagsState): TagsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.openDeleteTagModal,
    (
      state: TagsState,
      { payload }: ActionType<typeof actions.openDeleteTagModal>,
    ): TagsState => ({
      ...state,
      deleteTagModal: {
        ...state.deleteTagModal,
        tagId: payload,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeDeleteTagModal,
    (state: TagsState): TagsState => ({
      ...state,
      deleteTagModal: {
        ...state.deleteTagModal,
        ...initialState.deleteTagModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.loadTagInfo.request,
    (
      state: TagsState,
    ): TagsState => ({
      ...state,
      deleteTagModal: {
        ...state.deleteTagModal,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadTagInfo.success,
    (
      state: TagsState,
      { payload }: ActionType<typeof actions.loadTagInfo.success>,
    ): TagsState => ({
      ...state,
      deleteTagModal: {
        ...state.deleteTagModal,
        data: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadTagInfo.failure,
    (
      state: TagsState,
    ): TagsState => ({
      ...state,
      deleteTagModal: {
        ...state.deleteTagModal,
        loading: false,
      },
    }),
  );
