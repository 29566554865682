import { ScenarioGraphLabel } from "../../types/models/ScenarioGraphLabel";
import { ScenarioGroupName } from "../../types/backendModels/ScenarioGraphBackend";

import FormattedCreateLabel from "../../components-new/select/helpers";
import { MultiOperator } from "../../components-new/select/configurable";

import i18n from '../../services/i18n';

export type ScenariosFilters = {
  search?: string;
  labels?: Array<ScenarioGraphLabel['id']>;
  labelsOperator?: MultiOperator;
  scenarioTypes?: ScenarioGroupName[];
}

export const formatCreateLabelOption = (input: string) => (
  <FormattedCreateLabel
    input={input}
    subtitle={i18n.t("MODALS.ADD_INTEGRATIONS_TO_LABEL.CREATE_NEW_LABEL")}
  />
);
