import { SvgProps } from "../types";

function Weight({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50094 11.9162C4.11042 11.5257 3.47725 11.5257 3.08673 11.9162L2.77337 12.2296C2.38285 12.6201 2.38285 13.2532 2.77337 13.6438L10.8608 21.7312C11.2513 22.1217 11.8845 22.1217 12.275 21.7312L12.5883 21.4178C12.9789 21.0273 12.9789 20.3941 12.5883 20.0036L4.50094 11.9162ZM11.4112 3.5925C11.0207 3.98303 11.0207 4.61619 11.4112 5.00671L19.4986 13.0941C19.8891 13.4846 20.5223 13.4846 20.9128 13.0941L21.2262 12.7808C21.6167 12.3902 21.6167 11.7571 21.2262 11.3666L13.1388 3.27915C12.7482 2.88862 12.1151 2.88862 11.7246 3.27915L11.4112 3.5925ZM1.35917 15.3694C1.74969 14.9789 2.38286 14.9789 2.77338 15.3694L9.13322 21.7293C9.52374 22.1198 9.52374 22.7529 9.13322 23.1435L8.81987 23.4568C8.42934 23.8473 7.79618 23.8473 7.40565 23.4568L5.52119 21.5724L4.86975 22.2238C4.75259 22.341 4.56264 22.341 4.44548 22.2238L2.2784 20.0567C2.16124 19.9396 2.16124 19.7496 2.2784 19.6325L2.92984 18.981L1.04582 17.097C0.655293 16.7065 0.655294 16.0733 1.04582 15.6828L1.35917 15.3694ZM14.8664 1.86511C14.4758 2.25564 14.4758 2.8888 14.8664 3.27933L21.2262 9.63916C21.6167 10.0297 22.2499 10.0297 22.6404 9.63916L22.9538 9.32581C23.3443 8.93529 23.3443 8.30212 22.9538 7.9116L21.069 6.02689L21.7212 5.37478C21.8383 5.25762 21.8383 5.06767 21.7212 4.95052L19.5541 2.78343C19.4369 2.66627 19.247 2.66627 19.1298 2.78343L18.4777 3.43554L16.5939 1.55176C16.2034 1.16124 15.5702 1.16124 15.1797 1.55176L14.8664 1.86511ZM13.7273 9.04698L8.54465 14.2297L10.2722 15.9572L15.4549 10.7745L13.7273 9.04698Z"
        fill={color}
      />
    </svg>
  );
}

export default Weight;
