import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { deepClone } from "../../services/helpers/utilities";

import { BalanceClient, Person } from "../../types/models/Billing";

import * as actions from './actions';

export enum ModalClientSteps {
  Organisation,
  Manager,
  Credentials,
}

type BillingState = {
  clients: {
    loading: boolean;
    list: BalanceClient[];
  },
  activeClient: BalanceClient['id'] | null;
  modalClientToEdit: {
    opened: boolean;
    step: ModalClientSteps;
    loading: boolean;
    person: Person | null;
    personLoading: boolean;
  }
}

const initialState: BillingState = {
  clients: {
    list: [],
    loading: false,
  },
  activeClient: null,
  modalClientToEdit: {
    opened: false,
    step: ModalClientSteps.Organisation,
    loading: false,
    person: null,
    personLoading: false,
  },
};

export const billingReducer: Reducer<BillingState> = createReducer<BillingState>(initialState)
  .handleAction(
    actions.loadClients.request,
    (state: BillingState): BillingState => ({
      ...state,
      clients: {
        ...state.clients,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadClients.success,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.loadClients.success>,
    ): BillingState => ({
      ...state,
      clients: {
        ...state.clients,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadClients.failure,
    (state: BillingState): BillingState => ({
      ...state,
      clients: {
        ...state.clients,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.setActiveClient,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.setActiveClient>,
    ): BillingState => ({
      ...state,
      activeClient: payload,
    }),
  )
  .handleAction(
    actions.openActiveClientEditModal,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.openActiveClientEditModal>,
    ): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        opened: true,
        step: payload || ModalClientSteps.Organisation,
      },
    }),
  )
  .handleAction(
    actions.closeActiveClientEditModal,
    (state: BillingState): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.setActiveClientStep,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.setActiveClientStep>,
    ): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        step: payload,
      },
    }),
  )
  .handleAction(
    actions.loadActiveClientPerson.request,
    (state: BillingState): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        personLoading: true,
      },
    }),
  )
  .handleAction(
    actions.loadActiveClientPerson.success,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.loadActiveClientPerson.success>,
    ): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        person: payload,
        personLoading: false,
      },
    }),
  )
  .handleAction(
    actions.loadActiveClientPerson.failure,
    (state: BillingState): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        personLoading: false,
      },
    }),
  )
  .handleAction(
    actions.setModalLoadingStatus,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.setModalLoadingStatus>,
    ): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        loading: payload,
      },
    }),
  )
  .handleAction(
    actions.updateClient,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.updateClient>,
    ): BillingState => {
      const clientIndex = state.clients.list.findIndex(c => c.id === payload.id);

      const newClients = deepClone(state.clients.list);

      if (clientIndex !== -1) {
        newClients[clientIndex] = payload;
      } else {
        newClients.push(payload);
      }

      return {
        ...state,
        clients: {
          ...state.clients,
          list: newClients,
        },
      };
    },
  )
  .handleAction(
    actions.updatePerson,
    (
      state: BillingState,
      { payload }: ActionType<typeof actions.updatePerson>,
    ): BillingState => ({
      ...state,
      modalClientToEdit: {
        ...state.modalClientToEdit,
        person: payload,
      },
    }),
  )
  .handleAction(
    actions.nextModalStep,
    (state: BillingState): BillingState => {
      let nextStep = state.modalClientToEdit.step;

      switch (nextStep) {
        case ModalClientSteps.Organisation:
          const activeClient = state.clients.list.find(c => c.id === state.activeClient);

          if (!activeClient?.isUserLinked) {
            nextStep = ModalClientSteps.Manager;
          } else {
            nextStep = ModalClientSteps.Credentials;
          }

          break;
        case ModalClientSteps.Manager:
          nextStep = ModalClientSteps.Credentials;
          break;
        case ModalClientSteps.Credentials:
        default:
          break;
      }
      return {
        ...state,
        modalClientToEdit: {
          ...state.modalClientToEdit,
          step: nextStep,
        },
      };
    },
  );
