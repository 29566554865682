import React from "react";

import { SvgProps } from '../types';

function BellOutlineIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1.99976H11L10.6408 4.15525C8.35687 4.68638 6.54016 6.52886 6.10557 8.9191L5.35777 13.032C5.13255 14.2707 4.33726 15.3311 3.21115 15.8942L3 15.9998V17.9998C3 18.79 5.0645 19.4771 8.08112 19.8013C8.23702 20.5635 8.61325 21.2699 9.17157 21.8282C9.92172 22.5783 10.9391 22.9998 12 22.9998C13.0609 22.9998 14.0783 22.5783 14.8284 21.8282C15.3868 21.2699 15.763 20.5635 15.9189 19.8013C18.9355 19.4771 21 18.79 21 17.9998V15.9998L20.7889 15.8942C19.6627 15.3311 18.8675 14.2707 18.6422 13.032L17.8944 8.91911C17.4598 6.52886 15.6431 4.68638 13.3593 4.15526L13 1.99976ZM13.7533 19.962C13.1868 19.9868 12.6006 19.9998 12 19.9998C11.3994 19.9998 10.8132 19.9868 10.2467 19.962C10.337 20.1265 10.4507 20.2789 10.5858 20.414C10.9609 20.789 11.4696 20.9998 12 20.9998C12.5304 20.9998 13.0391 20.789 13.4142 20.414C13.5493 20.2789 13.663 20.1265 13.7533 19.962ZM7.32551 13.3898L8.07331 9.27688C8.41835 7.37916 10.0712 5.99976 12 5.99976C13.9288 5.99976 15.5816 7.37916 15.9267 9.27688L16.6745 13.3898C16.9491 14.8999 17.7884 16.2333 19 17.1332V17.1627C18.7458 17.2551 18.3959 17.3584 17.9395 17.4602C16.4959 17.7823 14.399 17.9998 12 17.9998C9.60099 17.9998 7.50406 17.7823 6.06045 17.4602C5.60409 17.3584 5.25416 17.2551 5 17.1627V17.1332C6.21156 16.2333 7.05094 14.8999 7.32551 13.3898Z"
        fill={color}
      />
    </svg>
  );
}

export default BellOutlineIcon;
