import { createAsyncAction } from "typesafe-actions";
import { AuthData } from "../../types/models/ProjectAuthData";
import { ErrorAction } from "../reducers";

export const loadProjectAuthData = createAsyncAction(
  'projectAuthData/loadProjectAuthData/requested',
  'projectAuthData/loadProjectAuthData/succeeded',
  'projectAuthData/loadProjectAuthData/failed',
)<string, { projectSlug: string, data: AuthData }, ErrorAction>();

export const updateProjectAuthData = createAsyncAction(
  'projectAuthData/updateProjectAuthData/requested',
  'projectAuthData/updateProjectAuthData/succeeded',
  'projectAuthData/updateProjectAuthData/failed',
)<
  { projectSlug: string, data: AuthData },
  { projectSlug: string, data: AuthData },
  ErrorAction
>();
