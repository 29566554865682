import React, { useCallback } from 'react';
import { block } from 'bem-cn';

import { AudioFileMetadata } from '../../../types/models/Files';
import { getFilenameWithoutExtension } from '../../../services/helpers/utilities';
import { Editable } from '../../../redux/reducers';

import UploadingRobotAudio from '../uploadingRobotAudio';
import AudioItem from '../audioItem';

import './styles.scss';

const b = block("audio-list");

export type AudioListProps = {
  files: Editable<AudioFileMetadata[]>,
  activeFile: string,
  onActiveFileChange: (filename: string) => void,
  onEditClick: (filename: string) => void,
}

export default function AudioList({
  activeFile,
  files,
  onActiveFileChange,
  onEditClick,
}: AudioListProps) {
  const handleFilenameSelect = useCallback(
    (filename: string) => {
      onActiveFileChange(filename);
    },
    [onActiveFileChange],
  );

  return (
    <div className={b()}>
      <div className={b("wrapper").mix("column-2")}>
        {files.map((file: Editable<AudioFileMetadata>) => (
          file.pending ? (
            <UploadingRobotAudio key="uploading" filename={file.filename} />
          ) : (
            <AudioItem
              key={file.filename}
              selected={getFilenameWithoutExtension(activeFile) === getFilenameWithoutExtension(file.filename)}
              meta={file}
              onSelect={handleFilenameSelect}
              onEditClick={onEditClick}
            />
          )
        ))}
      </div>
    </div>
  );
}
