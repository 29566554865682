export type AuthProviderMeta = {
  name: string,
  title: string,
  url: string,
  path: string,
  type: string,
  manualSignup: boolean
}

export enum AuthProviderType {
  YANDEX = 'yandex',
  YANDEX_TEAM = 'yandex-team',
  OPENID ='openid',
  PASSWORD= 'password',
}

export type AuthProvider = {
  name: string;
  title: string;
  default: boolean;
  path: string;
  type: AuthProviderType;
  scim: {
    token: string;
  },
  config: AuthProviderConfig;
}

export type AuthProviderConfig = PasswordProviderConfig
  | YandexProviderConfig
  | YandexTeamProviderConfig
  | OpenIDProviderConfig;

export type PasswordProviderConfig = {
  type: AuthProviderType.PASSWORD;
}

export type YandexProviderConfig = {
  type: AuthProviderType.YANDEX;
  appId: string;
}

export type YandexTeamProviderConfig = {
  type: AuthProviderType.YANDEX_TEAM;
  appId: string;
}

export type OpenIDProviderConfig = {
  type: AuthProviderType.OPENID;
  secret: string;
  baseURL: string;
  clientID: string;
  idField: string;
  issuerBaseURL: string;
}

export function getEmptyAuthProvider(): AuthProvider {
  return {
    name: "",
    title: "",
    default: false,
    path: "",
    type: AuthProviderType.YANDEX_TEAM,
    scim: {
      token: "",
    },
    config: getEmptyAuthProviderConfig(),
  };
}

export const DEFAULT_AUTH_PROVIDER_TYPE = AuthProviderType.YANDEX_TEAM;

export function getEmptyAuthProviderConfig(type = DEFAULT_AUTH_PROVIDER_TYPE): AuthProviderConfig {
  switch (type) {
    case AuthProviderType.OPENID:
      return {
        type: AuthProviderType.OPENID,
        secret: "",
        baseURL: "",
        clientID: "",
        idField: "",
        issuerBaseURL: "",
      };
    case AuthProviderType.PASSWORD:
      return {
        type: AuthProviderType.PASSWORD,
      };
    case AuthProviderType.YANDEX:
      return {
        type: AuthProviderType.YANDEX,
        appId: "",
      };
    default:
      return {
        type: AuthProviderType.YANDEX_TEAM,
        appId: "",
      };
  }
}
