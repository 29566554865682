import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { Slot } from "../../types/models/Slots";
import { Editable } from "../reducers";

import * as actions from './actions';

type SlotsState = {
  slots: {
    list: Editable<Slot[]>,
    modelId: string,
    loading: boolean,
  },
  freeSlots: {
    list: Editable<Slot[]>,
    loading: boolean,
  },
}

const initialState: SlotsState = {
  slots: {
    list: [],
    modelId: '',
    loading: false,
  },
  freeSlots: {
    list: [],
    loading: false,
  },
};

export const slotsReducer: Reducer<SlotsState> = createReducer<SlotsState>(initialState)
  .handleAction(
    actions.loadSlots.request,
    (state: SlotsState, { payload }: ActionType<typeof actions.loadSlots.request>): SlotsState => ({
      ...state,
      slots: {
        ...state.slots,
        modelId: payload.modelId,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadSlots.success,
    (state: SlotsState, { payload }: ActionType<typeof actions.loadSlots.success>): SlotsState => ({
      ...state,
      slots: {
        ...state.slots,
        modelId: payload.modelId,
        list: payload.slots,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadSlots.failure,
    (state: SlotsState): SlotsState => ({
      ...state,
      slots: {
        ...state.slots,
        modelId: '',
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadFreeSlots.request,
    (state: SlotsState): SlotsState => ({
      ...state,
      freeSlots: {
        ...state.freeSlots,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadFreeSlots.success,
    (state: SlotsState, { payload }: ActionType<typeof actions.loadFreeSlots.success>): SlotsState => ({
      ...state,
      freeSlots: {
        ...state.freeSlots,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadFreeSlots.failure,
    (state: SlotsState): SlotsState => ({
      ...state,
      freeSlots: {
        ...state.freeSlots,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.lockSlot.request,
    (state: SlotsState, { payload }: ActionType<typeof actions.lockSlot.request>): SlotsState => {
      return updateFreeSlot(state, payload.shardId, payload.workerId || 0, slot => ({ ...slot, pending: true }));
    },
  )
  .handleAction(
    actions.lockSlot.success,
    (state: SlotsState, { payload }: ActionType<typeof actions.lockSlot.success>): SlotsState => {
      return {
        ...state,
        slots: {
          ...state.slots,
          list: [payload, ...state.slots.list],
        },
        freeSlots: {
          ...state.freeSlots,
          list: state.freeSlots.list
            .filter(slot => (slot.workerId !== payload.workerId) || (slot.shardId !== payload.shardId)),
        },
      };
    },
  )
  .handleAction(
    actions.lockSlot.failure,
    (state: SlotsState, { payload: error }: ActionType<typeof actions.lockSlot.failure>): SlotsState => {
      const { payload } = error;
      return updateFreeSlot(state, payload.shardId, payload.workerId || 0, slot => ({ ...slot, pending: false }));
    },
  )
  .handleAction(
    actions.unlockSlot.request,
    (state: SlotsState, { payload }: ActionType<typeof actions.unlockSlot.request>): SlotsState => {
      return updateSlot(state, payload.shardId, payload.workerId || 0, slot => ({ ...slot, pending: true }));
    },
  )
  .handleAction(
    actions.unlockSlot.success,
    (state: SlotsState, { payload }: ActionType<typeof actions.unlockSlot.success>): SlotsState => {
      return {
        ...state,
        slots: {
          ...state.slots,
          list: state.slots.list
            .filter(slot => (slot.workerId !== payload.workerId) || (slot.shardId !== payload.shardId)),
        },
        freeSlots: {
          ...state.freeSlots,
          list: [payload, ...state.freeSlots.list],
        },
      };
    },
  )
  .handleAction(
    actions.unlockSlot.failure,
    (state: SlotsState, { payload: error }: ActionType<typeof actions.unlockSlot.failure>): SlotsState => {
      const { payload } = error;
      return updateSlot(state, payload.shardId, payload.workerId || 0, slot => ({ ...slot, pending: false }));
    },
  );

function updateSlot(
  state: SlotsState,
  shardId: string,
  workerId: number,
  callback: (value: Editable<Slot>) => Editable<Slot>,
): SlotsState {
  return {
    ...state,
    slots: {
      ...state.slots,
      list: state.slots.list.map(slot => {
        if (slot.shardId === shardId && slot.workerId === workerId) {
          return callback(slot);
        }

        return slot;
      }),
    },
  };
}

function updateFreeSlot(
  state: SlotsState,
  shardId: string,
  workerId: number,
  callback: (value: Editable<Slot>) => Editable<Slot>,
): SlotsState {
  return {
    ...state,
    freeSlots: {
      ...state.freeSlots,
      list: state.freeSlots.list.map(slot => {
        if (slot.shardId === shardId && slot.workerId === workerId) {
          return callback(slot);
        }

        return slot;
      }),
    },
  };
}
