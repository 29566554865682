import React from 'react';

import cx from '../../services/helpers/classNames';

import Loading from '../loading';

import './styles.scss';

type LoadingRowProps = {
  className?: string,
}

export default function LoadingRow({ className }: LoadingRowProps) {
  const classNames = cx(className, 'loading-row');
  return <div className={classNames}><Loading /></div>;
}
