import { createAction, createAsyncAction } from 'typesafe-actions';

import { ErrorAction } from "../reducers";
import { ScenarioGraph } from '../../types/models/ScenarioGraph';
import { GraphStatisticsFilters, NodeStatistics } from '../../types/models/GraphStatistics';
import { Node } from '../../pages/graphEditor/types';
import { ScenarioGraphGroup } from '../../types/models/ScenarioGraphGroup';

export type GraphActionParams = ScenarioGraph & {
  skipValidation?: boolean;
  openGraph?: boolean;
  openInNewWindow?: boolean;
  saveDefaultPredicate?: boolean;
  newParentScenarios?: string[];
  groups?: ScenarioGraphGroup[];
};

export const createGraph = createAsyncAction(
  'graph/createGraph/requested',
  'graph/createGraph/succeeded',
  'graph/createGraph/failed',
)<GraphActionParams, ScenarioGraph, ErrorAction>();

export const updateGraph = createAsyncAction(
  'graph/updateGraph/requested',
  'graph/updateGraph/succeeded',
  'graph/updateGraph/failed',
)<GraphActionParams, ScenarioGraph, ErrorAction>();

export const changeActiveGraph = createAction('graph/changeActiveGraph')<ScenarioGraph>();
export const resetActiveGraph = createAction('graph/resetActiveGraph')();

export const highlightFreeLines = createAction('graph/highlightFreeLines')();
export const cancelFreeLinesHighlight = createAction('graph/cancelFreeLinesHighlight')();

export const loadGraphList = createAsyncAction(
  'graph/loadGraphList/requested',
  'graph/loadGraphList/succeeded',
  'graph/loadGraphList/failed',
)<undefined, ScenarioGraph[], ErrorAction>();

export const loadGraphById = createAsyncAction(
  'graph/loadGraphById/requested',
  'graph/loadGraphById/succeeded',
  'graph/loadGraphById/failed',
)<ScenarioGraph['id'], ScenarioGraph, ErrorAction>();

export const createNestedGraph = createAsyncAction(
  'graph/createNestedGraph/requested',
  'graph/createNestedGraph/succeeded',
  'graph/createNestedGraph/failed',
)<{ nodeId: Node['id'], title: string }, { nodeId: Node['id'], scenarioId: ScenarioGraph['id'] }, ErrorAction>();

export const setSearchResults = createAction('graph/setSearchResults')<string[]>();
export const resetSearchResults = createAction('graph/resetSearchResults')();

export const openScenarioEditor = createAction('graphTopics/openScenarioEditor')();
export const closeScenarioEditor = createAction('graphTopics/closeScenarioEditor')();

export const setGraphLoadingStatus = createAction('graph/setGraphLoadingStatus')<boolean>();

export const loadNodesStatistics = createAsyncAction(
  'graph/loadNodesStatistics/requested',
  'graph/loadNodesStatistics/succeeded',
  'graph/loadNodesStatistics/failed',
)<
  Array<Node['id']>,
  Array<NodeStatistics>,
  ErrorAction
>();

export const setStatsEnabled = createAction(
  'graph/setStatsEnabled',
)<boolean>();

export const setGraphStatisticsFilters = createAction(
  'graph/setGraphStatisticsFilters',
)<GraphStatisticsFilters>();
