export enum Metric {
  probability = 'threshold',
  precision = 'precision',
  automatization = 'automatization',
  recall = 'recall',
  automatizationTopic = 'automatization_topic',
}

export const metricLabels = {
  [Metric.probability]: 'METRICS.PROBABILITY',
  [Metric.precision]: 'METRICS.PRECISION',
  [Metric.automatization]: 'METRICS.AUTOMATIZATION',
  [Metric.recall]: 'METRICS.RECALL',
  [Metric.automatizationTopic]: 'METRICS.AUTOMATIZATION_TOPIC',
};

export enum KeyMetric {
  recall = 'recall',
  precision = 'precision',
}

export const keyMetricLabels = {
  [KeyMetric.precision]: 'METRICS.PRECISION',
  [KeyMetric.recall]: 'METRICS.RECALL',
};
