import { createAction, createAsyncAction } from 'typesafe-actions';

import { OutgoingCall } from '../../types/models/OutgoingCall';
import { OutgoingCallResult } from '../../types/models/OutgoingCallResult';
import { OutgoingCallStatus } from '../../types/models/OutgoingCallStatus';
import { Pagination } from '../../types/models/Pagination';
import { Task } from '../../types/models/Task';
import { ErrorAction } from '../reducers';

export const loadCallResult = createAsyncAction(
  'callDetails/loadCallResult/requested',
  'calls/loadCallResult/succeeded',
  'calls/loadCallResult/failed',
)<{ callId: string }, { callId: string, result?: OutgoingCallResult }, ErrorAction<{ callId: string }>>();

export const loadTaskCalls = createAsyncAction(
  'calls/loadTaskCalls/requested',
  'calls/loadTaskCalls/succeeded',
  'calls/loadTaskCalls/failed',
)<{
  taskId: string,
  phone?: string,
  status?: OutgoingCallStatus,
  limit: number,
  offset: number,
}, {
  calls: OutgoingCall[],
  totalCount: number,
  addChunk?: boolean,
}, ErrorAction>();

export const loadCallExports = createAsyncAction(
  'calls/loadCallExports/requested',
  'calls/loadCallExports/succeeded',
  'calls/loadCallExports/failed',
)<{ taskId: string } & Pagination, { tasks: Task[], pagination?: Pagination }, ErrorAction>();

export const loadCallRecords = createAction('calls/loadRecords/requested')<{ ids: string[] }>();

export const loadCallRecord = createAsyncAction(
  'calls/loadRecord/requested',
  'calls/loadRecord/succeeded',
  'calls/loadRecord/failed',
)<{ callId: string }, undefined, ErrorAction>();
