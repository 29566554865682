import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeActionIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C0.999999 5.92487 5.92487 1 12 1C18.0751 0.999999 23 5.92487 23 12Z" fill="none" stroke={color} strokeWidth="2" />
  </svg>

);

export default GraphNodeActionIcon;
