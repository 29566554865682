import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from '../../services/helpers/utilities';

import Button from '../../components-new/button';
import Icon, { IconSize } from '../../components-new/icon';
import Input from '../../components-new/input';

import { b } from '.';

type Props = {
  apiKey?: string;
  onChange?: (key: string) => void;
};

const ApiKey: FC<Props> = ({
  apiKey,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [editing, setEditing] = useState<boolean>(false);

  const handleCopyKey = useCallback(
    () => {
      if (!apiKey) return;

      copyToClipboard(apiKey);
    },
    [apiKey],
  );

  const handleKeyChange = useCallback(
    (_, { value }) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const toggleEditing = useCallback(
    () => {
      setEditing(!editing);
    },
    [editing],
  );

  const disableEditing = useCallback(
    () => {
      setEditing(false);
    },
    [],
  );

  useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [editing],
  );

  return (
    <div className={b("key-wrapper")}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {editing ? (
        <Input
          ref={inputRef}
          className={b('input')}
          visualStyle="contrast"
          name="apiKey"
          value={apiKey || ""}
          size="tiny"
          fluid
          placeholder={t("MODALS.PROJECT_AUTH_DATA_MODAL.API_KEY")}
          onChange={handleKeyChange}
          onBlur={disableEditing}
        />
      ) : apiKey ? (
        <span className={b('text')}>{apiKey}</span>
      ) : (
        <span className={b('placeholder')}>{t("MODALS.PROJECT_AUTH_DATA_MODAL.EMPTY")}</span>
      )}
      <Button
        className={b("key-action")}
        simple
        size="mini"
        onClick={toggleEditing}
      >
        {editing ? (
          <Icon type="checkmarkSmall" iconSize={IconSize.small} />
        ) : (
          <Icon type="pen" iconSize={IconSize.small} />
        )}
      </Button>
      {!editing && apiKey && (
        <Button
          className={b("key-action")}
          simple
          size="mini"
          onClick={handleCopyKey}
        >
          <Icon type="documents" iconSize={IconSize.small} />
        </Button>
      )}
    </div>
  );
};

export default memo(ApiKey);
