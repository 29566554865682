import { generateId } from '../../services/helpers/generateId';
import { CallService } from './ProjectConfig';
import { MAP_LANGUAGE_TO_PERSON, MAP_PERSON_TO_ROLE, speechKitLanguages, VoiceRole, VoicePerson } from '../../redux/projectVoiceConfig/helpers';
import { deepClone } from '../../services/helpers/utilities';

export type ProjectVoice = {
  unsaved?: boolean;
  dispatcherParams: DispatcherParams;
  speechKit: SpeechKit;
  separateCallsScheduleSettings: CallsScheduleSettings;
}

export type DispatcherParams = IVRDispatcherParams |
  VoximplantDispatcherParams |
  YaTelephonyDispatcherParams;

export type IVRDispatcherParams = {
  callService: CallService.ivr,
}

export type VoximplantDispatcherParams = {
  callService: CallService.voximplant,
  accountId: string;
  apiKey: string;
  ruleId: number;
  priority: number;
  maxSimultaneous: number;
  numAttempts: number;
}

export type YaTelephonyDispatcherParams = {
  callService: CallService.ya,
  speechSilenceTimeoutMs: number;
  storeRecords: boolean;
  enableDtmf: boolean;
  recordOperator: boolean;
  fallbackOperator: string;
  callProcessingStartPauseMs: number;
}

export type SpeechKit = {
  unsafeTts?: boolean;
  sttLanguages: string[];
  defaultTtsLanguage: string;
  eou: {
    sensitivity?: number; // default: 0
    maxPauseBetweenWordsMs?: number; // default: 3000
  },
  voices: VoiceLanguage[];
}

export type CallsScheduleSettings = {
  callbackAfterInMinutes?: number;
  schedule?: {
    workingDays?: {
      from?: string; // default = "00:00"
      to?: string; // default = "23:59"
    },
    weekEnd?: {
      from?: string; // default = "00:00"
      to?: string; // default = "23:59"
    }
  }
}

export type Voice = {
  name: VoicePerson | null; // default: "jane"
  role?: VoiceRole | null;
  speed?: number; // default 1.0
};

export type VoiceLanguage = {
  id: string;
  language: string;
  voice: Voice;
};

export type ProjectPhone = {
  phoneId: number;
  phone: string;
  isOutgoing?: boolean;
}

export function getEmptyProjectVoiceConfig(): ProjectVoice {
  return {
    unsaved: true,
    dispatcherParams: getEmptyDispatcherParams(),
    speechKit: getEmptySpeechKit(),
    separateCallsScheduleSettings: {},
  };
}

export function getEmptyDispatcherParams(type: CallService = CallService.ya): DispatcherParams {
  switch (type) {
    case CallService.ivr:
      return getEmptyIVRParams();
    case CallService.voximplant:
      return getEmptyVoximplantParams();
    default:
      return getEmptyYaTelephonyParams();
  }
}

export function getEmptyIVRParams(): IVRDispatcherParams {
  return {
    callService: CallService.ivr,
  };
}

export function getEmptyVoximplantParams(): VoximplantDispatcherParams {
  return {
    callService: CallService.voximplant,
    accountId: "",
    apiKey: "",
    ruleId: 0,
    priority: 0,
    maxSimultaneous: 0,
    numAttempts: 0,
  };
}

export function getEmptyYaTelephonyParams(): YaTelephonyDispatcherParams {
  return {
    callService: CallService.ya,
    speechSilenceTimeoutMs: 5000,
    storeRecords: false,
    enableDtmf: false,
    recordOperator: false,
    fallbackOperator: "",
    callProcessingStartPauseMs: 0,
  };
}

export function getEmptySpeechKit(): SpeechKit {
  return {
    sttLanguages: [],
    defaultTtsLanguage: speechKitLanguages[0],
    eou: { },
    voices: [getEmptyVoiceLanguage()],
  };
}

export function getEmptyVoiceLanguage(): VoiceLanguage {
  return {
    id: generateId(),
    language: speechKitLanguages[0],
    voice: getEmptyVoice(),
  };
}

export function getEmptyVoice(): Voice {
  return {
    name: VoicePerson.jane,
    role: VoiceRole.good,
    speed: 1,
  };
}

export function isVoiceLanguageValid(value: VoiceLanguage) {
  const hasLanguage = !!value.language;

  if (!hasLanguage) return false;

  const personShouldExist = !!MAP_LANGUAGE_TO_PERSON[value.language];

  if (personShouldExist && !value.voice.name) return false;

  return true;
}

export function correctVoiceLanguage(value: VoiceLanguage): VoiceLanguage {
  const result: VoiceLanguage = deepClone(value);
  const { language, voice } = result;
  let { name, role } = voice;

  const defaultLanguage = speechKitLanguages[0];
  const defaultPerson = MAP_LANGUAGE_TO_PERSON[defaultLanguage] ? MAP_LANGUAGE_TO_PERSON[defaultLanguage][0] : null;
  const defaultRole = defaultPerson ? (MAP_PERSON_TO_ROLE[defaultPerson] || [])[0] : null;

  if (!language) {
    return {
      ...value,
      language: defaultLanguage,
      voice: {
        name: defaultPerson,
        role: defaultRole,
      },
    };
  }

  if (name) {
    const isNameCorrect = MAP_LANGUAGE_TO_PERSON[language].includes(name);
    if (!isNameCorrect) {
      name = MAP_LANGUAGE_TO_PERSON[language][0];
    }
  } else {
    name = MAP_LANGUAGE_TO_PERSON[language][0];
  }

  const personRoles = name ? MAP_PERSON_TO_ROLE[name] : null;
  const isRoleCorrect = Array.isArray(personRoles) ? personRoles.includes(role as VoiceRole) : role === null;

  if (!isRoleCorrect) {
    role = Array.isArray(personRoles) ? personRoles[0] : null;
  }

  return {
    ...result,
    language,
    voice: {
      name,
      role: role,
      speed: result.voice.speed,
    },
  };
}
