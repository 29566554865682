import React, { memo } from "react";

import { stopEvent } from "../../../services/helpers/utilities";

import './multiValueFloating.scss';

function MultiValueFloating({
  children,
  ...props
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) {
  const inputValue = props.selectProps.inputValue;

  if (inputValue) return null;

  return (
    <span className="nd-custom-select__multi-value-floating">
      {props.data.label}
      <button
        className="nd-custom-select__multi-value-remove-button"
        onMouseDown={stopEvent}
        onClick={props.removeProps.onClick}
      >
        &#215;
      </button>
    </span>
  );
}

export default memo(MultiValueFloating);
