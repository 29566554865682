import { FC, memo, useMemo } from 'react';
import { block } from 'bem-cn';

import Button from '../button';
import { getLocale } from './locale';
import { preventDefaultEvent } from '../../services/helpers/utilities';

type Props = {
  className: string;
  monthDate: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  customHeaderCount: number;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  monthsShown?: number;
};

const b = block('nd-datepicker-header');

const Header: FC<Props> = ({
  className,
  monthDate,
  decreaseMonth,
  increaseMonth,
  customHeaderCount,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
  monthsShown,
}) => {
  const { month, year } = useMemo(
    () => {
      const locale = getLocale();

      return {
        month: monthDate.toLocaleString(locale, { month: "long" }),
        year: monthDate.toLocaleString(locale, { year: "numeric" }),
      };
    },
    [monthDate],
  );

  return (
    <div className={`${b()} ${className}`}>
      {(monthsShown === undefined || customHeaderCount === 0) && (
        <Button
          disabled={prevMonthButtonDisabled}
          className={b('button-prev-month')}
          name="prev"
          bordered
          form="circle"
          icon="squareArrowLeft"
          onClick={decreaseMonth}
          // @ts-ignore
          onBlur={preventDefaultEvent}
        />
      )}
      <div className={b('month-and-year-buttons')}>
        <span className={b('title-month')}>
          {month}
        </span>
        <span className={b('title-year')}>
          {year}
        </span>
      </div>
      {(monthsShown === undefined || customHeaderCount === monthsShown - 1) && (
        <Button
          disabled={nextMonthButtonDisabled}
          className={b('button-next-month')}
          name="next"
          bordered
          form="circle"
          icon="squareArrowRight"
          onClick={increaseMonth}
        />
      )}
    </div>
  );
};

export default memo(Header);
