import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { block } from "bem-cn";

import { copyToClipboard } from "../../services/helpers/utilities";
import { ModalProps } from "../../services/modal";

import Button from "../../components-new/button";
import Message from "../../components-new/message";
import { IconSize } from "../../components-new/icon";

import Modal from "../components/ndModal";

import './styles.scss';

const b = block("user-created-modal");

export type UserCreatedModalProps = {
  username: string;
  password: string;
}

function UserCreatedModal({
  username,
  password,
  close,
}: UserCreatedModalProps & ModalProps) {
  const { t } = useTranslation();

  const handleCopy = useCallback(
    () => { copyToClipboard(`${username} ${password}`) },
    [password, username],
  );

  return (
    <Modal
      className={`${b()} nd-modal`}
      title={t("MODALS.USER_CREATED.TITLE")}
      showCancelButton={false}
      confirmationText={t("COMMON.CLOSE")}
      close={close}
      onConfirm={close}
    >
      <div className={b("body").mix("column-2")}>
        <span className={b("message")}>{t("MODALS.USER_CREATED.MESSAGE")}</span>
        <span className={b("password")}>
          <span>{username}</span>
          <span>{password}</span>
          <Button
            icon="documents"
            simple
            contrast
            iconSize={IconSize.smallPlus}
            onClick={handleCopy}
          />
        </span>
        <Message error className={b("warning")}>
          <span>{t("MODALS.USER_CREATED.WARNING")}</span>
        </Message>
      </div>
    </Modal>
  );
}

export default memo(UserCreatedModal);
