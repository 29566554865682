import React from 'react';
import { SvgProps } from '../types';

export default function ThumbUpFilled({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9 4.5C15.9 3 14.4178 2 13.2598 2C12.4544 2 12.3913 2.61249 12.2666 3.82083C12.212 4.35005 12.1457 4.99358 12 5.75C11.6145 7.75169 10.2799 10.3093 9.00439 11.0746V17C9 19.25 9.75 20 13 20H16.7726C18.9493 20 19.4763 18.5675 19.672 18.0357C19.6766 18.0233 19.681 18.0114 19.6852 18C19.7985 17.6939 20.0432 17.4535 20.3225 17.1791C20.6332 16.8739 20.9867 16.5266 21.25 16C21.5613 15.3774 21.5196 14.8228 21.4826 14.3299C21.4601 14.031 21.4394 13.7549 21.5 13.5C21.5642 13.2301 21.6463 13.0255 21.7252 12.8285C21.8676 12.4734 22 12.1432 22 11.5C22 10 21.2515 9.00169 19.6852 9.00169H15.5C15.5 9.00169 15.9 6 15.9 4.5ZM5.5 10C4.67157 10 4 10.6716 4 11.5V18.5C4 19.3284 4.67157 20 5.5 20C6.32843 20 7 19.3284 7 18.5V11.5C7 10.6716 6.32843 10 5.5 10Z"
        fill={color}
      />
    </svg>
  );
}
