import React from 'react';
import { components } from "react-select";

import { b } from '../index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SingleValue(props: any) {
  const { data: { label } } = props;

  return (
    <components.SingleValue {...props}>
      <span className={b("value-text").mix("crop-text")}>{label}</span>
    </components.SingleValue>
  );
}

export default React.memo(SingleValue);
