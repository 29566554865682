import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationManager from '../../services/notifications';
import { ModalProps } from '../../services/modal';

import Modal from '../components/ndModal';
import Input from '../../components-new/input';

export type TextInputModalProps = {
  inputName: string;
  inputPlaceholder: string;
  value: string;
  title?: string;
  newDesign?: boolean;
  cancelButtonText?: string;
  saveButtonText?: string;
  unavailableValues?: string[];
  unavailableValueError?: string;
  onSave: (value: string) => void;
  onCancel?: () => void;
};

export default function TextInputModalModal({
  inputName,
  inputPlaceholder,
  value: defaultValue,
  title = '',
  onSave,
  onCancel,
  close,
  unavailableValues,
  unavailableValueError,
}: TextInputModalProps & ModalProps) {
  const [value, setValue] = useState(defaultValue);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (_, { value: newValue }) => { setValue(newValue) },
    [],
  );

  const handleSaveClick = useCallback(
    () => {
      if (unavailableValues && unavailableValues.includes(value)) {
        NotificationManager.error(unavailableValueError || t('COMMON.INCORRECT_TEXT'));
        return;
      }

      onSave && onSave(value);

      close();
    },
    [
      close,
      onSave,
      t,
      unavailableValueError,
      unavailableValues,
      value,
    ],
  );

  const handleCloseClick = useCallback(
    () => {
      close();
    },
    [close],
  );

  useEffect(() => {
    return () => {
      onCancel && onCancel();
    };
  }, [onCancel]);

  return (
    <Modal
      title={title}
      cancelText={t("COMMON.CANCEL")}
      confirmationText={t("COMMON.SAVE")}
      confirmDisabled={!value.trim()}
      close={handleCloseClick}
      onCancel={handleCloseClick}
      onConfirm={handleSaveClick}
    >
      <div className="column-2">
        <Input
          name={inputName}
          fluid
          value={value}
          placeholder={inputPlaceholder}
          floatingPlaceholder
          autoFocus
          onChange={handleChange}
        />
      </div>
    </Modal>
  );
}
