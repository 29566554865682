import { generateId } from "../../../../../../../../services/helpers/generateId";
import { UserResponseOption } from "../../../../../../types";

export const getEmptyOption = (): UserResponseOption => ({ id: generateId(), text: '' });
export const getDefaultOptions = (): UserResponseOption[] => [getEmptyOption()];

export const checkIfLinkOptionsEmpty = (options?: UserResponseOption[]) => (
  !options?.length || (options.length === 1 && !options[0].text)
);

export enum DnDTypes {
  answerOption = 'answerOption',
}
