import React, { FC, useCallback } from 'react';
import { block } from "bem-cn";

import Icon from '../icon';

import './styles.scss';

const b = block("new-radio");

export type RadioProps = {
  size?: "small" | "medium";
  value: string,
  isChecked?: boolean,
  className?: string,
  name?: string,
  disabled?: boolean;
  onChange: (value: string) => void,
  onClick?: React.MouseEventHandler;
}

const Radio: FC<RadioProps> = ({
  className,
  isChecked = false,
  size = "small",
  value,
  name,
  disabled,
  onChange,
  onClick,
}) => {
  const handleInputChage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.value);
    },
    [onChange],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!onClick) return;

      onClick(e);
    },
    [onClick],
  );

  const iconSize = size === "medium" ? "24" : "20";

  return (
    <label className={b({ size }).mix(className)} onClick={handleClick}>
      <div className={b("wrapper")}>
        <input
          disabled={disabled}
          type="radio"
          className="hidden"
          checked={isChecked}
          name={name}
          value={value}
          onChange={handleInputChage}
        />
        <span className={b("checked-icon")}>
          <Icon type="checkmarkSmall" svgProps={{ height: iconSize, width: iconSize }} />
        </span>
      </div>
    </label>
  );
};

export default Radio;
