import React from 'react';
import { SvgProps } from '../types';

export default function ThumbDownFilled({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1 20.5C8.1 22 9.58217 23 10.7402 23C11.5456 23 11.6087 22.3875 11.7334 21.1792C11.788 20.65 11.8543 20.0064 12 19.25C12.3855 17.2483 13.7201 14.6907 14.9956 13.9254V8C15 5.75 14.25 5 11 5H7.22742C5.05073 5 4.52367 6.43245 4.32798 6.96431C4.32342 6.97671 4.31904 6.98861 4.31482 7C4.20146 7.30607 3.95678 7.54647 3.67746 7.82089C3.36679 8.12612 3.01329 8.47343 2.75 9C2.43872 9.62255 2.4804 10.1772 2.51744 10.6701C2.53989 10.969 2.56065 11.2451 2.5 11.5C2.43578 11.7699 2.35374 11.9745 2.27477 12.1715C2.1324 12.5266 2 12.8568 2 13.5C2 15 2.74847 15.9983 4.31482 15.9983H8.5C8.5 15.9983 8.1 19 8.1 20.5ZM18.5 15C19.3284 15 20 14.3284 20 13.5V6.5C20 5.67157 19.3284 5 18.5 5C17.6716 5 17 5.67157 17 6.5V13.5C17 14.3284 17.6716 15 18.5 15Z"
        fill={color}
      />
    </svg>
  );
}
