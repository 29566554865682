import { TranslateResponse } from "../../types/requests/Languages";
import { Lang } from "../../types/models/Languages";

import NotificationManager from "../../services/notifications";
import i18n from "../../services/i18n";
import apiService, { ApiResponse } from "../../services/api";

export const translate = async(text: string, targetLang: Lang) => {
  try {
    if (text === '') return text;

    const response: ApiResponse<TranslateResponse> = await apiService.translateText({
      target_language: targetLang,
      text,
    });

    return response.data.text;
  } catch {
    NotificationManager.error(i18n.t("ERRORS.API"));
    return '';
  }
};
