import React from "react";

import { SvgProps } from '../types';

function BellOutlineNoticeIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.99976H13L13.3476 4.0851C12.8411 4.62663 12.4536 5.28088 12.2269 6.00614C12.1517 6.0019 12.0761 5.99976 12 5.99976C10.0712 5.99976 8.41835 7.37916 8.07331 9.27688L7.32551 13.3898C7.05094 14.8999 6.21156 16.2333 5 17.1332V17.1627C5.25416 17.2551 5.60409 17.3584 6.06045 17.4602C7.50406 17.7823 9.60099 17.9998 12 17.9998C14.399 17.9998 16.4959 17.7823 17.9395 17.4602C18.3959 17.3584 18.7458 17.2551 19 17.1627V17.1332C17.7884 16.2333 16.9491 14.8999 16.6745 13.3898L16.5083 12.4759C16.6701 12.4917 16.8341 12.4998 17 12.4998C17.5239 12.4998 18.0291 12.4192 18.5036 12.2697L18.6422 13.032C18.8675 14.2707 19.6627 15.3311 20.7889 15.8942L21 15.9998V17.9998C21 18.79 18.9355 19.4771 15.9189 19.8013C15.763 20.5635 15.3868 21.2699 14.8284 21.8282C14.0783 22.5783 13.0609 22.9998 12 22.9998C10.9391 22.9998 9.92172 22.5783 9.17157 21.8282C8.61325 21.2699 8.23702 20.5635 8.08112 19.8013C5.0645 19.4771 3 18.79 3 17.9998V15.9998L3.21115 15.8942C4.33726 15.3311 5.13255 14.2707 5.35777 13.032L6.10557 8.9191C6.54016 6.52886 8.35687 4.68638 10.6408 4.15525L11 1.99976ZM12 19.9998C12.6006 19.9998 13.1868 19.9868 13.7533 19.962C13.663 20.1265 13.5493 20.2789 13.4142 20.414C13.0391 20.789 12.5304 20.9998 12 20.9998C11.4696 20.9998 10.9609 20.789 10.5858 20.414C10.4507 20.2789 10.337 20.1265 10.2467 19.962C10.8132 19.9868 11.3994 19.9998 12 19.9998Z"
        fill={color}
      />
      <path
        d="M20 7.49976C20 9.15661 18.6569 10.4998 17 10.4998C15.3431 10.4998 14 9.15661 14 7.49976C14 5.8429 15.3431 4.49976 17 4.49976C18.6569 4.49976 20 5.8429 20 7.49976Z"
        fill="#F5523A"
      />
    </svg>
  );
}

export default BellOutlineNoticeIcon;
