export type Message = {
  author?: MessageAuthor,
  text: string,
}

export enum MessageAuthor {
  Ai = 'ai',
  Support = 'support',
  User = 'user',
}

export const authorLabels = {
  [MessageAuthor.Ai]: "MESSAGES.AUTHOR_AI",
  [MessageAuthor.Support]: "MESSAGES.AUTHOR_SUPPORT",
  [MessageAuthor.User]: "MESSAGES.AUTHOR_USER",
};
