import { SagaIterator } from "redux-saga";
import { fork, put, select, take, takeLatest } from "redux-saga/effects";

import { RequestedFeature } from "../../types/backendModels/DialogBackend";
import { RequestMessage, ResponseMessage } from "../../types/models/Dialog";
import { ScenarioGraph } from "../../types/models/ScenarioGraph";
import { MessageAuthor } from "../../types/models/Message";
import { generateId } from "../../services/helpers/generateId";
import { getLiveChatId, getSavedUserMessage } from "../dialogs/selectors";
import i18n from "../../services/i18n";
import { getActiveGraph } from "../graph/selectors";

import * as dialogActions from "../dialogs/actions";
import * as graphActions from "../graph/actions";
import * as actions from './actions';

import {
  getFirstRequestedFeature,
  getRequestedFeatures,
  getActiveRequestedFeature,
  getUserMessageAndNodeMatches,
  MessageAndNodeMatches,
  getModifiedNodeId,
  checkIfTestingChatIsOpen,
} from "./selectors";
import { ScenarioGraphGroup } from "../../types/models/ScenarioGraphGroup";
import { getGraphGroups } from "../graphGroups/selectors";

function* postMessage(): SagaIterator {
  yield takeLatest(actions.postMessage, function* ({ payload }) {
    const activeFeature: RequestedFeature | null = yield select(getActiveRequestedFeature);

    const { text: defaultText, attachments, skipReset } = payload;

    if (activeFeature) {
      yield put(dialogActions.setFeatureDispatchValue({
        featureSlug: activeFeature.feature,
        dispatchValue: defaultText,
      }));

      yield put(actions.setActiveRequestedFeature(null));

      const message: RequestMessage = {
        id: generateId(),
        author: MessageAuthor.User,
        text: defaultText,
        attachments,
      };

      yield put(dialogActions.appendLiveMessage(message));
    }

    const requestedFeatures: RequestedFeature[] | null = yield select(getRequestedFeatures);

    if (requestedFeatures?.length) {
      yield put(actions.updateActiveRequestedFeature());
      return;
    }

    const savedMessage: RequestMessage | null = yield select(getSavedUserMessage);
    const text = savedMessage?.text ?? defaultText;

    yield put(
      dialogActions.sendLiveMessage.request({
        text,
        attachments,
        askFeatures: true,
        skipRequestMessage: !!savedMessage,
        checkVersionInterval: 500,
      }),
    );

    if (!skipReset) {
      yield put(dialogActions.resetFeatures());
    }
  });
}

function* updateActiveRequestedFeature(): SagaIterator {
  yield takeLatest(actions.updateActiveRequestedFeature, function* () {
    const feature: RequestedFeature | null = yield select(getFirstRequestedFeature);

    if (!feature) return;

    yield put(actions.setActiveRequestedFeature(feature));
    yield put(actions.removeFirstRequestedFeature());

    const message: ResponseMessage = {
      id: generateId(),
      author: MessageAuthor.Ai,
      text: `${i18n.t('GRAPH.TESTING_CHAT.FEATURES_MESSAGE')} ${feature.feature}`,
    };

    yield put(dialogActions.appendLiveMessage(message));
  });
}

function* deleteMessage(): SagaIterator {
  yield takeLatest(actions.deleteMessage, function* (action) {
    const messageIdToDelete = action.payload;
    const matches: MessageAndNodeMatches = yield select(getUserMessageAndNodeMatches);
    if (!matches || !messageIdToDelete) return;

    const i = matches.findIndex(match => match.userMessageId === messageIdToDelete);
    if (i === -1) return;

    const newMatches = matches.slice(0, i);

    if (!newMatches.length) {
      yield put(dialogActions.clearLiveChat());
      return;
    }

    const chatId: string = yield select(getLiveChatId);
    const newChatId = generateId();
    const cloneCount = newMatches.length;

    yield put(dialogActions.cloneDialog.request({
      chatId,
      newChatId,
      cloneCount,
      skipRouting: true,
    }));

    yield takeLatest(dialogActions.cloneDialog.success, function* ({ payload: dialog }) {
      const chatIsOpen: boolean = yield select(checkIfTestingChatIsOpen);
      if (!chatIsOpen) return;

      const newUserMessages = dialog.filter(message => message.author === MessageAuthor.User);

      if (!(newUserMessages.length && newMatches?.length)) {
        actions.setMessageAndNodeMatches(null);
        actions.setGraphPositionsStack([]);
        return;
      }

      const newGraphPositionsStack: string[] = newMatches
        .map(({ nodeId }) => nodeId)
        .filter(nodeId => nodeId != null) as string[];

      yield put(actions.setGraphPositionsStack(newGraphPositionsStack));

      yield put(actions.setMessageAndNodeMatches(newMatches.map(
        (item, j) => ({
          ...item,
          userMessageId: newUserMessages[j].id ?? item.userMessageId,
        }),
      )));
    });
  });
}

function* updateDialog(): SagaIterator {
  yield takeLatest(actions.updateChatOnGraphEdit, function* () {
    const modifiedNodeId: string | null = yield select(getModifiedNodeId);
    const matches: MessageAndNodeMatches = yield select(getUserMessageAndNodeMatches);
    const groups: ScenarioGraphGroup[] = yield select(getGraphGroups);

    const activeGraph: ScenarioGraph = yield select(getActiveGraph);
    yield put(graphActions.updateGraph.request({
      ...activeGraph,
      groups,
    }));
    yield take(graphActions.updateGraph.success);

    if (!modifiedNodeId || !matches?.length) {
      // Если нажали на сброс, но при этом никакая нода не была изменена
      // (например, создали а потом сразу удалили ноду),
      // то просто разблокируем чат
      yield put(actions.unlockChat());
      return;
    }

    const messageIdToDelete = matches.find(m => m.nodeId === modifiedNodeId)?.userMessageId;
    if (!messageIdToDelete) {
      // Тот же случай, что выше. Если нажат сброс
      // Но нечего удалять - просто разблокируем чат
      yield put(actions.unlockChat());
      return;
    }

    yield put(actions.deleteMessage(messageIdToDelete));
  });
}

export default function* graphChatSagas(): SagaIterator {
  yield fork(postMessage);
  yield fork(updateActiveRequestedFeature);
  yield fork(deleteMessage);
  yield fork(updateDialog);
}
