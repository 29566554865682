/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from "react";

import { SortDirection } from "../../types/models/Sorting";

export type DataItem = any; // Для читаемости (по сути это "строка данных в таблице")

// Функция сортировки элементов таблицы. Для правильной работы
// должна сортировать по убыванию (т.е. если itemA > itemB, функция должна вернуть -1)
export type SortingFunction = (itemA: DataItem, itemB: DataItem, sortingProp?: string) => number;

export type CellRendererFunction = (accessorValue: any) => ReactNode | undefined;
export type HeaderCellRendererFunction = (title: string) => ReactNode;

export type ColumnsConfiguration = (ColumnConfig | ColumnsGroup)[]

export type ColumnsGroup = {
  id: string;
  title: string;
  columns: ColumnConfig[];
}

export type ColumnConfig = {
  id: string;
  title: string;
  accessor: string;
  className?: string; // можно прописывать кастомную ширину столбца. Для этого помимо
                      // width нужно указывать ещё и flex-basis (TODO разобраться, мб можно
                      // выпилить, чтоб указывать только через width)
  loading?: boolean;
  width?: string;
  sortingConfig?: SortingConfig[];
  cellRenderer?: CellRendererFunction;
  headerCellRenderer?: HeaderCellRendererFunction;
  _group?: string;
  _groupStart?: boolean;
  _groupEnd?: boolean;
}

export type Sorting = {
  column: ColumnConfig['id'];
  sorting: SortingConfig['id'];
}

export type SortingConfig = {
  id: string; // Идентификатор сортировки
  label?: string; // Текст, который будет выводиться для пользователя.
                  // Необязательный параметр, т.к. бывают простые сортировки,
                  // где только 2 опции - по возрастанию и по убыванию. В этом случае,
                  // можно не передавать title. Если будет больше 2х сортировок без title, то будет
                  // работать некорректно
  dataKey?: string; // ключ, по которому нужно вытягивать данные. ВАЖНО: в функццию сортировки попадёт то,
                    // что извлекается в accessor'e столбца (ColumnConfig). dataKey внутри конфига сортировки
                    // нужен в случае, если конфиг столбца возвращает объект
                    // Если оставить поле пустым, то будет использоваться то, что возвращает конфиг столбца
  direction: SortDirection;
  sortFunction?: SortingFunction;
  deepSort?: boolean;
};

export type CustomSortingCellData = HeaderItem
  & Required<Pick<HeaderItem, 'activeSorting' | 'availableSortings'>>;

export type HeaderConfiguration = HeaderItem[]

export type HeaderItem = {
  id: string;
  title: string;
  className?: string;
  activeSorting?: SortingConfig['id'];
  availableSortings?: SortingConfig[];
  group?: string;
  groupStart?: boolean;
  groupEnd?: boolean;
  renderer: HeaderCellRendererFunction;
}

export type HeaderGroup = {
  id: string;
  title: string;
  items: HeaderItem[];
}

export enum DataItemType {
  item = 'item',
  folder = 'folder',
}

export type RowStatus = "success" | "warning" | "error" | "default";
export type StatusCallback = (item: DataItem) => RowStatus;
