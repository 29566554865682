import { v4 as uuidv4 } from "uuid";

import i18n from '../../../services/i18n';
import { ScenarioGroupName } from "../../../types/backendModels/ScenarioGraphBackend";
import { ScenarioGraph } from "../../../types/models/ScenarioGraph";

import {
  ScenarioGraphAction,
  SelectScenarioAction,
  ScenarioGraphActionType,
  ResponseAction,
} from "../../../types/models/ScenarioGraphAction";

import { ScenarioGraphNode } from "../../../types/models/ScenarioGraphNode";
import { ScenarioTag } from "../../../types/models/ScenarioTag";
import { FALLBACK_LINK_INDEX } from "../constants";

import {
  Node, Link, ObjectLinks, NodeTypes, Option,
} from '../types';

export type GroupedByNodeIdLinks = Record<Node['id'], Link[]>;
export const groupLinksByNodeId = (objectLinks: ObjectLinks, key?: 'from' | 'to'): GroupedByNodeIdLinks => {
  const result: GroupedByNodeIdLinks = {};

  Object.values(objectLinks).forEach(link => {
    const from = link?.bind?.[key || 'from'];
    if (!from) return;

    result[from] = [...(result[from] || []), link];
  });

  return result;
};

export const TAG_PLAY = { id: '', slug: 'play' };
export const TAG_SPEAK = { id: '', slug: 'speak' };

export const checkIfNodeFinal = (nodeType: NodeTypes) => (
  nodeType === NodeTypes.operator || nodeType === NodeTypes.close
);

export const checkIfNodeFirst = (node: ScenarioGraphNode) => node.meta.x === 0 && node.meta.y === 0;

export const createNewLink = <T extends Link>({
  nodeFromId,
  nodeToId = '',
  optionId,
  label,
}: {
  nodeFromId: Node['id'],
  nodeToId?: Node['id'],
  optionId?: Option['id'],
  label?: string,
}): T => (
  {
    id: uuidv4(),
    bind: {
      from: nodeFromId,
      to: nodeToId,
    },
    optionId,
    label,
    meta: {
      error: false,
      errorDescription: '',
    },
  } as T);

export const createFallbackLink = (
  nodeFromId: Node['id'],
  nodeToId?: Node['id'],
  label: string = i18n.t("GRAPH.ACTION_RESPONSE.FALLBACK_LINK_INDEX"),
) => (
  createNewLink({
    nodeFromId,
    nodeToId,
    optionId: FALLBACK_LINK_INDEX,
    label,
  })
);

export const getNodeTitle = (node: Node) => {
  if (node.type === NodeTypes.selectScenario) {
    const action = node.meta.action as SelectScenarioAction;
    return i18n.t("GRAPH.ACTION_SELECT_SCENARIO.SELECTED_SCENARIOS", { count: action.scenarios.length });
  }

  return node.meta.title;
};

export const ACTIONS_WITH_COUNTER = [
  ScenarioGraphActionType.integrationAction,
  ScenarioGraphActionType.condition,
  ScenarioGraphActionType.response,
  ScenarioGraphActionType.custom,
  ScenarioGraphActionType.changeState,
];

export const getGraphNodeAction = (
  node: ScenarioGraphNode,
  withFallbackLink?: boolean,
): ScenarioGraphAction | undefined => {
  if (!node.action) return undefined;

  const result: ScenarioGraphAction = { ...node.action };

  if (ACTIONS_WITH_COUNTER.includes(node.action.type)) {
    // @ts-ignore
    result.counter = node.counter || 1;
  }

  if (result.type === ScenarioGraphActionType.response && withFallbackLink) {
    result.withFallbackLink = withFallbackLink;
  }

  return result;
};

export const getNodeCounter = (node: Node): number | undefined => (
  // @ts-ignore
  node.meta.action.counter
);

export const convertTagToScenarioTag = (tag: string): ScenarioTag => ({
  id: '',
  slug: tag,
});

export const convertScenarioTagToTag = (tag: ScenarioTag): string => tag.slug;

export const isFallbackRequired = (node: Node) => (
  node.type === NodeTypes.response && !!(node.meta.action as ResponseAction).withFallbackLink
);

export const isFeedbackGraph = ({ groupName }: ScenarioGraph) => (
  groupName === ScenarioGroupName.feedback
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual(objA: any, objB: any) {
  if (objA === objB) {
    return true;
  }

  if (objA === null || typeof(objA) !== "object" || objB === null || typeof(objB) !== "object") {
    return false;
  }

  let propertiesInA = 0;
  let propertiesInB = 0;
  for (let _ in objA) {
    propertiesInA += 1;
  }
  for (let property in objB) {
    propertiesInB += 1;
    if (!(property in objA) || !deepEqual(objA[property], objB[property])) {
      return false;
    }
  }
  return propertiesInA === propertiesInB;
}

export function parseDebugIdQueryParam(queryParam: string): string | string[] {
  try {
    if (queryParam.startsWith("[")) {
      const idList = JSON.parse(queryParam);
      return idList;
    }

    return queryParam;
  } catch {
    return "";
  }
}
