import React from 'react';

import authorizedPages from '../../routes/authorized';
import navigationService from '../../services/navigation';
import i18n from '../../services/i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class ErrorBoundary extends React.Component<any, {
  error: Error | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorInfo: any,
}> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: React.PropsWithChildren<any>) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  navigateToMainPage() {
    navigationService.navigateTo(authorizedPages.account);
    window.location.reload();
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            padding: '1rem',
          }}
        >
          <h2>{i18n.t("ERRORS.COMMON.SOMETHING_WENT_WRONG")}</h2>
          <span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {i18n.t("ERRORS.COMMON.RELOAD_PAGE")} <a href="#" onClick={this.navigateToMainPage}>{i18n.t("ERRORS.COMMON.OR_NAVIGATE_TO_MAIN")}</a>
          </span>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
