import { SagaIterator } from 'redux-saga';
import {
  call, fork, put, select, takeLatest,
} from 'redux-saga/effects';

import {
  AddFavoriteFeatureRequest,
  CreateFeatureRequest,
  CreateFeatureResponse,
  DeleteFavoriteFeatureRequest,
  DeleteFeatureRequest,
  FeaturesRequest,
  FeaturesResponse,
  GetFavoriteFeaturesResponse,
  UpdateFeatureRequest,
  UpdateFeatureResponse,
} from '../../types/requests/Features';
import Api, { ApiResponse } from '../../services/api';
import { Feature } from '../../types/models/Feature';
import { parseFeature, parseFeatureToBackend } from '../../types/parsers/FeatureParser';

import * as actions from './actions';
import i18n from '../../services/i18n';
import { ProjectRequestParams } from '../../types/requests/Projects';
import { getGeneralRequestParams } from '../selectors';
import { ErrorAction } from '../reducers';
import modalService from '../../services/modal';

function* loadFeatures(): SagaIterator {
  yield takeLatest(actions.loadFeatures.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const params: FeaturesRequest = general;

      const { data }: ApiResponse<FeaturesResponse> = yield call(Api.getFeatures, params);
      const parsedFeatures: Feature[] = data.features.map(feature => parseFeature(feature));
      yield put(actions.loadFeatures.success(parsedFeatures));
    } catch (err) {
      yield put(actions.loadFeatures.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.FEATURES.MULTIPLE") }),
      )));
    }
  });
}

function* createFeature(): SagaIterator {
  yield takeLatest(actions.createFeature.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const feature: Feature = {
        ...action.payload.feature,
        id: '',
      };

      const params: CreateFeatureRequest = {
        ...general,
        feature: parseFeatureToBackend(feature),
      };

      const response: ApiResponse<CreateFeatureResponse> = yield call(Api.createFeature, { ...params });
      const parsedFeature = parseFeature(response.data);
      yield put(actions.createFeature.success(parsedFeature));
      yield put(actions.deleteNewFeature(action.payload.feature.id));

      const { modalId } = action.payload;
      if (modalId) {
        modalService.close(modalId);
      }
    } catch (err) {
      yield put(actions.createFeature.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.FEATURES.SINGLE") }),
      )));
    }
  });
}

function* deleteFeature(): SagaIterator {
  yield takeLatest(actions.deleteFeature.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const featureId = action.payload;
      const params: DeleteFeatureRequest = {
        featureId,
        ...general,
      };

      yield call(Api.deleteFeature, params);
      yield put(actions.deleteFeature.success(featureId));
    } catch (err) {
      yield put(actions.deleteFeature.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.FEATURES.SINGLE") }),
      )));
    }
  });
}

function* updateFeature(): SagaIterator {
  yield takeLatest(actions.updateFeature.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const preparedFeature: UpdateFeatureRequest = {
        ...general,
        feature: {
          ...parseFeatureToBackend(action.payload),
        },
      };

      const response: ApiResponse<UpdateFeatureResponse> = yield call(Api.updateFeature, preparedFeature);
      const parsedFeature = parseFeature(response.data);
      yield put(actions.updateFeature.success(parsedFeature));
    } catch (err) {
      yield put(actions.updateFeature.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.FEATURES.SINGLE") }),
        { ...action.payload },
      )));
    }
  });
}

function* loadFavoriteFeatures(): SagaIterator {
  yield takeLatest(actions.loadFavoriteFeatures.request, function* () {
    try {
      const response: ApiResponse<GetFavoriteFeaturesResponse> = yield call(Api.getFavoriteFeatures);

      yield put(actions.loadFavoriteFeatures.success(response.data.favorite_features.map(el => el.name)));
    } catch (err) {
      yield put(actions.loadFavoriteFeatures.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.FEATURES.MULTIPLE") }),
      )));
    }
  });
}

function* updateFavoriteFeatures(): SagaIterator {
  yield takeLatest(actions.addFavoriteFeature.request, function* (action) {
    try {
      const request: AddFavoriteFeatureRequest = {
        body: {
          name: action.payload,
        },
      };

      yield call(Api.addFavoriteFeature, request);

      yield put(actions.addFavoriteFeature.success(action.payload));
    } catch (err) {
      yield put(actions.addFavoriteFeature.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.FEATURES.SINGLE") }),
        action.payload,
      )));
    }
  });
}

function* deleteFavoriteFeature(): SagaIterator {
  yield takeLatest(actions.deleteFavoriteFeature.request, function* (action) {
    try {
      const request: DeleteFavoriteFeatureRequest = {
        favorite_feature: action.payload,
      };

      yield call(Api.deleteFavoriteFeature, request);

      yield put(actions.deleteFavoriteFeature.success(action.payload));
    } catch (err) {
      yield put(actions.deleteFavoriteFeature.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.FEATURES.MULTIPLE") }),
        action.payload,
      )));
    }
  });
}

export default function* featuresSagas(): SagaIterator {
  yield fork(loadFeatures);
  yield fork(createFeature);
  yield fork(deleteFeature);
  yield fork(updateFeature);
  yield fork(loadFavoriteFeatures);
  yield fork(updateFavoriteFeatures);
  yield fork(deleteFavoriteFeature);
}
