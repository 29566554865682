import { PayloadAction } from "typesafe-actions";

import ApiError from "../../types/models/ApiError";
import { NotificationMode } from "../../types/models/Notification";

import NotificationManager from "../../services/notifications";
import access, { Capability } from "../../services/access";
import { copyToClipboard } from "../../services/helpers/utilities";
import i18n from "../../services/i18n";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export const errorsMiddleware = (store: any) => (next: any) => (action: PayloadAction<string, any>) => {
  const { payload } = action;

  if (payload && payload.error) {
    if (payload.error instanceof ApiError) {
      const error = payload.error as ApiError;
      const intent = payload.intent || i18n.t('ERRORS.COMMON.ERROR');
      const traceText = i18n.t('ERRORS.COMMON.TRACE');
      const accessToHideInternalErrors = access.hasAccessTo(Capability.hideInternalErrors);
      const internalError = error.status.toString().startsWith('5');

      if ((!accessToHideInternalErrors || (accessToHideInternalErrors && !internalError))) {
        NotificationManager.error(
          `${intent}: ${error.message}\n${traceText}: ${error.traceId}`,
          {
            mode: NotificationMode.button,
            action: {
              text: i18n.t("COMMON.COPY"),
              onClick: () => copyToClipboard(`${error.traceId} : ${error.message}`),
            },
          },
        );
      }
    } else {
      NotificationManager.error(payload.error.toString());
    }
  }

  return next(action);
};
