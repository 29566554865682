import React, { useCallback, useEffect, useRef } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { block } from 'bem-cn';

import { SidebarItem } from '../config';
import Icon from '../../../components-new/icon';

import { EVENT_ACCESS_CHANGED } from '../../../services/access';
import { AppState } from '../../../redux/reducers';
import * as actions from '../../../redux/sidebar/actions';
import { useForceUpdate } from '../../../hooks/useForceUpdate';
import { getIsSidebarItemOpened, getSidebarOpenStatus } from '../../../redux/sidebar/selectors';
import navigationService from '../../../services/navigation';
import { itemCanBeRendered } from './helpers';
import postYaMetric, { YaMetricsActions } from '../../../services/ya-metrics';
import { getPagePath } from '../../../routes/authorized';

import './styles.scss';

const b = block("sidebar-item");

export type SidebarItemProps = {
  value: SidebarItem;
  activeItemId?: string;
  level?: number;
}

function SidebarItemComponent({
  value,
  level = 0,
  opened = false,
  activeItemId,
  close,
  open,
}: SidebarItemProps & ReduxProps) {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const navHelperPositionTop = useRef<number>(0);

  const sidebarOpened = useSelector(
    getSidebarOpenStatus,
    shallowEqual,
  );

  useEffect(() => {
    window.addEventListener(EVENT_ACCESS_CHANGED, forceUpdate);

    return () => window.removeEventListener(EVENT_ACCESS_CHANGED, forceUpdate);
  });

  const handleExpandClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      if (opened) {
        close(value.id);
      } else {
        open(value.id);
      }
    },
    [close, open, opened, value.id],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (event.defaultPrevented) {
        return;
      }
      event.preventDefault();
      if (value.url) {
        navigationService.navigateTo(value.url);
        const pagePath = getPagePath(value.url);
        postYaMetric(YaMetricsActions.onSidebarPageClick + pagePath as YaMetricsActions);
      } else {
        handleExpandClick(event);
      }
    },
    [handleExpandClick, value.url],
  );

  const handleNavigationHelperClick = useCallback(
    (event: React.MouseEvent, item: SidebarItem) => {
      event.preventDefault();
      if (item.url) {
        navigationService.navigateTo(item.url);
        const pagePath = getPagePath(item.url);
        postYaMetric(YaMetricsActions.onSidebarPageClick + pagePath as YaMetricsActions);
      }
    },
    [],
  );

  const adjustNavHelper = useCallback(
    (event: React.MouseEvent) => {
      const item = event.currentTarget;
      const box = item.getBoundingClientRect();
      navHelperPositionTop.current = box.y - 6;
      forceUpdate();
    },
    [forceUpdate],
  );

  const hasChildren = value.children && value.children?.length > 0;

  if (!itemCanBeRendered(value)) {
    return null;
  }

  const Component = value.url ? "a" : "span";
  return (
    <>
      <Component
        href={value.url}
        className={b({
          child: level > 0,
          active: value.id === activeItemId,
          opened: hasChildren && opened,
        })}
        onClick={handleClick}
        onMouseEnter={adjustNavHelper}
      >
        {value.icon && (
        <div className={b("icon-wrapper")}>
          <Icon
            className={b("icon")}
            type={value.icon}
          />
        </div>
        )}
        {sidebarOpened && (
          <>
            <span>
              {t(value.label)}
            </span>
            {hasChildren && (
            <div className={b("expand-icon-wrapper")}>
              <Icon
                className={b("expand-icon")}
                type="chevronDown"
                onClick={handleExpandClick}
            />
            </div>
            )}
          </>
        )}
        {!sidebarOpened && (
          <div
            style={{ top: navHelperPositionTop.current + "px" }}
            className={b("navigation-helper")}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {hasChildren ? value.children?.map(child => (
              itemCanBeRendered(child) ? (
                <a
                  key={child.id}
                  className={b("navigation-helper-item")}
                  href={child.url}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={e => handleNavigationHelperClick(e, child)}
                >
                  {t(child.label)}
                </a>
              ) : null
            )) :
              value.id !== activeItemId ?
                <a
                  key={value.id}
                  className={b("navigation-helper-item")}
                  href={value.url}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={e => handleNavigationHelperClick(e, value)}
                >
                  {t(value.label)}
                </a> : null}
          </div>
        )}
      </Component>
      {sidebarOpened && hasChildren && opened && value.children?.map(children => (
        <ConnectedSidebarItemComponent
          key={children.id}
          value={children}
          level={level + 1}
          activeItemId={activeItemId}
        />
      ))}
    </>
  );
}

type MapStateToProps = {
  opened?: boolean,
}

const mapStateToProps = (state: AppState, ownProps: SidebarItemProps): MapStateToProps => ({
  opened: getIsSidebarItemOpened(state, ownProps),
});

type MapDispatchToProps = {
  close: typeof actions.closeItem,
  open: typeof actions.openItem,
}

const mapDispatchToProps: MapDispatchToProps = {
  close: actions.closeItem,
  open: actions.openItem,
};

type ReduxProps = MapStateToProps & MapDispatchToProps;

const ConnectedSidebarItemComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarItemComponent);

export default ConnectedSidebarItemComponent;
