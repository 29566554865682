import NotificationManager from "../../../../../../services/notifications";
import { Lang, Language } from "../../../../../../types/models/Languages";
import { ResponseAction } from "../../../../../../types/models/ScenarioGraphAction";
import i18n from "../../../../../../services/i18n";
import { translate } from "../../../../../../redux/languages/helpers";
import { TranslatedTexts } from './types';
import apiService, { ApiResponse } from "../../../../../../services/api";
import { GenerateTextsResponse } from "../../../../../../types/requests/Generate";
import { UserResponseOption } from "../../../../types";
import { getEmptyOption } from "./components/userAnswerOptions/helpers";
import { deepClone } from "../../../../../../services/helpers/utilities";

export const AUDIO_FILE_TEXT_PREFIX = "__AUDIOFILE__"; //
// Костыль, связанный с тем, как мы задаём синтезированный текст и предзаписи.
// У реплик бота есть поле texts, где мы храним либо генерируемый текст,
// либо имя файла предзаписи. Когда мы получаем реплику бота, нам нужно определить,
// что там хранится - текст либо предзапись. Можно было бы сравнивать полученный текст
// со всеми известными предзаписями, но на момент открытия реплики бота они могут быть не загружены
// + получается коллизия в случае, если в качестве сгенерируемого текста введем имя предзаписи,
// тогда невозможно корректно определить, ввели мы текст или выбрали предзапись.
// Для этого предлагаю ввести префикс, который будем клеить тексту в случае, если выбрали предзапись.
// Перед отправкой на сервер этот префикс убирается (в функции convertNodesAndLinksToBackendGraph),
// Также при получении с сервера если у ноды есть тег play, то к тексту клеим префикс, чтобы обозначить,
// что это предзапись
// TODO по-хорошему нужно рефакторить. Скорее всего лучше переделать сначала на сервере, чтобы за предзапись
// отвечало отдельное поле, а texts отвечало только за генерируемый текст.

export function isAudioFileInTexts(texts: TranslatedTexts) {
  return Object.keys(texts).some(lang => (
    texts[lang as Lang].some(text => text.startsWith(AUDIO_FILE_TEXT_PREFIX))
  ));
}

export function getAudioTexts(filename: string) {
  return [AUDIO_FILE_TEXT_PREFIX + filename];
}

export function getAudioFilename(texts: TranslatedTexts, lang: Lang = Lang.ru) {
  if (isAudioFileInTexts(texts) && texts[lang]) {
    return texts[lang][0].slice(AUDIO_FILE_TEXT_PREFIX.length) || '';
  }

  return '';
}

export function addAudioPrefix(filename: string) {
  return AUDIO_FILE_TEXT_PREFIX + filename;
}

export function removeAudioPrefix(filename: string) {
  return filename.startsWith(AUDIO_FILE_TEXT_PREFIX) ?
    filename.slice(AUDIO_FILE_TEXT_PREFIX.length) :
    filename;
}

export const getSelectedLanguages = (
  action: ResponseAction,
  languages: Language[],
): Language[] => Object.keys(action.texts || action.clarifyTexts || {}).map(slug => (
  languages.find(l => l.slug === slug) || { name: slug, slug: slug as Lang }
));

export const deleteTranslatedTexts = (
  action: ResponseAction,
  deletedLangSlug: Lang,
): ResponseAction => {
  const {
    texts: newTexts = {} as TranslatedTexts,
    clarifyTexts: newClarifyTexts = {} as TranslatedTexts,
    userAnswerOptions: newUserAnswerOptions = {} as Record<Lang, UserResponseOption[]>,
  } = action;

  delete newTexts[deletedLangSlug];
  delete newClarifyTexts[deletedLangSlug];
  delete newUserAnswerOptions[deletedLangSlug];

  return {
    ...action,
    texts: newTexts,
    clarifyTexts: newClarifyTexts,
    userAnswerOptions: newUserAnswerOptions,
  };
};

export const deleteAllTranslatedTexts = (
  action: ResponseAction,
  mainLang: Lang,
): ResponseAction => {
  const copy = deepClone(action);

  Object.keys(copy.texts || {}).forEach(lang => {
    if (lang !== mainLang && copy.texts && copy.texts[lang as Lang]) {
      delete copy.texts[lang as Lang];
    }
  });

  Object.keys(copy.clarifyTexts || {}).forEach(lang => {
    if (lang !== mainLang && copy.clarifyTexts && copy.clarifyTexts[lang as Lang]) {
      delete copy.clarifyTexts[lang as Lang];
    }
  });

  Object.keys(copy.userAnswerOptions || {}).forEach(lang => {
    if (lang !== mainLang && copy.userAnswerOptions && copy.userAnswerOptions[lang as Lang]) {
      delete copy.userAnswerOptions[lang as Lang];
    }
  });

  return copy;
};

export const translateTexts = (texts: string[], targetLang: Lang) => (
  Promise.all(texts.map(t => translate(t, targetLang)))
);

export const addNewTexts = async(
  action: ResponseAction,
  newLangSlug: Lang,
  mainLanguageSlug: Lang,
): Promise<ResponseAction> => {
  const {
    texts: newTexts,
    clarifyTexts: newClarifyTexts,
    userAnswerOptions: newUserAnswerOptions,
  } = action;

  if (mainLanguageSlug === newLangSlug) {
    return action;
  }

  const newAction: ResponseAction = { ...action };

  if (newTexts && !isTextsEmpty(newTexts)) {
    newAction.texts = {
      ...(newAction.texts || {}),
      [newLangSlug]: await translateTexts(newTexts[mainLanguageSlug], newLangSlug),
    } as TranslatedTexts;
  }

  if (newClarifyTexts && !isTextsEmpty(newClarifyTexts)) {
    newAction.clarifyTexts = {
      ...(newAction.clarifyTexts || {}),
      [newLangSlug]: await translateTexts(newClarifyTexts[mainLanguageSlug], newLangSlug),
    } as TranslatedTexts;
  }

  if (newUserAnswerOptions) {
    const translatedOptions = await getTranslatedUserAnswerOptions({
      languages: [newLangSlug],
      userAnswerOptions: newUserAnswerOptions[mainLanguageSlug],
    });

    newAction.userAnswerOptions = {
      ...(newAction.userAnswerOptions || {} as Record<Lang, UserResponseOption[]>),
      ...translatedOptions,
    };
  }

  return newAction;
};

export const getSimilarText = async(text: string) => {
  try {
    if (text === '') return text;

    const response: ApiResponse<GenerateTextsResponse> = await apiService.generateTexts({ text });

    return response.data.texts[0];
  } catch {
    NotificationManager.error(i18n.t("ERRORS.API.SCENARIOS.GENERATE_TEXT"));
    return '';
  }
};

export async function getTranslatedTextsForMain<T = string>(
  defaultTexts: string[],
  languages: Lang[],
  mapper?: (s: string) => T,
) {
  const translated = await Promise.all(
    languages.map(
      async lang => ({
        lang,
        texts: await translateTexts(defaultTexts, lang as Lang),
      }),
    ),
  );

  const newTexts: Record<string, T[]> = {};
  translated.forEach(({ lang, texts }) => {
    newTexts[lang] = (mapper ? texts.map(mapper) : texts) as T[];
  });

  return newTexts as Record<Lang, T[]>;
}

export const getTranslatedUserAnswerOptions = async({
  languages,
  userAnswerOptions,
} : {
  userAnswerOptions: UserResponseOption[];
  languages: Lang[];
}) => {
  if (!userAnswerOptions) return undefined;

  const textsToTranslate = userAnswerOptions.map(({ text }) => text);

  return getTranslatedTextsForMain<UserResponseOption>(
    textsToTranslate,
    languages,
    text => ({ ...getEmptyOption(), text }),
  );
};

export const getTranslatedTexts = async(
  texts: string[],
  currentTranslatedTexts: TranslatedTexts,
  activeLanguage: Lang,
  mainLanguage: Lang,
) => {
  const isMainLanguage = activeLanguage === mainLanguage;

  if (isMainLanguage) {
    const langs = Object.keys(currentTranslatedTexts) as Lang[];
    return getTranslatedTextsForMain(texts, langs);
  }

  return {
    ...currentTranslatedTexts,
    [activeLanguage]: await translateTexts(texts, activeLanguage),
  } as TranslatedTexts;
};

export function getCountOfLanguages(texts: TranslatedTexts): number {
  return Object.keys(texts).length;
}

export function isTextsEmpty(texts: TranslatedTexts): boolean {
  return Object.keys(texts).every(lang => texts[lang as Lang].every(text => !text));
}
