import React from 'react';
import { Route, Switch } from 'react-router';

import PrivateRoute from './privateRoute';
import UnauthorizedLayout from '../../layouts/unauthorized';
import AuthorizedLayout from '../../layouts/authorized';
import { AUTHORIZED_ROOT } from '../../routes/authorized';
import { UNAUTHORIZED_ROOT } from '../../routes/unauthorized';

const Routes = () => (
  <Switch>
    <Route path={UNAUTHORIZED_ROOT} component={UnauthorizedLayout} />
    <PrivateRoute path={AUTHORIZED_ROOT}>
      <AuthorizedLayout />
    </PrivateRoute>
  </Switch>
);

export default Routes;
