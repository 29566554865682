import { generateId } from "../../services/helpers/generateId";
import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ChoiceFromVariantsExtractorBackend, CustomExtractorBackend, EntityBackend, EntityChoiceVariantBackend, EntityExtractorBackend, RegularExpressionExtractorBackend } from "../backendModels/EntityBackend";
import { ChoiceFromVariantsExtractor, CustomExtractor, Entity, EntityChoiceVariant, EntityExtractor, ExtractorType, RegularExpressionExtractor } from "../models/Entity";

export function parseEntity(value: EntityBackend): Entity {
  return {
    ...objSnakeToCamel(value),
    extractor: parseEntityExtractor(value.extractor),
    pending: false,
  };
}

export function parseEntityExtractor(value: EntityExtractorBackend): EntityExtractor {
  if (value.type === ExtractorType.choiceFromVariants) {
    return parseChoiceFromVariantsExtractor(value);
  } else if (value.type === ExtractorType.regularExpression) {
    return parseRegularExpressionExtractor(value);
  }
  return parseCustomExtractor(value);
}

export function parseChoiceFromVariantsExtractor(
  value: ChoiceFromVariantsExtractorBackend,
): ChoiceFromVariantsExtractor {
  return {
    type: ExtractorType.choiceFromVariants,
    variants: value.variants.map(variant => parseEntityChoiceVariant(variant)),
  };
}

export function parseRegularExpressionExtractor(value: RegularExpressionExtractorBackend): RegularExpressionExtractor {
  return {
    type: ExtractorType.regularExpression,
    regularExpression: value.regular_expression,
  };
}

export function parseCustomExtractor(value: CustomExtractorBackend): CustomExtractor {
  return {
    type: ExtractorType.custom,
    extractorType: value.extractor_type,
  };
}

export function parseEntityToBackend(value: Entity): EntityBackend {
  return {
    ...objCamelToSnake(value),
    extractor: parseEntityExtractorToBackend(value.extractor),
  };
}

export function parseEntityExtractorToBackend(value: EntityExtractor): EntityExtractorBackend {
  if (value.type === ExtractorType.regularExpression) {
    return parseRegularExpressionExtractorToBackend(value);
  } else if (value.type === ExtractorType.choiceFromVariants) {
    return parseChoiceFromVariantsExtractorToBackend(value);
  }
  return parseCustomExtractorToBackend(value);
}

export function parseRegularExpressionExtractorToBackend(
  value: RegularExpressionExtractor,
): RegularExpressionExtractorBackend {
  return {
    type: ExtractorType.regularExpression,
    regular_expression: value.regularExpression,
  };
}

export function parseChoiceFromVariantsExtractorToBackend(
  value: ChoiceFromVariantsExtractor,
): ChoiceFromVariantsExtractorBackend {
  return {
    type: ExtractorType.choiceFromVariants,
    variants: value.variants.map(variant => parseEntityChoiceVariantToBackend(variant)),
  };
}

export function parseCustomExtractorToBackend(value: CustomExtractor): CustomExtractorBackend {
  return {
    type: ExtractorType.custom,
    extractor_type: value.extractorType,
  };
}

export function parseEntityChoiceVariant(value: EntityChoiceVariantBackend): EntityChoiceVariant {
  return {
    regularExpression: value.regular_expression,
    value: value.value,
    id: generateId(),
  };
}

export function parseEntityChoiceVariantToBackend(value: EntityChoiceVariant): EntityChoiceVariantBackend {
  return {
    regular_expression: value.regularExpression,
    value: value.value,
  };
}
