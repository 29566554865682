import React, { FC } from 'react';
import { format } from 'date-fns';

import './styles.scss';

type AudioMetaProps = {
  filename: string,
  duration?: number, // seconds
  subtitle?: string,
}

const AudioMeta: FC<AudioMetaProps> = ({
  filename,
  duration,
  subtitle,
}) => {
  const getDuration = (): string => {
    if (!duration || isNaN(duration)) {
      return '';
    }

    return format(new Date(Math.ceil(duration * 1000)), 'mm:ss');
  };

  return (
    <div className="audio-meta">
      <div className="audio-meta__title">
        <span className="crop-text">
          {filename}
          {typeof duration === 'number' &&
            !isNaN(duration) &&
            <span className="audio-meta__duration">
                {getDuration()}
            </span>
          }
        </span>
      </div>
      <div className="audio-meta__subtitle crop-text">
        <span>{subtitle}</span>
      </div>
    </div>
  );
};

export default AudioMeta;
