import React, { FC } from 'react';
import { SvgProps } from '../types';

const FiltersIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="6" width="11" height="2" rx="0.5" fill={color} />
    <rect x="2" y="6" width="3" height="2" rx="0.5" fill={color} />
    <rect x="3" y="16" width="10" height="2" rx="0.5" fill={color} />
    <rect x="19" y="16" width="3" height="2" rx="0.5" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 7C6 8.10457 6.89543 9 8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7ZM8 3C5.79086 3 4 4.79086 4 7C4 9.20914 5.79086 11 8 11C10.2091 11 12 9.20914 12 7C12 4.79086 10.2091 3 8 3Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M14 17C14 18.1046 14.8954 19 16 19C17.1046 19 18 18.1046 18 17C18 15.8954 17.1046 15 16 15C14.8954 15 14 15.8954 14 17ZM16 13C13.7909 13 12 14.7909 12 17C12 19.2091 13.7909 21 16 21C18.2091 21 20 19.2091 20 17C20 14.7909 18.2091 13 16 13Z" fill={color} />
  </svg>
);

export default FiltersIcon;
