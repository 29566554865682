import { subDays } from "date-fns";
import { ActionType, createReducer } from "typesafe-actions";
import { Reducer } from "redux";

import { PreviousAnswer, PreviousAnswerDialog, PreviousAnswerFilters } from "../../types/models/PreviousAnswer";
import { Task } from "../../types/models/Task";

import * as actions from "./actions";

type PreviousAnswerState = {
  filters: PreviousAnswerFilters;
  list: {
    value: PreviousAnswer[];
    loading: boolean;
  },
  dialogs: {
    value: PreviousAnswerDialog[],
    loading: boolean;
  },
  activeDialog: string | null;
  exportHistory: {
    list: Task[];
    loading: boolean;
  }
}

const defaultFilters: PreviousAnswerFilters = {
  dateFrom: subDays(new Date(), 7),
  dateTo: new Date(),
  iteration: undefined,
  scenario: "",
};

const initialState: PreviousAnswerState = {
  filters: defaultFilters,
  list: {
    value: [],
    loading: false,
  },
  dialogs: {
    value: [],
    loading: false,
  },
  activeDialog: null,
  exportHistory: {
    list: [],
    loading: false,
  },
};

export const previousAnswerReducer: Reducer<PreviousAnswerState> = createReducer(initialState)
  .handleAction(
    actions.setFilters,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.setFilters>,
    ): PreviousAnswerState => ({
      ...state,
      filters: payload,
    }),
  )
  .handleAction(
    actions.resetFilters,
    (state: PreviousAnswerState): PreviousAnswerState => ({
      ...state,
      filters: defaultFilters,
    }),
  )
  .handleAction(
    actions.loadPreviousAnswers.request,
    (
      state: PreviousAnswerState,
    ): PreviousAnswerState => ({
      ...state,
      list: {
        ...state.list,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadPreviousAnswers.success,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.loadPreviousAnswers.success>,
    ): PreviousAnswerState => ({
      ...state,
      list: {
        ...state.list,
        value: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadPreviousAnswers.failure,
    (
      state: PreviousAnswerState,
    ): PreviousAnswerState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadPreviousAnswerDialogs.request,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.loadPreviousAnswerDialogs.request>,
    ): PreviousAnswerState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        loading: true,
        value: typeof payload.offset === 'number' ?
          state.dialogs.value :
          [],
      },
    }),
  )
  .handleAction(
    actions.loadPreviousAnswerDialogs.success,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.loadPreviousAnswerDialogs.success>,
    ): PreviousAnswerState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        loading: false,
        value: [
          ...state.dialogs.value,
          ...payload,
        ],
      },
    }),
  )
  .handleAction(
    actions.setActiveDialog,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.setActiveDialog>,
    ): PreviousAnswerState => ({
      ...state,
      activeDialog: payload,
    }),
  )
  .handleAction(
    actions.loadExportHistory.request,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.loadExportHistory.request>,
    ): PreviousAnswerState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        list: payload.offset ?
          state.exportHistory.list :
          [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadExportHistory.success,
    (
      state: PreviousAnswerState,
      { payload }: ActionType<typeof actions.loadExportHistory.success>,
    ): PreviousAnswerState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        loading: false,
        list: [
          ...state.exportHistory.list,
          ...payload,
        ],
      },
    }),
  )
  .handleAction(
    actions.loadExportHistory.failure,
    (
      state: PreviousAnswerState,
    ): PreviousAnswerState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        loading: false,
      },
    }),
  );
