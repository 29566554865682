import { createReducer, ActionType } from "typesafe-actions";
import { Reducer } from 'redux';

import * as actions from './actions';
import * as projectsActions from '../projects/actions';
import { AudioFileMetadata } from "../../types/models/Files";
import { Editable } from "../reducers";
import { unique } from "../../services/helpers/utilities";

export type FilesState = {
  callFiles: {
    countToUpload: number;
    countUploaded: number;
    processing: boolean;
    errorMessage: string;
  },
  audioFiles: {
    list: Editable<AudioFileMetadata[]>;
    loading: boolean;
    uploading: boolean;
    wasLoaded: boolean;
  },
  lockedFiles: string[];
}

const initialState: FilesState = {
  callFiles: {
    countToUpload: 0,
    countUploaded: 0,
    processing: false,
    errorMessage: '',
  },
  audioFiles: {
    list: [],
    loading: false,
    uploading: false,
    wasLoaded: false,
  },
  lockedFiles: [],
};

export const filesReducer: Reducer<FilesState> = createReducer<FilesState>(initialState)
  .handleAction(
    actions.startUploadCallFiles,
    (): FilesState => ({
      ...initialState,
      callFiles: {
        ...initialState.callFiles,
        processing: true,
      },
    }),
  )
  .handleAction(
    actions.endUploadCallFiles,
    (state: FilesState, { payload }: ActionType<typeof actions.endUploadCallFiles>): FilesState => ({
      ...state,
      callFiles: {
        ...state.callFiles,
        errorMessage: payload,
        processing: false,
      },
    }),
  )
  .handleAction(
    actions.setCallFilesCountToUpload,
    (state: FilesState, { payload }: ActionType<typeof actions.setCallFilesCountToUpload>): FilesState => ({
      ...state,
      callFiles: {
        ...state.callFiles,
        countToUpload: payload,
      },
    }),
  )
  .handleAction(
    actions.setCallFilesUploaded,
    (state: FilesState, { payload }: ActionType<typeof actions.setCallFilesUploaded>): FilesState => ({
      ...state,
      callFiles: {
        ...state.callFiles,
        countUploaded: payload,
      },
    }),
  )
  .handleAction(
    actions.loadAudioFiles.request,
    (state: FilesState): FilesState => ({
      ...state,
      audioFiles: {
        ...state.audioFiles,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadAudioFiles.success,
    (state: FilesState, { payload }: ActionType<typeof actions.loadAudioFiles.success>): FilesState => ({
      ...state,
      audioFiles: {
        ...state.audioFiles,
        list: payload,
        loading: false,
        wasLoaded: true,
      },
    }),
  )
  .handleAction(
    actions.loadAudioFiles.failure,
    (state: FilesState): FilesState => ({
      ...state,
      audioFiles: {
        ...state.audioFiles,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.uploadAudioFile.request,
    (state: FilesState): FilesState => {
      return {
        ...state,
        audioFiles: {
          ...state.audioFiles,
          uploading: true,
        },
      };
    },
  )
  .handleAction(
    actions.uploadAudioFile.success,
    (state: FilesState, { payload }: ActionType<typeof actions.uploadAudioFile.success>): FilesState => {
      return {
        ...state,
        audioFiles: {
          ...state.audioFiles,
          list: unique([payload, ...state.audioFiles.list], file => file.filename),
          uploading: false,
        },
      };
    },
  )
  .handleAction(
    actions.uploadAudioFile.failure,
    (state: FilesState): FilesState => {
      return {
        ...state,
        audioFiles: {
          ...state.audioFiles,
          uploading: false,
        },
      };
    },
  )
  .handleAction(
    actions.updateAudioUserFilename.success,
    (state: FilesState, { payload }: ActionType<typeof actions.updateAudioUserFilename.request>): FilesState => {
      return updateAudioFile(state, payload.filename, audio => ({
        ...audio,
        userFilename: payload.newUserFilename,
        userComment: payload.newUserComment,
      }));
    },
  )
  .handleAction(
    actions.lockFile,
    (
      state: FilesState,
      { payload: fileId }: ActionType<typeof actions.lockFile>,
    ): FilesState => ({
      ...state,
      lockedFiles: [...state.lockedFiles, fileId],
    }),
  )
  .handleAction(
    actions.unlockFile,
    (
      state: FilesState,
      { payload: fileId }: ActionType<typeof actions.lockFile>,
    ): FilesState => ({
      ...state,
      lockedFiles: state.lockedFiles.filter(f => f !== fileId),
    }),
  )
  .handleAction(
    projectsActions.selectProject,
    (): FilesState => ({
      ...initialState,
    }),
  );

function updateAudioFile(
  state: FilesState,
  filename: string,
  callbabk: (value: Editable<AudioFileMetadata>) => Editable<AudioFileMetadata>,
): FilesState {
  return {
    ...state,
    audioFiles: {
      ...state.audioFiles,
      list: state.audioFiles.list.map(audio => audio.filename === filename ? callbabk(audio) : audio),
    },
  };
}
