import { NodeStatisticsBackend } from "../backendModels/GraphStatisticsBackend";
import { NodeStatistics } from "../models/GraphStatistics";

export function parseGraphNodeStatistics(
  value: NodeStatisticsBackend,
): NodeStatistics {
  const params = value || {};
  return ({
    nodeId: value.node_id,
    params: {
      ok: params.ok ?? null,
      count: params.count ?? null,
      error: params.error ?? null,
      timeAvg: params.time_avg ?? null,
      timeMax: params.time_max ?? null,
      timeMin: params.time_min ?? null,
    },
  });
}
