import { SagaIterator } from "redux-saga";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import { parseSecret, parseSecretToBackend } from "../../types/parsers/Secret";
import { Project } from "../../types/models/Project";
import {
  CreateSecretRequest,
  CreateSecretResponse,
  DeleteSecretRequest,
  GetSecretsRequest,
  GetSecretsResponse,
  UpdateSecretDescriptionRequest,
  UpdateSecretRequest,
  UpdateSecretResponse,
} from "../../types/requests/Secret";

import apiService, { ApiResponse } from "../../services/api";
import i18n from "../../services/i18n";

import { getSelectedProject } from "../projects/selectors";
import * as actions from "./actions";
import { ErrorAction } from "../reducers";
import { Secret } from "../../types/models/Secret";
import { isKeyNotEmpty, parseKeyValueToBackend } from "../../types/parsers/IntegrationsParser";
import { getSecretBySlug } from "./selector";

function* loadSecrets(): SagaIterator {
  yield takeLatest(actions.loadSecrets.request, function* () {
    try {
      const selectedProject: Project = yield select(getSelectedProject);

      const request: GetSecretsRequest = {
        path: {
          project_slug: selectedProject.slug,
        },
      };

      const response: ApiResponse<GetSecretsResponse> = yield call(apiService.getSecrets, request);

      const secrets = response.data.secrets.map(parseSecret);

      yield put(actions.loadSecrets.success(secrets));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SECRETS.MULTIPLE") });
      yield put(actions.loadSecrets.failure(new ErrorAction(err, text)));
    }
  });
}

function* createSecret(): SagaIterator {
  yield takeLatest(actions.createSecret.request, function* (action) {
    try {
      const selectedProject: Project = yield select(getSelectedProject);
      const newSecret: Secret = action.payload;

      const request: CreateSecretRequest = {
        path: {
          project_slug: selectedProject.slug,
        },
        body: parseSecretToBackend(newSecret),
      };

      const response: ApiResponse<CreateSecretResponse> = yield call(
        apiService.createSecret,
        request,
      );

      yield put(actions.createSecret.success(parseSecret(response.data)));
      yield put(actions.closeNewSecretModal());
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.SECRETS.SINGLE") });
      yield put(actions.createSecret.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateSecret(): SagaIterator {
  yield takeLatest(actions.updateSecret.request, function* (action) {
    try {
      const project: Project = yield select(getSelectedProject);
      const secretToUpdate: Secret = action.payload;
      const originalSecret: Secret = yield select(getSecretBySlug, secretToUpdate.slug);

      if (!originalSecret) {
        throw new Error();
      }

      const isDescriptionDifferent = secretToUpdate.description !== originalSecret.description;

      if (isDescriptionDifferent) {
        const request: UpdateSecretDescriptionRequest = {
          path: {
            secret_slug: secretToUpdate.slug,
            project_slug: project.slug,
          },
          body: {
            description: secretToUpdate.description,
          },
        };

        yield call(
          apiService.updateSecretDescription,
          request,
        );
      }

      const request: UpdateSecretRequest = {
        path: {
          project_slug: project.slug,
          secret_slug: secretToUpdate.slug,
        },
        body: {
          value: secretToUpdate.value
            ?.filter(isKeyNotEmpty)
            .map(parseKeyValueToBackend)
            .map(v => ({
              ...v,
              value: v.value.toString(),
            })) || [],
        },
      };

      const response: ApiResponse<UpdateSecretResponse> = yield call(
        apiService.updateSecret,
        request,
      );

      yield put(actions.updateSecret.success(parseSecret(response.data)));
      yield put(actions.closeEditModal());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.SECRETS.SINGLE") });
      yield put(actions.updateSecret.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteSecret(): SagaIterator {
  yield takeLatest(actions.deleteSecret.request, function* (action) {
    try {
      const project: Project = yield select(getSelectedProject);
      const secretSlug: string = action.payload;

      const request: DeleteSecretRequest = {
        path: {
          project_slug: project.slug,
          secret_slug: secretSlug,
        },
      };

      yield call(
        apiService.deleteSecret,
        request,
      );

      yield put(actions.deleteSecret.success(secretSlug));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.SECRETS.SINGLE") });
      yield put(actions.deleteSecret.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* secretSagas(): SagaIterator {
  yield fork(loadSecrets);
  yield fork(createSecret);
  yield fork(updateSecret);
  yield fork(deleteSecret);
}
