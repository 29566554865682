import React, { useCallback, useEffect, useRef, useState } from "react";
import { ColumnConfig, DataItem } from "../types";
import Row from "./row";
import Button from "../../button";

import { b } from "..";

type RowWithAddsProps = {
  item: DataItem;
  columns: ColumnConfig[];
  className?: string;
}

function RowWithAdds({
  item,
  columns,
  className,
}: RowWithAddsProps) {
  const parentRowRef = useRef<HTMLDivElement>(null);
  const addButtonRef = useRef<HTMLDivElement>(null);

  const [opened, setOpened] = useState<boolean>(false);

  const toggleOpened = useCallback(
    () => {
      setOpened(o => !o);
    },
    [],
  );

  useEffect(
    () => {
      if (!parentRowRef.current) return;
      if (!addButtonRef.current) return;

      const cell = parentRowRef.current.childNodes.item(0);
      const cellHeight = (cell as HTMLDivElement).clientHeight;
      addButtonRef.current.style.top = `-${Math.floor(cellHeight / 2)}px`;
    },
    [],
  );

  return (
    <React.Fragment>
      <Row
        ref={parentRowRef}
        className={className}
        item={item}
        columns={columns}
      />
      <div className={b("additional")}>
        <div ref={addButtonRef} className={b("add-toggle-button-wrapper")}>
          <Button
            visualStyle="ghost"
            className={b("add-toggle-button")}
            icon={opened ? "chevronUp" : "chevronDown"}
            onClick={toggleOpened}
          />
        </div>
      </div>
      {opened && item.additional}
    </React.Fragment>
  );
}

export default RowWithAdds;
