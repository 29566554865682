import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { Event } from "../../types/models/Event";
import * as actions from './actions';

type ChangeHistoryState = {
  events: {
    value: Event[],
    loading: boolean,
    total: number,
  }
}

const initialState: ChangeHistoryState = {
  events: {
    value: [],
    loading: false,
    total: 0,
  },
};

export const changeHistoryReducer: Reducer<ChangeHistoryState> = createReducer<
  ChangeHistoryState
>(initialState)
  .handleAction(
    actions.loadChangeHistory.request,
    (
      state: ChangeHistoryState,
      { payload }: ActionType<typeof actions.loadChangeHistory.request>,
    ): ChangeHistoryState => ({
      ...state,
      events: {
        ...state.events,
        value: payload.offset ? state.events.value : [],
        loading: true,
        total: payload.offset ? state.events.total : 0,
      },
    }),
  )
  .handleAction(
    actions.loadChangeHistory.success,
    (
      state: ChangeHistoryState,
      { payload }: ActionType<typeof actions.loadChangeHistory.success>,
    ): ChangeHistoryState => {
      const events = payload.offset ?
        state.events.value.concat(payload.events) :
        payload.events;
      const noMoreEvents = isLastChunk(payload.offset, payload.events);

      return {
        ...state,
        events: {
          ...state.events,
          value: events,
          loading: false,
          total: noMoreEvents ? events.length : state.events.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadChangeHistory.failure,
    (state: ChangeHistoryState): ChangeHistoryState => ({
      ...state,
      events: {
        ...state.events,
        loading: false,
      },
    }),
  );

function isLastChunk(offset: number, chunk: Event[]): boolean {
  return chunk.length === 0 && offset > 0;
}
