import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../icon';
import Button from '../button';

import './uploadFileButton.scss';

type UploadFileButtonProps = {
  onClick: () => void,
}

export default function UploadFileButton({
  onClick,
}: UploadFileButtonProps) {
  const { t } = useTranslation();

  return (
    <Button simple className="upload-file-button" onClick={onClick}>
      <div className="upload-file-button__icon-wrapper">
        <Icon type="plus" className="upload-file-button__icon" />
      </div>
      <span>{t("GRAPH.UPLOAD_FILE_FROM_COMPUTER")}...</span>
    </Button>
  );
}
