import { findThresholdIndex } from "../../services/helpers/utilities";
import { Metric } from "../../types/models/Metric";
import { Model, ModelTopic, PlotsPoint } from "../../types/models/ProjectModel";
import { AppState } from "../reducers";
import { ScenarioGraphsItem } from "../scenarioGraphs/types";
import { getScenarioGraphs } from "../scenarioGraphs/selectors";

export const getModels = (state: AppState): {
  list: Model[],
  loading: boolean,
} => state.models.projectModels;

export const getCurrentModelsLoading = ({ models }: AppState) => models.current.loading;

export const getCurrentModelTopics = (state: AppState): {
  list: ModelTopic[],
  loading: boolean,
} => state.models.projectModelTopics;

export const getCurrentModelTopicsWithTitles = (state: AppState) => {
  const topics = getCurrentModelTopics(state);
  if (topics.loading) return topics;

  const scenarios = getScenarioGraphs(state);

  type T = Record<ScenarioGraphsItem['slug'], ScenarioGraphsItem>;
  const groupedScenarios: T = scenarios.reduce(
    (o, s) => ({ ...o, [s.slug]: s }),
    {},
  );

  return {
    list: topics.list.map(
      item => ({ ...item, title: groupedScenarios[item.slug]?.title || '' }),
    ),
    loading: false,
  };
};

export const getCurrentModel = (state: AppState): {
  value: Model,
  loading: boolean,
} => state.models.current;

export const getOnlineModelLoading = ({ models }: AppState) => models.onlineModel.loading;

export const getIsUseOldThresholds = (state: AppState) => state.models.current.useOldThresholds;

export const getOnlineModelUpdating = ({ models }: AppState) => models.onlineModel.updating;

export const getOnlineModelValue = ({ models }: AppState) => models.onlineModel.value;

export const getCurrentModelTopicBySlug = (
  state: AppState,
  slug: string,
): ModelTopic | undefined => state.models.projectModelTopics.list
  .find(topic => topic.slug === slug);

export const getThresholds = (
  state: AppState,
  metric: Metric,
  value: number,
): Array<{ metric: Metric, value: number }> => {
  if (state.models.plots.list.length === 0) {
    return [{
      metric,
      value,
    }];
  }

  const metricValues = state.models.plots.list.map(plot => plot[metric as keyof PlotsPoint]);

  const thresholdIndex = findThresholdIndex(value, metricValues);

  return Object.keys(state.models.plots.list[thresholdIndex as number])
    .reduce<Array<{ metric: Metric, value: number }>>((result, m) => {
      const metricThreshold = {
        metric: m as Metric,
        value: state.models.plots.list[thresholdIndex as number][m as keyof PlotsPoint],
      };

      result.push(metricThreshold);

      return result;
    }, [])
    .sort(defaultThresholdSort)
    .map(roundThreshold);
};

export const getCurrentThresholds = (state: AppState) => state.models.current.thresholds
  .sort(defaultThresholdSort)
  .map(roundThreshold);

export const getThresholdsByMetric = (
  state: AppState,
  metric: Metric,
) => state.models.plots.list
  .map(plot => Math.round(plot[metric as keyof PlotsPoint] * 10000) / 10000);

export const getPlots = (state: AppState) => state.models.plots;

export const getThresholdDialogs = (state: AppState) => state.models.dialogs;

export const getCommonModels = (state: AppState) => state.models.commonModels;

export const getCommonModelById = (
  state: AppState,
  id: string,
) => state.models.commonModels.list.find(model => model.id === id);

export const getComparisonTasks = (state: AppState) => state.models.comparisonTasks;

export const getComparisonsModalOpenStatus = (state: AppState) => (
  state.models.comparisonModelOpened
);

export const getComparisonCreatingStatus = (state: AppState) => (
  state.models.comparisonTasks.creatingComparison
);

function defaultThresholdSort(
  thresholdA: { metric: Metric, value: number },
  thresholdB: { metric: Metric, value: number },
): number {
  if (thresholdA.metric === Metric.probability) {
    return -1;
  } if (thresholdB.metric === Metric.probability) {
    return 1;
  }
  return (thresholdA.metric < thresholdB.metric) ? -1 : 1;
}

function roundThreshold(
  threshold: { metric: Metric, value: number },
): { metric: Metric, value: number } {
  return {
    metric: threshold.metric,
    value: Math.round(threshold.value * 1000) / 1000,
  };
}
