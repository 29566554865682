import React, { CSSProperties } from 'react';
import { SvgProps } from '../types';

const style: CSSProperties = {
  maskType: "alpha",
};

function ArrowCircleUpIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_23991_107366"
        style={style}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect
          y="0.5"
          width={width}
          height={height}
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_23991_107366)">
        <path
          d="M12 16.5C12.2833 16.5 12.521 16.404 12.713 16.212C12.9043 16.0207 13 15.7833 13 15.5V12.3L13.9 13.2C14.0833 13.3833 14.3167 13.475 14.6 13.475C14.8833 13.475 15.1167 13.3833 15.3 13.2C15.4833 13.0167 15.575 12.7833 15.575 12.5C15.575 12.2167 15.4833 11.9833 15.3 11.8L12.7 9.2C12.6 9.1 12.4917 9.029 12.375 8.987C12.2583 8.94567 12.1333 8.925 12 8.925C11.8667 8.925 11.7417 8.94567 11.625 8.987C11.5083 9.029 11.4 9.1 11.3 9.2L8.7 11.8C8.51667 11.9833 8.425 12.2167 8.425 12.5C8.425 12.7833 8.51667 13.0167 8.7 13.2C8.88333 13.3833 9.11667 13.475 9.4 13.475C9.68333 13.475 9.91667 13.3833 10.1 13.2L11 12.3V15.5C11 15.7833 11.096 16.0207 11.288 16.212C11.4793 16.404 11.7167 16.5 12 16.5ZM12 22.5C10.6167 22.5 9.31667 22.2373 8.1 21.712C6.88333 21.1873 5.825 20.475 4.925 19.575C4.025 18.675 3.31267 17.6167 2.788 16.4C2.26267 15.1833 2 13.8833 2 12.5C2 11.1167 2.26267 9.81667 2.788 8.6C3.31267 7.38333 4.025 6.325 4.925 5.425C5.825 4.525 6.88333 3.81233 8.1 3.287C9.31667 2.76233 10.6167 2.5 12 2.5C13.3833 2.5 14.6833 2.76233 15.9 3.287C17.1167 3.81233 18.175 4.525 19.075 5.425C19.975 6.325 20.6873 7.38333 21.212 8.6C21.7373 9.81667 22 11.1167 22 12.5C22 13.8833 21.7373 15.1833 21.212 16.4C20.6873 17.6167 19.975 18.675 19.075 19.575C18.175 20.475 17.1167 21.1873 15.9 21.712C14.6833 22.2373 13.3833 22.5 12 22.5ZM12 20.5C14.2167 20.5 16.1043 19.721 17.663 18.163C19.221 16.6043 20 14.7167 20 12.5C20 10.2833 19.221 8.39567 17.663 6.837C16.1043 5.279 14.2167 4.5 12 4.5C9.78333 4.5 7.896 5.279 6.338 6.837C4.77933 8.39567 4 10.2833 4 12.5C4 14.7167 4.77933 16.6043 6.338 18.163C7.896 19.721 9.78333 20.5 12 20.5Z"
          fill={color}
        />
      </g>
    </svg>

  );
}

export default ArrowCircleUpIcon;
