import React, {
  DragEventHandler,
  FocusEventHandler,
  forwardRef, MouseEventHandler, ReactNode, ReactNodeArray, Ref, useCallback, useMemo,
} from 'react';

import { block } from 'bem-cn';

import Icon, { IconSize, IconTypes, SIZES } from '../icon';

import './style.scss';

export type ButtonClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
export type ButtonMouseEventHandler = MouseEventHandler<HTMLButtonElement>;
export type ButtonVisualStyle = 'minor' | 'main' | 'outline' | 'floating' | 'ghost' | "destructive";

export type ButtonProps = {
  className?: string;
  fluid?: boolean;
  size?: 'mini' | 'small' | 'medium' | 'large';
  // TODO Перенести все флаги визуального стиля в один пропс (чтобы нельзя было
  // поставить одновременно, например, primary и simple)
  primary?: boolean; // style = main
  simple?: boolean; // style = ghost
  contrast?: boolean; // style = minor
  bordered?: boolean; // style = outline
  withShadow?: boolean; // style = floating
  disabled?: boolean;
  icon?: IconTypes;
  iconSize?: IconSize;
  iconColor?: string;
  title?: string;
  name?: string;
  pressed?: boolean;
  children?: ReactNode | ReactNodeArray | HTMLButtonElement['children'];
  form?: 'squircle' | 'circle';
  visualStyle?: ButtonVisualStyle;
  onClick?: ButtonMouseEventHandler;
  onMouseEnter?: ButtonMouseEventHandler;
  onMouseLeave?: ButtonMouseEventHandler;
  onMouseDown?: ButtonMouseEventHandler;
  onBlur?: FocusEventHandler;
  onDragStart?: DragEventHandler;
  onDragEnd?: DragEventHandler;
};

const b = block('nd-button');

const Button = (
  {
    className = '',
    size = 'small',
    fluid,
    primary,
    simple,
    contrast,
    icon,
    disabled = false,
    bordered = false,
    withShadow = false,
    pressed = false,
    visualStyle: visualStyleProps,
    title = '',
    iconColor,
    iconSize = IconSize.medium,
    form = "squircle",
    onClick,
    onMouseLeave,
    onMouseEnter,
    onMouseDown,
    onBlur,
    children,
    ...rest
  }: ButtonProps,
  ref: Ref<HTMLButtonElement> | undefined,
) => {
  const visualStyle: ButtonVisualStyle = useMemo(
    () => {
      if (visualStyleProps) return visualStyleProps;

      switch (true) {
        case primary:
          return "main";
        case simple:
          return "ghost";
        case bordered:
          return "outline";
        case withShadow:
          return "floating";
        case contrast:
        default:
          return "minor";
      }
    },
    [
      bordered,
      contrast,
      primary,
      simple,
      withShadow,
      visualStyleProps,
    ],
  );

  const cn = b({
    size,
    fluid,
    simple,
    contrast,
    disabled,
    bordered,
    pressed,
    'only-icon': !!icon && !children,
    'visual-style': visualStyle,
    form,
  });

  const handleClick = useCallback(
    () => disabled ? () => {} : onClick,
    [disabled, onClick],
  );

  const handleMouseDown = useCallback(
    e => {
      if (!onMouseDown) return;
      onMouseDown(e);
    },
    [onMouseDown],
  );

  return (
    <button
      {...rest}
      ref={ref || undefined}
      className={`${className} ${cn}`}
      type="button"
      title={title}
      onClick={handleClick()}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={handleMouseDown}
      onBlur={onBlur}
    >
      {icon && (
        <Icon
          className={b("icon")}
          svgProps={{ width: SIZES[iconSize], height: SIZES[iconSize], color: iconColor }}
          type={icon}
        />
      )}
      {children}
    </button>
  );
};

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);
