import { generateId } from "../../services/helpers/generateId";
import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ExportedScenarioGraphBackend, ScenarioGraphBackend, ScenarioGroupName } from "../backendModels/ScenarioGraphBackend";
import { Lang } from "../models/Languages";
import { ExportedScenarioGraph, ScenarioGraph } from "../models/ScenarioGraph";
// import { getEmptyPredicate } from "../models/ScenarioGraphAction";
import { parsePredicate, parsePredicateToBackend } from "./ScenarioGraphActionParser";
import { parseScenarioGraphLink, parseScenarioGraphLinkToBackend } from "./ScenarioGraphLinkParser";
import { parseScenarioGraphNode, parseScenarioGraphNodeToBackend } from "./ScenarioGraphNode";

export function parseScenarioGraph(value: ScenarioGraphBackend, mainLang?: Lang): ScenarioGraph {
  const result: ScenarioGraph = {
    ...objSnakeToCamel(value),
    ...({
      nodes: Array.isArray(value.nodes) ?
        value.nodes.map(item => parseScenarioGraphNode(item, mainLang)) :
        [],
    }),
    ...({ links: Array.isArray(value.links) ? value.links.map(parseScenarioGraphLink) : [] }),
    extraModelTopics: Array.isArray(value.extra_model_topics) ? value.extra_model_topics : [],
    groupName: value.group_name as ScenarioGroupName ?? ScenarioGroupName.main,
    hash: generateId(),
  };

  if (value.predicate) {
    result.predicate = parsePredicate(value.predicate);
  }

  return result;
}

export function parseScenarioGraphToBackend(
  value: ScenarioGraph,
  mainLang: Lang,
  isVoiceProject: boolean,
): ScenarioGraphBackend {
  return {
    ...objCamelToSnake(value),
    predicate: parsePredicateToBackend(value.predicate),
    nodes: value.nodes.map(item => parseScenarioGraphNodeToBackend(item, mainLang, isVoiceProject)),
    links: value.links.map(parseScenarioGraphLinkToBackend),
  };
}

export function parseScenarioGraphToExport(
  value: ExportedScenarioGraph,
  mainLang: Lang,
  isVoiceProject: boolean,
): ExportedScenarioGraphBackend {
  const { projectId, groups, ...rest } = value;

  return {
    ...parseScenarioGraphToBackend(rest, mainLang, isVoiceProject),
    project_id: projectId,
    groups: groups,
  };
}

export function parseScenarioGraphToImport(
  value: ExportedScenarioGraphBackend,
  mainLang: Lang,
): ExportedScenarioGraph {
  // eslint-disable-next-line camelcase
  const { project_id, groups, ...rest } = value;

  return {
    ...parseScenarioGraph(rest, mainLang),
    groups,
    projectId: project_id,
  };
}
