import React from 'react';

import { SvgProps } from '../types';

function LoaderBracketsIcon({
  width = '24',
  height = '24',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12.5C20 16.9183 16.4183 20.5 12 20.5M4 12.5C4 8.08172 7.58172 4.5 12 4.5"
        stroke="#739EFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 12 12"
          to="360 12 12"
          dur="1s"
          additive="sum"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}

export default LoaderBracketsIcon;
