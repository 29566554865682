import { ScenarioGraphGroup } from "../../types/models/ScenarioGraphGroup";
import { AppState } from "../reducers";

export function getCreateGroupModalOpened(state: AppState) {
  return state.graphGroups.createModal.opened;
}

export function getDeleteGroupModalOpened(state: AppState) {
  return state.graphGroups.deleteModal.opened;
}

export function getEditGroupModalOpened(state: AppState) {
  return state.graphGroups.editModal.opened;
}

export function getGraphGroupById(
  state: AppState,
  id: ScenarioGraphGroup['id'],
): ScenarioGraphGroup | undefined {
  return state.graphGroups.list.find(g => g.id === id);
}

export function getGraphGroups(
  state: AppState,
) {
  return state.graphGroups.list;
}

export function getGraphGroupsLoading(
  state: AppState,
): boolean {
  return state.graphGroups.loading;
}
