import { Feature } from "../../../../../../types/models/Feature";
import { PredicateItemType, PredicateOperator } from "../../../../../../types/models/ScenarioGraphAction";

export type ScenarioGraphRules = RulePredicate[];

export type RulePredicate = {
  id: string;
  type: PredicateItemType;
  rules?: FeatureRule[];
  code?: string;
  isActive: boolean;
}

export type FeatureRule = {
  id: string;
  feature: Feature['slug'];
  action: FeatureAction;
  value: RuleWord[] | SymbolsRule | string;
  suggestedSynonyms?: string[];
}

export type RuleWord = {
  id: string;
  text: string;
}

export type SymbolsRule = {
  action: PredicateOperator;
  count: number;
}

export enum FeatureAction {
  contains = PredicateOperator.CONTAINS,
  notContains = PredicateOperator.NOT_CONTAINTS,
  matches = PredicateOperator.MATCHES,
  equals = PredicateOperator.EQUALS,
}
