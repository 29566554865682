import React from 'react';

import { SvgProps } from "../types";

function SuitcaseIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 12V10.3333C19.1667 12.8333 15.7667 13.25 12.5 13.25C9.23333 13.25 5.83333 12.8333 2.5 10.3333V12C5.95692 13.3827 8.22072 13.9051 10.8333 14.0429V15.3333C10.8333 15.7936 11.2064 16.1666 11.6667 16.1666H13.3333C13.7936 16.1666 14.1667 15.7936 14.1667 15.3333V14.0429C16.7793 13.9051 19.0431 13.3827 22.5 12ZM3.33333 6.99998H21.6667C22.1269 6.99998 22.5 7.37308 22.5 7.83331V18.0881C22.5 18.9028 21.9127 19.5957 21.1052 19.7044C19.2721 19.9511 15.886 20.3333 12.5 20.3333C9.11396 20.3333 5.72791 19.9511 3.89475 19.7044C3.0873 19.5957 2.5 18.9028 2.5 18.0881V7.83331C2.5 7.37308 2.8731 6.99998 3.33333 6.99998ZM15.4167 6.16665V4.91665C15.4167 4.68653 15.2301 4.49998 15 4.49998H10C9.76988 4.49998 9.58333 4.68653 9.58333 4.91665V6.16665H8.33333V4.91665C8.33333 3.76605 9.26607 2.83331 10.4167 2.83331H14.5833C15.7339 2.83331 16.6667 3.76605 16.6667 4.91665V6.16665H15.4167Z"
        fill={color}
      />
    </svg>
  );
}

export default SuitcaseIcon;
