import { createAction, createAsyncAction } from "typesafe-actions";
import { Model as CommonModel } from "../../types/models/CommonModel";
import { Dialog } from "../../types/models/Dialog";
import { KeyMetric, Metric } from "../../types/models/Metric";
import { Pagination } from "../../types/models/Pagination";
import { Model, Model as ProjectModel, ModelDraft, ModelTopic, PlotsPoint } from "../../types/models/ProjectModel";
import { Task } from "../../types/models/Task";
import { ErrorAction } from "../reducers";
import { NewSampling } from "../../types/models/Sampling";

export const loadCurrentModel = createAsyncAction(
  'models/loadCurrentModel/requested',
  'models/loadCurrentModel/succeeded',
  'models/loadCurrentModel/failed',
)<undefined, ProjectModel, ErrorAction>();

export const addProjectModel = createAsyncAction(
  'models/addProjectModel/requested',
  'models/addProjectModel/succeeded',
  'models/addProjectModel/failed',
)<ProjectModel, ProjectModel, ErrorAction<ProjectModel>>();

export const clearThresholdsVersionSetting = createAction('models/clearThresholdsVersionSetting')();

export const deleteProjectCurrentModel = createAsyncAction(
  'models/deleteProjectModel/requested',
  'models/deleteProjectModel/succeeded',
  'models/deleteProjectModel/failed',
)<undefined, undefined, ErrorAction>();

export const loadProjectModels = createAsyncAction(
  'models/loadProjectModels/requested',
  'models/loadProjectModels/succeeded',
  'models/loadProjectModels/failed',
)<undefined, ProjectModel[], ErrorAction>();

export const loadCurrentModelTopics = createAsyncAction(
  'models/loadCurrentModelTopics/requested',
  'models/loadCurrentModelTopics/succeeded',
  'models/loadCurrentModelTopics/failed',
)<undefined, ModelTopic[], ErrorAction>();

export const loadOnlineModelDraft = createAsyncAction(
  'models/loadOnlineModelDraft/requested',
  'models/loadOnlineModelDraft/succeeded',
  'models/loadOnlineModelDraft/failed',
)<undefined, ModelDraft, ErrorAction>();

export const updateOnlineModelDraft = createAsyncAction(
  'models/updateOnlineModelDraft/requested',
  'models/updateOnlineModelDraft/succeeded',
  'models/updateOnlineModelDraft/failed',
)<ModelDraft, ModelDraft, ErrorAction>();

export const updateThresholds = createAsyncAction(
  'models/updateThresholds/requested',
  'models/updateThresholds/succeeded',
  'models/updateThresholds/failed',
)<ModelTopic, ModelTopic, ErrorAction>();

export const loadModelPlots = createAsyncAction(
  'models/loadModelPlots/requested',
  'models/loadModelPlots/succeeded',
  'models/loadModelPlots/failed',
)<{ topicSlug: string, keyMetric?: KeyMetric }, PlotsPoint[], ErrorAction>();

export const loadThresholdDialogs = createAsyncAction(
  'models/loadThresholdDialogs/requested',
  'models/loadThresholdDialogs/succeeded',
  'models/loadThresholdDialogs/failed',
)<{ topicSlug: string, threshold: number, direction: 'above' | 'below' }, Dialog[], ErrorAction>();

export const setCurrentThresholds = createAction('models/setCurrentThresholds')<Array<{ metric: Metric, value: number }>>();

export const loadCommonModels = createAsyncAction(
  'models/loadCommonModels/requested',
  'models/loadCommonModels/succeeded',
  'models/loadCommonModels/failed',
)<undefined, CommonModel[], ErrorAction>();

export const createCommonModel = createAsyncAction(
  'models/createCommonModel/requested',
  'models/createCommonModel/succeeded',
  'models/createCommonModel/failed',
)<CommonModel, CommonModel, ErrorAction<CommonModel>>();

export const deleteCommonModel = createAsyncAction(
  'models/deleteCommonMode/requested',
  'models/deleteCommonMode/succeeded',
  'models/deleteCommonMode/failed',
)<{ modelId: string }, { modelId: string }, ErrorAction<{ modelId: string }>>();

export const compareModels = createAsyncAction(
  'models/compareModels/requested',
  'models/compareModels/succeeded',
  'models/compareModels/failed',
)<{ models: Model[], sampling?: NewSampling, modalId: string }, Task, ErrorAction>();

export const loadModelsComparisons = createAsyncAction(
  'models/loadModelsComparisons/requested',
  'models/loadModelsComparisons/succeeded',
  'models/loadModelsComparisons/failed',
)<Pagination, { pagination?: Pagination, tasks: Task[] }, ErrorAction>();

export const openComparisonModal = createAction('models/openComparisonModal')();
export const closeComparisonModal = createAction('models/closeComparisonModal')();
