import React from 'react';
import { block } from 'bem-cn';
import { useSelector } from 'react-redux';

import { Project, isVoiceProject } from '../../types/models/Project';

import { getSidebarOpenStatus } from '../../redux/sidebar/selectors';
import { getSelectedProject } from '../../redux/projects/selectors';

import Icon from '../icon';

import './selectedValue.scss';

const b = block("project-selector-selected-value");

type SelectedValueProps = {
  project: Project;
  isOpen?: boolean;
  onClick: (event: React.MouseEvent) => void;
}

function SelectedValue({
  project,
  isOpen,
  onClick,
}: SelectedValueProps) {
  const selectedProject = useSelector(getSelectedProject);
  const sidebarOpened = useSelector(getSidebarOpenStatus);

  return (
    <button
      type="button"
      onClick={onClick}
      className={b({ open: isOpen, extended: sidebarOpened })}
    >
      <Icon
        className={b("project-icon")}
        type={isVoiceProject(selectedProject) ? "phone" : "chat"}
      />
      {sidebarOpened && (
        <>
          <span className="crop-text">{project.title}</span>
          <Icon
            className={b("chevron")}
            type="chevronDown"
          />
        </>
      )}
    </button>
  );
}

export default SelectedValue;
