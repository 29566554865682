import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '../../../services/modal';

import Button, { ButtonVisualStyle } from '../../../components-new/button';

import Modal from '../modal';
import ModalHeader from '../modalHeader';
import ModalBody from '../modalBody';
import ModalFooter from '../modalFooter';

export type ConfirmModalProps = {
  className?: string;
  title?: ReactNode;
  children: ReactNode;
  cancelText?: string;
  showCancelButton?: boolean;
  confirmationText?: string;
  confirmDisabled?: boolean;
  confirmButtonStyle?: ButtonVisualStyle;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export default function NdModal({
  className = '',
  children,
  title = '',
  cancelText,
  confirmationText,
  confirmDisabled,
  showCancelButton = true,
  confirmButtonStyle = "main",
  onConfirm,
  onCancel,
  close,
}: ConfirmModalProps & ModalProps) {
  const { t } = useTranslation();
  const handleConfirmClick = useCallback(
    () => {
      if (onConfirm) {
        onConfirm();
      }
      close();
    },
    [close, onConfirm],
  );

  const handleCloseClick = useCallback(
    () => {
      if (onCancel) {
        onCancel();
      }
      close();
    },
    [close, onCancel],
  );

  return (
    <Modal className={`nd-modal ${className} ${!title ? "without-title" : ""}`}>
      {title && (
        <ModalHeader>
          {title}
          <Button
            className="nd-modal__close-button"
            icon="close"
            simple
            onClick={handleCloseClick}
          />
        </ModalHeader>
      )}
      {!title && (
        <Button
          className="nd-modal__close-button_outside"
          icon="close"
          simple
          onClick={handleCloseClick}
        />
      )}
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        {showCancelButton && (
          <Button onClick={handleCloseClick}>
            {cancelText ?? t("COMMON.CANCEL")}
          </Button>
        )}
        <Button
          disabled={!!confirmDisabled}
          visualStyle={confirmButtonStyle}
          onClick={handleConfirmClick}
        >
          {confirmationText ?? t("COMMON.OK")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
