import { SagaIterator, Task as SagaTask } from "redux-saga";
import { all, call, cancel, delay, fork, put, select, take, takeLatest } from "redux-saga/effects";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { format } from "date-fns";

import { AppState, ErrorAction } from "../reducers";
import i18n from "../../services/i18n";

import NotificationManager from "../../services/notifications";
import Api, { ApiResponse } from "../../services/api";
import {
  ChangeLabelScenariosRequest,
  CreateScenarioGraphLabelRequest,
  CreateScenarioGraphLabelResponse,
  DeleteScenarioGraphLabelRequest,
  GetScenarioGraphsLabelsResponse,
  GetScenarioGraphsResponse,
  SwitchScenarioGraphAvailabilityRequest,
  UpdateScenarioGraphLabelRequest,
  UpdateScenarioGraphLabelResponse,
} from "../../types/requests/ScenarioGraphs";
import { parseScenarioGraphs } from "../../types/parsers/ScenarioGraphs";

import * as actions from './actions';
import * as taskActions from '../tasks/actions';
import * as filesActions from '../files/actions';
import * as graphActions from '../graph/actions';

import { Task, TaskStatus, TaskType } from "../../types/models/Task";
import { GetTasksRequest, GetTasksResponse } from "../../types/requests/Task";
import {
  CloneScenarioGraphRequest,
  CloneScenarioGraphResponse,
} from "../../types/requests/GraphTopics";
import { getEmptyScenarioGraph } from "../../types/models/ScenarioGraph";
import { DeleteGraphRequest, GetGraphRequest, GetGraphResponse } from "../../types/requests/Graph";

import { ExtendedGameType, GameDataDiagnosticsRecord } from "../../types/models/Games";
import { GetGameDataResponse } from "../../types/requests/Games";
import { getLabelScenarios, getScenarioById, getScenarioGraphs, getScenarioLabelById, getSelectedParentNodes, getTrainingDiagnostics, getTrainingMarking, getTrainingScenarioSlug } from "./selectors";
import { ScenarioGraphsState } from "./reducer";
import { GameResultRecord } from "../../types/backendModels/Games";

import openGraphById from "../../pages/graphEditor/helpers/openGraphById";
import { ScenarioGraphMeta, ScenarioGraphs } from "../../types/models/ScenarioGraphs";
import { ScenarioGraphsItem } from "./types";
import { objCamelToSnakeDeep, objSnakeToCamelDeep, onlyUnique, transliterate } from "../../services/helpers/utilities";
import apiService from "../../services/api";
import { parseScenarioGraphUsage } from "../../types/parsers/ScenarioGraphParent";
import { ScenarioGraphLabel } from "../../types/models/ScenarioGraphLabel";
import { generateId } from "../../services/helpers/generateId";
import { GetActionSuggestRequest, GetActionSuggestResponse } from "../../types/requests/Scenarios";
import { getSelectedProjectSlug } from "../projects/selectors";
import NotificationService from "../../services/notifications";
import { DRAFT_VERSION } from "../versions/reducer";
import { getVersions, getVersionsAvailableToSwitch } from "../versions/selectors";
import { Version } from "../../types/models/Version";
import { changeVersionToDisplay, loadVersions } from "../versions/actions";
import { checkIfGraphExist } from "../../components-new/chat/components/aiMessageWithDebug/helpers";
import authorizedPages from "../../routes/authorized";

import { usageMock } from "./mocks";
import { getConfig } from "../user/selectors";
import { UseParentScenariosConfig } from "../../types/codegen/experiments";
import { GetScenarioUsageRequest, GetScenarioUsageResponse, UpdateScenarioUsageRequest } from "../../types/requests/ScenarioUsage";
import { getActiveGraphId } from "../graph/selectors";
import { UpdateScenariosPriorityRequest } from "../../types/requests/ScenarioPriorities";

// eslint-disable-next-line space-before-function-paren
export const getScenarios = async () => {
  const params = { limit: 999, offset: 0 };

  type T = ApiResponse<GetScenarioGraphsResponse>;
  const response: T = await Api.getScenarioGraphs({ params });

  return parseScenarioGraphs(response.data);
};

function* loadScenarios(): SagaIterator {
  yield takeLatest(actions.loadScenarios.request, function* () {
    try {
      const parsed: ScenarioGraphs = yield call(getScenarios);

      yield put(actions.loadScenarios.success(parsed));
    } catch (err) {
      yield put(actions.loadScenarios.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SCENARIOS.MULTIPLE") }),
      )));
    }
  });
}

function* createScenario(): SagaIterator {
  yield takeLatest(actions.createScenario, function* () {
    yield put(graphActions.changeActiveGraph(getEmptyScenarioGraph()));
    yield put(actions.openScenarioEditor());
  });
}

function* deleteScenario(): SagaIterator {
  yield takeLatest(actions.deleteScenario.request, function* ({ payload }) {
    try {
      const { id } = payload;

      const params: DeleteGraphRequest['params'] = { scenario_id: id };
      yield call(Api.deleteGraph, { params });

      yield put(actions.loadScenarios.request());
    } catch (err) {
      yield put(
        actions.deleteScenario.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.SCENARIOS.SINGLE") })),
        ),
      );
    }
  });
}

function* cloneScenario(): SagaIterator {
  yield takeLatest(actions.cloneScenario.request, function* ({ payload }) {
    try {
      const params: CloneScenarioGraphRequest = {
        params: { scenario_id: payload.id },
        body: { title: payload.title },
      };

      type T = ApiResponse<CloneScenarioGraphResponse>;
      const response: T = yield call(Api.cloneGraphTopic, params);

      const clonedScenarioId = response.data.id;

      if (clonedScenarioId) {
        openGraphById(clonedScenarioId);
      }
      yield put(actions.loadScenarios.request());
    } catch (err) {
      yield put(
        actions.cloneScenario.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.CLONE", { name: i18n.t("ERRORS.API.SCENARIOS.SINGLE") })),
        ),
      );
    }
  });
}

function* createCalling(): SagaIterator {
  yield takeLatest(actions.createCalling.request, function* (action) {
    try {
      yield put(taskActions.runCall.request(action.payload));
    } catch (err) {
      yield put((actions.createCalling.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.RUN")))));
    }
  });
}

function* handleCallingCreated(): SagaIterator {
  yield takeLatest(taskActions.runCall.success, function* (action) {
    try {
      const task: Task = action.payload;

      yield delay(1500);

      const params: GetTasksRequest = {
        limit: 50,
        calls: true,
        types: [TaskType.callInit],
      };

      const tasks: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);
      const status = tasks.data.tasks.find(t => t.id === task.id)?.status;

      if (status === TaskStatus.error) {
        yield put(actions.createCalling.failure(new ErrorAction(null, i18n.t("ERRORS.API.CALLS.RUN"))));
      } else {
        yield put(actions.createCalling.success());
        yield put(actions.closeCallingModal());
        yield put(actions.updateFilesList.request());
      }
    } catch (err) {
      yield put(actions.createCalling.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.RUN"))));
    }
  });
}

function* updateFilesList(): SagaIterator {
  yield takeLatest(actions.updateFilesList.request, function* () {
    try {
      yield put(
        filesActions.getFilesMeta.request({ taskType: TaskType.callInit, limit: 100 }),
      );

      yield takeLatest(filesActions.getFilesMeta.success, function* (action) {
        yield put(actions.updateFilesList.success(action.payload));
      });
    } catch (err) {
      yield put((actions.updateFilesList.failure(new ErrorAction(err, i18n.t("ERRORS.API.FILES.LOAD")))));
    }
  });
}

function* activateScenario(): SagaIterator {
  yield takeLatest(actions.activateScenario.request, function* ({ payload }) {
    try {
      const scenarioId = payload;
      const scenario: ScenarioGraphsItem = yield select(getScenarioById, scenarioId);

      const params: SwitchScenarioGraphAvailabilityRequest = {
        query: {
          scenario_id: scenarioId,
        },
        body: {
          available: true,
          automatable: scenario.automatable,
        },
      };

      yield call(Api.switchScenarioAvailability, params);
      yield put(actions.activateScenario.success(scenarioId));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SCENARIOS.SWITCH_AVAILABILITY");
      yield put(actions.activateScenario.failure(new ErrorAction(err, text)));
    }
  });
}

function* deactivateScenario(): SagaIterator {
  yield takeLatest(actions.deactivateScenario.request, function* ({ payload }) {
    try {
      const scenarioId = payload;
      const scenario: ScenarioGraphsItem = yield select(getScenarioById, scenarioId);

      const params: SwitchScenarioGraphAvailabilityRequest = {
        query: {
          scenario_id: scenarioId,
        },
        body: {
          available: false,
          automatable: scenario.automatable,
        },
      };

      yield call(Api.switchScenarioAvailability, params);
      yield put(actions.deactivateScenario.success(scenarioId));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SCENARIOS.SWITCH_AVAILABILITY");
      yield put(actions.deactivateScenario.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadGamesData(): SagaIterator {
  // eslint-disable-next-line camelcase
  yield takeLatest(actions.loadGamesData.request, function* ({ payload: scenarioSlug }) {
    try {
      type T = ApiResponse<GetGameDataResponse>
      const [diagnostics, marking]: T[] = yield all([
        call(Api.getGameData, { scenario_slug: scenarioSlug, game_slug: ExtendedGameType.Diagnostics }),
        call(Api.getGameData, { scenario_slug: scenarioSlug, game_slug: ExtendedGameType.Marking }),
      ]);

      const result: {
        diagnostics: GameDataDiagnosticsRecord[],
        marking: string[],
      } = {
        diagnostics: (diagnostics.data.records as GameDataDiagnosticsRecord[])
          .map<GameDataDiagnosticsRecord>(objSnakeToCamelDeep),
        marking: (marking.data.records as string[])
          .map<string>(objSnakeToCamelDeep),
      };

      yield put(actions.loadGamesData.success(result));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.TRAININGS_DATA") });
      yield put(actions.loadGamesData.failure(new ErrorAction(err, text)));
    }
  });
}

function* saveGamesData(): SagaIterator {
  // eslint-disable-next-line camelcase
  yield takeLatest(actions.saveGamesData.request, function* ({ payload: gameType }) {
    try {
      type T = ScenarioGraphsState['training']['marking']
             | ScenarioGraphsState['training']['diagnostics'];

      const {
        items,
        itemsToDelete,
      }: T = gameType === ExtendedGameType.Diagnostics ?
        yield select(getTrainingDiagnostics) :
        yield select(getTrainingMarking);

      const scenarioSlug: string = yield select(getTrainingScenarioSlug);

      const filtered = items.filter(({ type }) => type !== undefined && type !== false);
      const records = filtered.map(({ text, type, chatId, iteration }) => ({
        scenario_slug: scenarioSlug,
        text,
        type,
        chat_id: chatId,
        iteration,
      } as GameResultRecord));

      yield call(Api.sendGameResult, {
        params: {
          scenario_slug: scenarioSlug,
          game_slug: gameType,
        },
        body: {
          marking_records: records,
          trash_records: itemsToDelete,
        },
      });

      yield put(actions.saveGamesData.success({
        type: gameType,
        resultsCount: filtered.length,
      }));

      NotificationManager.info(i18n.t("COMMON.SAVED"));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SAVE", { name: i18n.t("ERRORS.API.TRAININGS_DATA") });
      yield put(actions.saveGamesData.failure(new ErrorAction(err, text)));
    }
  });
}

function* toggleScenarioAutomatable(): SagaIterator {
  yield takeLatest(actions.toggleScenarioAutomatable.request, function* (action) {
    try {
      const { id } = action.payload;
      const scenario: ScenarioGraphsItem = yield select(getScenarioById, id);
      const newAutomatableStatus = !scenario.automatable;

      const params: SwitchScenarioGraphAvailabilityRequest = {
        query: {
          scenario_id: id,
        },
        body: {
          automatable: newAutomatableStatus,
          available: scenario.available,
        },
      };

      yield call(Api.switchScenarioAvailability, params);
      yield put(actions.toggleScenarioAutomatable.success({
        id,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.SCENARIOS.SWITCH_AVAILABILITY");
      yield put(actions.toggleScenarioAutomatable.failure(new ErrorAction(err, text)));
    }
  });
}

function* changeScenario(): SagaIterator {
  yield takeLatest(actions.changeTrainingScenario, function* ({ payload: scenarioSlug }) {
    yield put(actions.loadGamesData.request(scenarioSlug));
  });
}

function* editScenario(): SagaIterator {
  yield takeLatest(actions.editScenario, function* ({ payload }) {
    yield put(graphActions.loadGraphById.request(payload.scenarioId));
    yield take(graphActions.loadGraphById.success);
    yield put(actions.openScenarioEditor());
  });
}

function* closeEditor(): SagaIterator {
  yield takeLatest(actions.closeScenarioEditor, function* () {
    yield put(actions.loadScenarios.request());
  });
}

function* loadScenariosLabels(): SagaIterator {
  yield takeLatest(actions.loadScenariosLabels.request, function* () {
    try {
      const response: ApiResponse<
        GetScenarioGraphsLabelsResponse
      > = yield call(apiService.getScenarioLabels);

      yield put(actions.loadScenariosLabels.success(response.data
        .map<ScenarioGraphLabel>(objSnakeToCamelDeep),
      ));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SCENARIO_LABELS.MULTIPLE") });
      yield put(actions.loadScenariosLabels.failure(new ErrorAction(err, text)));
    }
  });
}

function* createScenarioLabel(): SagaIterator {
  yield takeLatest(actions.createScenarioLabel.request, function* (action) {
    try {
      const title = action.payload;
      const slug = transliterate(action.payload)
        .replace(/\s/g, "_");

      const request: CreateScenarioGraphLabelRequest = {
        body: {
          id: generateId(),
          title,
          slug,
        },
      };

      const response: ApiResponse<CreateScenarioGraphLabelResponse> = yield call(
        apiService.createScenarioLabel,
        request,
      );

      yield put(actions.createScenarioLabel.success(response.data));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.SCENARIO_LABELS.SINGLE") });
      yield put(actions.createScenarioLabel.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteScenarioLabel(): SagaIterator {
  yield takeLatest(actions.deleteScenarioLabel.request, function* (action) {
    try {
      const request: DeleteScenarioGraphLabelRequest = {
        path: {
          label_id: action.payload,
        },
      };

      yield call(
        apiService.deleteScenarioLabel,
        request,
      );

      yield put(actions.deleteScenarioLabel.success(action.payload));
      yield put(actions.closeEditLabelModal());
      yield put(actions.closeDeleteLabelModal());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if ((err || {}).code === "label_has_connected_scenario") {
        err.message = i18n.t("ERRORS.API.SCENARIO_LABELS.DUPLICATE");
      }
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.SCENARIO_LABELS.SINGLE") });
      yield put(actions.deleteScenarioLabel.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateScenarioLabel(): SagaIterator {
  yield takeLatest(actions.updateScenarioLabel.request, function* (action) {
    try {
      const {
        label: updatedLabel,
        scenarios: updatedScenariosIds,
      } = action.payload;

      const oldLabel: ScenarioGraphLabel | undefined = yield select(getScenarioLabelById, updatedLabel.id);

      if (!oldLabel) {
        throw new Error("Label not found");
      }

      const titleChanged = updatedLabel.title !== oldLabel.title;
      let labelToSave = updatedLabel;

      if (titleChanged) {
        const request: UpdateScenarioGraphLabelRequest = {
          path: {
            label_id: updatedLabel.id,
          },
          body: objCamelToSnakeDeep(updatedLabel),
        };

        const response: ApiResponse<UpdateScenarioGraphLabelResponse> = yield call(
          apiService.updateScenarioLabel,
          request,
        );

        labelToSave = objSnakeToCamelDeep(response.data);
      }

      const request: ChangeLabelScenariosRequest = {
        path: {
          label_id: updatedLabel.id,
        },
        body: {
          scenarios_id: updatedScenariosIds
            .filter(onlyUnique)
            .map(Number),
        },
      };

      yield call(
        apiService.updateLabelScenarios,
        request,
      );

      yield put(actions.updateScenarioLabel.success({
        label: labelToSave,
        scenarios: updatedScenariosIds,
      }));

      NotificationService.success(i18n.t("COMMON.SAVED"));
      yield put(actions.loadScenarios.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.SCENARIO_LABELS.SINGLE") });
      yield put(actions.updateScenarioLabel.failure(new ErrorAction(err, text)));
    }
  });
}

function* addScenariosToLabel(): SagaIterator {
  yield takeLatest(actions.addScenariosToLabel.request, function* (action) {
    try {
      const {
        label: labelId,
        scenarios: scenarioIds,
      } = action.payload;

      const currentScenarios: ScenarioGraphsItem[] = yield select(getLabelScenarios, labelId);
      const newScenariosList = [
        ...currentScenarios.map(s => s.id),
        ...scenarioIds,
      ].filter(onlyUnique);

      const request: ChangeLabelScenariosRequest = {
        path: {
          label_id: labelId,
        },
        body: {
          scenarios_id: newScenariosList.map(Number),
        },
      };

      yield call(apiService.updateLabelScenarios, request);

      yield put(actions.addScenariosToLabel.success({
        label: labelId,
        scenarios: newScenariosList,
      }));
      yield put(actions.loadScenarios.request());
      yield put(actions.closeAddScenariosToLabelModal());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.SCENARIO_LABELS.SINGLE") });
      yield put(actions.addScenariosToLabel.failure(new ErrorAction(err, text)));
    }
  });
}

function* removeScenarioFromLabel(): SagaIterator {
  yield takeLatest(actions.removeScenarioFromLabel.request, function* (action) {
    try {
      const { label, scenario } = action.payload;
      const labelScenarios: ScenarioGraphsItem[] = yield select(getLabelScenarios, label);

      const newScenariosList = labelScenarios
        .filter(s => s.id !== scenario)
        .map(s => s.id);

      const request: ChangeLabelScenariosRequest = {
        path: {
          label_id: label,
        },
        body: {
          scenarios_id: newScenariosList.map(Number),
        },
      };

      yield call(
        apiService.updateLabelScenarios,
        request,
      );

      yield put(actions.removeScenarioFromLabel.success({
        label,
        scenario,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.SCENARIO_LABELS.SINGLE") });
      yield put(actions.removeScenarioFromLabel.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadActionIdentifierSuggestions(): SagaIterator {
  yield takeLatest(actions.loadActionIdentifierSuggestions.request, function* (action) {
    try {
      const search = action.payload || '';
      const params: GetActionSuggestRequest = {
        search,
      };

      const response: ApiResponse<GetActionSuggestResponse> = yield call(apiService.getActionSuggest, params);

      yield put(actions.loadActionIdentifierSuggestions.success(response.data.action_types));
    } catch (err) {
      yield put(actions.loadActionIdentifierSuggestions.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SCENARIOS.SUGGEST_LOAD"),
      )));
    }
  });
}

function* startExportingScenarios(): SagaIterator {
  yield takeLatest(actions.startExportingScenarios, function* (action) {
    const scenariosList = action.payload;
    const exportTask: SagaTask = yield fork(exportScenariosTask, scenariosList);
    yield take(actions.stopExportingScenarios);
    yield cancel(exportTask);
  });
}

function* exportScenariosTask(scenarios: ScenarioGraphsItem[]): SagaIterator {
  const projectSlug: string = yield select(getSelectedProjectSlug);

  const successList: Array<ScenarioGraphMeta['title']> = [];
  const errorList: Array<ScenarioGraphMeta['title']> = [];

  const zip = new JSZip();
  for (let i = 0; i < scenarios.length; i++) {
    const scenario = scenarios[i];
    const params: GetGraphRequest = {
      path: {
        scenario_id: scenario.id,
      },
    };

    try {
      const response: ApiResponse<GetGraphResponse> = yield call(Api.getGraph, params);
      const graph = response.data;

      zip.file(`${scenario.slug}.json`, JSON.stringify(graph, undefined, " "));
      successList.push(scenario.title);
    } catch {
      errorList.push(scenario.title);
    } finally {
      yield put(actions.updateExportingScenarioCount(i + 1));
    }
  }

  const content = yield call(generateZip, zip);
  const today = new Date();
  FileSaver.saveAs(content, `scenarios-${projectSlug}-${format(today, "yyyy-MM-dd HH:mm")}.zip`);
  const zipLink = window.URL.createObjectURL(content);

  yield put(actions.updateExportinScenariosInfo({
    errorList: errorList,
    successList: successList,
    zipLink,
  }));
}

function* navigateToScenario(): SagaIterator {
  yield takeLatest(actions.navigateToScenario, function* (action) {
    try {
      const {
        scenarioId,
        debugIds,
        nodeId,
        scenarioSlug,
        openNode,
      } = action.payload;

      let versionId = action.payload.version;

      const versions: AppState['versions']['list'] = yield select(getVersions);

      if (!versions.wasLoaded) {
        yield put(loadVersions.request());

        yield take([
          loadVersions.success,
          loadVersions.failure,
        ]);
      }

      const separatorIndex = versionId?.indexOf("_");
      const versionNumber = separatorIndex !== -1 ?
        versionId?.slice(0, separatorIndex) :
        versionId;

      const availableVersions: Version[] = yield select(getVersionsAvailableToSwitch);
      const queryParams = new URLSearchParams();

      const version = availableVersions.find(v => v.version === Number(versionNumber));

      queryParams.set("version", version?.id ?? DRAFT_VERSION.id);
      queryParams.set("scenario_id", scenarioId);

      const scenarioExist: boolean = yield call(checkIfGraphExist, scenarioId, version?.id ?? DRAFT_VERSION.id);

      if (!scenarioExist) {
        const scenarios: ScenarioGraphMeta[] = yield select(getScenarioGraphs);
        const scenarioWithSameSlug = scenarios.find(s => s.slug === scenarioSlug);

        if (scenarioWithSameSlug) {
          queryParams.set("version", DRAFT_VERSION.id);
          queryParams.set("scenario_id", scenarioWithSameSlug.id);
        } else {
          NotificationManager.error(i18n.t("NEW_PAGE_DIALOGS_HISTORY.DIALOG_MODAL.SCENARIO_DOESNT_EXIST"));
          return;
        }
      }

      nodeId && queryParams.set("node_id", nodeId);
      openNode && queryParams.set("open_node", String(openNode));
      if (debugIds) {
        try {
          queryParams.set("debug_id", JSON.stringify(debugIds));
        } catch (err) {
          console.error("Can't set debug id: ", err);
        }
      }

      window.open(authorizedPages.editor + "?" + String(queryParams), "_blank");
    } catch (err) {
      console.error(err);
    }
  });
}

function* loadActiveScenarioUsage(): SagaIterator {
  yield takeLatest(actions.loadActiveScenarioUsage.request, function* () {
    try {
      const config: UseParentScenariosConfig = yield select(getConfig, 'supportai-frontend:use_parent_scenarios');
      const activeScenarioId: string = yield select(getActiveGraphId);

      if (!config?.enabled || !activeScenarioId) {
        yield put(actions.loadActiveScenarioUsage.success({ selected: [], nodes: [] }));
        return;
      }

      const mock = config?.useMocks;
      if (!mock) {
        const request: GetScenarioUsageRequest = {
          query: {
            ref_scenario_id: activeScenarioId,
          },
        };

        const response: ApiResponse<GetScenarioUsageResponse> = yield call(
          apiService.getScenarioUsage,
          request,
        );

        const nodes = response.data.select_scenario_nodes
          .map(parseScenarioGraphUsage);
        const selected = response.data.select_scenario_nodes
          .filter(m => m.is_ref_scenario_included)
          .map(m => m.id) as string[];

        yield put(actions.loadActiveScenarioUsage.success({ nodes, selected }));
      } else {
        const mocks = usageMock.select_scenario_nodes.map(parseScenarioGraphUsage);
        const selected = usageMock.select_scenario_nodes
          .filter(m => m.is_ref_scenario_included)
          .map(m => m.id) as string[];

        yield put(actions.loadActiveScenarioUsage.success({
          nodes: mocks,
          selected,
        }));
      }
    } catch (err) {
      const text = i18n.t("ERROS.API.LOAD", { name: i18n.t("ERRORS.API.SCENARIOS.MULTIPLE") });
      yield put(actions.loadActiveScenarioUsage.failure(new ErrorAction(err, text)));
    }
  });
}

function* saveActiveScenarioUsage(): SagaIterator {
  yield takeLatest(actions.saveActiveScenarioUsage.request, function* (action) {
    try {
      const parentsConfig: UseParentScenariosConfig = yield select(getConfig, 'supportai-frontend:use_parent_scenarios');
      const activeScenarioId: string = yield select(getActiveGraphId);
      const enabled = parentsConfig?.enabled;
      if (!enabled || !activeScenarioId) {
        yield put(actions.saveActiveScenarioUsage.success([]));
        return;
      }

      const mock = parentsConfig?.useMocks;
      const newSelected = action.payload;
      if (mock) {
        yield put(actions.saveActiveScenarioUsage.success(newSelected));
        return;
      }

      const currentSelected: string[] = yield select(getSelectedParentNodes);
      const toRemove = currentSelected.filter(id => !newSelected.includes(id));
      const toAdd = newSelected.filter(id => !currentSelected.includes(id));

      for (let nodeId of toRemove) {
        const request: UpdateScenarioUsageRequest = {
          query: {
            scenario_id: activeScenarioId,
            operation: "remove",
          },
          path: {
            node_id: nodeId,
          },
        };
        yield call(
          apiService.updateScenarioUsage,
          request,
        );
      }

      for (let nodeId of toAdd) {
        const request: UpdateScenarioUsageRequest = {
          query: {
            scenario_id: activeScenarioId,
            operation: "add",
          },
          path: {
            node_id: nodeId,
          },
        };
        yield call(
          apiService.updateScenarioUsage,
          request,
        );
      }
      yield put(actions.saveActiveScenarioUsage.success(newSelected));
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.SCENARIOS.SINGLE") });
      yield put(actions.saveActiveScenarioUsage.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateScenariosPriority(): SagaIterator {
  yield takeLatest(actions.updateScenariosPriority.request, function* (action) {
    try {
      const request: UpdateScenariosPriorityRequest = {
        priorities: action.payload.map((scenario, index, arr) => ({
          scenario_id: Number(scenario.id),
          previous_scenario_id: arr[index - 1]?.groupName === scenario.groupName ? Number(arr[index - 1].id) : null,
        })),
      };

      yield call(
        apiService.updateScenariosPriority,
        request,
      );

      yield put(actions.updateScenariosPriority.success());
    } catch (err) {
      yield put(actions.updateScenariosPriority.failure(new ErrorAction(err)));
    }
  });
}

async function generateZip(zip: JSZip) {
  return zip.generateAsync({ type: "blob" }).then(content => {
    return content;
  });
}

function* updateScenariosOnVersionChange(): SagaIterator {
  yield takeLatest(changeVersionToDisplay, function* () {
    yield put(actions.loadScenarios.request());
  });
}

export default function* scenarioGraphsSagas(): SagaIterator {
  yield fork(createCalling);
  yield fork(handleCallingCreated);
  yield fork(updateFilesList);
  yield fork(loadScenarios);
  yield fork(deleteScenario);
  yield fork(cloneScenario);
  yield fork(createScenario);
  yield fork(activateScenario);
  yield fork(deactivateScenario);
  yield fork(toggleScenarioAutomatable);
  yield fork(changeScenario);
  yield fork(saveGamesData);
  yield fork(loadGamesData);
  yield fork(editScenario);
  yield fork(closeEditor);
  yield fork(loadScenariosLabels);
  yield fork(createScenarioLabel);
  yield fork(deleteScenarioLabel);
  yield fork(loadActionIdentifierSuggestions);
  yield fork(startExportingScenarios);
  yield fork(updateScenarioLabel);
  yield fork(addScenariosToLabel);
  yield fork(removeScenarioFromLabel);
  yield fork(navigateToScenario);
  yield fork(loadActiveScenarioUsage);
  yield fork(saveActiveScenarioUsage);
  yield fork(updateScenariosPriority);
  yield fork(updateScenariosOnVersionChange);
}
