import React, { CSSProperties } from 'react';

import i18n from '../../services/i18n';

import { SortDirection } from "../../types/models/Sorting";
import {
  ColumnConfig,
  ColumnsConfiguration,
  ColumnsGroup,
  DataItem,
  DataItemType,
  HeaderCellRendererFunction,
  HeaderConfiguration,
  HeaderGroup,
  HeaderItem,
  Sorting,
  SortingConfig,
} from "./types";
import { sortStrings } from '../../services/helpers/utilities';

export function parseHeaderItems(
  columns: ColumnConfig[],
  activeSorting: Sorting | undefined,
): HeaderConfiguration {
  return columns.map<HeaderItem>(config => {
    const {
      id, title, className, sortingConfig,
    } = config;

    const sorting = sortingConfig?.find(s => s.id === activeSorting?.sorting);

    return {
      id,
      title,
      className,
      activeSorting: sorting?.id,
      availableSortings: config.sortingConfig,
      group: config._group,
      groupEnd: config._groupEnd,
      groupStart: config._groupStart,
      renderer: config.headerCellRenderer || defaultHeaderCellRenderer,
    };
  });
}

export function flatColumnsConfiguration(value: ColumnsConfiguration): ColumnConfig[] {
  const flatColumns = value.reduce<ColumnConfig[]>((result, item) => {
    if (isColumnsGroup(item)) {
      result = result
        .concat((item as ColumnsGroup)
          .columns.map(c => ({ ...c, _group: item.title })),
        );
    } else {
      result.push(item as ColumnConfig);
    }

    return result;
  }, []);

  const groupsPos = flatColumns.map(c => c._group);

  return flatColumns.map((column, index) => {
    if (column._group) {
      const groupStart = groupsPos.indexOf(column._group);
      const groupEnd = groupsPos.lastIndexOf(column._group);

      column._groupStart = index === groupStart;
      column._groupEnd = index === groupEnd;
    }

    return column;
  });
}

export function parseColumnConfig(
  config: ColumnConfig,
  activeSorting: Sorting | undefined,
): HeaderItem {
  const {
    id, title, className, sortingConfig,
  } = config;

  const sorting = sortingConfig?.find(s => s.id === activeSorting?.sorting);

  return {
    id,
    title,
    className,
    activeSorting: sorting?.id,
    availableSortings: config.sortingConfig,
    groupEnd: config._groupEnd,
    groupStart: config._groupStart,
    group: config._group,
    renderer: config.headerCellRenderer || defaultHeaderCellRenderer,
  };
}

export function isColumnsGroup(item: ColumnConfig | ColumnsGroup) {
  return (item as Object).hasOwnProperty('columns');
}

export function isHeaderGroup(item: HeaderGroup | HeaderItem) {
  return (item as Object).hasOwnProperty('items');
}

export function getDataItemType(item: DataItem): DataItemType {
  return item?.items ? DataItemType.folder : DataItemType.item;
}

export function isFolder(item: DataItem): boolean {
  return getDataItemType(item) === DataItemType.folder;
}

export function getColumnValue(
  item: DataItem,
  column: ColumnConfig,
  dataKey: string = "",
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (column.accessor.length > 0 || dataKey.length > 0) {
    const keys = [column.accessor, dataKey]
      .filter(k => !!k)
      .join(".");

    return getProp(item, keys);
  }

  return item;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProp(object: any, keys: string | string[], defaultVal?: any): any {
  const keysList = Array.isArray(keys) ? keys : keys.split('.');
  const result = object[keysList[0]];

  if (result && keysList.length > 1) {
    return getProp(result, keysList.slice(1));
  }

  return result === undefined ? defaultVal : result;
}

export function sortData({
  data,
  column,
  sortConfigId,
}: {
  data: DataItem[],
  column: ColumnConfig,
  sortConfigId: SortingConfig['id'],
}): DataItem[] {
  const sortConfig = column.sortingConfig?.find(s => s.id === sortConfigId);
  if (!sortConfig) return data;

  const {
    dataKey,
    deepSort,
    direction,
    sortFunction = defaultSortFunction,
  } = sortConfig;

  const dataCopy: DataItem[] = data.slice();

  const sorted = dataCopy.sort((itemA: DataItem, itemB: DataItem) => {
    if (itemA?.isTotals) return -1;
    if (itemB?.isTotals) return 1;

    const valueA: unknown = getColumnValue(itemA, column, dataKey);
    const valueB: unknown = getColumnValue(itemB, column, dataKey);

    let result = sortFunction(valueA, valueB, dataKey);
    if (direction === SortDirection.DESC) result *= -1;
    return result;
  });

  if (deepSort) {
    return sorted.map(item => (item.items ?
      {
        ...item,
        items: sortData({
          data: item.items, column, sortConfigId,
        }),
      } :
      item));
  }

  return sorted;
}

export const defaultHeaderCellRenderer: HeaderCellRendererFunction = (title: string) => <span>{title}</span>;

export const getGroupStyles = (group: ColumnsGroup | HeaderGroup): CSSProperties => {
  const childrenArray = (group as ColumnsGroup).columns || (group as HeaderGroup).items || [];
  return {
    flex: `${childrenArray.length} ${childrenArray.length} 0`,
  };
};

export function getNextSimpleSorting(
  availableSortings: SortingConfig[],
  activeColumn: ColumnConfig['id'],
  activeSorting?: SortingConfig['id'],
): Sorting | undefined {
  if (availableSortings.length !== 2) return undefined;

  if (!activeSorting) {
    return {
      column: activeColumn,
      sorting: availableSortings[0].id,
    };
  }

  const activeIndex = availableSortings.findIndex(s => s.id === activeSorting);

  if (activeIndex === 0) {
    return {
      column: activeColumn,
      sorting: availableSortings[1].id,
    };
  }

  return undefined;
}

export const getSimpleSortingConfig = (
  sortingId: string,
  dataKey: string,
): SortingConfig[] => {
  return [
    {
      id: `simple_${sortingId}_${dataKey}_${SortDirection.ASC}`,
      dataKey,
      direction: SortDirection.ASC,
    },
    {
      id: `simple_${sortingId}_${dataKey}_${SortDirection.DESC}`,
      dataKey,
      direction: SortDirection.DESC,
    },
  ];
};

export const getSimpleStringSortingConfig = (
  sortingId: string,
  dataKey: string,
): SortingConfig[] => {
  return [
    {
      id: `simple_${sortingId}_${dataKey}_${SortDirection.ASC}`,
      dataKey,
      direction: SortDirection.ASC,
      sortFunction: sortStrings,
    },
    {
      id: `simple_${sortingId}_${dataKey}_${SortDirection.DESC}`,
      dataKey,
      direction: SortDirection.DESC,
      sortFunction: sortStrings,
    },
  ];
};

export const getSimpleDeepSortingConfig = (
  sortingId: string,
  dataKey: string,
): SortingConfig[] => {
  return [
    {
      id: `simple_${sortingId}_${dataKey}_${SortDirection.ASC}`,
      dataKey,
      direction: SortDirection.ASC,
      deepSort: true,
    },
    {
      id: `simple_${sortingId}_${dataKey}_${SortDirection.DESC}`,
      dataKey,
      direction: SortDirection.DESC,
      deepSort: true,
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultSortFunction = (itemA: any, itemB: any) => {
  return itemA > itemB ? 1 : -1;
};

export const getDefaultSorting = (
  sortingId: string,
  dataKey: string,
  title?: string,
): SortingConfig[] => {
  return [
    {
      id: `${sortingId}_${dataKey}_${SortDirection.ASC}`,
      label: title ?
        `${title} (${i18n.t("TABLE.ASC")})` :
        i18n.t("TABLE.ASC"),
      direction: SortDirection.ASC,
      dataKey,
    },
    {
      id: `${sortingId}_${dataKey}_${SortDirection.DESC}`,
      label: title ?
        `${title} (${i18n.t("TABLE.DESC")})` :
        i18n.t("TABLE.DESC"),
      direction: SortDirection.DESC,
      dataKey,
    },
  ];
};

const dateSortingFunction = (itemA: string, itemB: string) => {
  return +(new Date(itemA)) < +(new Date(itemB)) ? 1 : -1;
};

export const getDefaultDateSorting = (
  sortingId: string,
  dataKey: string,
  title?: string,
): SortingConfig[] => (
  [
    {
      id: `${sortingId}_${dataKey}_${SortDirection.ASC}`,
      label: title ?
        `${title} (${i18n.t("TABLE.DATE_ASC")})` :
        i18n.t("TABLE.DATE_ASC"),
      direction: SortDirection.ASC,
      dataKey,
      sortFunction: dateSortingFunction,
    },
    {
      id: `${sortingId}_${dataKey}_${SortDirection.DESC}`,
      label: title ?
        `${title} (${i18n.t("TABLE.DATE_DESC")})` :
        i18n.t("TABLE.DATE_DESC"),
      direction: SortDirection.DESC,
      dataKey,
      sortFunction: dateSortingFunction,
    },
  ]
);

export const getDateLengthSorting = (
  dataKey?: string,
): SortingConfig[] => (
  [
    {
      id: `dateLength_${SortDirection.ASC}`,
      label: i18n.t("TABLE.DATE_LENGTH_ASC"),
      direction: SortDirection.ASC,
      dataKey,
    },
    {
      id: `dateLength_${SortDirection.DESC}`,
      label: i18n.t("TABLE.DATE_LENGTH_DESC"),
      direction: SortDirection.DESC,
      dataKey,
    },
  ]
);
