import { ScenarioGraphExample, ScenarioGraphExampleType } from "../../types/models/ScenarioGraphExample";
import { ScenarioGraphsItem, ScenarioGraphsList } from "../scenarioGraphs/types";
import { ScenariosExamplesState } from "./reducer";
import { DeletionParams, ExampleData } from "./types";

type R = Record<ScenarioGraphsItem['id'], ScenarioGraphsItem>;
export const groupScenariosById = (scenarios: ScenarioGraphsList): R => (
  scenarios.reduce(
    (result, scenario) => ({
      ...result,
      [scenario.id]: scenario,
    }),
    {},
  )
);

export const updateExamplesOnDelete = (
  state: ScenariosExamplesState,
  payload: DeletionParams,
  status: 'request' | 'success' | 'failure',
): ScenariosExamplesState => {
  const { type, scenarioId, selectedExamples } = payload;
  const result = { ...state };

  if (type === 'selected') {
    if (!selectedExamples) return state;

    Object.keys(selectedExamples).forEach(id => {
      const examples = result.examples[id];
      if (!examples) return state;

      const selected = selectedExamples[id];

      result.examples[id] = {
        ...examples,
        loading: status === 'request',
        list: status !== 'success' ?
          examples.list :
          examples.list.filter(item => !selected[item.id]),
      };
    });

    return result;
  }

  if (type === 'scenario') {
    if (!scenarioId) return state;

    if (!result.examples[scenarioId]) return state;
    result.examples[scenarioId].loading = status === 'request';

    if (status === 'success') {
      result.examples[scenarioId].list = [];
    }

    return result;
  }

  const getUpdatedExamples = (id: string, exclude: ScenarioGraphExampleType): ExampleData => {
    const defaultExamples = result.examples[id];

    return {
      ...defaultExamples,
      loading: status === 'request',
      ...status === 'success' && ({
        list: defaultExamples.list.filter(item => item.type !== exclude),
        totalAdditional: exclude === ScenarioGraphExampleType.addition ? 0 : defaultExamples.totalAdditional,
        totalException: exclude === ScenarioGraphExampleType.exception ? 0 : defaultExamples.totalException,
      }),
    };
  };

  if (type === ScenarioGraphExampleType.addition) {
    if (scenarioId) {
      return {
        ...result,
        examples: {
          ...result.examples,
          [scenarioId]: getUpdatedExamples(scenarioId, ScenarioGraphExampleType.addition),
        },
      };
    }

    return {
      ...result,
      examples: Object.keys(result.examples).reduce(
        (o, id) => ({
          ...o,
          [id]: getUpdatedExamples(id, ScenarioGraphExampleType.addition),
        }),
        {} as ScenariosExamplesState['examples'],
      ),
    };
  }

  if (type === ScenarioGraphExampleType.exception) {
    if (scenarioId) {
      return {
        ...result,
        examples: {
          ...result.examples,
          [scenarioId]: getUpdatedExamples(scenarioId, ScenarioGraphExampleType.exception),
        },
      };
    }

    return {
      ...result,
      examples: Object.keys(result.examples).reduce(
        (o, id) => ({
          ...o,
          [id]: getUpdatedExamples(id, ScenarioGraphExampleType.exception),
        }),
        {} as ScenariosExamplesState['examples'],
      ),
    };
  }

  if (type === 'trash') {
    return {
      ...result,
      trashPhrases: {
        loading: status === 'request',
        ...status === 'success' && ({
          phrases: [],
          total: 0,
        }),
      },
    } as ScenariosExamplesState;
  }

  //all
  result.examples = {};

  result.trashPhrases = {
    ...result.trashPhrases,
    loading: status === 'request',
    ...status === 'success' && ({
      phrases: [],
      total: 0,
    }),
  };

  return result;
};

export function updateExample(
  state: ScenariosExamplesState,
  scenarioId: ScenarioGraphsItem['id'],
  exampleId: ScenarioGraphExample['id'],
  callback: (item: ScenarioGraphExample) => ScenarioGraphExample,
): ScenariosExamplesState {
  return {
    ...state,
    examples: {
      ...state.examples,
      [scenarioId]: {
        ...state.examples[scenarioId],
        list: state.examples[scenarioId].list.map(e => e.id === exampleId ? callback(e) : e),
      },
    },
  };
}
