import React, { FC } from 'react';
import { SvgProps } from '../types';

const ClearFilledIcon: FC<SvgProps> = ({
  width = '20',
  height = '20',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill={color} />
    <path d="M6 6L14.5 14.5M14.5 6L6 14.5" stroke="white" strokeWidth="2" />
  </svg>
);

export default ClearFilledIcon;
