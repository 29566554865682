import React from 'react';

import { SvgProps } from '../types';

function LightningIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2896 10.2778L15.0673 0.5L3 14.7222H9.68013L7.30976 24.5L20.2391 10.2778H13.2896Z"
        fill={color}
      />
    </svg>
  );
}

export default LightningIcon;
