import { SearchRequest } from "~/api-types/backend-py3/services/supportai-history/docs/yaml/definitions";
import { dateMscToLocal } from "../../services/helpers/dateUtils";
import { objSnakeToCamel, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import { SamplingBackend, SamplingStatisticsPlotItemBackend, SamplingsStatisticsBackend, SamplingNewBackend } from "../backendModels/SamplingBackend";
import { DialogsHistorySearchFilter } from "../models/Dialog";
import { Sampling, SamplingStatisticsPlotItem, SamplingsStatistics, NewSampling } from "../models/Sampling";

export function parseSampling(value: SamplingBackend): Sampling {
  return {
    ...objSnakeToCamel(value, true),
    ...(value.created_at && { createdAt: dateMscToLocal(new Date(value.created_at)).toString() }),
  };
}

export function parseNewSampling(value: SamplingNewBackend): NewSampling {
  const { filters, ...rest } = value;
  return {
    ...objSnakeToCamel(rest),
    filters: parseFilters(filters),
  };
}

export function parseFilters(value: SearchRequest): DialogsHistorySearchFilter {
  const { start_dt, end_dt, chat_ids, max_iteration, simulated, min_csat, max_csat, ...rest } = value;
  return {
    ...objSnakeToCamel(rest),
    ...(start_dt && { dateFrom: dateMscToLocal(new Date(start_dt)).getTime() }),
    ...(end_dt && { dateTo: dateMscToLocal(new Date(end_dt)).getTime() }),
    ...(chat_ids && { chatId: chat_ids[0] }),
    ...(max_iteration && { iterations: String(max_iteration) }),
    ...(typeof simulated === 'boolean' && { mode: simulated }),
    ...(min_csat && { minUserScore: min_csat }),
    ...(max_csat && { maxUserScore: max_csat }),
  };
}

export function parsePlotStatisticsItem(
  value: SamplingStatisticsPlotItemBackend,
): SamplingStatisticsPlotItem {
  return {
    ...objSnakeToCamelDeep(value),
    datetime: dateMscToLocal(new Date(value.datetime)).toString(),
  };
}

export function parseSamplingsStatistics(
  value: SamplingsStatisticsBackend,
): SamplingsStatistics {
  return {
    markedCount: value.marked_count,
    okCount: value.ok_count,
    plotItems: value.plot_items.map(parsePlotStatisticsItem),
  };
}
