import { createAsyncAction } from "typesafe-actions";
import { Slot } from "../../types/models/Slots";
import { ErrorAction } from "../reducers";

export const loadSlots = createAsyncAction(
  'slots/loadSlots/requested',
  'slots/loadSlots/succeeded',
  'slots/loadSlots/failed',
)<{ modelId: string }, { modelId: string, slots: Slot[] }, ErrorAction<{ modelId: string }>>();

export const loadFreeSlots = createAsyncAction(
  'slots/loadFreeSlots/requested',
  'slots/loadFreeSlots/succeeded',
  'slots/loadFreeSlots/failed',
)<undefined, Slot[], ErrorAction>();

export const lockSlot = createAsyncAction(
  'slots/lockSlot/requested',
  'slots/lockSlot/succeeded',
  'slots/lockSlot/failed',
)<Slot, Slot, ErrorAction<Slot>>();

export const unlockSlot = createAsyncAction(
  'slots/unlockSlot/requested',
  'slots/unlockSlot/succeeded',
  'slots/unlockSlot/failed',
)<Slot, Slot, ErrorAction<Slot>>();
