import React, { useCallback, useState, useContext } from 'react';

import { DataItem } from '../types';
import { b, TableContext } from '../index';
import { isFolder } from '../helpers';

import TableRow from './row';
import { ColumnConfig } from '../../table/types';

type TableFolderProps = {
  item: DataItem;
  columns: ColumnConfig[];
  className?: string;
  defaultExpanded?: boolean;
  level: number;
}

function TableFolder({
  item,
  level,
  columns,
  className,
  defaultExpanded = false,
}: TableFolderProps) {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const { onFolderClick } = useContext(TableContext);

  const handleExpandClick = useCallback(
    (event: React.MouseEvent) => {
      if (!event.defaultPrevented) {
        setExpanded(!expanded);

        if (onFolderClick && !item?.items?.length) {
          onFolderClick(item);
        }
      }
    },
    [expanded, item, onFolderClick],
  );

  return (
    <>
      <button
        type="button"
        className={b("folder-wrapper", { level, expanded })}
        onClick={handleExpandClick}
      >
        <TableRow item={item} columns={columns} />
      </button>
      {expanded && Array.isArray(item.items) &&
      item.items.map((child: DataItem) => (isFolder(child) ?
        (
          <TableFolder
            key={child.id}
            level={level + 1}
            item={child}
            columns={columns}
          />
        ) : (
          <TableRow key={child.id} item={child} columns={columns} className={className} />
        )))}
    </>
  );
}

export default React.memo(TableFolder);
