import React from 'react';

import CustomSortingHeaderCell from './customSortingHeaderCell';
import { CustomSortingCellData, HeaderItem, Sorting } from '../types';
import { b } from '../index';

export const DEFAULT_HEADER_CELL_CLASSNAME = 'default';

type HeadeCellProps = {
  item: HeaderItem;
  short?: boolean;
  onSortingChange?: (sorting: Sorting | undefined) => void;
}

function HeaderCell({
  item,
  short = false,
  onSortingChange,
}: HeadeCellProps) {
  const { activeSorting, availableSortings } = item;

  const sortable: boolean = !!((availableSortings || [])?.length > 0 && activeSorting);
  let className = item.className || DEFAULT_HEADER_CELL_CLASSNAME;

  if (item.groupStart) {
    className += " group-start";
  }

  if (item.groupEnd) {
    className += " group-end";
  }

  if ((availableSortings || []).length > 0 && onSortingChange) {
    return (
      <CustomSortingHeaderCell
        headerCellClassName={b("header-cell", { sortable: true, short }).mix(className).toString()}
        column={item as CustomSortingCellData}
        sortingId={activeSorting}
        onSortingChange={onSortingChange}
      />
    );
  }

  const Tag = sortable ? 'button' : 'div';

  return (
    <Tag
      className={b("header-cell", { sortable, short }).mix(className)}
    >
      <span>{item.renderer(item.title)}</span>
    </Tag>
  );
}

export default React.memo(HeaderCell);
