/* eslint-disable camelcase */
import { SagaIterator } from "@redux-saga/types";

import {
  call,
  fork,
  put,
  takeLatest,
  select,
} from "redux-saga/effects";

import NotificationManager from "../../services/notifications";
import apiService, { ApiResponse } from "../../services/api";
import i18n from '../../services/i18n';

import { ErrorAction } from "../reducers";

import * as actions from './actions';

import {
  GetActionsRequest,
  GetActionsResponse,
  GetCallbacksRequest,
  GetCallbacksResponse,
  GetIntegrationsRequest,
  GetIntegrationsResponse,
  GetLinkedProjectsResponse,
  LinkIntegrationToProjectsRequest,
  PostActionRequest,
  PostCallbackRequest,
  PostIntegrationRequest,
  PutActionRequest,
  PutCallbackRequest,
  PutIntegrationRequest,
} from "../../types/requests/IncomingIntegraions";

import {
  parseIncomingIntegration,
  parseIncomingIntegrationAction,
  parseIncomingIntegrationActionToBackend,
  parseIncomingIntegrationCallback,
  parseIncomingIntegrationCallbackToBackend,
  parseIncomingIntegrationToBackend,
} from "../../types/parsers/IncomingIntegrations";

import { getIntegrationToEdit } from "./selectors";

import { IncomingIntegration, ProjectIntegration } from "../../types/models/IncomingIntegrations";
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import { generateId } from "../../services/helpers/generateId";

function* loadCustomIntegrations(): SagaIterator {
  yield takeLatest(actions.loadIntegrations.request, function* () {
    try {
      const params: GetIntegrationsRequest = {
        // limit: 500,
        // offset: 0,
      };

      type R = ApiResponse<GetIntegrationsResponse>;
      const response: R = yield call(apiService.getCustomIntegrations, params);
      const parsed = response.data.integrations.map(parseIncomingIntegration);

      yield put(actions.loadIntegrations.success(parsed));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.LOAD");
      yield put(actions.loadIntegrations.failure(new ErrorAction(err, text)));
    }
  });
}

function* editIntegration(): SagaIterator {
  yield takeLatest(actions.setIntegrationToEdit, function* ({ payload }) {
    const integrationId = payload?.id;
    if (!integrationId) return;

    yield put(actions.loadActions.request({ integrationId }));
  });
}

function* saveCustomIntegration(): SagaIterator {
  yield takeLatest(actions.saveIntegration.request, function* ({ payload }) {
    try {
      const body = parseIncomingIntegrationToBackend(payload.data);
      let typedResponse: ApiResponse<PutIntegrationRequest | PostIntegrationRequest>;

      if (body.id) {
        typedResponse = yield call(
          apiService.updateCustomIntegration,
          body as PutIntegrationRequest,
        );
      } else {
        typedResponse = yield call(
          apiService.createCustomIntegration,
          body as PostIntegrationRequest,
        );
      }

      yield put(actions.saveIntegration.success({
        close: payload.close,
        data: parseIncomingIntegration(typedResponse.data),
      }));

      NotificationManager.info(i18n.t('COMMON.SAVED'));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.SAVE");
      yield put(actions.saveIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteCustomIntegration(): SagaIterator {
  yield takeLatest(actions.deleteIntegration.request, function* (action) {
    try {
      yield call(apiService.deleteCustomIntegration, action.payload);

      yield put(actions.deleteIntegration.success(action.payload));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.DELETE");
      yield put(actions.deleteIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadCustomIntegrationActions(): SagaIterator {
  yield takeLatest(actions.loadActions.request, function* (action) {
    try {
      const params: GetActionsRequest = action.payload;

      type R = ApiResponse<GetActionsResponse>;
      const response: R = yield call(apiService.getCustomIntegrationActions, params);
      const parsed = response.data.actions.map(parseIncomingIntegrationAction);

      yield put(actions.loadActions.success(parsed));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.ACTION.LOAD");
      yield put(actions.loadActions.failure(new ErrorAction(err, text)));
    }
  });
}

function* editAction(): SagaIterator {
  yield takeLatest(actions.setActionToEdit, function* ({ payload }) {
    const actionId = payload?.id;
    if (!actionId) return;

    const integration: IncomingIntegration | null = yield select(getIntegrationToEdit);
    const integrationId = (integration || {}).id;

    if (!integrationId) return;

    yield put(actions.loadCallbacks.request({ actionId, integrationId }));
  });
}

function* saveCustomIntegrationAction(): SagaIterator {
  yield takeLatest(actions.saveAction.request, function* (action) {
    try {
      const { data, close, ...params } = action.payload;
      const body = parseIncomingIntegrationActionToBackend(data);
      let typedResponse: ApiResponse<PostIntegrationRequest | PutIntegrationRequest>;

      if (body.id) {
        typedResponse = yield call(
          apiService.updateCustomIntegrationAction,
          { params, body } as PutActionRequest,
        );
      } else {
        typedResponse = yield call(
          apiService.createCustomIntegrationAction,
          { params, body } as PostActionRequest,
        );
      }

      const parsed = parseIncomingIntegrationAction(typedResponse.data);
      yield put(actions.saveAction.success({ data: parsed, close }));
      yield put(actions.loadActions.request({ integrationId: params.integrationId }));

      NotificationManager.info(i18n.t('COMMON.SAVED'));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.ACTION.SAVE");
      yield put(actions.saveAction.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteCustomIntegrationAction(): SagaIterator {
  yield takeLatest(actions.deleteAction.request, function* (action) {
    try {
      yield call(
        apiService.deleteCustomIntegrationAction,
        action.payload,
      );

      yield put(actions.deleteAction.success(action.payload.id));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.ACTION.DELETE");
      yield put(actions.deleteAction.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadCustomIntegrationCallbacks(): SagaIterator {
  yield takeLatest(actions.loadCallbacks.request, function* (action) {
    try {
      const params: GetCallbacksRequest = action.payload;

      type R = ApiResponse<GetCallbacksResponse>;
      const response: R = yield call(apiService.getCustomIntegrationCallbacks, params);
      const parsed = response.data.callbacks.map(parseIncomingIntegrationCallback);

      yield put(actions.loadCallbacks.success(parsed));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.CALLBACK.LOAD");
      yield put(actions.loadCallbacks.failure(new ErrorAction(err, text)));
    }
  });
}

function* saveCustomIntegrationCallback(): SagaIterator {
  yield takeLatest(actions.saveCallback.request, function* (action) {
    try {
      const { data, ...params } = action.payload;
      const body = parseIncomingIntegrationCallbackToBackend(data);

      if (body.id) {
        yield call(
          apiService.updateCustomIntegrationCallback,
          { params, body } as PutCallbackRequest,
        );
      } else {
        yield call(
          apiService.createCustomIntegrationCallback,
          { params, body } as PostCallbackRequest,
        );
      }

      yield put(actions.saveCallback.success());
      yield put(actions.loadCallbacks.request(params));

      NotificationManager.info(i18n.t('COMMON.SAVED'));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.ACTION.SAVE");
      yield put(actions.saveAction.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteCustomIntegrationCallback(): SagaIterator {
  yield takeLatest(actions.deleteCallback.request, function* (action) {
    try {
      yield call(
        apiService.deleteCustomIntegrationCallback,
        action.payload,
      );

      yield put(actions.deleteCallback.success(action.payload.id));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.ACTION.DELETE");
      yield put(actions.deleteCallback.failure(new ErrorAction(err, text)));
    }
  });
}

function* getProjectsLinkedToIntegration(): SagaIterator {
  yield takeLatest(actions.getLinkedProjects.request, function* ({ payload: integrationId }) {
    try {
      type R = ApiResponse<GetLinkedProjectsResponse>;
      const { data }: R = yield call(
        apiService.getProjectsLinkedToIntegration,
        integrationId,
      );

      const parsed: ProjectIntegration[] = data.integrations.map(
        item => ({
          ...objSnakeToCamelDeep<ProjectIntegration>(item),
          id: generateId(),
        }),
      );

      yield put(actions.getLinkedProjects.success({
        integrationId,
        data: parsed,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.PROJECTS.LOAD");
      yield put(actions.getLinkedProjects.failure(new ErrorAction(err, text)));
    }
  });
}

function* linkIntegrationAndProject(): SagaIterator {
  yield takeLatest(actions.linkIntegrationAndProject.request, function* (action) {
    try {
      const data: LinkIntegrationToProjectsRequest = objCamelToSnakeDeep(action.payload);

      yield call(
        apiService.linkIntegrationAndProject,
        data,
      );

      const { integrationId } = action.payload;

      yield put(actions.linkIntegrationAndProject.success(action.payload));
      yield put(actions.getLinkedProjects.request(integrationId));
      yield put(actions.loadIntegrations.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.SAVE");
      yield put(actions.linkIntegrationAndProject.failure(new ErrorAction(err, text)));
    }
  });
}

function* unlinkIntegrationAndProject(): SagaIterator {
  yield takeLatest(actions.unlinkIntegrationFromProject.request, function* (action) {
    try {
      yield call(
        apiService.unlinkIntegrationFromProject,
        action.payload,
      );

      const { integrationId } = action.payload;

      yield put(actions.getLinkedProjects.request(integrationId));
      yield put(actions.loadIntegrations.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.CUSTOM_INTEGRATIONS.SAVE");
      yield put(actions.linkIntegrationAndProject.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* incomingIntegrationSagas() {
  yield fork(loadCustomIntegrations);
  yield fork(editIntegration);
  yield fork(saveCustomIntegration);
  yield fork(deleteCustomIntegration);
  yield fork(loadCustomIntegrationActions);
  yield fork(editAction);
  yield fork(saveCustomIntegrationAction);
  yield fork(deleteCustomIntegrationAction);
  yield fork(loadCustomIntegrationCallbacks);
  yield fork(saveCustomIntegrationCallback);
  yield fork(deleteCustomIntegrationCallback);
  yield fork(getProjectsLinkedToIntegration);
  yield fork(linkIntegrationAndProject);
  yield fork(unlinkIntegrationAndProject);
}
