import { createAction, createAsyncAction } from "typesafe-actions";
import { AuthProvider } from "../../types/models/AuthProvider";
import { ErrorAction } from "../reducers";

export const loadProviders = createAsyncAction(
  'providers/loadProviders/requested',
  'providers/loadProviders/succeeded',
  'providers/loadProviders/failed',
)<undefined, AuthProvider[], ErrorAction>();

export const createProvider = createAsyncAction(
  'providers/createProvider/requested',
  'providers/createProvider/succeeded',
  'providers/createProvider/failed',
)<AuthProvider, AuthProvider, ErrorAction>();

export const updateProvider = createAsyncAction(
  'providers/updateProvider/requested',
  'providers/updateProvider/succeeded',
  'providers/updateProvider/failed',
)<AuthProvider, AuthProvider, ErrorAction>();

export const deleteProvider = createAsyncAction(
  'providers/deleteProvider/requested',
  'providers/deleteProvider/succeeded',
  'providers/deleteProvider/failed',
)<AuthProvider['name'], AuthProvider['name'], ErrorAction>();

export const setProviderToEdit = createAction(
  'providers/setProviderToEdit',
)<AuthProvider['name'] | null>();

export const closeEditor = createAction(
  'providers/closeEditor',
)<undefined>();
