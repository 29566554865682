import { SagaIterator } from "redux-saga";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import i18n from '../../services/i18n';
import apiService, { ApiResponse } from "../../services/api";
import NotificationService from "../../services/notifications";

import { Project } from "../../types/models/Project";
import { parseProjectVoice, parseProjectVoiceToBackend } from '../../types/parsers/ProjectVoiceParser';
import {
  GetProjectVoiceConfigRequest,
  GetProjectVoiceConfigResponse,
  UpdateProjectVoiceConfigRequest,
  UpdateProjectVoiceConfigResponse,
} from "../../types/requests/ProjectVoice";
import { getSelectedProject } from "../projects/selectors";

import * as actions from './actions';
import { ErrorAction } from "../reducers";
import { getEmptyProjectVoiceConfig, ProjectVoice } from "../../types/models/ProjectVoice";
import { getVoiceConfig } from "./selectors";

function* loadProjectVoiceConfig(): SagaIterator {
  yield takeLatest(actions.loadProjectVoiceConfig.request, function* () {
    try {
      const selectedProject: Project = yield select(getSelectedProject);
      const request: GetProjectVoiceConfigRequest = {
        path: {
          project_slug: selectedProject.slug,
        },
      };

      const response: ApiResponse<GetProjectVoiceConfigResponse> = yield call(
        apiService.getProjectVoiceConfig,
        request,
      );

      if (response.data) {
        yield put(actions.loadProjectVoiceConfig.success(parseProjectVoice(response.data)));
      } else {
        yield put(actions.loadProjectVoiceConfig.success(getEmptyProjectVoiceConfig()));
      }
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.PROJECTS.VOICE_CONFIG") });
      yield put(actions.loadProjectVoiceConfig.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateProjectVoiceConfig() {
  yield takeLatest(actions.updateProjectVoiceConfig.request, function* (action) {
    try {
      const config = action.payload;
      const selectedProject: Project = yield select(getSelectedProject);
      const currentConfig: ProjectVoice = yield select(getVoiceConfig);

      const isNew = currentConfig.unsaved;

      if (isNew) {
        const request: UpdateProjectVoiceConfigRequest = {
          path: {
            project_slug: selectedProject.slug,
          },
          body: parseProjectVoiceToBackend(config),
        };

        const response: ApiResponse<UpdateProjectVoiceConfigResponse> = yield call(
          apiService.updateProjectVoiceConfig,
          request,
        );

        yield put(actions.updateProjectVoiceConfig.success(parseProjectVoice(response.data)));
      } else {
        const telephonyTypeDifferent = (
          currentConfig.dispatcherParams.callService !== config.dispatcherParams.callService
        );

        if (telephonyTypeDifferent) {
          yield call(apiService.switchProjectVoiceConfig, {
            path: {
              project_slug: selectedProject.slug,
            },
            body: {
              call_service: currentConfig.dispatcherParams.callService,
            },
          });
        }

        const request: UpdateProjectVoiceConfigRequest = {
          path: {
            project_slug: selectedProject.slug,
          },
          body: parseProjectVoiceToBackend(config),
        };

        const response: ApiResponse<UpdateProjectVoiceConfigResponse> = yield call(
          apiService.updateProjectVoiceConfig,
          request,
        );

        yield put(actions.updateProjectVoiceConfig.success(parseProjectVoice(response.data)));
        yield put(actions.closeProjectVoiceModal());
        NotificationService.success(i18n.t("COMMON.SAVED"));
      }
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.PROJECTS.PROJECT_PHONES") });
      yield put(actions.updateProjectVoiceConfig.failure(new ErrorAction(err, text)));
    }
  });
}

function* openProjectVoiceModal() {
  yield takeLatest(actions.openProjectVoiceModal, function* () {
    yield put(actions.loadProjectVoiceConfig.request());
  });
}

export default function* projectVoiceConfigSagas() {
  yield fork(loadProjectVoiceConfig);
  yield fork(openProjectVoiceModal);
  yield fork(updateProjectVoiceConfig);
}
