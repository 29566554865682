import { SagaIterator } from "redux-saga";
import { fork, put, select, takeLatest } from "redux-saga/effects";

import { generateAnalyticsData, generateIterations } from "../../__mocks__/riseOfAutomation/utils.mock";

import i18n from "../../services/i18n";

import { ErrorAction } from "../reducers";
import * as actions from "./actions";
import { VersionsState } from '../versions/reducer';
import { getVersions } from "../versions/selectors";

import { IterationAnalyticsDataForProject, IterationDialog } from "../../types/models/IterationAnalyticsData";
import { Dialog } from "../../types/models/Dialog";

import { getScenarioGraphs } from "../scenarioGraphs/selectors";
import { ScenarioGraphsState } from "../scenarioGraphs/reducer";
import { getDialogs } from "../dialogs/selectors";

function* loadIterationAnalyticsData(): SagaIterator {
  yield takeLatest(actions.loadIterationAnalyticsData.request, function* () {
    try {
      const v: VersionsState['list'] = yield select(getVersions);
      const scenarios: ScenarioGraphsState['list'] = yield select(getScenarioGraphs);

      const data: IterationAnalyticsDataForProject = generateAnalyticsData(v.value[0].id, v.value[1].id, scenarios);

      yield put(actions.loadIterationAnalyticsData.success(data));
    } catch (err) {
      const text = i18n.t("ERRORS.COMMON.ERROR");
      yield put(actions.loadIterationAnalyticsData.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadNotAutomatedDialogs(): SagaIterator {
  yield takeLatest(actions.loadNotAutomatedDialogs.request, function* (action) {
    try {
      const dialogs: Dialog[] = yield select(getDialogs);

      const data: IterationDialog[] = generateIterations(dialogs).map(dialog => ({
        id: '',
        externalId: '',
        createdAt: '',
        Dialog: [],
        messages: [],
        sessionId: '',
        ...dialogs.find(d => d.id === dialog.chatId),
        ...dialog,
      }));

      yield put(actions.loadNotAutomatedDialogs.success({ dialogs: data, offset: action.payload.offset }));
    } catch (err) {
      const text = i18n.t("ERRORS.COMMON.ERROR");
      yield put(actions.loadNotAutomatedDialogs.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* iterationAnalyticSagas(): SagaIterator {
  yield fork(loadIterationAnalyticsData);
  yield fork(loadNotAutomatedDialogs);
}
