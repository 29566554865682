import { LOCAL_ENTITY_ID_PREFIX } from "../../redux/entities/reducer";
import { generateId } from "../../services/helpers/generateId";
import { FeatureType } from "./Feature";
import { Editable } from "./Editable";

export type Entity = {
  id: string,
  slug: string,
  title: string,
  type: FeatureType,
  extractor: EntityExtractor
} & Editable;

export type EntityExtractor = RegularExpressionExtractor | ChoiceFromVariantsExtractor | CustomExtractor

export type RegularExpressionExtractor = {
  type: ExtractorType.regularExpression,
  regularExpression: string, // minLength = 1,
}

export type ChoiceFromVariantsExtractor = {
  type: ExtractorType.choiceFromVariants,
  variants: EntityChoiceVariant[],
}

export type EntityChoiceVariant = {
  id: string,
  regularExpression: string, // minLength = 1,
  value: string, // minLength = 1,
}

export type CustomExtractor = {
  type: ExtractorType.custom,
  extractorType: string,
}

export type EntityCustomExtractor = string;

export enum ExtractorType {
  custom = 'custom',
  regularExpression = 'regular_expression',
  choiceFromVariants = 'choice_from_variants',
}

export function getEmptyEntity(): Entity {
  return {
    id: LOCAL_ENTITY_ID_PREFIX + generateId(),
    slug: '',
    title: '',
    type: FeatureType.str,
    extractor: getEmptyRegularExpressionExtractor(),
    pending: false,
  };
}

export function getEmptyRegularExpressionExtractor(): RegularExpressionExtractor {
  return {
    type: ExtractorType.regularExpression,
    regularExpression: '',
  };
}

export function getEmptyChoiceVariant(): EntityChoiceVariant {
  return {
    id: generateId(),
    regularExpression: '',
    value: '',
  };
}
