import { SagaIterator } from "redux-saga";
import { call, fork, put, takeLatest } from "redux-saga/effects";

import apiService, { ApiResponse } from "../../services/api";
import i18n from '../../services/i18n';
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";

import { ErrorAction } from "../reducers";

import {
  CreateAllowedDomainRequest,
  CreateAllowedDomainResponse,
  DeleteAllowedDomainRequest,
  GetAllowedDomainsResponse,
  UpdateAllowedDomainRequest,
  UpdateAllowedDomainResponse,
} from "../../types/requests/Domains";
import { AllowedDomain } from "../../types/models/Domains";

import * as actions from './actions';

function* loadAllowedDomains(): SagaIterator {
  yield takeLatest(actions.loadAllowedDomains.request, function* () {
    try {
      const response: ApiResponse<GetAllowedDomainsResponse> = yield call(apiService.loadAllowedDomains);

      yield put(actions.loadAllowedDomains.success(response.data.domains.map<AllowedDomain>(objSnakeToCamelDeep)));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.DOMAINS.MULTIPLE") });
      yield put(actions.loadAllowedDomains.failure(new ErrorAction(err, text)));
    }
  });
}

function* createAllowedDomain(): SagaIterator {
  yield takeLatest(actions.createAllowedDomain.request, function* (action) {
    try {
      const domain = action.payload;

      const request: CreateAllowedDomainRequest = {
        body: objCamelToSnakeDeep(domain),
      };

      const response: ApiResponse<CreateAllowedDomainResponse> = yield call(
        apiService.createAllowedDomain,
        request,
      );

      yield put(actions.createAllowedDomain.success(objSnakeToCamelDeep(response.data)));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.DOMAINS.SINGLE") });
      yield put(actions.createAllowedDomain.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateAllowedDomain(): SagaIterator {
  yield takeLatest(actions.updateAllowedDomain.request, function* (action) {
    try {
      const domain = action.payload;

      if (!domain.id) throw new Error("No id");

      const request: UpdateAllowedDomainRequest = {
        path: {
          domain_id: domain.id,
        },
        body: objCamelToSnakeDeep(domain),
      };

      const response: ApiResponse<UpdateAllowedDomainResponse> = yield call(
        apiService.updateAllowedDomain,
        request,
      );

      yield put(actions.updateAllowedDomain.success(objSnakeToCamelDeep(response.data)));
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.DOMAINS.SINGLE") });
      yield put(actions.updateAllowedDomain.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteAllowedDomains(): SagaIterator {
  yield takeLatest(actions.deleteAllowedDomain.request, function* (action) {
    try {
      const { payload: domainId } = action;

      if (!domainId) throw new Error("No id");

      const request: DeleteAllowedDomainRequest = {
        path: {
          domain_id: domainId,
        },
      };

      yield call(
        apiService.deleteAllowedDomain,
        request,
      );

      yield put(actions.deleteAllowedDomain.success(domainId));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.DOMAINS.SINGLE") });
      yield put(actions.deleteAllowedDomain.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* domainsSagas(): SagaIterator {
  yield fork(loadAllowedDomains);
  yield fork(createAllowedDomain);
  yield fork(updateAllowedDomain);
  yield fork(deleteAllowedDomains);
}
