import { ScenarioGraphsList } from "../../redux/scenarioGraphs/types";
import { getRandomElementOfArray, range } from "../../services/helpers/utilities";
import { Dialog } from "../../types/models/Dialog";
import { IterationAnalyticsDataForProject, Iterations } from "../../types/models/IterationAnalyticsData";

export function generateAnalyticsData(v1: string, v2: string, scenarios: ScenarioGraphsList)
  :IterationAnalyticsDataForProject {
  return {
    grouping: {
      periods: [
        {
          periodBegin: '08-01-2024',
          versionId: v1,
          automatedCount: 94,
          notAutomatedCount: 93,
          totalCount: 187,
        },
        {
          periodBegin: '08-02-2024',
          versionId: v1,
          automatedCount: 72,
          notAutomatedCount: 49,
          totalCount: 121,
        },
        {
          periodBegin: '08-03-2024',
          versionId: v1,
          automatedCount: 14,
          notAutomatedCount: 7,
          totalCount: 21,
        },
        {
          periodBegin: '08-04-2024',
          versionId: v1,
          automatedCount: 70,
          notAutomatedCount: 38,
          totalCount: 108,
        },
        {
          periodBegin: '08-05-2024',
          versionId: v1,
          automatedCount: 75,
          notAutomatedCount: 45,
          totalCount: 120,
        },
        {
          periodBegin: '08-06-2024',
          versionId: v1,
          automatedCount: 84,
          notAutomatedCount: 57,
          totalCount: 141,
        },
        {
          periodBegin: '08-07-2024',
          versionId: v1,
          automatedCount: 100,
          notAutomatedCount: 67,
          totalCount: 167,
        },
        {
          periodBegin: '08-08-2024',
          versionId: v1,
          automatedCount: 65,
          notAutomatedCount: 70,
          totalCount: 135,
        },
        {
          periodBegin: '08-09-2024',
          versionId: v1,
          automatedCount: 44,
          notAutomatedCount: 53,
          totalCount: 97,
        },
        {
          periodBegin: '08-10-2024',
          versionId: v1,
          automatedCount: 56,
          notAutomatedCount: 83,
          totalCount: 139,
        },
        {
          periodBegin: '08-11-2024',
          versionId: v1,
          automatedCount: 66,
          notAutomatedCount: 80,
          totalCount: 146,
        },
        {
          periodBegin: '08-12-2024',
          versionId: v1,
          automatedCount: 22,
          notAutomatedCount: 24,
          totalCount: 46,
        },
        {
          periodBegin: '08-13-2024',
          versionId: v1,
          automatedCount: 22,
          notAutomatedCount: 63,
          totalCount: 85,
        },
        {
          periodBegin: '08-12-2024',
          versionId: v2,
          automatedCount: 22,
          notAutomatedCount: 24,
          totalCount: 46,
        },
        {
          periodBegin: '08-13-2024',
          versionId: v2,
          automatedCount: 34,
          notAutomatedCount: 50,
          totalCount: 84,
        },
        {
          periodBegin: '08-14-2024',
          versionId: v2,
          automatedCount: 40,
          notAutomatedCount: 73,
          totalCount: 113,
        },
        {
          periodBegin: '08-15-2024',
          versionId: v2,
          automatedCount: 60,
          notAutomatedCount: 91,
          totalCount: 151,
        },
        {
          periodBegin: '08-16-2024',
          versionId: v2,
          automatedCount: 59,
          notAutomatedCount: 87,
          totalCount: 146,
        },
        {
          periodBegin: '08-17-2024',
          versionId: v2,
          automatedCount: 58,
          notAutomatedCount: 95,
          totalCount: 152,
        },
        {
          periodBegin: '08-18-2024',
          versionId: v2,
          automatedCount: 41,
          notAutomatedCount: 76,
          totalCount: 118,
        },
        {
          periodBegin: '08-19-2024',
          versionId: v2,
          automatedCount: 66,
          notAutomatedCount: 99,
          totalCount: 165,
        },
        {
          periodBegin: '08-20-2024',
          versionId: v2,
          automatedCount: 68,
          notAutomatedCount: 84,
          totalCount: 152,
        },
        {
          periodBegin: '08-21-2024',
          versionId: v2,
          automatedCount: 27,
          notAutomatedCount: 27,
          totalCount: 54,
        },
        {
          periodBegin: '08-22-2024',
          versionId: v2,
          automatedCount: 76,
          notAutomatedCount: 63,
          totalCount: 139,
        },
        {
          periodBegin: '08-23-2024',
          versionId: v2,
          automatedCount: 12,
          notAutomatedCount: 8,
          totalCount: 20,
        },
        {
          periodBegin: '08-24-2024',
          versionId: v2,
          automatedCount: 15,
          notAutomatedCount: 7,
          totalCount: 22,
        },
      ],
      scenarios: scenarios.map((sc, i) => ({
        scenarioSlug: sc.slug,
        notAutomatedCount: i * 2,
        totalCount: i * 5,
        automatedCount: i * 3,
      })),
    },
    automatedCount: 5,
    notAutomatedCount: 5,
    totalCount: 10,
  };
}

export function generateIterations(dialogs: Dialog[]): Iterations {
  return range(5).map(() => ({
    iterationNumber: 1,
    chatId: getRandomElementOfArray(dialogs).id,
  }));
}
