import { call, fork, put, takeLatest } from "redux-saga/effects";

import apiService, { ApiResponse } from "../../services/api";
import i18n from "../../services/i18n";
import {
  CreateProviderRequest,
  CreateProviderResponse,
  DeleteProviderRequest,
  GetProvidersResponse,
  UpdateProviderRequest,
  UpdateProviderResponse,
} from "../../types/requests/Authentication";
import { ErrorAction } from "../reducers";

import * as actions from './actions';

function* loadProviders() {
  yield takeLatest(actions.loadProviders.request, function* () {
    try {
      const response: ApiResponse<GetProvidersResponse> = yield call(apiService.getProviders);
      yield put(actions.loadProviders.success(response.data.providers));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS.MULTIPLE") });
      yield put(actions.loadProviders.failure(new ErrorAction(err, text)));
    }
  });
}

function* createProvider() {
  yield takeLatest(actions.createProvider.request, function* (action) {
    try {
      const request: CreateProviderRequest = {
        body: action.payload,
      };

      const response: ApiResponse<CreateProviderResponse> = yield call(apiService.createProvider, request);
      yield put(actions.createProvider.success(response.data));
      yield put(actions.closeEditor());
      yield put(actions.loadProviders.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS.SINGLE") });
      yield put(actions.createProvider.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateProvider() {
  yield takeLatest(actions.updateProvider.request, function* (action) {
    try {
      const request: UpdateProviderRequest = {
        path: {
          name: action.payload.name,
        },
        body: action.payload,
      };

      const response: ApiResponse<UpdateProviderResponse> = yield call(apiService.updateProvider, request);
      yield put(actions.updateProvider.success(response.data));
      yield put(actions.closeEditor());
      yield put(actions.loadProviders.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS.SINGLE") });
      yield put(actions.updateProvider.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteProvider() {
  yield takeLatest(actions.deleteProvider.request, function* (action) {
    try {
      const providerName = action.payload;

      const request: DeleteProviderRequest = {
        path: {
          name: providerName,
        },
      };

      yield call(apiService.deleteProvider, request);
      yield put(actions.deleteProvider.success(providerName));
      yield put(actions.closeEditor());
      yield put(actions.loadProviders.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS.SINGLE") });
      yield put(actions.deleteProvider.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* providersSagas() {
  yield fork(loadProviders);
  yield fork(createProvider);
  yield fork(updateProvider);
  yield fork(deleteProvider);
}
