import { generateId } from "../../services/helpers/generateId";

export type IpAddress = {
  id: string;
  value: string;
}

export type AuthData = {
  apiKey: string;
  ipAddresses: IpAddress[]
}

export const createIpAddress = (value = ''): IpAddress => ({
  id: generateId(),
  value,
});
