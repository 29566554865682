import { memo } from "react";
import { EnumType } from "typescript";

import NotificationManager from "../notifications";

import i18n from '../i18n';

import { Metric } from "../../types/models/Metric";
import { MetricModel } from "../../types/models/Threshold";
import { Feature } from "../../types/models/Feature";
import { CustomExtractor } from "../../types/models/Entity";

export function stringToBoolean(value: string): boolean {
  return [true, 'true', 'True', 'TRUE'].includes(value);
}

export function getEnumLength(value: EnumType): number {
  let count = 0;
  for (const item in value) {
    if (isNaN(Number(item))) count++;
  }
  return count;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objSnakeToCamel<T>(obj: any, deep?: boolean): T {
  if (typeof obj !== 'object') return obj;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {};

  Object.keys(obj).forEach(prop => {
    const camelProp = snakeToCamel(prop);
    const value = obj[prop];

    if (!!value && typeof value === 'object' && deep) {
      if (Array.isArray(value)) {
        newObj[camelProp] = value.map(item => ((typeof item === "object" && item !== null) ?
          objSnakeToCamel(item, true) :
          item));
      } else {
        newObj[camelProp] = objSnakeToCamel(value, true);
      }
    } else {
      newObj[camelProp] = value;
    }
  });

  return newObj as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objSnakeToCamelDeep<T>(obj: any): T {
  return objSnakeToCamel<T>(obj, true);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objCamelToSnake<T>(obj: any, deep?: boolean): T {
  if (typeof obj !== 'object') return obj;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {};

  Object.keys(obj).forEach(prop => {
    const snakeProp = camelToSnake(prop);
    const value = obj[prop];

    if (!!value && typeof value === 'object' && deep) {
      if (Array.isArray(value)) {
        newObj[snakeProp] = value.map(item => ((typeof item === "object" && item !== null) ?
          objCamelToSnake(item, true) :
          item));
      } else {
        newObj[snakeProp] = objCamelToSnake(value, true);
      }
    } else {
      newObj[snakeProp] = value;
    }
  });

  return newObj as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objCamelToSnakeDeep<T>(obj: any): T {
  return objCamelToSnake<T>(obj, true);
}

export function capitalize(str: string = ""): string {
  return `${(str[0] || "").toUpperCase()}${str.slice(1)}`;
}

export function snakeToCamel(value: string): string {
  return value
    .toLowerCase()
    .replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));
}

export function camelToSnake(value: string): string {
  return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeNullFields(obj: any): any {
  if (typeof obj === 'object') {
    const copy = { ...obj };

    for (const prop in copy) {
      if (copy[prop] === null || copy[prop] === undefined) {
        delete copy[prop];
      }
    }

    return copy;
  }
  return obj;
}

export function removeEmptyFields(obj: Object): Object {
  const copy = deepClone(obj);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(copy).forEach((property: any) => {
    // @ts-ignore
    const value = obj[property];
    if (typeof value !== "string") return;

    if (!value.trim() || !property.trim()) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      delete copy[property];
    }
  });

  return copy;
}

export function copyToClipboardFallback(value: string) {
  const textarea = document.createElement('textarea');
  textarea.value = value;
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

export function copyToClipboard(text: string, skipNotification = false) {
  if (!navigator.clipboard) {
    copyToClipboardFallback(text);
  } else {
    navigator.clipboard.writeText(text);
  }

  if (!skipNotification) {
    NotificationManager.info(i18n.t('COMMON.COPIED'));
  }
}

export function findThresholdIndex(value: number, array: number[]): number | undefined {
  let minDiff = Infinity;
  let resultIndex = 0;

  for (let i = 0; i < array.length; i++) {
    const currentDiff = value - array[i];
    if (currentDiff >= 0 && currentDiff < minDiff) {
      minDiff = currentDiff;
      resultIndex = i;
    }
  }
  return resultIndex;
}

export function findMetricByKey(metrics: MetricModel[], key: Metric): MetricModel | undefined {
  return metrics.find(metric => metric.key === key);
}

export function onlyUnique(value: unknown, index: number, self: unknown[]) {
  return self.indexOf(value) === index;
}

export function download(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function wrapErrorMessage(error: unknown, message: string) {
  return `${message}: ${error}`;
}

export function unique<T = unknown>(list: T[], uniqueBy: (item: T) => string): T[] {
  const addedElements: string[] = [];

  return list.filter(element => {
    const property = uniqueBy(element);
    if (!addedElements.includes(property)) {
      addedElements.push(property);
      return true;
    }

    return false;
  });
}

export const toggleBugTracker = (show: boolean): void => {
  const bugButton: HTMLDivElement | null = document.querySelector('.YndxBug');
  if (!bugButton) return;

  bugButton.style.display = show ? 'block' : 'none';
};

export function renameFile(file: File, newName: string): File {
  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export function getMediaErrorMessage(error: MediaError) {
  switch (error.code) {
    case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
      return i18n.t("ERRORS.MEDIA.MEDIA_ERR_SRC_NOT_SUPPORTED");
    case MediaError.MEDIA_ERR_NETWORK:
      return i18n.t("ERRORS.MEDIA.MEDIA_ERR_NETWORK");
    case MediaError.MEDIA_ERR_ABORTED:
      return i18n.t("ERROR.MEDIA.MEDIA_ERR_ABORTED");
    case MediaError.MEDIA_ERR_DECODE:
      return i18n.t("ERRORS.MEDIA.MEDIA_ERR_DECODE");
    default:
      return i18n.t("ERRORS.MEDIA.UNKNOWN_ERROR");
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readObjProperty(obj: any, property: string): any {
  if (typeof obj === 'undefined') {
    return undefined;
  }

  const pointerIndex = property.indexOf('.');
  if (pointerIndex > -1) {
    const innerObjectKey = property.substring(0, pointerIndex);
    const innerObject: Object = obj[innerObjectKey];

    if (typeof innerObject !== 'object') {
      return undefined;
    }

    return readObjProperty(innerObject, property.substring(pointerIndex + 1));
  }

  return obj[property];
}

export function extractValidFilenameSymbols(value: string) {
  return value.replace(/[^A-Z0-9]/gi, '');
}

export function getFileExtension(filename: string) {
  return filename.split('.').pop() || '';
}

export function getFilenameWithoutExtension(filename: string) {
  return filename.split('.').shift() || '';
}

export const cutPhrase = (str: string, length?: number) => (length && str.length < length ? str : `${str.slice(0, length)}...`);

export const trimPhrase = (str: string, length = 20, first = 10, last = 7) => (
  str.length > length ? `${str.slice(0, first)}...${str.slice(-last)}` : str
);

export function debounce(f: Function, ms: number) {
  let isCooldown = false;

  return function() {
    if (isCooldown) return;

    // @ts-ignore
    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => isCooldown = false, ms);
  };
}

export const getValueWithinLimits = (min: number, value: number, max: number): number => (
  Math.max(min, Math.min(value, max))
);

export function roundTo(value: number, digits: number) {
  return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
}

export const downloadData = (data: unknown, name: string, type = 'application/json') => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(
    new Blob(
      [JSON.stringify(data, null, 2)],
      { type },
    ),
  );
  a.setAttribute("download", name);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadBlobData = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = () => {
        let result = reader.result as string;
        const cutFlag = ';base64,';
        const cutIndex = result.indexOf(cutFlag);

        result = result.slice(cutIndex + cutFlag.length);

        resolve(result);
      };

      reader.onerror = () => {
        reject();
      };

      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}

export const downloadDOMString = (link: string, filename: string) => {
  const elem = document.createElement('a');
  elem.href = link;
  elem.style.display = "none";
  elem.download = filename;
  elem.click();
  window.URL.revokeObjectURL(link);
};

export const readJsonFile = (file: File) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = event => {
      const data = event.target?.result;

      if (!(data && typeof data === 'string')) {
        reject();
      } else {
        resolve(data);
      }
    };

    reader.readAsText(file);
  });
};

export const validateJson = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJsonSafely = (data: any): Record<string, any> => {
  if (typeof data !== 'string') return data;

  try {
    return JSON.parse(data);
  } catch {
    return {};
  }
};

export const isMacOs = () => navigator.userAgent.includes('Mac OS');
export const cmdOrCtrl = () => isMacOs() ? 'cmd' : 'ctrl';

export const checkCtrlOrCmdInEvent = (event: KeyboardEvent) => {
  const { metaKey = false, ctrlKey = false } = event;

  return isMacOs() ? metaKey : ctrlKey;
};

export const checkShiftPressed = (event: MouseEvent) => {
  return event.shiftKey;
};

export const getBrowserScale = () => {
  // eslint-disable-next-line no-restricted-globals
  const scale = Math.round((screen.availWidth / document.documentElement.clientWidth) * 100) / 100;

  if (Math.abs(1 - scale) < 0.03) return 1;

  return scale;
};

export type ReadablePart = {
  title: string;
  subtitle?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withCacheDecorator<T>(f: Function): (args: any) => T {
  const cache = new Map();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(args: any) {
    if (!cache.has(args)) {
      // @ts-ignore
      cache.set(args, f.call(this, args));
    }
    return cache.get(args);
  };
}

export const getExtractorReadablePart = withCacheDecorator<ReadablePart>((slug: CustomExtractor['extractorType']): ReadablePart => {
  if (i18n.exists(`EXTRACTOR_LIST.${slug.toUpperCase()}`)) {
    return {
      title: i18n.t(`EXTRACTOR_LIST.${slug.toUpperCase()}`),
      subtitle: slug,
    };
  }
  return {
    title: slug,
  };
});

export const getFeatureReadablePart = withCacheDecorator<ReadablePart>((slug: Feature['slug']): ReadablePart => {
  if (i18n.exists(`FEATURES_LIST.${slug.toUpperCase()}`)) {
    return {
      title: i18n.t(`FEATURES_LIST.${slug.toUpperCase()}`),
      subtitle: slug,
    };
  }
  return {
    title: slug,
  };
});

export const parseSecondsToFormattedString = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = (seconds % 3600) % 60;

  const getPadded = (n: number) => String(n).padStart(2, '0');

  return `${h}:${getPadded(m)}:${getPadded(s)}`;
};

// стандартный memo пока не может в дженерики
export const typedMemo: <T>(c: T) => T = memo;

export const getPercents = (total: number, part: number) => {
  if (total === 0) return 0;

  return Math.round((part / total) * 1000) / 10;
};

export function divideBy<T = unknown>(
  arr: Array<T>,
  filter: (a: T) => boolean,
): [T[], T[]] {
  return arr.reduce<[T[], T[]]>((result, elem) => {
    if (filter(elem)) {
      result[0].push(elem);
    } else {
      result[1].push(elem);
    }
    return result;
  }, [[], []]);
}

export function throttle(func: Function, ms: number): Function {
  let isThrottled = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let savedArgs: any;
  let savedContext: ThisType<Function> | undefined;

  function wrapper() {
    if (isThrottled) {
      // eslint-disable-next-line prefer-rest-params
      savedArgs = arguments;
      // @ts-ignore ignore "this"
      savedContext = this;
      return;
    }

    // @ts-ignore ignore "this"
    // eslint-disable-next-line prefer-rest-params
    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedContext, savedArgs);
        savedArgs = undefined;
        savedContext = undefined;
      }
    }, ms);
  }

  return wrapper;
}

export type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

export type Either<T, U> = Only<T, U> | Only<U, T>;

export const defaultSort = (a: number, b: number) => a - b;

export const deepClone = <T>(data: NonNullable<T>):T => {
  try {
    return JSON.parse(JSON.stringify(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`An error has occured while parsing "${JSON.stringify(data)}"`);
    return data;
  }
};

export const trim = (value: string, symbols: string): string => {
  const regRule = new RegExp(`^[${symbols}]+|[${symbols}]+$`, "g");
  return value.replace(regRule, "");
};

export const checkIfStringIncludes = (a: string, b: string): boolean => (
  (a || '').toLowerCase().includes((b || '').toLowerCase())
);

export const checkIfObjIncludes = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any> | string | number,
  str: string,
  excludeKeys?: string[],
): boolean => {
  if (!data || !['string', 'object', 'number'].includes(typeof data)) return false;
  if (typeof data !== 'object') return String(data) === str;

  return Object.keys(data)
    .filter(k => !(excludeKeys || []).includes(k))
    .some(k => {
      const value: number | string | object = data[k];
      if (!value) return false;

      if (typeof value === 'object') {
        return checkIfObjIncludes(value, str, excludeKeys);
      }

      return checkIfStringIncludes(String(value), str);
    });
};

export const limitStringLength = (str: string, length: number) => (
  str.length > length ?
    `${str.slice(0, length - 1)}...` :
    str
);

export const isBox = () => process.env.REACT_APP_ENVIRONMENT_ID === 'box';

export const getRandomElementOfArray = <T>(arr: T[]): T => (
  Array.isArray(arr) ?
    arr[Math.floor(Math.random() * arr.length)] :
    arr
);

// Возвращает ключи из переведенной перевода
// Пример: для строки "Тексты: {{texts}}. Кнопки: {{buttons}}" вернет массив ["texts", "buttons"]
export const getTranslationParams = (translation: string) => {
  const keyReg = /{{[^{{]+}}/gi;
  return translation.match(keyReg)?.map(key => key.slice(2, -2));
};

export const checkIpAddress = (ipAddress: string) => (
  checkIpv4Address(ipAddress) || checkIpv6Address(ipAddress)
);

export const checkIpv4Address = (ipAddress: string) => (
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)
);

// Source: https://stackoverflow.com/a/69685444
export const checkIpv6Address = (ipAddress: string) => {
  const pattern = /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm;
  return pattern.test(ipAddress);
};

export function arrayBufferToDOMString(buffer: string) {
  const blob = new Blob([buffer]);
  const url = URL.createObjectURL(blob);

  return url;
}

export const getSelectedText = () => window.getSelection()?.toString() || '';

export function stopEvent(e: React.MouseEvent<unknown>) {
  e.preventDefault();
  e.stopPropagation();
}

export function preventDefaultEvent(e: React.MouseEvent | React.FocusEvent) {
  e.preventDefault();
}

export function transliterate(text: string) {
  text = text
    .replace(/\u0401/g, 'YO')
    .replace(/\u0419/g, 'I')
    .replace(/\u0426/g, 'TS')
    .replace(/\u0423/g, 'U')
    .replace(/\u041A/g, 'K')
    .replace(/\u0415/g, 'E')
    .replace(/\u041D/g, 'N')
    .replace(/\u0413/g, 'G')
    .replace(/\u0428/g, 'SH')
    .replace(/\u0429/g, 'SCH')
    .replace(/\u0417/g, 'Z')
    .replace(/\u0425/g, 'H')
    .replace(/\u042A/g, '')
    .replace(/\u0451/g, 'yo')
    .replace(/\u0439/g, 'i')
    .replace(/\u0446/g, 'ts')
    .replace(/\u0443/g, 'u')
    .replace(/\u043A/g, 'k')
    .replace(/\u0435/g, 'e')
    .replace(/\u043D/g, 'n')
    .replace(/\u0433/g, 'g')
    .replace(/\u0448/g, 'sh')
    .replace(/\u0449/g, 'sch')
    .replace(/\u0437/g, 'z')
    .replace(/\u0445/g, 'h')
    .replace(/\u044A/g, "'")
    .replace(/\u0424/g, 'F')
    .replace(/\u042B/g, 'I')
    .replace(/\u0412/g, 'V')
    .replace(/\u0410/g, 'a')
    .replace(/\u041F/g, 'P')
    .replace(/\u0420/g, 'R')
    .replace(/\u041E/g, 'O')
    .replace(/\u041B/g, 'L')
    .replace(/\u0414/g, 'D')
    .replace(/\u0416/g, 'ZH')
    .replace(/\u042D/g, 'E')
    .replace(/\u0444/g, 'f')
    .replace(/\u044B/g, 'i')
    .replace(/\u0432/g, 'v')
    .replace(/\u0430/g, 'a')
    .replace(/\u043F/g, 'p')
    .replace(/\u0440/g, 'r')
    .replace(/\u043E/g, 'o')
    .replace(/\u043B/g, 'l')
    .replace(/\u0434/g, 'd')
    .replace(/\u0436/g, 'zh')
    .replace(/\u044D/g, 'e')
    .replace(/\u042F/g, 'Ya')
    .replace(/\u0427/g, 'CH')
    .replace(/\u0421/g, 'S')
    .replace(/\u041C/g, 'M')
    .replace(/\u0418/g, 'I')
    .replace(/\u0422/g, 'T')
    .replace(/\u042C/g, "'")
    .replace(/\u0411/g, 'B')
    .replace(/\u042E/g, 'YU')
    .replace(/\u044F/g, 'ya')
    .replace(/\u0447/g, 'ch')
    .replace(/\u0441/g, 's')
    .replace(/\u043C/g, 'm')
    .replace(/\u0438/g, 'i')
    .replace(/\u0442/g, 't')
    .replace(/\u044C/g, "'")
    .replace(/\u0431/g, 'b')
    .replace(/\u044E/g, 'yu');

  return text;
}

export function isOnlyNumbers(value: string): boolean {
  const numbersReg = /^\d+$/gi;

  return numbersReg.test(value);
}

export const emptyHandler = () => {};

export const resetFocus = () => {
  if (!document.activeElement || !(document.activeElement as HTMLElement).blur) return;

  (document.activeElement as HTMLElement).blur();
};

export function getEnvBoolean(key: string) {
  const value = process.env[key];
  if (!value) return false;

  return stringToBoolean(value);
}

export function isScrollable(el: HTMLElement | null) {
  if (!el) return false;
  const { clientHeight, scrollHeight } = el;

  return scrollHeight > clientHeight;
}

export function sortStrings(a: string, b: string) {
  return a.toUpperCase() > b.toUpperCase() ? 1 : -1;
}

export function addOpacityToHex(color: string, opacity: number) {
  const correctOpacity = Math.min(Math.max(opacity ?? 1, 0), 1);
  const opacityValue = Math.round(correctOpacity * 255);
  return color + opacityValue.toString(16).toUpperCase();
}

export function hasWhiteSpace(value: string) {
  return /\s/g.test(value);
}

export const range = (
  start: number,
  end?: number,
  step: number = 1,
) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export function awayClickHandler(
  event: React.MouseEvent<Document, MouseEvent>,
  showConfirmationModal: (cb: () => void) => void,
) {
  const { target } = event;
  const htmlTarget = target as HTMLElement;

  if (htmlTarget.classList.contains('modal-overlay') ||
      htmlTarget.closest('#modal-container')
  ) {
    // Клик внутри модального окна, всё норм, продолжаем работать
    return;
  }

  // Мы хотим показывать предупреждение только при переходе на другие страницы (чтобы
  // изменения не потерялись). Например, нет смысла показывать предупреждение,
  // если пользователь просто сворачивает меню. Поэтому явно проверяем,
  // что нажатие было не на элемент бокового меню (ссылка или другой проект), то пропускаем
  const clickOnSidebarLink = !!htmlTarget.closest(".sidebar-item");
  const clickOnSidebarProjectSelector = !!htmlTarget.closest(".project-selector-dropdown") &&
    !htmlTarget.closest(".new-project-selector");

  if (clickOnSidebarLink || clickOnSidebarProjectSelector) {
    event.preventDefault();
    showConfirmationModal(() => {
      event.target.dispatchEvent(new CustomEvent('click', { bubbles: true }));
    });
  }
}
