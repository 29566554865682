import moment from "moment";
import { setDefaultOptions } from "date-fns";
import { ru, tr, enUS } from "date-fns/locale";
import i18next from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import "moment/locale/ru";
import "moment/locale/tr";

const STORAGE_KEY = 'lang';

function parseNavigatorLanguage(key: string) {
  return key.split('-')[0];
}

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'ru',
    react: {
      transSupportBasicHtmlNodes: false,
    },
  });

const defaultLanguage = parseNavigatorLanguage(Intl.DateTimeFormat().resolvedOptions().locale) || 'ru';
i18next.reloadResources(['ru', 'en', 'tr']);
i18next.changeLanguage(localStorage.getItem(STORAGE_KEY) || defaultLanguage);

switch (i18next.language) {
  case 'en':
    setDefaultOptions({
      locale: enUS,
    });
    break;
  case 'tr':
    setDefaultOptions({
      locale: tr,
    });
    break;
  default:
    setDefaultOptions({
      locale: ru,
    });
    break;
}

moment.locale(i18next.language);

export function changeLanguage(key: string) {
  localStorage.setItem('lang', key);
  i18next.changeLanguage(key);
  window.location.reload();
}

export default i18next;
