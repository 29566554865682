import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { block } from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from "file-saver";
import { format } from 'date-fns';

import { ScenarioGraphMeta } from '../../types/models/ScenarioGraphs';

import { ModalProps } from '../../services/modal';

import {
  getScenarioExportCount,
  getScenarioExportResulst,
  getScenarioExportStatus,
} from '../../redux/scenarioGraphs/selectors';
import {
  startExportingScenarios,
  stopExportingScenarios,
} from '../../redux/scenarioGraphs/actions';
import {
  getSelectedProject,
} from '../../redux/projects/selectors';

import Button from '../../components-new/button';
import Icon, { IconSize } from '../../components-new/icon';
import SuperCheck from '../../components-new/icon/images/superCheck';

import Modal from '../components/modal';
import ModalFooter from '../components/modalFooter';
import ModalBody from '../components/modalBody';
import ModalHeader from '../components/modalHeader';

import './styles.scss';

const b = block("export-scenarios-modal");

export type ExportScenariosModalProps = {
  scenarios: ScenarioGraphMeta[];
}

export default function ExportScenariosModal({
  scenarios,
  close,
}: ExportScenariosModalProps & ModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const project = useSelector(getSelectedProject);
  const processing = useSelector(getScenarioExportStatus);
  const completedCount = useSelector(getScenarioExportCount);
  const results = useSelector(getScenarioExportResulst);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const totalCount = scenarios.length;

  const done = Boolean(!processing && results.zipLink);

  const submitClick = useCallback(
    () => {
      if (done) {
        close();
      } else {
        dispatch(startExportingScenarios(scenarios));
      }
    },
    [
      close,
      dispatch,
      done,
      scenarios,
    ],
  );

  const submitText = useMemo(
    () => {
      if (done) {
        return t("COMMON.CLOSE");
      }

      if (!processing) {
        return t("MODALS.EXPORT_SCENARIOS.START_PROCESSING");
      }

      return t("COMMON.WAIT");
    },
    [done, processing, t],
  );

  const toggleShowSuccess = useCallback(
    () => {
      setShowSuccess(s => !s);
    },
    [],
  );

  const downloadZip = useCallback(
    () => {
      const today = new Date();
      FileSaver.saveAs(
        results.zipLink,
        `scenarios-${project.slug}-${format(today, "yyyy-MM-dd HH:mm")}.zip`,
      );
    },
    [project, results.zipLink],
  );

  useEffect(
    () => {
      return () => {
        dispatch(stopExportingScenarios());
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      className={b() + " nd-modal"}
    >
      <ModalHeader>
        {t("MODALS.EXPORT_SCENARIOS.TITLE")}
        <Button
          className="nd-modal__close-button"
          icon="close"
          simple
          onClick={close}
        />
      </ModalHeader>
      <ModalBody>
        {!done && !processing && (
          <div className="column-2">
            <span className="font-bold">
              {t("MODALS.EXPORT_SCENARIOS.START_PROCESSING_MSG", {
                project: project.title,
                scenariosCount: totalCount,
              })}
            </span>
            <ul className="column-2">
              {scenarios.map(s => (
                <li key={s.id}>{s.title}</li>
              ))}
            </ul>
          </div>
        )}
        {processing && (
          <div className={b("loading-wrapper")}>
            <Icon type="loaderBrackets" />
            <span className="text-secondary caption-1">{t("MODALS.EXPORT_SCENARIOS.PROCESSING_MSG")}</span>
            <span>
              {t("MODALS.EXPORT_SCENARIOS.PROGRESS", {
                count: completedCount,
                total: totalCount,
              })}
            </span>
          </div>
        )}
        {done && (
          <div className={b("done-wrapper")}>
            <SuperCheck />
            <span className="caption-1 text-secondary">{t("MODALS.EXPORT_SCENARIOS.SUCCESS_MSG")}</span>
            <Button
              className={b("download-button")}
              onClick={downloadZip}
            >
              {t("COMMON.DOWNLOAD")}
            </Button>
            <button
              className={b("success-button")}
              onClick={toggleShowSuccess}
            >
              <span>{t("MODALS.EXPORT_SCENARIOS.SUCCESS_LIST")}</span>
              <Icon iconSize={IconSize.medium} type={showSuccess ? "chevronUp" : "chevronDown"} />
            </button>
            {showSuccess && (
              <div className={b("list")}>
                <ul>
                  {results.successList.map(scenario => (
                    <li key={scenario}>{scenario}</li>
                  ))}
                </ul>
              </div>
            )}
            {results.errorList.length > 0 && (
              <>
                <span className="text-error">{t("MODALS.EXPORT_SCENARIOS.ERROR_MSG")}</span>
                <div className={b("list")}>
                  <span className="text-error">{t("MODALS.EXPORT_SCENARIOS.ERROR_LIST")}:</span>
                  <ul>
                    {results.errorList.map(scenario => (
                      <li
                        key={scenario}
                        className="text-error"
                      >
                        {scenario}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          disabled={processing}
          onClick={submitClick}
        >
          {submitText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
