import { Reducer } from "redux";
import { createReducer, ActionType } from "typesafe-actions";

import { Feature, getEmptyFeature } from "../../types/models/Feature";

import * as actions from './actions';
import * as projectActions from '../projects/actions';

type FeaturesState = {
  loading: boolean,
  createProcessing: boolean,
  deleteProcessing: boolean,
  updateProcessing: boolean,
  list: Feature[],
  toCreate: Feature[],
  favoriteFeatures: string[],
}

export const initialState: FeaturesState = {
  loading: false,
  createProcessing: false,
  deleteProcessing: false,
  updateProcessing: false,
  list: [],
  toCreate: [], // Список признаков "к созданию".
  // Т.е. это пустые признаки, для которых указываются данные и отправляются на
  // сервер для создания. При успешном создании новый
  // признак добавляется в  list, а из  toCreate удаляется
  favoriteFeatures: [],
};

export const CLIENT_FEATURE_ID_PREFIX = 'Client_';
let newFeaturesCounter: number = 0;

export const featuresReducer: Reducer<FeaturesState> = createReducer(initialState)
  .handleAction(
    actions.loadFeatures.request,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadFeatures.success,
    (state: FeaturesState, { payload }: ActionType<typeof actions.loadFeatures.success>): FeaturesState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadFeatures.failure,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createFeature.request,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      createProcessing: true,
    }),
  )
  .handleAction(
    actions.createFeature.success,
    (state: FeaturesState, { payload }: ActionType<typeof actions.createFeature.success>): FeaturesState => ({
      ...state,
      list: [payload, ...state.list],
      createProcessing: false,
    }),
  )
  .handleAction(
    actions.createFeature.failure,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      createProcessing: false,
    }),
  )
  .handleAction(
    actions.deleteFeature.request,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      deleteProcessing: true,
    }),
  )
  .handleAction(
    actions.deleteFeature.success,
    (state: FeaturesState, { payload: featureId }: ActionType<typeof actions.deleteFeature.success>): FeaturesState => {
      const listWithoutDeletedFeature = state.list.filter(feature => feature.id !== featureId);
      return {
        ...state,
        list: listWithoutDeletedFeature,
        deleteProcessing: false,
      };
    },
  )
  .handleAction(
    actions.deleteFeature.failure,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      deleteProcessing: false,
    }),
  )
  .handleAction(
    actions.updateFeature.request,
    (
      state: FeaturesState,
      { payload: featureToUpdate }: ActionType<typeof actions.updateFeature.request>,
    ): FeaturesState => {
      const listCopy = [...state.list];

      const featureToUpdateIndex = state.list.findIndex(feature => feature.id === featureToUpdate.id);
      listCopy[featureToUpdateIndex].pending = true;
      return {
        ...state,
        list: listCopy,
        updateProcessing: true,
      };
    },
  )
  .handleAction(
    actions.updateFeature.success,
    (
      state: FeaturesState,
      { payload: updatedFeature }: ActionType<typeof actions.updateFeature.success>,
    ): FeaturesState => {
      // Удаляем старый (до обновления) признак
      const updatedFeatureIndex = state.list.findIndex(feature => feature.id === updatedFeature.id);

      const newList = [...state.list];
      newList.splice(updatedFeatureIndex, 1, updatedFeature);
      return {
        ...state,
        list: newList,
        updateProcessing: false,
      };
    },
  )
  .handleAction(
    actions.updateFeature.failure,
    (state: FeaturesState, { payload: error }: ActionType<typeof actions.updateFeature.failure>): FeaturesState => {
      const { id } = error.payload;
      const listCopy = [...state.list];

      const featureToUpdateIndex = state.list.findIndex(feature => feature.id === id);
      listCopy[featureToUpdateIndex].pending = false;
      return {
        ...state,
        list: listCopy,
        updateProcessing: false,
      };
    },
  )
  .handleAction(
    actions.createNewFeature,
    (state: FeaturesState): FeaturesState => {
      const newFeature = getEmptyFeature();
      newFeature.id = CLIENT_FEATURE_ID_PREFIX + newFeaturesCounter++;
      return {
        ...state,
        toCreate: [...state.toCreate, newFeature],
      };
    },
  )
  .handleAction(
    actions.clearToCreateFeatures,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      toCreate: [],
    }),
  )
  .handleAction(
    actions.deleteNewFeature,
    (state: FeaturesState, { payload }: ActionType<typeof actions.deleteNewFeature>): FeaturesState => {
      const listWithoutDeletedFeature = state.toCreate.filter(feature => feature.id !== payload);

      return {
        ...state,
        toCreate: listWithoutDeletedFeature,
      };
    },
  )
  .handleAction(
    projectActions.selectProject,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      list: [],
    }),
  )
  .handleAction(
    actions.loadFavoriteFeatures.success,
    (
      state: FeaturesState,
      { payload }: ActionType<typeof actions.loadFavoriteFeatures.success>,
    ): FeaturesState => ({
      ...state,
      favoriteFeatures: payload,
    }),
  )
  .handleAction(
    actions.loadFavoriteFeatures.failure,
    (state: FeaturesState): FeaturesState => ({
      ...state,
      favoriteFeatures: [],
    }),
  )
  .handleAction(
    actions.addFavoriteFeature.success,
    (
      state: FeaturesState,
      { payload }: ActionType<typeof actions.addFavoriteFeature.success>,
    ): FeaturesState => ({
      ...state,
      favoriteFeatures: [...state.favoriteFeatures, payload],
    }),
  )
  .handleAction(
    actions.addFavoriteFeature.failure,
    (
      state: FeaturesState,
      { payload: error }: ActionType<typeof actions.addFavoriteFeature.failure>,
    ): FeaturesState => {
      const slug = error.payload;

      const listCopy = state.favoriteFeatures.filter(feature => feature !== slug);
      return {
        ...state,
        favoriteFeatures: listCopy,
      };
    },
  )
  .handleAction(
    actions.deleteFavoriteFeature.success,
    (
      state: FeaturesState,
      { payload }: ActionType<typeof actions.deleteFavoriteFeature.success>,
    ): FeaturesState => ({
      ...state,
      favoriteFeatures: state.favoriteFeatures.filter(feature => feature !== payload),
    }),
  )
  .handleAction(
    actions.deleteFavoriteFeature.failure,
    (
      state: FeaturesState,
      { payload: error }: ActionType<typeof actions.deleteFavoriteFeature.failure>,
    ): FeaturesState => {
      const slug = error.payload;

      return {
        ...state,
        favoriteFeatures: [...state.favoriteFeatures, slug],
      };
    },
  )
  .handleAction(
    actions.resetReducer,
    (): FeaturesState => ({
      ...initialState,
    }),
  );
