import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const WindowPlug = () => {
  const { t } = useTranslation();

  if (process.env.REACT_APP_ENVIRONMENT_ID === "local") return null;

  return (
    <div className="window-plug">
      { t("COMMON.SCREEN_RESOLUTION") }
      <span>{ t("COMMON.SCREEN_RESOLUTION_INFO") }</span>
    </div>
  );
};
export default memo(WindowPlug);
