import { ActiveNodeIdsWithBinds } from "../../pages/graphEditor/types";
import { RequestedFeature } from "../../types/backendModels/DialogBackend";
import { AppState } from "../reducers";
import { convertIdsArrayToObject } from "./helpers";
import { GraphChatState, UserMessageAndNodeMatch } from "./reducers";

export const getGraphChat = ({ graphChat }: AppState): GraphChatState => graphChat;

export const getGraphChatButtons = ({ graphChat }: AppState): GraphChatState['buttons'] => graphChat.buttons;

export const getRequestedFeatures = ({ graphChat }: AppState): RequestedFeature[] | null => (
  graphChat.requestedFeatures
);

export const getFirstRequestedFeature = ({ graphChat }: AppState): RequestedFeature | null => (
  graphChat.requestedFeatures?.[0] || null
);

export const getActiveRequestedFeature = ({ graphChat }: AppState): RequestedFeature | null => (
  graphChat.activeRequestedFeature || null
);

export type MessageAndNodeMatches = UserMessageAndNodeMatch[] | null;
export const getUserMessageAndNodeMatches = ({ graphChat }: AppState): MessageAndNodeMatches => (
  graphChat.userMessageAndNodeMatches || null
);

export const getGraphPositionStack = ({ graphChat }: AppState): string[] | null => (
  graphChat.graphPositionStack || null
);

export const getActiveNodeIds = (
  { graphChat, scenarioGraph }: AppState,
): ActiveNodeIdsWithBinds => {
  const stack = graphChat.graphPositionStack || scenarioGraph.searchResults;

  return stack?.length ? convertIdsArrayToObject(stack) : {};
};

export const getModifiedNodeId = ({ graphChat }: AppState): string | null => (
  graphChat.modifiedNodeId
);

export const checkIfTestingChatIsOpen = ({ graphChat }: AppState): boolean => graphChat.show;
