import React from 'react';

import { SvgProps } from '../types';

function HandIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9917 5.27676L10.9972 3.50639C10.9989 2.95411 10.5526 2.505 10.0003 2.50328C9.44805 2.50156 8.99894 2.94787 8.99722 3.50016L8.97988 9.06127V14.296C8.97988 14.6437 8.82262 14.9726 8.55208 15.191C8.07331 15.5773 7.37527 15.5184 6.968 15.0573C6.968 15.0573 4.35447 12.0865 3.87208 11.7619C3.3897 11.4372 2.85431 11.4365 2.41345 11.8122C1.83245 12.3073 1.76281 13.1797 2.25792 13.7607L6.83305 19.1296L6.8453 19.1438C8.3057 20.7579 9.79385 22.1116 11.9878 22.1116C14.5358 22.1116 16.8486 20.2372 17.697 17.9758L20.9926 8.40707C21.1724 7.88488 20.8949 7.31578 20.3727 7.13593C19.8506 6.95609 19.2815 7.23361 19.1016 7.75579L17.8473 11.3818L17.5875 12.1708C17.5014 12.4325 17.2039 12.5578 16.9564 12.4368C16.7589 12.3402 16.6589 12.1159 16.7191 11.9043L17.5351 8.84633L18.3075 5.83701C18.4448 5.30206 18.1225 4.7571 17.5875 4.6198C17.0526 4.4825 16.5076 4.80485 16.3703 5.3398L16.1887 6.04746L14.9299 10.9081C14.8442 11.25 14.4598 11.4315 14.1929 11.3521C13.8919 11.2626 13.7614 10.9615 13.8151 10.6521L14.3426 7.29318L14.9483 3.66379C15.0392 3.11904 14.6713 2.60373 14.1266 2.51282C13.5818 2.42191 13.0665 2.78982 12.9756 3.33457L12.708 4.93792L11.8693 10.1856C11.8391 10.3743 11.7166 10.5491 11.5127 10.5713C11.2646 10.5983 11.0712 10.4145 11.0753 10.1054L10.9917 5.27676Z"
        fill={color}
      />
    </svg>
  );
}

export default HandIcon;
