import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { block } from 'bem-cn';

import { throttle } from '../../services/helpers/utilities';

import Header from './components/header';
import RowWithAdds from './components/rowWithAdds';
import Row from './components/row';
import Folder from './components/folder';

import { flatColumnsConfiguration, isColumnsGroup, isFolder, parseHeaderItems, sortData } from './helpers';

import {
  ColumnConfig,
  ColumnsConfiguration,
  ColumnsGroup,
  DataItem,
  Sorting,
} from './types';

import './styles.scss';

export const b = block('nd-table-new');

export type TableProps = {
  id?: string;
  className?:string;
  data: DataItem[];
  columns: ColumnsConfiguration;
  rowsDisplayMode?: 'default' | 'separated';
  stickyHeader?: boolean;
  stickyFirstColumn?: boolean;
  bottomMargin?: number;
  loading?: boolean;
  rowClassName?: (item: DataItem) => string | undefined;
  onFolderClick?: (item: DataItem) => void;
  onItemClick?: (item: DataItem, event: React.MouseEvent) => void;
  onBottomReached?: () => void;
  useAutoColumns?: boolean;
  onScroll?: (e: React.UIEvent) => void,
}

type Context = {
  onFolderClick?: (item: DataItem) => void;
  onItemClick?: (item: DataItem, event: React.MouseEvent) => void;
};

export const TableContext = createContext<Context>({
  onFolderClick: undefined,
  onItemClick: undefined,
});

function Table({
  id,
  className = "",
  data,
  columns,
  rowsDisplayMode = 'default',
  stickyHeader = true,
  stickyFirstColumn = true,
  rowClassName,
  bottomMargin = 200,
  onFolderClick,
  onItemClick,
  loading,
  onBottomReached,
  useAutoColumns = true,
  onScroll,
}: TableProps) {
  const tableRef = useRef<HTMLDivElement>(null);
  const fakeScrollWrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const lastVerticalScroll = useRef<number>(0);

  const [sorting, setSorting] = useState<Sorting | undefined>();

  const handleSortingClick = useCallback((newSorting: Sorting | undefined) => {
    setSorting(newSorting);
  }, []);

  const memoizedTableScrollHandler = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => throttle((event: any) => {
      const tableContainer = event.target;
      if (!tableContainer) return;

      const hasHorizontalScroll = tableContainer.scrollLeft > 0;
      const hasVerticalScroll = tableContainer.scrollTop > 0;

      const currentVerticalScroll = event.target.scrollTop;

      if (!loading &&
          onBottomReached &&
          currentVerticalScroll !== lastVerticalScroll.current
      ) {
        const { clientHeight, scrollTop, scrollHeight } = tableContainer;
        const bottom = Math.abs((clientHeight + scrollTop) - scrollHeight);
        const near = bottom < bottomMargin;

        if (near) {
          onBottomReached();
        }
      }

      if (hasHorizontalScroll) {
        tableContainer.classList.add("horizontal-scrolled");
      } else {
        tableContainer.classList.remove("horizontal-scrolled");
      }

      if (hasVerticalScroll) {
        tableContainer.classList.add("vertical-scrolled");
      } else {
        tableContainer.classList.remove("vertical-scrolled");
      }

      lastVerticalScroll.current = currentVerticalScroll;
    }, 100),
    [bottomMargin, loading, onBottomReached],
  );

  const handleTableScroll = useCallback((event: React.UIEvent) => {
    if (onScroll) onScroll(event);

    if (!tableRef.current) return;
    if (!fakeScrollWrapperRef.current) return;

    fakeScrollWrapperRef.current.scroll({
      left: tableRef.current.scrollLeft,
    });

    memoizedTableScrollHandler(event);
  }, [memoizedTableScrollHandler, onScroll]);

  const parsedColumns = flatColumnsConfiguration(columns);

  const headerItems = useMemo(
    () => parseHeaderItems(parsedColumns, sorting),
    [parsedColumns, sorting],
  );

  const sortingColumn = useMemo(
    () => {
      let result: ColumnConfig | undefined;

      columns.forEach(column => {
        if (result) return;

        if (column.id === sorting?.column) {
          result = column as ColumnConfig;
        }

        if (isColumnsGroup(column)) {
          const groupColumn = (column as ColumnsGroup).columns
            .find(gColumn => gColumn.id === sorting?.column);

          if (groupColumn) {
            result = groupColumn;
          }
        }
      });

      return result;
    },
    [columns, sorting],
  );
  const needToSort = !!(sortingColumn && sorting);

  const sortedData = useMemo(
    () => (needToSort ?
      sortData({
        data,
        column: sortingColumn as ColumnConfig,
        sortConfigId: (sorting as Sorting).sorting,
      }) :
      data),
    [data, needToSort, sorting, sortingColumn],
  );

  const context = useMemo<Context>(
    () => ({
      onFolderClick,
      onItemClick,
    }),
    [onFolderClick, onItemClick],
  );

  const handleFakeScroll = useCallback(
    () => {
      if (!tableRef.current) return;
      if (!fakeScrollWrapperRef.current) return;

      tableRef.current.scroll({
        left: fakeScrollWrapperRef.current.scrollLeft,
      });
    },
    [],
  );

  useEffect(
    () => {
      if (!fakeScrollWrapperRef.current) return;
      if (!tableRef.current) return;

      const headerNode = tableRef.current.childNodes.item(0);
      if (!headerNode) return;

      let width = 0;
      (headerNode as HTMLDivElement).childNodes.forEach(node => {
        if ((node as HTMLDivElement).classList.contains("nd-table-new__group-title")) return;

        const nodeCoords = (node as HTMLDivElement).getBoundingClientRect();
        width += nodeCoords.width;
      });

      const fakeScrollContent = (fakeScrollWrapperRef.current as HTMLDivElement).childNodes.item(0);
      if (!fakeScrollContent) return;
      (fakeScrollContent as HTMLDivElement).style.width = width + "px";

      const negativeMargin = getComputedStyle(tableRef.current).getPropertyValue("--negative-margin");

      fakeScrollWrapperRef.current.style.marginLeft = `calc(-1 * ${negativeMargin})`;
      fakeScrollWrapperRef.current.style.marginRight = `calc(-1 * ${negativeMargin})`;
    },
    [],
  );

  useEffect(
    () => {
      if (!tableRef.current) return;
      if (!wrapperRef.current) return;

      const negativeMargin = getComputedStyle(tableRef.current).getPropertyValue("--negative-margin");

      wrapperRef.current.style.margin = `0 calc(-1 * ${negativeMargin})`;
      wrapperRef.current.style.padding = `0 ${negativeMargin} var(--nd-size-2x)`;
    },
    [],
  );

  useEffect(
    () => {
      if (!tableRef.current) return;
      if (rowsDisplayMode !== "separated") return;
      if (!stickyHeader) return;

      const header = tableRef.current.childNodes.item(0);
      if (!header) return;

      const headerCell = header.childNodes.item(0);
      if (!headerCell) return;

      const headerHeight = (headerCell as HTMLDivElement).clientHeight;
      tableRef.current.style.clipPath = `polygon(
        0% 0%,
        100% 0%,
        100% ${headerHeight}px,
        calc(100% + 6px) ${headerHeight}px,
        calc(100% + 6px) calc(100% + 6px),
        -6px calc(100% + 6px),
        -6px ${headerHeight}px,
        0px ${headerHeight}px,
        0% 0%
      )`;
    },
    [rowsDisplayMode, stickyHeader],
  );

  return (
    <div ref={wrapperRef} className={b("wrapper")}>
      <TableContext.Provider value={context}>
        <div ref={fakeScrollWrapperRef} onScroll={handleFakeScroll} className={b("fake-scrollbar-wrapper")}>
          <div className={b('fake-scrollbar-content')} />
        </div>
        <div
          id={id}
          ref={tableRef}
          className={`${b({
            'rows-display-mode': rowsDisplayMode,
            'sticky-first-column': stickyFirstColumn,
            'sticky-header': stickyHeader,
          })} ${className}`}
          style={{
            gridTemplateColumns: useAutoColumns ?
              `repeat(${parsedColumns.length}, auto)` :
              undefined,
          }}
          onScroll={handleTableScroll}
        >
          <Header
            items={headerItems}
            sortingClick={handleSortingClick}
          />
          {
            sortedData.map(item => (isFolder(item) ?
              (
                <Folder
                  className={rowClassName ? rowClassName(item) : undefined}
                  key={item.id}
                  item={item}
                  columns={parsedColumns}
                  level={0}
                  defaultExpanded={sortedData.length === 1}
                />
              ) : (
                <React.Fragment key={item.id}>
                  {item.additional ?
                    <RowWithAdds
                      className={rowClassName ? rowClassName(item) : undefined}
                      item={item}
                      columns={parsedColumns}
                    /> :
                    <Row
                      className={rowClassName ? rowClassName(item) : undefined}
                      item={item}
                      columns={parsedColumns}
                    />
                  }
                </React.Fragment>
              )))
          }
          {loading && (
            <React.Fragment>
              <div className={b("stab")} />
              <div className={b("stab")} />
              <div className={b("stab")} />
            </React.Fragment>
          )}
        </div>
      </TableContext.Provider>
    </div>
  );
}

export default React.memo(Table);
