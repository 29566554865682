import { ActionType, createReducer } from "typesafe-actions";
import { Reducer } from "redux";

import { RequestedFeature } from "../../types/backendModels/DialogBackend";
import * as actions from './actions';
import * as dialogActions from "../dialogs/actions";
import { Node } from "../../pages/graphEditor/types";
import { ResponseButtonsBlock } from "../../types/models/Dialog";
import { getNewMessageAndNodeMatches } from "./helpers";

export type UserMessageAndNodeMatch = {
  userMessageId: string;
  nodeId: string | null;
}

export interface GraphChatState {
  show: boolean;
  loading: boolean;
  buttons: ResponseButtonsBlock | undefined;
  requestedFeatures: RequestedFeature[] | null;
  activeRequestedFeature: RequestedFeature | null;
  lastNodeId: Node['id'] | null,
  disabledOnGraphEdit: boolean;
  modifiedNodeId: string | null;
  graphPositionStack: string[] | null;
  userMessageAndNodeMatches: UserMessageAndNodeMatch[] | null;
}

const initialState: GraphChatState = {
  show: false,
  loading: false,
  buttons: undefined,
  requestedFeatures: null,
  activeRequestedFeature: null,
  lastNodeId: null,
  disabledOnGraphEdit: false,
  modifiedNodeId: null,
  graphPositionStack: null,
  userMessageAndNodeMatches: null,
};

export const graphChatReducer: Reducer<GraphChatState> = createReducer<GraphChatState>(initialState)
  .handleAction(
    actions.openGraphTestingChat,
    (state: GraphChatState): GraphChatState => (
      {
        ...state,
        show: true,
      }
    ),
  )
  .handleAction(
    actions.closeGraphTestingChat,
    (state: GraphChatState): GraphChatState => (
      {
        ...state,
        show: false,
      }
    ),
  )
  .handleAction(
    dialogActions.clearLiveChat,
    (state: GraphChatState): GraphChatState => (
      {
        ...initialState,
        show: state.show,
        disabledOnGraphEdit: false,
        modifiedNodeId: null,
        graphPositionStack: null,
        userMessageAndNodeMatches: null,
      }
    ),
  )
  .handleAction(
    actions.setRequestedFeatures,
    (
      state: GraphChatState,
      { payload }: ActionType<typeof actions.setRequestedFeatures>,
    ): GraphChatState => (
      {
        ...state,
        requestedFeatures: payload,
      }
    ),
  )
  .handleAction(
    actions.setButtonsBlock,
    (
      state: GraphChatState,
      { payload }: ActionType<typeof actions.setButtonsBlock>,
    ): GraphChatState => (
      {
        ...state,
        buttons: payload,
      }
    ),
  )
  .handleAction(
    actions.setGraphPositionsStack,
    (
      state: GraphChatState,
      { payload }: ActionType<typeof actions.setGraphPositionsStack>,
    ): GraphChatState => {
      if (!payload) {
        return {
          ...state,
          graphPositionStack: null,
          lastNodeId: null,
        };
      }

      return {
        ...state,
        graphPositionStack: payload,
        lastNodeId: payload.slice(-1)[0],
      };
    },
  )
  .handleAction(
    actions.setActiveRequestedFeature,
    (
      state: GraphChatState,
      { payload }: ActionType<typeof actions.setActiveRequestedFeature>,
    ): GraphChatState => (
      {
        ...state,
        activeRequestedFeature: payload,
      }
    ),
  )
  .handleAction(
    actions.removeFirstRequestedFeature,
    (state: GraphChatState): GraphChatState => (
      {
        ...state,
        requestedFeatures: state.requestedFeatures ?
          state.requestedFeatures.slice(1) :
          [],
      }
    ),
  )
  .handleAction(
    actions.disableChatOnGraphEdit,
    (
      state: GraphChatState,
      { payload: newModifiedNodeId }: ActionType<typeof actions.disableChatOnGraphEdit>,
    ): GraphChatState => {
      const result = {
        ...state,
        disabledOnGraphEdit: !!(state.show && state.graphPositionStack),
      };

      const prevModifiedNodeIndex = state.modifiedNodeId ?
        state.graphPositionStack?.findIndex(id => id === state.modifiedNodeId) ?? -1 :
        -1;

      const newModifiedNodeIndex: number = newModifiedNodeId ?
        state.graphPositionStack?.findIndex(id => id === newModifiedNodeId) ?? -1 :
        -1;

      const getModifiedNodeId = () => {
        switch (true) {
          case (newModifiedNodeIndex === -1 && prevModifiedNodeIndex === -1):
            return null;
          case (prevModifiedNodeIndex === -1):
          case (newModifiedNodeIndex < prevModifiedNodeIndex):
            return newModifiedNodeId;
          default:
            return state.modifiedNodeId;
        }
      };

      result.modifiedNodeId = getModifiedNodeId();

      return result;
    },
  )
  .handleAction(
    actions.updateMessageAndNodeMatches,
    (
      state: GraphChatState,
      { payload }: ActionType<typeof actions.updateMessageAndNodeMatches>,
    ): GraphChatState => {
      const currentMatches = state.userMessageAndNodeMatches || [];

      const newMatches = getNewMessageAndNodeMatches({
        currentMatches,
        nodeIds: payload.nodeIds,
        userMessageId: payload.userMessageId,
      });

      return {
        ...state,
        userMessageAndNodeMatches: currentMatches.concat(...newMatches),
      };
    },
  )
  .handleAction(
    actions.setMessageAndNodeMatches,
    (
      state: GraphChatState,
      { payload }: ActionType<typeof actions.setMessageAndNodeMatches>,
    ): GraphChatState => (
      {
        ...state,
        userMessageAndNodeMatches: payload,
        disabledOnGraphEdit: false,
      }
    ),
  )
  .handleAction(
    actions.unlockChat,
    (state: GraphChatState): GraphChatState => ({
      ...state,
      disabledOnGraphEdit: false,
    }),
  );
