import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, fork } from 'redux-saga/effects';
import apiService, { ApiResponse } from '../../services/api';

import i18n from '../../services/i18n';
import { parseSlot } from '../../types/parsers/SlotParser';
import {
  GetFreeSlotsResponse,
  GetSlotsRequest,
  GetSlotsResponse,
  LockSlotRequest,
  UnlockSlotRequest,
} from '../../types/requests/Slots';
import { ErrorAction } from '../reducers';
import * as actions from './actions';

function* loadSlots(): SagaIterator {
  yield takeLatest(actions.loadSlots.request, function* (action) {
    const { modelId } = action.payload;
    try {
      const params: GetSlotsRequest = {
        query: {
          model_id: modelId,
        },
      };

      const response: ApiResponse<GetSlotsResponse> = yield call(apiService.getSlots, params);
      const slots = response.data.slots.map(slot => parseSlot(slot));
      yield put(actions.loadSlots.success({ modelId, slots }));
    } catch (err) {
      yield put(actions.loadFreeSlots.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SLOTS.SINGLE") }),
        { modelId },
      )));
    }
  });
}

function* loadFreeSlots(): SagaIterator {
  yield takeLatest(actions.loadFreeSlots.request, function* () {
    try {
      const response: ApiResponse<GetFreeSlotsResponse> = yield call(apiService.getFreeSlots);
      const slots = response.data.slots.map(slot => parseSlot(slot));
      yield put(actions.loadFreeSlots.success(slots));
    } catch (err) {
      yield put(actions.loadFreeSlots.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SLOTS.SINGLE") }),
      )));
    }
  });
}

function* lockSlot(): SagaIterator {
  yield takeLatest(actions.lockSlot.request, function* (action) {
    const slot = action.payload;
    try {
      const params: LockSlotRequest = {
        body: {
          worker_id: slot.workerId || 0,
          shard_id: slot.shardId,
          model_id: slot.modelId,
        },
      };
      yield call(apiService.lockSlot, params);
      yield put(actions.lockSlot.success(slot));
    } catch (err) {
      yield put(actions.lockSlot.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SLOTS.LOCK"),
        slot,
      )));
    }
  });
}

function* unlockSlot(): SagaIterator {
  yield takeLatest(actions.unlockSlot.request, function* (action) {
    const slot = action.payload;
    try {
      const params: UnlockSlotRequest = {
        params: {
          slot_id: action.payload.id,
        },
      };

      yield call(apiService.unlockSlot, params);
      const unlockedSlot = slot;
      yield put(actions.unlockSlot.success(unlockedSlot));
    } catch (err) {
      yield put(actions.unlockSlot.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SLOTS.UNLOCK"),
        slot,
      )));
    }
  });
}

export default function* slotsSagas(): SagaIterator {
  yield fork(loadSlots);
  yield fork(loadFreeSlots);
  yield fork(lockSlot);
  yield fork(unlockSlot);
}
