import { SagaIterator } from "redux-saga";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import * as actions from './actions';
import Api, { ApiResponse } from "../../services/api";
import { CreateLineRequest, CreateLineResponse, LinesRequest, LinesResponse } from "../../types/requests/Lines";
import { Line } from "../../types/models/Line";
import { parseLine } from "../../types/parsers/LineParser";
import i18n from "../../services/i18n";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import { ErrorAction } from "../reducers";

function* loadLines(): SagaIterator {
  yield takeLatest(actions.loadLines.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: LinesRequest = general;

      const response: ApiResponse<LinesResponse> = yield call(Api.getLines, params);
      const parsedLines: Line[] = (response.data.lines || []).map(bLine => parseLine(bLine));
      yield put(actions.loadLines.success(parsedLines));
    } catch (err) {
      yield put(actions.loadLines.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.LINES.MULTIPLE") }),
      )));
    }
  });
}

function* createLine(): SagaIterator {
  yield takeLatest(actions.createLine.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const params: CreateLineRequest = {
        ...general,
        line: {
          id: '',
          slug: action.payload,
        },
      };

      const response: ApiResponse<CreateLineResponse> = yield call(Api.createLine, params);
      const parsedLine = parseLine(response.data);
      yield put(actions.createLine.success(parsedLine));
    } catch (err) {
      yield put(actions.createLine.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.LINES.SINGLE") }),
      )));
    }
  });
}

export default function* linesSagas(): SagaIterator {
  yield fork(loadLines);
  yield fork(createLine);
}
