import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import * as actions from './actions';
import { AuthProviderMeta } from "../../types/models/AuthProvider";

export type AuthProvidersState = {
  default: Array<AuthProviderMeta>,
  list: Array<AuthProviderMeta>,
  current: AuthProviderMeta,
  loading: boolean,
}

const initialState: AuthProvidersState = {
  default: [],
  list: [],
  current: { name: '', title: '', path: '', url: '', type: '', manualSignup: false },
  loading: false,
};

export const authProviderReducer: Reducer<AuthProvidersState> = createReducer(initialState)
  .handleAction(
    actions.loadAuthProviders.request,
    (state: AuthProvidersState): AuthProvidersState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadAuthProviders.success,
    (
      state: AuthProvidersState,
      { payload = [] }: ActionType<typeof actions.loadAuthProviders.success>,
    ): AuthProvidersState => ({
      ...state,
      default: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadAuthProviders.failure,
    (state: AuthProvidersState): AuthProvidersState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadAllAuthProviders.request,
    (state: AuthProvidersState): AuthProvidersState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadAllAuthProviders.success,
    (
      state: AuthProvidersState,
      { payload = [] }: ActionType<typeof actions.loadAuthProviders.success>,
    ): AuthProvidersState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadAllAuthProviders.failure,
    (state: AuthProvidersState): AuthProvidersState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadAuthProvider.request,
    (state: AuthProvidersState): AuthProvidersState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadAuthProvider.success,
    (
      state: AuthProvidersState,
      { payload }: ActionType<typeof actions.loadAuthProvider.success>,
    ): AuthProvidersState => ({
      ...state,
      current: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadAuthProviders.failure,
    (state: AuthProvidersState): AuthProvidersState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.setCurrentAuthProvider,
    (
      state: AuthProvidersState,
      { payload }: ActionType<typeof actions.setCurrentAuthProvider>,
    ): AuthProvidersState => ({
      ...state,
      current: payload,
    }),
  );
