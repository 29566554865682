import React, { memo } from "react";
import { components, MenuProps } from "react-select";

import { b } from "..";
import Icon from "../../icon";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Menu({ children, ...props }: MenuProps<any>) {
  return (
    <components.Menu {...props}>
      {props.selectProps.isLoading ? (
        <div className={b("loading-wrapper")}>
          <Icon type="loaderBrackets" />
        </div>
      ) : children}
    </components.Menu>
  );
}

export default memo(Menu);
