import React from 'react';

import { SvgProps } from '../types';

function CommentOutlineIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.136 20.915C4.214 20.969 4.306 20.999 4.402 21C7.126 21.03 9.123 20.504 10.387 19.357C11.067 19.5 11.774 19.575 12.5 19.575C17.747 19.575 22 15.641 22 10.788C22 5.933 17.747 2 12.5 2C7.253 2 3 5.934 3 10.787C3 13.037 3.914 15.09 5.418 16.645C5.29 17.915 4.825 19.128 4.02 20.285C3.87 20.501 3.92 20.765 4.136 20.915ZM10.8 17.42C10.5099 17.3589 10.2095 17.3675 9.92336 17.445C9.63724 17.5226 9.37357 17.6668 9.154 17.866C8.651 18.323 7.588 18.954 6.591 19.146C7.059 18.467 7.305 17.477 7.377 16.766C7.40424 16.4948 7.372 16.2208 7.28254 15.9633C7.19308 15.7058 7.04854 15.4709 6.859 15.275C5.663 14.038 5 12.463 5 10.797C5 7.077 8.327 4 12.5 4C16.673 4 20 7.077 20 10.797C20 14.517 16.673 17.594 12.5 17.594C11.921 17.594 11.352 17.534 10.8 17.419V17.42Z"
        fill={color}
      />
    </svg>
  );
}

export default CommentOutlineIcon;
