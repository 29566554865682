import { DRAFT_VERSION } from "../../redux/versions/reducer";
import { Editable } from "./Editable";

export type Version = {
  id: string;
  version: number;
  draft: boolean;
  hidden: boolean;
  created: string;
  createdDateFormatted: string;
  versionSlug?: string;
  name?: string;
  status?: VersionStatus;
  errorDescription?: string;
  isActive: boolean;
} & Editable

export type VersionToPercentage = {
  versionId: number;
}

export enum VersionStatus {
  needToReload = 'need_to_load', // fallback
  creating = 'creating',
  created = 'created',
  loading = 'loading',
  loaded = 'loaded',
  deleting = 'deleting',
  error = 'error',
}

export enum VersionType {
  release = 'release',
  draft = 'draft',
}

export function isReleaseVersion(version: Version): boolean {
  return version.draft === false;
}

export function isDraftVersion(version: Version): boolean {
  return version.id === DRAFT_VERSION.id;
}

export function isCurrentReleaseVersion(version: Version): boolean {
  return isReleaseVersion(version) && version.status === VersionStatus.loaded;
}
