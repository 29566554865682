import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import cx from '../../services/helpers/classNames';
import { getSidebarOpenStatus } from '../../redux/sidebar/selectors';

import './styles.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Page({ children, id, className }: PropsWithChildren<any>, ref: React.Ref<any>) {
  const sidebarOpened = useSelector(getSidebarOpenStatus);
  const classNames = cx('page', 'page-container', className, { 'page-container-wide': !sidebarOpened });

  return (
    <div id={id} className={classNames} ref={ref}>
      {children}
    </div>
  );
}

export default React.forwardRef(Page);
