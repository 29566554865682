import { isAfter, isBefore, addDays, addMonths, addHours } from "date-fns";

const mscOffsetMinutes = -180; // UTC + 3;

export const getDateWithinLimits = ({
  date,
  minDate,
  maxDate,
}: {
  date: Date,
  minDate?: Date,
  maxDate?: Date,
}): Date => {
  if (minDate && isBefore(date, minDate)) return minDate;
  if (maxDate && isAfter(date, maxDate)) return maxDate;

  return date;
};

export function dateLocalToMsc(value: Date): Date {
  const offset = value?.getTimezoneOffset() || 0;
  const diff = (mscOffsetMinutes - offset) * 60 * 1000;
  return new Date(+value + diff);
}

export function dateMscToLocal(value: Date): Date {
  const offset = value?.getTimezoneOffset() || 0;
  const diff = (mscOffsetMinutes - offset) * 60 * 1000;
  return new Date(+value - diff);
}

export function getDatesRange(
  dateFrom: Date,
  dateTo: Date,
  period: 'day' | 'hour' | 'month' = 'day',
): Date[] {
  const result: Date[] = [];

  let currentDate = new Date(dateFrom);

  while (isBefore(currentDate, dateTo)) {
    result.push(currentDate);

    let addFunction;
    switch (period) {
      case 'hour':
        addFunction = addHours;
        break;
      case 'month':
        addFunction = addMonths;
        break;
      default:
        addFunction = addDays;
        break;
    }

    currentDate = addFunction(currentDate, 1);
  }

  return result;
}
