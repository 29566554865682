import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeOperatorIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM9.90137 9.61426C9.90137 10.8398 10.8047 11.7871 11.8691 11.7871C12.9385 11.7871 13.8418 10.8398 13.8418 9.62402C13.8467 8.43262 12.9336 7.5 11.8691 7.5C10.8096 7.5 9.90137 8.41797 9.90137 9.61426ZM8.83203 16.3281H14.916C15.7217 16.3281 16 16.084 16 15.6348C16 14.3799 14.4033 12.6514 11.874 12.6514C9.33984 12.6514 7.74805 14.3799 7.74805 15.6348C7.74805 16.084 8.02637 16.3281 8.83203 16.3281Z" fill={color} />
  </svg>
);

export default GraphNodeOperatorIcon;
