import { ActionType, createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';

import { Secret } from '../../types/models/Secret';

import * as actions from "./actions";

type SecretsState = {
  list: Secret[],
  loading: boolean;
  processing: boolean;
  editSecretModal: {
    activeSecret: Secret | null,
    opened: boolean,
  },
  newSecretModal: {
    opened: boolean,
  }
}

const initialState: SecretsState = {
  list: [],
  loading: false,
  processing: false,
  editSecretModal: {
    activeSecret: null,
    opened: false,
  },
  newSecretModal: {
    opened: false,
  },
};

export const secretsReducer: Reducer<SecretsState> = createReducer<SecretsState>(initialState)
  .handleAction(
    actions.loadSecrets.request,
    (state: SecretsState): SecretsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadSecrets.success,
    (
      state: SecretsState,
      { payload }: ActionType<typeof actions.loadSecrets.success>,
    ): SecretsState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadSecrets.failure,
    (state: SecretsState): SecretsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createSecret.request,
    (state: SecretsState): SecretsState => ({
      ...state,
      processing: true,
    }),
  )
  .handleAction(
    actions.createSecret.success,
    (
      state: SecretsState,
      { payload }: ActionType<typeof actions.createSecret.success>,
    ): SecretsState => ({
      ...state,
      list: [
        ...state.list,
        payload,
      ],
      processing: false,
    }),
  )
  .handleAction(
    actions.createSecret.failure,
    (state: SecretsState): SecretsState => ({
      ...state,
      processing: false,
    }),
  )
  .handleAction(
    actions.updateSecret.request,
    (state: SecretsState): SecretsState => ({
      ...state,
      processing: true,
    }),
  )
  .handleAction(
    actions.updateSecret.success,
    (
      state: SecretsState,
      { payload }: ActionType<typeof actions.updateSecret.success>,
    ): SecretsState => ({
      ...state,
      list: state.list.map(secret => secret.slug === payload.slug ?
        payload :
        secret,
      ),
      processing: false,
    }),
  )
  .handleAction(
    actions.updateSecret.failure,
    (state: SecretsState): SecretsState => ({
      ...state,
      processing: false,
    }),
  )
  .handleAction(
    actions.deleteSecret.request,
    (state: SecretsState): SecretsState => ({
      ...state,
      processing: true,
    }),
  )
  .handleAction(
    actions.deleteSecret.success,
    (
      state: SecretsState,
      { payload }: ActionType<typeof actions.deleteSecret.success>,
    ): SecretsState => ({
      ...state,
      list: state.list.filter(s => s.slug !== payload),
      processing: false,
    }),
  )
  .handleAction(
    actions.deleteSecret.failure,
    (state: SecretsState): SecretsState => ({
      ...state,
      processing: false,
    }),
  )
  .handleAction(
    actions.setSecretToEdit,
    (
      state: SecretsState,
      { payload }: ActionType<typeof actions.setSecretToEdit>,
    ): SecretsState => ({
      ...state,
      editSecretModal: {
        ...state.editSecretModal,
        opened: true,
        activeSecret: payload,
      },
    }),
  )
  .handleAction(
    actions.closeEditModal,
    (state: SecretsState): SecretsState => ({
      ...state,
      editSecretModal: {
        ...state.editSecretModal,
        opened: false,
        activeSecret: null,
      },
    }),
  )
  .handleAction(
    actions.openNewSecretModal,
    (state: SecretsState): SecretsState => ({
      ...state,
      newSecretModal: {
        ...state.newSecretModal,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeNewSecretModal,
    (state: SecretsState): SecretsState => ({
      ...state,
      newSecretModal: {
        ...state.newSecretModal,
        opened: false,
      },
    }),
  );
