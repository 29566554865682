import { isValid } from "date-fns";

import { objSnakeToCamel } from "../../services/helpers/utilities";
import { ScenarioGroupName } from "../backendModels/ScenarioGraphBackend";
import { ScenarioGraphsBackend } from "../backendModels/ScenarioGraphsBackend";
import { CallStatus, ScenarioGraphs } from "../models/ScenarioGraphs";
import { dateMscToLocal } from "../../services/helpers/dateUtils";

export function parseScenarioGraphs(value: ScenarioGraphsBackend): ScenarioGraphs {
  return {
    total: value.total,
    scenarios: value.scenarios.map(o => {
      const updatedAt = o.updated_at ? new Date(o.updated_at) : false;

      return {
        ...objSnakeToCamel(o),
        callStatus: CallStatus.new, // TODO бэк пока не присылает callStatus
        updatedAt: isValid(updatedAt) ? dateMscToLocal(updatedAt as Date) : undefined,
        groupName: o.group_name as ScenarioGroupName ?? ScenarioGroupName.main,
      };
    }),
  };
}
