import React, { memo, useCallback } from 'react';
import { block } from "bem-cn";
import TimeField from 'react-simple-timefield';

import Icon from "../icon";

import './styles.scss';

const b = block("range-time-input");

type RangeTimeInputProps = {
  value: [string, string];
  placeholder?: string;
  fluid?: boolean;
  onChange: (value: [string, string]) => void;
}

function RangeTimeInput({
  value,
  placeholder,
  fluid = true,
  onChange,
}: RangeTimeInputProps) {
  const handleChangeFirst = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      onChange([newValue, value[1]]);
    },
    [onChange, value],
  );

  const handleChangeSecond = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      onChange([value[0], newValue]);
    },
    [onChange, value],
  );

  return (
    <label className={b({ fluid })}>
      {placeholder && <span className={b("placeholder")}>{placeholder}</span>}
      <div className={b("value-wrapper")}>
        <TimeField
          value={value[0]}
          onChange={handleChangeFirst}
        />
        -
        <TimeField
          value={value[1]}
          onChange={handleChangeSecond}
        />
      </div>
      <Icon
        className={b("icon")}
        type="timeOutline"
      />
    </label>
  );
}

export default memo(RangeTimeInput);
