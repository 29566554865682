import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Entity } from '../../../../types/models/Entity';

import { AppState } from '../../../../redux/reducers';

import Select, { SelectConfig } from '../../../../components-new/select/configurable';

type EntitySelectorProps = {
  value?: string,
  disabled?: boolean,
  onChange: (value: string) => void,
}

function EntitySelector({
  entities,
  value,
  disabled = false,
  onChange,
}: EntitySelectorProps & ReduxProps) {
  const { t } = useTranslation();

  const config: SelectConfig = useMemo(
    () => ({
      options: entities.value.map(entity => ({
        label: entity.slug,
        value: entity.id,
      })),
      valueFunction: option => option.value,
      labelFunction: option => option.label,
    }),
    [entities.value],
  );

  return (
    <Select
      placeholder={t("ENTITIES.ENTITY")}
      config={config}
      isDisabled={disabled}
      value={value || ""}
      onChange={onChange}
      isLoading={entities.loading}
    />
  );
}

type MapStateToProps = {
  entities: {
    value: Entity[],
    loading: boolean,
  }
}

const mapStateToProps = (state: AppState): MapStateToProps => ({
  entities: state.entities.list,
});

type ReduxProps = MapStateToProps;

export default connect(mapStateToProps, {})(EntitySelector);
