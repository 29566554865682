import { createAsyncAction, createAction } from "typesafe-actions";
import { FileMeta } from "../../types/models/Files";
import { GameData, GameType } from "../../types/models/Games";
import { ScenarioGraphMeta, ScenarioGraphs } from "../../types/models/ScenarioGraphs";
import { ErrorAction } from "../reducers";
import { RunCallRequest } from "../tasks/actions";
import { ScenarioGraphsItem, ScenarioGraphsList, TrainingItem } from "./types";
import { ScenarioGraphLabel } from "../../types/models/ScenarioGraphLabel";
import { ActionIdentifierSuggest } from "../../types/models/ActionSuggest";
import { Version } from "../../types/models/Version";
import { ScenarioGraphParent, ScenarioGraphParentNode } from "../../types/models/ScenarioGraphParent";
import { ScenariosFilters } from "../../pages/scenarioGraphs/helpers";
import { ScenarioGroupName } from "../../types/backendModels/ScenarioGraphBackend";

export const loadScenarios = createAsyncAction(
  'scenarioGraphs/loadScenarios/requested',
  'scenarioGraphs/loadScenarios/succeeded',
  'scenarioGraphs/loadScenarios/failed',
)<undefined, ScenarioGraphs, ErrorAction>();

export const createScenario = createAction('scenarioGraphs/createScenario')();

export const updateScenariosList = createAction('scenarioGraphs/updateScenariosList')<ScenarioGraphsList>();
export const setCurrentDragGroup = createAction('scenarioGraphs/setCurrentDragGroup')<ScenarioGroupName | undefined>();
export const setSearchScenariosFilter = createAction('scenarioGraphs/setFilters')<ScenariosFilters>();

export const deleteScenario = createAsyncAction(
  'scenarioGraphs/deleteScenario/requested',
  'scenarioGraphs/deleteScenario/succeeded',
  'scenarioGraphs/deleteScenario/failed',
)<Pick<ScenarioGraphsItem, 'id' >, ScenarioGraphsList, ErrorAction>();

export const cloneScenario = createAsyncAction(
  'scenarioGraphs/cloneScenario/requested',
  'scenarioGraphs/cloneScenario/succeeded',
  'scenarioGraphs/cloneScenario/failed',
)<{ id: ScenarioGraphsItem['id'], title: string }, ScenarioGraphMeta, ErrorAction>();

export const editScenario = createAction('graphTopics/editScenario')<{
  scenarioId: ScenarioGraphsItem['id'],
}>();

export const updateScenariosPriority = createAsyncAction(
  'scenarioGraphs/updateScenrariosPriority/requested',
  'scenarioGraphs/updateScenrariosPriority/succeeded',
  'scenarioGraphs/updateScenrariosPriority/failed',
)<ScenarioGraphsList, undefined, ErrorAction>();

export const createCalling = createAsyncAction(
  'scenarioGraphs/createCalling/requested',
  'scenarioGraphs/createCalling/succeeded',
  'scenarioGraphs/createCalling/failed',
)<RunCallRequest, undefined, ErrorAction>();

export const updateFilesList = createAsyncAction(
  'scenarioGraphs/updateFilesList/requested',
  'scenarioGraphs/updateFilesList/succeeded',
  'scenarioGraphs/updateFilesList/failed',
)<undefined, { total: number, list: FileMeta[] }, ErrorAction>();

export const openCallingModal = createAction('scenarioGraphs/openCallingModal')<string>();
export const closeCallingModal = createAction('scenarioGraphs/closeCallingModal')();

export const activateScenario = createAsyncAction(
  'scenarioGraphs/activateScenario/requested',
  'scenarioGraphs/activateScenario/succeeded',
  'scenarioGraphs/activateScenario/failed',
)<ScenarioGraphsItem['id'], ScenarioGraphsItem['id'], ErrorAction>();

export const deactivateScenario = createAsyncAction(
  'scenarioGraphs/deactivateScenario/requested',
  'scenarioGraphs/deactivateScenario/succeeded',
  'scenarioGraphs/deactivateScenario/failed',
)<ScenarioGraphsItem['id'], ScenarioGraphsItem['id'], ErrorAction>();

export const changeTrainingScenario = createAction('scenarioGraphs/changeTrainingScenario')<ScenarioGraphsItem['slug']>();

export const changeGamesData = createAction('scenarioGraphs/changeGamesData')<{
  type: GameType,
  data: TrainingItem[],
}>();

export const addPhraseToDelete = createAction('scenarioGraphs/addPhraseToDelete')<{
  type: GameType,
  phrase: string,
}>();

export const loadGamesData = createAsyncAction(
  'scenarioGraphs/loadGamesData/requested',
  'scenarioGraphs/loadGamesData/succeeded',
  'scenarioGraphs/loadGamesData/failed',
)<
  ScenarioGraphsItem['slug'],
  Record<GameType, GameData['records']>,
  ErrorAction
>();

export const saveGamesData = createAsyncAction(
  'scenarioGraphs/saveGamesData/requested',
  'scenarioGraphs/saveGamesData/succeeded',
  'scenarioGraphs/saveGamesData/failed',
)<GameType, { type: GameType, resultsCount: number }, ErrorAction>();

export const openTrainingModal = createAction('scenarioGraphs/openTrainingModal')<GameType>();
export const closeTrainingModal = createAction('scenarioGraphs/closeTrainingModal')<GameType>();

export const openTrainingResultsModal = createAction('scenarioGraphs/openTrainingResultsModal')<GameType>();
export const closeTrainingResultsModal = createAction('scenarioGraphs/closeTrainingResultsModal')<GameType>();

export const toggleScenarioAutomatable = createAsyncAction(
  'scenarioGraphs/toggleScenarioAutomatable/requested',
  'scenarioGraphs/toggleScenarioAutomatable/succeeded',
  'scenarioGraphs/toggleScenarioAutomatable/failed',
)<
  { id: ScenarioGraphMeta['id'] },
  { id: ScenarioGraphMeta['id'] },
  ErrorAction
>();

export const openScenarioEditor = createAction('graphTopics/openScenarioEditor')();
export const closeScenarioEditor = createAction('graphTopics/closeScenarioEditor')();

export const loadScenariosLabels = createAsyncAction(
  'scenario/loadScenariosLabels/requested',
  'scenario/loadScenariosLabels/succeeded',
  'scenario/loadScenariosLabels/failed',
)<
  undefined,
  ScenarioGraphLabel[],
  ErrorAction
>();

export const createScenarioLabel = createAsyncAction(
  'scenario/createScenarioLabel/requested',
  'scenario/createScenarioLabel/succeeded',
  'scenario/createScenarioLabel/failed',
)<
  ScenarioGraphLabel['title'],
  ScenarioGraphLabel,
  ErrorAction
>();

export const deleteScenarioLabel = createAsyncAction(
  'scenario/deleteScenarioLabel/requested',
  'scenario/deleteScenarioLabel/succeeded',
  'scenario/deleteScenarioLabel/failed',
)<
  ScenarioGraphLabel['id'],
  ScenarioGraphLabel['id'],
  ErrorAction
>();

export const updateScenarioLabel = createAsyncAction(
  'scenario/updateScenarioLabel/requested',
  'scenario/updateScenarioLabel/succeeded',
  'scenario/updateScenarioLabel/failed',
)<
  { label: ScenarioGraphLabel, scenarios: Array<ScenarioGraphMeta['id']> },
  { label: ScenarioGraphLabel, scenarios: Array<ScenarioGraphMeta['id']> },
  ErrorAction
>();

export const addScenariosToLabel = createAsyncAction(
  'scenaio/addScenariosToLabel/requested',
  'scenaio/addScenariosToLabel/succeeded',
  'scenaio/addScenariosToLabel/failed',
)<
  { label: ScenarioGraphLabel['id'], scenarios: Array<ScenarioGraphMeta['id']> },
  { label: ScenarioGraphLabel['id'], scenarios: Array<ScenarioGraphMeta['id']> },
  ErrorAction
>();

export const removeScenarioFromLabel = createAsyncAction(
  'scenario/removeScenarioFromLabel/requested',
  'scenario/removeScenarioFromLabel/succeeded',
  'scenario/removeScenarioFromLabel/failed',
)<
  { label: ScenarioGraphLabel['id'], scenario: ScenarioGraphMeta['id'] },
  { label: ScenarioGraphLabel['id'], scenario: ScenarioGraphMeta['id'] },
  ErrorAction
>();

export const loadActionIdentifierSuggestions = createAsyncAction(
  'scenario/actionSuggestions/requested',
  'scenario/actionSuggestions/succeeded',
  'scenario/actionSuggestions/failed',
)<string, ActionIdentifierSuggest[], ErrorAction>();

export const startExportingScenarios = createAction('scenarioGraphs/startExportingScenarios')<ScenarioGraphMeta[]>();
export const stopExportingScenarios = createAction('scenarioGraphs/stopExportingScenarios')();
export const updateExportinScenariosInfo = createAction('scenarioGraphs/updateExportinScenariosInfo')<
  {
    successList: Array<ScenarioGraphMeta['title']>,
    errorList: Array<ScenarioGraphMeta['title']>,
    zipLink: string,
  }
>();
export const updateExportingScenarioCount = createAction('scenarioGraphs/updateExportingScenarioCount')<number>();

export const openAddScenariosToLabelModal = createAction(
  'scenarios/openAddScenariosToLabelModal',
)<{
  initScenarios: Array<ScenarioGraphMeta['id']>
}>();
export const closeAddScenariosToLabelModal = createAction(
  'scenarios/closeAddScenariosToLabelModal',
)();

export const openEditLabelModal = createAction(
  'scenarios/openEditLabelModal',
)<{
  labelId: ScenarioGraphLabel['id'],
}>();
export const closeEditLabelModal = createAction(
  'scenarios/closeEditLabelModal',
)();

export const openDeleteLabelModal = createAction(
  'scenarios/openDeleteLabelModal',
)<{
  labelId: ScenarioGraphLabel['id'],
}>();
export const closeDeleteLabelModal = createAction(
  'scenarios/closeDeleteLabelModal',
)();

export const navigateToScenario = createAction(
  'scenarios/navigateToScenario',
)<{
  scenarioId: ScenarioGraphsItem['id'],
  scenarioSlug: ScenarioGraphsItem['slug'],
  version?: Version['id'],
  nodeId?: string,
  openNode?: boolean,
  debugIds?: string[],
}>();

export const loadActiveScenarioUsage = createAsyncAction(
  'scenarios/loadActiveScenarioUsage/requested',
  'scenarios/loadActiveScenarioUsage/succeeded',
  'scenarios/loadActiveScenarioUsage/failed',
)<
  undefined,
  { nodes: ScenarioGraphParentNode[], selected: Array<ScenarioGraphParent['id']> },
  ErrorAction
>();

export const saveActiveScenarioUsage = createAsyncAction(
  'scenarios/saveActiveScenarioUsage/requested',
  'scenarios/saveActiveScenarioUsage/succeeded',
  'scenarios/saveActiveScenarioUsage/failed',
)<
  string[],
  string[],
  ErrorAction
>();
