import React from 'react';
import { block } from 'bem-cn';

import { SortDirection } from '../../types/models/Sorting';
import Icon from '../icon';

import './styles.scss';

const b = block("sort-sign");

type SortButtonProps = {
  direction: SortDirection;
}

function SortSign({
  direction,
}: SortButtonProps) {
  return (
    <div className={b()}>
      <Icon type="sort" />
      <div className={b("fade").mix(direction.toLocaleLowerCase())} />
    </div>
  );
}

export default SortSign;
