import { generateId } from "../../services/helpers/generateId";
import { Slot } from "./Slots";

export type Model = {
  id: string,
  title: string,
  slug: string,
  version: number,
  type: ModelType,
  slots: Slot[],
}

export enum ModelType {
  'dialog_text_classification',
  'knn_one_message_text_classification',
  'one_message_text_classification',
  'united_message_model_classification',
  'label_embedding_based_on_one_message',
  'sentence_bert_one_message',
  'bert_binary_clf_one_message',
  'sbert_embedder',
}

export enum ModelComparisonType {
  sampling = 'sampling',
}

export function getEmptyModel(): Model {
  return {
    id: generateId(),
    title: '',
    slug: '',
    version: 1,
    type: ModelType.bert_binary_clf_one_message,
    slots: [],
  };
}
