import { User } from "../../types/models/User";
import { User as UserExtended } from "../../types/models/UserAccesses";
import { getSelectedProject } from "../projects/selectors";
import { AppState } from "../reducers";

export function getCurrentUser(): User {
  const user = JSON.parse(localStorage.getItem('user') || "null");

  return user;
}

export function getUsers(state: AppState) {
  return state.user.all;
}

export function getUsersList(state: AppState): UserExtended[] {
  return state.user.all.list;
}

export function getUserByLogin(state: AppState, login: string): UserExtended | undefined {
  return state.user.all.list.find(user => user.username === login);
}

export const getCurrentUserAccesses = (state: AppState) => {
  return state.user.accesses;
};

export const getCurrentUserRole = (state: AppState) => {
  const selectedProject = getSelectedProject(state);

  return state.user.accesses[selectedProject.slug].group;
};

export const getAccessesMap = (state: AppState) => {
  return state.user.accessesMap;
};

export const getConfigs = (state: AppState) => {
  return state.user.configs;
};

export const getConfig = (state: AppState, key: keyof AppState['user']['configs']) => {
  return state.user.configs[key];
};

export const getExperiments = (state: AppState) => {
  return state.user.experiments;
};

export const getExperiment = (state: AppState, key: keyof AppState['user']['experiments']) => {
  return state.user.experiments[key];
};
