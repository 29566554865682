import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import block from 'bem-cn';

import { AppState } from '../../redux/reducers';
import { Project } from '../../types/models/Project';
import { ModalProps } from '../../services/modal';

import Input, { OnChangeFunc } from '../../components-new/input';
import SideModal from '../../components-new/sideModal';
import Button from '../../components-new/button';

import './styles.scss';

export type CreateVersionModalProps = {
  onConfirm: (name: string) => void,
}

export const b = block('create-version-modal');

function CreateVersionModal({
  selectedProject,
  onConfirm,
  close,
}: CreateVersionModalProps & ModalProps & ReduxProps) {
  const { t } = useTranslation();

  const [versionName, setVersionName] = useState<string>(
    `${selectedProject.title} ${format(new Date(), 'dd MMMM HH:mm')}`,
  );

  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSubmit = useCallback(
    () => {
      onConfirm(versionName);
    },
    [onConfirm, versionName],
  );

  const handleRename: OnChangeFunc = useCallback(
    (_, { value }) => {
      setVersionName(value);
    },
    [],
  );

  useEffect(
    () => {
      const el = confirmButtonRef.current;

      if (el && el.focus) {
        el.focus();
      }
    },
    [],
  );

  return (
    <SideModal
      className={b()}
      title={t("MODALS.CREATE_VERSION.TITLE")}
      footer={(
        <Button
          primary
          fluid
          size="large"
          disabled={!versionName.trim().length}
          onClick={handleSubmit}
        >
          {t("COMMON.SAVE")}
        </Button>
      )}
      onClose={close}
      open
      >
      <Input
        fluid
        name="versionName"
        placeholder={`${t("MODALS.CREATE_VERSION.VERSION_NAME")}:`}
        floatingPlaceholder
        value={versionName}
        onChange={handleRename}
      />
    </SideModal>
  );
}

type MapStateToProps = {
  selectedProject: Project,
}

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedProject: state.projects.selectedProject,
});

type ReduxProps = MapStateToProps;

export default connect(mapStateToProps, {})(CreateVersionModal);
