import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

import block from 'bem-cn';

import { ScenarioGraphExampleType } from '../../types/models/ScenarioGraphExample';

import { ModalProps } from '../../services/modal';

import { getScenarioGraphs } from '../../redux/scenarioGraphs/selectors';
import { createScenarioGraphExample } from '../../redux/scenariosExamples/actions';

import SideModal from '../../components-new/sideModal';
import Select from '../../components-new/select';
import Textarea from '../../components-new/textarea';
import Button from '../../components-new/button';

import './styles.scss';

const b = block('nd-add-example-modal');

export type AddExampleModalProps = {
  className?: string,
  currentScenario?: string,
  text: string,
};

export default function AddExampleModal({
  id,
  className = '',
  text,
  currentScenario,
  close,
}: AddExampleModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const scenarios = useSelector(getScenarioGraphs);

  const [exampleText, setExampleText] = useState(text);

  const selectOptions = scenarios.map(scenario => ({
    label: scenario.title,
    value: scenario.id,
  }));

  const [selectedOption, setSelectedOption] = useState(selectOptions.find(option => option.label === currentScenario));

  const handleChangeExample = useCallback(
    (_, { value: nextValue }) => { setExampleText(nextValue) },
    [],
  );

  const onChangeScenario = useCallback(
    newValue => {
      setSelectedOption(newValue);
    }, [setSelectedOption],
  );

  const onConfirm = useCallback(
    () => {
      dispatch(createScenarioGraphExample.request({
        scenarioId: selectedOption?.value || '',
        text: exampleText,
        type: ScenarioGraphExampleType.addition,
        modalId: id || "",
      }));
    }, [
      dispatch,
      exampleText,
      selectedOption?.value,
      id,
    ],
  );

  return (
    <SideModal
      open
      className={`${b()} ${className}`}
      onClose={close}
      footer={(
        <>
          <Button
            size="large"
            fluid
            onClick={close}
          >
            {t("COMMON.CANCEL")}
          </Button>
          <Button
            size="large"
            fluid
            visualStyle="main"
            disabled={!exampleText || !selectedOption}
            onClick={onConfirm}
          >
            {t("COMMON.ADD")}
          </Button>
        </>
      )}
      title={(
        <div className="row-2">
          <Button
            icon="arrowLeft"
            visualStyle="ghost"
            onClick={close}
          />
          <span>{t('MODALS.ADD_EXAMPLE.TITLE')}</span>
        </div>
      )}
    >
      <div className={b("content").mix("column-2")}>
        <Select
          options={selectOptions}
          value={selectedOption}
          onChange={onChangeScenario}
          usePortal
          placeholder={t("COMMON.SCENARIO_SELECT_PLACEHOLDER")}
        />
        <Textarea
          name={'textarea'}
          value={exampleText}
          onChange={handleChangeExample}
          fluid
          resizeOnInput
          placeholder={t("GRAPH.SCENARIO_EDITOR.EXAMPLE_PLACEHOLDER")}
        />
        {exampleText.length > 500 && <span className={b('warning')}>{t('MODALS.ADD_EXAMPLE.ERROR')}</span>}
      </div>
    </SideModal>
  );
}
