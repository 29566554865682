/* eslint-disable camelcase */
import { createAsyncAction, createAction } from 'typesafe-actions';

import { Project, ProjectType } from '../../types/models/Project';
import { ProjectConfig } from '../../types/models/ProjectConfig';
import { ErrorAction } from '../reducers';
import { DialogsStatisticsGroup } from '../../types/models/AutomationStatistics';
import { CallsDetailsStatisticsItem, CallsStatisticsItem } from '../../types/models/CallsStatistics';
import { CallsDetailsStatisticsRequest } from '../../types/requests/CallsStatistics';
import { StatisticsTab } from './helpers';

import { FiltersWithDirection } from '../../pages/account/components/callsTab';
import { Filters } from '../../pages/account/components/filters';

export const loadProjects = createAsyncAction(
  'projects/load/requested',
  'projects/load/succeeded',
  'projects/load/failed',
)<undefined, Project[], ErrorAction>();

export const loadDialogsStatistics = createAsyncAction(
  'projects/loadDialogsStatistics/requested',
  'projects/loadDialogsStatistics/succeeded',
  'projects/loadDialogsStatistics/failed',
)<undefined, {
  topics: DialogsStatisticsGroup[],
  chart: DialogsStatisticsGroup[]
}, ErrorAction>();

export const loadCallsStatistics = createAsyncAction(
  'projects/loadCallsStatistics/requested',
  'projects/loadCallsStatistics/succeeded',
  'projects/loadCallsStatistics/failed',
)<
  { groupId?: string },
  {
    data: CallsStatisticsItem;
    chartStatistics?: CallsStatisticsItem[];
    groupId?: string;
  },
  ErrorAction
>();

export const loadCallsDetailsStatistics = createAsyncAction(
  'projects/loadCallsDetailsStatistics/requested',
  'projects/loadCallsDetailsStatistics/succeeded',
  'projects/loadCallsDetailsStatistics/failed',
)<
  Omit<CallsDetailsStatisticsRequest, 'batch_id'>
    & {
      batchId?: CallsDetailsStatisticsRequest['batch_id'];
    },
  CallsDetailsStatisticsItem[],
  ErrorAction
>();

export const resetCallsDetailsStatistics = createAction('projects/resetCallsDetailsStatistics')();

export const changeProject = createAction('projects/changeProject')<Project>();
export const selectProject = createAction('projects/selectProject')<Project>();

export const loadProjectConfig = createAsyncAction(
  'projects/loadConfig/requested',
  'projects/loadConfig/succeeded',
  'projects/loadConfig/failed',
)<undefined, ProjectConfig, ErrorAction>();

export const updateProjectConfig = createAsyncAction(
  'projects/updateConfig/requested',
  'projects/updateConfig/succeeded',
  'projects/updateConfig/failed',
)<{ config: ProjectConfig, file?: File }, ProjectConfig, ErrorAction>();

export const createProject = createAsyncAction(
  'projects/create/requested',
  'projects/create/succeeded',
  'projects/create/failed',
)<{
  slug: string,
  title: string,
  isChatterbox: boolean,
  isAsync: boolean,
  modalId?: string,
  type: ProjectType,
  parentProjectSlug?: string,
}, Project, ErrorAction>();

export const updateFilters = createAction(
  'projects/updateFilters',
)<Filters | FiltersWithDirection>();

export const setStatisticsTab = createAction(
  'projects/setStatisticsTab',
)<StatisticsTab>();

export const openExportHistory = createAction(
  'project/openExportHistory',
)();
export const closeExportHistory = createAction(
  'project/closeExportHistory',
)();
