import React, { memo } from "react";
import { useTranslation } from "react-i18next";

function FormattedCreateLabel({
  input,
  subtitle,
}: {
  input: string,
  subtitle?: string,
}) {
  const { t } = useTranslation();

  return (
    <div className="column-1">
      <span>{input}</span>
      <span className="option-subtitle">{subtitle ?? t("COMMON.CREATE_OPTION")}</span>
    </div>
  );
}

export default memo(FormattedCreateLabel);
