import { Node } from "../../pages/graphEditor/types";
import { AppState } from "../reducers";
import { DebugStep, DebugWindow } from "./types";
import { GraphDebugBlock } from "../../types/models/GraphDebugBlock";

export const getDebugSteps = (
  state: AppState,
) => state.graphDebug.debugSteps;

export const getDebugStepsAsArray = (
  state: AppState,
) => Object.values(state.graphDebug.debugSteps)
  .flatMap(b => (Array.isArray(b) ? b : [b]));

export const isNodeHasDebugStep = (
  state: AppState,
  nodeId: Node['id'],
): boolean => Object.keys(state.graphDebug.debugSteps).includes(nodeId);

export const getDebugStepsByNodeId = (
  state: AppState,
  nodeId: Node['id'],
): DebugStep | DebugStep[] | undefined => (
  state.graphDebug.debugSteps[nodeId]
);

export const getDebugWindow = (
  state: AppState,
  id: DebugWindow['id'],
): DebugWindow | undefined => (
  state.graphDebug.debugWindows.find(window => window.id === id)
);

export const getDebugWindows = (
  state: AppState,
) => state.graphDebug.debugWindows;

export const getDebugBlocksByNodeId = (
  state: AppState,
  nodeId: Node['id'],
): GraphDebugBlock | GraphDebugBlock[] | undefined => {
  const blocksToNode = state.graphDebug.debugBlocks.filter(block => block?.nodeId === nodeId);

  if (blocksToNode.length === 1) {
    return blocksToNode[0];
  }

  return blocksToNode;
};

export const getDebugBlockById = (
  state: AppState,
  debugId: GraphDebugBlock['id'],
): GraphDebugBlock | undefined => (
  state.graphDebug.debugBlocks.find(block => block.id === debugId)
);

export const getDebugBlocks = ({ graphDebug }: AppState) => graphDebug.debugBlocks;
export const getDebugBlocksLoading = ({ graphDebug }: AppState) => graphDebug.loading;
export const getDebugBlocksId = ({ graphDebug }: AppState) => graphDebug.debugId;
