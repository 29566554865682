import React, { FC } from 'react';
import { SvgProps } from '../types';

const PauseIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.163 3.819C5 4.139 5 4.559 5 5.4V18.6C5 19.44 5 19.86 5.163 20.181C5.30702 20.4631 5.53666 20.6924 5.819 20.836C6.139 21 6.559 21 7.4 21H7.6C8.44 21 8.86 21 9.181 20.837C9.46349 20.6931 9.69315 20.4635 9.837 20.181C10 19.861 10 19.441 10 18.6V5.4C10 4.56 10 4.14 9.837 3.819C9.69315 3.53651 9.46349 3.30685 9.181 3.163C8.861 3 8.441 3 7.6 3H7.4C6.56 3 6.14 3 5.819 3.163C5.53651 3.30685 5.30685 3.53651 5.163 3.819ZM14.163 3.819C14 4.139 14 4.559 14 5.4V18.6C14 19.44 14 19.86 14.164 20.181C14.3078 20.463 14.537 20.6922 14.819 20.836C15.139 21 15.559 21 16.4 21H16.6C17.44 21 17.86 21 18.181 20.837C18.4631 20.693 18.6924 20.4633 18.836 20.181C19 19.861 19 19.441 19 18.6V5.4C19 4.56 19 4.14 18.837 3.819C18.6931 3.53651 18.4635 3.30685 18.181 3.163C17.861 3 17.441 3 16.6 3H16.4C15.56 3 15.14 3 14.819 3.163C14.5369 3.30702 14.3076 3.53666 14.164 3.819H14.163Z"
      fill={color}
    />
  </svg>
);

export default PauseIcon;
