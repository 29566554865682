import { SagaIterator } from "redux-saga";
import {
  call, fork, put, select, takeEvery, takeLatest,
} from "redux-saga/effects";

import NotificationManager from "../../services/notifications";
import apiService, { ApiResponse } from "../../services/api";
import { downloadBlobData } from "../../services/helpers/utilities";
import i18n from "../../services/i18n";
import { AudioFileMetadata } from "../../types/models/Files";
import { parseAudioFileMetadata, parseFileMeta } from "../../types/parsers/FileParser";
import {
  DownloadFileRequest,
  DownloadFileResponse,
  GetAudioFilesMetadataResponse,
  GetFilesMetaByTaskTypeRequest,
  GetFilesMetaByTaskTypeResponse,
  UpdateUserAudioFilenameRequest,
  UploadAudioFileRequest,
  UploadAudioFileResponse,
  UploadExternalPhoneIdsFileRequest,
  UploadFileRequest,
  UploadFileResponse,
} from "../../types/requests/Files";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { ErrorAction } from "../reducers";
import { getGeneralRequestParams } from "../selectors";
import { isFileLocked } from "./selectors";

import * as actions from './actions';

function* downloadFile():SagaIterator {
  yield takeEvery(actions.downloadFile.request, function* (action) {
    const { fileId, service } = action.payload;
    try {
      const fileLocked: boolean = yield select(isFileLocked, fileId);

      if (fileLocked) {
        NotificationManager.error(i18n.t("ERRORS.API.FILES.LOCKED"));
        return;
      }

      yield put(actions.lockFile(fileId));

      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: DownloadFileRequest = {
        ...general,
        file_id: fileId,
        service,
      };
      const response: ApiResponse<DownloadFileResponse> = yield call(apiService.downloadFile, params);
      const filename = getFilenameFromDispositionHeader(response.headers['content-disposition']);
      downloadBlobData(response.data, filename);
      yield put(actions.downloadFile.success(response.data));
    } catch (err) {
      yield put(actions.downloadFile.failure(new ErrorAction(err, i18n.t("ERRORS.API.FILES.UPLOAD"))));
    } finally {
      yield put(actions.unlockFile(fileId));
    }
  });
}

export function* uploadFileS3(file: File): SagaIterator {
  const general: ProjectRequestParams = yield select(getGeneralRequestParams);

  const formData = new FormData();
  formData.append('file', file);

  const params: UploadFileRequest = {
    ...general,
    formData,
  };
  const response: ApiResponse<UploadFileResponse> = yield call(apiService.uploadFileS3, params);

  const fileId: string = response.data.file ? response.data.file.id : '';
  return fileId;
}

function* loadAudioFiles(): SagaIterator {
  yield takeLatest(actions.loadAudioFiles.request, function* () {
    try {
      const response: ApiResponse<GetAudioFilesMetadataResponse> = yield call(apiService.getAudioFilesMetadata);
      const parsedAudioMetadata = (response.data.files_metadata || []).map(meta => parseAudioFileMetadata(meta));
      yield put(actions.loadAudioFiles.success(parsedAudioMetadata));
    } catch (err) {
      yield put(actions.loadAudioFiles.failure(new ErrorAction(err, i18n.t("ERRORS.API.FILES.LOAD"))));
    }
  });
}

function* uploadAudioFile(): SagaIterator {
  yield takeLatest(actions.uploadAudioFile.request, function* (action) {
    const { file, name = '', comment = '' } = action.payload;
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (name) formData.append('user_filename', name);
      if (comment) formData.append('user_comment', comment);

      const params: UploadAudioFileRequest = {
        body: {
          formData,
        },
      };

      const response: ApiResponse<UploadAudioFileResponse> = yield call(apiService.uploadAudioFile, params);
      const meta: AudioFileMetadata = parseAudioFileMetadata(response.data);
      yield put(actions.uploadAudioFile.success(meta));
    } catch (err) {
      yield put(actions.uploadAudioFile.failure(new ErrorAction(err, i18n.t("ERRORS.API.FILES.UPLOAD"), { name })));
    }
  });
}

function* updateAudioUserFilename(): SagaIterator {
  yield takeLatest(actions.updateAudioUserFilename.request, function* (action) {
    const { newUserFilename, newUserComment, filename } = action.payload;
    try {
      const params: UpdateUserAudioFilenameRequest = {
        filename,
        body: {
          user_filename: newUserFilename,
          user_comment: newUserComment,
        },
      };

      yield call(apiService.updateAudioUserFilename, params);
      yield put(actions.updateAudioUserFilename.success({ filename, newUserFilename, newUserComment }));
    } catch (err) {
      yield put(actions.updateAudioUserFilename.failure(new ErrorAction(err, i18n.t("ERRORS.API.AUDIO.UPDATE_FILENAME"), { filename })));
    }
  });
}

function* uploadExternalPhoneIdsFile(): SagaIterator {
  yield takeLatest(actions.uploadExternalPhoneIdsFile.request, function* (action) {
    try {
      const { file } = action.payload;
      const formData = new FormData();
      formData.append('file', file);

      const params: UploadExternalPhoneIdsFileRequest = {
        body: {
          formData,
        },
      };

      yield call(apiService.uploadExternalPhoneIdsFile, params);

      NotificationManager.success(i18n.t("PAGE_VOICE.FILE_UPLOAD_SUCCESS"));
      yield put(actions.uploadExternalPhoneIdsFile.success());
    } catch (err) {
      yield put(
        actions.updateAudioUserFilename.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.FILES.UPLOAD")),
        ),
      );
    }
  });
}

export function getFilenameFromDispositionHeader(contentDisposition: string = ''): string {
  const parts = contentDisposition.split(';') || [];
  const attachmentPart = parts.find(part => (part.split('=')[0] || '').trim() === 'filename') || '';
  const curlyFilename = (attachmentPart.split('=')[1] || '');
  const filename = curlyFilename.slice(1, curlyFilename.length - 1);
  const parsedFilename = filename.replace(/:/g, "-");
  return parsedFilename;
}

function* getFilesMeta(): SagaIterator {
  yield takeLatest(actions.getFilesMeta.request, function* (action) {
    try {
      const params: GetFilesMetaByTaskTypeRequest = {
        task_type: action.payload.taskType,
        limit: action.payload.limit,
        offset: action.payload.offset,
      };

      type T = ApiResponse<GetFilesMetaByTaskTypeResponse>;
      const { data }: T = yield call(apiService.getFilesMetaByTaskType, params);

      if (!data) throw Error("Data not found");

      yield put(
        actions.getFilesMeta.success({ total: data.total, list: data.files.map(parseFileMeta).reverse() }),
      );
    } catch (err) {
      yield put(
        actions.getFilesMeta.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.FILES.LOAD")),
        ),
      );
    }
  });
}

export default function* filesSagas(): SagaIterator {
  yield fork(downloadFile);
  yield fork(loadAudioFiles);
  yield fork(uploadAudioFile);
  yield fork(updateAudioUserFilename);
  yield fork(uploadExternalPhoneIdsFile);
  yield fork(getFilesMeta);
}
