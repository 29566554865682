import { memo, ReactNode } from "react";
import { block } from "bem-cn";

import { ModalProps } from "../../services/modal";
import i18n from "../../services/i18n";

import SideModal from "../../components-new/sideModal";
import UserWithLaptop from "../../components-new/icon/images/userWithLaptop";
import Button from "../../components-new/button";

import './styles.scss';

const b = block("confirm-delete-item-side-modal");

export type ConfirmDeleteItemSideModalProps = {
  title?: string,
  message: ReactNode,
  cancelText?: string,
  confirmText?: string,
  onSubmit: () => void,
}

function ConfirmDeleteItemSideModal({
  title = i18n.t("MODALS.CONFIRM_ACTION.TITLE"),
  cancelText = i18n.t("COMMON.CANCEL"),
  confirmText = i18n.t("COMMON.DELETE"),
  message,
  close,
  onSubmit,
}: ModalProps & ConfirmDeleteItemSideModalProps) {
  return (
    <SideModal
      className={b()}
      open
      title={title}
      footer={(
        <>
          <Button
            fluid
            size="large"
            onClick={close}
          >
            {cancelText}
          </Button>
          <Button
            fluid
            visualStyle="destructive"
            size="large"
            onClick={onSubmit}
          >
            {confirmText}
          </Button>
        </>
      )}
      onClose={close}
    >
      <div className={b("content").mix('column')}>
        <UserWithLaptop />
        {message}
      </div>
    </SideModal>
  );
}

export default memo(ConfirmDeleteItemSideModal);
