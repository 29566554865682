import React, { useCallback, useEffect, useRef, useState } from "react";
import { block } from "bem-cn";

import { NotificationModel, NotificationType } from "../../../types/models/Notification";

import Icon, { IconTypes } from "../../../components-new/icon";
import Button from "../../../components-new/button";

import "./notification.scss";

const b = block("sai-notification");

type NotificationProps = {
  data: NotificationModel;
  onClose: (id: NotificationModel['id']) => void;
  onExpire?: (id: NotificationModel['id']) => void;
}

function Notification({
  data,
  onClose,
  onExpire,
}: NotificationProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState<boolean>(false);

  function getIconType(): IconTypes {
    switch (data.type) {
      case NotificationType.success:
        return "checkmark";
      case NotificationType.error:
        return "exclamation";
      case NotificationType.info:
      default:
        return "info";
    }
  }

  const handleCloseClick = useCallback(
    () => {
      onClose(data.id);
    },
    [data.id, onClose],
  );

  const expand = useCallback(
    () => {
      setExpanded(true);
    },
    [],
  );

  const fold = useCallback(
    () => {
      setExpanded(false);
    },
    [],
  );

  useEffect(
    () => {
      if (!data.config || !data.config.durationMs || !onExpire) return;

      const notificationElem = ref.current;
      if (!notificationElem) return;

      const isLastElem = notificationElem.parentElement === notificationElem.parentElement?.parentElement?.lastChild;

      let timer: NodeJS.Timeout;
      if (isLastElem) {
        timer = setTimeout(
          () => {
            onExpire(data.id);
          },
          data.config.durationMs,
        );
      }

      return () => {
        clearTimeout(timer);
      };
    },
  );

  return (
    <div
      ref={ref}
      id={data.id}
      className={b({
        type: data.type,
        mode: data.config?.mode || "default",
        expanded,
      })}
      onMouseLeave={fold}
    >
      <div className={b("icon")}>
        <Icon type={getIconType()} />
      </div>
      <div className={b("text")}>
        <span
          className={b("text-wrapper")}
          onClick={expand}
        >
          {data.text}
        </span>
      </div>
      <div className={b("close-button")}>
        <Button
          icon="close"
          simple
          size="small"
          onClick={handleCloseClick}
        />
      </div>
      {data.config?.action && (
        <div className={b("action")}>
          <Button
            className={b("action-button")}
            simple
            onClick={data.config.action.onClick}
          >
            {data.config.action.text}
          </Button>
        </div>
      )}
    </div>
  );
}

export default (Notification);
