import React, { FC } from 'react';
import { SvgProps } from '../types';

const ArrowRightSquareIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8 11L13.0464 5.75355C13.2417 5.55829 13.2417 5.24171 13.0464 5.04645L12.3536 4.35355C12.1583 4.15829 11.8417 4.15829 11.6464 4.35355L4 12L11.6464 19.6464C11.8417 19.8417 12.1583 19.8417 12.3536 19.6464L13.0464 18.9536C13.2417 18.7583 13.2417 18.4417 13.0464 18.2464L7.8 13H19.5C19.7761 13 20 12.7761 20 12.5V11.5C20 11.2239 19.7761 11 19.5 11H7.8Z"
      fill={color}
    />
  </svg>
);

export default ArrowRightSquareIcon;
