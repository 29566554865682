import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { SelectedClusterItems } from "../../pages/scenarioGraphs/components/trainingModal/components/clusteringModal/types";
import { Cluster, ClusterItem } from "../../types/models/Clustering";

import * as actions from './actions';
import * as projectActions from '../projects/actions';

export type ClusteringState = {
  openModal: boolean;
  loading: boolean;
  loadingDetails: boolean;
  expandedClusterNumber: number | null,
  clusters: Cluster[];
  canRun: boolean;
  createdAt: string | null;
  defaultScenarioSlug: string;
}

const initialState: ClusteringState = {
  openModal: false,
  loading: false,
  loadingDetails: false,
  expandedClusterNumber: null,
  clusters: [],
  canRun: false,
  createdAt: null,
  defaultScenarioSlug: '',
};

export const clusteringReducer: Reducer<ClusteringState> = createReducer<ClusteringState>(initialState)
  .handleAction(
    actions.loadClusters.request,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      loading: true,
      clusters: [],
    }),
  )
  .handleAction(
    actions.loadClusters.success,
    (
      state: ClusteringState,
      { payload }: ActionType<typeof actions.loadClusters.success>,
    ): ClusteringState => ({
      ...state,
      loading: false,
      clusters: payload.clusters,
      canRun: payload.canRun || false,
      createdAt: payload.createdAt || null,
    }),
  )
  .handleAction(
    actions.loadClusters.failure,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.launchClustering.request,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      canRun: false,
    }),
  )
  .handleAction(
    actions.launchClustering.failure,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      canRun: true,
    }),
  )
  .handleAction(
    actions.closeClusterDetails,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      expandedClusterNumber: null,
      clusters: state.clusters.map(cluster => ({ ...cluster, clusterItemsFull: [] })),
    }),
  )
  .handleAction(
    actions.loadClusterDetails.request,
    (
      state: ClusteringState,
      { payload }: ActionType<typeof actions.loadClusterDetails.success>,
    ): ClusteringState => ({
      ...state,
      loadingDetails: true,
      expandedClusterNumber: payload.clusterNumber,
      clusters: state.clusters.map(cluster => (
        cluster.clusterNumber !== payload.clusterNumber ?
          { ...cluster, clusterItemsFull: [] } :
          cluster
      )),
    }),
  )
  .handleAction(
    actions.loadClusterDetails.success,
    (
      state: ClusteringState,
      { payload }: ActionType<typeof actions.loadClusterDetails.success>,
    ): ClusteringState => ({
      ...state,
      loadingDetails: false,
      clusters: state.clusters.map(cluster => (
        cluster.clusterNumber === payload.clusterNumber ?
          {
            ...cluster,
            clusterItemsFull: cluster.clusterItemsFull.concat(payload.clusterItems),
          } :
          cluster
      )),
    }),
  )
  .handleAction(
    actions.loadClusterDetails.failure,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      loadingDetails: false,
    }),
  )
  .handleAction(
    actions.setPhrases.request,
    (
      state: ClusteringState,
      { payload: { grouped } }: ActionType<typeof actions.setPhrases.request>,
    ): ClusteringState => {
      const filter = (selected: SelectedClusterItems) => (item: ClusterItem) => !selected[item.id];

      return {
        ...state,
        clusters: state.clusters
          .map(cluster => {
            const selected = grouped[cluster.id];
            if (!selected) return cluster;

            const f = filter(selected);
            const clusterItemsShort = cluster.clusterItemsShort.filter(f);
            const clusterItemsFull = cluster.clusterItemsFull.filter(f);

            return {
              ...cluster,
              clusterItemsShort,
              clusterItemsFull,
              total: Math.max(clusterItemsShort.length, clusterItemsFull.length, 0),
            };
          })
          .filter(cluster => cluster.total > 0),
      };
    },
  )
  .handleAction(
    actions.closeModal,
    (state: ClusteringState): ClusteringState => ({
      ...state,
      openModal: false,
      loading: false,
      loadingDetails: false,
      expandedClusterNumber: null,
      clusters: state.clusters.map(cluster => ({ ...cluster, clusterItemsFull: [] })),
      defaultScenarioSlug: '',
    }),
  )
  .handleAction(
    actions.openModal,
    (
      state: ClusteringState,
      { payload: defaultScenarioSlug }: ActionType<typeof actions.openModal>,
    ): ClusteringState => ({
      ...state,
      openModal: true,
      defaultScenarioSlug,
    }),
  )
  .handleAction(
    projectActions.selectProject,
    (): ClusteringState => ({
      ...initialState,
    }),
  );
