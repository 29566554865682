import { dateMscToLocal } from "../../services/helpers/dateUtils";
import { objSnakeToCamel } from "../../services/helpers/utilities";
import { AudioFileMetadataBackend, FileMetaBackend } from "../backendModels/FilesBackend";
import { AudioFileMetadata, FileMeta } from "../models/Files";

export function parseAudioFileMetadata(value: AudioFileMetadataBackend): AudioFileMetadata {
  return {
    ...objSnakeToCamel(value),
    uploadedAt: value.uploaded_at ?
      dateMscToLocal(new Date(value.uploaded_at)).toString() :
      undefined,
  };
}

export function parseFileMeta(value: FileMetaBackend): FileMeta {
  return {
    ...objSnakeToCamel(value),
  };
}
