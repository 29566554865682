import { call, fork, put, takeLatest } from 'redux-saga/effects';

import apiService, { ApiResponse } from '../../services/api';
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from '../../services/helpers/utilities';
import i18n from "../../services/i18n";
import { AccountType } from '../../types/models/Accounts';

import { ChangeMessengerIntegrationEnableStatusRequest, CreateMessengerAccountRequest, DeleteMessengerAccountRequest, GetMessengerAccountsRespone, UpdateMessengerAccountRequest } from '../../types/requests/Account';

import { ErrorAction } from '../reducers';

import * as actions from './actions';

function* loadMessengerAccountsStatuses() {
  yield takeLatest(actions.loadMessengerIntegrations.request, function* () {
    try {
      const response: ApiResponse<GetMessengerAccountsRespone> = yield call(apiService.getMessengerAccounts);
      yield put(actions.loadMessengerIntegrations.success({
        telegram: objSnakeToCamelDeep(response.data.telegram),
        whatsapp: objSnakeToCamelDeep(response.data.whatsapp),
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.INTEGRATIONS.MULTIPLE") });
      yield put(actions.loadMessengerIntegrations.failure(new ErrorAction(err, text)));
    }
  });
}

function* createMessengerIntegration() {
  yield takeLatest(actions.createMessengerIntegration.request, function* (action) {
    try {
      const accountData = action.payload;
      const request: CreateMessengerAccountRequest = {
        body: accountData,
      };

      yield call(
        apiService.createMessengerAccount,
        request,
      );

      yield put(actions.createMessengerIntegration.success(accountData));
      yield put(actions.enableMessengerIntegration.request(accountData.type));
      yield put(actions.setMessengerToEdit(null));
      yield put(actions.loadMessengerIntegrations.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") });
      yield put(actions.createMessengerIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateMessengerIntegration() {
  yield takeLatest(actions.updateMessengerIntegration.request, function* (action) {
    try {
      const accountData = action.payload;

      if (accountData.type === AccountType.telegram) {
        // Поле botInfo только у интеграции с Telegram только для чтения,
        // бек возвращает 500 если пытаться прокинуть его для изменения
        // Поэтому добавляем проверку в сагу на удаление этого поля при обновлении интеграции
        delete accountData.botInfo;
      }

      const request: UpdateMessengerAccountRequest = {
        body: objCamelToSnakeDeep(accountData),
      };

      yield call(
        apiService.updateMessengerAccount,
        request,
      );

      yield put(actions.updateMessengerIntegration.success(accountData));
      yield put(actions.setMessengerToEdit(null));
      yield put(actions.loadMessengerIntegrations.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") });
      yield put(actions.updateMessengerIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteMessengerIntegration() {
  yield takeLatest(actions.deleteMessengerIntegration.request, function* (action) {
    try {
      const type = action.payload;

      const request: DeleteMessengerAccountRequest = {
        query: {
          type,
        },
      };

      yield call(apiService.deleteMessengerAccount, request);
      yield put(actions.setMessengerToEdit(null));
      yield put(actions.deleteMessengerIntegration.success(type));
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") });
      yield put(actions.deleteMessengerIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

function* enableMessengerIntegration() {
  yield takeLatest(actions.enableMessengerIntegration.request, function* (action) {
    try {
      const type = action.payload;
      const request: ChangeMessengerIntegrationEnableStatusRequest = {
        query: {
          type,
        },
        path: {
          enable_value: "enable",
        },
      };

      yield call(apiService.changeMessengerEnableStatus, request);
      yield put(actions.enableMessengerIntegration.success(type));
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") });
      yield put(actions.enableMessengerIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

function* disableMessengerIntegration() {
  yield takeLatest(actions.disableMessengerIntegration.request, function* (action) {
    try {
      const type = action.payload;
      const request: ChangeMessengerIntegrationEnableStatusRequest = {
        query: {
          type,
        },
        path: {
          enable_value: "disable",
        },
      };

      yield call(apiService.changeMessengerEnableStatus, request);
      yield put(actions.disableMessengerIntegration.success(type));
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") });
      yield put(actions.disableMessengerIntegration.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* messengetIntegrationsSagas() {
  yield fork(loadMessengerAccountsStatuses);
  yield fork(createMessengerIntegration);
  yield fork(updateMessengerIntegration);
  yield fork(deleteMessengerIntegration);
  yield fork(enableMessengerIntegration);
  yield fork(disableMessengerIntegration);
}
