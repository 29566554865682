import { createReducer, ActionType } from "typesafe-actions";
import { Reducer } from 'redux';

import { User as UserExtended, UserRole } from "../../types/models/UserAccesses";
import { User } from "../../types/models/User";
import { UserConfigs, UserExperiments } from '../../types/codegen/experiments';
import { Project } from "../../types/models/Project";
import { AccessesMap } from "../../types/backendModels/UserAccessesBackend";

import * as actions from './actions';

type UserState = {
  current: User,
  all: {
    list: UserExtended[],
    loading: boolean,
  },
  configs: UserConfigs;
  experiments: UserExperiments;
  accesses: Record<
    Project['slug'],
    { group: UserRole, capabilities: string[]
  }>,
  accessesMap: AccessesMap,
};

const initialState: UserState = {
  current: JSON.parse(localStorage.getItem('user') || JSON.stringify({
    id: null,
    email: null,
    name: null,
    pending: false,
  })),
  all: {
    list: [],
    loading: false,
  },
  configs: {},
  experiments: {},
  accesses: {},
  // @ts-ignore
  accessesMap: {},
};

export const userReducer: Reducer<UserState> = createReducer<UserState>(initialState)
  .handleAction(
    actions.setUser,
    (state: UserState, { payload }: ActionType<typeof actions.setUser>): UserState => ({
      ...state,
      current: {
        ...payload,
      },
    }),
  )
  .handleAction(
    actions.setConfigs,
    (state: UserState, { payload }: ActionType<typeof actions.setConfigs>): UserState => ({
      ...state,
      configs: {
        ...payload,
      },
    }),
  )
  .handleAction(
    actions.setExperiments,
    (state: UserState, { payload }: ActionType<typeof actions.setExperiments>): UserState => ({
      ...state,
      experiments: {
        ...payload,
      },
    }),
  )
  .handleAction(
    actions.clearUser,
    (state: UserState): UserState => ({
      ...state,
      current: { ...initialState.current },
      configs: { ...initialState.configs },
      experiments: { ...initialState.experiments },
    }),
  )
  .handleAction(
    actions.loadUsers.request,
    (state: UserState): UserState => ({
      ...state,
      all: {
        ...state.all,
        list: [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadUsers.success,
    (
      state: UserState,
      { payload: users }: ActionType<typeof actions.loadUsers.success>,
    ): UserState => ({
      ...state,
      all: {
        ...state.all,
        list: users,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadUsers.failure,
    (state: UserState): UserState => ({
      ...state,
      all: {
        ...state.all,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.setAccesses,
    (
      state: UserState,
      { payload }: ActionType<typeof actions.setAccesses>,
    ): UserState => ({
      ...state,
      accesses: payload,
    }),
  )
  .handleAction(
    actions.setAccessesMap,
    (
      state: UserState,
      { payload }: ActionType<typeof actions.setAccessesMap>,
    ): UserState => ({
      ...state,
      accessesMap: payload,
    }),
  );
