import { SagaIterator } from "redux-saga";
import { call, fork, put, takeLatest } from "redux-saga/effects";

import * as actions from './actions';

import api, { ApiResponse } from '../../services/api';
import { ButterflyAvailabilityResponse } from "../../types/requests/Butterfly";
import { ErrorAction } from "../reducers";
import i18n from "../../services/i18n";

function* checkAvailability(): SagaIterator {
  yield takeLatest(actions.checkAvailability.request, function* () {
    try {
      const response: ApiResponse<ButterflyAvailabilityResponse> = yield call(api.getButterflyAvailability);
      yield put(actions.checkAvailability.success(response.data));
    } catch (err) {
      yield put(
        actions.checkAvailability.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.BUTTERFLY_AVAILABILITY") })),
        ),
      );
    }
  });
}

export default function* butterflySagas(): SagaIterator {
  yield fork(checkAvailability);
}
