import { ResolutionTypeBackend } from "../../types/backendModels/ResolutionBackend";

export const mockResolutionTypes: ResolutionTypeBackend[] = [
  {
    id: "2",
    title: "OK",
    is_editable: false,
  },
  {
    id: "3",
    title: "Ошибка интеграции",
    is_editable: false,
  },
  {
    id: "4",
    title: "Ошибка модели",
    is_editable: false,
  },
  {
    id: "5",
    title: "Ошибка правила",
    is_editable: false,
  },
  {
    id: "6",
    title: "Другое",
    is_editable: false,
  },
];
