import { SagaIterator } from "redux-saga";
import {
  call,
  fork,
  put,
  takeLatest,
} from "redux-saga/effects";

import i18n from "../../services/i18n";
import { downloadBlobData, objCamelToSnake } from "../../services/helpers/utilities";
import {
  GetCallResultRequest,
  GetCallResultResponse,
  GetCallsDataRequest,
  GetCallsDataResponse,
  GetCallStatisticsRequest,
  GetCallStatisticsResponse,
} from "../../types/requests/Calls";
import { ErrorAction } from "../reducers";

import * as actions from './actions';
import apiService, { ApiResponse } from "../../services/api";
import { parseOutgoingCall } from "../../types/parsers/OutgoingCallParser";
import { CALLS_CHUNK_SIZE } from "../callDetails/reducer";
import { parseOutgoingCallResult } from "../../types/parsers/OutgoingCallResultParser";
import {
  CreateTaskRequest,
  CreateTaskResponse,
  GetTasksRequest,
  GetTasksResponse,
} from "../../types/requests/Task";
import { TaskType } from "../../types/models/Task";
import { parseTask } from "../../types/parsers/TaskParser";

import * as taskActions from '../tasks/actions';
import { getFilenameFromDispositionHeader } from "../files/sagas";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";

export const EXPORT_HISTORY_CHUNK_SIZE = 20;

function* loadCalls(): SagaIterator {
  yield takeLatest(actions.loadCalls.request, function* (action) {
    try {
      const params: GetCallsDataRequest = {
        ...objCamelToSnake(action.payload),
        limit: CALLS_CHUNK_SIZE,
        newest_first: true,
      };

      const response: ApiResponse<GetCallsDataResponse> = yield call(apiService.getCalls, params);

      yield put(actions.loadCalls.success({
        list: response.data.calls.map(parseOutgoingCall),
        total: response.data.total_calls_count,
        offset: action.payload.offset,
      }));
    } catch (err) {
      yield put(actions.loadCalls.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.LOAD"))));
    }
  });
}

function* loadCallResults(): SagaIterator {
  yield takeLatest(actions.loadCallResults.request, function* (action) {
    try {
      const params: GetCallResultRequest = {
        call_id: action.payload,
      };

      const response: ApiResponse<GetCallResultResponse> = yield call(
        apiService.getCallResult,
        params,
      );

      const result = response.status === 204 ?
        null :
        parseOutgoingCallResult(response.data);

      yield put(actions.loadCallResults.success({
        id: action.payload,
        value: result,
      }));
    } catch (err) {
      yield put(actions.loadCallResults.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.LOAD"), action.payload)));
    }
  });
}

function* loadExportHistory(): SagaIterator {
  yield takeLatest(actions.loadExportHistory.request, function* (action) {
    try {
      const { olderThan } = action.payload;
      const params: GetTasksRequest = {
        types: [TaskType.callResults],
        results_from_separate_calls: true,
        limit: EXPORT_HISTORY_CHUNK_SIZE,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(apiService.getTasks, params);
      const tasks = response.data.tasks.map(parseTask);
      yield put(actions.loadExportHistory.success({
        tasks,
        pagination: { olderThan },
      }));
    } catch (err) {
      yield put(actions.loadExportHistory.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* exportCalls(): SagaIterator {
  yield takeLatest(actions.exportCalls.request, function* (action) {
    try {
      const { filters, name } = action.payload;
      const params: CreateTaskRequest = {
        type: TaskType.callResults,
        params: {
          results_from_separate_calls: true,
          ...objCamelToSnake(filters),
        },
        description: name,
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(apiService.createTask, params);

      const task = parseTask(response.data);
      yield put(actions.exportCalls.success(task));
    } catch (err) {
      yield put(actions.exportCalls.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE"))));
    }
  });
}

function* exportVoxCalls(): SagaIterator {
  yield takeLatest(actions.exportVoxCalls.request, function* (action) {
    try {
      const { fromDate, toDate } = action.payload;
      const params: CreateTaskRequest = {
        type: TaskType.exportVoximplantResourcesUsage,
      };

      if (fromDate || toDate) {
        params.params = objCamelToSnake(action.payload);
      }

      const response: ApiResponse<CreateTaskResponse> = yield call(apiService.createTask, params);

      const task = parseTask(response.data);
      yield put(actions.exportVoxCalls.success(task));
      yield put(taskActions.loadVoxExportTasks.request({}));
    } catch (err) {
      yield put(actions.exportVoxCalls.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE"))));
    }
  });
}

function* loadCallStatistics(): SagaIterator {
  yield takeLatest(actions.loadCallStatistics.request, function* (action) {
    try {
      const { newerThan, olderThan } = action.payload;

      const params: GetCallStatisticsRequest = {
        query: {
          older_than: olderThan ?
            +dateLocalToMsc(new Date(olderThan)) :
            undefined,
          newer_than: newerThan ?
            +dateLocalToMsc(new Date(newerThan)) :
            undefined,
        },
      };

      const response: ApiResponse<GetCallStatisticsResponse> = yield call(
        apiService.getCallStatistics,
        params,
      );

      const filename = getFilenameFromDispositionHeader(response.headers['content-disposition']);
      downloadBlobData(response.data, filename);
    } catch (err) {
      yield put(actions.loadCallStatistics.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.LOAD"))));
    }
  });
}

export default function* callsDetailsSagas() {
  yield fork(loadCalls);
  yield fork(loadCallResults);
  yield fork(loadCallStatistics);
  yield fork(loadExportHistory);
  yield fork(exportCalls);
  yield fork(exportVoxCalls);
}
