import { Reducer } from "redux";
import { createReducer, ActionType } from "typesafe-actions";

import { OutgoingCall } from "../../types/models/OutgoingCall";
import { OutgoingCallResult } from "../../types/models/OutgoingCallResult";
import { Task } from "../../types/models/Task";
import { isLastChunk } from "../tasks/reducer";

import * as actions from "./actions";
import * as projectsActions from "../projects/actions";

type CallsState = {
  list: {
    value: OutgoingCall[];
    loading: boolean;
    total: number;
  },
  results: Record<OutgoingCall['id'], {
    value?: OutgoingCallResult | null;
    loading: boolean;
  }>,
  exportHistory: {
    list: Task[];
    loading: boolean;
    total: number;
  },
};

const initialState: CallsState = {
  list: {
    value: [],
    loading: false,
    total: 0,
  },
  results: {},
  exportHistory: {
    list: [],
    loading: false,
    total: 0,
  },
};

export const callsReducer: Reducer<CallsState> = createReducer<CallsState>(initialState)
  .handleAction(
    actions.loadCalls.request,
    (state: CallsState, { payload }: ActionType<typeof actions.loadCalls.request>): CallsState => ({
      ...state,
      list: {
        ...state.list,
        value: payload.offset ? [...state.list.value] : [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCalls.success,
    (state: CallsState, { payload }: ActionType<typeof actions.loadCalls.success>): CallsState => ({
      ...state,
      list: {
        ...state.list,
        value: payload.offset ? [...state.list.value, ...payload.list] : payload.list,
        total: payload.total,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCalls.failure,
    (state: CallsState): CallsState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCallResults.request,
    (
      state: CallsState,
      { payload }: ActionType<typeof actions.loadCallResults.request>,
    ): CallsState => ({
      ...state,
      results: {
        ...state.results,
        [payload]: {
          loading: true,
        },
      },
    }),
  )
  .handleAction(
    actions.loadCallResults.success,
    (
      state: CallsState,
      { payload }: ActionType<typeof actions.loadCallResults.success>,
    ): CallsState => ({
      ...state,
      results: {
        ...state.results,
        [payload.id]: {
          ...state.results[payload.id],
          loading: false,
          value: payload.value,
        },
      },
    }),
  )
  .handleAction(
    actions.loadCallResults.failure,
    (
      state: CallsState,
      { payload: error }: ActionType<typeof actions.loadCallResults.failure>,
    ): CallsState => {
      const { payload } = error;
      return {
        ...state,
        results: {
          ...state.results,
          [payload]: {
            ...state.results[payload],
            loading: false,
          },
        },
      };
    },
  )
  .handleAction(
    actions.loadExportHistory.request,
    (
      state: CallsState,
      { payload }: ActionType<typeof actions.loadExportHistory.request>,
    ): CallsState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        list: payload.olderThan ? [...state.exportHistory.list] : [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadExportHistory.success,
    (
      state: CallsState,
      { payload }: ActionType<typeof actions.loadExportHistory.success>,
    ): CallsState => {
      const { tasks, pagination } = payload;
      const noMoreTasks = isLastChunk(pagination, tasks);

      const newTaskList = pagination.olderThan ?
        [...state.exportHistory.list, ...payload.tasks] :
        payload.tasks;
      return {
        ...state,
        exportHistory: {
          ...state.exportHistory,
          list: newTaskList,
          total: noMoreTasks ? newTaskList.length : state.exportHistory.list.length,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadExportHistory.failure,
    (state: CallsState): CallsState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.exportCalls.request,
    (state: CallsState): CallsState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.exportCalls.success,
    (
      state: CallsState,
      { payload }: ActionType<typeof actions.exportCalls.success>,
    ): CallsState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        list: [payload, ...state.exportHistory.list],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.exportCalls.failure,
    (state: CallsState): CallsState => ({
      ...state,
      exportHistory: {
        ...state.exportHistory,
        loading: false,
      },
    }),
  )
  .handleAction(
    projectsActions.selectProject,
    () => initialState,
  );
