import React, { FC } from 'react';
import { SvgProps } from '../types';

const CheckmarkIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.716 4.38594C19.8816 4.18247 20.1205 4.0522 20.3812 4.02322C20.6419 3.99423 20.9035 4.06885 21.1098 4.23098C21.316 4.39312 21.4502 4.62979 21.4836 4.88998C21.5169 5.15017 21.4467 5.41304 21.288 5.62194L10.665 19.1359C10.5275 19.3109 10.3528 19.453 10.1535 19.552C9.95425 19.651 9.73546 19.7045 9.51298 19.7085C9.29051 19.7125 9.06993 19.667 8.86723 19.5753C8.66453 19.4835 8.48478 19.3478 8.34101 19.1779L3.23702 13.1459C3.14864 13.0462 3.08108 12.9299 3.0383 12.8037C2.99552 12.6775 2.97839 12.5441 2.98791 12.4112C2.99742 12.2783 3.0334 12.1486 3.09373 12.0298C3.15405 11.9111 3.2375 11.8055 3.33919 11.7194C3.44087 11.6333 3.55873 11.5684 3.68584 11.5285C3.81296 11.4886 3.94677 11.4745 4.07941 11.4871C4.21205 11.4996 4.34085 11.5385 4.45824 11.6015C4.57564 11.6645 4.67926 11.7503 4.76302 11.8539L9.47101 17.4179L19.716 4.38594Z" fill={color} />
  </svg>

);

export default CheckmarkIcon;
