import React from 'react';

import { SvgProps } from '../types';

function BookIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C4.355 1 3 2.355 3 4V20C3 21.645 4.355 23 6 23H20C20.2652 23 20.5196 22.8946 20.7071 22.7071C20.8946 22.5196 21 22.2652 21 22C21 21.7348 20.8946 21.4804 20.7071 21.2929C20.5196 21.1054 20.2652 21 20 21H6C5.434 21 5 20.566 5 20C5 19.434 5.434 19 6 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1H6ZM6 3H19V17H6C5.65829 17.0043 5.31993 17.0679 5 17.188V4C5 3.434 5.434 3 6 3ZM8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H16C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6H9C8.73478 6 8.48043 6.10536 8.29289 6.29289C8.10536 6.48043 8 6.73478 8 7C8 7.26522 8.10536 7.51957 8.29289 7.70711Z"
        fill={color}
      />
    </svg>
  );
}

export default BookIcon;
