import { PreviousAnswer } from "../../types/models/PreviousAnswer";
import { AppState } from "../reducers";

export const getPreviousAnswerFilters = (state: AppState) => {
  return state.previousAnswer.filters;
};

export const getPreviousAnswers = (state: AppState) => {
  return state.previousAnswer.list.value;
};

export const getPreviousAnswerDialogs = (
  state: AppState,
  id: PreviousAnswer['id'],
) => {
  return state.previousAnswer.dialogs.value
    .filter(d => d.answerId === id)
    .map(d => ({
      ...d,
      scenariosList: [],
    }));
};

export const getActiveDialogId = (
  state: AppState,
): string | null => {
  return state.previousAnswer.activeDialog;
};
