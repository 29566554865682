import {
  useCallback,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import block from 'bem-cn';

import { ModalProps } from '../../services/modal';

import * as graphActions from "../../redux/graph/actions";

import Select, { SelectConfig } from '../../components-new/select/configurable';

import Modal from '../components/ndModal';
import './styles.scss';
import { FLOATING_PLACEHOLDER_COMPONENTS } from '../../components-new/select/configs/floatingPlaceholderComponents';
import Input from '../../components-new/input';
import { ScenarioGroupName } from '../../types/backendModels/ScenarioGraphBackend';
import Checkbox from '../../components-new/checkbox';

const b = block('nd-scenario-create-modal');

export type CreateScenarioModalProps = {
  className?: string,
};

export default function CreateScenarioModal({
  className = '',
  close,
}: CreateScenarioModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState<ScenarioGroupName>();
  const [openScenario, setOpenScenario] = useState(false);

  const [graphTitle, setGraphTitle] = useState('');

  const groups = [
    ScenarioGroupName.greeting,
    ScenarioGroupName.main,
    ScenarioGroupName.fallback,
    ScenarioGroupName.pre,
    ScenarioGroupName.feedback,
  ];
  const handleSave = useCallback(
    () => {
      const data: graphActions.GraphActionParams = {
        id: "",
        extraModelTopics: [],
        links: [],
        nodes: [],
        automatable: true,
        title: graphTitle,
        groupName: selected,
        openGraph: openScenario,
        openInNewWindow: true,
      };
      dispatch(
        graphActions.createGraph.request({ ...data, saveDefaultPredicate: true }),
      );
    },
    [dispatch, graphTitle, selected, openScenario],
  );

  const handleConfirmClick = useCallback(
    () => {
      if (!selected || !graphTitle) return;

      handleSave();
      close();
    },
    [selected, close, graphTitle, handleSave],
  );

  const selectConfig: SelectConfig = {
    options: groups,
    labelFunction: (option: ScenarioGroupName) => t(`GRAPH.TYPE_SELECT.${option.toUpperCase()}.TITLE`),
    valueFunction: (option: ScenarioGroupName) => option,
  };

  const handleChange = useCallback(
    (groupName: ScenarioGroupName) => {
      setSelected(groupName);
    },
    [],
  );

  const handleChangeTitle = useCallback(
    (_, { value }) => {
      setGraphTitle(value);
    },
    [],
  );

  const handleCheckbox = useCallback(
    () => {
      setOpenScenario(!openScenario);
    },
    [openScenario],
  );

  return (
    <Modal
      className={`${b()} ${className}`}
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.CREATE')}
      title={t('COMMON.CREATE_SCENARIO')}
      onConfirm={handleConfirmClick}
      confirmDisabled={!selected || !graphTitle}
    >
      <Input
        value={graphTitle}
        onChange={handleChangeTitle}
        name="featureSearch"
        placeholder={t('GRAPH.SCENARIO_EDITOR.TITLE_PLACEHOLDER_MODAL')}
        floatingPlaceholder
      />
      <Select
        className={b('select')}
        fluid
        placeholder={t('GRAPH.TYPE_SELECT.PLAСEHOLDER')}
        config={selectConfig}
        value={selected?.toString() || ""}
        usePortal
        onChange={handleChange}
        components={FLOATING_PLACEHOLDER_COMPONENTS}
      />
      <Checkbox
        name="openScenario"
        value={openScenario}
        onChange={handleCheckbox}
        label={t('MODALS.SCENARIO_SELECT.GO_TO_SCENARIO')}
      />
    </Modal>
  );
}
