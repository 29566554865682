import React, { FC, memo } from 'react';
import { block } from 'bem-cn';

import './styles.scss';

const b = block('side-modal-content-island');

const ContentIsland: FC<{
  className?: string;
  notSubtitle?: boolean;
  children?: React.ReactNode
}> = ({ className = '', notSubtitle = false, children }) => (
  <div className={`${b({ 'not-subtitle': notSubtitle })} ${className}`}>
    {children}
  </div>
);

export const ContentIslandLabel: FC<{
  error?: boolean,
  className?: string;
  children?: React.ReactNode
}> = ({ className = '', error, children }) => (
  <div className={`${b('label', { error })} ${className}`}>
    {children}
  </div>
);

export default memo(ContentIsland);
