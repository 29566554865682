/* eslint-disable camelcase */
import { AuthDataBackend } from '../backendModels/ProjectAuthDataBackend';
import { AuthData, createIpAddress } from '../models/ProjectAuthData';

export const parseAuthData = ({ api_key, ip_addresses }: AuthDataBackend): AuthData => ({
  apiKey: api_key,
  ipAddresses: ip_addresses.map(item => createIpAddress(item)),
});

export const parseAuthDataToBackend = ({ apiKey, ipAddresses }: AuthData): AuthDataBackend => ({
  api_key: apiKey,
  ip_addresses: ipAddresses.map(({ value }) => value),
});

export const parseIpAddressesToBackend = (ipAddresses: AuthData['ipAddresses']): string[] => (
  ipAddresses.map(({ value }) => value)
);
