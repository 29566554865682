import { isValid } from 'date-fns';
import { objSnakeToCamelDeep } from '../../services/helpers/utilities';
import { TaskBackend } from '../backendModels/TaskBackend';
import { Task, TaskProcessingState, TaskStatus, TaskType } from '../models/Task';
import { parseProjectUser } from './ProjectUserParser';
import { dateMscToLocal } from '../../services/helpers/dateUtils';

export function parseTask(task: TaskBackend): Task {
  const created = isValid(new Date(task.created)) ?
    dateMscToLocal(new Date(task.created)).toString() :
    task.created;

  const ended = (task.ended && isValid(new Date(task.ended))) ?
    dateMscToLocal(new Date(task.ended)).toString() :
    task.created;

  return {
    id: task.id,
    projectSlug: task.project_slug,
    s3FileId: task.s3_file_id,
    created,
    ended,
    type: task.type as TaskType,
    status: task.status as TaskStatus,
    errorMessage: task.error_message,
    params: objSnakeToCamelDeep(task.params),
    stqId: task.stq_id,
    processingState: task.processing_state as TaskProcessingState,
    ...(task.name && { name: task.name }),
    ...(task.user && { user: parseProjectUser(task.user) }),
    ...(task.progress && { progress: task.progress }),
    ...(task.scheduled_on && { scheduledOn: task.scheduled_on }),
    ...(task.description && { description: task.description }),
  };
}
