import React, { FC } from 'react';
import { SvgProps } from '../types';

const PhoneIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8198 8.05523C14.6267 8.45926 16.1129 9.94656 16.6103 10.4443C16.6103 10.6434 14.6208 13.0324 13.825 13.8288C13.0292 14.6251 10.6418 16.616 10.4429 16.616C9.94626 16.1182 8.45335 14.6251 8.05546 14.8242C7.65756 15.0233 3.57908 16.7155 3.18118 16.9146C2.78328 17.1137 3.08167 19.2042 3.67856 19.8014C4.27544 20.3986 8.15493 23.6836 15.914 15.9192C23.673 8.15478 20.4128 4.2956 19.7935 3.6753C19.2028 3.08361 17.1135 2.78526 16.9087 3.17758C16.7156 3.58161 15.0246 7.66291 14.8198 8.05523Z"
      fill={color}
    />
  </svg>
);

export default PhoneIcon;
