import React, { FC } from 'react';
import { SvgProps } from '../types';

const SearchIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49994 17C11.2099 17 12.7869 16.427 14.0499 15.463L18.7929 20.207C18.9815 20.3892 19.2341 20.49 19.4963 20.4877C19.7585 20.4854 20.0094 20.3802 20.1948 20.1948C20.3802 20.0094 20.4853 19.7586 20.4876 19.4964C20.4899 19.2342 20.3891 18.9816 20.2069 18.793L15.4629 14.049C16.4893 12.7036 17.0305 11.0507 16.9987 9.3589C16.9668 7.66704 16.3638 6.03568 15.2876 4.72988C14.2113 3.42409 12.7251 2.52062 11.0705 2.16628C9.41587 1.81195 7.69004 2.02757 6.17344 2.77811C4.65684 3.52865 3.43862 4.77 2.71673 6.30044C1.99485 7.83089 1.81172 9.56048 2.19711 11.2082C2.58251 12.8558 3.51377 14.3248 4.83957 15.3763C6.16537 16.4277 7.80779 17 9.49994 17ZM14.9999 9.50001C14.9999 10.9587 14.4205 12.3576 13.389 13.3891C12.3576 14.4205 10.9586 15 9.49994 15C8.04125 15 6.64231 14.4205 5.61086 13.3891C4.57941 12.3576 3.99994 10.9587 3.99994 9.50001C3.99994 8.04132 4.57941 6.64237 5.61086 5.61092C6.64231 4.57947 8.04125 4.00001 9.49994 4.00001C10.9586 4.00001 12.3576 4.57947 13.389 5.61092C14.4205 6.64237 14.9999 8.04132 14.9999 9.50001Z"
      fill={color}
    />
  </svg>
);

export default SearchIcon;
