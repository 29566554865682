import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Input, { InputVisualStyle } from '../input';

import PopupWithTrigger from '../popupWithTrigger';
import {
  changeTime,
  formatPeriodDate,
  getInitialInputValue,
  isDatesDifferent,
  isValidInputValue,
  parseInputValueToDate,
} from './helpers';

type DatePickerInputProps = {
  dateTo?: string;
  placeholderText?: string;
  selectsRange?: boolean;
  clearable?: boolean;
  isOpen?: boolean;
  visualStyle?: Exclude<InputVisualStyle, 'underline'>;
  onOpen?: () => void;
}

function DatePickerInput(
  {
    selectsRange,
    dateFrom,
    dateTo,
    value,
    placeholderText,
    clearable,
    onOpen,
    isOpen,
    visualStyle,
    onCustomChange,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: any & DatePickerInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const [inputValue, setInputValue] = useState<string>(getInitialInputValue(
    dateFrom,
    dateTo,
    value,
    selectsRange,
  ));

  const resetInputValue = useCallback(
    () => {
      setInputValue(getInitialInputValue(
        dateFrom,
        dateTo,
        value,
        selectsRange,
      ));
    },
    [
      dateFrom,
      dateTo,
      selectsRange,
      value,
    ],
  );

  const emitValue = useCallback(
    (newVal?: string) => {
      if (selectsRange) {
        if (!inputValue && clearable) {
          onCustomChange([null, null]);
          return;
        }
        const valid = isValidInputValue(inputValue);
        if (!valid) {
          resetInputValue();
          return;
        }

        const inputDates = inputValue.split('-');
        let newDateFrom = parseInputValueToDate(inputDates[0]);
        let newDateTo = inputDates[1].trim() ? parseInputValueToDate(inputDates[1].trim()) : null;

        newDateFrom = dateFrom ?
          changeTime(dateFrom, newDateFrom) :
          newDateFrom;
        newDateTo = dateTo ?
          changeTime(dateTo, newDateTo) :
          newDateTo;

        if (!!newDateTo && !!newDateFrom && (newDateTo < newDateFrom)) newDateTo = null;

        if (!newDateFrom) {
          return;
        }

        if (isDatesDifferent(newDateFrom, dateFrom) ||
            isDatesDifferent(newDateTo, dateTo)
        ) {
          onCustomChange([newDateFrom, newDateTo]);
        }
        return;
      }

      const newValue = parseInputValueToDate(newVal ?? inputValue);

      if (!newValue) {
        onCustomChange(null);
        return;
      }

      onCustomChange(newValue);
    },
    [
      selectsRange,
      inputValue,
      onCustomChange,
      dateFrom,
      dateTo,
      clearable,
      resetInputValue,
    ],
  );

  const onFocus = useCallback(
    () => {
      onOpen();
    },
    [onOpen],
  );

  const onBlur = useCallback(
    e => {
      e.preventDefault();
      emitValue();
    },
    [emitValue],
  );

  const handleInputChange = useCallback(
    (_, { value: newValue }) => {
      setInputValue(newValue);

      // Обработка нажатия на clear button
      if (!newValue && document.activeElement?.tagName !== "INPUT") {
        emitValue(newValue);
      }
    },
    [emitValue],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.code === '13') {
        emitValue();
      }
    },
    [emitValue],
  );

  const { fullText, text } = useMemo(
    () => {
      const params = {
        dateFrom: selectsRange ? dateFrom : value,
        dateTo,
      };

      return {
        fullText: formatPeriodDate({ fullText: true, ...params }),
        text: formatPeriodDate({ ...params }),
      };
    },
    [dateFrom, dateTo, selectsRange, value],
  );

  useEffect(
    () => {
      setInputValue(getInitialInputValue(
        dateFrom,
        dateTo,
        value,
        selectsRange,
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateFrom, dateTo, value],
  );

  return (
    <PopupWithTrigger
      withWrapper
      disableHoverListener={isOpen}
      disableClickListener
      text={fullText}
      trigger={
        <Input
          ref={ref}
          name="date"
          size="medium"
          icon="calendarOutline"
          iconPosition="right"
          onFocus={onFocus}
          onBlur={onBlur}
          clearable={clearable}
          placeholder={placeholderText}
          value={isOpen ? inputValue : text}
          floatingPlaceholder
          visualStyle={visualStyle}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      }
     />
  );
}

export default React.forwardRef(DatePickerInput);
