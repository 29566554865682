import { FiltersWithDirection } from '../../pages/account/components/callsTab';
import { Filters } from '../../pages/account/components/filters';
import { DialogsStatisticsGroup } from '../../types/models/AutomationStatistics';
import { isVoiceProject, Project } from '../../types/models/Project';
import { CallService } from '../../types/models/ProjectConfig';
import { COUNTER_PREFIX } from '../../types/parsers/AutomationStatistics';
import { AppState } from '../reducers';

export const getSelectedProject = (state: AppState) => state.projects.selectedProject;
export const getSelectedProjectSlug = (state: AppState) => state.projects.selectedProject?.slug || '';
export const getSelectedProjectName = (state: AppState) => state.projects.selectedProject?.title || '';

export const getIsSelectedProjectVoice = (state: AppState) => {
  return isVoiceProject(state.projects.selectedProject);
};

export const getProjectById = (
  state: AppState,
  projectId: Project['id'],
): Project | undefined => (
  state.projects.list.find(project => project.id === projectId)
);

export const getProjectBySlug = (
  state: AppState,
  projectSlug: string,
): Project | undefined => (
  state.projects.list.find(project => project.slug === projectSlug)
);

export const getProjects = (state: AppState) => state.projects.list;

export const getParentProjects = ({ projects }: AppState) => (
  projects.list.filter(p => !!p.is_template)
);

export const getProjectsLoading = (state: AppState) => state.projects.loading;

export const getTableTopicStatistics = (
  state: AppState,
): DialogsStatisticsGroup[] => (
  state.projects.tableTopicsStatistics
);

export const getStatisticsLoadingStatus = (state: AppState) => state.projects.statisticsLoading;

export const getCallsTableStatistics = (state: AppState) => state.projects.callsTableStatistics;
export const getCallsChartStatistics = (state: AppState) => state.projects.callsChartStatistics;
export const getCallsStatisticsLoading = (state: AppState) => state.projects.callsStatisticsLoading;

export const getCallsDetailsStatistics = (state: AppState) => state.projects.callsDetailsStatistics;

export const getCallsService = ({ projects }: AppState) => (
  projects.config.dispatcherParams.callService
);

export const callsServiceIsVoximplant = ({ projects }: AppState) => (
  projects.config.dispatcherParams.callService === CallService.voximplant
);

export const getGroupedProjects = ({ projects }: AppState) => {
  const result: Record<Project['id'], Project> = {};

  projects.list.forEach(p => {
    result[p.id] = p;
  });

  return result;
};

export const getProjectStatisticsFilters = ({
  projects,
}: AppState): Filters | FiltersWithDirection => (projects.statisticsFilters);

export const getIsProjectCreating = ({
  projects,
}: AppState) => (projects.creating);

export const getActiveStatisticsTab = ({
  projects,
}: AppState) => (
  projects.statisticsTab
);

export const getExportHistoryOpenedStatus = ({
  projects,
}: AppState) => (
  projects.exportHistoryOpened
);

export function getUserRatesRange({
  projects,
}: AppState): number [] | undefined {
  if (projects.tableTopicsStatistics.length === 0) return undefined;

  const firstItem = projects.tableTopicsStatistics.slice(-1)[0];

  return Object.keys(firstItem.userScoreCounters || {})
    .map(score => +score.slice(COUNTER_PREFIX.length)) // Обрезаем слово counter
    .filter(s => !!s && !isNaN(s));
}
