import { getType } from 'typesafe-actions';
import { CacheItem } from '../../services/cache/cacheItem';
import cacheService from '../../services/cache/cacheService';

import * as dialogActions from '../dialogs/actions';
import * as sidebarActions from '../sidebar/actions';
import * as projectActions from '../projects/actions';
import * as versionsActions from '../versions/actions';
import * as scenariosActions from '../scenarioGraphs/actions';

import { AppState } from '../reducers';

type ActionCacheConfig = {
  cacheItem: CacheItem,
  stateCallback?: (state: AppState) => unknown,
}

const cacheConfig: { [key: string]: Array<ActionCacheConfig>} = {
  [getType<string>(dialogActions.setDialogsHistoryFilter)]: [
    {
      cacheItem: CacheItem.DIALOGS_HISTORY_FILTER,
      stateCallback: state => state.dialogs.searchFilter,
    }],
  [getType<string>(sidebarActions.openItem)]: [
    {
      cacheItem: CacheItem.SIDEBAR_OPENED_ITEMS,
      stateCallback: state => state.sidebar.openedItems,
    },
  ],
  [getType<string>(sidebarActions.closeItem)]: [
    {
      cacheItem: CacheItem.SIDEBAR_OPENED_ITEMS,
      stateCallback: state => state.sidebar.openedItems,
    },
  ],
  [getType<string>(projectActions.updateFilters)]: [
    {
      cacheItem: CacheItem.PROJECT_STATISTICS,
    },
  ],
  [getType<string>(dialogActions.setFeatureDispatchValues)]: [
    {
      cacheItem: CacheItem.DISPATCH_FEATURES,
      stateCallback: state => state.dialogs.liveDialog.features,
    },
  ],
  [getType<string>(dialogActions.setFeatureDispatchValue)]: [
    {
      cacheItem: CacheItem.DISPATCH_FEATURES,
      stateCallback: state => state.dialogs.liveDialog.features,
    },
  ],
  [getType<string>(dialogActions.resetFeatures)]: [
    {
      cacheItem: CacheItem.DISPATCH_FEATURES,
      stateCallback: state => state.dialogs.liveDialog.features,
    },
  ],
  [getType<string>(dialogActions.setSearchDialogsHistoryFilter)]: [
    {
      cacheItem: CacheItem.DIALOGS_HISTORY_NEW_FILTER,
      stateCallback: state => state.dialogs.newSearchFilter,
    }],
  [getType<string>(versionsActions.changeVersionToDisplay)]: [
    {
      cacheItem: CacheItem.VERSION,
    },
  ],
  [getType<string>(dialogActions.setShowDialogTiming)]: [
    {
      cacheItem: CacheItem.SHOW_DIALOG_TIMING,
    },
  ],
  [getType<string>(scenariosActions.setSearchScenariosFilter)]: [
    {
      cacheItem: CacheItem.SCENARIOS_FILTER,
      stateCallback: state => state.scenarioGraphs.filters,
    }],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cacheMiddleware = (store: any) => (next: any) => (action: any) => {
  const actionType = action.type;

  const useCache = !!cacheConfig[actionType];

  let actionDispatched = false;

  if (useCache) {
    const actionConfigs = cacheConfig[actionType];
    // eslint-disable-next-line no-restricted-syntax
    for (const config of actionConfigs) {
      const { cacheItem, stateCallback } = config;

      if (stateCallback) {
        if (!actionDispatched) {
          actionDispatched = true;
          next(action);
        }

        const nextState = store.getState();
        const objectToCache = stateCallback(nextState);
        cacheService.setItem(cacheItem, objectToCache);
      } else {
        const { payload } = action;
        cacheService.setItem(cacheItem, payload);
      }
    }
  }

  return actionDispatched ? action : next(action);
};
