import authorizedPages from "../../routes/authorized";

import { ProjectType } from "../../types/models/Project";

import { store } from "../../redux/store";

import { IconTypes } from "../../components-new/icon";
import { UserRole } from "../../types/models/UserAccesses";
import accessService, { Access } from "../../services/access";
import { stringToBoolean } from "../../services/helpers/utilities";
import { UserConfigs } from "../../types/codegen/experiments";
import { getConfig } from "../../redux/user/selectors";

type GuardFunction = () => boolean;

function superadminGuard(): GuardFunction {
  return () => {
    return accessService.checkIfCurrentUserSuperadmin();
  };
}

function projectTypeGuard(types: ProjectType | ProjectType[]): GuardFunction {
  return () => {
    const list = Array.isArray(types) ? types : [types];
    const selectedProject = store.getState().projects.selectedProject;

    return list.includes(selectedProject.type);
  };
}

function userRoleGuard(minRole: UserRole): GuardFunction {
  return () => {
    return accessService.checkIfCurrentRoleNotLessThan(minRole);
  };
}

function accessGuard(access: Access): GuardFunction {
  return () => {
    return accessService.currentUserHasAccessTo(access);
  };
}

function envGuard(key: string) {
  return () => {
    if (!key) return false;

    return stringToBoolean(process.env[key] || "");
  };
}

function configGuard(key: keyof UserConfigs) {
  return () => {
    const config = getConfig(store.getState(), key);

    return !!config?.enabled;
  };
}

export type SidebarItem = {
  id: string,
  label: string,
  url?: string,
  icon?: IconTypes,
  children?: SidebarItem[],
  open?: boolean,
  guards?: GuardFunction[],
}

export let sidebarHierarchy: SidebarItem[] = [
  {
    id: "SCENARIOS",
    label: "SIDEBAR.GRAPH_SELECT",
    icon: "route",
    url: authorizedPages.graphSelect,
    guards: [
      accessGuard(Access.scenariosScenario),
    ],
  },
  {
    id: "DIALOGS_HISTORY",
    label: "SIDEBAR.DIALOGS_HISTORY",
    icon: "commentOutline",
    url: authorizedPages.dialogsHistory,
    guards: [accessGuard(Access.dialogsHistory)],
  },
  {
    id: "DIALOGS_HISTORY_OLD",
    label: "SIDEBAR.DIALOGS_HISTORY_OLD",
    icon: "commentOutline",
    url: authorizedPages.newDialogsHistory,
    guards: [
      accessGuard(Access.dialogsHistory),
      envGuard("REACT_APP_SHOW_OLD_DIALOGS_HISTORY"),
    ],
  },
  // {
  //   id: "DIALOG_MARKUP",
  //   label: "SIDEBAR.DIALOG_MARKUP",
  //   icon: "listChecked",
  //   url: authorizedPages.quality,
  //   guards: [
  //     accessGuard(Access.dialogsMarkup),
  //   ],
  // },
  {
    id: "QUALITY_CONTROL",
    label: "SIDEBAR.QUALITY_CONTROL",
    icon: "listChecked",
    url: authorizedPages.qualityControl,
    guards: [
      accessGuard(Access.dialogsMarkup),
    ],
  },
  {
    id: "VOICE",
    label: "SIDEBAR.VOICE",
    icon: "headphones",
    url: authorizedPages.voice,
    guards: [
      projectTypeGuard([
        ProjectType.voiceIncoming,
        ProjectType.voiceOutgoing,
      ]),
      accessGuard(Access.voice),
    ],
  },
  {
    id: "STATISTICS",
    label: "SIDEBAR.STATISTICS",
    icon: "arrowDashboard",
    url: authorizedPages.account,
    guards: [
      accessGuard(Access.statistics),
    ],
  },
  {
    id: "AUTOMATION_RISE",
    label: "SIDEBAR.AUTOMATION_RISE",
    icon: "arrowUp",
    url: authorizedPages.automationRise,
    guards: [
      configGuard("supportai-frontend:rise-of-automation"),
    ],
  },
  {
    id: "VERSIONS",
    label: "SIDEBAR.VERSIONS",
    icon: "timeOutline",
    url: authorizedPages.manageVersions,
    guards: [
      accessGuard(Access.version),
    ],
  },
  {
    id: "OTHER.THRESHOLDS",
    label: "SIDEBAR.THRESHOLDS",
    url: authorizedPages.tresholds,
    icon: 'boxOutline',
    guards: [
      accessGuard(Access.models),
    ],
  },
  {
    id: "FEATURES",
    label: "SIDEBAR.FEATURES",
    icon: "book",
    children: [
      {
        id: "FEATURES.LIST",
        label: "SIDEBAR.FEATURES",
        url: authorizedPages.features,
        guards: [
          accessGuard(Access.featuresFeature),
        ],
      }, {
        id: "FEATURES.ENTITIES",
        label: "SIDEBAR.ENTITIES",
        url: authorizedPages.entities,
        guards: [
          accessGuard(Access.featuresEntity),
        ],
      },
    ],
  },
  {
    id: "FOR_DEVELOPERS",
    label: "SIDEBAR.FOR_DEVELOPERS",
    icon: "code",
    guards: [
      userRoleGuard(UserRole.standard),
    ],
    children: [
      {
        id: "FOR_DEVELOPERS.PROJECTS",
        label: "SIDEBAR.PROJECTS_LIST",
        url: authorizedPages.projectsList,
        guards: [superadminGuard()],
      }, {
        id: "FOR_DEVELOPERS.ADVANCED",
        label: "SIDEBAR.ADDITIONAL",
        url: authorizedPages.additional,
        guards: [
          accessGuard(Access.configCustom),
        ],
      }, {
        id: "FOR_DEVELOPERS.TASKS",
        label: "SIDEBAR.TASKS",
        url: authorizedPages.tasks,
        guards: [
          superadminGuard(),
        ],
      },
    ],
  },
  {
    id: "SETTINGS",
    label: "SIDEBAR.SETTINGS",
    icon: "settings",
    children: [
      {
        id: "SETTINGS.INTEGRATIONS",
        label: "SIDEBAR.INTEGRATIONS",
        url: authorizedPages.integrations,
        guards: [
          accessGuard(Access.integrationsOutgoing),
        ],
      }, {
        id: "OTHER.WIDGET_PANEL",
        label: "SIDEBAR.WIDGET_PANEL",
        url: authorizedPages.widgetPanel,
        guards: [
          accessGuard(Access.widget),
        ],
      }, {
        id: "SETTINGS.CHANGE_HISTORY",
        label: "SIDEBAR.CHANGE_HISTORY",
        url: authorizedPages.changeHistory,
        guards: [
          accessGuard(Access.changes),
        ],
      }, {
        id: "SETTINGS.AUTH_PROVIDERS",
        label: "SIDEBAR.AUTH_PROVIDERS",
        url: authorizedPages.authProviders,
        // Если есть флаг REACT_APP_DISABLE_PROVIDERS, не рендерим этот пункт меню
        guards: !process.env.REACT_APP_DISABLE_PROVIDERS ? [superadminGuard()] : [() => false],
      }, {
        id: "SETTINGS.ACCESSES_NEW",
        label: "SIDEBAR.ACCESS",
        url: authorizedPages.accessesNew,
        guards: [
          accessGuard(Access.accessesUsers),
        ],
      },
    ],
  },
  // {
  //   id: "BILLING",
  //   label: "SIDEBAR.BILLING",
  //   icon: "coinsPlus",
  //   url: authorizedPages.billing,
  //   guards: [
  //     superadminGuard(),
  //   ],
  // },
];

// Показываем вкладку "Суперпользователи", только если запущено из коробки (возможно, решение костыльное,
// но т.к. единичный случай, не хочется пилить/поддерживать какой-то отдельный механизм для коробочных страниц)
if (process.env.REACT_APP_ENVIRONMENT_ID === "box") {
  const developersTabIndex = sidebarHierarchy.findIndex(i => i.id === "FOR_DEVELOPERS");
  if (developersTabIndex !== -1) {
    (sidebarHierarchy[developersTabIndex].children as SidebarItem[]).push({
      id: "FOR_DEVELOPERS.SUPERUSERS",
      label: "SIDEBAR.SUPERUSERS",
      url: authorizedPages.superusers,
      guards: [
        superadminGuard(),
      ],
    });
  }
}

export const getConfigAsPlainArray = (): SidebarItem[] => sidebarHierarchy.reduce<SidebarItem[]>(
  (result: SidebarItem[], item: SidebarItem) => {
    result.push(item);
    if (item.children) {
      // eslint-disable-next-line no-param-reassign
      result = result.concat(item.children);
    }

    return result;
  },
  [],
);

export const sidebarHierarchyAsPlainArray = getConfigAsPlainArray();
