import { NodeTypes } from "../../pages/graphEditor/types";

export type Tag = {
  id: string,
  slug: string,
  countUses?: number,
}

export type TagInfo = {
  id: number;
  slug: string;
  nodes: Array<TagNodeInfo>;
}

export type TagNodeInfo = {
  nodeId?: string;
  nodeTitle?: string;
  nodeType?: NodeTypes;
  scenarioId?: number;
  scenarioTitle?: string;
  scenarioSlug?: string;
  isScenarioActive?: boolean;
}

export function getEmptyTag(): Tag {
  return {
    id: '',
    slug: '',
  };
}

export function tagEmpty(tag: Tag): boolean {
  const emptyTag = getEmptyTag();
  return tag.id === emptyTag.id;
}

export function getEmptyTagInfo(): TagInfo {
  return {
    id: 0,
    slug: "",
    nodes: [],
  };
}
