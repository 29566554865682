import { GraphDebugBlock } from "../../types/models/GraphDebugBlock";

export function mergeGraphDebugBlocks(
  target: GraphDebugBlock,
  blocks: GraphDebugBlock | GraphDebugBlock[] = [],
): GraphDebugBlock {
  if (Array.isArray(blocks)) {
    if (blocks.length === 0) return target;

    return mergeTwoBlocks(
      target,
      blocks.reduce<GraphDebugBlock>((result, block) => {
        result = mergeTwoBlocks(result, block);

        return result;
      }, blocks[0]),
    );
  }

  return mergeTwoBlocks(target, blocks);
}

function mergeTwoBlocks(target: GraphDebugBlock, source: GraphDebugBlock): GraphDebugBlock {
  const error = target.type === "error" || source.type === "error";

  return {
    ...target,
    type: error ? "error" : "info",
    descriptions: mergeArrays(target.descriptions, source.descriptions),
    features: mergeArrays(target.features, source.features),
    extra: mergeArrays(target.extra, source.extra),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mergeArrays(target: Array<any> | undefined, source: Array<any> | undefined) {
  return (target ?? []).concat(source ?? []);
}

export function concatBlocks(
  oldBlocks: GraphDebugBlock[] = [],
  newBlocks: GraphDebugBlock[] = [],
): GraphDebugBlock[] {
  const lastOldBlock = oldBlocks[oldBlocks.length - 1];
  const firstNewBlock = newBlocks[0];

  if (!firstNewBlock) return oldBlocks;

  if (!lastOldBlock) return newBlocks;

  if (!!lastOldBlock && lastOldBlock.nodeId === firstNewBlock.id) {
    return oldBlocks.slice(0, -1).concat(newBlocks);
  }

  return oldBlocks.concat(newBlocks);
}
