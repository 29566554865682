import { ActionType, createReducer } from "typesafe-actions";
import { Reducer } from "redux";

import { AllowedDomain } from "../../types/models/Domains";

import * as actions from './actions';

type DomainsState = {
  list: AllowedDomain[];
  loading: boolean;
  activeDomain: AllowedDomain['id'] | null,
}

const initialState: DomainsState = {
  list: [],
  loading: false,
  activeDomain: null,
};

export const domainsReducer: Reducer<DomainsState> = createReducer(initialState)
  .handleAction(
    actions.setDomainToEdit,
    (
      state: DomainsState,
      { payload: domainId }: ActionType<typeof actions.setDomainToEdit>,
    ): DomainsState => ({
      ...state,
      activeDomain: domainId,
    }),
  )
  .handleAction(
    actions.loadAllowedDomains.request,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadAllowedDomains.success,
    (
      state: DomainsState,
      { payload }: ActionType<typeof actions.loadAllowedDomains.success>,
    ): DomainsState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadAllowedDomains.failure,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createAllowedDomain.request,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.createAllowedDomain.success,
    (
      state: DomainsState,
      { payload }: ActionType<typeof actions.createAllowedDomain.success>,
    ): DomainsState => ({
      ...state,
      list: [payload, ...state.list],
      loading: false,
    }),
  )
  .handleAction(
    actions.createAllowedDomain.failure,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.deleteAllowedDomain.request,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.deleteAllowedDomain.success,
    (
      state: DomainsState,
      { payload }: ActionType<typeof actions.deleteAllowedDomain.success>,
    ): DomainsState => ({
      ...state,
      list: state.list.filter(domain => domain.id !== payload),
      loading: false,
    }),
  )
  .handleAction(
    actions.deleteAllowedDomain.failure,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.updateAllowedDomain.request,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.updateAllowedDomain.success,
    (
      state: DomainsState,
      { payload }: ActionType<typeof actions.updateAllowedDomain.success>,
    ): DomainsState => ({
      ...state,
      list: state.list.map(domain => {
        if (domain.id === payload.id) {
          return payload;
        }

        return domain;
      }),
      loading: false,
    }),
  )
  .handleAction(
    actions.updateAllowedDomain.failure,
    (
      state: DomainsState,
    ): DomainsState => ({
      ...state,
      loading: false,
    }),
  );
