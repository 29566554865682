import { BalanceClient, getEmptyBalanceClient, getEmptyPerson, Person } from "../../types/models/Billing";
import { AppState } from "../reducers";
import { ModalClientSteps } from "./reducer";

export function getClients(state: AppState) {
  return state.billing.clients.list;
}

export function getClientsIsLoading(state: AppState) {
  return state.billing.clients.loading;
}

export function getClientById(state: AppState, id: BalanceClient['id']) {
  return state.billing.clients.list.find(c => c.id === id);
}

export function getActiveClient(state: AppState): BalanceClient {
  return state.billing.clients.list.find(c => state.billing.activeClient === c.id) ||
    getEmptyBalanceClient();
}

export function getActiveClientModalOpened(state: AppState) {
  return state.billing.modalClientToEdit.opened;
}

export function getActiveClientStep(state: AppState): ModalClientSteps {
  return state.billing.modalClientToEdit.step;
}

export function getActiveClientPerson(state: AppState): Person {
  return state.billing.modalClientToEdit.person || getEmptyPerson();
}

export function getActiveClientPersonLoading(state: AppState): boolean {
  return state.billing.modalClientToEdit.personLoading;
}

export function getActiveClientModalLoadingStatus(state: AppState): boolean {
  return state.billing.modalClientToEdit.loading;
}
