import { AxiosError } from "axios";
import { getErrorMessage } from "../../services/api";

export const apiErrorType = 'apiError';

export default class ApiError {
  public type = 'apiError';
  public status: number;
  public message: string;
  public code?: string;
  public traceId?: string;

  public constructor(error: AxiosError) {
    this.status = error.response?.status || 500;
    this.message = getErrorMessage(error);
    //@ts-ignore
    this.code = error.response?.data?.code;
    this.traceId = (error.response?.headers || {})['x-yatraceid'];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isApiError(value: any) {
    return value && value.type === apiErrorType;
  }
}
