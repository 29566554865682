import { SecretBackend } from "../backendModels/SecretBackend";
import { Secret } from "../models/Secret";

import {
  objSnakeToCamelDeep,
  objCamelToSnakeDeep,
} from "../../services/helpers/utilities";
import { isKeyNotEmpty, parseKeyValueToBackend } from "./IntegrationsParser";

export function parseSecret(
  value: SecretBackend,
): Secret {
  return {
    ...objSnakeToCamelDeep(value),
  };
}

export function parseSecretToBackend(
  value: Secret,
): SecretBackend {
  return {
    ...objCamelToSnakeDeep(value),
    ...(Array.isArray(value.value) && {
      value: value.value.filter(isKeyNotEmpty).map(parseKeyValueToBackend),
    }),
  };
}
