/* eslint-disable camelcase */
/* eslint-disable max-len */
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";

import {
  IncomingIntegrationActionBackend,
  IncomingIntegrationBackend,
  IncomingIntegrationCallbackBackend,
} from "../backendModels/IncomingIntegraionsBackend";

import {
  getEmptyAdditionalField,
  IncomingIntegration,
  IncomingIntegrationAction,
  IncomingIntegrationCallback,
  IncominIntegrationProtocol,
} from "../models/IncomingIntegrations";

export function parseIncomingIntegration({ additional_fields, ...data }: IncomingIntegrationBackend): IncomingIntegration {
  return {
    ...objSnakeToCamelDeep(data),
    additionalFields: additional_fields?.map(value => ({ ...getEmptyAdditionalField(), value })),
    protocol: IncominIntegrationProtocol.http,
  };
}

export function parseIncomingIntegrationToBackend({ additionalFields, ...data }: IncomingIntegration): IncomingIntegrationBackend {
  return {
    ...objCamelToSnakeDeep(data),
    additional_fields: additionalFields?.map(({ value }) => value),
    protocol: IncominIntegrationProtocol.http,
  };
}

export function parseIncomingIntegrationAction(value: IncomingIntegrationActionBackend): IncomingIntegrationAction {
  return objSnakeToCamelDeep(value);
}

export function parseIncomingIntegrationActionToBackend(value: IncomingIntegrationAction): IncomingIntegrationBackend {
  return objCamelToSnakeDeep(value);
}

export function parseIncomingIntegrationCallback(value: IncomingIntegrationCallbackBackend): IncomingIntegrationCallback {
  return objSnakeToCamelDeep(value);
}

export function parseIncomingIntegrationCallbackToBackend(value: IncomingIntegrationCallback): IncomingIntegrationCallbackBackend {
  return objCamelToSnakeDeep(value);
}
