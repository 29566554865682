import React, { FC } from 'react';
import { SvgProps } from '../types';

const TrashIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9087 1.99978L12.8032 1.99992L11.1976 1.99992L11.0921 1.99978C10.6607 1.9989 10.23 1.99802 9.83802 2.1293C9.32286 2.30181 8.87791 2.63743 8.57051 3.08538C8.33659 3.42626 8.21912 3.84058 8.10145 4.25562L8.07262 4.35707L7.83078 5.20349C4.95086 5.5105 3 6.14791 3 6.99992L3 8.99992L4.49 9.99992L5.90516 19.8402C5.96836 20.2796 6.04995 20.7344 6.35821 21.0539C6.92924 21.6458 8.38029 22.4996 12 22.4996C15.5986 22.4996 17.0537 21.6558 17.6317 21.0643C17.949 20.7397 18.0319 20.2746 18.0957 19.8252L19.49 9.99992L21 8.99992L21 7.05992C21 6.13093 19.0484 5.49467 16.1677 5.19545L15.9282 4.35708L15.8993 4.25562C15.7817 3.84058 15.6642 3.42626 15.4303 3.08538C15.1229 2.63743 14.6779 2.30181 14.1628 2.1293C13.7707 1.99802 13.3401 1.9989 12.9087 1.99978ZM9.99566 4.90652L9.95599 5.04536C10.6119 5.01538 11.2961 4.99992 12 4.99992C12.704 4.99992 13.3881 5.01439 14.0441 5.04297L14.0051 4.90652C13.8365 4.31629 13.8049 4.2515 13.7812 4.21701C13.7197 4.12742 13.6307 4.0603 13.5277 4.0258C13.488 4.01251 13.4171 3.99992 12.8032 3.99992L11.1976 3.99992C10.5837 3.99992 10.5127 4.01251 10.4731 4.02579C10.37 4.0603 10.2811 4.12742 10.2196 4.21701C10.1959 4.25149 10.1643 4.31629 9.99566 4.90652ZM6.34942 8.83918L5 7.93353L5 7.78366C5.24515 7.6951 5.58816 7.59552 6.04324 7.4982C7.48321 7.19027 9.58176 6.99992 12 6.99992C14.4277 6.99992 16.5223 7.1791 17.9553 7.48665C18.4193 7.58623 18.7615 7.68855 19 7.77828L19 7.92561L17.6298 8.83303L16.0816 19.7622C15.1071 20.4268 13.1309 20.4996 12 20.4996C9.29622 20.4996 8.23815 19.9807 7.91744 19.7616L6.34942 8.83918ZM13.4805 8.99992L13.0005 18.7496L14.5 18.4996L15.4805 9.12992L13.4805 8.99992ZM11 18.7496L10.52 8.99992L8.52 9.12992L9.5 18.4996L11 18.7496Z"
      fill={color}
    />
  </svg>
);

export default TrashIcon;
