import React, { ReactElement, useCallback } from 'react';

import { block } from 'bem-cn';

import './styles.scss';

export type ToggleProps = {
  className?: string;
  name?: string;
  value: boolean,
  size?: 'small' | 'medium',
  stopPropagation?: boolean;
  onChange: (newValue: boolean, name?: string) => void,
  isDisabled?: boolean
}

const b = block("toggle-container");

function Toggle({
  className = '',
  name,
  value,
  onChange,
  isDisabled = false,
  size = 'medium',
  stopPropagation = false,
}: ToggleProps): ReactElement {
  const handleValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (stopPropagation) {
        event.stopPropagation();
      }

      onChange(event.target.checked, name);
    },
    [name, onChange, stopPropagation],
  );

  const handleClick = useCallback(event => {
    if (stopPropagation) {
      event.stopPropagation();
    }
  }, [stopPropagation]);

  return (
    <label
      onClick={handleClick}
      className={`${b({ size })} ${className}`}
    >
      <input
        className={b("input")}
        disabled={isDisabled}
        type="checkbox"
        checked={value}
        onChange={handleValueChanged}
      />
      <div className={b("field")} />
    </label>
  );
}

export default Toggle;
