import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, memo } from "react";
import { block } from "bem-cn";

import './styles.scss';

const b = block("card");

type CardProps = {
  className?: string;
  vPadding?: 'none' | 'small' | 'medium' | 'large';
  hPadding?: 'none' | 'small' | 'medium' | 'large';
  borderRadius?: 'small' | 'medium' | 'large';
}

function Card({
  className,
  vPadding = 'medium',
  hPadding = 'medium',
  borderRadius = 'medium',
  children,
  ...delegated
}: PropsWithChildren<CardProps & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>) {
  return (
    <div
      className={b({
        'v-padding': vPadding,
        'h-padding': hPadding,
        'border-radius': borderRadius,
      }).mix(className)}
      {...delegated}
    >
      {children}
    </div>
  );
}

export default memo(Card);
