import React from 'react';
import { SvgProps } from '../types';

function CalendarOutlineIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.00084V2H7V3.05317C6.07317 3.11965 5.42316 3.26926 4.88328 3.60596C4.36568 3.92877 3.92877 4.36568 3.60596 4.88328C3 5.85491 3 7.18327 3 9.84V14.16C3 16.8167 3 18.1451 3.60596 19.1167C3.92877 19.6343 4.36568 20.0712 4.88328 20.394C5.85491 21 7.18327 21 9.84 21H14.16C16.8167 21 18.1451 21 19.1167 20.394C19.6343 20.0712 20.0712 19.6343 20.394 19.1167C21 18.1451 21 16.8167 21 14.16V9.84C21 7.18327 21 5.85491 20.394 4.88328C20.0712 4.36568 19.6343 3.92877 19.1167 3.60596C18.5768 3.26926 17.9268 3.11965 17 3.05317V2H15V3.00084C14.7356 3 14.456 3 14.16 3H9.84C9.54395 3 9.2644 3 9 3.00084ZM7 5.05933V6H9V5.00097C9.2582 5.00011 9.53723 5 9.84 5H14.16C14.4628 5 14.7418 5.00011 15 5.00097V6H17V5.05933C17.0264 5.06163 17.0526 5.06403 17.0783 5.06651C17.7153 5.12792 17.943 5.23105 18.0584 5.30298C18.3172 5.46438 18.5356 5.68284 18.697 5.94164C18.7689 6.05697 18.8721 6.28475 18.9335 6.92166C18.9836 7.4413 18.996 8.09184 18.999 9H5.00097C5.00401 8.09184 5.01641 7.4413 5.06651 6.92166C5.12792 6.28475 5.23105 6.05697 5.30298 5.94164C5.46438 5.68284 5.68284 5.46438 5.94164 5.30298C6.05697 5.23105 6.28475 5.12792 6.92166 5.06651C6.94745 5.06403 6.97355 5.06163 7 5.05933ZM5 11V14.16C5 15.5277 5.00217 16.411 5.06651 17.0783C5.12792 17.7153 5.23105 17.943 5.30298 18.0584C5.46438 18.3172 5.68284 18.5356 5.94164 18.697C6.05697 18.7689 6.28475 18.8721 6.92166 18.9335C7.58904 18.9978 8.47233 19 9.84 19H14.16C15.5277 19 16.411 18.9978 17.0783 18.9335C17.7153 18.8721 17.943 18.7689 18.0584 18.697C18.3172 18.5356 18.5356 18.3172 18.697 18.0584C18.7689 17.943 18.8721 17.7153 18.9335 17.0783C18.9978 16.411 19 15.5277 19 14.16V11H5Z"
        fill={color}
      />
    </svg>
  );
}

export default CalendarOutlineIcon;
