import { differenceInSeconds } from "date-fns";
import { dateMscToLocal } from "../../services/helpers/dateUtils";
import { OutgoingCallBackend } from "../backendModels/OutgoingCallBackend";
import { CallDirection, OutgoingCall } from "../models/OutgoingCall";
import { OutgoingCallStatus } from "../models/OutgoingCallStatus";

export function parseOutgoingCall(value: OutgoingCallBackend): OutgoingCall {
  return {
    id: value.id,
    created: value.created,
    taskId: value.task_id,
    chatId: value.chat_id,
    phone: value.phone,
    status: value.status as OutgoingCallStatus,
    ...(value.error_message && { errorMessage: value.error_message }),
    ...(value.ended && { ended: dateMscToLocal(new Date(value.ended)).toString() }),
    ...(value.initiated && { initiated: dateMscToLocal(new Date(value.initiated)).toString() }),
    ...(value.initiate_at && { initiateAt: dateMscToLocal(new Date(value.initiate_at)).toString() }),
    ...(value.ended && value.initiated && {
      length: Math.floor(differenceInSeconds(new Date(value.ended), new Date(value.initiated))),
    }),
    ...(value.has_record && { hasRecord: value.has_record }),
    ...(value.direction && { direction: value.direction as CallDirection }),
  };
}
