import React from 'react';

import { SvgProps } from '../types';

function YaIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1225 20H17V4H12.8113C8.59823 4 6.38852 6.09104 6.38852 9.18062C6.38852 11.8473 7.82119 13.351 10.3587 14.9838L11.9007 15.9706L9.92163 14.3612L6 20H9.12031L13.3091 13.9383L11.8521 12.9985C10.0916 11.8473 9.22958 10.9545 9.22958 9.01615C9.22958 7.31278 10.468 6.16153 12.8234 6.16153H14.1104V20H14.1225Z"
        fill={color}
      />
    </svg>
  );
}

export default YaIcon;
