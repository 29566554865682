import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '../../services/modal';
import { ButtonVisualStyle } from '../../components-new/button';
import Modal from '../components/ndModal';

export type ConfirmModalProps = {
  message: string | ReactNode,
  title?: string,
  cancelText?: string,
  confirmationText?: string,
  buttonStyle?: ButtonVisualStyle,
  onConfirm: () => void,
  onCancel?: () => void,
};

export default function ConfirmModal({
  message,
  title = '',
  cancelText,
  confirmationText,
  buttonStyle,
  onConfirm,
  onCancel,
  close,
}: ConfirmModalProps & ModalProps) {
  const { t } = useTranslation();

  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleConfirmClick = useCallback(
    () => {
      if (onConfirm) {
        onConfirm();
      }
      close();
    },
    [close, onConfirm],
  );

  const handleCloseClick = useCallback(
    () => {
      if (onCancel) {
        onCancel();
      }
      close();
    },
    [close, onCancel],
  );

  useEffect(
    () => {
      const el = cancelButtonRef.current;

      if (el && el.focus) {
        el.focus();
      }
    },
    [],
  );

  return (
    <Modal
      title={title}
      close={handleCloseClick}
      onConfirm={handleConfirmClick}
      cancelText={cancelText ?? t("COMMON.NO")}
      confirmButtonStyle={buttonStyle}
      confirmationText={confirmationText ?? t("COMMON.YES")}
    >
      {message}
    </Modal>
  );
}
