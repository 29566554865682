/* eslint-disable camelcase */
import { SagaIterator } from "redux-saga";
import {
  call, fork, put, takeLatest,
} from "redux-saga/effects";

import { ErrorAction } from "../reducers";
import i18n from "../../services/i18n";

import Api, { ApiResponse } from "../../services/api";
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";

import {
  DeleteReleaseNoteRequest,
  GetLatesetReleaseNoteResponse,
  GetReleaseNotesResponse,
  UpdateReleaseNoteRequest,
} from "../../types/requests/ReleaseNotes";

import { SavedReleaseNote } from "../../types/models/ReleaseNotes";
import { ReleaseNoteBackend } from "../../types/backendModels/ReleaseNotesBackend";

import { checkIfNoteShouldBeShown, setLastShownNote } from "./helpers";
import * as actions from './actions';

function* loadReleaseNotes(): SagaIterator {
  yield takeLatest(actions.loadReleaseNotes.request, function* () {
    try {
      type T = ApiResponse<GetReleaseNotesResponse>;
      const response: T = yield call(Api.getReleaseNotes);
      const { release_notes } = response.data;
      const parsed = release_notes.map(item => objSnakeToCamelDeep<SavedReleaseNote>(item));

      yield put(actions.loadReleaseNotes.success(parsed));
    } catch (err) {
      yield put(actions.loadReleaseNotes.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.RELEASE_NOTES") }),
      )));
    }
  });
}

function* loadTheLastReleaseNote(): SagaIterator {
  yield takeLatest(actions.loadTheLastReleaseNote.request, function* () {
    try {
      type T = ApiResponse<GetLatesetReleaseNoteResponse>;
      const response: T = yield call(Api.getTheLatestReleaseNode);

      const parsed = objSnakeToCamelDeep<SavedReleaseNote>(response.data);
      const skip = !checkIfNoteShouldBeShown(parsed);
      if (skip) return;

      yield put(actions.loadTheLastReleaseNote.success(parsed));
    } catch (err) {
      yield put(actions.loadTheLastReleaseNote.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.RELEASE_NOTE") }),
      )));
    }
  });
}

function* createReleaseNote(): SagaIterator {
  yield takeLatest(actions.createReleaseNote.request, function* ({ payload }) {
    try {
      const body = objCamelToSnakeDeep<ReleaseNoteBackend>(payload);
      yield call(Api.createReleaseNote, body);

      yield put(actions.loadReleaseNotes.request());
      yield put(actions.resetNoteToEdit());
    } catch (err) {
      yield put(
        actions.createReleaseNote.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.RELEASE_NOTE") })),
        ),
      );
    }
  });
}

function* updateReleaseNote(): SagaIterator {
  yield takeLatest(actions.updateReleaseNote.request, function* ({ payload }) {
    try {
      const params: UpdateReleaseNoteRequest = {
        params: { id: payload.id },
        body: objCamelToSnakeDeep<ReleaseNoteBackend>(payload),
      };

      yield call(Api.updateReleaseNote, params);

      yield put(actions.loadReleaseNotes.request());
      yield put(actions.resetNoteToEdit());
    } catch (err) {
      yield put(
        actions.updateReleaseNote.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.RELEASE_NOTE") })),
        ),
      );
    }
  });
}

function* deleteReleaseNote(): SagaIterator {
  yield takeLatest(actions.deleteReleaseNote.request, function* ({ payload }) {
    try {
      const params: DeleteReleaseNoteRequest = { id: payload };
      yield call(Api.deleteReleaseNote, params);

      yield put(actions.loadReleaseNotes.request());
    } catch (err) {
      yield put(
        actions.updateReleaseNote.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.RELEASE_NOTE") })),
        ),
      );
    }
  });
}

function* closeLastReleaseNote(): SagaIterator {
  yield takeLatest(actions.closeLastReleaseNote, ({ payload }) => {
    if (!payload) {
      return;
    }

    setLastShownNote(String(payload));
  });
}

export default function* releaseNotesSagas(): SagaIterator {
  yield fork(loadReleaseNotes);
  yield fork(loadTheLastReleaseNote);
  yield fork(createReleaseNote);
  yield fork(updateReleaseNote);
  yield fork(deleteReleaseNote);
  yield fork(closeLastReleaseNote);
}
