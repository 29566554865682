import React, { FC } from 'react';
import { SvgProps } from '../types';

const DragIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4 9C4 8.73478 4.08429 8.48043 4.23431 8.29289C4.38434 8.10536 4.58783 8 4.8 8H19.2C19.4122 8 19.6157 8.10536 19.7657 8.29289C19.9157 8.48043 20 8.73478 20 9C20 9.26522 19.9157 9.51957 19.7657 9.70711C19.6157 9.89464 19.4122 10 19.2 10H4.8C4.58783 10 4.38434 9.89464 4.23431 9.70711C4.08429 9.51957 4 9.26522 4 9ZM4.8 13C4.58783 13 4.38434 13.1054 4.23431 13.2929C4.08429 13.4804 4 13.7348 4 14C4 14.2652 4.08429 14.5196 4.23431 14.7071C4.38434 14.8946 4.58783 15 4.8 15H19.2C19.4122 15 19.6157 14.8946 19.7657 14.7071C19.9157 14.5196 20 14.2652 20 14C20 13.7348 19.9157 13.4804 19.7657 13.2929C19.6157 13.1054 19.4122 13 19.2 13H4.8Z" fill={color} />
  </svg>
);

export default DragIcon;
