import React from 'react';

import { SvgProps } from '../types';

function UserAvatarIcon({
  width = '20',
  height = '20',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.62002 14.8273C4.59596 13.4935 6.72364 13 10 13C13.2764 13 15.404 13.4935 16.38 14.8273C14.9192 16.7549 12.605 18 10 18C7.39497 18 5.08076 16.7549 3.62002 14.8273ZM10 11.5C11.8 11.5 13.5 9.5 13.5 7C13.5 3.8 11.35 3 10 3C8.65001 3 6.5 3.8 6.5 7C6.5 9.64 8.2 11.5 10 11.5Z"
        fill={color}
      />
    </svg>
  );
}

export default UserAvatarIcon;
