import { createAction, createAsyncAction } from 'typesafe-actions';

import { GraphDebugBlock } from '../../types/models/GraphDebugBlock';
import { DebugStep, DebugWindow } from "./types";

import { ErrorAction } from '../reducers';

export const createDebugStep = createAction(
  'graphDebug/createDebugStep',
)<DebugStep>();

export const removeDebugSteps = createAction(
  'graphDebug/removeDebugSteps',
)();

export const createDebugWindow = createAction(
  'graphDebug/createDebugWindow',
)<{ debugId: GraphDebugBlock['id']; x: number; y: number; title: string }>();

export const closeDebugWindow = createAction(
  'graphDebug/closeDebugWindow',
)<DebugWindow['id']>();

export const closeAllDebugWindows = createAction(
  'graphDebug/closeAllDebugWindows',
)();

export const focusDebugWindow = createAction(
  'graphDebug/focusDebugWindow',
)<DebugWindow['id']>();

export const loadDebugById = createAsyncAction(
  'graphDebug/loadDebugById/requested',
  'graphDebug/loadDebugById/succeeded',
  'graphDebug/loadDebugById/failed',
)<string, { debugId: string, debugBlocks: GraphDebugBlock[] }, ErrorAction>();

export const loadDebugs = createAction(
  'graphDebug/loadDebugs',
)<string[]>();

export const resetAllDebugInfo = createAction(
  'graphDebug/resetAllDebugInfo',
)();
