export function triggerChangeEvent(
  inputElement: HTMLTextAreaElement,
  value: string,
) {
  //@ts-ignore
  let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
  //@ts-ignore
  nativeInputValueSetter.call(inputElement, value);

  let ev2 = new Event('input', { bubbles: true });
  inputElement.dispatchEvent(ev2);
}
