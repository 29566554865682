import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';

import { ModalProps } from '../../services/modal';
import Button from '../../components-new/button';
import SideModal from '../../components-new/sideModal';
import UserWithLaptop from '../../components-new/icon/images/userWithLaptop';

import "./styles.scss";

const b = block("unsaved-changes-side-modal");

export type UnsavedChangesModalProps = {
  onConfirm: () => void,
};

export default function UnsavedChangesModal({
  onConfirm,
  close,
}: UnsavedChangesModalProps & ModalProps) {
  const { t } = useTranslation();

  const handleConfirmClick = useCallback(
    () => {
      if (onConfirm) {
        onConfirm();
      }
      close();
    },
    [close, onConfirm],
  );

  return (
    <SideModal
      className={b()}
      open
      title={t("GRAPH.NOTIFICATION_UNSAVED_CHANGES_TITLE")}
      onClose={close}
      footer={(
        <>
          <Button
            fluid
            size="large"
            onClick={handleConfirmClick}
          >
            {t("GRAPH.NOTIFICATION_UNSAVED_CHANGES_LEAVE")}
          </Button>
          <Button
            fluid
            size="large"
            onClick={close}
            primary
          >
            {t("GRAPH.NOTIFICATION_UNSAVED_CHANGES_STAY")}
          </Button>
        </>
      )}
    >
      <div className={b("content")}>
        <UserWithLaptop />
        {t("GRAPH.NOTIFICATION_UNSAVED_CHANGES_MSG")}
      </div>
    </SideModal>
  );
}
