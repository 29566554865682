export type Sorting = {
  field?: string,
  direction: SortDirection,
}

export enum SortDirection {
  DEFAULT = 'DEFAULT',
  ASC = 'ASC',
  DESC = 'DESC',
}

export function getNextSortDirection(direction: SortDirection) {
  switch (direction) {
    case SortDirection.DEFAULT:
      return SortDirection.ASC;
    case SortDirection.ASC:
      return SortDirection.DESC;
    case SortDirection.DESC:
      return SortDirection.DEFAULT;
    default:
      return SortDirection.DEFAULT;
  }
}
