import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as actions from '../../redux/projects/actions';
import { getProjects, getSelectedProject } from '../../redux/projects/selectors';
import navigationService from '../../services/navigation';
import { getEmptyProject } from '../../types/models/Project';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProjectPageWrapperProps = React.PropsWithChildren<any>;

function ProjectPageWrapper({
  children,
}: ProjectPageWrapperProps) {
  const location = useLocation();
  const dispatch = useDispatch();

  const projects = useSelector(getProjects);
  const selectedProject = useSelector(getSelectedProject);

  useEffect(() => {
    const queryProjectSlug = navigationService.getSearchParam('project');
    if (queryProjectSlug && queryProjectSlug !== selectedProject.slug) {
      const project = projects.find(p => p.slug === queryProjectSlug) || getEmptyProject();
      dispatch(actions.selectProject(project));
      return;
    }

    navigationService.addSearchParam('project', selectedProject.slug);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, projects, dispatch]);

  return children;
}

export default ProjectPageWrapper;
