import { createAction, createAsyncAction } from "typesafe-actions";
import { AuthProviderMeta } from "../../types/models/AuthProvider";

import {
  UserRole, ProjectUser, User, UserAccess,
} from "../../types/models/UserAccesses";

import { ErrorAction } from "../reducers";

export const loadProjectUsersList = createAsyncAction(
  'accesses/loadProjectUsersList/requested',
  'accesses/loadProjectUsersList/succeeded',
  'accesses/loadProjectUsersList/failed',
)<undefined, ProjectUser[], ErrorAction>();

export const addUserToProject = createAsyncAction(
  'accesses/addUserToProject/requested',
  'accesses/addUserToProject/succeeded',
  'accesses/addUserToProject/failed',
)<ProjectUser, ProjectUser, ErrorAction>();

export const loadAllUsers = createAsyncAction(
  'accesses/loadAllUsers/requested',
  'accesses/loadAllUsers/succeeded',
  'accesses/loadAllUsers/failed',
)<{ isSuperAdmin?: boolean; } | undefined, User[], ErrorAction>();

export const getUserById = createAsyncAction(
  'accesses/getUserById/requested',
  'accesses/getUserById/succeeded',
  'accesses/getUserById/failed',
)<ProjectUser['id'], { user: User; accesses: UserAccess[] }, ErrorAction>();

export const updateUserGroup = createAsyncAction(
  'accesses/updateUserGroup/requested',
  'accesses/updateUserGroup/succeeded',
  'accesses/updateUserGroup/failed',
)<{ userId: User['id']; group: UserRole }, ProjectUser, ErrorAction>();

export const removeUserFromProject = createAsyncAction(
  'accesses/removeUserFromProject/requested',
  'accesses/removeUserFromProject/succeeded',
  'accesses/removeUserFromProject/failed',
)<NonNullable<User['id']>, undefined, ErrorAction>();

export type CreateUserByProviderParams = { username: string, provider: AuthProviderMeta, password?: string }
export const createUserByProvider = createAsyncAction(
  'accesses/createUserByProvider/requested',
  'accesses/createUserByProvider/succeeded',
  'accesses/createUserByProvider/failed',
)<
  CreateUserByProviderParams,
  User,
  ErrorAction
>();

export const setEditableUser = createAction('accesses/setEditableUser')<User | null>();

export const init = createAsyncAction(
  'accesses/init/requested',
  'accesses/init/succeeded',
  'accesses/init/failed',
)<undefined, undefined, ErrorAction>();
