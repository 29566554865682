import { createAction, createAsyncAction } from 'typesafe-actions';
import { ScenarioGraphExample, ScenarioGraphExampleType, TrashPhrase } from '../../types/models/ScenarioGraphExample';

import { ErrorAction } from '../reducers';
import { ScenarioGraphsItem } from '../scenarioGraphs/types';
import { DeletionParams } from './types';

export const loadScenarioGraphExamples = createAsyncAction(
  'scenariosExamples/loadScenarioGraphExamples/requested',
  'scenariosExamples/loadScenarioGraphExamples/succeeded',
  'scenariosExamples/loadScenarioGraphExamples/failed',
)<
  { scenarioId: string, searchValue?: string },
  { scenarioId: string, examples: ScenarioGraphExample[] },
  ErrorAction<ScenarioGraphsItem['id']>
>();

export const createScenarioGraphExample = createAsyncAction(
  'scenariosExamples/createScenarioGraphExample/requested',
  'scenariosExamples/createScenarioGraphExample/succeeded',
  'scenariosExamples/createScenarioGraphExample/failed',
)<
  { scenarioId: string, text: string, type: ScenarioGraphExample['type'], modalId?: string },
  { scenarioId: ScenarioGraphsItem['id'], example: ScenarioGraphExample },
  ErrorAction<ScenarioGraphsItem['id']>
>();

export const deleteScenarioGraphExample = createAsyncAction(
  'scenariosExamples/deleteScenarioGraphExample/requested',
  'scenariosExamples/deleteScenarioGraphExample/succeeded',
  'scenariosExamples/deleteScenarioGraphExample/failed',
)<
  { scenarioId: string, exampleId: string },
  { scenarioId: string, exampleId: string },
  ErrorAction<ScenarioGraphsItem['id']>
>();

export const loadTrashPhrases = createAsyncAction(
  'scenariosExamples/loadTrashPhrases/requested',
  'scenariosExamples/loadTrashPhrases/succeeded',
  'scenariosExamples/loadTrashPhrases/failed§',
)<
  { offset?: number },
  { total: number, phrases: TrashPhrase[] },
  ErrorAction
>();

export const loadAllExamples = createAsyncAction(
  'scenariosExamples/loadAllExamples/requested',
  'scenariosExamples/loadAllExamples/succeeded',
  'scenariosExamples/loadAllExamples/failed',
)<
  undefined,
  Record<ScenarioGraphsItem['slug'], { totalAddition: number, totalException: number }>,
  ErrorAction
>();

export const deleteExamples = createAsyncAction(
  'scenariosExamples/deleteExamples/requested',
  'scenariosExamples/deleteExamples/succeeded',
  'scenariosExamples/deleteExamples/failed',
)<
  DeletionParams,
  DeletionParams,
  DeletionParams
>();

export const openScenarioExamplesModal = createAction('scenariosExamples/openScenarioExamplesModal')<{
  slug: ScenarioGraphsItem['slug'],
  type: ScenarioGraphExampleType
}>();
export const closeScenarioExamplesModal = createAction('scenariosExamples/closeScenarioExamplesModal')();

export const openAllExamplesModal = createAction('scenariosExamples/openAllExamplesModal')();
export const closeAllExamplesModal = createAction('scenariosExamples/closeAllExamplesModal')();

export const toggleExampleReferenceStatus = createAsyncAction(
  'scenariosExamples/toggleExampleReferenceStatus/requested',
  'scenariosExamples/toggleExampleReferenceStatus/succeeded',
  'scenariosExamples/toggleExampleReferenceStatus/failed',
)<
  { scenarioId: ScenarioGraphsItem['id'], exampleId: ScenarioGraphExample['id'] },
  { scenarioId: ScenarioGraphsItem['id'], exmaple: ScenarioGraphExample },
  ErrorAction<{ scenarioId: ScenarioGraphsItem['id'], exampleId: ScenarioGraphExample['id'] }>
>();

export const checkDirtyExamplesSearchAvailable = createAsyncAction(
  'scenariosExamples/checkDirtyExamplesSearchAvailable/requested',
  'scenariosExamples/checkDirtyExamplesSearchAvailable/succeeded',
  'scenariosExamples/checkDirtyExamplesSearchAvailable/failed',
)<
  undefined,
  boolean,
  ErrorAction
>();
