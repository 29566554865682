import { Tag } from "../../../../../../types/models/Tag";

export const TAG_BLANK: string = "#";

export const TAG_PLAY: string = "play";

export const TAG_SPEAK: string = "speak";

export const FIXED_TAGS = [
  TAG_BLANK,
  TAG_PLAY,
  TAG_SPEAK,
];

export function isFixedTag(tag: string) {
  return FIXED_TAGS.includes(tag);
}

export function notBlankTag(tag: Tag) {
  return tag.slug !== TAG_BLANK;
}

export const convertTagsToOptions = (
  tags: Tag[] = [],
  isVoiceProject: boolean,
): (Tag & {
  isDisabled: boolean,
})[] => {
  const isTagFixed = (tag: string) => {
    switch (tag) {
      case TAG_BLANK:
        return true;
      case TAG_PLAY:
      case TAG_SPEAK:
        return isVoiceProject;
      default:
        return false;
    }
  };

  return tags.map(tag => ({
    ...tag,
    isDisabled: isTagFixed(tag.slug),
  }));
};

export const convertOptionsToTags = (options: { value: string, label: string, id: string }[]): Tag[] => (
  options.map(option => {
    return {
      slug: option.value,
      id: option.id,
    };
  }).filter(option => !!option.slug)
);

export const removeUnavailableTags = (
  allTags: Tag[] = [],
  selectedTags: string[] = [],
  isVoiceProject: boolean,
): Tag[] => {
  let result = [...allTags];

  if (isVoiceProject) {
    if (!selectedTags.includes(TAG_PLAY)) {
      result = removeTag(result, TAG_PLAY);
    }
    if (!selectedTags.includes(TAG_SPEAK)) {
      result = removeTag(result, TAG_SPEAK);
    }
  }

  return result;
};

export const removeTag = (list: Tag[], tag: string): Tag[] => list.filter(t => t.slug !== tag);

export const getIdBySlug = (used: string[], allTags: Tag[]): Tag[] =>
  used.map(el => {
    return {
      slug: el,
      id: allTags.find(tag => tag.slug === el)?.id || '-1',
    };
  }).filter(el => el.id !== '-1');
