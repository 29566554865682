import { useState } from 'react';

// Хук используется для принудительного ререндера элемента. Создавался для компонента Container из-за необходимости
// динамически менять размер элементов UI в зависимости от состояния меню (открыто/закрыто).
// forceUpdate не очень хорошая практика, поэтому по возможности нужно придумать
// какое-то более грамотное решение (или избавиться от требования открытия/закрытия меню
// в связи с специфическими требованиями к размерам элементов)
export function useForceUpdate() {
  const [value, setValue] = useState<number>(0);
  return () => setValue(value + 1);
}
