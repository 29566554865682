import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { Entity, EntityCustomExtractor, getEmptyEntity } from "../../types/models/Entity";
import * as actions from './actions';

type EntitiesState = {
  list: {
    loading: boolean,
    value: Entity[],
  },
  customExtractors: {
    value: EntityCustomExtractor[],
    loading: boolean,
  }
}

const initialState: EntitiesState = {
  list: {
    loading: false,
    value: [],
  },
  customExtractors: {
    value: [],
    loading: false,
  },
};

export const LOCAL_ENTITY_ID_PREFIX = 'LOCAL_ENTITY_';

export const entitiesReducer: Reducer<EntitiesState> = createReducer<EntitiesState>(initialState)
  .handleAction(
    actions.loadEntities.request,
    (state: EntitiesState): EntitiesState => ({
      ...state,
      list: {
        ...state.list,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadEntities.success,
    (state: EntitiesState, { payload: entities }: ActionType<typeof actions.loadEntities.success>): EntitiesState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        value: entities,
      },
    }),
  )
  .handleAction(
    actions.loadEntities.failure,
    (state: EntitiesState): EntitiesState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createEntity.request,
    (state: EntitiesState, { payload: entity }: ActionType<typeof actions.createEntity.request>): EntitiesState => {
      return updateEntity(state, entity.id, sEntity => ({ ...sEntity, pending: true }));
    },
  )
  .handleAction(
    actions.createEntity.success,
    (state: EntitiesState, { payload: entity }: ActionType<typeof actions.createEntity.success>): EntitiesState => {
      const copy = [...state.list.value];
      const firstNotLocalEntityIndex = copy.findIndex(e => !e.id.startsWith(LOCAL_ENTITY_ID_PREFIX));
      copy.splice(firstNotLocalEntityIndex, 0, entity);
      return {
        ...state,
        list: {
          ...state.list,
          value: copy,
        },
      };
    },
  )
  .handleAction(
    actions.createEntity.failure,
    (state: EntitiesState, { payload: error }: ActionType<typeof actions.createEntity.failure>): EntitiesState => {
      return updateEntity(state, error.payload.id, sEntity => ({ ...sEntity, pending: false }));
    },
  )
  .handleAction(
    actions.updateEntity.request,
    (state: EntitiesState, { payload: entity }: ActionType<typeof actions.updateEntity.request>): EntitiesState => {
      return updateEntity(state, entity.id, sEntity => ({ ...sEntity, pending: true }));
    },
  )
  .handleAction(
    actions.updateEntity.success,
    (state: EntitiesState, { payload: entity }: ActionType<typeof actions.updateEntity.success>): EntitiesState => {
      return updateEntity(state, entity.id, () => ({ ...entity }));
    },
  )
  .handleAction(
    actions.updateEntity.failure,
    (state: EntitiesState, { payload: error }: ActionType<typeof actions.updateEntity.failure>): EntitiesState => {
      return updateEntity(state, error.payload.id, sEntity => ({ ...sEntity, pending: false }));
    },
  )
  .handleAction(
    actions.deleteEntity.request,
    (state: EntitiesState, { payload: entity }: ActionType<typeof actions.deleteEntity.request>): EntitiesState => {
      return updateEntity(state, entity.id, sEntity => ({ ...sEntity, pending: true }));
    },
  )
  .handleAction(
    actions.deleteEntity.success,
    (state: EntitiesState, { payload: entity }: ActionType<typeof actions.deleteEntity.success>): EntitiesState => {
      return {
        ...state,
        list: {
          ...state.list,
          value: state.list.value.filter(sEntity => sEntity.id !== entity.id),
        },
      };
    },
  )
  .handleAction(
    actions.deleteEntity.failure,
    (state: EntitiesState, { payload: error }: ActionType<typeof actions.deleteEntity.failure>): EntitiesState => {
      return updateEntity(state, error.payload.id, sEntity => ({ ...sEntity, pending: false }));
    },
  )
  .handleAction(
    actions.createEmptyEntity,
    (state: EntitiesState): EntitiesState => ({
      ...state,
      list: {
        ...state.list,
        value: [getEmptyEntity(), ...state.list.value],
      },
    }),
  )
  .handleAction(
    actions.clearEmptyEntities,
    (state: EntitiesState): EntitiesState => ({
      ...state,
      list: {
        ...state.list,
        value: state.list.value.filter(sEntity => !sEntity.id.startsWith(LOCAL_ENTITY_ID_PREFIX)),
      },
    }),
  )
  .handleAction(
    actions.removeEntityById,
    (state: EntitiesState, { payload: entityId }: ActionType<typeof actions.removeEntityById>): EntitiesState => ({
      ...state,
      list: {
        ...state.list,
        value: state.list.value.filter(sEntity => sEntity.id !== entityId),
      },
    }),
  )
  .handleAction(
    actions.loadCustomExtractors.request,
    (state: EntitiesState): EntitiesState => ({
      ...state,
      customExtractors: {
        ...state.customExtractors,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCustomExtractors.success,
    (
      state: EntitiesState,
      { payload: extractors }: ActionType<typeof actions.loadCustomExtractors.success>,
    ): EntitiesState => ({
      ...state,
      customExtractors: {
        ...state.customExtractors,
        value: extractors,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCustomExtractors.failure,
    (state: EntitiesState): EntitiesState => ({
      ...state,
      customExtractors: {
        ...state.customExtractors,
        loading: false,
      },
    }),
  );

function updateEntity(state: EntitiesState, entityId: string, callback: (value: Entity) => Entity): EntitiesState {
  const entityIndex = state.list.value.findIndex(sEntity => sEntity.id === entityId);
  if (entityIndex !== -1) {
    const copy = [...state.list.value];
    copy[entityIndex] = callback(copy[entityIndex]);
    return {
      ...state,
      list: {
        ...state.list,
        value: copy,
      },
    };
  }
  return state;
}
