import { SagaIterator } from "redux-saga";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import i18n from "../../services/i18n";

import * as actions from "./actions";
import { parseScenarioGraphGroup, parseScenarioGraphGroupToBackend } from "../../types/parsers/ScenarioGraphGroup";
import { ErrorAction } from "../reducers";
import { getGraphGroups } from "./selectors";
import { checkMetaChanged, checkNodesChanged, isGroupNew, ScenarioGraphGroup } from "../../types/models/ScenarioGraphGroup";
import { CreateGraphGroupRequest, CreateGraphGroupResponse, DeleteGraphGroupRequest, EditGraphGroupRequest, EditGraphGroupsBulkRequest, GetGraphGroupsRequest, GetGraphGroupsResponse } from "../../types/requests/GraphGroups";
import { getActiveGraph } from "../graph/selectors";
import { ScenarioGraph } from "../../types/models/ScenarioGraph";
import apiService, { ApiResponse } from "../../services/api";
import { getConfig } from "../user/selectors";
import { UseGraphGroupsConfig } from "../../types/codegen/experiments";

function* loadGraphGroups(): SagaIterator {
  yield takeLatest(actions.loadGraphGroups.request, function* () {
    try {
      const config: UseGraphGroupsConfig | undefined = yield select(getConfig, 'supportai-frontend:use_graph_groups');

      if (!config?.enabled) {
        yield put(actions.loadGraphGroups.success([]));
        return;
      }

      if (!config?.useMocks) {
        const activeGraph: ScenarioGraph = yield select(getActiveGraph);
        const request: GetGraphGroupsRequest = {
          path: {
            scenario_id: activeGraph.id,
          },
        };
        const response: ApiResponse<GetGraphGroupsResponse> = yield call(apiService.getGraphGroups, request);
        const groups = response.data.scenario_graph_groups.map(parseScenarioGraphGroup);

        yield put(actions.loadGraphGroups.success(groups));
      } else {
        yield put(actions.loadGraphGroups.success([]));
      }
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.GRAPH_GROUPS.MULTIPLE") });
      yield put(actions.loadGraphGroups.failure(new ErrorAction(err, text)));
    }
  });
}

function* saveGroups(): SagaIterator {
  yield takeLatest(actions.saveGraphGroups.request, function* (action) {
    try {
      const newGroupsState = action.payload;
      const currentGroupsState: ScenarioGraphGroup[] = yield select(getGraphGroups);

      const config: UseGraphGroupsConfig | undefined = yield select(getConfig, 'supportai-frontend:use_graph_groups');

      const USE_GROUPS = !!config?.enabled;
      const MOCK_GROUPS = !!config?.useMocks;

      if (!USE_GROUPS) {
        yield put(actions.saveGraphGroups.success([]));
      }

      const activeGraph: ScenarioGraph = yield select(getActiveGraph);
      const scenarioId = activeGraph.id;
      if (!scenarioId) throw new Error("Can't identify graph");

      const deletedGroups: ScenarioGraphGroup[] = currentGroupsState
        .filter(currentGroup => !newGroupsState.some(newGroup => currentGroup.id === newGroup.id));
      if (!MOCK_GROUPS) {
        for (let group of deletedGroups) {
          const request: DeleteGraphGroupRequest = {
            path: {
              scenario_id: scenarioId,
              group_id: group.id,
            },
          };

          yield call(apiService.deleteGraphGroup, request);
        }
      }

      const newGroups: ScenarioGraphGroup[] = newGroupsState.filter(isGroupNew);

      if (!MOCK_GROUPS) {
        let i = 0;
        for (let group of newGroups) {
          const request: CreateGraphGroupRequest = {
            path: {
              scenario_id: scenarioId,
            },
            body: parseScenarioGraphGroupToBackend(group),
          };

          const response: ApiResponse<CreateGraphGroupResponse> = yield call(
            apiService.createGraphGroup,
            request,
          );

          const createdGroup = parseScenarioGraphGroup(response.data);
          newGroups[i] = createdGroup;
          i++;
        }
      }

      const groupsWithModifiedNodes: ScenarioGraphGroup[] = newGroupsState
        .filter(group => !isGroupNew(group))
        .filter(group => checkNodesChanged(group, currentGroupsState));

      if (groupsWithModifiedNodes.length > 0 &&
        !MOCK_GROUPS
      ) {
        const request: EditGraphGroupsBulkRequest = {
          path: {
            scenario_id: scenarioId,
          },
          body: {
            scenario_graph_groups: groupsWithModifiedNodes.map(parseScenarioGraphGroupToBackend),
          },
        };

        yield call(
          apiService.updateGraphGroupsBulk,
          request,
        );
      }

      const groupsWithModifiedMeta: ScenarioGraphGroup[] = newGroupsState
        .filter(group => !isGroupNew(group))
        .filter(group => checkMetaChanged(group, currentGroupsState));

      if (groupsWithModifiedMeta.length > 0 && !MOCK_GROUPS) {
        for (let group of groupsWithModifiedMeta) {
          const request: EditGraphGroupRequest = {
            path: {
              scenario_id: activeGraph.id,
              group_id: group.id,
            },
            body: parseScenarioGraphGroupToBackend({
              ...group,
            }),
          };

          yield call(
            apiService.updateGraphGroup,
            request,
          );
        }
      }

      yield put(actions.saveGraphGroups.success([
        ...newGroupsState,
      ]));
    } catch (err) {
      const text = i18n.t("ERRORS.API.EDIT", { name: i18n.t("ERRORS.API.GRAPH_GROUPS.MULTIPLE") });
      yield put(actions.saveGraphGroups.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* graphGroupsSagas() {
  yield fork(loadGraphGroups);
  yield fork(saveGroups);
}
