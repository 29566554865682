import { connectRouter, push } from 'connected-react-router';
// @ts-ignore
import { createBrowserHistory } from 'history';

import { store } from '../../redux/store';
import unauthorizedPages from '../../routes/unauthorized';
import { Project } from '../../types/models/Project';

export const history = createBrowserHistory();
export const connectedHistory = connectRouter(history);

function navigateTo(path: string, search?: string, hash?: string) {
  store.dispatch(push({
    pathname: path,
    search,
    hash,
  }));
}

function getLoginFormUrl() {
  return process.env.REACT_APP_ENVIRONMENT_ID === 'box' ? unauthorizedPages.loginPassword : unauthorizedPages.root;
}

function getProjectFromNavigation(): Project['slug'] | null {
  const params = new URLSearchParams(window.location.search);
  return params.get('project');
}

function addSearchParam(key: string, value: string) {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);

  updateSearchParams(params.toString());
}

function getSearchParam(key: string): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

function setSearchParams(values: Record<string, string>) {
  const params = new URLSearchParams();
  Object.keys(values).forEach(key => {
    const value = values[key];

    params.set(key, value);
  });

  updateSearchParams(params.toString());
}

function updateSearchParams(search: string) {
  const newUrl = `${window.location.origin}${window.location.pathname}?${search}`;
  window.history.pushState({ url: newUrl }, '', newUrl);
}

function removeSearchParam(param: string) {
  const params = new URLSearchParams(window.location.search);
  if (params.has(param)) {
    params.delete(param);
    window.history.pushState(null, '', params.toString());
  }
}

function getPageNavigationLink(
  path: string,
  params: Record<string, string> = {},
): string {
  const queryParams = new URLSearchParams();

  Object.keys(params).forEach(key => {
    queryParams.append(key, params[key]);
  });

  return `${window.origin}${path}?${queryParams}`;
}

const navigationService = {
  navigateTo,
  getLoginFormUrl,
  getProjectFromNavigation,
  setSearchParams,
  addSearchParam,
  getSearchParam,
  getPageNavigationLink,
  removeSearchParam,
};

export default navigationService;
