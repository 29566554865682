import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeCloseIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM24 12C24 18.6274 18.6274 24 12 24C5.37259 24 1.43766e-06 18.6274 8.58275e-07 12C2.78888e-07 5.37259 5.37258 -2.78888e-07 12 -8.58275e-07C18.6274 -1.43766e-06 24 5.37258 24 12ZM10.0582 16.8026C10.2553 16.9405 10.4848 17.0044 10.7109 16.9998C10.9787 16.9951 11.2451 16.8944 11.4495 16.6977C11.5079 16.6414 11.5582 16.5802 11.6004 16.5155L16.7949 9.63896C17.1431 9.17804 17.0373 8.53266 16.5585 8.19744C16.0798 7.86223 15.4094 7.96413 15.0612 8.42505L10.5608 14.3827L8.82975 12.7161C8.41117 12.3131 7.73252 12.3131 7.31394 12.7161C6.89536 13.1191 6.89536 13.7725 7.31394 14.1755L9.8826 16.6485C9.93399 16.7048 9.9926 16.7566 10.0582 16.8026Z" fill={color} />
  </svg>
);

export default GraphNodeCloseIcon;
