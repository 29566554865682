import { Project } from "../models/Project";
import { ScenarioGraphGroup } from "../models/ScenarioGraphGroup";
import { ScenarioGraph } from "~/api-types/backend-py3/services/supportai/docs/yaml/definitions/scenario";

export enum ScenarioGroupName {
  greeting = 'greeting',
  pre = 'pre',
  main = 'main',
  fallback = 'fallback',
  feedback = 'feedback',
}

export type ScenarioGraphBackend = ScenarioGraph;

export type ExportedScenarioGraphBackend = ScenarioGraphBackend & {
  project_id: Project['id'],
  groups: ScenarioGraphGroup[],
};
