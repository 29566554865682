import { CallStatusBackend } from "../backendModels/CallsStatisticsBackend";
import { StatisticsPeriod } from "./StatisticsPeriod";
import { Tag } from "./Tag";

export enum DetailedCallsStatisticPeriod {
  BatchId = 'batch_id'
}

export type CallsStatisticsPeriod = StatisticsPeriod | DetailedCallsStatisticPeriod;

export type CallStatus = CallStatusBackend;

export type CallsStatisticsItem = {
  id?: string;
  title: string;
  startAt: string; // Date time iso
  totalSeconds: number;
  calls: number;
  callAttempts: number;
  connectedFirstAttempt: PercentMetrics;
  connectedSecondAttempt: PercentMetrics;
  status: CallStatus;
  endedCount: PercentMetrics;
  forwardedCount: PercentMetrics;
  noAnswerCount: PercentMetrics;
  userBusyCount: PercentMetrics;
  hangupCount: PercentMetrics;
  errorCount: PercentMetrics;
  items?: CallsStatisticsItem[];
  period: CallsStatisticsPeriod;
  tagsCounter?: Record<Tag['slug'], PercentMetrics>;
};

export type PercentMetrics = {
  number: number;
  percent?: number;
}

export type CallsDetailsStatisticsItem = {
  userPhoneNumber: string;
  servicePhoneNumber: string;
  initiatedAt: string;
  startedAt: string;
  duration: number;
  attemptsTaken: number;
  status: CallStatus;
};
