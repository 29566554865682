import { format } from "date-fns";

import { StatisticsPeriod } from "../../../../types/models/StatisticsPeriod";

import { ceilDate, floorDate } from "../../helpers";

export const getDateLabel = (date: Date, period: StatisticsPeriod): string => {
  switch (period) {
    case StatisticsPeriod.Hour:
      return (format(date, 'HH:00 dd MMM')).toUpperCase();

    case StatisticsPeriod.Day:
      return (format(date, 'EEEEEE\tdd MMMM')).toUpperCase();

    case StatisticsPeriod.Month:
      return (format(date, "MMM")).toUpperCase();

    default:
      return date.toString();
  }
};

export function getStartDate(date: Date, period: StatisticsPeriod): Date {
  return floorDate(date, period);
}

export function getEndDate(date: Date, period: StatisticsPeriod): Date {
  if (period === StatisticsPeriod.Hour) {
    return ceilDate(date, period);
  }
  return floorDate(date, period);
}

export const categoriesVisibilityMapper = (dates: Date[]) => (
  item: string,
  i: number,
): string => {
  const totalCount = dates.length;
  const last = totalCount - 1;

  const visible = [0, Math.floor(last * 0.33), Math.floor(last * 0.66), last].includes(i);

  return visible ? item : '';
};
