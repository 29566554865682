import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { block } from 'bem-cn';

import Textarea, { TextAreaOnChangeFunc } from '../../components-new/textarea';
import Modal from '../components/ndModal';
import Input from '../../components-new/input';
import { ModalProps } from '../../services/modal';

import './styles.scss';

const b = block("update-audio-record-modal");

export type UpdateAudioRecordModalProps = {
  name: string,
  comment?: string,
  onSubmit: (params: {
    name: string,
    comment?: string,
  }) => void,
}

export default function UpdateAudioRecordModal({
  name: defaultName,
  comment: defaultComment = '',
  onSubmit,
  close,
}: UpdateAudioRecordModalProps & ModalProps) {
  const [name, setName] = useState<string>(defaultName);
  const [comment, setComment] = useState<string>(defaultComment);
  const { t } = useTranslation();

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | null) => {
      setName(event ? event.target.value : '');
    },
    [],
  );

  const handleCommentChange = useCallback<TextAreaOnChangeFunc>(
    (_, { value }) => { setComment(value) },
    [],
  );

  const handleSubmitClick = useCallback(
    () => {
      onSubmit({
        name,
        comment,
      });
    },
    [comment, name, onSubmit],
  );

  return (
    <Modal
      title={t("MODALS.UPDATE_AUDIO_RECORD.TITLE")}
      className={b()}
      cancelText={t("COMMON.CANCEL")}
      confirmationText={t("COMMON.READY")}
      close={close}
      onConfirm={handleSubmitClick}
    >
      <div className="column-2">
        <Input
          className={b("input")}
          name="audio-record-name"
          placeholder={t("COMMON.NAME")}
          floatingPlaceholder
          value={name}
          onChange={handleNameChange}
        />
        <Textarea
          className={b("textarea")}
          name="comment"
          value={comment}
          placeholder={t("COMMON.COMMENT")}
          fluid
          onChange={handleCommentChange}
        />
      </div>
    </Modal>
  );
}
