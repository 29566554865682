import { SidebarItemProps } from "../../components/sidebar/sidebarItem";
import { AppState } from "../reducers";

export function getIsSidebarItemOpened(state: AppState, props: SidebarItemProps): boolean {
  return state.sidebar.openedItems.includes(props.value.id);
}

export function getSidebarOpenStatus(state: AppState): boolean {
  return state.sidebar.opened;
}

export function getOpenedSidebarItems(state: AppState): string[] {
  return state.sidebar.openedItems;
}
