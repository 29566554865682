import { createAsyncAction } from 'typesafe-actions';

import { SavedUser } from '../../types/models/UserAccesses';
import { ErrorAction } from '../reducers';

export const loadSuperUsers = createAsyncAction(
  'users/loadSuperUsers/requested',
  'users/loadSuperUsers/succeeded',
  'users/loadSuperUsers/failed',
)<undefined, SavedUser[], ErrorAction>();

export const provideSuperUserRole = createAsyncAction(
  'users/provideSuperUserRole/requested',
  'users/provideSuperUserRole/succeeded',
  'users/provideSuperUserRole/failed',
)<{ userId: string }, SavedUser, ErrorAction>();
