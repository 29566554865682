export enum VoiceRole {
  modern = 'modern',
  classic = 'classic',
  neutral = 'neutral',
  good = 'good',
  evil = 'evil',
}

export enum VoicePerson {
  lea = 'lea',
  john = 'john',
  naomi = 'naomi',
  amira = 'amira',
  madi = 'madi',
  alena = 'alena', // Default
  filipp = 'filipp',
  ermil = 'ermil',
  jane = 'jane',
  madirus = 'madirus',
  omazh = 'omazh',
  zahar = 'zahar',
  dasha = 'dasha',
  julia = 'julia',
  lera = 'lera',
  marina = 'marina',
  alexander = 'alexander',
  kirill = 'kirill',
  anton = 'anton',
  nigora = 'nigora',
}

export const MAP_PERSON_TO_ROLE: Record<VoicePerson, VoiceRole[] | null> = {
  [VoicePerson.lea]: null,
  [VoicePerson.john]: null,
  [VoicePerson.naomi]: [VoiceRole.modern, VoiceRole.classic],
  [VoicePerson.amira]: null,
  [VoicePerson.madi]: null,
  [VoicePerson.alena]: [VoiceRole.neutral, VoiceRole.good],
  [VoicePerson.filipp]: null,
  [VoicePerson.ermil]: [VoiceRole.neutral, VoiceRole.good],
  [VoicePerson.jane]: [VoiceRole.neutral, VoiceRole.good, VoiceRole.evil],
  [VoicePerson.madirus]: null,
  [VoicePerson.omazh]: [VoiceRole.neutral, VoiceRole.evil],
  [VoicePerson.zahar]: [VoiceRole.neutral, VoiceRole.good],
  [VoicePerson.dasha]: null,
  [VoicePerson.julia]: null,
  [VoicePerson.lera]: null,
  [VoicePerson.marina]: null,
  [VoicePerson.alexander]: null,
  [VoicePerson.kirill]: null,
  [VoicePerson.anton]: null,
  [VoicePerson.nigora]: null,
};

export const speechKitLanguages = [
  'ru-RU',
  'de-DE',
  'en-US',
  'he-IL',
  'kk-KK',
  'uz-UZ',
];

export const MAP_LANGUAGE_TO_PERSON: Record<string, VoicePerson[]> = {
  [speechKitLanguages[0]]: [
    VoicePerson.alena,
    VoicePerson.filipp,
    VoicePerson.ermil,
    VoicePerson.jane,
    VoicePerson.madirus,
    VoicePerson.omazh,
    VoicePerson.zahar,
    VoicePerson.dasha,
    VoicePerson.julia,
    VoicePerson.lera,
    VoicePerson.marina,
    VoicePerson.alexander,
    VoicePerson.kirill,
    VoicePerson.anton,
  ],
  [speechKitLanguages[1]]: [
    VoicePerson.lea,
  ],
  [speechKitLanguages[2]]: [
    VoicePerson.john,
  ],
  [speechKitLanguages[3]]: [
    VoicePerson.naomi,
  ],
  [speechKitLanguages[4]]: [
    VoicePerson.amira,
    VoicePerson.madi,
  ],
  [speechKitLanguages[5]]: [
    VoicePerson.nigora,
  ],
};
