import React from 'react';
import { SvgProps } from '../types';

export default function StarStrokeIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0005 17.3276L6.59705 20.6139C6.24315 20.8292 5.78177 20.7168 5.56653 20.3629C5.46407 20.1944 5.43207 19.9924 5.47746 19.8005L6.93324 13.646L2.13798 9.52265C1.82391 9.25259 1.78823 8.77906 2.0583 8.46499C2.18685 8.31549 2.36911 8.22262 2.56562 8.2065L8.86874 7.68919L11.3085 1.85442C11.4683 1.47227 11.9076 1.29201 12.2898 1.45181C12.4717 1.52787 12.6163 1.67251 12.6924 1.85442L15.1322 7.68919L21.4353 8.2065C21.8481 8.24038 22.1553 8.6025 22.1214 9.01533C22.1053 9.21184 22.0124 9.3941 21.8629 9.52265L17.0677 13.646L18.5234 19.8005C18.6188 20.2036 18.3693 20.6077 17.9662 20.703C17.7743 20.7484 17.5723 20.7164 17.4039 20.6139L12.0005 17.3276ZM12.5201 15.3028L15.889 17.3517L14.9814 13.5145C14.8954 13.1509 15.0192 12.7697 15.3025 12.5261L18.2923 9.95527L14.3624 9.63274C13.99 9.60218 13.6657 9.36657 13.5216 9.02186L12.0005 5.38399L10.4793 9.02186C10.3352 9.36657 10.0109 9.60218 9.6385 9.63274L5.70862 9.95527L8.69838 12.5261C8.98168 12.7697 9.10554 13.1509 9.01954 13.5145L8.11188 17.3517L11.4808 15.3028C11.8 15.1086 12.2009 15.1086 12.5201 15.3028Z"
        fill={color}
      />
    </svg>
  );
}
