import { AppState } from "../reducers";

export function getAudioFiles(state: AppState) {
  return state.files.audioFiles;
}

export function isFileLocked(state: AppState, fileId?: string) {
  if (!fileId) return false;

  return state.files.lockedFiles.includes(fileId);
}
