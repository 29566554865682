export type ReleaseNote = {
  id?: number;
  title: string;
  description: string;
  enabled: boolean;
  timestamp?: number;
}

export const getEmptyReleaseNote = ():ReleaseNote => (
  {
    description: '',
    enabled: false,
    title: '',
  }
);

export type SavedReleaseNote = Required<ReleaseNote>;
