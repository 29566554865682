import { Context as UndoContext } from '../../services/undoContext';

import { ScenarioGraphAction } from "../../types/models/ScenarioGraphAction";
import { ScenarioGraphGroup } from '../../types/models/ScenarioGraphGroup';
import { NodeMetaAction } from "./components/nodeEditor/types";

import { FALLBACK_LINK_INDEX } from './constants';

export enum NodeTypes {
  condition = 'condition',
  response = 'response',
  action = 'action',
  integrationAction = 'integration_action',
  close = 'close',
  operator = 'operator',
  selectScenario = 'select_scenario',
}

export enum NodeTypesHotkeys {
  condition = 'C',
  response = 'R',
  action = 'A',
  close = 'V',
  operator = 'O',
  scenarios = 'S',
}

export type Position = {
  x: number
  y: number
}

export type TargetBound = {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type StageMovementDelta = {
  x: number;
  y: number;
}

export type Meta = {
  nodeId: string;
  title: string;
  action?: ScenarioGraphAction;
  error?: boolean;
  errorDescription?: string;
}

export type UserResponseOption = {
  id: string;
  text: string;
};

export type Bind = {
  from: string;
  to?: string;
}

export type NodeOptions = Option[];

export type Option = {
  id: string;
  text?: string;
}

export interface NodeFunctions {
  update?: (position: Position) => void;
}

export type Node = {
  id: string;
  type: NodeTypes;
  position: Position;
  meta: Meta;
  isFirstNode: boolean;
  tags?: string[];
  new?: boolean; // Флаг, отвечающий за то, была ли хоть раз сохранена нода
                   // Т.е. значение флага является true только для новых нод, которые
                   // ни разу не сохранялись в шторке (после сохранения в шторке этот флаг
                   // ставится в false)
  modified?: boolean;
  isVisible?: boolean;
  chunk: string;
} & NodeFunctions;

export type Link = {
  id: string;
  bind: Bind;
  optionId?: string | typeof FALLBACK_LINK_INDEX;
  label?: string;
  meta?: {
    error?: boolean;
    errorDescription?: string;
    icon?: LinkIcon;
  };
}

export enum LinkIcon {
  userCross = 'user_cross',
}

export type Entry = {
  scenarioName: string
  linkName: string
}

export type Point = Position;

export type BoundingBox = {
  width: number,
  height: number
} & Point;

export type LocalEntry = {linkName: string, scenarioName: string};
export type Sizes = {width: number, height: number} | undefined;
export type ObjectNodes = Record<string, Node>;

export type ObjectLinks = Record<string, Link>;

export type ActiveNodeIdsWithBinds = Record<string, Bind[]>;

export type SelectedAreaProps = { from: Point, to: Point, appendMode?: boolean };

export type SelectedNodeIds = Record<Node['id'], boolean>;

export type StageMovement = { from?: Position, to?: Position };

export type VersionedState = {
  objectNodes: ObjectNodes;
  objectLinks: ObjectLinks;
  nodeToEdit: Node | null;
  scale: number;
  stageMovement: StageMovement;
  selectedNodeIds: Record<Node['id'], boolean>;
  modified: boolean;
  nodeTitle: string;
  nodeData: NodeMetaAction | null;
  nodeTags: Node['tags'];
  groups: ScenarioGraphGroup[];
}

export type GraphUndoContext = UndoContext<VersionedState>;
