import { createAction, createAsyncAction } from 'typesafe-actions';

import { AccountData, AccountStatus, AccountStatuses, AccountType } from '../../types/models/Accounts';

import { ErrorAction } from '../reducers';

export const loadMessengerIntegrations = createAsyncAction(
  'messengerIntegrations/loadMessengerIntegrations/requested',
  'messengerIntegrations/loadMessengerIntegrations/succeeded',
  'messengerIntegrations/loadMessengerIntegrations/failed',
)<
  undefined,
  AccountStatuses,
  ErrorAction
>();

export const createMessengerIntegration = createAsyncAction(
  'messengerIntegrations/createMessengerIntegration/requested',
  'messengerIntegrations/createMessengerIntegration/succeeded',
  'messengerIntegrations/createMessengerIntegration/failed',
)<
  AccountData,
  AccountData,
  ErrorAction
>();

export const updateMessengerIntegration = createAsyncAction(
  'messengerIntegrations/updateMessengerIntegration/requested',
  'messengerIntegrations/updateMessengerIntegration/succeeded',
  'messengerIntegrations/updateMessengerIntegration/failed',
)<
  AccountData,
  AccountData,
  ErrorAction
>();

export const deleteMessengerIntegration = createAsyncAction(
  'messengerIntegrations/deleteMessengerIntegration/requested',
  'messengerIntegrations/deleteMessengerIntegration/succeeded',
  'messengerIntegrations/deleteMessengerIntegration/failed',
)<
  AccountType,
  AccountType,
  ErrorAction
>();

export const enableMessengerIntegration = createAsyncAction(
  'messengerIntegrations/enableMessengerIntegration/requested',
  'messengerIntegrations/enableMessengerIntegration/succeeded',
  'messengerIntegrations/enableMessengerIntegration/failed',
)<
  AccountType,
  AccountType,
  ErrorAction
>();

export const disableMessengerIntegration = createAsyncAction(
  'messengerIntegrations/disableMessengerIntegration/requested',
  'messengerIntegrations/disableMessengerIntegration/succeeded',
  'messengerIntegrations/disableMessengerIntegration/failed',
)<
  AccountType,
  AccountType,
  ErrorAction
>();

export const setMessengerToEdit = createAction(
  'messengerIntegrations/setMessengerToEdit',
)<AccountType | null>();

export const setMessengerStatus = createAction(
  'messengerIntegrations/setMessengerStatus',
)<{ type: AccountType, status: AccountStatus }>();
