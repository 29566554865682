import { isValid, format, isEqual } from "date-fns";

import i18n from "../../../../services/i18n";

import { StatisticsPeriod } from "../../../../types/models/StatisticsPeriod";

import { Categories, Series } from "../../../../components-new/chart";

import { getDateLabel } from "../projectStatisticsChartNew/helpers";
import { DialogsStatisticsGroup, MessageMetrics } from "../../../../types/models/AutomationStatistics";
import { Project, isVoiceProject } from "../../../../types/models/Project";
import { getPercents } from "../../../../services/helpers/utilities";

type CategoriesAndSeriesAndOptions = { categories: Categories, series: Series, yaxis: ApexYAxis[] };

export const getCategoriesAndSeries = (
  project: Project,
  dates: Date[],
  data: DialogsStatisticsGroup[],
  period: StatisticsPeriod,
): CategoriesAndSeriesAndOptions => {
  if (!data.length) {
    return {
      categories: [],
      series: [],
      yaxis: [],
    };
  }

  const categories: Categories = [];

  const series: Series = [
    {
      name: i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.AUTOMATED_PERCENT'),
      data: [],
    }, {
      name: i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.TOTAL'),
      data: [],
    }, {
      name: i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.AUTOMATED'),
      data: [],
    }, {
      name: i18n.t("PAGE_ACCOUNT.MESSAGE_METRICS.NOT_AUTOMATED_DIALOGS"),
      data: [],
      defaultVisible: false,
    }, {
      name: i18n.t("PAGE_ACCOUNT.MESSAGE_METRICS.FORWARDED_NUMBER"),
      data: [],
      defaultVisible: false,
    }, {
      name: i18n.t("PAGE_ACCOUNT.MESSAGE_METRICS.CLOSED_NUMBER"),
      data: [],
      defaultVisible: false,
    }];

  if (isVoiceProject(project)) {
    series.push({
      name: i18n.t("PAGE_ACCOUNT.MESSAGE_METRICS.MINUTES"),
      data: [],
      defaultVisible: false,
    });
  } else {
    series.push({
      name: i18n.t("PAGE_ACCOUNT.MESSAGE_METRICS.MESSAGES_NUMBER"),
      data: [],
      defaultVisible: false,
    }, {
      name: i18n.t("PAGE_ACCOUNT.MESSAGE_METRICS.MESSAGES_AUTOMATED_NUMBER"),
      data: [],
      defaultVisible: false,
    });
  }

  const items: Array<DialogsStatisticsGroup & { date: Date }> = data
    .map(item => ({ ...item, date: new Date(item.title) }));

  dates.forEach(m => {
    categories.push(getDateLabel(m, period));

    const item: DialogsStatisticsGroup | undefined = items
      .find(({ date }) => date && isValid(date) && isEqual(date, m));

    series[0].data.push(getPercents(item?.totalNumber.number || 0, item?.automatedTemp.number || 0));
    series[1].data.push(item?.totalNumber.number || 0);
    series[2].data.push(item?.automatedTemp.number || 0);
    series[3].data.push(item?.notAutomated.number || 0);
    series[4].data.push(item?.automatedForwarded.number || 0);
    series[5].data.push(item?.automatedNotForwarded.number || 0);
    if (isVoiceProject(project)) {
      series[6].data.push(item?.minutes || 0);
    } else {
      series[6].data.push(item?.messagesNumber.number || 0);
      series[7].data.push(item?.messagesAutomatedTempNumber.number || 0);
    }
  });

  const yAxisOptions = series.map<ApexYAxis>(s => getSeriesYAxis(s.name));

  return { categories, series, yaxis: yAxisOptions };
};

const getSeriesYAxis = (seriesName: string): ApexYAxis => {
  let config: ApexYAxis = {
    forceNiceScale: true,
    labels: {
      style: {
        colors: 'rgb(144,144,143)',
        fontSize: '12px',
      },
      align: 'left',
      offsetY: -4,
      offsetX: 40,
      formatter: value => Math.round(value).toString(),
    },
    floating: true,
  };

  if (seriesName === i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.AUTOMATED_PERCENT')) {
    config.forceNiceScale = false;
    config.opposite = true;
    config.min = 0;
    config.max = 100;
    config.labels = {
      offsetX: 28,
      offsetY: -4,
      formatter: value => `${Math.round(value)}%`,
    };
    config.tickAmount = 2;
    config.axisBorder = {
      show: false,
    };
    config.seriesName = seriesName;
  } else {
    config.seriesName = i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.TOTAL');
    config.showAlways = true;
    config.show = seriesName === i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.TOTAL');

    if (!config.show) {
      config = {
        show: false,
        seriesName: i18n.t('PAGE_ACCOUNT.PROJECT_STATISTICS_CHART.TOTAL'),
      };
    }
  }

  return config;
};

export function formatDate(date: string, period: StatisticsPeriod): string {
  if (!isValid(new Date(date))) {
    return date;
  }

  const d = new Date(date);
  switch (period) {
    case StatisticsPeriod.Day:
      return format(d, "dd MMM");
    case StatisticsPeriod.Hour:
      return format(d, "HH:mm dd MMM");
    case StatisticsPeriod.Month:
      return format(d, "MMMM yyyy");
    default:
      return date;
  }
}

export function getDefaultDialogsMetrics(): MessageMetrics[] {
  const defaultMetrics: MessageMetrics[] = [
    MessageMetrics.automatizationNew,
    MessageMetrics.noAutomatization,
    MessageMetrics.userRates,
    MessageMetrics.byMessages,
    MessageMetrics.messagesAutomatedNumber,
  ];

  return defaultMetrics;
}

export function getTableItemClassName(item: DialogsStatisticsGroup) {
  if (item.title === i18n.t("COMMON.IN_TOTAL")) return "statistics-summary";

  return undefined;
}

export function getAdditionalMetrics(item: DialogsStatisticsGroup): MessageMetrics[] {
  const result: MessageMetrics[] = [
    MessageMetrics.automatizationNewTemp,
  ];

  if (!item) return result;

  if (Object.prototype.hasOwnProperty.call(item, "cdsat")) {
    result.push(MessageMetrics.cdsat);
  }

  if (Object.prototype.hasOwnProperty.call(item, "csat")) {
    result.push(MessageMetrics.csat);
  }

  if (Object.prototype.hasOwnProperty.call(item, "nps")) {
    result.push(MessageMetrics.nps);
  }

  return result;
}

export function getUserScores(item: DialogsStatisticsGroup): number[] {
  return Object.keys(item.userScoreCounters || {}).map(count => (count.startsWith('counter') ?
    +count.slice(7) :
    +count));
}
