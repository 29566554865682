export type BalanceClient = {
  id: number;
  name: string;
  isUserLinked: boolean;
  isIntegrationLinked: boolean;
  isPersonCreated: boolean;
  email?: string;
  phone?: string;
  fax?: string;
  url?: string;
  isAgency?: boolean;
  country?: string;
}

export type Person = {
  name: string;
  longname: string;
  phone: string;
  email: string;
  inn: string;
  kpp: string;
  bik: string;
  account: string;
  signerPersonName: string;
  signerPersonGender: Gender;
  signerPositionName: string;
  postAddress: PostAddressWithFias | PostAddressWithoutFias;
  legalAddress: LegalAddressWithFias | LegalAddressWithoutFias;
}

export enum Gender {
  W = "W",
  M = "M"
}

export type PostAddressWithFias = {
  type: AddressType.withFias;
  fiasGuid: string;
  postcode: string;
  postsuffix: string;
}

export type PostAddressWithoutFias = {
  type: AddressType.withoutFias;
  postcode: string;
  postaddress: string;
}

export type LegalAddressWithFias = {
  type: AddressType.withFias;
  legalFiasGuid: string;
  legalAddressPostcode: string;
  legalAddressHome: string;
}

export type LegalAddressWithoutFias = {
  type: AddressType.withoutFias;
  legaladdress: string;
  legalAddressPostcode: string;
}

export enum AddressType {
  withFias = "with_fias",
  withoutFias = "without_fias",
}

export enum Country {
  RU = "RU",
  KZ = "KZ",
}

export const EMPTY_CLIENT_ID = -1;
export const EMPTY_PERSON_NAME = "";

export function getEmptyBalanceClient(): BalanceClient {
  return {
    id: EMPTY_CLIENT_ID,
    name: "",
    isIntegrationLinked: false,
    isPersonCreated: false,
    isUserLinked: false,
    email: "",
    phone: "",
    fax: "",
    url: "",
  };
}

export function isBalanceClientUnsaved(value: BalanceClient): boolean {
  return value.id === EMPTY_CLIENT_ID;
}

export function getEmptyPerson(): Person {
  return {
    name: EMPTY_PERSON_NAME,
    longname: "",
    phone: "",
    email: "",
    inn: "",
    kpp: "",
    bik: "",
    account: "",
    signerPersonGender: Gender.M,
    signerPersonName: "",
    signerPositionName: "",
    postAddress: getEmptyPostAddressWithoutFias(),
    legalAddress: getEmptyLegalAddressWithoutFias(),
  };
}

export function isPersonEmpty(value: Person) {
  return value.name === EMPTY_PERSON_NAME;
}

export function getEmptyPostAddressWithFias(): PostAddressWithFias {
  return {
    type: AddressType.withFias,
    fiasGuid: "",
    postcode: "",
    postsuffix: "",
  };
}

export function getEmptyPostAddressWithoutFias(): PostAddressWithoutFias {
  return {
    type: AddressType.withoutFias,
    postaddress: "",
    postcode: "",
  };
}

export function getEmptyLegalAddressWithFias(): LegalAddressWithFias {
  return {
    type: AddressType.withFias,
    legalFiasGuid: "",
    legalAddressPostcode: "",
    legalAddressHome: "",
  };
}

export function getEmptyLegalAddressWithoutFias(): LegalAddressWithoutFias {
  return {
    type: AddressType.withoutFias,
    legaladdress: "",
    legalAddressPostcode: "",
  };
}
