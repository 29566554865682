import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';

import block from 'bem-cn';

import { ModalProps } from '../../services/modal';

import Modal from '../components/ndModal';
import Input from '../../components-new/input';
import LoadingRow from '../../components/loadingRow';

import * as actions from "../../redux/domains/actions";
import { getActiveDomain, getIsDomainsLoading } from "../../redux/domains/selectors";

import { AllowedDomain } from '../../types/models/Domains';
import { isDomainValid } from '../createDomain';

import './styles.scss';

const b = block('nd-edit-domain-modal');

export type EditDomainModalProps = {
  className?: string,
};

export default function EditDomainModal({
  className = '',
  close,
}: EditDomainModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(getIsDomainsLoading);
  const activeDomain = useSelector(getActiveDomain);

  const [domain, setDomain] = useState<AllowedDomain['domain']>(activeDomain?.domain || "");
  const [tvmName, setTvmName] = useState<AllowedDomain['tvmName']>(activeDomain?.tvmName || "");

  const handleDomainChange = useCallback(
    (_, { value }) => {
      setDomain(value);
    },
    [],
  );

  const handleTvmNameChange = useCallback(
    (_, { value }) => {
      setTvmName(value);
    },
    [],
  );

  const isValid = useMemo(
    () => (
      domain && isDomainValid(domain)
    ),
    [domain],
  );

  const handleSaveClick = useCallback(
    () => {
      if (!activeDomain) return;

      dispatch(actions.updateAllowedDomain.request({
        ...activeDomain,
        tvmName,
        domain,
      }));
    },
    [activeDomain, domain, tvmName, dispatch],
  );

  return (
    <Modal
      className={`${b()} ${className}`}
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.SAVE')}
      title={t("PAGE_WHITELISTS.EDIT_DOMAIN")}
      onConfirm={handleSaveClick}
      confirmDisabled={!isValid || loading}
    >
      <div className={b("content")}>
        <Input
          name="domain"
          value={domain}
          placeholder={t("PAGE_WHITELISTS.DOMAIN")}
          floatingPlaceholder
          onChange={handleDomainChange}
        />
        {domain && !isValid && (
          <span className={b("error")}>{t("PAGE_WHITELISTS.INVALID_DOMAIN")}</span>
        )}
        <Input
          name="tvmName"
          value={tvmName || ""}
          placeholder={t("PAGE_WHITELISTS.TVM_NAME")}
          floatingPlaceholder
          onChange={handleTvmNameChange}
        />
        {loading &&
          <div className={b("loading-row")}>
            <LoadingRow />
          </div>
        }
      </div>
    </Modal>
  );
}
