import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ModalProps } from '../../services/modal';
import Modal from '../components/ndModal';

export type ConfirmDeleteItemModalProps = {
  onConfirm: () => void,
};

export default function ConfirmDeleteItemModal({
  onConfirm,
  close,
}: ConfirmDeleteItemModalProps & ModalProps) {
  const { t } = useTranslation();
  const handleConfirmClick = useCallback(
    () => {
      if (onConfirm) {
        onConfirm();
      }
      close();
    },
    [close, onConfirm],
  );

  return (
    <Modal
      title={t("MODALS.CONFIRM_ACTION.TITLE")}
      onConfirm={handleConfirmClick}
      close={close}
      confirmationText={t("COMMON.YES")}
      confirmButtonStyle="destructive"
      cancelText={t("COMMON.NO")}
    >
      <span>{t("MODALS.CONFIRM_ACTION.MSG_DELETE_ELEMENT")}</span>
    </Modal>
  );
}
