import axios, { AxiosResponse } from "axios";
import NavigationService from './navigation';
import unauthorizedRoutes from '../routes/unauthorized';
import { store } from '../redux/store';
import * as userActions from '../redux/user/actions';
import * as authProviderActions from '../redux/authProviders/actions';
import { parseUser } from '../types/parsers/UserParser';
import { AuthProviderMeta, AuthProviderType } from '../types/models/AuthProvider';
import { User } from '../types/models/User';

import { AuthProviderRequest } from "../types/requests/Authentication";
import { UserAccessBackend } from "../types/backendModels/UserAccessesBackend";
import { UserRole } from "../types/models/UserAccesses";
import { objSnakeToCamel } from "./helpers/utilities";

const authRequester = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/authentication`,
  withCredentials: true,
});

const experimentsRequester = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/experiments`,
  withCredentials: true,
});

function createAuthService() {
  function loginOAuth(provider: AuthProviderMeta): void {
    store.dispatch(authProviderActions.setCurrentAuthProvider(provider));
    window.location.href = provider.url.replace(
      '{REDIRECT}',
      `${window.location.origin}${unauthorizedRoutes.oAuth}`,
    );
  }

  function getSignupOAuthLink(provider: AuthProviderMeta): string {
    return provider.url.replace(
      '{REDIRECT}',
      `${window.location.origin}${unauthorizedRoutes.oAuthSignup}`,
    );
  }

  function testOAuthLogin(): void {
    if (
      !process.env.REACT_APP_ENABLE_TEST_AUTHORIZATION ||
      !process.env.REACT_APP_YANDEX_OAUTH_AUTHORIZATION_TOKEN
    ) { return }

    store.dispatch(authProviderActions.setCurrentAuthProvider({
      name: 'yandex.passport',
      path: 'passport',
      title: 'Test Yandex Passport',
      url: 'some-test-url',
      type: AuthProviderType.YANDEX,
      manualSignup: false,
    }));

    const testToken = process.env.REACT_APP_YANDEX_OAUTH_AUTHORIZATION_TOKEN;
    NavigationService.navigateTo(unauthorizedRoutes.oAuth, undefined, `access_token=${testToken}`);
  }

  function testYaTeamOAuthLogin(): void {
    if (
      !process.env.REACT_APP_ENABLE_TEST_AUTHORIZATION ||
      !process.env.REACT_APP_YANDEX_TEAM_OAUTH_AUTHORIZATION_TOKEN
    ) { return }

    store.dispatch(authProviderActions.setCurrentAuthProvider({
      name: 'yandex-team',
      path: 'yandex-team',
      title: 'Test Yandex Team Passport',
      url: 'some-test-url',
      type: AuthProviderType.YANDEX_TEAM,
      manualSignup: false,
    }));

    const testToken = process.env.REACT_APP_YANDEX_TEAM_OAUTH_AUTHORIZATION_TOKEN;
    NavigationService.navigateTo(unauthorizedRoutes.oAuth, undefined, `access_token=${testToken}`);
  }

  function getProvider(params: AuthProviderRequest): Promise<AxiosResponse> {
    return authRequester.get(`/providers/${params.name}`);
  }

  function getProviders(onlyDefault: boolean = true): Promise<AxiosResponse> {
    return authRequester.get(`/providers?default=${onlyDefault}`);
  }

  function logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('supportai_token');
    localStorage.removeItem('user');
    store.dispatch(userActions.clearUser());
    NavigationService.navigateTo(NavigationService.getLoginFormUrl());
  }

  function isAuthorized(): boolean {
    return !!localStorage.getItem('supportai_token');
  }

  function getUser(): User | null {
    return JSON.parse(localStorage.getItem('user') || 'null');
  }

  function getSupportaiToken(): string {
    return localStorage.getItem('supportai_token') || '';
  }

  function setSupportaiToken(token: string) {
    localStorage.setItem('supportai_token', token);
  }

  function setUser(value: User) {
    if (!value.superadminGroup) value.superadminGroup = UserRole.guest;
    localStorage.setItem('user', JSON.stringify(value));
  }

  function retrieveUser(): Promise<void> {
    return authRequester.get(
      '/user',
      {
        headers: {
          Authorization: `OAuth ${getSupportaiToken()}`,
        },
      },
    )
      .then(response => {
        const parsedUser = parseUser(response.data);
        setUser(parsedUser);
        store.dispatch(userActions.setUser(parsedUser));
      });
  }

  function retrieveExperiments(): Promise<void> {
    return experimentsRequester.get('/', {
      headers: {
        Authorization: `OAuth ${getSupportaiToken()}`,
      },
    }).then(response => {
      store.dispatch(userActions.setConfigs(response.data.configs));
      store.dispatch(userActions.setExperiments(response.data.experiments));
    });
  }

  function retrieveUserAccesses(): Promise<{
    projects: UserAccessBackend[],
    accessesMap: string,
  }> {
    return authRequester.get(
      '/user/accesses',
      {
        params: {
          new: true,
        },
        headers: {
          Authorization: `OAuth ${getSupportaiToken()}`,
        },
      },
    ).then(response => objSnakeToCamel(response.data));
  }

  function login(provider: AuthProviderMeta, data: object = {}): Promise<void> {
    return authRequester.post(
      provider.path,
      data,
    )
      .then(response => {
        setSupportaiToken(response.data.token);
      })
      .catch(err => {
        console.error(err);
      });
  }

  function signup(provider: AuthProviderMeta, data?: object): Promise<void> {
    return authRequester.post(
      `${provider.path}/signup`,
      data,
    )
      .then(response => {
        setSupportaiToken(response.data.token);
      })
      .catch(err => {
        console.error(err);
      });
  }

  return {
    loginOAuth,
    getSignupOAuthLink,
    logout,
    getProviders,
    getProvider,
    getSupportaiToken,
    getUser,
    setSupportaiToken,
    setUser,
    login,
    signup,
    isAuthorized,
    testOAuthLogin,
    testYaTeamOAuthLogin,
    retrieveUser,
    retrieveExperiments,
    retrieveUserAccesses,
  };
}

export default createAuthService();
