export type CustomConfig = {
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
}

export function getEmptyCustomConfig(): CustomConfig {
  return {
    id: '',
    value: {},
  };
}
