import React, { FC } from 'react';
import { SvgProps } from '../types';

const ChevronRightIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6732 16.9512C10.8607 17.1387 11.115 17.244 11.3802 17.244C11.6453 17.244 11.8996 17.1387 12.0872 16.9512L16.3302 12.7082C16.5176 12.5207 16.623 12.2664 16.623 12.0012C16.623 11.7361 16.5176 11.4818 16.3302 11.2942L12.0872 7.05024C11.8986 6.86808 11.646 6.76729 11.3838 6.76957C11.1216 6.77185 10.8708 6.87702 10.6854 7.06242C10.4999 7.24783 10.3948 7.49865 10.3925 7.76084C10.3902 8.02304 10.491 8.27564 10.6732 8.46424L14.2082 12.0002L10.6732 15.5352C10.5802 15.6281 10.5064 15.7384 10.4561 15.8598C10.4058 15.9812 10.3799 16.1113 10.3799 16.2427C10.3799 16.3742 10.4058 16.5043 10.4561 16.6257C10.5064 16.7471 10.5802 16.8574 10.6732 16.9502V16.9512Z" fill={color} />
  </svg>
);

export default ChevronRightIcon;
