import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import NotificationManager from '../../services/notifications';

import { ModalProps } from '../../services/modal';
import { DialogsHistorySearchFilter } from '../../types/models/Dialog';
import Modal from '../components/ndModal';
import DialogsSearchFilterOverview from '../../components/dialogsSearchFilterOverview';
import Input from '../../components-new/input';

import './styles.scss';

export type CreateSamplingModalProps = {
  filters: DialogsHistorySearchFilter,
  onConfirm: (params: {
    name: string,
    quantity: number,
    filters: DialogsHistorySearchFilter,
  }) => void,
  withQuantity?: boolean,
}

export default function CreateSamplingModal({
  filters,
  close,
  onConfirm,
  withQuantity = true,
}: ModalProps & CreateSamplingModalProps) {
  const { t } = useTranslation();
  const [name, setName] = useState<string>(getDefaultSamplingName());
  const [quantity, setQuantity] = useState<number>(1);

  function getDefaultSamplingName() {
    const dateFrom = format(new Date(filters.dateFrom), "dd.MM");
    const dateTo = filters.dateTo ?
      (`- ${format(new Date(filters.dateTo), "dd.MM")}`) :
      "";

    return `${dateFrom} ${dateTo}`;
  }

  const handleCloseClick = useCallback(
    () => {
      close();
    },
    [close],
  );

  const handleConfirmClick = useCallback(
    () => {
      if (quantity < 1) {
        NotificationManager.info(t("MODALS.CREATE_SAMPLING.ERRORS.MIN_SAMPLING_SIZE"));
        return;
      }

      onConfirm({
        name,
        quantity,
        filters,
      });
      close();
    },
    [
      filters,
      name,
      quantity,
      t,
      close,
      onConfirm,
    ],
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  const handleQuantityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuantity(+event.target.value);
    },
    [],
  );

  return (
    <Modal
      className="create-samplings-modal"
      title={t("MODALS.CREATE_SAMPLING.TITLE")}
      onConfirm={handleConfirmClick}
      onCancel={handleCloseClick}
      close={close}
      cancelText={t("MODALS.CREATE_SAMPLING.CANCEL")}
      confirmationText={t("MODALS.CREATE_SAMPLING.CREATE")}
    >
      <div className="column-2">
        <div className="row-2">
          <div className="name-container">
            <Input
              name="name"
              value={name}
              placeholder={t("MODALS.CREATE_SAMPLING.SAMPLING_NAME")}
              floatingPlaceholder
              onChange={handleNameChange}
            />
          </div>
          {withQuantity && (
            <div className="quantity-container">
              <Input
                className="quantity-input"
                type="number"
                name="quantity"
                min={1}
                value={quantity.toString()}
                placeholder={t("MODALS.CREATE_SAMPLING.SAMPLING_SIZE")}
                floatingPlaceholder
                onChange={handleQuantityChange}
              />
            </div>
          )}
        </div>
        <span>
          {t("MODALS.CREATE_SAMPLING.MSG_APPLIED_FILTERS")}
          :
        </span>
        <DialogsSearchFilterOverview
          filters={filters}
          hide={["samplingDeprecated"]}
        />
      </div>
    </Modal>
  );
}
