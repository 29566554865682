import { useCallback, useEffect, useMemo, useState } from "react";
import { MenuProps, components } from "react-select";
import { useTranslation } from "react-i18next";
import { block } from "bem-cn";

import { checkIfStringIncludes, divideBy, preventDefaultEvent } from "../../../services/helpers/utilities";

import Button from "../../button";
import Checkbox from "../../checkbox";

import { ConfigurableSelectOption, MultiOperator } from "../configurable";
import Icon, { IconSize } from "../../icon";
import Radio from "../../radio";
import Input from "../../input";

import './multiMenu.scss';

const b = block('select-multi-menu');

export function getMultiMenu(
  withSelectAll?: boolean,
  withOperator?: boolean,
  searchField?: {
    placeholder: string;
  },
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MultiMenu = (props: MenuProps<any>) => {
    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = useState<ConfigurableSelectOption[]>(props.selectProps.value || []);
    const [multiOperator, setMultiOperator] = useState<MultiOperator>(props.selectProps.operator || MultiOperator.and);
    const [searchFilter, setSearchFilter] = useState<string>("");

    const inputValue = props.selectProps.inputValue;
    const options = props.options;

    const toggleOption = useCallback(
      (e: React.MouseEvent) => {
        if (e.defaultPrevented) return;
        e.preventDefault();

        const value = (e.currentTarget as HTMLButtonElement).name;
        setSelectedOptions(selected => {
          if (selected?.some(option => option.value === value)) {
            return selected.filter(option => option.value !== value);
          }
          const selectedOption = options.find(o => o.value === value);

          if (!selectedOption) return selected;

          return [...selected, selectedOption];
        });
      },
      [options],
    );

    const applyChanges = useCallback(
      () => {
        if (!props.selectProps.onChange) return;
        props.selectProps.onChange(
          selectedOptions,
          {
            action: "set-value",
            // @ts-expect-error
            operator: multiOperator,
          },
        );

        (document.activeElement as HTMLElement).blur();
      },
      [
        multiOperator,
        props.selectProps,
        selectedOptions,
      ],
    );

    const createOption = useCallback(
      () => {
        props.selectProps.onCreateOption(inputValue);
      },
      [inputValue, props.selectProps],
    );

    const toggleAll = useCallback(
      () => {
        if (!props.selectProps.onChange) return;

        const allSelected = selectedOptions.length === options.length;
        if (allSelected) {
          setSelectedOptions([]);
        } else {
          setSelectedOptions(options as ConfigurableSelectOption[]);
        }
      },
      [
        options,
        props.selectProps.onChange,
        selectedOptions?.length,
      ],
    );

    const handleIconClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        const optionValue = event.currentTarget.name;
        if (!optionValue) return;

        const option = options.find(o => o.value === optionValue);
        if (!option || !option.onIconClick) return;

        (document.activeElement as HTMLElement).blur();

        option.onIconClick(event, optionValue);
      },
      [options],
    );

    const handleSearchFilterChange = useCallback(
      (_, { value }) => {
        setSearchFilter(value);
      },
      [],
    );

    const visibleOptions = useMemo(
      () => {
        const visible = options
          .filter(o => !o.__isNew__)
          .filter(o => checkIfStringIncludes(o.label, (inputValue || '')));

        const currentValues: string[] = (props.selectProps.value || [])
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((o: any) => o.value);
        const [selected, rest] = divideBy(
          visible,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (option: any) => currentValues.includes(option.value),
        );
        selected.sort();
        rest.sort();

        return [...selected, ...rest];
      },
      [
        inputValue,
        options,
        props.selectProps.value,
      ],
    );

    const showCreatable = (
      !!props.selectProps.onCreateOption &&
      (inputValue || "").length >= 1 &&
      !options.some(o => o.label === inputValue)
    );

    useEffect(
      () => {
        setSelectedOptions(props.selectProps.value);
      },
      [props.selectProps.value],
    );

    return (
      <components.Menu
        {...props}
        className={b()}
      >
        <>
          {!!searchField && (
            <Input
              name="search"
              value={searchFilter}
              floatingPlaceholder
              placeholder={searchField.placeholder}
              onChange={handleSearchFilterChange}
            />
          )}
          {withOperator && options.length > 1 && (
            <>
              <div className={b("operator")}>
                <div className="row-2">
                  <label className="row-2">
                    <Radio
                      name="operator"
                      value={MultiOperator.or}
                      isChecked={multiOperator === MultiOperator.or}
                      // @ts-expect-error
                      onChange={setMultiOperator}
                    />
                    <span>{t("COMMON.OR")}</span>
                  </label>
                  <label className="row-2">
                    <Radio
                      name="operator"
                      value={MultiOperator.and}
                      isChecked={multiOperator === MultiOperator.and}
                      // @ts-expect-error
                      onChange={setMultiOperator}
                    />
                    <span>{t("COMMON.AND")}</span>
                  </label>
                </div>
              </div>
              <div className={b("delimiter")} />
            </>
          )}
          {(withSelectAll && !!options.length) && (
            <div className="column">
              <Button
                simple
                fluid
                name="all"
                size="large"
                className={b("option")}
                onClick={toggleAll}
              >
                <Checkbox
                  readonly
                  value={selectedOptions.length === options.length}
                  name="all-selected"
                  onClick={preventDefaultEvent}
                />
                <div className={b("text")}>
                  <span>{`${t("COMMON.SELECT_ALL")} (${options.length}${props.selectProps.allSelectedLabel ? (" " + props.selectProps.allSelectedLabel(options.length)) : ''})`}</span>
                </div>
              </Button>
              <div className={b("delimiter")} />
            </div>
          )}
          <div
            className={b("list", {
              "with-select-all": withSelectAll,
              "with-operator": withOperator,
            })}
          >
            {options.length === 0 && (
              <span className={b("no-data").mix("text-secondary body-1")}>
                {t("COMMON.NO_DATA")}
              </span>
            )}
            {showCreatable && (
              <Button
                className={b("option")}
                fluid
                simple
                size="large"
                onClick={createOption}
              >
                <div className={b("text-container")}>
                  <span>{inputValue}</span>
                  <span className="caption-2 text-minor">
                    {props.selectProps.createOptionSubtitle ?? (
                      <>
                        {t("COMMON.CREATE")}
                        &nbsp;
                        {inputValue}
                      </>
                    )}
                  </span>
                </div>
              </Button>
            )}
            {visibleOptions.map(option => (
              <Button
                key={option.value}
                simple
                name={option.value}
                size="large"
                className={b("option").mix(String(option.className))}
                disabled={option.disabled}
                onClick={toggleOption}
              >
                <Checkbox
                  readonly
                  value={selectedOptions?.some(o => o.value === option.value)}
                  name={option.value}
                  onClick={toggleOption}
                />
                <div className={b("text-container")}>
                  <span className={b("text")}>{option.label}</span>
                  {option.subtitle && (
                    <span className={b("subtitle").mix("caption-1")}>
                      {option.subtitle}
                    </span>
                  )}
                </div>
                {option.icon && (
                  option.onIconClick ? (
                    <Button
                      className={b("icon")}
                      simple
                      name={option.value}
                      icon={option.icon}
                      onClick={handleIconClick}
                    />
                  ) : (
                    <Icon
                      className={b("icon")}
                      type={option.icon}
                    />
                  )
                )}
              </Button>
            ))}
          </div>
          <div className={b("delimiter")} />
          <Button
            className={b("apply-button")}
            primary
            fluid
            size="small"
            onClick={applyChanges}
          >
            {t("COMMON.APPLY")}
          </Button>
          {props.selectProps.isLoading && (
            <div className={b("loading")}>
              <Icon iconSize={IconSize.large} type="loaderBrackets" />
            </div>
          )}
        </>
      </components.Menu>
    );
  };

  return MultiMenu;
}
