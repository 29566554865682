import React, {
  BaseSyntheticEvent,
  FC, memo, ReactElement, useCallback, useRef,
} from 'react';

import MaterialTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { ClickAwayListener } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import block from 'bem-cn';

import useEffectWithoutInitial from '../../hooks/useEffectWithoutInitial';

import Button, { ButtonMouseEventHandler, ButtonProps } from '../button';

import './styles.scss';

export type Props = {
  text?: string;
  children?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  trigger?: ReactElement;
  buttonProps?: Partial<ButtonProps>;
  placement?: TooltipProps['placement'];
  popperProps?: TooltipProps['PopperProps'];
  disableHoverListener?: boolean;
  disableClickListener?: boolean;
  withWrapper?: boolean;
  closeAfterClick?: boolean;
  dark?: boolean;
  mouseEnterDelay?: number;
  wrapperClassName?: string;
  leaveDelay?: number;
  onTriggerClick?: ButtonMouseEventHandler;
  onToggleOpen?: (open: boolean) => void;
}

const b = block('nd-popup-with-trigger');

const PopupWithTrigger: FC<Props> = ({
  buttonProps = {},
  popperProps = {},
  disableHoverListener = false,
  disableClickListener = false,
  mouseEnterDelay = 500,
  text = '',
  withWrapper = false,
  children,
  trigger,
  dark,
  wrapperClassName = '',
  placement,
  leaveDelay,
  onTriggerClick,
  onToggleOpen = () => {},
  closeAfterClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const hoverTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleTriggerClick = useCallback<ButtonMouseEventHandler>(
    e => {
      e.stopPropagation();

      if (!disableClickListener) {
        setOpen(!open);
      }

      if (onTriggerClick) {
        onTriggerClick(e);
      }
    },
    [disableClickListener, open, onTriggerClick],
  );

  const handleTriggerEnter = useCallback<ButtonMouseEventHandler>(
    () => {
      if (disableHoverListener) return;

      hoverTimer.current = setTimeout(
        () => { setOpen(true) },
        mouseEnterDelay,
      );
    },
    [disableHoverListener, mouseEnterDelay],
  );

  const handleTriggerLeave = useCallback<ButtonMouseEventHandler>(
    () => {
      if (disableHoverListener) return;

      if (hoverTimer.current) {
        clearTimeout(hoverTimer.current);
        hoverTimer.current = null;
      }
      setOpen(false);
    },
    [disableHoverListener],
  );

  useEffectWithoutInitial(
    () => {
      setOpen(false);

      if (hoverTimer.current) {
        clearTimeout(hoverTimer.current);
        hoverTimer.current = null;
      }

      return () => {
        if (hoverTimer.current) {
          clearTimeout(hoverTimer.current);
        }
      };
    },
    [handleTriggerEnter],
  );

  useEffectWithoutInitial(
    () => {
      onToggleOpen(open);
    },
    [open],
  );

  const handleClose = useCallback(
    (e: BaseSyntheticEvent) => {
      if (e.defaultPrevented) return;

      setOpen(false);
    },
    [],
  );

  const handleTooltipClick = useCallback(
    () => {
      if (closeAfterClick) {
        setOpen(false);
      }
    },
    [closeAfterClick],
  );

  const handleAwayClick = useCallback(
    (e: React.MouseEvent<Document>) => {
      if (!tooltipRef.current) {
        handleClose(e);
        return;
      }

      if (tooltipRef.current.contains(e.target as HTMLElement)) {
        return;
      }
      handleClose(e);
    },
    [handleClose],
  );

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <MaterialTooltip
        interactive
        disableHoverListener={disableHoverListener}
        disableFocusListener
        PopperProps={popperProps}
        placement={placement}
        title={children ? (
          <div
            ref={tooltipRef}
            onClick={handleTooltipClick}
          > {children}
          </div>
        ) : (
          <div
            className={b({ dark })}
            onClick={handleTooltipClick}
          >
            {text}
          </div>
        )}
        onClose={handleClose}
        open={open}
        leaveDelay={leaveDelay}
      >
        {withWrapper ? (
          <span
            className={`nd-button-wrapper ${wrapperClassName}`}
            onMouseEnter={handleTriggerEnter}
            onMouseLeave={handleTriggerLeave}
            >
            {trigger ?? (
            <Button
              {...buttonProps}
              onClick={handleTriggerClick}
                />
            )}
          </span>
        ) : (
          <Button
            {...buttonProps}
            onMouseEnter={handleTriggerEnter}
            onMouseLeave={handleTriggerLeave}
            onClick={handleTriggerClick}
            />
        )}
      </MaterialTooltip>
    </ClickAwayListener>
  );
};

const PopupWithoutStyles = withStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent',
    width: 'auto',
    maxWidth: 'none',
  },
}))(PopupWithTrigger);

export default memo(PopupWithoutStyles);
