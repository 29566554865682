import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import * as actions from './actions';

export type ButterflyState = {
  isAvailable: boolean;
}

const initialState: ButterflyState = {
  isAvailable: false,
};

export const butterflyReducer: Reducer<ButterflyState> = createReducer(initialState)
  .handleAction(
    actions.checkAvailability.success,
    (state: ButterflyState, { payload }: ActionType<typeof actions.checkAvailability.success>): ButterflyState => ({
      ...state,
      isAvailable: payload,
    }),
  );
