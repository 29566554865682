import { generateId } from "../../services/helpers/generateId";
import { objSnakeToCamel } from "../../services/helpers/utilities";
import { Cluster } from "../models/Clustering";
import { GetClustersDetailResponse, GetClustersOverviewResponse } from "../requests/Clustering";

export function parseClusters(clusters: GetClustersOverviewResponse['clusters']): Cluster[] {
  return clusters.map(cluster => ({
    id: generateId(),
    total: cluster.total_items,
    clusterNumber: cluster.cluster_number,
    clusterItemsShort: cluster.cluster_items,
    clusterItemsFull: [],
  }));
}

export function parseClustersResults(clusterItems: GetClustersDetailResponse['cluster_items']): Cluster['clusterItemsFull'] {
  return clusterItems.map(item => objSnakeToCamel(item));
}
