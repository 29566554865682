import {
  call,
  put,
  select,
  takeLatest,
  fork,
  delay,
  takeEvery,
} from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import apiService, { ApiResponse } from "../../services/api";
import i18n from "../../services/i18n";
import modalService from "../../services/modal";
import { parseVersion } from "../../types/parsers/VersionParser";
import { ProjectRequestParams } from "../../types/requests/Projects";
import {
  ActivateVersionRequest,
  CreateVersionRequest,
  CreateVersionResponse,
  DeleteVersionRequest,
  GetVersionsRequest,
  GetVersionsResponse,
  ReloadCurrentVersionResponse,
} from "../../types/requests/Versions";
import { ErrorAction } from "../reducers";
import { getGeneralRequestParams } from "../selectors";

import * as actions from './actions';
import * as projectsActions from "../projects/actions";
import ApiError from "../../types/models/ApiError";
import { DRAFT_VERSION } from "./reducer";
import { getActiveVersion, getCurrentVersionToDisplay, getVersionsAvailableToSwitch } from "./selectors";
import { Version, VersionStatus } from "../../types/models/Version";
import NotificationService from "../../services/notifications";
import navigationService from "../../services/navigation";

export const CHECK_VERSION_INTERVAL = 5000; // Ms

function* loadVersions(): SagaIterator {
  yield takeLatest(actions.loadVersions.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const params: GetVersionsRequest = general;
      const response: ApiResponse<GetVersionsResponse> = yield call(
        apiService.loadVersions,
        params,
      );

      const parsedVersions = response.data.versions.map(bVersion => parseVersion(bVersion));
      yield put(actions.loadVersions.success(parsedVersions));
    } catch (err) {
      yield put(actions.loadVersions.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.VERSIONS.MULTIPLE") }))));
    }
  });
}

function* createVersion(): SagaIterator {
  yield takeLatest(actions.createVersion.request, function* (action) {
    try {
      const { payload: { versionName, modalId } } = action;
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: CreateVersionRequest = {
        query: {
          ...general,
        },
        body: {
          name: versionName,
        },
      };

      const response: ApiResponse<CreateVersionResponse> = yield call(
        apiService.createVersion,
        params,
      );

      if (modalId) {
        modalService.close(modalId);
      }
      const parsedVersion = parseVersion(response.data);
      yield put(actions.createVersion.success(parsedVersion));
    } catch (err) {
      yield put(actions.createVersion.failure(new ErrorAction(err, i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.VERSIONS.SINGLE") }))));
    }
  });
}

function* deleteVersion(): SagaIterator {
  yield takeLatest(actions.deleteVersion.request, function* (action) {
    const { payload: versionId } = action;
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: DeleteVersionRequest = {
        ...general,
        version_id: versionId,
      };

      yield call(apiService.deleteVersion, params);
      yield put(actions.deleteVersion.success(versionId));
      yield delay(500); // Даём время бэку обновить статус версии
      yield put(actions.loadVersions.request());
      NotificationService.success(i18n.t("PAGE_MANAGE_VERSIONS.VERSION_DELETED"));
    } catch (err) {
      const isActual = (err as ApiError).code === 'not_zero_percentage';
      if (isActual) (err as ApiError).message = i18n.t("ERRORS.API.VERSIONS.ACTUAL");
      yield put(actions.deleteVersion.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.VERSIONS.SINGLE") }),
        { id: versionId },
      )));
    }
  });
}

function* saveVersions(): SagaIterator {
  yield takeLatest(actions.saveVersions.request, function* () {
    try {
      const activeVersion: Version | undefined = yield select(getActiveVersion);
      if (!activeVersion) {
        throw new Error();
      }

      const params: ActivateVersionRequest = {
        body: {
          active_version_id: +activeVersion.id,
        },
      };

      yield call(
        apiService.activateVersion,
        params,
      );

      yield put(actions.saveVersions.success(activeVersion));
      yield delay(500); // Даём время бэку обновить статус версии
      yield put(actions.loadVersions.request());
      NotificationService.success(i18n.t("PAGE_MANAGE_VERSIONS.VERSION_SAVED"));
    } catch (err) {
      yield put(actions.loadVersions.request());
      yield put(actions.saveVersions.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.VERSIONS.MULTIPLE") }),
      )));
    }
  });
}

function* reloadCurrentVersionStatus(): SagaIterator {
  yield takeLatest(actions.reloadCurrentVersionStatus.request, function* () {
    try {
      const response: ApiResponse<ReloadCurrentVersionResponse> = yield call(
        apiService.reloadDraftVersion,
      );

      yield put(actions.reloadCurrentVersionStatus.success(response.data.status as VersionStatus));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.VERSIONS.SINGLE") });
      yield put(actions.reloadCurrentVersionStatus.failure(new ErrorAction(
        err,
        text,
      )));
    }
  });
}

function* checkQueryVersion(): SagaIterator {
  yield takeLatest(actions.loadVersions.success, function* () {
    const queryVersion = navigationService.getSearchParam('version');
    if (!queryVersion) return;

    const availableVersions: Version[] = yield select(getVersionsAvailableToSwitch);

    if (!availableVersions.some(v => v.id === queryVersion)) {
      yield put(actions.changeVersionToDisplay(DRAFT_VERSION.id));
    } else {
      const activeVersion: Version['id'] = yield select(getCurrentVersionToDisplay);

      if (queryVersion !== activeVersion) {
        yield put(actions.changeVersionToDisplay(queryVersion));
      }
    }
  });
}

function* addVersionParam(): SagaIterator {
  yield takeEvery(actions.changeVersionToDisplay, function* () {
    const activeVersion: string = yield select(getCurrentVersionToDisplay);
    navigationService.addSearchParam('version', activeVersion);
  });
}

function* resetDraftVersion(): SagaIterator {
  yield takeLatest(projectsActions.changeProject, function* () {
    yield put(actions.changeVersionToDisplay(DRAFT_VERSION.id));
  });
}

export default function* versionsSagas(): SagaIterator {
  yield fork(loadVersions);
  yield fork(createVersion);
  yield fork(deleteVersion);
  yield fork(saveVersions);
  yield fork(reloadCurrentVersionStatus);
  yield fork(resetDraftVersion);
  yield fork(checkQueryVersion);
  yield fork(addVersionParam);
}
