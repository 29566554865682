import { useCallback, useEffect, useRef, useState } from 'react';
import { block } from 'bem-cn';

import { AudioFileMetadata } from '../../../types/models/Files';

import { Editable } from '../../../redux/reducers';

import apiService from '../../../services/api';
import { arrayBufferToDOMString } from '../../../services/helpers/utilities';

import Button from '../../button';

import Audio from '../audio';
import AudioMeta from '../audioMeta';
import Radio from '../../radio';

import './styles.scss';

const b = block('audio-item');

type RobotAudioProps = {
  meta: Editable<AudioFileMetadata>,
  selected?: boolean,
  onSelect?: (filename: string) => void,
  onEditClick?: (filename: string) => void,
}

export default function AudioItem({
  meta,
  selected,
  onSelect,
  onEditClick,
}: RobotAudioProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [audio, setAudio] = useState<HTMLAudioElement>();

  useEffect(() => {
    const fetchAudio = async() => {
      const response = await apiService.getAudioFile(meta.filename);

      const url = arrayBufferToDOMString(response.data);

      const a = document.createElement('audio');

      wrapperRef.current?.appendChild(a);
      a.src = url;
      setAudio(a);
    };

    fetchAudio();
  }, [meta.filename]);

  const handleRadioChange = useCallback(
    () => {
      if (onSelect) {
        onSelect(meta.filename);
      }
    },
    [meta.filename, onSelect],
  );

  const handleEditClick = useCallback(
    () => {
      if (onEditClick) {
        onEditClick(meta.filename);
      }
    },
    [meta.filename, onEditClick],
  );

  return (
    <div
      className={b()}
    >
      <div className={b("wrapper")}>
        <div ref={wrapperRef} className={b("audio-wrapper")}>
          {audio && <Audio audio={audio} />}
        </div>
        <AudioMeta
          filename={meta.userFilename || meta.filename}
          subtitle={meta.userComment}
          duration={audio?.duration}
        />
        <div className={b("actions")}>
          {onEditClick && (
            <Button
              className={b("edit-button")}
              simple
              icon="pen"
              onClick={handleEditClick}
            />
          )}
          {onSelect && (
            <Radio
              className={b("select-button", { selected })}
              isChecked={selected}
              value={meta.filename}
              onChange={handleRadioChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}
