import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { block } from "bem-cn";
import { format } from 'date-fns';

import { ModalProps } from '../../services/modal';
import { CallFilters } from '../../pages/voice/types';

import Input from '../../components-new/input';

import Modal from '../components/ndModal';

import './styles.scss';

const b = block("export-calls-modal");

export type ExportCallsModalProps = {
  filters: CallFilters,
  onSubmit: (name: string, filters: CallFilters) => void,
}

const emptyFilter: string = "-";

export default function ExportCallsModal({
  filters,
  onSubmit,
  close,
}: ExportCallsModalProps & ModalProps) {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(format(new Date(), "dd.MM.yyyy HH:mm"));

  const handleSubmit = useCallback(() => {
    onSubmit(name, filters);
  }, [filters, name, onSubmit]);

  const handleNameChange = useCallback(
    (_, { value }) => {
      setName(value);
    },
    [],
  );

  return (
    <Modal
      className={b()}
      title={t("MODALS.EXPORT_CALLS.TITLE")}
      close={close}
      cancelText={t("COMMON.CANCEL")}
      confirmationText={t("MODALS.EXPORT_CALLS.EXPORT")}
      confirmDisabled={!name}
      onConfirm={handleSubmit}
    >
      <div className={b("filters-wrapper")}>
        <Input
          className={b("name-input")}
          value={name}
          name="name"
          onChange={handleNameChange}
          placeholder={t("TASKS.EXPORT_NAME")}
          floatingPlaceholder
        />
        <div>
          <span className={b("filter-name")}>{t("MODALS.EXPORT_CALLS.DATE_FROM")}</span>
          <span className={b("filter-value")}>
            {filters.newerThan ?
              format(new Date(filters.newerThan), "dd MMM yyyy HH:mm") :
              emptyFilter}
          </span>
        </div>
        <div>
          <span className={b("filter-name")}>{t("MODALS.EXPORT_CALLS.DATE_TO")}</span>
          <span className={b("filter-value")}>
            {filters.olderThan ?
              format(new Date(filters.olderThan), "dd MMM yyyy HH:mm") :
              "-"}
          </span>
        </div>
        <div>
          <span className={b("filter-name")}>{t("PAGE_VOICE.PHONE")}</span>
          <span className={b("filter-value")}>{filters.phone || emptyFilter}</span>
        </div>
        <div>
          <span className={b("filter-name")}>{t("PAGE_VOICE.DIALOG_ID")}</span>
          <span className={b("filter-value")}>{filters.chatId || emptyFilter}</span>
        </div>
        <div>
          <span className={b("filter-name")}>{t("PAGE_VOICE.DIRECTION")}</span>
          <span className={b("filter-value")}>
            {filters.direction ?
              t(`PAGE_VOICE.CALL_DIRECTION.${filters.direction.toUpperCase()}`) :
              emptyFilter}
          </span>
        </div>
        <div>
          <span className={b("filter-name")}>{t("MODALS.EXPORT_CALLS.STATUSES")}</span>
          <span>
            {Array.isArray(filters.statuses) && filters.statuses.length > 0 ?
              filters.statuses.map(status => t(`PAGE_VOICE_DETAILS.CALL_STATUSES.${status.toUpperCase()}`)).join(", ") :
              emptyFilter}
          </span>
        </div>
      </div>
    </Modal>
  );
}
