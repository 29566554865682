import { createAction, createAsyncAction } from "typesafe-actions";
import { ScenarioGraphGroup } from "../../types/models/ScenarioGraphGroup";
import { ErrorAction } from "../reducers";

export const loadGraphGroups = createAsyncAction(
  'graphGroups/loadGraphGroups/requested',
  'graphGroups/loadGraphGroups/succeeded',
  'graphGroups/loadGraphGroups/failed',
)<
  undefined,
  ScenarioGraphGroup[],
  ErrorAction
>();

export const openCreateModal = createAction('graphGroups/openCreateModal')();
export const closeCreateModal = createAction('graphGroups/closeCreateModal')();

export const openEditModal = createAction('graphGroups/openEditModal')();
export const closeEditModal = createAction('graphGroups/closeEditModal')();

export const openDeleteModal = createAction('graphGroups/openDeleteModal')();
export const closeDeleteModal = createAction('graphGroups/closeDeleteModal')();

export const saveGraphGroups = createAsyncAction(
  'graphGroups/saveGraphGroups/requested',
  'graphGroups/saveGraphGroups/succeeded',
  'graphGroups/saveGraphGroups/failed',
)<ScenarioGraphGroup[], ScenarioGraphGroup[], ErrorAction>();
