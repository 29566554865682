import React, { useCallback } from 'react';
import { block } from 'bem-cn';
import { useSelector } from 'react-redux';

import navigationService from '../../services/navigation';
import postYaMetric, { YaMetricsActions } from '../../services/ya-metrics';

import { isVoiceProject } from '../../types/models/Project';

import authorizedPages, { getPagePath } from '../../routes/authorized';

import { getSelectedProject } from '../../redux/projects/selectors';

import Icon from '../icon';

import { b as parentB } from './index';
import './menuWrapper.scss';

const b = block("project-selector-menu-wrapper");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuWrapper = ({ children, ...props }: any) => {
  const selectedProject = useSelector(getSelectedProject);

  const handleSelectedProjectClick = useCallback(
    () => {
      navigationService.navigateTo(authorizedPages.account);

      const pagePath = getPagePath(authorizedPages.account);
      postYaMetric(YaMetricsActions.onSidebarPageClick + pagePath as YaMetricsActions);
    },
    [],
  );
  return (
    <div
      className={b()}
      {...props}
    >
      <button
        onClick={handleSelectedProjectClick}
        type="button"
        className={b("current-project")}
      >
        <Icon className={b("project-icon")} type={isVoiceProject(selectedProject) ? "phone" : "chat"} />
        <span>{selectedProject.title}</span>
        <Icon type="chevronRightThin" />
      </button>
      <div className={parentB("delimiter")} />
      { children }
    </div>
  );
};

export default MenuWrapper;
