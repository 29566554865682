import {
  useCallback, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import { ScenarioGraphNode } from '../../types/models/ScenarioGraphNode';
import { CountScenarioNodesResponse } from '../../types/requests/Tags';

import { ModalProps } from '../../services/modal';
import apiService from '../../services/api';
import { objSnakeToCamel } from '../../services/helpers/utilities';

import { getActiveGraph } from '../../redux/graph/selectors';
import * as graphActions from '../../redux/graph/actions';
import * as actions from '../../redux/tags/actions';

import Modal from '../components/ndModal';

import './styles.scss';

const b = block('nd-delete-tag-confirm-modal');

export type Data = {
    scenarioTitle: string,
    counts: number
};

export type DeleteTagConfirmModalProps = {
  className?: string,
  id: string,
  value: string,
};

export default function DeleteTagConfirmModal({
  className = '',
  id,
  value,
  close,
}: DeleteTagConfirmModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [data, setData] = useState<Data[]|undefined>();

  const activeGraph = useSelector(getActiveGraph);

  useEffect(() => {
    apiService.getCountScenrarioNodesByTag({
      tag_id: id,
    }).then(res => {
      const info = res.data.nodes_counts.map((el: CountScenarioNodesResponse) => objSnakeToCamel(el)) as Data[];
      setData(info);
    });
  }, [id]);

  const handleDeleteTag = useCallback(
    () => {
      dispatch(actions.deleteTag.request(id));

      const currentNodes = activeGraph.nodes;
      const filteredNodes: ScenarioGraphNode[] = currentNodes.map(node =>
        ({
          ...node,
          tags: node.tags.filter(tag => tag.slug !== value),
        }),
      );
      dispatch(graphActions.changeActiveGraph({
        ...activeGraph,
        nodes: filteredNodes,
      }));
    },
    [dispatch, id, activeGraph, value],
  );

  return (
    <Modal
      className={`${b()} ${className}`}
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.DELETE')}
      confirmButtonStyle="destructive"
      title={t('TAGS.DELETE_TAG', { tag: value })}
      onConfirm={handleDeleteTag}
    >
      { t('TAGS.DELETE_INFO', { nodeCount: data ? data?.reduce((count, el) => count += el.counts, 0) : 'X', scenarioCount: data ? data?.length : 'X' }) }
    </Modal>
  );
}
