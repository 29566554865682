import { SvgProps } from "../types";

function AIIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9748 6L14.2193 18.3219H11.9854L11.3117 15.6271H7.53531L6.84386 18.3219H5L8.26221 6H10.9748ZM9.43236 8.25164L7.96081 13.9605H10.8862L9.43236 8.25164Z" fill={color} />
      <path d="M15.8747 18.3219V6H18.02V18.3219H15.8747Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.5 5.5C0.5 2.73858 2.73858 0.5 5.5 0.5H18.5C21.2614 0.5 23.5 2.73858 23.5 5.5V18.5C23.5 21.2614 21.2614 23.5 18.5 23.5H5.5C2.73858 23.5 0.5 21.2614 0.5 18.5V5.5ZM5.5 2H18.5C20.433 2 22 3.567 22 5.5V18.5C22 20.433 20.433 22 18.5 22H5.5C3.567 22 2 20.433 2 18.5V5.5C2 3.567 3.567 2 5.5 2Z" fill={color} />
    </svg>
  );
}

export default AIIcon;
