export type Slot = {
  id: string,
  shardId: string,
  modelId: string,
  workerId?: number,
}

export function getEmptySlot(): Slot {
  return {
    id: '',
    shardId: '',
    modelId: '',
  };
}

export function isSlotEmpty(value: Slot): boolean {
  return value.id === getEmptySlot().id;
}
