import { createAsyncAction } from 'typesafe-actions';
import { Line } from '../../types/models/Line';
import { ErrorAction } from '../reducers';

export const loadLines = createAsyncAction(
  'lines/load/requested',
  'lines/load/succeeded',
  'lines/load/failed',
)<undefined, Line[], ErrorAction>();

export const createLine = createAsyncAction(
  'lines/create/requested',
  'lines/create/succeeded',
  'lines/create/failed',
)<string, Line, ErrorAction>();
