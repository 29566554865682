import { FC } from 'react';
import { SvgProps } from '../types';

const ChevronDownIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 10L12 14.5L7.5 10" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ChevronDownIcon;
