import React from 'react';
import { SvgProps } from '../types';

function ChatIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12C3 8.13401 6.13401 5 10 5H14C17.866 5 21 8.13401 21 12C21 13.5636 20.4873 15.0074 19.621 16.1726C20.4822 17.2114 21 18.5452 21 20V21.5C20.0694 19.949 18.3933 19 16.5845 19H10C6.13401 19 3 15.866 3 12ZM8 13.25C8.69036 13.25 9.25 12.6904 9.25 12C9.25 11.3096 8.69036 10.75 8 10.75C7.30964 10.75 6.75 11.3096 6.75 12C6.75 12.6904 7.30964 13.25 8 13.25ZM13.25 12C13.25 11.3096 12.6904 10.75 12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12ZM16 13.25C16.6904 13.25 17.25 12.6904 17.25 12C17.25 11.3096 16.6904 10.75 16 10.75C15.3096 10.75 14.75 11.3096 14.75 12C14.75 12.6904 15.3096 13.25 16 13.25Z"
        fill={color}
      />
    </svg>
  );
}

export default ChatIcon;
