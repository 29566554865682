/* eslint-disable camelcase */
import { Capability } from "../../services/access";

export type Project = {
  id: number, // ID проекта
  slug: string, // ID проекта в удобочитаемом формате
  title: string, // Имя проекта (отображается в раскрывающемся списке "Выберите поддержку")
  capabilities: Capability[],
  preset?: string;
  is_chatterbox?: boolean, // TODO Вынести в ProjectBackend
  is_async?: boolean,
  new_config_schema?: boolean,
  validation_instance_id?: string,
  voice_template_file_id?: string,
  is_template?: boolean;
  type: ProjectType;
}

export enum ProjectType {
  text = 'text',
  voiceIncoming = 'voice_incoming',
  voiceOutgoing = 'voice_outgoing'
}

export const projectEmpty = (project: Project): boolean => {
  const emptyProject = getEmptyProject();
  return project.id === emptyProject.id;
};

export const getEmptyProject = (): Project => ({
  id: 0,
  slug: '',
  title: '',
  capabilities: [],
  type: ProjectType.text,
});

export const isVoiceProject = (project: Project): boolean => (
  [
    ProjectType.voiceIncoming,
    ProjectType.voiceOutgoing,
  ].includes(project.type)
);

export const emptyProject: Project = Object.freeze({
  id: 0,
  slug: '',
  title: '',
  capabilities: [],
  type: ProjectType.text,
});
