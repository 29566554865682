import { SelectConfig } from "../../../../../../../../../../components-new/select/configurable";
import { getEmptySymbolsRule } from "../../../../helpers";
import { FeatureAction, FeatureRule } from "../../../../types";
import i18n from '../../../../../../../../../../services/i18n';

export const ALWAYS_RULE_ID = 'always';

export const ALWAYS_RULE: FeatureRule = {
  id: ALWAYS_RULE_ID,
  feature: ALWAYS_RULE_ID,
  action: FeatureAction.contains,
  value: ALWAYS_RULE_ID,
};

export enum RulesSelectValue {
  ai = 'ai',
  length = 'length',
  contains = 'contains',
  notContains = 'not_contains',
  matches = 'matches',
  always = 'always',
}

export const getDefaultValues = (selected: RulesSelectValue, scenarioSlug?: string) => {
  switch (selected) {
    case RulesSelectValue.ai:
      return {
        feature: 'sure_topic',
        value: scenarioSlug || '',
        action: FeatureAction.equals,
      };
    case RulesSelectValue.length:
      return {
        feature: 'last_user_message_length',
        value: getEmptySymbolsRule(),
        action: FeatureAction.contains,
      };
    case RulesSelectValue.matches:
      return {
        feature: 'last_user_message',
        value: '',
        action: FeatureAction.matches,
      };
    case RulesSelectValue.notContains:
      return {
        feature: 'last_user_message',
        value: [],
        action: FeatureAction.notContains,
      };
    case RulesSelectValue.always:
      return {
        feature: ALWAYS_RULE_ID,
        value: ALWAYS_RULE_ID,
        action: FeatureAction.contains,
      };
    default:
      return {
        feature: 'last_user_message',
        value: [],
        action: FeatureAction.contains,
      };
  }
};

export function isAlwaysRule(value: FeatureRule): boolean {
  return value.feature === ALWAYS_RULE.feature;
}

export const getSelectConfig = (
  withAlwaysRule: boolean = false,
): SelectConfig => {
  const config: SelectConfig = {
    options: [{
      label: i18n.t("GRAPH.SCENARIO_EDITOR.AI_DEFINED_SCENARIO"),
      value: RulesSelectValue.ai,
    }, {
      label: i18n.t("GRAPH.SCENARIO_EDITOR.USER_MESSAGE_CONTAINS"),
      value: RulesSelectValue.contains,
    }, {
      label: i18n.t("GRAPH.SCENARIO_EDITOR.USER_MESSAGE_NOT_CONTAINS"),
      value: RulesSelectValue.notContains,
    }, {
      label: i18n.t("GRAPH.SCENARIO_EDITOR.USER_MESSAGE_MATCHES_REGEXP"),
      value: RulesSelectValue.matches,
    }, {
      label: i18n.t("GRAPH.SCENARIO_EDITOR.USER_MESSAGE_LENGTH"),
      value: RulesSelectValue.length,
    }],
    labelFunction: ({ label }) => label,
    valueFunction: ({ value }) => value,
  };

  if (withAlwaysRule) {
    config.options.push({
      label: i18n.t("GRAPH.SCENARIO_EDITOR.ALWAYS"),
      value: RulesSelectValue.always,
    });
  }

  return config;
};
