import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeEntryIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="18.2002" width="12.4" height="22" rx="6.2" transform="rotate(-90 1 18.2002)" fill="white" stroke={color} strokeWidth="2" />
  </svg>
);

export default GraphNodeEntryIcon;
