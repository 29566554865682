import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { block } from "bem-cn";

import i18n from '../../services/i18n';

import useEffectWithoutInitial from '../../hooks/useEffectWithoutInitial';

import { Project, ProjectType } from '../../types/models/Project';

import { getIsProjectCreating, getParentProjects } from '../../redux/projects/selectors';

import Input from '../../components-new/input';
import { ModalProps } from '../../services/modal';
import Checkbox from '../../components-new/checkbox';
import Select, { SelectConfig } from '../../components-new/select/configurable';
import SideModal from '../../components-new/sideModal';
import Button from '../../components-new/button';

import './styles.scss';

const b = block("create-project-modal");

export type CreateProjectModalProps = {
  onConfirm: (params: {
    title: string,
    slug: string,
    isChatterbox: boolean,
    isAsync: boolean,
    type: ProjectType,
    parentProjectSlug?: string,
  }) => void,
}

export const projectTypesConfig: SelectConfig = {
  options: [
    ProjectType.text,
    ProjectType.voiceIncoming,
    ProjectType.voiceOutgoing,
  ],
  labelFunction: (type: ProjectType) => i18n.t(`PROJECTS.TYPES.${type.toUpperCase()}`),
  valueFunction: (type: ProjectType) => type,
};

export default function CreateProjectModal({
  onConfirm,
  close,
}: CreateProjectModalProps & ModalProps) {
  const parentProjects = useSelector(getParentProjects);
  const loading = useSelector(getIsProjectCreating);

  const [title, setTitle] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [type, setType] = useState<ProjectType>(ProjectType.text);
  const [isChatterbox, setIsChatterbox] = useState<boolean>(false);
  const [isAsync, setIsAsync] = useState<boolean>(false);
  const [parentProjectSlug, setParentProject] = useState<Project['slug'] | undefined>();
  const [slugError, setSlugError] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value);
    },
    [],
  );

  const handleSlugChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSlug(event.target.value);
    },
    [],
  );

  useEffectWithoutInitial(
    () => {
      const cyrillicPattern = /[а-яА-ЯЁё]/;
      setSlugError(cyrillicPattern.test(slug));
    }, [slug],
  );

  const handleParentProjectChange = useCallback(
    (str: string) => {
      setParentProject(str || undefined);
    },
    [],
  );

  const handleChatterboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChatterbox(event.target.checked);
    },
    [],
  );

  const handleAsyncChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsAsync(event.target.checked);
    },
    [],
  );

  const handleSubmit = useCallback(
    () => {
      onConfirm({
        title,
        slug,
        isChatterbox,
        isAsync,
        type,
        parentProjectSlug,
      });
    },
    [
      isChatterbox,
      isAsync,
      parentProjectSlug,
      slug,
      title,
      type,
      onConfirm,
    ],
  );

  const parentProjectsConfig: SelectConfig = useMemo(
    () => ({
      options: parentProjects,
      labelFunction: (p: Project) => p.title,
      valueFunction: (p: Project) => p.slug,
    }),
    [parentProjects],
  );

  return (
    <SideModal
      className={b()}
      title={t("MODALS.CREATE_PROJECT.TITLE")}
      open
      onClose={close}
      defaultShadow
    >
      <Input
        placeholder={t("COMMON.NAME")}
        value={title}
        floatingPlaceholder
        name="name"
        onChange={handleTitleChange}
      />
      <div>
        <Input
          placeholder={t("COMMON.IDENTIFIER")}
          value={slug}
          floatingPlaceholder
          name="slug"
          onChange={handleSlugChange}
          error={slugError}
        />
        {slugError && <span className={b('error')}>{t("MODALS.CREATE_PROJECT.SLUG_ERROR")}</span>}
      </div>
      <div className={b("selectors")}>
        <Select
          config={projectTypesConfig}
          placeholder={t("COMMON.TYPE")}
          value={type}
          onChange={setType}
          usePortal
        />
        <Select
          config={parentProjectsConfig}
          isClearable
          placeholder={t("MODALS.CREATE_PROJECT.PARENT_PROJECT_SLUG")}
          value={parentProjectSlug || ""}
          usePortal
          onChange={handleParentProjectChange}
        />
      </div>
      <div className={b('checkboxes')}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={b("chatterbox")}>
          <Checkbox
            name="isChatterbox"
            value={isChatterbox}
            onChange={handleChatterboxChange}
          />
          <span>{t("MODALS.CREATE_PROJECT.IS_CHATTERBOX")}</span>
        </label>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={b("async")}>
          <Checkbox
            name="isAsync"
            value={isAsync}
            onChange={handleAsyncChange}
          />
          <span>{t("MODALS.CREATE_PROJECT.IS_ASYNC")}</span>
        </label>
      </div>
      <div className={b('footer')}>
        <Button
          primary
          name="save"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleSubmit}
          size="large"
          disabled={((!title || !slug) || slugError) || loading}
        >
          {t('COMMON.CREATE')}
        </Button>
      </div>
    </SideModal>
  );
}
