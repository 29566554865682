import { createAsyncAction } from "typesafe-actions";

import { CallFilters, VoxCallFilters } from "../../pages/voice/types";
import { OutgoingCall } from "../../types/models/OutgoingCall";
import { OutgoingCallResult } from "../../types/models/OutgoingCallResult";
import { Pagination } from "../../types/models/Pagination";
import { Task } from "../../types/models/Task";
import { ErrorAction } from "../reducers";

export const loadCalls = createAsyncAction(
  'calls/loadCalls/requested',
  'calls/loadCalls/succeeded',
  'calls/loadCalls/failed',
)<
  CallFilters & { offset: number },
  { list: OutgoingCall[], total: number, offset: number },
  ErrorAction
>();

export const loadCallResults = createAsyncAction(
  'calls/loadCallResults/requested',
  'calls/loadCallResults/succeeded',
  'calls/loadCallResults/failed',
)<
  OutgoingCall['id'],
  { id: OutgoingCall['id'], value: OutgoingCallResult | null },
  ErrorAction<OutgoingCall['id']>
>();

export const loadExportHistory = createAsyncAction(
  'calls/loadExportHistory/requested',
  'calls/loadExportHistory/succeeded',
  'calls/loadExportHistory/failed',
)<Pagination, { tasks: Task[], pagination: Pagination }, ErrorAction>();

export const exportCalls = createAsyncAction(
  'calls/exportCalls/requested',
  'calls/exportCalls/succeeded',
  'calls/exportCalls/failed',
)<{ name: string, filters: CallFilters }, Task, ErrorAction>();

export const exportVoxCalls = createAsyncAction(
  'calls/exportVoxCalls/requested',
  'calls/exportVoxCalls/succeeded',
  'calls/exportVoxCalls/failed',
)<VoxCallFilters, Task, ErrorAction>();

export const loadCallStatistics = createAsyncAction(
  'calls/loadCallStatistics/requested',
  'calls/loadCallStatistics/succeeded',
  'calls/loadCallStatistics/failed',
)<{ newerThan?: number, olderThan?: number }, Blob, ErrorAction>();
