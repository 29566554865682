import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  Version,
  isCurrentReleaseVersion,
  isDraftVersion,
  isReleaseVersion,
} from "../../types/models/Version";

import {
  getCurrentVersionToDisplay,
  getVersions,
  getVersionsAvailableToSwitch,
} from "../../redux/versions/selectors";
import {
  loadVersions,
  changeVersionToDisplay,
} from "../../redux/versions/actions";
import { getSelectedProject } from "../../redux/projects/selectors";
import { getActiveGraphSlug } from "../../redux/graph/selectors";
import Select, { SelectConfig } from "../select/configurable";

import authorizedPages from "../../routes/authorized";

import { isScenarioExistInVersion } from "./helpers";
import { DRAFT_VERSION } from "../../redux/versions/reducer";

type VersionSelectorProps = {
  className?: string;
  contrast?: boolean;
  onClick?: (cb: () => void) => void;
}

function VersionSelector({
  className = "",
  contrast = false,
  onClick,
}: VersionSelectorProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const allVersions = useSelector(getVersions);
  const availableVersions = useSelector(getVersionsAvailableToSwitch, shallowEqual);
  const versionToDisplay = useSelector(getCurrentVersionToDisplay);
  const selectedProject = useSelector(getSelectedProject);
  const activeGraphSlug = useSelector(getActiveGraphSlug);

  const [options, setOptions] = useState<Version[]>([DRAFT_VERSION]);
  const [validating, setValidating] = useState<boolean>(false);

  const handleVersionChange = useCallback(
    (id: Version['id']) => {
      if (onClick) onClick(() => { dispatch(changeVersionToDisplay(id)) });
      else dispatch(changeVersionToDisplay(id));
    },
    [dispatch, onClick],
  );

  const formVersions = useCallback(
    async() => {
      if (validating) return;

      setValidating(true);

      const onScenariosPage = authorizedPages.graphSelect === location.pathname;
      if (onScenariosPage) {
        setOptions(availableVersions as Version[]);
        setValidating(false);
        return;
      }

      const onEditorPage = authorizedPages.editor === location.pathname;

      if (onEditorPage && activeGraphSlug) {
        const newOptions: Version[] = [];

        for (let version of availableVersions) {
          if (isReleaseVersion(version)) {
            const versionValid = await isScenarioExistInVersion(
              selectedProject.slug,
              version.id,
              activeGraphSlug,
            );
            if (versionValid) {
              newOptions.push(version);
            }
          } else {
            newOptions.push(version);
          }
        }

        setOptions(newOptions);
        setValidating(false);
        return;
      }

      setValidating(false);
    },
    [
      validating,
      activeGraphSlug,
      availableVersions,
      location.pathname,
      selectedProject.slug,
    ],
  );

  const config: SelectConfig = useMemo(
    () => ({
      options,
      labelFunction: v => isDraftVersion(v) ?
        t("SCENARIO_GRAPHS.EDITABLE_VERSION") :
        v.name,
      valueFunction: v => v.id,
      subtitleFunction: v => isReleaseVersion(v) ?
        `${v.createdDateFormatted} (${t("SCENARIO_GRAPHS.RELEASE")})` :
        v.createdDateFormatted,
      // eslint-disable-next-line no-nested-ternary
      iconFunction: v => isDraftVersion(v) ?
        "pen" :
        isCurrentReleaseVersion(v) ?
          "play" :
          "pause",
    }),
    [options, t],
  );

  useEffect(
    () => {
      if (allVersions.loading) return;

      dispatch(loadVersions.request());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProject],
  );

  useEffect(
    () => {
      formVersions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allVersions, activeGraphSlug],
  );

  return (
    <Select
      className={className}
      contrast={contrast}
      config={config}
      value={versionToDisplay}
      onChange={handleVersionChange}
      placeholder={t("COMMON.VERSION")}
      isLoading={allVersions.loading || validating}
      onMenuOpen={formVersions}
      usePortal
    />
  );
}

export default memo(VersionSelector);
