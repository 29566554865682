import { NodeStatistics } from "../../types/models/GraphStatistics";
import { ScenarioGraph } from "../../types/models/ScenarioGraph";
import { ScenarioGraphNode } from "../../types/models/ScenarioGraphNode";
import { AppState } from "../reducers";

export const getActiveGraph = (state: AppState) => state.scenarioGraph.activeGraph;
export const getSavedGraph = (state: AppState) => state.scenarioGraph.savedGraph;

export const getGraphListData = (state: AppState) => state.scenarioGraph.graphList;
export const getGraphList = (state: AppState) => state.scenarioGraph.graphList.value;

export const getGraphLoading = (state: AppState) => state.scenarioGraph.loading;

export const getActiveGraphPredicate = ({ scenarioGraph }: AppState): ScenarioGraph['predicate'] => (
  scenarioGraph.activeGraph.predicate
);

export const getScenarioName = ({ scenarioGraph }: AppState): ScenarioGraph['title'] => (
  scenarioGraph.activeGraph.title
);

export const getActiveGraphMLTopics = (state: AppState): string[] => (
  state.scenarioGraph.activeGraph.extraModelTopics
);

export const getActiveGraphSlug = (state: AppState) => state.scenarioGraph.activeGraph.slug;

export const getActiveGraphId = (state: AppState) => state.scenarioGraph.activeGraph.id;

export const getNodesStatistics = (state: AppState) => (
  state.scenarioGraph.statistics
);

export const getNodeStatistics = (
  state: AppState,
  id: ScenarioGraphNode['id'],
): NodeStatistics | undefined => (
  state.scenarioGraph.statistics.nodes[id]
);

export const getNodeStatisticsCount = (
  state: AppState,
  id: ScenarioGraphNode['id'],
): string => {
  if (!state.scenarioGraph.statistics.nodes[id]) return "";

  return String(state.scenarioGraph.statistics.nodes[id]?.params?.count || "-");
};

export const getGraphStatisticsLoadingStatus = (
  state: AppState,
): boolean => (
  state.scenarioGraph.statistics.loading
);

export const getGraphStatisticsEnabled = (state: AppState) => (
  state.scenarioGraph.statistics.enabled
);

export const getNodesWithStatistics = (state: AppState): Array<ScenarioGraphNode['id']> => (
  Object.keys(state.scenarioGraph.statistics.nodes)
);

export const getGraphStatisticsFilters = (state: AppState) => {
  return state.scenarioGraph.statistics.filters;
};
