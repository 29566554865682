import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";
import apiService, { ApiResponse } from "../../services/api";
import { parseOutgoingCall } from "../../types/parsers/OutgoingCallParser";
import { parseOutgoingCallResult } from "../../types/parsers/OutgoingCallResultParser";
import {
  DownloadCallRecordRequest,
  DownloadCallRecordResponse,
  GetCallResultRequest,
  GetCallResultResponse,
  GetCallsDataRequest,
  GetCallsDataResponse,
} from "../../types/requests/Calls";
import modalService from "../../services/modal";
import i18n from "../../services/i18n";

import * as filesActions from '../files/actions';
import * as actions from './actions';
import { GetTasksRequest, GetTasksResponse } from "../../types/requests/Task";
import { parseTask } from "../../types/parsers/TaskParser";
import { TaskType } from "../../types/models/Task";
import { getFilenameFromDispositionHeader } from "../files/sagas";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import { ErrorAction } from "../reducers";
import { downloadBlobData, objCamelToSnake } from "../../services/helpers/utilities";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";

export const VOICE_DETAILS_EXPORTS_CHUNK_SIZE = 10;

function* loadCallResult(): SagaIterator {
  yield takeLatest(actions.loadCallResult.request, function* (action) {
    try {
      const params: GetCallResultRequest = {
        call_id: action.payload.callId,
      };

      const response: ApiResponse<GetCallResultResponse> = yield call(apiService.getCallResult, params);
      if (response.status === 204) {
        yield put(actions.loadCallResult.success({ callId: action.payload.callId }));
      } else {
        const parsedCallResult = parseOutgoingCallResult(response.data);
        yield put(actions.loadCallResult.success({ result: parsedCallResult, callId: action.payload.callId }));
      }
    } catch (err) {
      yield put(actions.loadCallResult.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CALLS.RESULTS_LOAD"),
        { callId: action.payload.callId },
      )));
    }
  });
}

function* loadCallsData(): SagaIterator {
  yield takeLatest(actions.loadTaskCalls.request, function* (action) {
    try {
      const { status, ...filters } = action.payload;
      const params: GetCallsDataRequest = {
        ...(status && { statuses: [status] }),
        ...objCamelToSnake(filters),
      };

      const response: ApiResponse<GetCallsDataResponse> = yield call(apiService.getCalls, params);

      const parsedCalls = response.data.calls.map(parseOutgoingCall);
      yield put(actions.loadTaskCalls.success({
        calls: parsedCalls,
        totalCount: response.data.total_calls_count,
        addChunk: !!filters.offset,
      }));
    } catch (err) {
      yield put(actions.loadTaskCalls.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.LOAD"))));
    }
  });
}

function* loadCallRecords() {
  yield takeLatest(actions.loadCallRecords, function* (action) {
    try {
      yield put(filesActions.startUploadCallFiles());
      modalService.openDownloadCallFiles();
      yield put(filesActions.setCallFilesCountToUpload(action.payload.ids.length));

      let countUploadedFiles: number = 0;
      for (const fileId of action.payload.ids) {
        yield put(actions.loadCallRecord.request({ callId: fileId }));
        yield take([actions.loadCallRecord.success]);
        countUploadedFiles++;
        yield put(filesActions.setCallFilesUploaded(countUploadedFiles));
      }

      yield put(filesActions.endUploadCallFiles(''));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // NotificationManager.error(wrapErrorMessage(err, i18n.t("ERRORS.API.CALLS.RECORDS_LOAD")));
      yield put(filesActions.endUploadCallFiles(err.toString()));
    }
  });
}

function* loadCallTasks() {
  yield takeLatest(actions.loadCallExports.request, function* (action) {
    try {
      const { olderThan, limit = VOICE_DETAILS_EXPORTS_CHUNK_SIZE } = action.payload;
      const params: GetTasksRequest = {
        ref_task_id: action.payload.taskId,
        types: [TaskType.callResults],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(apiService.getTasks, params);

      const parsedTasks = response.data.tasks.map(bTask => parseTask(bTask));
      yield put(actions.loadCallExports.success({
        tasks: parsedTasks,
        pagination: {
          ...action.payload,
        },
      }));
    } catch (err) {
      yield put(actions.loadCallExports.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.TASKS_LOAD"))));
    }
  });
}

function* loadCallRecord() { // TODO Посмотреть можно ли переделать на обычную функцию-генератор для скачивания
  yield takeLatest(actions.loadCallRecord.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const params: DownloadCallRecordRequest = {
        ...general,
        call_id: action.payload.callId,
      };

      const response: ApiResponse<DownloadCallRecordResponse> = yield call(apiService.downloadCallRecord, params);
      const filename = getFilenameFromDispositionHeader(response.headers['content-disposition']);
      downloadBlobData(response.data, filename);
      yield put(actions.loadCallRecord.success());
    } catch (err) {
      yield put(actions.loadCallRecord.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.RECORD_DOWNLOAD"))));
    }
  });
}

export default function* callsDetailsSagas() {
  yield fork(loadCallResult);
  yield fork(loadCallsData);
  yield fork(loadCallRecords);
  yield fork(loadCallTasks);
  yield fork(loadCallRecord);
}
