import React, { FC, memo, ReactNode, useCallback, useState } from 'react';
import RSelect, { Props } from 'react-select';
import RSelectCreatable from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { block } from 'bem-cn';

import Icon from '../icon';

import Option from './components/option';
import SingleValue from './components/singleValue';
import Menu from './components/menu';
import LoadingIndicator from './components/loadingIndicator';
import ClearIndicator from './components/clearIndicator';
import getMultiValueBadgeStyles from './components/multiValueBadge';

import { getControlStyles, getMenuPortalStyles, getOptionStyles } from './computedStyles';

import { FLOATING_PLACEHOLDER_COMPONENTS } from './configs/floatingPlaceholderComponents';

import './styles.scss';

export const b = block("nd-custom-select");

export type BaseSelectProps = {
  size?: 'medium' | 'small',
  fluid?: boolean,
  shortList?: boolean,
  contrast?: boolean,
  transparent?: boolean,
  withoutBorder?: boolean,
  floatingPlaceholder?: boolean,
  mode?: "standard" | "underline",
  placeholder?: string;
  usePortal?: boolean;
  createOptionSubtitle?: string;
  onReset?: () => void;
  onCreateOption?: (value: string) => void;
  formatCreateLabel?: (value: string) => ReactNode;
  allSelectedLabel?: (value: number) => string;
} & Props;

const Select: FC<BaseSelectProps> = ({
  pStyles,
  components,
  className: customClassName,
  fluid = false,
  contrast = false,
  size = 'medium',
  mode = "standard",
  shortList = false,
  transparent = false,
  withoutBorder = false,
  usePortal = false,
  floatingPlaceholder = true,
  onKeyDown,
  onDeleteOption,
  onReset,
  styles,
  onCreateOption,
  formatCreateLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const prefix = 'nd-custom-select';

  const handleClose = useCallback(
    () => setOpen(false),
    [],
  );

  const handleOpen = useCallback(
    () => setOpen(true),
    [],
  );

  const blockClasses = b({
    size,
    fluid,
    disabled: props.isDisabled,
    contrast,
    transparent,
    mode,
    'short-list': shortList,
    'no-border': withoutBorder,
  });

  const classNames = customClassName ? `${customClassName} ${blockClasses}` : `${blockClasses}`;
  const Tag = (onCreateOption ? RSelectCreatable : RSelect) as React.ElementType;

  return (
    <>
      <Tag
        className={classNames}
        classNamePrefix={prefix}
        onMenuOpen={handleOpen}
        onMenuClose={handleClose}
        onKeyDown={onKeyDown}
        components={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Option: (p: any) => <Option onDelete={onDeleteOption} {...p} />,
          DropdownIndicator: () => <Icon type={open ? "chevronUp" : "chevronDown"} />,
          IndicatorSeparator: null,
          LoadingIndicator,
          ClearIndicator,
          Menu,
          SingleValue,
          ...(floatingPlaceholder && FLOATING_PLACEHOLDER_COMPONENTS),
          ...components,
        }}
        placeholder={t("COMMON.SELECT_VALUE")}
        // eslint-disable-next-line react/jsx-no-bind
        noOptionsMessage={() => t("COMMON.NO_DATA")}
        captureMenuScroll={false}
        styles={{
          multiValue: getMultiValueBadgeStyles,
          menuPortal: getMenuPortalStyles,
          option: getOptionStyles,
          control: getControlStyles,
          ...styles,
        }}
        onCreateOption={onCreateOption}
        formatCreateLabel={formatCreateLabel}
        createOptionPosition="first"
        {...(usePortal && {
          menuPortalTarget: document.getElementById('modal-container'),
          menuPosition: "fixed",
        })}
        floatingPlaceholder={floatingPlaceholder}
        {...props}
      />
    </>
  );
};

export default memo(Select);
