import { SelectScenarioNodeShortInfoBackend } from "../backendModels/ScenarioGraphParentBackend";
import { ScenarioGraphParentNode } from "../models/ScenarioGraphParent";

import i18n from "../../services/i18n";

export function parseScenarioGraphUsage(
  value: SelectScenarioNodeShortInfoBackend,
): ScenarioGraphParentNode {
  return {
    node: {
      id: value.node_id || "",
      uuid: value.id || "",
      title: value.title || i18n.t("GRAPH.ELEMENT_HELPER.SELECT_SCENARIO"),
    },
    scenarioId: value.scenario_id || "",
  };
}
