import React from 'react';
import { block } from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import { getSidebarOpenStatus } from '../../../redux/sidebar/selectors';

import Icon from '../../../components-new/icon';

import './styles.scss';

const b = block("sidebar-header");

function SidebarHeader() {
  const opened = useSelector(
    getSidebarOpenStatus,
    shallowEqual,
  );

  return (
    <div className={b()}>
      <Icon
        className={b("logo")}
        type="logo"
        svgProps={{
          width: opened ? "40" : "32",
          height: opened ? "46" : "36",
        }}
      />
      {opened && (
        <div className={b("text")}>
          <Icon type="logoText" />
        </div>
      )}
    </div>
  );
}

export default SidebarHeader;
