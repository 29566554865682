import React from 'react';

import { SvgProps } from '../types';

function GridIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="14"
        y="14"
        width="4"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="6"
        y="14"
        width="4"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="14"
        y="6"
        width="4"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="6"
        y="6"
        width="4"
        height="4"
        rx="1"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

export default GridIcon;
