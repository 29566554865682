import { FC, memo, useCallback } from 'react';
import { block } from 'bem-cn';
import MDEditor, { commands } from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

import { TextAreaOnChangeFunc } from '../textarea';

import './styles.scss';

type Props = {
  className?: string;
  value: string;
  name: string;
  placeholder?: string;
  rows?: number;
  onChange: TextAreaOnChangeFunc;
};

const b = block('nd-markdown-editor');

const MarkdownEditor: FC<Props> = ({
  className = '',
  name,
  onChange,
  value,
  placeholder,
}) => {
  const handleChange = useCallback(
    e => {
      if (!e?.target) return;
      // eslint-disable-next-line no-shadow
      const { name: inputName, value: inputValue } = e?.target || {};
      onChange(e, { name: inputName, value: inputValue });
    },
    [onChange],
  );

  return (
    <div className={`${b()} ${className}`}>
      <MDEditor
        commands={[
          commands.title1,
          commands.title2,
          commands.bold,
          commands.italic,
          commands.strikethrough,
          commands.link,
          commands.quote,
          commands.code,
          commands.codeBlock,
          commands.unorderedListCommand,
          commands.orderedListCommand,
          commands.checkedListCommand,
        ]}
        extraCommands={[
          commands.codeEdit,
          commands.codeLive,
        ]}
        value={value}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]],
        }}
        textareaProps={{
          name: name,
          placeholder: placeholder,
          onChange: handleChange,
        }}
      />
    </div>
  );
};

export default memo(MarkdownEditor);
