import React from 'react';
import { SvgProps } from '../types';

export default function StarIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0005 17.3276L6.59705 20.6139C6.24315 20.8292 5.78177 20.7168 5.56653 20.3629C5.46407 20.1944 5.43207 19.9924 5.47746 19.8005L6.93324 13.646L2.13798 9.52264C1.82391 9.25258 1.78823 8.77905 2.0583 8.46498C2.18685 8.31548 2.36911 8.22261 2.56562 8.20649L8.86874 7.68918L11.3085 1.85441C11.4683 1.47226 11.9076 1.29201 12.2898 1.4518C12.4717 1.52786 12.6163 1.6725 12.6924 1.85441L15.1322 7.68918L21.4353 8.20649C21.8481 8.24037 22.1553 8.60249 22.1214 9.01532C22.1053 9.21183 22.0124 9.39409 21.8629 9.52264L17.0677 13.646L18.5234 19.8005C18.6188 20.2036 18.3693 20.6077 17.9662 20.703C17.7743 20.7484 17.5723 20.7164 17.4039 20.6139L12.0005 17.3276Z"
        fill={color}
      />
    </svg>
  );
}
