import React from 'react';

import cx from '../../../services/helpers/classNames';

function Modal(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { children, className = '' }: React.PropsWithChildren<any>,
) {
  const classes = cx('modal-wrap', className);
  return (
    <div className={classes}>
      <div className="modal">
        {children}
      </div>
    </div>
  );
}

export default Modal;
