import { SagaIterator } from "redux-saga";
import {
  takeLatest, call, put, fork, select,
} from 'redux-saga/effects';

import * as actions from './actions';
import {
  CreateTagRequest,
  CreateTagResponse,
  DeleteTagRequest,
  GetTagInfoRequest,
  GetTagInfoResponse,
  TagsRequest,
  TagsResponse,
} from "../../types/requests/Tags";
import Api, { ApiResponse } from "../../services/api";
import { parseTag } from "../../types/parsers/TagParser";
import i18n from "../../services/i18n";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import { getTagBySlug } from "./selectors";
import { ErrorAction } from "../reducers";
import { Tag, TagInfo } from "../../types/models/Tag";
import { TAG_PLAY, TAG_SPEAK } from "../../pages/graphEditor/components/nodeEditor/components/tagSelector/helpers";
import { Project, isVoiceProject } from "../../types/models/Project";
import { getSelectedProject } from "../projects/selectors";
import { objSnakeToCamelDeep } from "../../services/helpers/utilities";

function* loadTags(): SagaIterator {
  yield takeLatest(actions.loadTags.request, function* () {
    try {
      const project: Project = yield select(getSelectedProject);
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: TagsRequest = {
        ...general,
      };

      const response: ApiResponse<TagsResponse> = yield call(Api.getTags, params);
      yield put(actions.loadTags.success(response.data));

      if (isVoiceProject(project)) {
        // На графе в голосовых проектах должно быть создано 2 тега - play и speak
        // Они используются в ноде "Ответ пользователю" и выставляются автоматически,
        // независимо от действий пользователя. Однако, чтобы ими можно было пользоваться
        // их сперва нужно создать на беке. Поэтому тут при загрузке проверяем, если этих
        // тегов не было создано - создаём их

        const playTag: Tag | undefined = yield select(getTagBySlug, TAG_PLAY);
        if (!playTag) {
          yield put(actions.createTag.request(TAG_PLAY));
        }

        const speakTag: Tag | undefined = yield select(getTagBySlug, TAG_SPEAK);
        if (!speakTag) {
          yield put(actions.createTag.request(TAG_SPEAK));
        }
      }
    } catch (err) {
      yield put(actions.loadTags.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.TAGS.LOAD"),
      )));
    }
  });
}

function* createTag(): SagaIterator {
  yield takeLatest(actions.createTag.request, function* (action) {
    try {
      if (!/^[a-z0-9_]+$/.test(action.payload)) {
        yield put(actions.createTag.failure(new ErrorAction(
          i18n.t("ERRORS.API.TAGS.CREATE_INFO"),
        )));
        return;
      }
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const { payload } = action;
      const params: CreateTagRequest = {
        tag: {
          id: '',
          slug: payload,
          usage_counter: 0,
        },
        ...general,
      };

      const response: ApiResponse<CreateTagResponse> = yield call(Api.createTag, params);
      const tag = response.data;
      const parsedTag = parseTag(tag);

      yield put(actions.createTag.success(parsedTag));
    } catch (err) {
      yield put(actions.createTag.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.TAGS.CREATE"),
      )));
    }
  });
}

function* deleteTag(): SagaIterator {
  yield takeLatest(actions.deleteTag.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      const { payload } = action;
      const params: DeleteTagRequest = {
        path: {
          tag_id: payload,
        },
        ...general,
      };

      yield call(Api.deleteTag, params);

      yield put(actions.deleteTag.success(payload));
      yield put(actions.closeDeleteTagModal());
    } catch (err) {
      yield put(actions.deleteTag.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.TAGS.DELETE"),
      )));
    }
  });
}

function* loadTagInfo(): SagaIterator {
  yield takeLatest(actions.loadTagInfo.request, function* (action) {
    try {
      const tagId = action.payload;

      const request: GetTagInfoRequest = {
        path: {
          tag_id: tagId,
        },
      };

      const response: ApiResponse<GetTagInfoResponse> = yield call(
        Api.getTagInfo,
        request,
      );

      const tagInfo = objSnakeToCamelDeep<TagInfo>(response.data);
      yield put(actions.loadTagInfo.success(tagInfo));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.TAG_INFO") });
      yield put(actions.loadTagInfo.failure(new ErrorAction(
        err,
        text,
      )));
    }
  });
}

export default function* tagsSagas(): SagaIterator {
  yield fork(loadTags);
  yield fork(createTag);
  yield fork(deleteTag);
  yield fork(loadTagInfo);
}
