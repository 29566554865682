import React from 'react';
import { SvgProps } from '../types';

function NoteIcon({
  width = "9",
  height = "10",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0V7.25C9 8.2165 8.2165 9 7.25 9C6.2835 9 5.5 8.2165 5.5 7.25C5.5 6.2835 6.2835 5.5 7.25 5.5C7.5184 5.5 7.77269 5.56042 8 5.66841V3.69822L3.5 4.5164V8.24999C3.5 9.21648 2.7165 10 1.75 10C0.783502 10 0 9.2165 0 8.25C0 7.2835 0.783502 6.5 1.75 6.5C2.0184 6.5 2.27269 6.56042 2.5 6.66841V1.18182L9 0ZM3.5 2.01639V3.50001L8 2.68182V1.19821L3.5 2.01639ZM1.75 7.5C2.16421 7.5 2.49999 7.83578 2.5 8.24999C2.5 8.6642 2.16421 9 1.75 9C1.33579 9 1 8.66421 1 8.25C1 7.83579 1.33579 7.5 1.75 7.5ZM8 7.25C8 7.66421 7.66421 8 7.25 8C6.83579 8 6.5 7.66421 6.5 7.25C6.5 6.83579 6.83579 6.5 7.25 6.5C7.66421 6.5 8 6.83579 8 7.25Z"
        fill={color}
      />
    </svg>
  );
}

export default NoteIcon;
