import { objSnakeToCamel, objCamelToSnake } from "../../services/helpers/utilities";
import { SlotBackend } from "../backendModels/SlotsBackend";
import { Slot } from "../models/Slots";

export function parseSlot(value: SlotBackend): Slot {
  return {
    ...objSnakeToCamel(value),
  };
}

export function parseSlotToBackend(value: Slot): SlotBackend {
  return {
    ...objCamelToSnake(value),
  };
}
