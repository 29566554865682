import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '../../services/modal';

import { AppState } from '../../redux/reducers';

import Modal from '../components/ndModal';
import LoadingRow from '../../components/loadingRow';

function UpdateRecordsModal({
  countFilesToUpload,
  countUploadedFiles,
  processing,
  error,
  close,
}: ReduxProps & ModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("MODALS.DOWNLOAD_FILES.TITLE")}
      confirmationText={t("COMMON.OK")}
      showCancelButton={false}
      confirmDisabled={processing}
      close={close}
      onConfirm={close}
    >
      <div className="column-2">
        { processing && <span>{t("MODALS.DOWNLOAD_FILES.MSG_LOADING")}</span> }
        <span>{t("MODALS.DOWNLOAD_FILES.DOWNLOAD_COUNT", { downloadedCount: countUploadedFiles, totalCount: countFilesToUpload })}</span>
        { processing && <LoadingRow />}
        { !processing && !error && <span>{t("MODALS.DOWNLOAD_FILES.MSG_COMPLETED")}</span> }
        { !processing && error && <span>{t("MODALS.DOWNLOAD_FILES.MSG_ERROR")}: <span className="text-error">{error}</span></span> }
      </div>
    </Modal>
  );
}

type MapStateToProps = {
  countFilesToUpload: number,
  countUploadedFiles: number,
  processing: boolean,
  error: string,
}

const mapStateToProps = (state: AppState): MapStateToProps => ({
  countFilesToUpload: state.files.callFiles.countToUpload,
  countUploadedFiles: state.files.callFiles.countUploaded,
  processing: state.files.callFiles.processing,
  error: state.files.callFiles.errorMessage,
});

type ReduxProps = MapStateToProps;

export default connect(mapStateToProps, {})(UpdateRecordsModal);
