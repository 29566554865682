import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { block } from 'bem-cn';

import { endOfDay, isAfter, isBefore, max, min } from 'date-fns';

import { ButtonClickEvent } from '../button';

import Input from './input';
import { getLocale } from './locale';
import { HelpDateButton, validateDates } from './helpers';
import Header from './header';
import DateHelpButtons from './dateHelpButtons';
import TimeInput from './timeInput';

import { preventDefaultEvent } from '../../services/helpers/utilities';

import { InputVisualStyle } from '../input';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

export const b = block('nd-datepicker');

export type DatePickerProps = {
  startDate?: ReactDatePickerProps['startDate'];
  endDate?: ReactDatePickerProps['endDate'];
  selected?: ReactDatePickerProps['selected'];
  selectsRange?: boolean;
  shouldCloseOnSelect?: ReactDatePickerProps['shouldCloseOnSelect'];
  showPreviousMonths?: ReactDatePickerProps['showPreviousMonths'];
  maxDate?: ReactDatePickerProps['maxDate'];
  minDate?: ReactDatePickerProps['minDate'];
  showTimeSelect?: ReactDatePickerProps['showTimeSelect'];
  timeInputLabel?: ReactDatePickerProps['timeInputLabel'];
  placeholder?: ReactDatePickerProps['placeholderText'];
  isClearable?: ReactDatePickerProps['isClearable'];
  className?: string;
  helpButtons?: HelpDateButton[];
  fluid?: boolean;
  disabled?: boolean;
  inputVisualStyle?: Exclude<InputVisualStyle, 'underline'>;
  name?: string | undefined;
  popperPlacement?: ReactDatePickerProps['popperPlacement'];
  popperModifiers?: ReactDatePickerProps['popperModifiers'];
  filterDate?: ReactDatePickerProps['filterDate'];
  onChange(
    date: Date | [Date | null, Date | null] | null,
    event?: ButtonClickEvent | undefined,
  ): void;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
  allowSameDay?: ReactDatePickerProps['allowSameDay'];
  children?: ReactDatePickerProps['children'];
  enableTabLoop?: ReactDatePickerProps['enableTabLoop'];
  inline?: boolean;
  additionalDataField?: boolean;
}

function DatePicker({
  startDate = null,
  endDate = null,
  selected,
  selectsRange,
  shouldCloseOnSelect,
  showPreviousMonths,
  maxDate,
  minDate,
  showTimeSelect,
  timeInputLabel,
  placeholder,
  isClearable,
  className,
  helpButtons,
  fluid = false,
  disabled = false,
  inputVisualStyle,
  name,
  filterDate,
  popperPlacement = "bottom-start",
  popperModifiers,
  onCalendarOpen,
  onCalendarClose,
  onChange,
  allowSameDay,
  enableTabLoop,
  inline,
  additionalDataField,
}: DatePickerProps) {
  const { t } = useTranslation();

  const datepickerRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<[Date | null, Date | null]>([startDate, endDate]);
  const [dateFrom, dateTo] = range;

  const handleOpen = useCallback(
    () => {
      setOpen(true);

      if (onCalendarOpen) {
        onCalendarOpen();
      }
    },
    [onCalendarOpen],
  );

  const handleClose = useCallback(
    () => {
      setOpen(false);

      if (selectsRange) {
        const [validDateFrom, validDateTo] = validateDates(
          dateFrom,
          dateTo,
          minDate,
          maxDate,
        );

        setRange([validDateFrom, validDateTo]);
        onChange([validDateFrom, validDateTo]);
      }

      if (onCalendarClose) {
        onCalendarClose();
      }
    },
    [
      dateFrom,
      dateTo,
      maxDate,
      minDate,
      onCalendarClose,
      selectsRange,
      onChange,
    ],
  );

  const handleDayChange = useCallback(
    date => {
      if (selectsRange) {
        let [newDateFrom, newDateTo] = date;

        if (minDate && newDateFrom) {
          newDateFrom = max([minDate, newDateFrom]);
        }

        if (maxDate && newDateTo) {
          newDateTo = min([maxDate, newDateTo]);
        }

        if (isAfter(newDateFrom, newDateTo)) {
          newDateFrom = newDateTo;
        }

        if (!newDateTo) {
          setRange(date);
        } else {
          const nextDateTo = endOfDay(newDateTo);
          setRange([newDateFrom, nextDateTo]);
        }

        if (!newDateFrom) {
          // Clear button
          onChange([null, null]);
        }

        return;
      }

      onChange(date);
    },
    [maxDate, minDate, onChange, selectsRange],
  );

  const handleTimeChange = useCallback(
    date => {
      if (selectsRange) {
        const [newDateFrom, newDateTo] = date;

        if (!newDateTo) {
          setRange(date);
        } else {
          const validDateTo = isBefore(newDateTo, newDateFrom) ?
            newDateFrom :
            newDateTo;

          setRange([newDateFrom, validDateTo]);
        }

        return;
      }

      onChange(date);
    },
    [onChange, selectsRange],
  );

  const monthsShown = selectsRange ? 2 : 1;

  const handleRenderHeader = useCallback(
    params => (
      <Header
        {...params}
        monthsShown={monthsShown}
        className={b('react-datepicker__header')}
      />
    ),
    [monthsShown],
  );

  useEffect(
    () => {
      setRange([startDate, endDate]);
    },
    [startDate, endDate],
  );

  const renderDayContents = useCallback(
    (day: number) => {
      return (
        <div
          tabIndex={0}
          //@ts-ignore
          onBlur={preventDefaultEvent}
        >
          {day}
        </div>
      );
    }, [],
  );

  return (
    <ReactDatePicker
      ref={datepickerRef}
      open={open}
      onClickOutside={handleClose}
      wrapperClassName={b({ fluid, disabled }).mix(className)}
      calendarClassName={monthsShown === 2 ? 'two-month' : ''}
      selected={selected}
      startDate={dateFrom}
      endDate={dateTo}
      selectsRange={selectsRange}
      monthsShown={monthsShown}
      filterDate={filterDate}
      onChange={handleDayChange}
      focusSelectedMonth={false}
      renderCustomHeader={handleRenderHeader}
      renderDayContents={renderDayContents}
      customInput={(
        <Input
          clearable={isClearable}
          value={selected}
          placeholderText={placeholder}
          dateFrom={dateFrom}
          dateTo={dateTo}
          selectsRange={selectsRange}
          visualStyle={inputVisualStyle}
          isOpen={open}
          onOpen={handleOpen}
          onCustomChange={handleDayChange}
        />
      )}
      shouldCloseOnSelect={shouldCloseOnSelect}
      showPreviousMonths={showPreviousMonths}
      showPopperArrow={false}
      maxDate={maxDate}
      minDate={minDate}
      timeInputLabel={timeInputLabel}
      placeholderText={placeholder}
      isClearable={isClearable}
      enableTabLoop={enableTabLoop}
      disabledKeyboardNavigation
      timeCaption={t("COMMON.TIME")}
      locale={getLocale()}
      name={name}
      popperPlacement={popperPlacement}
      popperModifiers={popperModifiers ?? [
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
          },
        },
      ]}
      onCalendarOpen={handleOpen}
      onCalendarClose={handleClose}
      allowSameDay={allowSameDay}
      inline={inline}
    >
      {helpButtons && (
        <div className={b("helper-buttons-wrapper")}>
          <DateHelpButtons
            helpButtons={helpButtons}
            onChange={onChange}
            datepickerRef={datepickerRef}
          />
        </div>
      )}
      {showTimeSelect && (
        <div className={b("time-input-wrapper")}>
          <TimeInput
            dateFrom={dateFrom}
            dateTo={dateTo}
            value={selected}
            selectsRange={selectsRange}
            onChange={handleTimeChange}
          />
        </div>
      )}
      {additionalDataField && (
        <div className={b("date-input-wrapper")}>
          <Input
            clearable={isClearable}
            value={selected}
            placeholderText={placeholder}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectsRange={selectsRange}
            isOpen={open}
            onOpen={handleOpen}
            onCustomChange={handleDayChange}
          />
        </div>
      )}
    </ReactDatePicker>
  );
}

export default React.memo(DatePicker);
