import React from 'react';
import { SvgProps } from '../types';

function RulerIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0208 3.58579C16.2398 2.80474 14.9734 2.80474 14.1924 3.58579L3.58579 14.1924C2.80474 14.9734 2.80474 16.2398 3.58579 17.0208L7.12132 20.5563C7.90237 21.3374 9.1687 21.3374 9.94975 20.5563L20.5563 9.94975C21.3374 9.1687 21.3374 7.90237 20.5563 7.12132L17.0208 3.58579ZM16.0459 5.53033C15.753 5.23744 15.3692 5.23744 15.0763 5.53033L5.53033 15.0763C5.23744 15.3692 5.20711 15.7071 5.5 16C5.79289 16.2929 6.20711 16.2929 6.5 16L7.73744 14.7626L8.26777 15.2929C8.56066 15.5858 9.03553 15.5858 9.32843 15.2929C9.62132 15 9.62132 14.5251 9.32843 14.2322L8.7981 13.7019L9.85876 12.6412L11.0962 13.8787C11.3891 14.1716 11.864 14.1716 12.1569 13.8787C12.4497 13.5858 12.4497 13.1109 12.1569 12.818L10.9194 11.5806L11.9801 10.5199L12.5104 11.0503C12.8033 11.3431 13.2782 11.3431 13.5711 11.0503C13.864 10.7574 13.864 10.2825 13.5711 9.98959L13.0407 9.45926L14.1014 8.3986L15.3388 9.63604C15.6317 9.92893 16.1066 9.92893 16.3995 9.63604C16.6924 9.34315 16.6924 8.86827 16.3995 8.57538L15.1621 7.33794L16.0459 6.45406C16.3388 6.16117 16.3388 5.82322 16.0459 5.53033Z"
        fill={color}
      />
    </svg>
  );
}

export default RulerIcon;
