import { SagaIterator } from 'redux-saga';
import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { addDays, format, subDays } from 'date-fns';

import NotificationManager from '../../services/notifications';
import Api, { ApiResponse } from "../../services/api";

import * as actions from './actions';
import * as versionsActions from "../versions/actions";
import { AppState, ErrorAction } from "../reducers";
import i18n from "../../services/i18n";

import {
  CreateGraphRequest,
  CreateGraphResponse,
  GetGraphicalScenariosRequest,
  GetGraphicalScenariosResponse,
  GetGraphRequest,
  GetGraphResponse,
  GetNodesStatisticsRequest,
  GetNodesStatisticsResponse,
  UpdateGraphRequest,
  UpdateGraphResponse,
} from '../../types/requests/Graph';

import { ScenarioGraph } from '../../types/models/ScenarioGraph';
import { parseScenarioGraph, parseScenarioGraphToBackend } from '../../types/parsers/ScenarioGraph';
import { getMainLanguage } from '../languages/selectors';
import { Lang } from '../../types/models/Languages';
import { generateId } from '../../services/helpers/generateId';
import authorizedPages from '../../routes/authorized';
import apiService from '../../services/api';
import openGraphById from '../../pages/graphEditor/helpers/openGraphById';
import * as scenarioGraphsActions from '../scenarioGraphs/actions';
import { getEmptyScenarioGraphRule, parseRulePredicate } from '../../pages/graphEditor/components/scenarioEditor/components/mainScreen/helpers';
import ApiError from '../../types/models/ApiError';
import { getCurrentVersionToDisplay, getVersionById, getVersions } from '../versions/selectors';
import { getVersionScenarios } from '../../components-new/versionSelector/helpers';
import { getIsSelectedProjectVoice } from '../projects/selectors';
import { ScenarioGraphMetaBackend } from '../../types/backendModels/ScenarioGraphsBackend';
import { getActiveGraphSlug, getGraphStatisticsEnabled, getGraphStatisticsFilters, getNodesStatistics } from './selectors';
import navigationService from '../../services/navigation';
import { ScenarioGroupName } from '../../types/backendModels/ScenarioGraphBackend';
import { ScenarioGraphNode } from '../../types/models/ScenarioGraphNode';
import { GraphStatisticsFilters, NodeStatistics } from '../../types/models/GraphStatistics';
import { isReleaseVersion, Version } from '../../types/models/Version';
import { parseGraphNodeStatistics } from '../../types/parsers/GraphStatistics';
import postYaMetric, { YaMetricsActions } from '../../services/ya-metrics';
import { saveGraphGroups } from '../graphGroups/actions';
import { getConfig } from '../user/selectors';
import { ShowGraphStatisticsPeriodFilterConfig } from '../../types/codegen/experiments';

function* createGraph(): SagaIterator {
  yield takeLatest(actions.createGraph.request, function* ({ payload }) {
    try {
      yield put(actions.highlightFreeLines());

      const mainLang: Lang = yield select(getMainLanguage) || '';
      const isVoiceProject: boolean = yield select(getIsSelectedProjectVoice);

      const { saveDefaultPredicate, openGraph, groups, openInNewWindow, newParentScenarios, ...graph } = payload;
      const params: CreateGraphRequest = {
        body: {
          ...parseScenarioGraphToBackend(graph, mainLang, isVoiceProject),
        },
      };

      const { data }: ApiResponse<CreateGraphResponse> = yield call(
        Api.createScenarioGraph,
        params,
      );

      if (groups !== undefined && groups.length > 0) {
        yield put(saveGraphGroups.request(groups));

        yield take([
          saveGraphGroups.success,
          saveGraphGroups.failure,
        ]);
      }

      const parsedGraph = parseScenarioGraph(data, mainLang);

      if (newParentScenarios) {
        yield put(scenarioGraphsActions.saveActiveScenarioUsage.request(newParentScenarios));
        yield take([
          scenarioGraphsActions.saveActiveScenarioUsage.success,
          scenarioGraphsActions.saveActiveScenarioUsage.failure,
        ]);
      }

      if (saveDefaultPredicate) {
        const defaultPredicate = getEmptyScenarioGraphRule(
          data.group_name as ScenarioGroupName || ScenarioGroupName.main,
          data.slug || '',
        );
        yield put(actions.updateGraph.request({
          ...parsedGraph,
          openGraph,
          openInNewWindow,
          predicate: { predicates: defaultPredicate.map(parseRulePredicate) },
          skipValidation: true,
          groups: groups,
        }));
      } else {
        yield put(actions.changeActiveGraph(parsedGraph));
        yield put(actions.createGraph.success(parsedGraph));
        NotificationManager.info(i18n.t('GRAPH.NOTIFICATION_GRAPH_CREATED'));

        if (openGraph) {
          openGraphById(data.id, openInNewWindow);
          actions.closeScenarioEditor();
        } else {
          yield put(scenarioGraphsActions.loadScenarios.request());
        }
      }
    } catch (err) {
      const duplicateError = (err as ApiError).code === 'duplicate_scenario_title';
      if (duplicateError) (err as ApiError).message = i18n.t("ERRORS.API.SCENARIOS.DUPLICATE_SLUG");
      yield put(
        actions.createGraph.failure(
          new ErrorAction(
            err,
            i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.SCENARIOS.SINGLE") }),
          ),
        ),
      );
    }
  });
}

function* updateGraph(): SagaIterator {
  yield takeLatest(actions.updateGraph.request, function* ({ payload }) {
    try {
      yield put(actions.highlightFreeLines());

      const { skipValidation, openGraph, openInNewWindow, groups, newParentScenarios, ...graph } = payload;

      if (newParentScenarios) {
        yield put(scenarioGraphsActions.saveActiveScenarioUsage.request(newParentScenarios));
        yield take([
          scenarioGraphsActions.saveActiveScenarioUsage.success,
          scenarioGraphsActions.saveActiveScenarioUsage.failure,
        ]);
      }

      const mainLang: Lang = yield select(getMainLanguage);
      const isVoiceProject: boolean = yield select(getIsSelectedProjectVoice);

      const params: UpdateGraphRequest = {
        params: {
          scenario_id: graph.id,
        },
        body: {
          ...parseScenarioGraphToBackend(graph, mainLang, isVoiceProject),
        },
      };

      const { data }: ApiResponse<UpdateGraphResponse> = yield call(
        Api.updateScenarioGraph,
        params,
      );

      const parsedGraph = parseScenarioGraph(data, mainLang);

      if (groups !== undefined) {
        yield put(saveGraphGroups.request(groups));
        yield take([
          saveGraphGroups.success,
          saveGraphGroups.failure,
        ]);
      }

      yield put(actions.updateGraph.success(parsedGraph));
      yield put(actions.changeActiveGraph(parsedGraph));
      yield put(scenarioGraphsActions.loadScenarios.request());

      if (skipValidation || parsedGraph.valid) {
        NotificationManager.info(i18n.t('GRAPH.NOTIFICATION_VALID_GRAPH_SAVED'));
      } else {
        NotificationManager.error(i18n.t("GRAPH.NOTIFICATION_INVALID_GRAPH_SAVED"));
      }

      if (openGraph) {
        openGraphById(data.id, openInNewWindow);
        actions.closeScenarioEditor();
      }
    } catch (err) {
      yield put(
        actions.createGraph.failure(
          new ErrorAction(
            err,
            i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SCENARIOS.MULTIPLE") }),
          ),
        ),
      );
    }
  });
}

function* createNestedGraph() {
  yield takeLatest(actions.createNestedGraph.request, function* ({ payload }) {
    const { nodeId, title: topicTitle } = payload;

    try {
      const newGraph: ScenarioGraph = {
        id: generateId(),
        title: topicTitle,
        extraModelTopics: [],
        nodes: [],
        links: [],
        automatable: true,
      };
      const mainLang: Lang = yield select(getMainLanguage);
      const isVoiceProject: boolean = yield select(getIsSelectedProjectVoice);

      const params: CreateGraphRequest = {
        body: parseScenarioGraphToBackend(newGraph, mainLang, isVoiceProject),
      };

      const response: ApiResponse<CreateGraphResponse> = yield call(
        apiService.createScenarioGraph,
        params,
      );

      const createdGraph = parseScenarioGraph(response.data, mainLang);
      const scenarioId = createdGraph.id;

      window.open(`${authorizedPages.editor}?scenario_id=${scenarioId}`);

      yield put(actions.createNestedGraph.success({ nodeId, scenarioId }));
      yield put(actions.loadGraphList.request());
    } catch (err) {
      yield put(actions.createNestedGraph.failure(
        new ErrorAction(
          err,
          i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.SCENARIOS.SINGLE") }),
        ),
      ));
    }
  });
}

function* loadGraphList() {
  yield takeLatest(actions.loadGraphList.request, function* () {
    try {
      const params: GetGraphicalScenariosRequest = {
        query: {
          limit: 0,
        },
      };

      const response: ApiResponse<GetGraphicalScenariosResponse> = yield call(
        Api.getGraphicalScenarios,
        params,
      );

      const mainLang: Lang = yield select(getMainLanguage);
      const scenarios: ScenarioGraph[] = response.data.scenarios
        .map(item => parseScenarioGraph(item, mainLang));
      yield put(actions.loadGraphList.success(scenarios));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SCENARIOS.MULTIPLE") });
      yield put(actions.loadGraphList.failure(
        new ErrorAction(err, text),
      ));
    }
  });
}

function* loadGraphById() {
  yield takeLatest(actions.loadGraphById.request, function* ({ payload }) {
    try {
      const path: GetGraphRequest['path'] = { scenario_id: payload };
      const response: ApiResponse<GetGraphResponse> = yield call(
        Api.getGraph,
        { path },
      );

      const scenarios = parseScenarioGraph(response.data);
      yield put(actions.loadGraphById.success(scenarios));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SCENARIOS.SINGLE") });
      yield put(actions.loadGraphById.failure(
        new ErrorAction(err, text),
      ));

      if (
        ((err as ApiError || {}).status === 403) ||
        ((err as ApiError || {}).status === 404)
      ) {
        NotificationManager.info(i18n.t("NEW_PAGE_DIALOGS_HISTORY.DIALOG_MODAL.SCENARIO_DOESNT_EXIST"));
        navigationService.navigateTo(authorizedPages.graphSelect);
      }
    }
  });
}

function* loadNodesStatistics(): SagaIterator {
  yield takeLatest(actions.loadNodesStatistics.request, function* (action) {
    try {
      const versions: AppState['versions']['list'] = yield select(getVersions);
      if (!versions.wasLoaded) {
        if (!versions.loading) {
          yield put(versionsActions.loadVersions.request());
        }

        yield take([
          versionsActions.loadVersions.success,
          versionsActions.loadVersions.failure,
        ]);
      }

      const nodesIdList = action.payload;
      const versionId: string = yield select(getCurrentVersionToDisplay);
      const version: Version | undefined = yield select(getVersionById, versionId);

      if (!version) {
        throw new Error("Can't download nodes statistics. Version unknown");
      }
      if (!isReleaseVersion(version)) {
        throw new Error("Can't download nodes statistics. Version isn't release");
      }

      const existedStatisticsIds: Record<
        ScenarioGraphNode['id'],
        NodeStatistics
      > = yield select(getNodesStatistics);

      const idsToLoad = nodesIdList
        .filter(id => !Object.keys(existedStatisticsIds.nodes).includes(id));

      if (idsToLoad.length === 0) {
        yield put(actions.loadNodesStatistics.success([]));
        return;
      }

      const filtersConfig: ShowGraphStatisticsPeriodFilterConfig = yield select(getConfig, 'supportai-frontend:show-graph-statistics-period-filter');

      let dateFrom;
      let dateTo;
      if (filtersConfig?.enabled) {
        const filters: GraphStatisticsFilters = yield select(getGraphStatisticsFilters);

        dateFrom = format(filters.dateFrom, "yyyy-MM-dd");
        dateTo = format(addDays(filters.dateTo ?? new Date(), 1), "yyyy-MM-dd");

        if (dateFrom === dateTo) {
          dateFrom = format(subDays(new Date(filters.dateFrom), 1), "yyyy-MM-dd");
        }
      } else {
        dateFrom = format(new Date(version.created), "yyyy-MM-dd");
        dateTo = format(addDays(new Date(), 1), "yyyy-MM-dd");

        if (dateFrom === dateTo) {
          dateFrom = format(subDays(new Date(), 1), "yyyy-MM-dd");
        }
      }

      const request: GetNodesStatisticsRequest = {
        body: {
          nodes: idsToLoad,
          version_id: String(version.id),
          version_type: "release",
          begin: dateFrom,
          end: dateTo,
        },
      };

      const response: ApiResponse<GetNodesStatisticsResponse> = yield call(
        apiService.getNodesStatistics,
        request,
      );

      const data = response.data.map<NodeStatistics>(parseGraphNodeStatistics);

      yield put(actions.loadNodesStatistics.success(data));
    } catch (err) {
      const text = i18n.t("ERRORS.API.NODES_STATISTICS.COMMON");
      yield put(actions.loadNodesStatistics.failure(new ErrorAction(err, text)));
    }
  });
}

function* changeVersionGraph() {
  yield takeLatest(versionsActions.changeVersionToDisplay, function* () {
    try {
      yield put(actions.setGraphLoadingStatus(true));

      const version: string = yield select(getCurrentVersionToDisplay);
      const activeScenario: string = yield select(getActiveGraphSlug);

      if (!activeScenario) return;

      const scenarios: ScenarioGraphMetaBackend[] = yield call(getVersionScenarios, version);

      const scenario = scenarios.find(s => s.slug === activeScenario);
      if (scenario) {
        openGraphById(scenario.id);
      } else {
        NotificationManager.error(i18n.t("NEW_PAGE_DIALOGS_HISTORY.DIALOG_MODAL.SCENARIO_DOESNT_EXIST"));
        navigationService.navigateTo(authorizedPages.graphSelect);
      }
    } catch {
      NotificationManager.error("GRAPH.CANT_LOAD_VERSION");
      navigationService.navigateTo(authorizedPages.graphSelect);
    } finally {
      yield put(actions.setGraphLoadingStatus(false));
    }
  });
}

function* sendStatsMetrics(): SagaIterator {
  yield takeLatest(
    actions.setStatsEnabled,
    function(action) {
      const newStatus = action.payload;

      if (newStatus) {
        postYaMetric(YaMetricsActions.graphEnableStatistics);
      }
    });
}

function* updateStatisticsFilters(): SagaIterator {
  yield takeLatest([
    actions.setStatsEnabled,
    versionsActions.changeVersionToDisplay,
  ], function* () {
    const statsEnabled: boolean = yield select(getGraphStatisticsEnabled);
    if (!statsEnabled) return;

    const versionId: string = yield select(getCurrentVersionToDisplay);
    const version: Version | undefined = yield select(getVersionById, versionId);

    if (!version) return;

    yield put(actions.setGraphStatisticsFilters({
      dateFrom: new Date(version.created),
      dateTo: new Date(),
    }));
  });
}

export default function* sagas(): SagaIterator {
  yield fork(createGraph);
  yield fork(updateGraph);
  yield fork(loadGraphList);
  yield fork(createNestedGraph);
  yield fork(loadGraphById);
  yield fork(changeVersionGraph);
  yield fork(loadNodesStatistics);
  yield fork(sendStatsMetrics);
  yield fork(updateStatisticsFilters);
}
