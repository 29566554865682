import { dateMscToLocal } from "../../services/helpers/dateUtils";
import { generateId } from "../../services/helpers/generateId";
import { objSnakeToCamel } from "../../services/helpers/utilities";
import { Event } from "../models/Event";
import { GetChangeHistoryResponse } from "../requests/EventsHistory";

export function parseChangesHistory(response: GetChangeHistoryResponse): Event[] {
  return response.changes_history.flatMap(chunk => chunk.events.map(e => ({
    ...objSnakeToCamel(e),
    id: generateId(),
    userId: chunk.user_id,
    created: e.created ?
      dateMscToLocal(new Date(e.created)).toString() :
      "",
  })));
}
