import React from 'react';

import { SvgProps } from '../types';

function UndoIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0899 1.70718L12.6757 0.292969L7.9686 5.00008L12.6757 9.70718L14.0899 8.29297L11.7998 6.00287C11.8663 6.001 11.933 6.00006 12 6.00006C15.866 6.00006 19 9.13407 19 13.0001C19 16.8661 15.866 20.0001 12 20.0001C8.13401 20.0001 5 16.8661 5 13.0001C5 11.0671 5.7835 9.31706 7.05025 8.05031L5.63604 6.6361C4.00736 8.26478 3 10.5148 3 13.0001C3 17.9706 7.02944 22.0001 12 22.0001C16.9706 22.0001 21 17.9706 21 13.0001C21 8.0295 16.9706 4.00006 12 4.00006C11.9314 4.00006 11.863 4.00083 11.7947 4.00236L14.0899 1.70718Z"
        fill={color}
      />
    </svg>
  );
}

export default UndoIcon;
