import { Version, isCurrentReleaseVersion, isReleaseVersion } from "../../types/models/Version";
import { AppState } from "../reducers";
import { DRAFT_VERSION } from "./reducer";

export function getVersions(state: AppState) {
  return state.versions.list;
}

export function getVersionsLoadingStatus(state: AppState) {
  return state.versions.list.loading;
}

export function getVersionsAvailableToSwitch(state: AppState) {
  const versions = state.versions.list;

  const currentReleaseVersion = versions.value.find(isCurrentReleaseVersion);
  const oldReleaseVersions = versions.value
    .slice()
    .filter(v => !isCurrentReleaseVersion(v) && isReleaseVersion(v))
    .sort((a, b) => +a.id > +b.id ? -1 : 1);

  let result = [DRAFT_VERSION];

  if (currentReleaseVersion) {
    result.push(currentReleaseVersion);
  }

  result = result.concat(oldReleaseVersions);

  return result.slice(0, 7);
}

export function getActiveVersion(state: AppState) {
  return state.versions.list.value.find(v => v.isActive);
}

export function getCurrentVersionToDisplay(state: AppState) {
  return state.versions.versionToDisplay;
}

export function isCurrentVersionRelease(state: AppState) {
  return state.versions.versionToDisplay !== DRAFT_VERSION.id;
}

export function getVersionById(state: AppState, id: Version['id']) {
  return state.versions.list.value.find(v => v.id === id);
}
