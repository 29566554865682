import {
  Dialog,
  RequestMessage,
  ResponseMessage,
} from "../../types/models/Dialog";
import { getFeatures } from "../features/selectors";
import { AppState } from "../reducers";
import { getConfig } from "../user/selectors";
import { defaultFilters, DialogMessage, GraphDebugBlockWithLoading, LiveDialog } from "./reducer";

export const getLiveChat = (state: AppState): LiveDialog => state.dialogs.liveDialog;

export const getLiveChatId = (state: AppState): string => state.dialogs.liveDialog.id;

export const getChatIdByMessageId = (
  state: AppState,
  messageId: string,
): string => state.dialogs.history.value
  .find(dialog => dialog.messages
    .some(message => message.id === messageId))?.id || '';

export const getHistoryMessageById = (
  state: AppState,
  messageId: string,
): RequestMessage | ResponseMessage | undefined => {
  let result;

  state.dialogs.history.value.forEach((dialog: Dialog) => {
    const messageInDialog = dialog.messages.find(message => message.id === messageId);
    if (messageInDialog) {
      result = messageInDialog;
    }
  });

  return result;
};

export const getCurrentDialog = (state: AppState): Dialog => state.dialogs.currentDialog.value;
export const isCurrentDialogLoading = (state: AppState): boolean => state.dialogs.currentDialog.loading;

export const getLiveMessageById = (
  state: AppState,
  messageId: ResponseMessage['id'],
): DialogMessage | undefined => state.dialogs.liveDialog.messages
  .find(message => message.id === messageId);

export const getDispatchFeatures = (
  state: AppState,
): { [featureSlug: string]: string | number | boolean } => {
  const projectFeatures = getFeatures(state);
  const projectFeaturesSlugs = projectFeatures.map(f => f.slug);

  return Object.keys(state.dialogs.liveDialog.features)
    .filter(dispatchFeatureSlug => projectFeaturesSlugs.includes(dispatchFeatureSlug))
    .reduce<Record<string, string | number | boolean>>((result, featureSlug) => {
      result[featureSlug] = state.dialogs.liveDialog.features[featureSlug];
      return result;
    }, {});
};

export const getDialogHistorySearchFilter = ({ dialogs }: AppState) => ({
  ...dialogs.searchFilter,
});

export const getNewDialogHistorySearchFilter = ({ dialogs }: AppState) => ({
  ...dialogs.newSearchFilter,
  topics: undefined,
});

export const getDefaultFiltersForNewDialogHistory = () => ({ ...defaultFilters });

export const getSavedUserMessage = (
  state: AppState,
): RequestMessage | null => state.dialogs.liveDialog.savedUserMessage;

export const getLiveDialogMessages = ({ dialogs }: AppState) => dialogs.liveDialog.messages;

export const getLiveDialogSendindStatus = (
  state: AppState,
): boolean => state.dialogs.liveSending;

export const getDialogs = ({ dialogs }: AppState) => dialogs.history.value;
export const getDialogsCount = ({ dialogs }: AppState) => dialogs.history.total;
export const getDialogsLoading = ({ dialogs }: AppState) => dialogs.history.loading;
export const getDialogById = ({ dialogs }: AppState, id: Dialog['id']) => dialogs.history.value.find(d => d.id === id);

export const getMarkLoading = ({ dialogs }: AppState) => dialogs.markLoading;

type T = GraphDebugBlockWithLoading | undefined;
export const getDebugById = (id: string) => ({ dialogs }: AppState): T => dialogs.debugBlocksById[id];

export const getProcessExamplesModalIsOpen = ({ dialogs }: AppState) => dialogs.processExamplesModalOpened;
export const getHistoryExportDialogsModalIsOpen = ({ dialogs }: AppState) => dialogs.historyExportDialogsModalOpened;
export const getExportDialogsModalIsOpen = ({ dialogs }: AppState) => dialogs.exportDialogsModalOpened;

export const getShowDialogTimingStatus = (state: AppState) =>{
  const config = getConfig(state, 'supportai-frontend:show-dialog-timing');

  if (!config?.enabled) return false;

  return state.dialogs.showDialogTiming;
};
