import apiService, { ApiResponse } from "../../services/api";
import { CacheItem } from "../../services/cache/cacheItem";
import { deepClone, divideBy, getFilenameWithoutExtension } from "../../services/helpers/utilities";
import { ResponseButtonsBlockBackend, SupportResponseBackend } from "../../types/backendModels/DialogBackend";
import {
  Dialog,
  ResponseMessage,
} from "../../types/models/Dialog";
import { Feature } from "../../types/models/Feature";
import { guessIsChatterboxMacros } from "../../types/models/Macros";
import { MessageAuthor } from "../../types/models/Message";
import { Project } from "../../types/models/Project";
import { TextType } from "../../types/models/ScenarioGraphAction";
import { GetChatterboxMacrosResponse, GetMacrosListResponse } from "../../types/requests/Macros";
import { FilesState } from "../files/reducer";

type CacheColumnsMap = Record<Project['slug'], Array<Feature['slug']>>;

export function isMessageHasComment(message: ResponseMessage) {
  return !!message.resolution && typeof message.resolution.comment.value === "string";
}
export function parseAudioMessagesToFullComment(parsedDialogs: Dialog[], audioFiles: FilesState['audioFiles']) {
  return parsedDialogs.map(dialog => ({
    ...dialog,
    messages: dialog.messages.map(message => ({
      ...message,
      ...(message.texts && { texts: message.texts?.map(text => {
        const audiofile = audioFiles.list.find(file => getFilenameWithoutExtension(file.filename) === text);
        return audiofile ? audiofile.userFilename : text;
      },
      ) }),
    }),
    ) }),
  );
}

export function findMacroses(dialog: Dialog): string[] {
  return dialog.messages
    .filter(message => message.author === MessageAuthor.Ai)
    .filter(message => {
      message = message as ResponseMessage;

      return message.textType === TextType.macro;
    })
    .map(message => {
      message = message as ResponseMessage;

      return (message.texts || []) as string[];
    })
    .flat();
}

export function findMacrosesInNewDialog(dialog: Dialog): string[] {
  return dialog.messages
    .filter(message => message.author === MessageAuthor.Ai || message.author === MessageAuthor.Support)
    .filter(message => {
      message = message as ResponseMessage;

      return message.texts?.length && guessIsChatterboxMacros(message.texts[0]);
    })
    .map(message => {
      message = message as ResponseMessage;

      return (message.texts || []) as string[];
    })
    .flat();
}

export async function getMacrosesTexts(macroses: string[]): Promise<Array<{
  macros: string,
  text: string,
}>> {
  let macrosTexts: Array<{
    macros: string,
    text: string,
  }> = [];

  const [
    newMacroses,
    oldMacroses,
  ] = divideBy<string>(macroses, (macros: string) => macros.startsWith('macro_var_'));

  const requestForNewMacroses = apiService.getMacrosList({ body: { slugs: newMacroses } });
  const requestsForOldMacroses = oldMacroses.map(macros => apiService.getChatterboxMacros({
    path: {
      macros_id: Number(macros),
    },
  }));

  await Promise.allSettled([
    requestForNewMacroses,
    ...requestsForOldMacroses,
  ]).then(results => {
    return results.forEach((result, index) => {
      if (index === 0) {
        // Обработка ответа запроса на новые макросы
        if (result.status === "rejected") {
          newMacroses.forEach(macros => {
            macrosTexts.push({ macros: macros, text: macros });
          });
        } else {
          const response = (result.value as unknown as ApiResponse<GetMacrosListResponse>);
          const macrosList = response?.data.macros;

          newMacroses.forEach(newMacrosSlug => {
            const newMacros = macrosList.find(m => m.slug === newMacrosSlug);

            macrosTexts.push({
              macros: newMacrosSlug,
              text: newMacros?.text || newMacrosSlug,
            });
          });
        }
      } else {
        // Обработка ответов запросов на старые макросы
        // eslint-disable-next-line no-lonely-if
        if (result.status === "rejected") {
          macrosTexts.push({
            macros: oldMacroses[index - 1],
            text: oldMacroses[index - 1],
          });
        } else {
          const response = (result.value as unknown as ApiResponse<GetChatterboxMacrosResponse>);

          if (!response.data.macro_id) return;

          macrosTexts.push({
            macros: response.data.macro_id,
            text: response.data.comment || "",
          });
        }
      }
    });
  });

  return macrosTexts;
}

export function fillMacroses(
  dialog: Dialog,
  macroses: Array<{ macros: string, text: string }>,
): Dialog {
  return {
    ...dialog,
    messages: dialog.messages.map(message => {
      if (message.author !== MessageAuthor.Ai) return message;
      if ((message as ResponseMessage).textType !== TextType.macro) return message;

      message = deepClone(message) as ResponseMessage;

      message.macrosTexts = (message.texts || []).map(text => {
        const macros = macroses.find(m => m.macros === text);

        if (macros) {
          return macros.text;
        }

        return text;
      });

      return message;
    }),
  };
}

export function fillMacrosesNewDialogs(
  dialog: Dialog,
  macroses: Array<{ macros: string, text: string }>,
): Dialog {
  return {
    ...dialog,
    messages: dialog.messages.map(message => {
      if (!(message.author === MessageAuthor.Ai || message.author === MessageAuthor.Support)) return message;
      if (!(message.texts?.length && guessIsChatterboxMacros(message.texts[0]))) return message;

      message = deepClone(message) as ResponseMessage;
      message.macrosTexts = (message.texts || []).map(text => {
        const macros = macroses.find(m => m.macros === text);

        if (macros) {
          return macros.text;
        }

        return text;
      });

      return message;
    }),
  };
}

export function appendButtons(
  response: SupportResponseBackend,
  buttons: ResponseButtonsBlockBackend,
): SupportResponseBackend {
  return {
    ...response,
    buttons_block: buttons,
  };
}

export function getCachedColumns(project: Project['slug']): Array<Feature['slug']> {
  try {
    const cache: CacheColumnsMap = JSON.parse(localStorage.getItem(CacheItem.EXPORT_DIALOGS_COLUMNS) || "");
    if (!cache) return [];
    if (!cache[project]) return [];
    if (Array.isArray(cache)) return [];

    return cache[project];
  } catch {
    return [];
  }
}

export function cacheColumns(
  project: Project['slug'],
  columns: Array<Feature['slug']>,
): boolean {
  try {
    const cacheString: string | null = localStorage.getItem(CacheItem.EXPORT_DIALOGS_COLUMNS);
    let cache: CacheColumnsMap = cacheString ?
      JSON.parse(cacheString || "{}") :
      {};

    if (Array.isArray(cache)) cache = {};

    cache[project] = columns;
    localStorage.setItem(CacheItem.EXPORT_DIALOGS_COLUMNS, JSON.stringify(cache));
    return true;
  } catch (err) {
    console.error("Can't cache columns", err);
    return false;
  }
}

// Подчищаем legacy закешированные столбцы выгрузки диалогов
function cleanLegacyCacheColumns() {
  try {
    const cacheString = localStorage.getItem(CacheItem.EXPORT_DIALOGS_COLUMNS);
    const cache = JSON.parse(cacheString ?? "{}");

    if (Array.isArray(cache)) {
      localStorage.removeItem(CacheItem.EXPORT_DIALOGS_COLUMNS);
    }
  } catch (err) {
    console.error(err);
  }
}
cleanLegacyCacheColumns();
