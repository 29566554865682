/* eslint-disable camelcase */
import { ApiParameter, ApiTokenAuthorization, GrpcRequest, HttpRequest, Integration, IntegrationTest, KeyValueSource, TvmAuthorization } from "~/api-types/backend-py3/services/supportai-actions/docs/yaml/api/integrations";
import { IntegrationLabel } from "~/api-types/backend-py3/services/supportai-actions/docs/yaml/definitions";

export type IntegrationBackend = Integration;

export type ApiRequestBackend = HttpRequestBackend | GRPCRequestBackend;

export type HttpRequestBackend = HttpRequest;

export type GRPCRequestBackend = GrpcRequest;

export type KeyValueBackend = KeyValueSource;

export type ApiParameterBackend = ApiParameter;

export enum BodyFormat {
  JSON = 'json',
  XML = 'xml',
  FORM_DATA = 'form-data',
  BYTES = 'bytes',
  X_WWW_FORM_URLENCODED = 'x-www-form-urlencoded'
}

export const DEFAULT_BODY_FORMAT = BodyFormat.JSON;

export type IntegrationFileFieldBackend = {
  file_download_url?: string;
  content_type?: string;
  filename?: string;
  file_download_authorization?: IntegrationFileAuthorizationBackend;
  file_auth?: IntegrationFileAuthorizationBackend;
}

export type IntegrationFileAuthorizationBackend = TvmAuthBackend | TokenAuthBackend | undefined;

export type IntegrationLabelBackend =IntegrationLabel;

export type IntegrationTestBackend = IntegrationTest;

export type TvmAuthBackend = TvmAuthorization;

export type TokenAuthBackend = ApiTokenAuthorization;
