import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeResponseIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="22" height="22" rx="7" fill="none" stroke={color} strokeWidth="2" />
  </svg>
);

export default GraphNodeResponseIcon;
