import i18n from "../../services/i18n";

import { Slot } from "../../types/models/Slots";

import { ColumnsConfiguration } from "../../components-new/table/types";
import Button from "../../components-new/button";

import { b } from ".";

export function getColumns({
  onUnlockSlotClick,
}: {
  onUnlockSlotClick: (e: React.MouseEvent) => void,
}): ColumnsConfiguration {
  return [
    {
      id: "shard",
      accessor: "shardId",
      title: i18n.t("MODALS.MANAGE_SLOTS.SHARD"),
    },
    {
      id: "shard",
      accessor: "workerId",
      title: i18n.t("MODALS.MANAGE_SLOTS.WORKER"),
    },
    {
      id: "actions",
      accessor: "",
      title: "",
      className: b("actions"),
      cellRenderer: (slot: Slot) => (
        <div className={b("show-on-hover")}>
          <Button
            simple
            icon="trash"
            iconColor="var(--accent-light-red)"
            name={slot.id}
            onClick={onUnlockSlotClick}
          />
        </div>
      ),
    },
  ];
}
