import { createAsyncAction, createAction } from "typesafe-actions";

import { VersionStatus, Version } from "../../types/models/Version";
import { ErrorAction } from "../reducers";

export const loadVersions = createAsyncAction(
  'manageVersions/loadVersions/requested',
  'manageVersions/loadVersions/succeeded',
  'manageVersions/loadVersions/failed',
)<undefined, Version[], ErrorAction>();

export const createVersion = createAsyncAction(
  'manageVersions/createVersion/requested',
  'manageVersions/createVersion/succeeded',
  'manageVersions/createVersion/failed',
)<{
  versionName: string,
  modalId?: string, // TODO  нехорошо передавать modalId, нужно посмотреть, как можно избавиться
},
  Version,
  ErrorAction
>();

export const deleteVersion = createAsyncAction(
  'manageVersions/deleteVersion/requested',
  'manageVersions/deleteVersion/succeeded',
  'manageVersions/deleteVersion/failed',
)<string, string, ErrorAction<{ id: string}>>();

export const saveVersions = createAsyncAction(
  'manageVersions/saveVersions/requested',
  'manageVersions/saveVersions/succeeded',
  'manageVersions/saveVersions/failed',
)<undefined, Version, ErrorAction>();

export const changeActiveVersion = createAction(
  'manageVersions/changeActiveVersion',
)<Version['id']>();

export const reloadCurrentVersionStatus = createAsyncAction(
  'manageVersions/reloadCurrentVersionStatus/requested',
  'manageVersions/reloadCurrentVersionStatus/succeeded',
  'manageVersions/reloadCurrentVersionStatus/failed',
)<undefined, VersionStatus | undefined, ErrorAction>();

export const currentVersionLoaded = createAction(
  'manageversions/currentVersionLoaded',
)();
export const currentVersionError = createAction(
  'manageVersions/currentVersionError',
)<{ description: string }>();

export const changeVersionToDisplay = createAction(
  'manageVersions/changeVersionToDisplay',
)<Version['id']>();
