export enum CacheItem {
  DIALOGS_HISTORY_FILTER = '01',
  SIDEBAR_OPENED_ITEMS = '02',
  LOCAL_CHANGES_GRAPH = '03',
  GRAPH_SELECTED_TOPIC = '04',
  PROJECT_STATISTICS = '05',
  DISPATCH_FEATURES = '06',
  DIALOGS_HISTORY_NEW_FILTER = '07',
  EXPORT_DIALOGS_COLUMNS = '08',
  VERSION = '09',
  SHOW_DIALOG_TIMING = '10',
  SCENARIOS_FILTER = '11',
}
