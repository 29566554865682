import React, { FocusEventHandler, ReactNode, useCallback } from 'react';
import { block } from 'bem-cn';

import Icon from '../icon';

import './styles.scss';

const b = block("nd-checkbox");

export type OnCheck = (event: React.ChangeEvent<HTMLInputElement>) => void;

export type CheckboxProps = {
  skeleton?: boolean;
  value: boolean;
  mixed?: boolean;
  name: string;
  className?: string;
  size?: 'medium' | 'small';
  label?: ReactNode;
  disabled?: boolean;
  bold?: boolean;
  readonly?: boolean;
  onChange?: OnCheck;
  onClick?: React.MouseEventHandler;
  onBlur?: FocusEventHandler;
}

function Checkbox({
  skeleton = false,
  value,
  name,
  className = '',
  label,
  size = 'small',
  bold = false,
  disabled = false,
  readonly = false,
  mixed,
  onChange = () => {},
  onClick,
  onBlur,
}: CheckboxProps) {
  const iconSize = size === "medium" ? "24" : "20";

  const focusInput = useCallback(
    (event: React.MouseEvent) => {
      const inputEl = (event.target as HTMLInputElement).querySelector('input');

      if (inputEl) {
        inputEl.focus();
      }
    },
    [],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!onClick) return;

      // @ts-ignore
      e.currentTarget.name = name;

      onClick(e);
    },
    [name, onClick],
  );

  return skeleton ?
    (
      <div className={`${b({ size, disabled, skeleton: true })} ${className}`}>
        <div className={b("mask")} />
        <div className={b("label")}>
          {label}
        </div>
      </div>
    ) :
    (
      <label
        className={`${b({ size, disabled, bold })} ${className}`}
        onMouseDown={focusInput}
        onClick={handleClick}
      >
        <input
          disabled={disabled}
          name={name}
          readOnly={readonly}
          type="checkbox"
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <div className={b("mask", { checked: value })}>
          {value && (
            <Icon
              svgProps={{ width: iconSize, height: iconSize }}
              type={mixed ? "minus" : "checkmarkSmall"}
            />
          )}
        </div>
        {label && (
          <span className={b("label").toString()}>
            {label}
          </span>
        )}
      </label>
    );
}

export default React.memo(Checkbox);
