import React from 'react';
import { SvgProps } from '../types';

function CalendarIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V3.001C5.039 3.015 4.66 3.13 4.279 3.334C3.87638 3.54714 3.54714 3.87638 3.334 4.279C3.116 4.686 3 5.09 3 6.205V16.795C3 17.909 3.116 18.314 3.334 18.721C3.552 19.128 3.872 19.448 4.279 19.666C4.686 19.884 5.09 20 6.205 20H17.795C18.909 20 19.314 19.884 19.721 19.666C20.128 19.448 20.448 19.128 20.666 18.721C20.884 18.314 21 17.91 21 16.795V6.205C21 5.09 20.884 4.686 20.666 4.279C20.4529 3.87638 20.1236 3.54714 19.721 3.334C19.34 3.13 18.961 3.015 18 3V2C18 1.73478 17.8946 1.48043 17.7071 1.29289C17.5196 1.10536 17.2652 1 17 1C16.7348 1 16.4804 1.10536 16.2929 1.29289C16.1054 1.48043 16 1.73478 16 2V3H8V2C8 1.73478 7.89464 1.48043 7.70711 1.29289C7.51957 1.10536 7.26522 1 7 1ZM5 8V16.795C5 17.222 5.019 17.489 5.049 17.644C5.061 17.704 5.066 17.718 5.098 17.778C5.12452 17.8321 5.16808 17.876 5.222 17.903C5.282 17.934 5.295 17.939 5.356 17.951C5.511 17.981 5.778 18 6.205 18H17.795C18.222 18 18.489 17.981 18.644 17.951C18.6915 17.944 18.7371 17.9273 18.778 17.902C18.8321 17.8755 18.876 17.8319 18.903 17.778C18.9279 17.7371 18.9443 17.6915 18.951 17.644C18.981 17.489 19 17.222 19 16.795L19.004 8H5ZM13.75 12C13.5511 12 13.3603 12.079 13.2197 12.2197C13.079 12.3603 13 12.5511 13 12.75V15.25C13 15.664 13.336 16 13.75 16H16.25C16.4489 16 16.6397 15.921 16.7803 15.7803C16.921 15.6397 17 15.4489 17 15.25V12.75C17 12.5511 16.921 12.3603 16.7803 12.2197C16.6397 12.079 16.4489 12 16.25 12H13.75Z"
        fill={color}
      />
    </svg>
  );
}

export default CalendarIcon;
