import { addDays, differenceInDays, isSameDay, startOfDay } from "date-fns";
import { SamplingStatisticsPlotItem } from "../../types/models/Sampling";
import { StatsByDayPlotItem } from "../../types/models/QualityStats";
import { ResolutionType } from "../../types/models/Resolution";

export function getDatesRange(
  dateFrom: Date,
  dateTo: Date,
) {
  const daysDiff = differenceInDays(dateTo, dateFrom);
  const rangeLength = daysDiff + 1;

  const result: Date[] = [];

  let current = startOfDay(new Date(+dateFrom));
  for (let i = 0; i < rangeLength; i++) {
    result.push(current);
    current = addDays(current, 1);
  }

  return result;
}

export function placeStatisticsInRange(
  dateFrom: Date,
  dateTo: Date,
  statistics: SamplingStatisticsPlotItem[],
): SamplingStatisticsPlotItem[] {
  const range = getDatesRange(dateFrom, dateTo);

  return range.map((date: Date) => {
    const dateStatistics = statistics.find(stats => isSameDay(date, new Date(stats.datetime)));

    if (!dateStatistics) {
      return {
        datetime: String(date),
        markedCount: 0,
        okCount: 0,
      };
    }

    return {
      datetime: String(date),
      markedCount: dateStatistics.markedCount,
      okCount: dateStatistics.okCount,
    };
  });
}

export const getDayTotalMarked = (stats: StatsByDayPlotItem): number => {
  return stats.resolutionsStats.reduce((res, item) => res + item.count, 0);
};

export const getDayMarked = (
  stats: StatsByDayPlotItem,
  resolutions: Array<ResolutionType['id']>,
): number => {
  return stats.resolutionsStats.reduce(
    (res, item) => resolutions.includes(item.resolutionTypeId) ?
      res + item.count :
      res,
    0,
  );
};
