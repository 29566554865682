import { Feature } from '../../types/models/Feature';
import { AppState } from '../reducers';

export const getFeatures = (state: AppState): Feature[] => state.features.list;

export const getFeaturesLoadingStatus = (state: AppState): boolean => state.features.loading;

export const getNonCoreFeatures = (state: AppState): Feature[] => state.features.list
  .filter(feature => !feature.isCore);

export const getFeaturesBySlugs = (state: AppState, slugs: Feature['slug'][]) => (
  state.features.list.filter(feature => slugs.includes(feature.slug))
);

export const checkIfFeatureIsNonCore = (k: string) => ({ features }: AppState) => (
  features.list.some(({ isCore, slug }) => !!isCore && slug === k)
);

export const getFavoriteFeatures = (state: AppState): string[] => state.features.favoriteFeatures;

export const getFeaturesCreatingStatus = (state: AppState): boolean => state.features.createProcessing;
