import { OVERFLOW_BADGE_WIDTH_PX } from "./valueContainerFloating";

export function getOverflowItemsCount(
  elem: HTMLElement,
  targetsClassName?: string,
): number {
  let result = 0;

  const parentRect = elem.getBoundingClientRect();

  elem.childNodes.forEach(item => {
    if (targetsClassName &&
      !(item as HTMLElement).classList.contains(targetsClassName)
    ) {
      return;
    }

    const childRect = (item as HTMLElement).getBoundingClientRect();

    if (childRect.right > parentRect.right) {
      result += 1;
    }
  });

  return result;
}

export function getFirstOverflowItemPosition(elem: HTMLElement): number | undefined {
  let result: number | undefined = undefined;

  const parentRect = elem.getBoundingClientRect();

  elem.childNodes.forEach(item => {
    if (result) return;
    const childRect = (item as HTMLElement).getBoundingClientRect();

    if (childRect.right > parentRect.right) {
      result = Math.min(
        childRect.left - parentRect.left,
        parentRect.right - parentRect.left - OVERFLOW_BADGE_WIDTH_PX,
      );
    }
  });

  return result;
}
