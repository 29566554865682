import { Reducer } from 'redux';
import { createReducer, ActionType } from "typesafe-actions";

import * as actions from './actions';

import {
  ProjectUser, User, UserAccesses,
} from '../../types/models/UserAccesses';

export type UserAccessesState = {
  projectUsers: ProjectUser[];
  projectUsersLoading: boolean;
  allUsers: User[];
  allUsersLoading: boolean;
  userData: UserAccesses | null;
  userDataLoading: boolean;
  editableUser: User | null;
}

const initialState: UserAccessesState = {
  projectUsers: [],
  projectUsersLoading: false,
  allUsers: [],
  allUsersLoading: false,
  userData: null,
  userDataLoading: false,
  editableUser: null,
};

type State = UserAccessesState;
export const accessesReducer: Reducer<State> = createReducer<State>(initialState)
  .handleAction(
    actions.loadProjectUsersList.request,
    (state: UserAccessesState): UserAccessesState => ({
      ...state,
      projectUsersLoading: true,
    }),
  )
  .handleAction(
    actions.loadProjectUsersList.success,
    (
      state: UserAccessesState,
      { payload }: ActionType<typeof actions.loadProjectUsersList.success>,
    ): UserAccessesState => ({
      ...state,
      projectUsers: payload,
      projectUsersLoading: false,
    }),
  )
  .handleAction(
    actions.loadProjectUsersList.failure,
    (state: UserAccessesState): UserAccessesState => ({
      ...state,
      projectUsersLoading: false,
    }),
  )
  .handleAction(
    actions.loadAllUsers.request,
    (state: UserAccessesState): UserAccessesState => ({
      ...state,
      allUsersLoading: true,
    }),
  )
  .handleAction(
    actions.loadAllUsers.success,
    (
      state: UserAccessesState,
      { payload }: ActionType<typeof actions.loadAllUsers.success>,
    ): UserAccessesState => ({
      ...state,
      allUsers: payload,
      allUsersLoading: false,
    }),
  )
  .handleAction(
    actions.loadAllUsers.failure,
    (state: UserAccessesState): UserAccessesState => ({
      ...state,
      allUsersLoading: false,
    }),
  )
  .handleAction(
    actions.getUserById.request,
    (state: UserAccessesState): UserAccessesState => ({
      ...state,
      userDataLoading: true,
    }),
  )
  .handleAction(
    actions.getUserById.success,
    (
      state: UserAccessesState,
      { payload }: ActionType<typeof actions.getUserById.success>,

    ): UserAccessesState => ({
      ...state,
      userData: payload,
      userDataLoading: false,
    }),
  )
  .handleAction(
    actions.getUserById.failure,
    (state: UserAccessesState): UserAccessesState => ({
      ...state,
      userDataLoading: false,
    }),
  )
  .handleAction(
    actions.setEditableUser,
    (
      state: UserAccessesState,
      { payload }: ActionType<typeof actions.setEditableUser>,
    ): UserAccessesState => ({
      ...state,
      editableUser: payload,
    }),
  );
