import { generateId } from "../../services/helpers/generateId";
import { ScenarioGroupName } from "../backendModels/ScenarioGraphBackend";
import { Project } from "./Project";
import { Predicate, getDefaultPredicate } from "./ScenarioGraphAction";
import { ScenarioGraphGroup } from "./ScenarioGraphGroup";
import { ScenarioGraphLabel } from "./ScenarioGraphLabel";
import { ScenarioGraphLink } from "./ScenarioGraphLink";
import { ScenarioGraphNode } from "./ScenarioGraphNode";
import { Topic } from "./Topic";

export type ScenarioGraph = {
  id: string;
  topicId?: string;
  title: string;
  slug?: string;
  extraModelTopics: Topic['slug'][];
  predicate?: Predicate;
  nodes: ScenarioGraphNode[];
  links: ScenarioGraphLink[];
  valid?: boolean;
  available?: boolean;
  automatable: boolean;
  groupName?: ScenarioGroupName;
  hash?: string; // Переменная, используемая на фронте. Используется для оптимизации
                // Для того, чтобы сократить количество рендеров, в компоненте графа
                // useEffect следит за изменениями graph.id, graph.topicId и graph.hash.
                // При изменении одного из этих полей компонент подхватит эти изменения из
                // redux'a
  labels?: ScenarioGraphLabel[];
  previousScenarioId?: string;
}

export type ExportedScenarioGraph = ScenarioGraph & {
  projectId: Project['id'],
  groups: ScenarioGraphGroup[],
};

export function getEmptyScenarioGraph(title: string = '', topicId: string = ''): ScenarioGraph {
  return {
    id: '',
    topicId,
    title,
    // slug: '',
    extraModelTopics: [],
    predicate: getDefaultPredicate(),
    nodes: [],
    links: [],
    hash: generateId(),
    available: false,
    automatable: true,
  };
}
