const cacheService = {
  setItem: (key: string, value: unknown) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  getItem: <T>(key: string, defaultValue: T): T => {
    const item: string | null = localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item) as T;
      } catch (err) {
        return defaultValue;
      }
    }

    return defaultValue;
  },
};

export default cacheService;
