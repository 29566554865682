/* eslint-disable camelcase */
export type ClusterItem = {
  id: string;
  text: string;
  score: number;
}

export type Cluster = {
  id: string;
  total: number;
  clusterNumber: number;
  clusterItemsShort: ClusterItem[];
  clusterItemsFull: ClusterItem[];
}

export enum ClusteringType {
  history = 'history',
  selection = 'selection'
}
