import React from 'react';

import { SvgProps } from '../types';

function CurvedPathWithPlusIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1667 7.98332L18.5268 13.5603L16.783 12.0724L13.818 15.6655C12.4556 17.3165 10.1736 17.6584 8.73148 16.4279C7.28935 15.1974 7.22417 12.8528 8.58657 11.2018L10.5632 8.80637C11.3806 7.81587 11.3941 6.45384 10.5925 5.7699C9.79096 5.08595 8.47421 5.33603 7.65687 6.32652L5.09513 9.43096C5.56706 10.2551 5.5105 11.3284 4.86611 12.1093C4.04728 13.1016 2.60297 13.2397 1.6398 12.4179C0.676635 11.5961 0.559647 10.1257 1.37847 9.13345C2.02286 8.35255 3.05247 8.10954 3.93258 8.43903L6.49432 5.33459C7.85673 3.68356 10.1387 3.3417 11.5809 4.5722C13.023 5.8027 13.0882 8.14728 11.7258 9.79831L9.74912 12.1937C8.93177 13.1842 8.91824 14.5462 9.71981 15.2302C10.5214 15.9141 11.8381 15.6641 12.6555 14.6736L15.6205 11.0805L13.8766 9.59255L19.1667 7.98332Z"
        fill={color}
      />
      <path
        d="M16.75 14C16.3358 14 16 14.3358 16 14.75V16.5H14.25C13.8358 16.5 13.5 16.8358 13.5 17.25V17.75C13.5 18.1642 13.8358 18.5 14.25 18.5H16V20.25C16 20.6642 16.3358 21 16.75 21H17.25C17.6642 21 18 20.6642 18 20.25V18.5H19.75C20.1642 18.5 20.5 18.1642 20.5 17.75V17.25C20.5 16.8358 20.1642 16.5 19.75 16.5H18V14.75C18 14.3358 17.6642 14 17.25 14H16.75Z"
        fill={color}
      />
    </svg>
  );
}

export default CurvedPathWithPlusIcon;
