import React, { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import block from "bem-cn";

import { checkAvailability } from "../../../redux/butterfly/actions";
import { getSidebarOpenStatus } from '../../../redux/sidebar/selectors';
import { getButterflyStatus } from "../../../redux/butterfly/selectors";

import { useForceUpdate } from "../../../hooks/useForceUpdate";

import Icon from "../../../components-new/icon";

const b = block("sidebar-item");

function FeedbackButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();

  const navHelperPositionTop = useRef<number>(0);

  const opened = useSelector(getSidebarOpenStatus);
  const isAvailable = useSelector(getButterflyStatus);

  const adjustNavHelperPosition = useCallback(
    (event: React.MouseEvent) => {
      const item = event.currentTarget;
      const box = item.getBoundingClientRect();
      navHelperPositionTop.current = box.y - 6;
      forceUpdate();
    },
    [forceUpdate],
  );

  useEffect(
    () => {
      dispatch(checkAvailability.request());
    },
    [dispatch],
  );

  if (!isAvailable) return null;

  return (
    <a
      className={b()}
      target="_blank"
      rel="noreferrer"
      href="https://forms.yandex-team.ru/surveys/tss/"
      onMouseEnter={adjustNavHelperPosition}
    >
      <div className={b("icon-wrapper")}>
        <Icon className={b("icon")} type="bug" />
      </div>
      {opened ? t("SIDEBAR.FEEDBACK") : (
        <div
          style={{ top: navHelperPositionTop.current + "px" }}
          className={b("navigation-helper")}
        >
          {
            <a
              className={b("navigation-helper-item")}
              href="https://forms.yandex-team.ru/surveys/tss/"
            >
              {t("SIDEBAR.FEEDBACK")}
            </a>
          }
        </div>
      )}
    </a>
  );
}

export default memo(FeedbackButton);
