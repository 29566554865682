import React from 'react';

import './styles.scss';

type FilterItemProps = {
  label: string,
  value: string,
}

export default function FilterItem({
  label,
  value,
}: FilterItemProps) {
  return (
    <div className="sampling-filter-item">
      <label>{label}</label>
      <span>{value}</span>
    </div>
  );
}
