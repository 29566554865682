import React, { memo, useCallback, useState } from "react";
import { block } from "bem-cn";

import {
  NotificationModel,
} from "../../../types/models/Notification";
import Notification from "./notification";

import './list.scss';
import Button from "../../../components-new/button";

const NOTIFICATION_HEIGHT_PX = 56;
const GAP_PX = 8;

const FADE_OUT_TIME_MS = 200;

const b = block("notifications-list");

type NotificationListProps = {
  data: NotificationModel[];
  onClose: (id: NotificationModel['id']) => void;
  onCloseAll: (ids: Array<NotificationModel['id']>) => void;
}

function NotificationList({
  data,
  onClose,
  onCloseAll,
}: NotificationListProps) {
  const [focused, setFocused] = useState<boolean>(false);

  const getNotificationMarginTop = useCallback(
    (index: number) => {
      if (index > 0) {
        return -(NOTIFICATION_HEIGHT_PX + GAP_PX) + "px";
      }

      return 0;
    },
    [],
  );

  const handleNotificationExpire = useCallback(
    (id: NotificationModel['id']) => {
      if (focused) return;

      const notificationElem = document.getElementById(id);
      if (!notificationElem) return;

      notificationElem.style.animationName = "notificationFadeOut";

      setTimeout(
        () => {
          onClose(id);
        },
        FADE_OUT_TIME_MS,
      );
    },
    [focused, onClose],
  );

  const handleNotificationCloseClick = useCallback(
    (id: NotificationModel['id']) => {
      const notificationElem = document.getElementById(id);
      if (!notificationElem) return;

      notificationElem.style.animationName = "notificationFadeOut";

      setTimeout(
        () => {
          onClose(id);
        },
        FADE_OUT_TIME_MS,
      );
    },
    [onClose],
  );

  const handleCloseAllClick = useCallback(
    () => {
      onCloseAll(data.map(n => n.id));
    },
    [data, onCloseAll],
  );

  const focus = useCallback(
    () => {
      setFocused(true);
    },
    [],
  );

  const blur = useCallback(
    () => {
      setFocused(false);
    },
    [],
  );

  return (
    <div className={b()} onMouseEnter={focus} onMouseLeave={blur}>
      <div className={b("list")}>
        {data.map((n, i) => (
          <div key={n.id} style={{ marginTop: getNotificationMarginTop(i) }}>
            <Notification
              data={n}
              onClose={handleNotificationCloseClick}
              onExpire={handleNotificationExpire}
            />
          </div>
        ))}
      </div>
      <div className={b("close-button-wrapper")}>
        <Button
          className={b("close-button")}
          withShadow
          form="circle"
          icon="close"
          size="large"
          onClick={handleCloseAllClick}
        />
      </div>
    </div>
  );
}

export default memo(NotificationList);
