import React from 'react';

import { SvgProps } from '../types';

function ArrowDownIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53948 13.4073C7.43812 13.3059 7.3178 13.2255 7.18537 13.1707C7.05294 13.1158 6.911 13.0876 6.76766 13.0876C6.62432 13.0876 6.48239 13.1158 6.34996 13.1707C6.21753 13.2255 6.0972 13.3059 5.99584 13.4073C5.89449 13.5086 5.81409 13.6289 5.75923 13.7614C5.70438 13.8938 5.67615 14.0357 5.67615 14.1791C5.67615 14.4686 5.79115 14.7462 5.99584 14.9509L11.2311 20.1851C11.4357 20.3896 11.7131 20.5045 12.0024 20.5045C12.2917 20.5045 12.5691 20.3896 12.7737 20.1851L18.0078 14.9509C18.2125 14.7462 18.3275 14.4686 18.3275 14.1791C18.3275 13.8896 18.2125 13.612 18.0078 13.4073C17.8031 13.2026 17.5255 13.0876 17.236 13.0876C16.9465 13.0876 16.6689 13.2026 16.4642 13.4073L14.9238 14.9487C14.3443 15.5289 13.8319 16.1726 13.3966 16.8676L12.8838 17.6825L12.8337 17.6313L12.9831 16.5851C13.0559 16.0741 13.0924 15.5587 13.0922 15.0425L13.0922 4.36363C13.0922 4.0743 12.9773 3.79682 12.7727 3.59224C12.5681 3.38765 12.2906 3.27272 12.0013 3.27272C11.712 3.27272 11.4345 3.38765 11.2299 3.59224C11.0253 3.79682 10.9104 4.0743 10.9104 4.36363L10.9104 15.0436C10.9106 15.5598 10.9474 16.0752 11.0206 16.5862L11.17 17.6334L11.1188 17.6836L10.606 16.8654C10.1708 16.1716 9.65887 15.529 9.07984 14.9498L7.53948 13.4073Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowDownIcon;
