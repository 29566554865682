import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { Provider } from 'react-redux';

import { store } from '../../redux/store';
import { generateId } from '../helpers/generateId';
import ModalOverlay from '../../modals/components/modalOverlay';
import ConfirmDeleteItemModal, { ConfirmDeleteItemModalProps } from '../../modals/confirmDeleteItem';
import CallModal, { CallModalProps } from '../../modals/call';
import ConfirmModal, { ConfirmModalProps } from '../../modals/confirmModal';
import downloadCallFiles from '../../modals/downloadCallFiles';
import CreateSamplingModal, { CreateSamplingModalProps } from '../../modals/createSampling';
import CreateVersionModal, { CreateVersionModalProps } from '../../modals/createVersion';
import CreateProjectModal, { CreateProjectModalProps } from '../../modals/createProject';
import CreateAudioRecordModal, { CreateAudioRecordModalProps } from '../../modals/createAudioRecord';
import UpdateAudioRecordModal, { UpdateAudioRecordModalProps } from '../../modals/updateAudioRecord';
import AddSuperUserModal, { AddSuperUserModalProps } from '../../modals/addSuperUser';
import ManageModelSlotsModal, { ManageModelSlotsModalProps } from '../../modals/manageModelSlots';
import SelectAudioFileModal, { SelectAudioFileModalProps } from '../../modals/selectAudioFile';
import TextInputModal, { TextInputModalProps } from '../../modals/textInputModal';
import UnsavedChangesModal, { UnsavedChangesModalProps } from '../../modals/unsavedChanges';
import ExportCallsModal, { ExportCallsModalProps } from '../../modals/exportCalls';
import ScenarioSelectModal, { ScenarioSelectModalProps } from '../../modals/scenarioSelectModal';
import ExportCallStatisticsModal, { ExportCallStatisticsModalProps } from '../../modals/exportCallStatistics';
import CustomSelectModal, { CustomSelectModalProps } from '../../modals/customSelectModal';
import ProjectAuthDataModal, { ProjectAuthDataModalProps } from '../../modals/projectAuthData';
import UserCreatedModal, { UserCreatedModalProps } from '../../modals/userCreated';
import LaunchClusteringModal, { LaunchClusteringModalProps } from '../../modals/launchClustering';
import CompareModelsModal, { CompareModelsModalProps } from '../../modals/compareModels';
import CreateScenarioModal, { CreateScenarioModalProps } from '../../modals/createScenario';
import DeleteTagConfirmModal, { DeleteTagConfirmModalProps } from '../../modals/deleteTagConfirm';
import AddExampleModal, { AddExampleModalProps } from '../../modals/addExamples';
import CreateDomainModal, { CreateDomainModalProps } from '../../modals/createDomain';
import EditDomainModal, { EditDomainModalProps } from '../../modals/editDomain';
import CallsSettingsModal from '../../modals/callsSettings';
import UserEditor, { UserEditorProps } from '../../modals/newUserEditor';
import ExportScenariosModal, { ExportScenariosModalProps } from '../../modals/exportScenarios';
import CancelCallModal, { CancelCallModalProps } from '../../modals/cancelCall';
import ConfirmDeleteItemSideModal, { ConfirmDeleteItemSideModalProps } from '../../modals/confirmDeleteItemSide';
import CreateFeatureModal, { CreateFeatureModalProps } from '../../modals/createFeature';

import './styles.scss';

export type ModalProps = {
  id?: string,
  close: () => void,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type openModalProps = { component: JSX.Element } & any;

type ModalService = {
  open: (options: openModalProps) => string,
  close: (modalId: string) => void,
  openConfirmDeleteItem: (props: ConfirmDeleteItemModalProps) => string,
  openConfirmModal: (props: ConfirmModalProps) => string,
  openConfirmDeleteItemSide: (props: ConfirmDeleteItemSideModalProps) => string,
  // openUpdateRecords: () => string,
  openDownloadCallFiles: () => string,
  openCall: (props: CallModalProps) => string,
  openCallsSettings: () => string,
  openCreateSampling: (props: CreateSamplingModalProps) => string,
  openCreateVersionModal: (props: CreateVersionModalProps) => string,
  openCreateProject: (props: CreateProjectModalProps) => string,
  openCreateAudioRecord: (props: CreateAudioRecordModalProps) => string,
  openUpdateAudioRecord: (props: UpdateAudioRecordModalProps) => string,
  openAddSuperUser: (props: AddSuperUserModalProps) => string,
  openManageModelSlots: (props: ManageModelSlotsModalProps) => string,
  openSelectAudio: (props: SelectAudioFileModalProps) => string,
  openTextInputModal: (props: TextInputModalProps) => string;
  openUnsavedChangesModal: (props: UnsavedChangesModalProps) => string;
  openExportCalls: (props: ExportCallsModalProps) => string;
  openScenarioSelect: (props: ScenarioSelectModalProps) => string;
  openExportCallStatistics: (prop: ExportCallStatisticsModalProps) => string;
  openCustomSelect: (prop: CustomSelectModalProps) => string;
  openProjectAuthData: (prop: ProjectAuthDataModalProps) => string;
  openUserCreated: (props: UserCreatedModalProps) => string;
  openLaunchClustering: (props: LaunchClusteringModalProps) => string;
  openCompareModels: (props: CompareModelsModalProps) => string;
  openCreateScenario: (props: CreateScenarioModalProps) => string;
  openDeleteTagConfirm: (props: DeleteTagConfirmModalProps) => string;
  openAddExample: (props: AddExampleModalProps) => string;
  openCreateDomain: (props: CreateDomainModalProps) => string;
  openEditDomain: (props: EditDomainModalProps) => string;
  openNewUserEditor: (props: UserEditorProps) => string;
  openExportScenarios: (props: ExportScenariosModalProps) => string;
  openCancelCall: (props: CancelCallModalProps) => string;
  openCreateFeature: (props: CreateFeatureModalProps) => string;
}

const modalService: ModalService = {
  open(options: openModalProps) {
    const id = generateId();
    const modalsPool = document.getElementById('modal-container');

    const modalContainer = document.createElement('div');
    modalContainer.id = id;

    const { component: Component, ...props } = options;

    modalsPool?.appendChild(modalContainer);
    const closeHandler = () => {
      if (modalsPool?.contains(modalContainer)) {
        unmountComponentAtNode(modalContainer);
        modalsPool?.removeChild(modalContainer);
      }
    };

    ReactDOM.render(
      (
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
            {/* eslint-disable-next-line react/jsx-no-bind */}
            <ModalOverlay onCancel={closeHandler}>
              {/* @ts-ignore  */}
              <Component
                // eslint-disable-next-line react/jsx-no-bind
                close={closeHandler}
                id={id}
                {...props}
              />
            </ModalOverlay>
          </MuiPickersUtilsProvider>
        </Provider>
      ), modalContainer,
    );

    return id;
  },
  close(modalId: string) {
    const modalsPool = document.getElementById('modal-container');
    const modalContainer = document.getElementById(modalId);
    if (modalsPool && modalContainer && modalsPool.contains(modalContainer)) {
      modalsPool.removeChild(modalContainer);
    }
  },
  openConfirmDeleteItem(props: ConfirmDeleteItemModalProps) {
    return this.open({
      component: ConfirmDeleteItemModal,
      ...props,
    });
  },
  openConfirmModal(props: ConfirmModalProps) {
    return this.open({
      component: ConfirmModal,
      ...props,
    });
  },
  openDownloadCallFiles() {
    return this.open({
      component: downloadCallFiles,
    });
  },
  openCall(props: CallModalProps) {
    return this.open({
      component: CallModal,
      ...props,
    });
  },
  openCallsSettings() {
    return this.open({
      component: CallsSettingsModal,
    });
  },
  openCreateSampling(props: CreateSamplingModalProps) {
    return this.open({
      component: CreateSamplingModal,
      ...props,
    });
  },
  openCreateVersionModal(props: CreateVersionModalProps) {
    return this.open({
      component: CreateVersionModal,
      ...props,
    });
  },
  openCreateProject(props: CreateProjectModalProps) {
    return this.open({
      component: CreateProjectModal,
      ...props,
    });
  },
  openCreateAudioRecord(props: CreateAudioRecordModalProps) {
    return this.open({
      component: CreateAudioRecordModal,
      ...props,
    });
  },
  openUpdateAudioRecord(props: UpdateAudioRecordModalProps) {
    return this.open({
      component: UpdateAudioRecordModal,
      ...props,
    });
  },
  openAddSuperUser(props: AddSuperUserModalProps) {
    return this.open({
      component: AddSuperUserModal,
      ...props,
    });
  },
  openManageModelSlots(props: ManageModelSlotsModalProps) {
    return this.open({
      component: ManageModelSlotsModal,
      ...props,
    });
  },
  openSelectAudio(props: SelectAudioFileModalProps) {
    return this.open({
      component: SelectAudioFileModal,
      ...props,
    });
  },
  openTextInputModal(props: TextInputModalProps) {
    return this.open({
      component: TextInputModal,
      ...props,
    });
  },
  openUnsavedChangesModal(props: UnsavedChangesModalProps) {
    return this.open({
      component: UnsavedChangesModal,
      ...props,
    });
  },
  openExportCalls(props: ExportCallsModalProps) {
    return this.open({
      component: ExportCallsModal,
      ...props,
    });
  },
  openScenarioSelect(props: ScenarioSelectModalProps) {
    return this.open({
      component: ScenarioSelectModal,
      ...props,
    });
  },
  openExportCallStatistics(props: ExportCallStatisticsModalProps) {
    return this.open({
      component: ExportCallStatisticsModal,
      ...props,
    });
  },
  openCustomSelect(props: CustomSelectModalProps) {
    return this.open({
      component: CustomSelectModal,
      ...props,
    });
  },
  openProjectAuthData(props: ProjectAuthDataModalProps) {
    return this.open({
      component: ProjectAuthDataModal,
      ...props,
    });
  },
  openUserCreated(props: UserCreatedModalProps) {
    return this.open({
      component: UserCreatedModal,
      ...props,
    });
  },
  openLaunchClustering(props: LaunchClusteringModalProps) {
    return this.open({
      component: LaunchClusteringModal,
      ...props,
    });
  },
  openCompareModels(props: CompareModelsModalProps) {
    return this.open({
      component: CompareModelsModal,
      ...props,
    });
  },
  openCreateScenario(props: CreateScenarioModalProps) {
    return this.open({
      component: CreateScenarioModal,
      ...props,
    });
  },
  openDeleteTagConfirm(props: DeleteTagConfirmModalProps) {
    return this.open({
      component: DeleteTagConfirmModal,
      ...props,
    });
  },
  openAddExample(props: AddExampleModalProps) {
    return this.open({
      component: AddExampleModal,
      ...props,
    });
  },
  openCreateDomain(props: CreateDomainModalProps) {
    return this.open({
      component: CreateDomainModal,
      ...props,
    });
  },
  openEditDomain(props: EditDomainModalProps) {
    return this.open({
      component: EditDomainModal,
      ...props,
    });
  },
  openNewUserEditor(props: UserEditorProps) {
    return this.open({
      component: UserEditor,
      ...props,
    });
  },
  openExportScenarios(props: ExportScenariosModalProps) {
    return this.open({
      component: ExportScenariosModal,
      ...props,
    });
  },
  openCancelCall(props: CancelCallModalProps) {
    return this.open({
      component: CancelCallModal,
      ...props,
    });
  },
  openConfirmDeleteItemSide(props: ConfirmDeleteItemSideModalProps) {
    return this.open({
      component: ConfirmDeleteItemSideModal,
      ...props,
    });
  },
  openCreateFeature(props: CreateFeatureModalProps) {
    return this.open({
      component: CreateFeatureModal,
      ...props,
    });
  },
};

export default modalService;
