import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../input';

import {
  parseDateToTimeValue,
  parseTimeValueToDate,
} from "./helpers";

type TimeInputProps = {
  dateFrom: Date | null | undefined;
  dateTo: Date | null | undefined;
  value: Date | null | undefined;
  selectsRange?: boolean;
  onChange: (value: [Date, Date] | Date) => void;
}

function TimeInput({
  dateFrom = null,
  dateTo = null,
  value = null,
  selectsRange = false,
  onChange,
}: TimeInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [timeString, setTimeString] = useState<string>(getInitialTimeStringValue());

  const resetInputValue = useCallback(
    () => {
      setTimeString(getInitialTimeStringValue());
    },
    [getInitialTimeStringValue],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getInitialTimeStringValue() {
    if (!dateFrom && !value) return "";

    if (selectsRange) {
      const timeFrom = parseDateToTimeValue(dateFrom);
      const timeTo = parseDateToTimeValue(dateTo);

      return `${timeFrom} - ${timeTo}`;
    }

    return parseDateToTimeValue(value);
  }

  const handleTimeStringChange = useCallback(
    (_, { value: newValue }) => {
      setTimeString(newValue);
    },
    [],
  );

  const emitValue = useCallback(
    () => {
      if (selectsRange) {
        const [timeFrom, timeTo] = timeString.split("-");

        if (!dateFrom || !dateTo) {
          resetInputValue();
          return;
        }

        const newDateFrom = parseTimeValueToDate(dateFrom, timeFrom) as Date;
        const newDateTo = parseTimeValueToDate(dateTo, timeTo) as Date;

        onChange([newDateFrom, newDateTo]);
        return;
      }

      if (!value) {
        resetInputValue();
        return;
      }

      const newValue = parseTimeValueToDate(value, timeString);

      if (!newValue) {
        resetInputValue();
        return;
      }

      onChange(newValue);
    },
    [
      selectsRange,
      value,
      timeString,
      dateFrom,
      dateTo,
      onChange,
      resetInputValue,
    ],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.code === '13') {
        emitValue();
      }
    },
    [emitValue],
  );

  useEffect(
    () => {
      resetInputValue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dateFrom,
      dateTo,
      value,
    ],
  );

  const handleBlur = useCallback(
    e => {
      e.preventDefault();
      emitValue();
    }, [emitValue],
  );

  useEffect(() => {
    // этот странный маневр позволяет не сбрасываться времени в инпуте
    // это нужно чтобы событие emitValue вызывать после того, как элемент отрендерится
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      ref={inputRef}
      name="time"
      floatingPlaceholder
      placeholder={t("COMMON.TIME")}
      value={timeString}
      icon="timeOutline"
      iconPosition="right"
      onChange={handleTimeStringChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
}

export default memo(TimeInput);
