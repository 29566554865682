/* eslint-disable camelcase */
/* eslint-disable func-names */
import i18next from "i18next";
import { SagaIterator } from "redux-saga";

import {
  all,
  call,
  fork,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import api, { ApiResponse } from "../../services/api";
import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import modalService from "../../services/modal";

import {
  ProjectUser,
  User, UserAccesses,
} from "../../types/models/UserAccesses";

import {
  AddUserToProjectRequest,
  CreateUserByProviderResponse,
  GetAllUsersRequest,
  GetAllUsersResponse,
  GetProjectUsersListRequest,
  GetProjectUsersListResponse,
  GetUserByIdResponse,
  RemoveUserFromProjectRequest,
  UpdateUserGroupRequest,
  UpdateUserGroupResponse,
} from '../../types/requests/UserAccesses';

import { getSelectedProjectSlug } from "../projects/selectors";
import { ErrorAction } from "../reducers";
import { loadAllAuthProviders } from "../authProviders/actions";

import * as actions from './actions';

function* loadUserAccesses(): SagaIterator {
  yield takeLatest(actions.loadProjectUsersList.request, function* () {
    try {
      const projectSlug: string = yield select(getSelectedProjectSlug);
      if (!projectSlug) return;

      const params: GetProjectUsersListRequest = { projectSlug };
      const response: ApiResponse<GetProjectUsersListResponse> = yield call(
        api.getProjectUsersList,
        params,
      );

      const parsed: ProjectUser[] = response.data.users.map(user => objSnakeToCamelDeep(user));
      yield put(actions.loadProjectUsersList.success(parsed));
    } catch (err) {
      yield put(actions.loadProjectUsersList.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.USER_ACCESSES.LIST")),
      ));
    }
  });
}

function* addUserToProject(): SagaIterator {
  yield takeLatest(actions.addUserToProject.request, function* ({ payload }) {
    try {
      const projectSlug: string = yield select(getSelectedProjectSlug);
      if (!projectSlug) return;

      const params: AddUserToProjectRequest = {
        params: { projectSlug },
        body: objCamelToSnakeDeep(payload),
      };

      yield call(api.addUserToProject, params);
      yield put(actions.loadProjectUsersList.request());
    } catch (err) {
      yield put(actions.addUserToProject.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.CREATE", { name: i18next.t("ERRORS.API.USER_ACCESSES.USER") })),
      ));
    }
  });
}

function* loadAllUsers(): SagaIterator {
  yield takeLatest(actions.loadAllUsers.request, function* ({ payload = {} }) {
    try {
      const params: GetAllUsersRequest = { params: { skipProvider: true, ...payload } };
      const { data }: ApiResponse<GetAllUsersResponse> = yield call(api.getAllUsers, params);
      const parsed: User[] = data.users.map(user => objSnakeToCamelDeep(user));

      yield put(actions.loadAllUsers.success(parsed));
    } catch (err) {
      yield put(actions.loadAllUsers.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.LOAD", { name: i18next.t("ERRORS.API.USER_ACCESSES.ALL_USERS") })),
      ));
    }
  });
}

// Пока по факту не используется, оставил на будущее
function* getUserById(): SagaIterator {
  yield takeLatest(actions.getUserById.request, function* ({ payload: userId }) {
    try {
      const response: ApiResponse<GetUserByIdResponse> = yield call(api.getUserById, { userId });
      const parsed: UserAccesses = objSnakeToCamelDeep(response.data);

      yield put(actions.getUserById.success(parsed));
    } catch (err) {
      yield put(actions.loadAllUsers.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.LOAD", { name: i18next.t("ERRORS.API.USER_ACCESSES.USER") })),
      ));
    }
  });
}

function* updateUserGroup(): SagaIterator {
  yield takeLatest(actions.updateUserGroup.request, function* ({ payload }) {
    try {
      const { group, userId } = payload;
      const projectSlug: string = yield select(getSelectedProjectSlug);
      if (!projectSlug) return;

      const params: UpdateUserGroupRequest = {
        params: { userId: Number(userId), projectSlug },
        body: { group },
      };

      type R = ApiResponse<UpdateUserGroupResponse>;
      const { data }: R = yield call(api.updateUserGroup, params);
      const parsed: ProjectUser = objSnakeToCamelDeep(data);

      yield put(actions.updateUserGroup.success(parsed));
      yield put(actions.loadProjectUsersList.request());
    } catch (err) {
      yield put(actions.updateUserGroup.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.UPDATE", { name: i18next.t("ERRORS.API.USER_ACCESSES.USER") })),
      ));
    }
  });
}

function* removeUserFromProject(): SagaIterator {
  yield takeLatest(actions.removeUserFromProject.request, function* ({ payload: userId }) {
    try {
      const projectSlug: string = yield select(getSelectedProjectSlug);
      if (!projectSlug) return;

      const params: RemoveUserFromProjectRequest = { userId: Number(userId), projectSlug };
      yield call(api.removeUserFromProject, params);

      yield put(actions.loadProjectUsersList.request());
    } catch (err) {
      yield put(actions.updateUserGroup.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.DELETE", { name: i18next.t("ERRORS.API.USER_ACCESSES.USER") })),
      ));
    }
  });
}

function* createUserByProvider(): SagaIterator {
  yield takeLatest(actions.createUserByProvider.request, function* ({ payload }) {
    try {
      type T = ApiResponse<CreateUserByProviderResponse>;
      const { data }: T = yield call(api.createUserByProvider, payload);
      const { password, ...rest } = data;

      if (!rest.id) return;

      if (password) {
        modalService.openUserCreated({
          username: payload.username,
          password,
        });
      }

      yield put(actions.loadAllUsers.request({}));

      const parsed = objSnakeToCamelDeep<User>(rest);
      yield put(actions.setEditableUser(parsed));
    } catch (err) {
      yield put(actions.createUserByProvider.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.CREATE", { name: i18next.t("ERRORS.API.USER_ACCESSES.USER") })),
      ));
    }
  });
}

function* init(): SagaIterator {
  yield takeLatest(actions.init.request, function* () {
    try {
      yield all([
        put(actions.loadProjectUsersList.request()),
        put(actions.loadAllUsers.request({})),
        put(loadAllAuthProviders.request()),
      ]);
    } catch (err) {
      yield put(actions.init.failure(
        new ErrorAction(err, i18next.t("ERRORS.API.LOAD", { name: i18next.t("ERRORS.API.USER_ACCESSES.INIT") })),
      ));
    }
  });
}

export default function* topicsSagas(): SagaIterator {
  yield fork(loadUserAccesses);
  yield fork(addUserToProject);
  yield fork(loadAllUsers);
  yield fork(getUserById);
  yield fork(updateUserGroup);
  yield fork(removeUserFromProject);
  yield fork(createUserByProvider);
  yield fork(init);
}
