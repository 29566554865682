import { createAction } from 'typesafe-actions';
import { ResponseRequestedFeatures, ResponseGraphPositionsStack, RequestedFeature } from '../../types/backendModels/DialogBackend';
import { RequestMessage, ResponseButtonsBlock, Attachment } from '../../types/models/Dialog';
import { UserMessageAndNodeMatch } from './reducers';

export const openGraphTestingChat = createAction('graphChat/openGraphTestingChat')();
export const closeGraphTestingChat = createAction('graphChat/closeGraphTestingChat')();

export const postMessage = createAction('graphChat/postMessage')<{
  text: string,
  attachments?: Attachment[],
  skipReset?: boolean,
}>();

export const setRequestedFeatures = createAction('graphChat/setRequestedFeatures')<ResponseRequestedFeatures['features'] | null>();
export const setButtonsBlock = createAction('graphChat/setButtonsBlock')<ResponseButtonsBlock | undefined>();
export const setGraphPositionsStack = createAction('graphChat/setGraphPositionsStack')<ResponseGraphPositionsStack['positions_stack'] | null>();

export const updateActiveRequestedFeature = createAction('graphChat/updateActiveRequestedFeature')();
export const setActiveRequestedFeature = createAction('graphChat/setActiveRequestedFeature')<RequestedFeature | null>();
export const removeFirstRequestedFeature = createAction('graphChat/removeFirstRequestedFeature')();
export const setCurrentRequestMessage = createAction('graphChat/setCurrentRequestMessage')<RequestMessage | null>();

export const disableChatOnGraphEdit = createAction('graphChat/disableChatOnGraphEdit')<string>();
export const updateChatOnGraphEdit = createAction('graphChat/updateChatOnGraphEdit')();
export const unlockChat = createAction('graphChat/unlock')<undefined>();

export const deleteMessage = createAction('graphChat/deleteMessage')<string>();

export const updateMessageAndNodeMatches = createAction('graphChat/updateMessageAndNodeMatches')<{ userMessageId: string, nodeIds: string[] }>();
export const setMessageAndNodeMatches = createAction('graphChat/setMessageAndNodeMatches')<UserMessageAndNodeMatch[] | null>();
