import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { components, OptionProps } from 'react-select';

import Button from '../../button';
import Icon from '../../icon';

import OptionLabel from './optionLabel';

import { b } from '../index';

// Этот компонент нужен для возможности переопределить стандартный Option компонент
// В компоненте добавлена обработка значения OPTION_CREATE_VALUE, которое нужно для
// создания несуществующей опции (например, при создании тегов, линий).
// Для обработки такого значения у опции в value нужно передать значение OPTION_CREATE_VALUE,
// в label имя новой сущности, а в onClick добавить функцию, которую нужно запустить при клике.

export const OPTION_CREATE_VALUE = 'CREATE';

type OptionObj = {
    label: string;
    value: string;
};

interface Props extends OptionProps<OptionObj> {
  value?: string;
  onDelete?: (value: string) => void;
}

function CustomOption({ onDelete, className, ...props }: Props) {
  const { t } = useTranslation();
  const handleDeleteClick = useCallback(
    (event: React.MouseEvent) => {
      if (!onDelete) return;

      event.preventDefault();
      event.stopPropagation();
      onDelete(props.value as string);
    },
    [onDelete, props.value],
  );

  if (props.value === 'CREATE') {
    return (
      <components.Option {...props}>
        {t('COMMON.CREATE')}
        &nbsp;
        <OptionLabel>{props.label}</OptionLabel>
      </components.Option>
    );
  }
  const Component = props.data.disabled ? Button : components.Option;
  const properties = props.data.disabled ? {
    disabled: props.data.disabled,
    className: `nd-custom-select__option ${className || ""} ${props.data.className || ""}`,
    simple: true,
  } : {
    className: `${className || ""} ${props.data.className || ""}`,
  };

  return (
    <Component
      {...props}
      {...properties}
    >
      {props.data.icon && (<div className={b("icon-wrapper")}><Icon type={props.data.icon} /></div>)}
      <div>
        <OptionLabel>{props.label}</OptionLabel>
        {props.data.subtitle && (
          <span className="option-subtitle">{props.data.subtitle}</span>
        )}
      </div>
      {(props.isSelected || props.data.checked) && (
        <Icon className={b("selected-icon")} type="checkmark" />
      )}
      {!!onDelete && (
      <Button
        icon="trash"
        onClick={handleDeleteClick}
      />
      )}
    </Component>
  );
}

export default memo(CustomOption);
