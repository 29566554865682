import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ScenarioGraphLinkBackend } from "../backendModels/ScenarioGraphLinkBackend";
import { ScenarioGraphLink } from "../models/ScenarioGraphLink";
import { parseScenarioGraphLinkMeta, parseScenarioGraphLinkMetaToBackend } from "./ScenarioGraphLinkMetaParser";

export function parseScenarioGraphLink(value: ScenarioGraphLinkBackend): ScenarioGraphLink {
  return {
    ...objSnakeToCamel<ScenarioGraphLink>(value),
    ...(value.meta && { meta: parseScenarioGraphLinkMeta(value.meta) }),
  };
}

export function parseScenarioGraphLinkToBackend(
  value: ScenarioGraphLink,
): ScenarioGraphLinkBackend {
  return {
    ...objCamelToSnake<ScenarioGraphLinkBackend>(value),
    ...(value.meta && { meta: parseScenarioGraphLinkMetaToBackend(value.meta) }),
  };
}
