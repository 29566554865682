import React from 'react';

import { SvgProps } from '../types';

function FlasherIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0004 5.5V0.5H11.0004V5.5H13.0004Z"
        fill={color}
      />
      <path
        d="M19.7665 6.97301L22.9804 3.14279L21.4483 1.85721L18.2344 5.68743L19.7665 6.97301Z"
        fill={color}
      />
      <path
        d="M1.23438 3.14279L4.44831 6.97301L5.9804 5.68743L2.76646 1.85721L1.23438 3.14279Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.21901 11.0757C5.43591 9.04237 7.15154 7.5 9.19645 7.5H14.8044C16.8493 7.5 18.5649 9.04236 18.7818 11.0757L20.0004 22.5H4.00043L5.21901 11.0757ZM11.2252 11.2378C11.3567 10.2432 12.2047 9.5 13.2079 9.5H14.7929C15.7962 9.5 16.6442 10.2432 16.7757 11.2378L18.0004 20.5H10.0004L11.2252 11.2378Z"
        fill={color}
      />
    </svg>
  );
}

export default FlasherIcon;
