import React, { ReactElement, useMemo } from 'react';

import Select, { SelectConfig } from '../../../../components-new/select/configurable';

import { FeatureType } from '../../../../types/models/Feature';

type FeatureTypeSelectorProps = {
  value: FeatureType,
  disabled?: boolean,
  floatingPlaceholder?: boolean;
  onChange: (value: FeatureType) => void,
}

export default function FeatureTypeSelector({
  value,
  disabled = false,
  floatingPlaceholder = false,
  onChange,
}: FeatureTypeSelectorProps): ReactElement {
  const config: SelectConfig = useMemo(
    () => {
      const options = [];
      for (let type in FeatureType) {
        options.push({ value: type, label: type });
      }

      return {
        options,
        labelFunction: option => option.label,
        valueFunction: option => option.value,
      };
    },
    [],
  );

  return (
    <Select
      floatingPlaceholder={floatingPlaceholder}
      config={config}
      value={value}
      isDisabled={disabled}
      onChange={onChange}
    />
  );
}
