import { createAction, createAsyncAction } from "typesafe-actions";

import { ProjectVoice } from "../../types/models/ProjectVoice";
import { ErrorAction } from "../reducers";

export const loadProjectVoiceConfig = createAsyncAction(
  'projectVoiceConfig/loadProjectVoiceConfig/requested',
  'projectVoiceConfig/loadProjectVoiceConfig/succeeded',
  'projectVoiceConfig/loadProjectVoiceConfig/failed',
)<undefined, ProjectVoice, ErrorAction>();

export const updateProjectVoiceConfig = createAsyncAction(
  'projectVoiceConfig/updateProjectVoiceConfig/requested',
  'projectVoiceConfig/updateProjectVoiceConfig/succeeded',
  'projectVoiceConfig/updateProjectVoiceConfig/failed',
)<ProjectVoice, ProjectVoice, ErrorAction>();

export const openProjectVoiceModal = createAction('projectVoiceConfig/openProjectVoiceModal')();
export const closeProjectVoiceModal = createAction('projectVoiceConfig/closeProjectVoiceModal')();
