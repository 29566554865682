import React, { FC, memo, useCallback, useEffect } from 'react';
import { block } from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeReleaseNotesModal, loadReleaseNotes, setNoteToEdit } from '../../../redux/releaseNotes/actions';
import { showReleaseNotesModal, getReleaseNotes } from '../../../redux/releaseNotes/selectors';

import { getEmptyReleaseNote, SavedReleaseNote } from '../../../types/models/ReleaseNotes';

import SideModal, { SideModalTitle } from '../../../components-new/sideModal';
import ContentIsland from '../../../components-new/sideModal/contentIsland';
import Button from '../../../components-new/button';

import Item from './item';
import Editor from './editor';
import accessService from '../../../services/access';

const b = block('release-notes-list');

const List: FC<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editable = accessService.checkIfCurrentUserSuperadmin();

  const showReleaseNotes = useSelector(showReleaseNotesModal, shallowEqual);
  const releaseNotes = useSelector(getReleaseNotes, shallowEqual);
  const notes = editable ? releaseNotes : releaseNotes.filter(note => !!note.enabled);

  const handleClose = useCallback(
    () => {
      dispatch(closeReleaseNotesModal());
    },
    [dispatch],
  );

  const handleEdit = useCallback(
    (idToEdit: SavedReleaseNote['id']) => {
      const note = releaseNotes.find(({ id }) => id === idToEdit);
      if (!note) return;

      dispatch(setNoteToEdit(note));
    },
    [dispatch, releaseNotes],
  );

  const handleCreate = useCallback(
    () => {
      const note = getEmptyReleaseNote();
      dispatch(setNoteToEdit(note));
    },
    [dispatch],
  );

  useEffect(
    () => {
      if (!showReleaseNotes) return;

      dispatch(loadReleaseNotes.request());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showReleaseNotes],
  );

  return (
    <SideModal
      className={b()}
      title={(
        <SideModalTitle className={b('header')}>
          <span>{t('RELEASE_NOTES.MODAL_TITLE')}</span>
          {editable && (
            <Button
              primary
              icon="plus"
              onClick={handleCreate}
            />
          )}
        </SideModalTitle>
      )}
      open={showReleaseNotes}
      defaultShadow
      islands
      roundedHeader
      onClose={handleClose}
    >
      <Editor />
      {notes.map(item => (
        <ContentIsland notSubtitle key={item.id}>
          <Item
            releaseNote={item}
            {...editable ? { onEdit: handleEdit } : {}}
          />
        </ContentIsland>
      ))}
    </SideModal>
  );
};

export default memo(List);
