import React from 'react';

import { SvgProps } from '../types';

function RefreshAltIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54172 2.5498L3.54172 2.54981L3.54172 9.20666L10.1986 9.20666V7.20666L6.95988 7.20666C7.00558 7.15832 7.0521 7.11046 7.09945 7.06312C9.83312 4.32945 14.2653 4.32945 16.9989 7.06312C19.7326 9.79679 19.7326 14.2289 16.9989 16.9626C14.2653 19.6963 9.83312 19.6963 7.09945 16.9626C5.73261 15.5958 5.0492 13.8043 5.0492 12.0129H3.04919C3.04919 14.3162 3.92788 16.6195 5.68524 18.3768C9.19995 21.8916 14.8984 21.8916 18.4132 18.3768C21.9279 14.8621 21.9279 9.16363 18.4132 5.64891C14.8984 2.13419 9.19995 2.13419 5.68523 5.64891C5.63673 5.69742 5.58889 5.74634 5.54172 5.79567V2.5498Z"
        fill={color}
      />
    </svg>
  );
}

export default RefreshAltIcon;
