import React, { FC } from 'react';
import { SvgProps } from '../types';

const QuestionMark: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12ZM20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12ZM10.75 16.243C10.75 15.52 11.3 15 11.993 15C12.701 15 13.25 15.52 13.25 16.243C13.25 16.965 12.7 17.5 11.993 17.5C11.828 17.5021 11.6643 17.4709 11.5116 17.4082C11.359 17.3456 11.2205 17.2529 11.1045 17.1355C10.9885 17.0182 10.8973 16.8787 10.8364 16.7254C10.7755 16.572 10.7461 16.408 10.75 16.243ZM12.225 6.5C10.068 6.5 8.45 7.743 8.45 9.75V9.775C8.45 9.90761 8.50268 10.0348 8.59645 10.1286C8.69022 10.2223 8.81739 10.275 8.95 10.275H9.753C9.993 10.275 10.188 10.08 10.188 9.84C10.188 8.717 11.056 8.118 12.225 8.118C13.363 8.118 14.112 8.717 14.112 9.571C14.112 10.38 13.692 10.754 12.644 11.234L12.344 11.368C11.461 11.758 11.131 12.342 11.131 13.361V13.5C11.131 13.6326 11.1837 13.7598 11.2774 13.8536C11.3712 13.9473 11.4984 14 11.631 14H12.369C12.5016 14 12.6288 13.9473 12.7226 13.8536C12.8163 13.7598 12.869 13.6326 12.869 13.5V13.466C12.869 13.016 12.989 12.836 13.349 12.672L13.648 12.537C14.906 11.967 15.85 11.219 15.85 9.586V9.496C15.85 7.773 14.352 6.5 12.225 6.5Z"
      fill={color}
    />
  </svg>
);

export default QuestionMark;
