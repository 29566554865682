import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { block } from "bem-cn";

import { NotificationModel } from "../../../types/models/Notification";

import { notificationEventId } from "..";

import NotificationList from './list';

import './container.scss';

const b = block("sai-notifications-container");

function NotificationsContainer() {
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);

  const closeNotification = useCallback(
    (id: NotificationModel['id']) => {
      setNotifications(list => list.filter(n => n.id !== id));
    },
    [],
  );

  const closeAllNotifications = useCallback(
    (listToRemove: Array<NotificationModel['id']>) => {
      setNotifications(list => list.filter(n => !listToRemove.includes(n.id)));
    },
    [],
  );

  useEffect(
    () => {
      const onNotificationHandler = (event: CustomEvent<NotificationModel>) => {
        const notification = event.detail;
        setNotifications(n => [...n, notification]);
      };

      //@ts-ignore
      window.addEventListener(notificationEventId, onNotificationHandler);

      // @ts-ignore
      return () => window.removeEventListener(notificationEventId, onNotificationHandler);
    },
    [],
  );

  const sideModalExist = document.getElementsByClassName('nd-side-modal').length > 0;

  const position = useMemo(
    () => {
      if (sideModalExist) {
        return "left";
      }

      return "right";
    },
    [sideModalExist],
  );

  return (
    <div className={b({ position })}>
      {notifications.length > 0 && (
        <NotificationList
          data={notifications}
          onClose={closeNotification}
          onCloseAll={closeAllNotifications}
        />
      )}
    </div>
  );
}

export default memo(NotificationsContainer);
