import { OutgoingCallResultBackend } from "../backendModels/OutgoingCallResultBackend";
import { OutgoingCallResult } from "../models/OutgoingCallResult";
import { OutgoingCallStatus } from "../models/OutgoingCallStatus";
import { parseOutgoingCallRecord, parseOutgoingCallRecordToBackend } from "./OutgoingCallRecordParser";

export function parseOutgoingCallResult(value: OutgoingCallResultBackend): OutgoingCallResult {
  return {
    callId: value.call_id,
    phone: value.phone,
    status: value.status,
    totalDuration: value.total_duration,
    talkDuration: value.talk_duration,
    records: (value.records || []).map(record => parseOutgoingCallRecord(record)),
    processingStatus: value.processing_status as OutgoingCallStatus,
    ...(value.timestamp && { timestamp: value.timestamp }),
  };
}

export function parseOutgoingCallResultToBackend(value: OutgoingCallResult): OutgoingCallResultBackend {
  return {
    phone: value.phone,
    call_id: value.callId,
    status: value.status,
    total_duration: value.totalDuration,
    talk_duration: value.talkDuration,
    records: (value.records || []).map(record => parseOutgoingCallRecordToBackend(record)),
    processing_status: value.processingStatus,
    ...(value.timestamp && { timestamp: value.timestamp }),
  };
}
