import React, { ReactElement, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from "react-redux";

import { AuthProviderMeta } from "../types/models/AuthProvider";
import navigationService from '../services/navigation';
import { toggleBugTracker } from '../services/helpers/utilities';
import { AppState } from "../redux/reducers";
import * as authProviderActions from "../redux/authProviders/actions";

import unauthorizedRoutes from '../routes/unauthorized';

import LoadingPage from '../pages/loading';
import WindowPlug from '../components-new/windowPlug';

import './styles.scss';

const isBox = process.env.REACT_APP_ENVIRONMENT_ID === 'box';
const BaseLoginPage = React.lazy(() => import('../pages/login'));
const OAuthAuthorizePage = React.lazy(() => import('../pages/oAuth'));
const OAuthSignupPage = React.lazy(() => import('../pages/oAuthSignup'));
const PasswordLoginPage = React.lazy(() => import('../pages/passwordLogin'));
const SignupPage = React.lazy(() => import('../pages/passwordSignup'));

const UnauthorizedLayout = ({
  providers,
  loadAuthProviders,
}: ReduxProps): ReactElement => {
  useEffect(() => {
    if (providers.list.length === 0 && !providers.loading) {
      loadAuthProviders();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toggleBugTracker(false);

    return () => {
      toggleBugTracker(true);
    };
  }, []);

  return (
    <div className="layout">
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {!isBox && <Route exact path={unauthorizedRoutes.root} component={BaseLoginPage} />}
          <Route exact path={unauthorizedRoutes.oAuth} component={OAuthAuthorizePage} />
          <Route exact path={unauthorizedRoutes.oAuthSignup} component={OAuthSignupPage} />
          <Route exact path={unauthorizedRoutes.loginPassword} component={PasswordLoginPage} />
          <Route exact path={unauthorizedRoutes.signupPassword} component={SignupPage} />
          <Route exact path={unauthorizedRoutes.loginProvider} component={BaseLoginPage} />
          <Route path="*"><Redirect to={navigationService.getLoginFormUrl()} /></Route>
        </Switch>
        <WindowPlug />
      </Suspense>
    </div>
  );
};

type MapStateToProps = {
    providers: {
        list: AuthProviderMeta[],
        loading: boolean,
    },
}

const mapStateToProps = (state: AppState): MapStateToProps => ({
  providers: state.authProviders,
});

type MapDispatchToProps = {
    loadAuthProviders: typeof authProviderActions.loadAuthProviders.request,
}

const mapDispatchToProps: MapDispatchToProps = {
  loadAuthProviders: authProviderActions.loadAuthProviders.request,
};

type ReduxProps = MapStateToProps & MapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedLayout);
