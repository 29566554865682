import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { block } from "bem-cn";

import { ModalProps } from '../../services/modal';

import { ClusteringType } from '../../types/models/Clustering';
import { NewSampling } from '../../types/models/Sampling';

import { getNewSamplingsState } from '../../redux/samplings/selectors';

import Modal from '../components/ndModal';

import Select, { SelectConfig } from '../../components-new/select/configurable';

import './styles.scss';

const b = block("modal-launch-clustering");

export type LaunchClusteringModalProps = {
  onSubmit: (params: {
    type: ClusteringType,
    selection?: NewSampling['id'],
  }) => void;
} & ModalProps;

function LaunchClusteringModal({
  onSubmit,
  close,
}: LaunchClusteringModalProps) {
  const { t } = useTranslation();

  const samplings = useSelector(getNewSamplingsState);

  const [clusteringType, setClusteringType] = useState<ClusteringType>(ClusteringType.history);
  const [samplingId, setSamplingId] = useState<NewSampling['id'] | null>(null);

  const handleSubmit = useCallback(
    () => {
      if (clusteringType === ClusteringType.history) {
        onSubmit({
          type: ClusteringType.history,
        });
      } else {
        if (!samplingId) return;

        onSubmit({
          type: ClusteringType.selection,
          selection: samplingId,
        });
      }
    },
    [clusteringType, samplingId, onSubmit],
  );

  const clusteringTypeConfig: SelectConfig = useMemo(
    () => ({
      options: [
        ClusteringType.history,
        ClusteringType.selection,
      ],
      labelFunction: (type: ClusteringType) => t(`MODALS.LAUNCH_CLUSTERING.BY_${type.toUpperCase()}`),
      valueFunction: (type: ClusteringType) => type,
    }),
    [t],
  );

  const samplingsConfig: SelectConfig = useMemo(
    () => ({
      options: samplings.value,
      valueFunction: (sampling: NewSampling) => sampling.id,
      labelFunction: (sampling: NewSampling) => sampling.title,
    }),
    [samplings],
  );

  useEffect(
    () => {
      if (!samplings || samplings.value.length === 0) return;

      if (clusteringType === ClusteringType.selection) {
        setSamplingId(samplings.value[0].id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusteringType],
  );

  return (
    <Modal
      className={b() as string}
      title={t("MODALS.LAUNCH_CLUSTERING.TITLE")}
      close={close}
      confirmationText={t("MODALS.LAUNCH_CLUSTERING.LAUNCH")}
      onConfirm={handleSubmit}
    >
      <div className="column-2">
        <Select
          config={clusteringTypeConfig}
          value={clusteringType}
          onChange={setClusteringType}
          placeholder={t("MODALS.LAUNCH_CLUSTERING.CLUSTERING_TYPE")}
          usePortal
        />
        {clusteringType === ClusteringType.selection && (
          <Select
            config={samplingsConfig}
            value={samplingId || ""}
            onChange={setSamplingId}
            placeholder={t("ENTITIES.SAMPLING")}
            usePortal
          />
        )}
      </div>
    </Modal>
  );
}

export default memo(LaunchClusteringModal);
