import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import {
  closeCreateModal,
  closeDeleteModal,
  closeEditModal,
  loadGraphGroups,
  openCreateModal,
  openDeleteModal,
  openEditModal,
  saveGraphGroups,
} from "./actions";

import { ScenarioGraphGroup } from "../../types/models/ScenarioGraphGroup";

type GraphGroupsState = {
  loading: boolean;
  list: ScenarioGraphGroup[];
  createModal: {
    opened: boolean;
  },
  editModal: {
    opened: boolean;
  },
  deleteModal: {
    opened: boolean;
  }
}

const initialState: GraphGroupsState = {
  loading: false,
  list: [],
  createModal: {
    opened: false,
  },
  editModal: {
    opened: false,
  },
  deleteModal: {
    opened: false,
  },
};

export const graphGroupsReducer: Reducer<GraphGroupsState> = createReducer(initialState)
  .handleAction(
    loadGraphGroups.request,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      list: [],
      loading: true,
    }),
  )
  .handleAction(
    loadGraphGroups.success,
    (
      state: GraphGroupsState,
      { payload }: ActionType<typeof loadGraphGroups.success>,
    ): GraphGroupsState => ({
      ...state,
      loading: false,
      list: payload,
    }),
  )
  .handleAction(
    loadGraphGroups.failure,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    openCreateModal,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      createModal: {
        ...state.createModal,
        opened: true,
      },
    }),
  )
  .handleAction(
    closeCreateModal,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      createModal: {
        ...state.createModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    openDeleteModal,
    (
      state: GraphGroupsState,
    ): GraphGroupsState => ({
      ...state,
      deleteModal: {
        ...state.deleteModal,
        opened: true,
      },
    }),
  )
  .handleAction(
    closeDeleteModal,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      deleteModal: {
        ...state.deleteModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    saveGraphGroups.request,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    saveGraphGroups.success,
    (
      state: GraphGroupsState,
      { payload }: ActionType<typeof saveGraphGroups.success>,
    ): GraphGroupsState => {
      return {
        ...state,
        list: payload,
        loading: false,
      };
    },
  )
  .handleAction(
    saveGraphGroups.failure,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    openEditModal,
    (
      state: GraphGroupsState,
    ): GraphGroupsState => ({
      ...state,
      editModal: {
        ...state.editModal,
        opened: true,
      },
    }),
  )
  .handleAction(
    closeEditModal,
    (state: GraphGroupsState): GraphGroupsState => ({
      ...state,
      editModal: {
        ...state.editModal,
        opened: false,
      },
    }),
  );
