import { SvgProps } from '../types';

function CrossIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.05018 8.46447L10.5857 12L7.05018 15.5355L8.4644 16.9497L11.9999 13.4142L15.5355 16.9497L16.9497 15.5355L13.4141 12L16.9497 8.46447L15.5355 7.05025L11.9999 10.5858L8.4644 7.05025L7.05018 8.46447Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default CrossIcon;
