export const UNAUTHORIZED_ROOT = '/login';

const unauthorizedPages = {
  root: UNAUTHORIZED_ROOT,
  oAuth: `${UNAUTHORIZED_ROOT}/auth`,
  oAuthSignup: `${UNAUTHORIZED_ROOT}/signup`,
  loginPassword: `${UNAUTHORIZED_ROOT}/password`,
  signupPassword: `${UNAUTHORIZED_ROOT}/password/signup`,
  loginProvider: `${UNAUTHORIZED_ROOT}/:provider`,
};

export default unauthorizedPages;
