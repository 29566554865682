import { SvgProps } from "../types";

function EyeOutlineIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6109 6.24629C7.54589 5.10288 9.75243 4.49919 12 4.5C14.2476 4.49919 16.4541 5.10288 18.3891 6.24629C20.3241 7.38969 21.9165 9.03084 23 11V13C21.9154 14.968 20.3226 16.6099 18.3878 17.7527C16.4531 18.8956 14.2471 19.5 12 19.5C9.7529 19.5 7.54694 18.8956 5.61216 17.7527C3.67738 16.6099 2.08463 14.968 1 13V11C2.08349 9.03084 3.67591 7.38969 5.6109 6.24629ZM12 6.5C10.067 6.5 8.5 8.067 8.5 10C8.5 11.933 10.067 13.5 12 13.5C13.933 13.5 15.5 11.933 15.5 10C15.5 8.06786 13.9344 6.50139 12.0026 6.5L12 6.5ZM6.96838 7.77561C6.66726 8.45573 6.5 9.20833 6.5 10C6.5 13.0376 8.96243 15.5 12 15.5C15.0376 15.5 17.5 13.0376 17.5 10C17.5 9.20833 17.3327 8.45573 17.0316 7.77561C17.146 7.83761 17.2594 7.90179 17.3716 7.96814C18.8568 8.84574 20.1016 10.0719 21 11.5378L21.25 11.9996L21 12.4615C20.1004 13.9273 18.8551 15.1539 17.3707 16.0307C15.7432 16.9921 13.8883 17.5 12 17.5C10.1117 17.5 8.25684 16.9921 6.62933 16.0307C5.14488 15.1539 3.89959 13.9273 3 12.4615L2.75 11.9996L3 11.5378C3.89838 10.0719 5.14318 8.84574 6.62836 7.96814C6.74064 7.90179 6.85399 7.83761 6.96838 7.77561Z"
        fill={color}
      />
    </svg>
  );
}

export default EyeOutlineIcon;
