import { SagaIterator } from "redux-saga";
import {
  call, fork, put, select, takeLatest,
} from "redux-saga/effects";

import { ErrorAction } from "../reducers";
import i18n from "../../services/i18n";

import * as actions from './actions';
import apiService, { ApiResponse } from "../../services/api";
import {
  CreateIntegrationLabelRequest,
  CreateIntegrationLabelResponse,
  CreateIntegrationRequest,
  CreateIntegrationResponse,
  DeleteIntegrationLabelRequest,
  DeleteIntegrationRequest,
  GetIntegrationFeaturesRequest,
  GetIntegrationFeaturesResponse,
  GetIntegrationsLabelsResponse,
  GetIntegrationsResponse,
  GetIntegrationUsageRequest,
  GetIntegrationUsageResponse,
  TestIntegrationRequest,
  TestIntegrationResponse,
  UpdateIntegrationLabelRequest,
  UpdateIntegrationLabelResponse,
  UpdateIntegrationRequest,
  UpdateIntegrationResponse,
  UpdateLabelIntegrationsRequest,
} from "../../types/requests/Integrations";
import { parseIntegration, parseIntegrationTest, parseIntegrationToBackend } from "../../types/parsers/IntegrationsParser";
import { Project } from "../../types/models/Project";
import { getSelectedProject } from "../projects/selectors";
import { objCamelToSnake, objCamelToSnakeDeep, objSnakeToCamelDeep, onlyUnique, transliterate } from "../../services/helpers/utilities";
import { getIntegrationToTestId, getIntegrationLabelById, getIntegrationsByIdList, getLabelIntegrations, getUsageIntegration } from "./selectors";
import { Integration, IntegrationLabel } from "../../types/models/Integrations";
import { integrationFileFeatures } from "./helpers";
import { generateId } from "../../services/helpers/generateId";
import NotificationService from "../../services/notifications";
import { NodeInfo } from "../../types/models/NodeInfo";

function* loadIntegrations(): SagaIterator {
  yield takeLatest(actions.loadIntegrations.request, function* () {
    try {
      const response: ApiResponse<GetIntegrationsResponse> = yield call(apiService.getIntegrations);
      const parsedIntegrations = response.data.integrations.map(parseIntegration);
      yield put(actions.loadIntegrations.success(parsedIntegrations));
    } catch (err) {
      yield put(actions.loadIntegrations.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.INTEGRATIONS.MULTIPLE") }),
      )));
    }
  });
}

function* createIntegration(): SagaIterator {
  yield takeLatest(actions.createIntegration.request, function* (action) {
    const integration = action.payload;
    yield put(actions.addIntegration({
      ...integration,
      pending: true,
    }));

    try {
      const selectProject: Project = yield select(getSelectedProject);
      const params: CreateIntegrationRequest = {
        body: {
          ...parseIntegrationToBackend(integration),
          project_slug: selectProject.slug,
        },
      };

      const response: ApiResponse<
        CreateIntegrationResponse
      > = yield call(apiService.createIntegration, params);
      const parsedIntegration = parseIntegration(response.data);
      yield put(actions.removeIntegration(integration.id as string));
      yield put(actions.addIntegration(parsedIntegration));
      yield put(actions.closePanel());
      yield put(actions.setActiveIntegrationId(''));
    } catch (err) {
      yield put(actions.createIntegration.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") }),
        integration,
      )));
      yield put(actions.removeIntegration(integration.id as string));
    }
  });
}

function* updateIntegration(): SagaIterator {
  yield takeLatest(actions.updateIntegration.request, function* (action) {
    const integration = action.payload;
    try {
      if (!integration.id) {
        console.error("Can't create an integrations because id field is empty");
        throw new Error("ERRORS.COMMON.INTERNAL_ERROR");
      }
      const params: UpdateIntegrationRequest = {
        params: {
          integration_id: integration.id,
        },
        body: parseIntegrationToBackend(integration),
      };

      const response: ApiResponse<
        UpdateIntegrationResponse
      > = yield call(apiService.updateIntegration, params);
      const parsedIntegration = parseIntegration(response.data);
      yield put(actions.updateIntegration.success(parsedIntegration));
      NotificationService.success(i18n.t("PAGE_INTEGRATIONS.INTEGRATION_SAVED"));
    } catch (err) {
      yield put(actions.updateIntegration.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") }),
        integration,
      )));
    }
  });
}

function* deleteIntegration(): SagaIterator {
  yield takeLatest(actions.deleteIntegration.request, function* (action) {
    const integrationId = action.payload;
    try {
      const params: DeleteIntegrationRequest = {
        params: {
          integration_id: integrationId,
        },
      };

      yield call(apiService.deleteIntegration, params);
      yield put(actions.deleteIntegration.success(integrationId));
    } catch (err) {
      yield put(actions.deleteIntegration.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.INTEGRATIONS.SINGLE") }),
        integrationId,
      )));
    }
  });
}

function* loadIntegrationFeatures(): SagaIterator {
  yield takeLatest(actions.loadIntegrationFeatures.request, function* (action) {
    try {
      const integrationId = action.payload;

      const params: GetIntegrationFeaturesRequest = {
        path: {
          integration_id: integrationId,
        },
      };
      const response: ApiResponse<GetIntegrationFeaturesResponse> = yield call(
        apiService.getIntegrationInputFeatures,
        params,
      );

      yield put(actions.loadIntegrationFeatures.success(response.data.features));
    } catch (err) {
      const message = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.FEATURES.MULTIPLE") });
      yield put(actions.loadIntegrationFeatures.failure(new ErrorAction(
        err,
        message,
      )));
    }
  });
}

function* testIntegration(): SagaIterator {
  yield takeLatest(actions.testIntegration.request, function* (action) {
    try {
      const integrationId: Integration['id'] = yield select(getIntegrationToTestId);
      const { state } = action.payload;

      state.features = state.features.map(feature => {
        if (integrationFileFeatures.includes(feature.key)) {
          return {
            ...feature,
            value: [feature.value as string],
          };
        }

        return feature;
      });

      const params: TestIntegrationRequest = {
        path: {
          integration_id: integrationId,
        },
        body: objCamelToSnake({ state }, true),
      };

      const response: ApiResponse<TestIntegrationResponse> = yield call(
        apiService.testIntegration,
        params,
      );

      yield put(actions.testIntegration.success(parseIntegrationTest(response.data)));
    } catch (err) {
      const message = i18n.t("ERRORS.API.INTEGRATIONS.TEST");
      yield put(actions.testIntegration.failure(new ErrorAction(err, message)));
    }
  });
}

function* setIntegrationIdToTest(): SagaIterator {
  yield takeLatest(actions.setIntegrationIdToTest, function* () {
    yield put(actions.openTestingModal());
  });
}

function* loadIntegrationsLabels(): SagaIterator {
  yield takeLatest(actions.loadIntegrationsLabels.request, function* () {
    try {
      const response: ApiResponse<GetIntegrationsLabelsResponse> = yield call(
        apiService.getIntegrationsLabels,
      );

      const labels = response.data.labels.map<IntegrationLabel>(objSnakeToCamelDeep);
      yield put(actions.loadIntegrationsLabels.success(labels));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.INTEGRATIONS_LABEL.MULTIPLE") });
      yield put(actions.loadIntegrationsLabels.failure(new ErrorAction(err, text)));
    }
  });
}

function* createIntegrationLabel(): SagaIterator {
  yield takeLatest(actions.createIntegrationLabel.request, function* (action) {
    try {
      const { payload: title } = action;

      const newLabel: IntegrationLabel = {
        id: generateId(),
        slug: transliterate(title),
        title,
      };

      const request: CreateIntegrationLabelRequest = {
        body: objCamelToSnakeDeep(newLabel),
      };
      const response: ApiResponse<CreateIntegrationLabelResponse> = yield call(
        apiService.createIntegrationLabel,
        request,
      );

      const label = objSnakeToCamelDeep<IntegrationLabel>(response.data);
      yield put(actions.createIntegrationLabel.success(label));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE", { name: i18n.t("ERRORS.API.INTEGRATIONS_LABEL.SINGLE") });
      yield put(actions.loadIntegrationsLabels.failure(new ErrorAction(err, text)));
    }
  });
}

function* updateIntegrationLabel(): SagaIterator {
  yield takeLatest(actions.updateIntegrationLabel.request, function* (action) {
    try {
      const { payload: { label, integrations } } = action;
      let newLabel = label;
      const oldLabel: IntegrationLabel = yield select(getIntegrationLabelById, label.id);

      const titleChanged = oldLabel.title !== label.title;

      if (titleChanged) {
        const request: UpdateIntegrationLabelRequest = {
          path: {
            label_id: label.id,
          },
          body: objCamelToSnakeDeep(label),
        };

        const response: ApiResponse<UpdateIntegrationLabelResponse> = yield call(
          apiService.updateIntegrationLabel,
          request,
        );

        newLabel = objSnakeToCamelDeep(response.data);
      }

      const request: UpdateLabelIntegrationsRequest = {
        path: {
          label_id: label.id,
        },
        body: {
          integrations_id: integrations.map(Number),
        },
      };

      yield call(apiService.updateLabelIntegrations, request);
      yield put(actions.updateIntegrationLabel.success(objSnakeToCamelDeep(newLabel)));
      yield put(actions.loadIntegrations.request());
      yield put(actions.closeEditLabelModal());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.INTEGRATIONS_LABEL.SINGLE") });
      yield put(actions.loadIntegrationsLabels.failure(new ErrorAction(err, text)));
    }
  });
}

function* deleteIntegrationLabel(): SagaIterator {
  yield takeLatest(actions.deleteIntegrationLabel.request, function* (action) {
    try {
      const { payload: labelId } = action;

      const request: DeleteIntegrationLabelRequest = {
        path: {
          label_id: labelId,
        },
      };

      yield call(
        apiService.deleteIntegrationLabel,
        request,
      );

      yield put(actions.deleteIntegrationLabel.success(labelId));
      yield put(actions.closeDeleteLabelModal());
      yield put(actions.closeEditLabelModal());
      yield put(actions.loadIntegrations.request());
    } catch (err) {
      const text = i18n.t("ERRORS.API.DELETE", { name: i18n.t("ERRORS.API.INTEGRATIONS_LABEL.SINGLE") });
      yield put(actions.loadIntegrationsLabels.failure(new ErrorAction(err, text)));
    }
  });
}

function* addIntegrationsToLabel(): SagaIterator {
  yield takeLatest(actions.addIntegrationsToLabel.request, function* (action) {
    try {
      const { label, integrations: integrationsIds } = action.payload;

      const integrationsToAdd: Integration[] = yield select(getIntegrationsByIdList, integrationsIds);
      const labelIntegrations: Integration[] = yield select(getLabelIntegrations, label.id);

      const newLabelIntegrationList = [
        ...integrationsToAdd.map(i => i.id),
        ...labelIntegrations.map(i => i.id),
      ].filter(onlyUnique);

      const request: UpdateLabelIntegrationsRequest = {
        path: {
          label_id: label.id,
        },
        body: {
          integrations_id: newLabelIntegrationList.map(Number),
        },
      };

      yield call(apiService.updateLabelIntegrations, request);
      yield put(actions.addIntegrationsToLabel.success({
        label,
        integrations: integrationsIds,
      }));
      yield put(actions.loadIntegrations.request());
      yield put(actions.closeAddIntegrationsToLabelModal());
    } catch (err) {
      const text = i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.INTEGRATIONS.MULTIPLE") });
      yield put(actions.addIntegrationsToLabel.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadIntegrationUsage(): SagaIterator {
  yield takeLatest(actions.loadIntegrationUsage.request, function* () {
    try {
      const activeIntegration: Integration | undefined = yield select(getUsageIntegration);
      if (!activeIntegration || !activeIntegration.id) {
        yield put(actions.loadIntegrationUsage.success([]));
        return;
      }

      const request: GetIntegrationUsageRequest = {
        path: {
          action_id: activeIntegration.id,
        },
      };

      const response: ApiResponse<GetIntegrationUsageResponse> = yield call(
        apiService.getintegrationUsage,
        request,
      );

      const info = response.data.nodes.map<NodeInfo>(objSnakeToCamelDeep);

      yield put(actions.loadIntegrationUsage.success(info));
    } catch (err) {
      const text = i18n.t("ERRORS.COMMON.ERROR");
      yield put(actions.loadIntegrationUsage.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* integrationsSagas(): SagaIterator {
  yield fork(loadIntegrations);
  yield fork(createIntegration);
  yield fork(updateIntegration);
  yield fork(deleteIntegration);
  yield fork(loadIntegrationFeatures);
  yield fork(testIntegration);
  yield fork(loadIntegrationsLabels);
  yield fork(createIntegrationLabel);
  yield fork(updateIntegrationLabel);
  yield fork(deleteIntegrationLabel);
  yield fork(addIntegrationsToLabel);
  yield fork(setIntegrationIdToTest);
  yield fork(loadIntegrationUsage);
}
