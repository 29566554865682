import React from 'react';

import { SvgProps } from '../types';

function LogoTextIcon({
  width = "82",
  height = "37",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 82 37"
      fill="none"
    >
      <path
        d="M7.28 13.9V11.54C7.28 9.76 7.06 9.24 6.38 7.76L2.82 0H0.3L4.86 9.92V13.9H7.28ZM8.86 6.72L11.82 0H9.36L6.4 6.72H8.86ZM34.14 14.1C35.26 14.1 36.18 13.58 36.82 12.6L36.94 13.9H39.14V0H36.76V5.02C36.16 4.12 35.28 3.64 34.24 3.64C31.94 3.64 30.34 5.58 30.34 8.94C30.34 12.26 31.9 14.1 34.14 14.1ZM48.76 13.22V11.28C48.02 11.78 46.78 12.22 45.62 12.22C43.88 12.22 43.22 11.4 43.12 9.72H48.86V8.46C48.86 4.96 47.32 3.64 44.94 3.64C42.04 3.64 40.66 5.86 40.66 8.9C40.66 12.4 42.38 14.1 45.42 14.1C46.94 14.1 48.06 13.7 48.76 13.22ZM23.14 6.68C23.58 6.14 24.28 5.68 25.14 5.68C25.98 5.68 26.36 6.04 26.36 6.8V13.9H28.74V6.56C28.74 4.56 27.94 3.68 26 3.68C24.58 3.68 23.74 4.2 23.26 4.68H23.14L23.08 3.84H20.76V13.9H23.14V6.68ZM18.82 7.04C18.82 4.6 17.58 3.68 15.06 3.68C13.5 3.68 12.24 4.34 11.52 4.86V6.92C12.28 6.3 13.44 5.58 14.78 5.58C15.92 5.58 16.44 5.98 16.44 7.06V7.7H16.06C12.42 7.7 10.8 8.88 10.8 10.98C10.8 12.92 12.04 14.04 13.88 14.04C15.28 14.04 15.88 13.58 16.34 13.1H16.44C16.46 13.36 16.54 13.7 16.62 13.9H18.94C18.86 13.08 18.82 12.26 18.82 11.44V7.04ZM56.08 13.9H58.76L55.48 8.72L58.32 3.84H55.94L54.22 6.88L52.3 3.84H49.62L52.66 8.66L49.5 13.9H51.92L53.94 10.5L56.08 13.9ZM34.84 5.52C36.12 5.52 36.76 6.54 36.76 8.86C36.76 11.2 36.08 12.22 34.74 12.22C33.44 12.22 32.8 11.22 32.8 8.94C32.8 6.56 33.48 5.52 34.84 5.52ZM44.9 5.52C46.08 5.52 46.44 6.5 46.44 7.76V7.96H43.12C43.18 6.36 43.76 5.52 44.9 5.52ZM16.44 11.5C16.14 11.94 15.58 12.3 14.74 12.3C13.74 12.3 13.24 11.72 13.24 10.84C13.24 9.68 14.06 9.28 16.1 9.28H16.44V11.5Z"
        fill="#21201F"
      />
      <path
        d="M3.8 34.1C6.08 34.1 8.54 33.16 8.54 29.96C8.54 27.44 7.06 26.44 5.1 25.88C3.66 25.46 2.36 25.12 2.36 23.64C2.36 22.4 3.08 21.68 4.68 21.68C6.1 21.68 7.02 22.18 7.82 22.64V20.7C7.36 20.38 6.42 19.8 4.62 19.8C1.98 19.8 0.02 21.12 0.02 23.84C0.02 26.34 1.48 27.18 2.98 27.68C4.52 28.2 6.08 28.5 6.08 30.16C6.08 31.5 5.1 32.22 3.6 32.22C1.98 32.22 0.68 31.42 0 30.92V32.86C0.6 33.34 1.74 34.1 3.8 34.1Z"
        fill="#21201F"
      />
      <path
        d="M15.7414 31.06C15.3014 31.6 14.6014 32.06 13.7414 32.06C12.9014 32.06 12.5214 31.7 12.5214 30.94V23.84H10.1414V31.18C10.1414 33.18 10.9414 34.06 12.8814 34.06C14.3014 34.06 15.1414 33.54 15.6214 33.06H15.7414L15.8014 33.9H18.1214V23.84H15.7414V31.06Z"
        fill="#21201F"
      />
      <path
        d="M22.3242 23.84H20.1242V36.8H22.5042V32.72C23.1042 33.62 23.9842 34.1 25.0042 34.1C27.3242 34.1 28.9242 32.24 28.9242 28.86C28.9242 25.5 27.3642 23.64 25.1242 23.64C24.0042 23.64 23.0842 24.16 22.4442 25.14L22.3242 23.84ZM24.4042 32.22C23.1442 32.22 22.5042 31.2 22.5042 28.88C22.5042 26.54 23.1842 25.52 24.5242 25.52C25.8242 25.52 26.4642 26.54 26.4642 28.86C26.4642 31.2 25.7842 32.22 24.4042 32.22Z"
        fill="#21201F"
      />
      <path
        d="M32.7148 23.84H30.5148V36.8H32.8948V32.72C33.4948 33.62 34.3748 34.1 35.3948 34.1C37.7148 34.1 39.3148 32.24 39.3148 28.86C39.3148 25.5 37.7548 23.64 35.5148 23.64C34.3948 23.64 33.4748 24.16 32.8348 25.14L32.7148 23.84ZM34.7948 32.22C33.5348 32.22 32.8948 31.2 32.8948 28.88C32.8948 26.54 33.5748 25.52 34.9148 25.52C36.2148 25.52 36.8548 26.54 36.8548 28.86C36.8548 31.2 36.1748 32.22 34.7948 32.22Z"
        fill="#21201F"
      />
      <path d="M44.9455 23.64C42.3255 23.64 40.5055 25.5 40.5055 28.88C40.5055 32.24 42.3255 34.1 44.9455 34.1C47.5655 34.1 49.3855 32.24 49.3855 28.86C49.3855 25.5 47.5655 23.64 44.9455 23.64ZM44.9455 32.22C43.6255 32.22 42.9655 31.2 42.9655 28.88C42.9655 26.54 43.6255 25.52 44.9455 25.52C46.2655 25.52 46.9255 26.54 46.9255 28.86C46.9255 31.2 46.2655 32.22 44.9455 32.22Z" fill="#21201F" />
      <path d="M53.3636 27.62C54.0236 26.4 55.0036 25.78 56.4636 25.86V23.62C55.1036 23.7 53.9836 24.52 53.4236 25.84H53.3636L53.3036 23.84H50.9836V33.9H53.3636V27.62Z" fill="#21201F" />
      <path d="M57.2539 23.84V25.72H58.5139V30.88C58.5139 32.76 59.5739 34.1 61.7739 34.1C62.9339 34.1 63.5939 33.84 63.5939 33.84V31.9C63.5939 31.9 63.1539 32.22 62.3539 32.22C61.3539 32.22 60.8939 31.64 60.8939 30.9V25.72H63.3939V23.84H60.8939V21.64H58.5139V23.84H57.2539Z" fill="#21201F" />
      <path d="M78.3961 33.9L74.7361 20H71.6761L67.9961 33.9H70.0761L70.8561 30.86H75.1161L75.8761 33.9H78.3961ZM71.3361 28.98L72.9961 22.54L74.6361 28.98H71.3361Z" fill="#21201F" />
      <path d="M79.5969 20V33.9H82V20H79.5969Z" fill="#21201F" />
    </svg>
  );
}

export default LogoTextIcon;
