import React from 'react';

import { SvgProps } from '../types';

function RedoIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91008 1.70718L11.3243 0.292969L16.0314 5.00008L11.3243 9.70718L9.91008 8.29297L12.2002 6.00287C12.1337 6.001 12.067 6.00006 12 6.00006C8.13401 6.00006 5 9.13407 5 13.0001C5 16.8661 8.13401 20.0001 12 20.0001C15.866 20.0001 19 16.8661 19 13.0001C19 11.0671 18.2165 9.31706 16.9497 8.05031L18.364 6.6361C19.9926 8.26478 21 10.5148 21 13.0001C21 17.9706 16.9706 22.0001 12 22.0001C7.02944 22.0001 3 17.9706 3 13.0001C3 8.0295 7.02944 4.00006 12 4.00006C12.0686 4.00006 12.137 4.00083 12.2053 4.00236L9.91008 1.70718Z"
        fill={color}
      />
    </svg>
  );
}

export default RedoIcon;
