import React, { FC } from 'react';
import { SvgProps } from '../types';

const PenIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7929 7.36792L6.43286 15.7279C6.25276 15.9074 6.10851 16.1195 6.00786 16.3529L4.27986 19.5199L7.64486 17.9899C7.87786 17.8889 8.08986 17.7449 8.26886 17.5659L16.6289 9.20392C16.4031 8.82903 16.1298 8.48485 15.8159 8.17992C15.5112 7.86642 15.1673 7.5935 14.7929 7.36792V7.36792ZM16.2389 5.92192C16.5739 6.15892 16.9039 6.44092 17.2299 6.76592C17.5559 7.09192 17.8379 7.42292 18.0749 7.75792L19.5459 6.28692C19.6155 6.21742 19.663 6.12893 19.6826 6.03252C19.7022 5.93611 19.6929 5.83607 19.6559 5.74492C19.5489 5.48292 19.3579 5.20792 19.0739 4.92292C18.7889 4.63892 18.5139 4.44792 18.2519 4.34092C18.1607 4.30391 18.0607 4.2946 17.9643 4.31416C17.8679 4.33373 17.7794 4.3813 17.7099 4.45092L16.2399 5.92192H16.2389ZM4.16986 15.5639C4.37057 15.0972 4.65837 14.673 5.01786 14.3139L16.2959 3.03592C16.6433 2.68838 17.0855 2.45096 17.5671 2.35331C18.0488 2.25567 18.5485 2.30214 19.0039 2.48692C19.5299 2.70092 20.0239 3.04392 20.4879 3.50792C20.9519 3.97292 21.2959 4.46692 21.5079 4.99192C21.6928 5.4472 21.7394 5.94687 21.6419 6.4285C21.5445 6.91013 21.3072 7.35236 20.9599 7.69992L9.68386 18.9799C9.32474 19.3391 8.90052 19.6265 8.43386 19.8269L4.99186 21.3069C4.67028 21.4454 4.31455 21.4842 3.97068 21.4183C3.62681 21.3525 3.31059 21.185 3.06291 20.9376C2.81523 20.6901 2.64747 20.374 2.58132 20.0302C2.51517 19.6864 2.55368 19.3306 2.69186 19.0089L4.16986 15.5639V15.5639Z" fill={color} />
  </svg>
);

export default PenIcon;
