import React from 'react';
import { useTranslation } from 'react-i18next';

import './uploadingRobotAudio.scss';

type UploadingRobotAudioProps = {
  filename: string,
}

export default function UploadingRobotAudio({
  filename,
}: UploadingRobotAudioProps) {
  const { t } = useTranslation();
  return (
    <div className="uploading-robot-audio">
      {t("GRAPH.UPLOADING_FILE", { filename })}
    </div>
  );
}
