/* eslint-disable camelcase */
import { SagaIterator } from "redux-saga";
import {
  call, fork, put, takeLatest,
} from "redux-saga/effects";

import i18n from "../../services/i18n";
import Api, { ApiResponse } from "../../services/api";

import {
  GetProjectAuthDataRequest,
  GetProjectAuthDataResponse,
  PutProjectAuthDataRequest,
  PutProjectAuthDataResponse,
} from "../../types/requests/ProjectAuthData";

import { parseAuthData, parseIpAddressesToBackend } from "../../types/parsers/ProjectAuthData";

import { ErrorAction } from "../reducers";

import * as actions from './actions';

function* loadProjectAuthData(): SagaIterator {
  yield takeLatest(actions.loadProjectAuthData.request, function* ({ payload }) {
    try {
      const params: GetProjectAuthDataRequest = { project_slug: payload };
      type T = ApiResponse<GetProjectAuthDataResponse>;
      const { data }: T = yield call(Api.getProjectAuthData, params);
      const parsed = parseAuthData(data);

      yield put(actions.loadProjectAuthData.success({ projectSlug: payload, data: parsed }));
    } catch (err) {
      yield put(actions.loadProjectAuthData.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.PROJECT_AUTH_DATA") }),
      )));
    }
  });
}

function* updateProjectAuthData(): SagaIterator {
  yield takeLatest(actions.updateProjectAuthData.request, function* ({ payload }) {
    try {
      const { data: newData, projectSlug } = payload;
      const { ipAddresses, apiKey } = newData;
      const ip_addresses = parseIpAddressesToBackend(ipAddresses);
      const api_key = apiKey ? apiKey : "";

      const params: PutProjectAuthDataRequest = {
        params: { project_slug: projectSlug },
        body: { ip_addresses, api_key },
      };

      type T = ApiResponse<PutProjectAuthDataResponse>;
      const { data }: T = yield call(Api.putProjectAuthData, params);
      const parsed = parseAuthData(data);

      yield put(
        actions.loadProjectAuthData.success({ projectSlug: payload.projectSlug, data: parsed }),
      );
    } catch (err) {
      yield put(actions.updateProjectAuthData.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.UPLOAD", { name: i18n.t("ERRORS.API.PROJECT_AUTH_DATA") }),
      )));
    }
  });
}

export default function* releaseNotesSagas(): SagaIterator {
  yield fork(loadProjectAuthData);
  yield fork(updateProjectAuthData);
}
