import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { Feature } from "../../types/models/Feature";
import { NodeInfo } from "../../types/models/NodeInfo";

import { Integration, IntegrationId, IntegrationLabel, IntegrationTest } from "../../types/models/Integrations";
import { Editable } from "../reducers";

import * as actions from './actions';

export type NEW_INTEGRATION_ID_TYPE = "NEW_INTEGRATION";
export const NEW_INTEGRATION_ID: NEW_INTEGRATION_ID_TYPE = "NEW_INTEGRATION";

type IntegrationsState = {
  integrations: {
    loading: boolean,
    list: Editable<Integration>[],
  },
  isPanelOpened: boolean,
  activeIntegration: {
    id: IntegrationId | NEW_INTEGRATION_ID_TYPE | null,
    loading: boolean,
  },
  integrationToTest: {
    id: IntegrationId | null,
    loading: boolean;
  }
  testing: {
    modalOpened: boolean;
    featuresLoading: boolean;
    features: Array<Feature['slug']>;
    testProcessing: boolean;
    state?: IntegrationTest;
    prevState?: IntegrationTest;
  },
  labels: {
    list: IntegrationLabel[];
    loading: boolean;
    creating: boolean;
    updating: boolean;
  },
  addIntegrationsModal: {
    opened: boolean;
    initIntegrations: Array<Integration['id']>;
  }
  editLabelModal: {
    id: IntegrationLabel['id'];
    opened: boolean;
  },
  deleteLabelModal: {
    id: IntegrationLabel['id'];
    opened: boolean;
  },
  usageModal: {
    id: Integration['id'],
    opened: boolean;
    loading: boolean;
    usageInfo: NodeInfo[];
  }
}

const initialState: IntegrationsState = {
  integrations: {
    loading: false,
    list: [],
  },
  isPanelOpened: false,
  activeIntegration: {
    id: null,
    loading: false,
  },
  integrationToTest: {
    id: null,
    loading: false,
  },
  testing: {
    modalOpened: false,
    featuresLoading: false,
    features: [],
    testProcessing: false,
  },
  labels: {
    list: [],
    loading: false,
    creating: false,
    updating: false,
  },
  addIntegrationsModal: {
    opened: false,
    initIntegrations: [],
  },
  editLabelModal: {
    id: "",
    opened: false,
  },
  deleteLabelModal: {
    id: "",
    opened: false,
  },
  usageModal: {
    id: undefined,
    opened: false,
    loading: false,
    usageInfo: [],
  },
};

export const integrationsReducer: Reducer<IntegrationsState> = createReducer(initialState)
  .handleAction(
    actions.loadIntegrations.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrations.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.loadIntegrations.success>,
    ): IntegrationsState => ({
      ...state,
      integrations: {
        ...state.integrations,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrations.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.addIntegration,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.addIntegration>,
    ): IntegrationsState => ({
      ...state,
      integrations: {
        ...state.integrations,
        list: [payload, ...state.integrations.list],
      },
    }),
  )
  .handleAction(
    actions.removeIntegration,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.removeIntegration>,
    ): IntegrationsState => ({
      ...state,
      integrations: {
        ...state.integrations,
        list: state.integrations.list.filter(integration => integration.id !== payload),
      },
    }),
  )
  .handleAction(
    actions.updateIntegration.request,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.updateIntegration.request>,
    ): IntegrationsState => updateIntegration(state, payload.id, integration => ({
      ...integration,
      pending: true,
    })),
  )
  .handleAction(
    actions.updateIntegration.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.updateIntegration.success>,
    ): IntegrationsState => updateIntegration(state, payload.id, integration => ({
      ...integration,
      ...payload,
      pending: false,
    })),
  )
  .handleAction(
    actions.updateIntegration.failure,
    (
      state: IntegrationsState,
      { payload: error }: ActionType<typeof actions.updateIntegration.failure>,
    ): IntegrationsState => {
      const { payload } = error;
      return updateIntegration(state, payload.id, integration => ({
        ...integration,
        pending: false,
      }));
    },
  )
  .handleAction(
    actions.deleteIntegration.request,
    (
      state: IntegrationsState,
      { payload: integrationId }: ActionType<typeof actions.deleteIntegration.request>,
    ): IntegrationsState => updateIntegration(state, integrationId, integration => ({
      ...integration,
      pending: true,
    })),
  )
  .handleAction(
    actions.deleteIntegration.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.deleteIntegration.success>,
    ): IntegrationsState => ({
      ...state,
      integrations: {
        ...state.integrations,
        list: state.integrations.list.filter(integration => integration.id !== payload),
      },
    }),
  )
  .handleAction(
    actions.deleteIntegration.failure,
    (
      state: IntegrationsState,
      { payload: error }: ActionType<typeof actions.deleteIntegration.failure>,
    ): IntegrationsState => {
      const { payload: integrationId } = error;
      return updateIntegration(state, integrationId, integration => ({
        ...integration,
        pending: false,
      }));
    },
  )
  .handleAction(
    actions.openPanel,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      isPanelOpened: true,
    }),
  )
  .handleAction(
    actions.closePanel,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      isPanelOpened: false,
    }),
  )
  .handleAction(
    actions.setActiveIntegrationId,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.setActiveIntegrationId>,
    ): IntegrationsState => ({
      ...state,
      activeIntegration: {
        ...state.activeIntegration,
        id: payload,
      },
    }),
  )
  .handleAction(
    actions.openTestingModal,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      testing: {
        ...state.testing,
        modalOpened: true,
      },
    }),
  )
  .handleAction(
    actions.closeTestingModal,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      testing: {
        ...initialState.testing,
        modalOpened: false,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationFeatures.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      testing: {
        ...state.testing,
        featuresLoading: true,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationFeatures.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.loadIntegrationFeatures.success>,
    ): IntegrationsState => ({
      ...state,
      testing: {
        ...state.testing,
        features: payload,
        featuresLoading: false,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationFeatures.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      testing: {
        ...state.testing,
        featuresLoading: false,
      },
    }),
  )
  .handleAction(
    actions.testIntegration.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      testing: {
        ...state.testing,
        testProcessing: true,
      },
    }),
  )
  .handleAction(
    actions.testIntegration.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.testIntegration.success>,
    ): IntegrationsState => {
      const currentState = state.testing.state;

      return {
        ...state,
        testing: {
          ...state.testing,
          state: payload,
          prevState: currentState,
          testProcessing: false,
        },
      };
    },
  )
  .handleAction(
    actions.testIntegration.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      testing: {
        ...state.testing,
        testProcessing: false,
      },
    }),
  )
  .handleAction(
    actions.setIntegrationIdToTest,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.setIntegrationIdToTest>,
    ): IntegrationsState => ({
      ...state,
      integrationToTest: {
        ...state.integrationToTest,
        id: payload,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationsLabels.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        list: [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationsLabels.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.loadIntegrationsLabels.success>,
    ): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationsLabels.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createIntegrationLabel.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        creating: true,
      },
    }),
  )
  .handleAction(
    actions.createIntegrationLabel.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.createIntegrationLabel.success>,
    ): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        creating: false,
        list: [...state.labels.list, payload],
      },
    }),
  )
  .handleAction(
    actions.createIntegrationLabel.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        creating: false,
      },
    }),
  )
  .handleAction(
    actions.addIntegrationsToLabel.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: true,
      },
    }),
  )
  .handleAction(
    actions.addIntegrationsToLabel.success,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.addIntegrationsToLabel.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.updateIntegrationLabel.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: true,
      },
    }),
  )
  .handleAction(
    actions.updateIntegrationLabel.success,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.deleteIntegrationLabel.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.deleteIntegrationLabel.success>,
    ): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        list: state.labels.list.filter(l => l.id !== payload),
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.deleteIntegrationLabel.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.deleteIntegrationLabel.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: true,
      },
    }),
  )
  .handleAction(
    actions.updateIntegrationLabel.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      labels: {
        ...state.labels,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.openAddIntegrationsToLabelModal,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.openAddIntegrationsToLabelModal>,
    ): IntegrationsState => ({
      ...state,
      addIntegrationsModal: {
        ...state.addIntegrationsModal,
        opened: true,
        initIntegrations: payload.initIntegrations,
      },
    }),
  )
  .handleAction(
    actions.closeAddIntegrationsToLabelModal,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      addIntegrationsModal: {
        ...state.addIntegrationsModal,
        opened: false,
        initIntegrations: [],
      },
    }),
  )
  .handleAction(
    actions.openEditLabelModal,
    (
      state: IntegrationsState,
      { payload: labelId }: ActionType<typeof actions.openEditLabelModal>,
    ): IntegrationsState => ({
      ...state,
      editLabelModal: {
        ...state.editLabelModal,
        id: labelId,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeEditLabelModal,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      editLabelModal: {
        ...state.editLabelModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.openDeleteLabelModal,
    (
      state: IntegrationsState,
      { payload: labelId }: ActionType<typeof actions.openDeleteLabelModal>,
    ): IntegrationsState => ({
      ...state,
      deleteLabelModal: {
        ...state.deleteLabelModal,
        id: labelId,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeDeleteLabelModal,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      deleteLabelModal: {
        ...state.deleteLabelModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.openUsageModal,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.openUsageModal>,
    ): IntegrationsState => ({
      ...state,
      usageModal: {
        ...state.usageModal,
        id: payload,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeUsageModal,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      usageModal: {
        ...state.usageModal,
        id: undefined,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationUsage.request,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      usageModal: {
        ...state.usageModal,
        loading: true,
        usageInfo: [],
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationUsage.success,
    (
      state: IntegrationsState,
      { payload }: ActionType<typeof actions.loadIntegrationUsage.success>,
    ): IntegrationsState => ({
      ...state,
      usageModal: {
        ...state.usageModal,
        loading: false,
        usageInfo: payload,
      },
    }),
  )
  .handleAction(
    actions.loadIntegrationUsage.failure,
    (state: IntegrationsState): IntegrationsState => ({
      ...state,
      usageModal: {
        ...state.usageModal,
        loading: false,
      },
    }),
  );

function updateIntegration(
  state: IntegrationsState,
  integrationId: string | undefined,
  callback: (integration: Editable<Integration>) => Editable<Integration>,
): IntegrationsState {
  return {
    ...state,
    integrations: {
      ...state.integrations,
      list: state.integrations.list
        .map(integration => (integration.id === integrationId ?
          callback(integration) :
          integration)),
    },
  };
}
