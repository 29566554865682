import Icon from '../../icon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ClearIndicator = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      className="nd-custom-select__clear"
      tabIndex={0}
      ref={ref}
    >
      <Icon type="clearFilled" />
    </div>
  );
};

export default ClearIndicator;
