import { SagaIterator } from "redux-saga";

import { call, fork, put, takeLatest } from "redux-saga/effects";

import { ErrorAction } from "../reducers";
import i18n from "../../services/i18n";

import * as actions from './actions';
import apiService, { ApiResponse } from "../../services/api";
import { LanguagesResponse } from "../../types/requests/Languages";
import { Language } from "../../types/models/Languages";

function* loadLanguages(): SagaIterator {
  yield takeLatest(actions.loadLanguages.request, function* () {
    try {
      const response: ApiResponse<LanguagesResponse> = yield call(apiService.getLanguages);
      const { languages, default: mainLanguage } = response.data;

      const list: Language[] = languages.map(slug => ({
        slug,
        name: i18n.t(`LANGUAGES.${slug.toUpperCase()}`),
      }));

      yield put(actions.loadLanguages.success({ list, mainLanguage }));
    } catch (err) {
      yield put(actions.loadLanguages.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.LANGUAGES") }),
      )));
    }
  });
}

export default function* languagesSagas(): SagaIterator {
  yield fork(loadLanguages);
}
