import React, { FC } from 'react';
import { SvgProps } from '../types';

const ExclamationIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.36499 21.5H19.645C21.3858 21.5 22.5 20.2214 22.5 18.6455C22.5 18.1698 22.3806 17.6841 22.122 17.2381L14.4621 3.93714C13.9249 2.98565 12.9699 2.5 12.005 2.5C11.04 2.5 10.0751 2.99557 9.5379 3.93714L1.87802 17.248C1.61937 17.6941 1.5 18.1698 1.5 18.6455C1.5 20.2214 2.62411 21.5 4.36499 21.5ZM4.554 19.597C3.93723 19.597 3.52937 19.0915 3.52937 18.5464C3.52937 18.3878 3.54927 18.1896 3.64874 18.0112L11.1097 4.99765C11.3086 4.66067 11.6568 4.50209 12.005 4.50209C12.3531 4.50209 12.6914 4.66067 12.8804 4.99765L20.3513 18.031C20.4408 18.1995 20.4806 18.3878 20.4806 18.5464C20.4806 19.0915 20.0628 19.597 19.446 19.597H4.554ZM12.005 14.6215C12.5521 14.6215 12.8704 14.3143 12.8804 13.7394L13.0296 8.97209C13.0495 8.38732 12.6118 7.97105 11.995 7.97105C11.3783 7.97105 10.9505 8.37741 10.9704 8.96218L11.1097 13.7493C11.1296 14.3143 11.4479 14.6215 12.005 14.6215ZM12.005 17.9121C12.6516 17.9121 13.1788 17.4463 13.1788 16.8219C13.1788 16.1875 12.6615 15.7316 12.005 15.7316C11.3584 15.7316 10.8311 16.1974 10.8311 16.8219C10.8311 17.4463 11.3683 17.9121 12.005 17.9121Z"
      fill={color}
    />
  </svg>
);

export default ExclamationIcon;
