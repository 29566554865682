import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import { LegalAddressWithoutFiasBackend, PersonBackend } from "../backendModels/BillingBackend";
import { AddressType, Gender, LegalAddressWithoutFias, Person } from "../models/Billing";

export function parsePerson(value: PersonBackend): Person {
  return {
    name: value.name,
    longname: value.name,
    phone: value.phone,
    email: value.email,
    inn: value.inn,
    kpp: value.kpp || "",
    bik: value.bik,
    account: value.account,
    signerPositionName: value["signer-position-name"] || "",
    signerPersonGender: value["signer-person-gender"] as Gender,
    signerPersonName: value["signer-person-name"] || "",
    postAddress: objSnakeToCamelDeep(value.post_address),
    legalAddress: parseLegalAddress(value.legal_address as LegalAddressWithoutFiasBackend),
  };
}

export function parseLegalAddress(value: LegalAddressWithoutFiasBackend): LegalAddressWithoutFias {
  return {
    type: AddressType.withoutFias,
    legaladdress: value.legaladdress,
    legalAddressPostcode: value['legal-address-postcode'],
  };
}

export function parsePersonToBackend(value: Person): PersonBackend {
  return {
    name: value.name,
    longname: value.name,
    phone: value.phone,
    email: value.email,
    inn: value.inn,
    kpp: value.kpp,
    bik: value.bik,
    account: value.account,
    "signer-position-name": value.signerPositionName,
    "signer-person-gender": value.signerPersonGender,
    "signer-person-name": value.signerPersonName,
    post_address: objCamelToSnakeDeep(value.postAddress),
    legal_address: parseLegalAddressToBackend(value.legalAddress as LegalAddressWithoutFias),
  };
}

export function parseLegalAddressToBackend(value: LegalAddressWithoutFias): LegalAddressWithoutFiasBackend {
  return {
    type: AddressType.withoutFias,
    legaladdress: value.legaladdress,
    "legal-address-postcode": value.legalAddressPostcode,
  };
}
