import { ActionType, createReducer } from 'typesafe-actions';
import { Reducer } from 'redux';

import { ScenarioGraphExampleType, TrashPhrase } from '../../types/models/ScenarioGraphExample';

import * as actions from './actions';
import { searchForDirtyExamples } from '../tasks/actions';

import { ScenarioGraphsItem } from '../scenarioGraphs/types';
import { ExampleData } from './types';
import { updateExample, updateExamplesOnDelete } from './helpers';

export type ScenariosExamplesState = {
  examples: Record<
    ScenarioGraphsItem['id'],
    ExampleData
  >;
  trashPhrases: {
    total: number;
    loading: boolean;
    phrases: TrashPhrase[];
  },
  allExamplesModal: {
    opened: boolean;
  },
  scenarioExamplesModal: {
    activeScenario: ScenarioGraphsItem['slug'];
    opened: boolean;
    defaultType: ScenarioGraphExampleType;
  },
  dirtyExamplesSearchAvailable: boolean;
}

const initialState: ScenariosExamplesState = {
  examples: {},
  trashPhrases: {
    loading: false,
    total: 0,
    phrases: [],
  },
  allExamplesModal: {
    opened: false,
  },
  scenarioExamplesModal: {
    activeScenario: "",
    opened: false,
    defaultType: ScenarioGraphExampleType.addition,
  },
  dirtyExamplesSearchAvailable: true,
};

type T = Reducer<ScenariosExamplesState>;
export const scenariosExamplesReducer: T = createReducer<ScenariosExamplesState>(initialState)
  .handleAction(
    actions.loadScenarioGraphExamples.request,
    (
      state: ScenariosExamplesState,
      { payload }: ActionType<typeof actions.loadScenarioGraphExamples.request>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [payload.scenarioId]: {
          ...state.examples[payload.scenarioId],
          list: [],
          loading: true,
        },
      },
    }),
  )
  .handleAction(
    actions.loadScenarioGraphExamples.success,
    (
      state: ScenariosExamplesState,
      {
        payload: { scenarioId, examples },
      }: ActionType<typeof actions.loadScenarioGraphExamples.success>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          list: examples,
          loading: false,
        },
      },
    }),
  )
  .handleAction(
    actions.loadScenarioGraphExamples.failure,
    (
      state: ScenariosExamplesState,
      {
        payload: { payload: id },
      }: ActionType<typeof actions.loadScenarioGraphExamples.failure>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [id]: {
          ...state.examples[id],
          list: [],
          loading: false,
        },
      },
    }),
  )
  .handleAction(
    actions.createScenarioGraphExample.request,
    (
      state: ScenariosExamplesState,
      {
        payload: { scenarioId },
      }: ActionType<typeof actions.createScenarioGraphExample.request>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          loading: true,
        },
      },
    }),
  )
  .handleAction(
    actions.createScenarioGraphExample.success,
    (
      state: ScenariosExamplesState,
      {
        payload: { scenarioId, example },
      }: ActionType<typeof actions.createScenarioGraphExample.success>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          list: [example, ...(state.examples[scenarioId].list || [])],
          loading: false,
        },
      },
    }),
  )
  .handleAction(
    actions.createScenarioGraphExample.failure,
    (
      state: ScenariosExamplesState,
      {
        payload: { payload: scenarioId },
      }: ActionType<typeof actions.createScenarioGraphExample.failure>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          loading: true,
        },
      },
    }),
  )
  .handleAction(
    actions.deleteScenarioGraphExample.request,
    (
      state: ScenariosExamplesState,
      {
        payload: { scenarioId },
      }: ActionType<typeof actions.deleteScenarioGraphExample.request>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          deleting: true,
        },
      },
    }),
  )
  .handleAction(
    actions.deleteScenarioGraphExample.success,
    (
      state: ScenariosExamplesState,
      {
        payload: { scenarioId, exampleId },
      }: ActionType<typeof actions.deleteScenarioGraphExample.success>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          list: state.examples[scenarioId].list.filter(e => e.id !== exampleId),
          deleting: false,
        },
      },
    }),
  )
  .handleAction(
    actions.deleteScenarioGraphExample.failure,
    (
      state: ScenariosExamplesState,
      {
        payload: { payload: scenarioId },
      }: ActionType<typeof actions.deleteScenarioGraphExample.failure>,
    ): ScenariosExamplesState => ({
      ...state,
      examples: {
        ...state.examples,
        [scenarioId]: {
          ...state.examples[scenarioId],
          deleting: false,
        },
      },
    }),
  )
  .handleAction(
    actions.loadTrashPhrases.request,
    (state: ScenariosExamplesState): ScenariosExamplesState => ({
      ...state,
      trashPhrases: {
        ...state.trashPhrases,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadTrashPhrases.success,
    (
      state: ScenariosExamplesState,
      { payload: { phrases, total } }: ActionType<typeof actions.loadTrashPhrases.success>,
    ): ScenariosExamplesState => ({
      ...state,
      trashPhrases: {
        ...state.trashPhrases,
        total,
        phrases,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadTrashPhrases.failure,
    (state: ScenariosExamplesState): ScenariosExamplesState => ({
      ...state,
      trashPhrases: {
        ...state.trashPhrases,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadAllExamples.success,
    (
      state: ScenariosExamplesState,
      { payload: examplesMeta }: ActionType<typeof actions.loadAllExamples.success>,
    ): ScenariosExamplesState => {
      let currentExamplesCopy: Record<string, ExampleData> = {};

      Object.keys(examplesMeta).forEach(slug => {
        currentExamplesCopy[slug] = {
          list: [],
          loading: false,
          deleting: false,
        };

        currentExamplesCopy[slug].totalAdditional = examplesMeta[slug].totalAddition;
        currentExamplesCopy[slug].totalException = examplesMeta[slug].totalException;
      });

      return {
        ...state,
        examples: currentExamplesCopy,
      };
    },
  )
  .handleAction(
    actions.deleteExamples.request,
    (
      state: ScenariosExamplesState,
      { payload }: ActionType<typeof actions.deleteExamples.request>,
    ): ScenariosExamplesState => updateExamplesOnDelete(state, payload, 'request'),
  )
  .handleAction(
    actions.deleteExamples.success,
    (
      state: ScenariosExamplesState,
      { payload }: ActionType<typeof actions.deleteExamples.success>,
    ): ScenariosExamplesState => updateExamplesOnDelete(state, payload, 'success'),
  )
  .handleAction(
    actions.deleteExamples.failure,
    (
      state: ScenariosExamplesState,
      { payload }: ActionType<typeof actions.deleteExamples.failure>,
    ): ScenariosExamplesState => updateExamplesOnDelete(state, payload, 'failure'),
  )
  .handleAction(
    actions.openAllExamplesModal,
    (state: ScenariosExamplesState): ScenariosExamplesState => ({
      ...state,
      allExamplesModal: {
        ...state.allExamplesModal,
        opened: true,
      },
    }),
  )
  .handleAction(
    actions.closeAllExamplesModal,
    (state: ScenariosExamplesState): ScenariosExamplesState => ({
      ...state,
      allExamplesModal: {
        ...state.allExamplesModal,
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.openScenarioExamplesModal,
    (
      state: ScenariosExamplesState,
      { payload: { slug, type } }: ActionType<typeof actions.openScenarioExamplesModal>,
    ): ScenariosExamplesState => ({
      ...state,
      scenarioExamplesModal: {
        ...state.scenarioExamplesModal,
        opened: true,
        activeScenario: slug,
        defaultType: type,
      },
    }),
  )
  .handleAction(
    actions.closeScenarioExamplesModal,
    (state: ScenariosExamplesState): ScenariosExamplesState => ({
      ...state,
      scenarioExamplesModal: {
        ...state.scenarioExamplesModal,
        activeScenario: "",
        opened: false,
      },
    }),
  )
  .handleAction(
    actions.toggleExampleReferenceStatus.success,
    (
      state: ScenariosExamplesState,
      { payload }: ActionType<typeof actions.toggleExampleReferenceStatus.success>,
    ): ScenariosExamplesState => (
      updateExample(
        state,
        payload.scenarioId,
        payload.exmaple.id,
        example => ({
          ...example,
          ...payload.exmaple,
        }),
      )
    ),
  )
  .handleAction(
    actions.checkDirtyExamplesSearchAvailable.success,
    (
      state: ScenariosExamplesState,
      { payload }: ActionType<typeof actions.checkDirtyExamplesSearchAvailable.success>,
    ): ScenariosExamplesState => ({
      ...state,
      dirtyExamplesSearchAvailable: payload,
    }),
  )
  .handleAction(
    searchForDirtyExamples.success,
    (state: ScenariosExamplesState): ScenariosExamplesState => ({
      ...state,
      dirtyExamplesSearchAvailable: false,
    }),
  );
