import { Bind, Node } from "../../pages/graphEditor/types";
import { UserMessageAndNodeMatch } from "./reducers";

type NodeLinks = Record<string, Bind[]>;
type ConvertIdsArrayToObject = (arr: string[], b?: boolean) => NodeLinks;

export const convertIdsArrayToObject: ConvertIdsArrayToObject = (nodeIds: Node['id'][]) => {
  const result: NodeLinks = {};

  nodeIds.forEach((nodeId, i, arr) => {
    const removeIndex = (str: string) => str.split('_')[0] || '';

    const from = removeIndex(nodeId);
    const to = removeIndex(arr[i + 1] || '');

    result[nodeId] = (result[nodeId] || []).concat({ from, to });
  });

  return result;
};

export const getNewMessageAndNodeMatches = ({
  currentMatches,
  nodeIds,
  userMessageId,
}: {
  currentMatches: UserMessageAndNodeMatch[],
  nodeIds: string[],
  userMessageId: string,
}): UserMessageAndNodeMatch[] => {
  // При отправке сообщения бэк возвращает стек из id нод.
  // Сообщение может быть связано с одной или несколькими нодами,
  // или не быть связанным совсем (пустые сообщения с бэка).
  // Также стоит заложить переспросы - несколько одинаковых nodeId для одного сообщения
  // Таким образом, их сопоставление реализуется через full-join, все со всеми.
  //
  const prevNodeIds = currentMatches.filter(({ nodeId }) => !!nodeId);
  const newNodeIds = nodeIds.slice(prevNodeIds.length);

  if (!newNodeIds.length) {
    return [{
      userMessageId,
      nodeId: null,
    }];
  }

  return newNodeIds.map(
    nodeId => ({
      userMessageId,
      nodeId,
    }),
  );
};
