import i18n from '../../services/i18n';

import {
  AuthorizationType,
  BasicAuth,
  TokenAuth,
  TvmAuth,
  Integration,
  HttpRequestType,
  IntegrationLabel,
} from '../../types/models/Integrations';

export type Validation = {
  isValid: boolean;
  errors: string[];
}

// eslint-disable-next-line no-shadow
export enum FileSource {
  lastUserMessage = 'last_user_message',
  custom = 'custom',
  unset = 'unset',
}

export const defaultFileURL = "{{last_user_files_urls[-1]}}";
export const defaultContentType = "{{last_user_files_content_types[-1]}}";

export function validateIntegration(value: Integration): Validation {
  const result: Validation = {
    isValid: true,
    errors: [],
  };

  const addError = (message: string) => {
    result.isValid = false;
    result.errors.push(message);
  };

  if (!value.apiRequest.url) {
    addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.URL_EMPTY"));
  }
  if (!value.title) {
    addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.TITLE_EMPTY"));
  }

  const authType = value.apiRequest.authorization.type;

  switch (authType) {
    case AuthorizationType.tvm: {
      const auth = value.apiRequest.authorization as TvmAuth;
      if (!auth.dstServiceName?.trim() && !auth.userOauthToken?.trim()) {
        addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.SERVICE_AND_TOKEN_EMPTY"));
      }
      break;
    }
    case AuthorizationType.basic: {
      const auth = value.apiRequest.authorization as BasicAuth;
      if (!auth.login) {
        addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.LOGIN_EMPTY"));
      }
      if (!auth.password) {
        addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.PASSWORD_EMPTY"));
      }
      break;
    }
    case AuthorizationType.token: {
      const auth = value.apiRequest.authorization as TokenAuth;
      if (!auth.header) {
        addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.HEADER_EMPTY"));
      }
      if (!auth.token) {
        addError(i18n.t("PAGE_INTEGRATIONS.ERRORS.TOKEN_EMPTY"));
      }
      break;
    }
    default:
      /** Nothing to do */
      break;
  }

  return result;
}

export function bodyAllowed(method: Integration['apiRequest']['method']) {
  return method !== HttpRequestType.DELETE && method !== HttpRequestType.HEAD;
}

export function sortIntegrationLabels(
  labelA: IntegrationLabel,
  labelB: IntegrationLabel,
) {
  return labelA.title.toUpperCase() > labelB.title.toUpperCase() ? 1 : -1;
}

export function getAuthLabel(type: AuthorizationType): string {
  switch (type) {
    case AuthorizationType.basic:
      return i18n.t("PAGE_INTEGRATIONS.AUTH_BY_LOGIN");
    case AuthorizationType.token:
      return i18n.t("PAGE_INTEGRATIONS.AUTH_BY_TOKEN");
    case AuthorizationType.noAuth:
      return i18n.t("PAGE_INTEGRATIONS.NO_AUTH");
    case AuthorizationType.tvm:
      return i18n.t("PAGE_INTEGRATIONS.AUTH_BY_TVM");
    default:
      return "";
  }
}
