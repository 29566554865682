import { FC, memo, useCallback } from 'react';

import { IpAddress } from '../../types/models/ProjectAuthData';
import { checkIpAddress } from '../../services/helpers/utilities';
import Input, { OnChangeFunc } from '../../components-new/input';
import Button from '../../components-new/button';

import { b } from '.';

type Props = {
  data: IpAddress;
  error: boolean;
  autoFocus?: boolean;
  onChange: (data: IpAddress, valid: boolean) => void;
  onDelete: (id: string) => void;
};

const IpAddressInput: FC<Props> = ({
  data: { id, value },
  error,
  autoFocus,
  onChange,
  onDelete,
}) => {
  const handleChange: OnChangeFunc = useCallback(
    (_, { value: newValue }) => {
      const [ipAdress, urlPath] = newValue.split('/');
      const ipAdressValid = checkIpAddress(ipAdress);
      // Мы хотим уметь сохранять такие урлы как 0.0.0.0/0 (но не 0.0.0.0/0/0, требование бэка)
      // Т.к. получается какая-то очень специфическая проверка, предлагаю
      // вынести её прямо сюда, чем выносить в какие-то общие утилиты, т.к. проверка не универсальная
      // eslint-disable-next-line no-useless-escape
      const pathValid = /^[a-z0-9-_\.]*$/gi.test(urlPath || "");

      onChange({ id, value: newValue }, ipAdressValid && pathValid);
    },
    [id, onChange],
  );

  const handleDelete = useCallback(
    () => onDelete(id),
    [id, onDelete],
  );

  return (
    <div className={b('ip-address')}>
      <Input
        fluid
        autoFocus={autoFocus}
        name="title"
        error={error}
        value={value}
        placeholder="255.255.255.255"
        onChange={handleChange}
      />
      <Button
        icon="trash"
        size="mini"
        simple
        contrast
        onClick={handleDelete}
      />
    </div>
  );
};

export default memo(IpAddressInput);
