import { createAction, createAsyncAction } from "typesafe-actions";
import { PreviousAnswer, PreviousAnswerDialog, PreviousAnswerFilters } from "../../types/models/PreviousAnswer";
import { ErrorAction } from "../reducers";
import { Task } from "../../types/models/Task";

export const setFilters = createAction(
  "previousAnswers/setFilters",
)<PreviousAnswerFilters>();

export const resetFilters = createAction(
  "previousAnswers/resetFilters",
)();

export const setActiveDialog = createAction(
  "previousAnswers/setActiveDialog",
)<string | null>();

export const loadPreviousAnswers = createAsyncAction(
  "previousAnswers/loadPreviousAnswers/requested",
  "previousAnswers/loadPreviousAnswers/succeeded",
  "previousAnswers/loadPreviousAnswers/failed",
)<
  undefined,
  PreviousAnswer[],
  ErrorAction
>();

export const loadPreviousAnswerDialogs = createAsyncAction(
  "previousAnswers/loadPreviousAnswerDialogs/requested",
  "previousAnswers/loadPreviousAnswerDialogs/succeeded",
  "previousAnswers/loadPreviousAnswerDialogs/failed",
)<
  { id: PreviousAnswer['id'], offset?: number },
  PreviousAnswerDialog[],
  ErrorAction
>();

export const loadExportHistory = createAsyncAction(
  "previousAnswers/loadExportHistory/requested",
  "previousAnswers/loadExportHistory/succeeded",
  "previousAnswers/loadExportHistory/failed",
)<
  { offset?: number },
  Task[],
  ErrorAction
>();
