import { SvgProps } from "../types";

function CheckmarkRoundedIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM16.66 8.251C16.4605 8.07668 16.1999 7.98867 15.9356 8.0063C15.6712 8.02392 15.4246 8.14574 15.25 8.345L10.733 13.8L8.737 11.325C8.5554 11.14 8.30908 11.0328 8.04997 11.026C7.79085 11.0191 7.5392 11.1131 7.34805 11.2882C7.1569 11.4632 7.0412 11.7057 7.02533 11.9644C7.00946 12.2231 7.09468 12.4779 7.263 12.675L9.636 15.5C9.90455 15.7934 10.272 16.0335 10.733 16.0335C11.2565 16.0335 11.7881 15.7493 12 15.5C12.2119 15.2507 16.754 9.662 16.754 9.662C16.9284 9.4626 17.0166 9.20212 16.9992 8.93776C16.9817 8.6734 16.8601 8.42676 16.661 8.252L16.66 8.251Z"
        fill={color}
      />
    </svg>
  );
}

export default CheckmarkRoundedIcon;
