import { ResolutionType } from "./Resolution";

export type StatsByDayPlotItem = {
  date: string;
  resolutionsStats: ResolutionStatsItem[];
}

export type ResolutionStatsItem = {
  resolutionTypeId: string;
  count: number;
}

export type SamplingStats = {
  samplingId: string;
  samplingTitle: string;
  samplingCreatedAt: string;
  samplingSize: number;
  stats: ResolutionStatsItem[];
}

export const RESOLUTION_TYPE_ERROR_TITLES = [
  'Ошибка интеграции',
  'Ошибка модели',
  'Ошибка правила',
];

export const RESOLUTION_TYPE_OK_TITLES = [
  'ОК',
];

export function getTotalMarkedCount(
  stats: SamplingStats,
): number {
  return stats.stats.reduce((res, s) => res + s.count, 0);
}

export function getMarkedCount(
  stats: SamplingStats,
  resolutionIds: Array<ResolutionType['id']>,
): number {
  return stats.stats.reduce(
    (res, item) => resolutionIds.includes(item.resolutionTypeId) ?
      res + item.count :
      res,
    0,
  );
}

export function isPositiveResolution(
  resolution: ResolutionType,
) {
  return RESOLUTION_TYPE_OK_TITLES.includes(resolution.title);
}

export function isNegativeResolution(
  resolution: ResolutionType,
) {
  return RESOLUTION_TYPE_ERROR_TITLES.includes(resolution.title);
}
