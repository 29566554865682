import { SagaIterator } from "redux-saga";
import { call, fork, put, takeLatest } from "redux-saga/effects";

import * as actions from './actions';

import AuthService from '../../services/auth';
import { ApiResponse } from '../../services/api';
import {
  AuthProvidersResponse,
  AuthProviderRequest,
  AuthProviderResponse,
} from "../../types/requests/Authentication";
import { ErrorAction } from "../reducers";
import i18n from "../../services/i18n";
import { AuthProviderMeta } from "../../types/models/AuthProvider";

function* loadAuthProviders(): SagaIterator {
  yield takeLatest(actions.loadAuthProviders.request, function* () {
    const localStorageProvider = localStorage.getItem('authProvider');

    if (localStorageProvider) {
      yield put(actions.setCurrentAuthProvider(JSON.parse(localStorageProvider)));
    }

    try {
      const response: ApiResponse<AuthProvidersResponse> = yield call(AuthService.getProviders);
      const { data } = response;
      yield put(actions.loadAuthProviders.success(data.providers));
    } catch (err) {
      yield put(
        actions.loadAuthProviders.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS") })),
        ),
      );
    }
  });
}

function* loadAllAuthProviders(): SagaIterator {
  yield takeLatest(actions.loadAllAuthProviders.request, function* () {
    const localStorageProvider = localStorage.getItem('authProvider');

    if (localStorageProvider) {
      yield put(actions.setCurrentAuthProvider(JSON.parse(localStorageProvider)));
    }

    try {
      const response: ApiResponse<AuthProvidersResponse> = yield call(AuthService.getProviders, false);
      const { data } = response;
      yield put(actions.loadAllAuthProviders.success(data.providers));
    } catch (err) {
      yield put(
        actions.loadAllAuthProviders.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS") })),
        ),
      );
    }
  });
}

function* loadAuthProvider(): SagaIterator {
  yield takeLatest(actions.loadAuthProvider.request, function* (action) {
    const params: AuthProviderRequest = {
      name: action.payload,
    };

    try {
      const response: ApiResponse<AuthProviderResponse> = yield call(
        AuthService.getProvider,
        params,
      );
      yield put(actions.loadAuthProvider.success(response.data.provider));
      localStorage.setItem('authProvider', JSON.stringify(response.data.provider));
    } catch (err) {
      yield put(
        actions.loadAuthProvider.failure(
          new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.AUTH_PROVIDERS") })),
        ),
      );
    }
  });
}

function* storeCurrentAuthProvider(): SagaIterator {
  yield takeLatest(actions.setCurrentAuthProvider, function(action) {
    const authProvider: AuthProviderMeta = action.payload;
    localStorage.setItem('authProvider', JSON.stringify(authProvider));
  });
}

export default function* authProvidersSagas(): SagaIterator {
  yield fork(loadAuthProviders);
  yield fork(loadAllAuthProviders);
  yield fork(loadAuthProvider);
  yield fork(storeCurrentAuthProvider);
}
