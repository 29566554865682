import { createReducer, ActionType } from "typesafe-actions";
import { Reducer } from 'redux';

import { SavedUser } from "../../types/models/UserAccesses";
import * as actions from './actions';

type SuperusersState = {
  nonSuperusers: {
    list: SavedUser[],
    loading: boolean,
  },
  superusers: {
    list: SavedUser[],
    loading: boolean,
  }
};

const initialState: SuperusersState = {
  nonSuperusers: {
    list: [],
    loading: false,
  },
  superusers: {
    list: [],
    loading: false,
  },
};

type S = SuperusersState;
export const superusersReducer: Reducer<S> = createReducer<S>(initialState)
  .handleAction(
    actions.loadSuperUsers.request,
    (state: SuperusersState): SuperusersState => ({
      ...state,
      nonSuperusers: {
        ...state.nonSuperusers,
        loading: true,
      },
      superusers: {
        ...state.superusers,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadSuperUsers.success,
    (
      state: SuperusersState,
      { payload }: ActionType<typeof actions.loadSuperUsers.success>,
    ): SuperusersState => ({
      ...state,
      nonSuperusers: {
        list: payload?.filter(({ isSuperadmin }) => !isSuperadmin),
        loading: false,
      },
      superusers: {
        list: payload?.filter(({ isSuperadmin }) => !!isSuperadmin),
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadSuperUsers.failure,
    (state: SuperusersState): SuperusersState => ({
      ...state,
      nonSuperusers: {
        ...state.nonSuperusers,
        loading: false,
      },
      superusers: {
        ...state.superusers,
        loading: false,
      },
    }),
  );
