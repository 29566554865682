import { notificationErrors } from "../messages/notifications";
import { projectEmpty } from "../types/models/Project";
import { ProjectRequestParams } from "../types/requests/Projects";
import { AppState } from "./reducers";
import i18n from '../services/i18n';

export const getGeneralRequestParams = (
  state: AppState,
): ProjectRequestParams => {
  const { selectedProject } = state.projects;
  if (projectEmpty(selectedProject)) {
    throw new Error(i18n.t(notificationErrors.noSelectedProject));
  }

  return {
    project_id: selectedProject.id,
    project_slug: selectedProject.slug,
  };
};

export function getChartStatistics(state: AppState) {
  return state.projects.chartStatistics;
}

export function getMetricsTableStatistics(state: AppState) {
  return state.projects.tableTopicsStatistics;
}
