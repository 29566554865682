import React from "react";

import { SvgProps } from "..";

const BugIcon = ({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 5.06152C7 5.56892 5 9.15028 5 13.4999C5 17.8496 7 21.431 11 21.9384V5.06152ZM13 21.9384C17 21.431 19 17.8496 19 13.4999C19 9.15028 17 5.56892 13 5.06152V21.9384Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4649 8C15.8052 7.41165 16 6.72857 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 6.72857 8.19479 7.41165 8.53513 8H15.4649Z"
          fill={color}
        />
        <path
          d="M16 8.5L17.5 11L21 9L22 4H21.8188C21.0187 4 20.2957 4.47679 19.9805 5.21216L19 7.5L16 8.5Z"
          fill={color}
        />
        <path
          d="M16 17.5L17.5 15L21 17L22 22H21.8188C21.0187 22 20.2957 21.5232 19.9805 20.7878L19 18.5L16 17.5Z"
          fill={color}
        />
        <path
          d="M8 8.5L6.5 11L3 9L2 4H2.18121C2.98126 4 3.70434 4.47679 4.0195 5.21216L5 7.5L8 8.5Z"
          fill={color}
        />
        <path
          d="M8 17.5L6.5 15L3 17L2 22H2.18121C2.98126 22 3.70434 21.5232 4.0195 20.7878L5 18.5L8 17.5Z"
          fill={color}
        />
        <path
          d="M1 12.5L6 12V14.5L1 14V12.5Z"
          fill={color}
        />
        <path
          d="M23 12.5L18 12V14.5L23 14V12.5Z"
          fill={color}
        />
      </g>
    </svg>

  );
};

export default BugIcon;
