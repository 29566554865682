import React from 'react';

import { SvgProps } from '../types';

function BookshelfIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2C9.22386 2 9 2.22386 9 2.5V18.5C9 18.7761 9.22386 19 9.5 19H11.5C11.7761 19 12 18.7761 12 18.5V2.5C12 2.22386 11.7761 2 11.5 2H9.5ZM13.104 3.93902C13.0465 3.66892 13.219 3.40341 13.4891 3.346L16.4235 2.72226C16.6936 2.66485 16.9591 2.83727 17.0165 3.10738L20.1352 17.7796C20.1926 18.0497 20.0202 18.3152 19.7501 18.3726L16.8157 18.9964C16.5456 19.0538 16.28 18.8813 16.2226 18.6112L13.104 3.93902ZM4.5 3C4.22386 3 4 3.22386 4 3.5V18.5C4 18.7761 4.22386 19 4.5 19H7.5C7.77614 19 8 18.7761 8 18.5V3.5C8 3.22386 7.77614 3 7.5 3H4.5ZM22 22V20H2V22H22Z"
        fill={color}
      />
    </svg>
  );
}

export default BookshelfIcon;
