import { SagaIterator, Task } from "redux-saga";
import {
  call, fork, put, takeLatest, select, all, race, take, delay, cancel, PutEffect,
} from "redux-saga/effects";
import { replace } from "connected-react-router";

import NotificationManager from "../../services/notifications";
import Api, { ApiResponse } from '../../services/api';
import {
  CloneDialogRequest,
  CloneDialogResponse,
  DialogsHistoryRequest,
  DialogsHistoryResponse,
  GetChatInfoRequest,
  GetChatInfoResponse,
  SearchDialogsHistoryRequest,
  SearchDialogsHistoryResponse,
} from "../../types/requests/Dialogs";
import { parseDialog, parseDialogButtons, parseNewDialog } from "../../types/parsers/DialogParser";
import { MessageAuthor } from "../../types/models/Message";
import { SendBotDialogMessageRequest, SendBotDialogMessageResponse } from "../../types/requests/BotDialog";
import { AttachmentBackend, ExtendedDialogBackend, SupportRequestBackend } from "../../types/backendModels/DialogBackend";
import {
  getDialogHistorySearchFilter,
  getDispatchFeatures,
  getLiveChatId,
  getNewDialogHistorySearchFilter,
} from "./selectors";
import { generateId } from "../../services/helpers/generateId";
import {
  DialogsHistorySearchFilter,
  DialogStatus,
  isRequestMessage,
  RequestMessage,
  SearchTextLocation,
} from "../../types/models/Dialog";
import * as actions from './actions';
import * as graphChatActions from '../graphChat/actions';
import * as versionsActions from '../versions/actions';
import * as graphDebugActions from '../graphDebug/actions';
import * as projectActions from '../projects/actions';
import * as filesActions from '../files/actions';
import * as samplingsActions from '../samplings/actions';
import { Feature, FeatureType } from "../../types/models/Feature";
import { getFeatures } from "../features/selectors";
import { parseFeatureValueByType } from "../../types/parsers/FeatureParser";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import { CreateTaskRequest } from "../../types/requests/Task";
import { TaskType } from "../../types/models/Task";
import modalService from "../../services/modal";
import { GetGraphDebugByIdRequest, GetGraphDebugByIdResponse } from "../../types/requests/Debug";
import i18n from "../../services/i18n";
import { AppState, ErrorAction } from "../reducers";
import { GetCurrentVersionResponse } from "../../types/requests/Versions";
import { VersionStatus } from "../../types/models/Version";
import { CHECK_VERSION_INTERVAL } from "../versions/sagas";
import { getFilenameWithoutExtension, objCamelToSnakeDeep, onlyUnique } from "../../services/helpers/utilities";
import { getSamplingsState } from "../samplings/selectors";
import apiService from "../../services/api";
import { parseGraphDebugBlock } from "../../types/parsers/GraphDebugBlockParser";
import { defaultFilters } from "./reducer";
import { getAudioFiles } from "../files/selectors";
import { FilesState } from "../files/reducer";
import { isVoiceProject, Project } from "../../types/models/Project";
import { getSelectedProject } from "../projects/selectors";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";
import { msToSeconds } from "../projects/helpers";
import { NotificationMode } from "../../types/models/Notification";
import { appendButtons, fillMacroses, fillMacrosesNewDialogs, findMacroses, findMacrosesInNewDialog, getMacrosesTexts } from "./helpers";
import { parseFiltersToBackend } from "../../types/parsers/FilterParser";

const LOADING_VERSION_MESSAGE_TIMER: number = 5000;
const MAX_REQUEST_LENGTH = 10000;

function* loadDialogsHistory() {
  yield takeLatest(actions.loadDialogsHistory.request, function* (action) {
    try {
      const {
        dateFrom,
        dateTo,
        olderThan,
        mode,
        resolutionOld,
        limit = 10,
        chatId,
        chatUser,
        samplingDeprecated: sampling,
        iterations,
        version,
        tags,
        scenarios,
        maxUserScore,
        minUserScore,
        status,
      } = action.payload;

      const params: DialogsHistoryRequest = {
        start: msToSeconds(+dateLocalToMsc(new Date(dateFrom))),
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
        ...(limit && { limit }),
        ...(dateTo && { end: msToSeconds(+dateLocalToMsc(new Date(dateTo))) }),
        ...(typeof mode === 'boolean' && { simulated: mode }),
        ...(chatId && { chat_id: chatId }),
        ...(chatUser && { chat_user: chatUser }),
        ...(sampling && { sampling_slug: sampling.slug }),
        ...(iterations && { iterations }),
        ...(version && { version }),
        ...(version && { version }),
        ...(tags && { tags }),
        ...(Array.isArray(scenarios) && scenarios.length > 0 && {
          scenario_slugs: scenarios.map(s => s.slug),
        }),
        ...(typeof maxUserScore === 'number' && { max_user_score: maxUserScore }),
        ...(typeof minUserScore === 'number' && { min_user_score: minUserScore }),
        ...(!!resolutionOld && { sampling_resolution: resolutionOld }),
        ...parseDialogStatusParams(status),
      };

      if (params.sampling_slug) {
        delete params.start;
        delete params.end;
      }

      const project: Project = yield select(getSelectedProject);
      const checkAudioFiles: FilesState['audioFiles'] = yield select(getAudioFiles);

      if (!checkAudioFiles.wasLoaded &&
        isVoiceProject(project)
      ) {
        yield take(filesActions.loadAudioFiles.success);
      }

      const response: ApiResponse<DialogsHistoryResponse> = yield call(
        Api.getDialogsHistory,
        params,
      );

      let dialogs = (response.data.contexts || []).map(bDialog => parseDialog(bDialog));

      const macroses = dialogs.map(findMacroses)
        .flat()
        .filter(onlyUnique);

      if (macroses.length > 0) {
        const macrosesTexts: Array<{
          macros: string,
          text: string,
        }> = yield call(getMacrosesTexts, macroses);

        dialogs = dialogs.map(dialog => fillMacroses(dialog, macrosesTexts));
      }

      yield put(actions.loadDialogsHistory.success({
        dialogs,
        olderThan: action.payload.olderThan,
        total: response.data.total,
      }));
    } catch (err) {
      yield put(actions.loadDialogsHistory.failure(new ErrorAction(err, i18n.t("ERRORS.API.DIALOGS.HISTORY_LOAD"))));
    }
  });
}

function* searchDialogsHistory() {
  yield takeLatest(actions.searchDialogsHistory.request, function* (action) {
    try {
      const {
        dateFrom,
        dateTo,
        mode,
        limit = 10,
        chatIds,
        chatUser,
        version,
        tags,
        searchText,
        searchTextLocation,
        offset,
        scenarios,
        status,
        minUserScore,
        maxUserScore,
        iterations,
        tagsOperator,
        resolution,
        newSampling,
      } = action.payload;

      const searchLocation = (
        searchTextLocation.includes(SearchTextLocation.request) &&
        searchTextLocation.includes(SearchTextLocation.response)
      ) ?
        SearchTextLocation.both :
        searchTextLocation[0];

      const params: SearchDialogsHistoryRequest = {
        query: {
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(resolution && { resolution_type: resolution }),
        },
        body: {
          start_dt: (dateLocalToMsc(new Date(dateFrom))).toISOString(),
          ...(newSampling && parseFiltersToBackend(newSampling.filters)),
          ...(dateTo && { end_dt: (dateLocalToMsc(new Date(dateTo))).toISOString() }),
          ...(typeof mode === 'boolean' && { simulated: mode }),
          ...(chatIds && { chat_ids: chatIds.map(id => id.slice(0, MAX_REQUEST_LENGTH)) }),
          ...(chatUser && { chat_user: chatUser.slice(0, MAX_REQUEST_LENGTH) }),
          ...(version && { version }),
          ...(tags?.length && { tags, tags_operator: tagsOperator }),
          ...(searchText && { search_text: searchText.slice(0, MAX_REQUEST_LENGTH) }),
          ...(Array.isArray(scenarios) && scenarios.length > 0 && {
            scenarios: scenarios.map(s => s.slug),
          }),
          ...parseDialogStatusParams(status),
          ...(minUserScore && { min_csat: minUserScore }),
          ...(maxUserScore && { max_csat: maxUserScore }),
          ...(iterations && {
            min_iteration: Number(iterations.slice(0, MAX_REQUEST_LENGTH)),
            max_iteration: Number(iterations.slice(0, MAX_REQUEST_LENGTH)),
          }),
          search_text_location: searchLocation,
        },
      };

      const project: Project = yield select(getSelectedProject);
      const checkAudioFiles: FilesState['audioFiles'] = yield select(getAudioFiles);

      if (!checkAudioFiles.wasLoaded &&
        isVoiceProject(project)
      ) {
        if (!checkAudioFiles.loading) {
          yield put(filesActions.loadAudioFiles.request());
        }
        yield take(filesActions.loadAudioFiles.success);
      }

      const response: ApiResponse<SearchDialogsHistoryResponse> = yield call(
        Api.getDialogsHistoryV3,
        params,
      );

      let dialogs = (response.data.dialogs || []).map(bDialog => parseNewDialog(bDialog));

      const macroses = dialogs.map(findMacrosesInNewDialog)
        .flat()
        .filter(onlyUnique);

      if (macroses.length > 0) {
        const macrosesTexts: Array<{
          macros: string,
          text: string,
        }> = yield call(getMacrosesTexts, macroses);

        dialogs = dialogs.map(dialog => fillMacrosesNewDialogs(dialog, macrosesTexts));
      }

      yield put(actions.searchDialogsHistory.success({
        dialogs,
        offset,
        total: response.data.total,
      }));
    } catch (err) {
      yield put(actions.searchDialogsHistory.failure(new ErrorAction(err, i18n.t("ERRORS.API.DIALOGS.HISTORY_LOAD"))));
    }
  });
}

function* loadDialogById(): SagaIterator {
  yield takeLatest(actions.loadDialogBySessionId.request, function* ({
    payload: { sessionId },
  }) {
    try {
      const request: GetChatInfoRequest = {
        query: {
          session_id: sessionId,
        },
      };

      const { data }: ApiResponse<GetChatInfoResponse> = yield call(
        apiService.getChatInfo,
        request,
      );

      let dialog = parseNewDialog(data);

      const macroses = findMacrosesInNewDialog(dialog)
        .flat()
        .filter(onlyUnique);

      if (macroses.length > 0) {
        const macrosesTexts: Array<{
          macros: string,
          text: string,
        }> = yield call(getMacrosesTexts, macroses);

        dialog = fillMacrosesNewDialogs(dialog, macrosesTexts);
      }
      yield put(actions.loadDialogBySessionId.success({ dialog }));
    } catch (err) {
      yield put(actions.closeDialog());
      yield put(actions.loadDialogBySessionId.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DIALOGS.HISTORY_LOAD"),
      )));
    }
  });
}

export function parseDialogStatusParams(status: DialogStatus | null) {
  switch (status) {
    case DialogStatus.notAnswered:
      return {
        is_hidden: false,
        replied: false,
      };
    case DialogStatus.withErrors:
      return {
        is_hidden: false,
        with_errors: true,
      };
    default:
      return {};
  }
}

function* updateSamplingOnSearch() {
  yield takeLatest(actions.loadDialogsHistory.request, function* (action) {
    const { samplingDeprecated: sampling } = action.payload;

    const samplingsState: AppState['samplings']['list'] = yield select(getSamplingsState);

    const samplingExist = samplingsState.value.some(s => s.slug === sampling?.slug);

    if (sampling && !samplingsState.loading && !samplingExist) {
      yield put(actions.setDialogsHistoryFilter({
        ...action.payload,
        samplingDeprecated: null,
      }));
    }
  });
}

function* updateFilters() {
  yield takeLatest(actions.setDialogsHistoryFilter, function* ({ payload }) {
    if (!payload.load) return;
    yield delay(300);

    yield put(actions.loadDialogsHistory.request(payload));
  });
}

function* updateSearchFilters() {
  yield takeLatest(actions.setSearchDialogsHistoryFilter, function* ({ payload }) {
    if (!payload.load) return;
    yield delay(300);

    yield put(actions.searchDialogsHistory.request(payload));
  });
}

function* sendLiveMessage(): SagaIterator {
  yield takeLatest(actions.sendLiveMessage.request, function* (action) {
    try {
      let text = '';
      let attachments;
      let askFeatures = false;
      let skipRequestMessage = false;
      let checkVersionInterval: number = CHECK_VERSION_INTERVAL;

      if (typeof action.payload === 'string') {
        text = action.payload;
        askFeatures = false;
      } else {
        text = action.payload?.text;
        attachments = action.payload?.attachments;
        askFeatures = !!action.payload?.askFeatures;
        skipRequestMessage = !!action.payload?.skipRequestMessage;
        checkVersionInterval = action.payload?.checkVersionInterval || CHECK_VERSION_INTERVAL;
      }

      let liveChatId: string = yield select(getLiveChatId);
      if (!liveChatId) {
        liveChatId = generateId();
        yield put(actions.setLiveChatId(liveChatId));
      }

      const dispatchValues: { [featureSlug: string]: string } = yield select(getDispatchFeatures);
      const origFeatures: Feature[] = yield select(getFeatures);

      type DispatchValuesKeys = Array<{ key: string, value: string | boolean | number }>;
      const featuresToSend = Object.keys(dispatchValues).reduce<DispatchValuesKeys>(
        (values, featureSlug) => {
          const dispatchValue = dispatchValues[featureSlug];
          const origFeature = origFeatures.find(feature => feature.slug === featureSlug);

          if (dispatchValue.length > 0) {
            values.push({
              key: featureSlug,
              value: parseFeatureValueByType(dispatchValue, origFeature?.type || FeatureType.str),
            });
          }

          return values;
        },
        [],
      );

      const messageId = generateId();
      const request: SupportRequestBackend = {
        chat_id: liveChatId,
        dialog: {
          messages: [
            {
              id: messageId,
              author: MessageAuthor.User,
              text,
              attachments: attachments?.map(item => objCamelToSnakeDeep<AttachmentBackend>(item)),
            },
          ],
        },
        features: featuresToSend,
      };

      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: SendBotDialogMessageRequest = {
        ...general,
        ask_features: askFeatures,
        request,
      };

      const requestMessage = {
        id: messageId,
        chatId: request.chat_id,
        recordId: generateId(),
        author: MessageAuthor.User,
        text,
        createdAt: '',
        attachments,
      };

      if (!skipRequestMessage) {
        yield put(actions.appendLiveMessage(requestMessage));
      }

      const observingTask: Task = yield fork(waitForVersionLoadedStatus, checkVersionInterval);

      const { cancelled, error } = yield race({
        success: take(versionsActions.currentVersionLoaded),
        cancelled: take(actions.preventLiveMessageSending),
        error: take(versionsActions.currentVersionError),
      });

      if (cancelled || error) {
        yield cancel(observingTask);
        yield put(actions.sendLiveMessage.failure(new ErrorAction("")));
        return;
      }

      const response: ApiResponse<SendBotDialogMessageResponse> = yield call(
        Api.sendBotDialogMessage,
        params,
      );

      if (response.data.error) {
        if (response.data.error.type?.toUpperCase() === "TIMEOUT") {
          const message =
            `${i18n.t("ERRORS.API.DIALOGS.ONLINE_TESTING")}: ${i18n.t("ERRORS.API.DIALOGS.ONLINE_TESTING_TIMEOUT")}`;
          throw new Error(message);
        }
        throw new Error(JSON.stringify(response.data.error));
      }

      let {
        buttons_block: buttonBlock,
        requested_features: requestedFeatures,
        graph_positions_stack: graphPositionsStack,
        ...responseData
      } = response.data;

      if (buttonBlock) {
        responseData = appendButtons(responseData, buttonBlock);
      }

      const { debug_id: debugId } = response.data;

      const newGraphPositionsStack = graphPositionsStack?.positions_stack ?
        graphPositionsStack?.positions_stack?.filter(nodeId => !/_/.test(nodeId)) || [] :
        null;

      yield put(graphChatActions.setRequestedFeatures(requestedFeatures?.features ?? null));
      yield put(graphChatActions.setGraphPositionsStack(newGraphPositionsStack));
      yield put(graphChatActions.setButtonsBlock(parseDialogButtons(buttonBlock)));

      yield put(
        actions.saveUserMessage(requestedFeatures?.features?.length ? requestMessage : null),
      );

      if (requestedFeatures?.features?.length) {
        yield put(graphChatActions.updateActiveRequestedFeature());
        yield put(actions.sendLiveMessage.success(response.data));
        return;
      }

      const fakeDialog: ExtendedDialogBackend = {
        chat_id: liveChatId,
        chat_user: liveChatId,
        id: '',
        created_at: '',
        records: [
          {
            id: generateId(),
            request,
            response: responseData,
          },
        ],
      };
      let dialog = parseDialog(fakeDialog);

      const macroses = findMacroses(dialog)
        .flat()
        .filter(onlyUnique);

      if (macroses.length > 0) {
        const macrosesTexts: Array<{
          macros: string,
          text: string,
        }> = yield call(getMacrosesTexts, macroses);

        dialog = fillMacroses(dialog, macrosesTexts);
      }

      if (!skipRequestMessage) {
        yield put(actions.deleteLastLiveMessage());

        const userMessage = dialog.messages?.find(
          message => message.author === MessageAuthor.User,
        );

        if (userMessage?.id) {
          yield put(graphChatActions.updateMessageAndNodeMatches({
            userMessageId: userMessage.id,
            nodeIds: newGraphPositionsStack || [],
          }));
        }
      }

      const messages = dialog.messages.slice(skipRequestMessage ? 1 : 0);
      const audioFiles: FilesState['audioFiles'] = yield select(getAudioFiles);

      for (const message of messages) {
        audioFiles.list.forEach(file =>
          message.texts?.forEach((txt, ind) => {
            if (getFilenameWithoutExtension(file.filename) === txt) {
              //@ts-ignore тк мы бегаем по массиву message, то он не будет undefined
              message.texts[ind] = file.userFilename;
            }
          }),
        );
        yield put(actions.appendLiveMessage(message));
      }

      yield put(actions.sendLiveMessage.success(response.data));

      if (debugId) {
        yield put(graphDebugActions.loadDebugById.request(debugId));
      }
    } catch (err) {
      yield put(actions.sendLiveMessage.failure(new ErrorAction(err, i18n.t("ERRORS.API.DIALOGS.ONLINE_TESTING"))));
    }
  });
}

// Перед отправкой сообщения нужно проверять, обновлена ли версия бота.
// Этот генератор проводит проверку статуса версии, обновляет в случае необходимости и дожидается
// загрузки версии.
function* waitForVersionLoadedStatus(checkInterval: number): SagaIterator {
  let loadingMessageTimer: ReturnType<typeof setTimeout> | undefined;

  while (true) {
    const response: ApiResponse<GetCurrentVersionResponse> = yield call(
      Api.getCurrentVersion,
    );

    const { status } = response.data;

    if (status === VersionStatus.error) {
      const errorDescription = i18n.t("ERRORS.API.DIALOGS.VERSION_SAVE");

      NotificationManager.error(errorDescription);
      if (loadingMessageTimer) {
        clearTimeout(loadingMessageTimer);
      }

      yield put(versionsActions.currentVersionError({
        description: errorDescription,
      }));
      return false;
    }

    if ((status === VersionStatus.loading || status === VersionStatus.needToReload) &&
        !loadingMessageTimer) {
      loadingMessageTimer = setTimeout(() => {
        NotificationManager.info(
          i18n.t("PAGE_ONLINE_DIALOG.VERSION_IS_LOADING"),
          { mode: NotificationMode.default, durationMs: 10000 },
        );
      }, LOADING_VERSION_MESSAGE_TIMER);
    }

    // fallback, статуса needToReload не будет в новых версиях
    if (status === VersionStatus.needToReload) {
      yield put(versionsActions.reloadCurrentVersionStatus.request());
    }

    if (status === VersionStatus.loaded) {
      if (loadingMessageTimer) {
        clearTimeout(loadingMessageTimer);
      }

      yield put(versionsActions.currentVersionLoaded());
      return true;
    }

    yield delay(checkInterval);
  }
}

// Вместе с клонированием просиходит переход на страницу диалога с ботом
function* cloneDialog(): SagaIterator {
  yield takeLatest(actions.cloneDialog.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: CloneDialogRequest = {
        ...general,
        chat_id: action.payload.chatId,
        new_chat_id: action.payload.newChatId,
        max_record_id: action.payload.lastRecordId,
        clone_count: action.payload.cloneCount,
      };

      const response: ApiResponse<CloneDialogResponse> = yield call(Api.cloneDialog, params);

      const dialog = parseDialog(response.data);

      let lastRequestMessage;
      for (let i = dialog.messages.length - 1; i >= 0; i--) {
        if (isRequestMessage(dialog.messages[i])) {
          lastRequestMessage = dialog.messages[i];
          break;
        }
      }

      let liveFeaturesDispatchValues;
      if (lastRequestMessage) {
        type LiveFeature = { featureSlug: string, dispatchValue: number | string };
        liveFeaturesDispatchValues = (lastRequestMessage as RequestMessage).features?.map<LiveFeature>(
          ({ key, value }) => ({ featureSlug: key, dispatchValue: value as (string | number) }),
        );
      }

      const actionsToDispatch: PutEffect[] = [
        put(actions.cloneDialog.success(dialog.messages)),
        put(actions.setLiveChatMessages(dialog.messages)),
        put(actions.setLiveChatId(dialog.id)),
      ];

      if (liveFeaturesDispatchValues) {
        actionsToDispatch.push(put(actions.setFeatureDispatchValues(liveFeaturesDispatchValues)));
      }

      yield all(actionsToDispatch);
    } catch (err) {
      yield put(actions.cloneDialog.failure(new ErrorAction(err, i18n.t("ERRORS.API.DIALOGS.CLONE"))));
    }
  });
}

function* handleExportDialogsHistory() {
  yield takeLatest(actions.exportDialogsHistory.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const { modalId, filters, title } = action.payload;
      const params: CreateTaskRequest = {
        ...general,
        type: TaskType.exportDialogHistoryContext,
        params: {
          start: msToSeconds(+filters.dateFrom),
          ...(filters.dateTo && { end: msToSeconds(+filters.dateTo) }),
          ...(filters.samplingDeprecated && { sampling_slug: filters.samplingDeprecated.slug }),
          ...(typeof filters.mode === 'boolean' && { simulated: filters.mode }),
          ...(filters.chatId && { chat_id: filters.chatId }),
          ...(filters.chatUser && { chat_user: filters.chatUser }),
          ...(filters.tags && { tags: filters.tags }),
          ...(filters.scenarios && { scenario_slugs: filters.scenarios.map(s => s.slug) }),
          ...(typeof filters.minUserScore === 'number' && { min_user_score: filters.minUserScore }),
          ...(typeof filters.maxUserScore === 'number' && { max_user_score: filters.maxUserScore }),
          ...(filters.resolution && { sampling_resolution: filters.resolution }),
          ...parseDialogStatusParams(filters.status),
        },
        ...(title && { task_description: title }),
      };

      if (filters.samplingDeprecated) {
        delete params.params?.start;
        delete params.params?.end;
      }

      yield call(Api.createExportExcelTask, params);
      modalService.close(modalId || '');
      yield put(actions.closeExportDialogsModal());
      yield put(actions.openHistoryExportDialogsModal());
      yield put(actions.exportDialogsHistory.success());
    } catch (err) {
      yield put(actions.exportDialogsHistory.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE"))));
    }
  });
}

function* loadDialogNodesDebug(): SagaIterator {
  yield takeLatest(actions.loadDebugBlocksById.request, function* ({
    payload: { debugId, isLinkToGraph = true },
  }) {
    try {
      const request: GetGraphDebugByIdRequest = {
        query: {
          debug_id: debugId,
          is_link_to_graph: isLinkToGraph,
        },
      };

      const { data, status }: ApiResponse<GetGraphDebugByIdResponse> = yield call(
        apiService.getGraphDebugById,
        request,
      );

      if (status === 204) {
        yield put(actions.loadDebugBlocksById.success({ debugId, debugBlocks: null }));
        return;
      }

      let blocks = (data.blocks || []).map(parseGraphDebugBlock);

      yield put(actions.loadDebugBlocksById.success({ debugId, debugBlocks: blocks }));
    } catch (err) {
      yield put(actions.loadDebugBlocksById.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.DIALOGS.DEBUG_LOAD"),
        { debugId },
      )));
    }
  });
}

function* resetFiltersOnProjectChange(): SagaIterator {
  yield takeLatest(projectActions.changeProject, function* () {
    const currentFilters: DialogsHistorySearchFilter = yield select(getDialogHistorySearchFilter);
    const currenSearchFilters: DialogsHistorySearchFilter = yield select(getNewDialogHistorySearchFilter);

    yield put(actions.setDialogsHistoryFilter({
      ...defaultFilters,
      dateFrom: currentFilters.dateFrom,
      dateTo: currentFilters.dateTo,
    }));

    yield put(actions.setSearchDialogsHistoryFilter({
      ...defaultFilters,
      dateFrom: currenSearchFilters.dateFrom,
      dateTo: currenSearchFilters.dateTo,
    }));
  });
}

function* openDialog(): SagaIterator {
  yield takeLatest(actions.openDialog, function* ({ payload }) {
    const { sessionId } = payload;
    const params = new URLSearchParams(window.location.search);
    params.set('dialog', sessionId);
    yield put(actions.setDialogViewed(payload.sessionId));
    yield put(replace(window.location.pathname + `?${params.toString()}`));
  });
}

function* closeDialog(): SagaIterator {
  yield takeLatest(actions.closeDialog, function* () {
    const params = new URLSearchParams(window.location.search);
    params.delete('dialog');
    yield put(replace(window.location.pathname + `?${params.toString()}`));
  });
}

function* resetDeletedSampling(): SagaIterator {
  yield takeLatest(samplingsActions.deleteNewSampling.success, function* (action) {
    const deletedSamplingId = action.payload;
    const filters: DialogsHistorySearchFilter = yield select(getNewDialogHistorySearchFilter);

    if (filters.newSampling?.id === deletedSamplingId) {
      yield put(actions.setSearchDialogsHistoryFilter({
        ...filters,
        newSampling: null,
      }));
    }
  });
}

export default function* dialogSagas() {
  yield fork(loadDialogsHistory);
  yield fork(searchDialogsHistory);
  yield fork(loadDialogById);
  yield fork(sendLiveMessage);
  yield fork(cloneDialog);
  yield fork(handleExportDialogsHistory);
  yield fork(loadDialogNodesDebug);
  yield fork(updateSamplingOnSearch);
  yield fork(updateFilters);
  yield fork(updateSearchFilters);
  yield fork(resetFiltersOnProjectChange);
  yield fork(openDialog);
  yield fork(closeDialog);
  yield fork(resetDeletedSampling);
}
