import React from 'react';

import { SvgProps } from '../types';

function CodeIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        xmlns="http://www.w3.org/2000/svg"
        id="mask0_17152_221201"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect
          width={width}
          height={height}
          fill="#D9D9D9"
        />
      </mask>
      <g
        xmlns="http://www.w3.org/2000/svg"
        mask="url(#mask0_17152_221201)"
      >
        <path
          d="M16.7004 17.2986C16.5171 17.4819 16.2881 17.5736 16.0134 17.5736C15.7381 17.5736 15.5004 17.4736 15.3004 17.2736C15.1004 17.0736 15.0004 16.836 15.0004 16.5606C15.0004 16.286 15.1004 16.0486 15.3004 15.8486L19.1754 11.9736L15.2754 8.07362C15.0921 7.89028 15.0044 7.66095 15.0124 7.38562C15.0211 7.11095 15.1171 6.88195 15.3004 6.69862C15.4837 6.51528 15.7171 6.42362 16.0004 6.42362C16.2837 6.42362 16.5171 6.51528 16.7004 6.69862L21.3004 11.2986C21.5004 11.4986 21.6004 11.732 21.6004 11.9986C21.6004 12.2653 21.5004 12.4986 21.3004 12.6986L16.7004 17.2986ZM7.30039 17.2986L2.70039 12.6986C2.50039 12.4986 2.40039 12.2653 2.40039 11.9986C2.40039 11.732 2.50039 11.4986 2.70039 11.2986L7.30039 6.69862C7.48372 6.51528 7.71706 6.41928 8.00039 6.41062C8.28372 6.40262 8.52539 6.49862 8.72539 6.69862C8.92539 6.89862 9.02539 7.13595 9.02539 7.41062C9.02539 7.68595 8.92539 7.92362 8.72539 8.12362L4.82539 12.0236L8.72539 15.9236C8.90872 16.107 8.99639 16.3359 8.98839 16.6106C8.97972 16.886 8.88372 17.1153 8.70039 17.2986C8.51706 17.4819 8.28372 17.5736 8.00039 17.5736C7.71706 17.5736 7.48372 17.4819 7.30039 17.2986Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default CodeIcon;
