import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeConditionIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.8205 22.2459C12.8151 23.2514 11.1849 23.2514 10.1795 22.2459L1.75408 13.8205C0.748639 12.8151 0.748639 11.1849 1.75408 10.1795L10.1795 1.75408C11.1849 0.748639 12.8151 0.748639 13.8205 1.75408L22.2459 10.1795C23.2514 11.1849 23.2514 12.8151 22.2459 13.8205L13.8205 22.2459Z" fill="none" stroke={color} strokeWidth="2" />
  </svg>
);

export default GraphNodeConditionIcon;
