import React from 'react';
import { SvgProps } from '../types';

function SortIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.74902 7.18872L5.23535 3.61938C5.40137 3.43953 5.60197 3.34961 5.83716 3.34961C6.07235 3.34961 6.27295 3.43953 6.43897 3.61938L9.92533 7.18872C10.0867 7.35473 10.1674 7.55073 10.1674 7.77669C10.1674 8.01188 10.0913 8.20556 9.93917 8.35778C9.79158 8.50528 9.6025 8.57912 9.37192 8.57912C9.14133 8.57912 8.95225 8.50303 8.80468 8.35087L7.38663 6.87743L6.60498 5.96435L6.64648 7.35473V15.8215C6.64648 16.0567 6.57039 16.2504 6.41821 16.4026C6.26603 16.5594 6.07235 16.6378 5.83716 16.6378C5.60197 16.6378 5.40597 16.5594 5.24918 16.4026C5.09701 16.2504 5.02092 16.0567 5.02092 15.8215V7.35473L5.07625 5.96435L4.28076 6.87743L2.86962 8.35087C2.71745 8.50303 2.52838 8.57912 2.30241 8.57912C2.06722 8.57912 1.87353 8.50528 1.72135 8.35778C1.57378 8.20556 1.5 8.01188 1.5 7.77669C1.5 7.55073 1.58301 7.35473 1.74902 7.18872Z"
        fill={color}
      />
      <path
        d="M18.2504 12.7987L14.771 16.375C14.6004 16.5548 14.3974 16.6424 14.1623 16.6378C13.9317 16.6378 13.7334 16.5501 13.5674 16.375L10.0879 12.7987C9.91736 12.6326 9.83203 12.4366 9.83203 12.2107C9.83203 11.9709 9.90811 11.7772 10.0603 11.6296C10.2125 11.482 10.4062 11.4083 10.6414 11.4083C10.8627 11.4083 11.0495 11.4844 11.2017 11.6365L12.6197 13.103L13.4083 14.023L13.3529 12.6326V4.16585C13.3529 3.93066 13.429 3.73698 13.5812 3.58479C13.738 3.428 13.934 3.34961 14.1692 3.34961C14.4044 3.34961 14.5981 3.428 14.7503 3.58479C14.9024 3.73698 14.9785 3.93066 14.9785 4.16585V12.6326L14.9301 14.023L15.7256 13.103L17.1367 11.6365C17.2843 11.4844 17.471 11.4083 17.697 11.4083C17.9322 11.4083 18.1236 11.482 18.2712 11.6296C18.4234 11.7772 18.4994 11.9709 18.4994 12.2107C18.4994 12.4366 18.4164 12.6326 18.2504 12.7987Z"
        fill={color}
      />
    </svg>
  );
}

export default SortIcon;
