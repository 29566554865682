/* eslint-disable camelcase */
import { createAction, createAsyncAction } from 'typesafe-actions';

import { AuthProviderMeta } from '../../types/models/AuthProvider';
import { ErrorAction } from '../reducers';

export const loadAuthProviders = createAsyncAction(
  'authProviders/load/requested',
  'authProviders/load/succeeded',
  'authProviders/load/failed',
)<undefined, AuthProviderMeta[], ErrorAction>();

export const loadAuthProvider = createAsyncAction(
  'authProviders/loadOne/requested',
  'authProviders/loadOne/succeeded',
  'authProviders/loadOne/failed',
)<
  AuthProviderMeta['name'],
  AuthProviderMeta,
  ErrorAction
>();

export const loadAllAuthProviders = createAsyncAction(
  'authProviders/loadAll/requested',
  'authProviders/loadAll/succeeded',
  'authProviders/loadAll/failed',
)<undefined, AuthProviderMeta[], ErrorAction>();

export const setCurrentAuthProvider = createAction('authProviders/setCurrent')<AuthProviderMeta>();
