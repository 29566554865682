/* eslint-disable camelcase */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import NotificationManager from "../notifications";

import {
  CloneDialogRequest,
  CreateMessageMarkupRequest,
  DialogsHistoryRequest,
  GetChatInfoRequest,
  SearchDialogsHistoryRequest,
  UpdateMessageMarkRequest,
  UpdateMessageMarkupRequest,
} from '../../types/requests/Dialogs';
import {
  DialogsStatisticsRequest,
} from '../../types/requests/ProjectStatistics';
import { CreateTagRequest, TagsRequest, CountScenarioNodesRequest, DeleteTagRequest, GetTagInfoRequest } from '../../types/requests/Tags';
import {
  GetActionSuggestRequest,
} from '../../types/requests/Scenarios';
import {
  CreateTaskRequest,
  GetTaskLogsRequest,
  GetTasksRequest,
} from '../../types/requests/Task';
import { CreateLineRequest, LinesRequest } from '../../types/requests/Lines';
import {
  AddFavoriteFeatureRequest,
  CreateFeatureRequest,
  CreateFeatureResponse,
  DeleteFavoriteFeatureRequest,
  DeleteFeatureRequest,
  FeaturesRequest,
  UpdateFeatureRequest,
} from '../../types/requests/Features';
import AuthorizationService from '../auth';
import { notificationErrors } from '../../messages/notifications';
import {
  DownloadFileRequest,
  GetFilesMetaByTaskTypeRequest,
  UpdateUserAudioFilenameRequest,
  UploadAudioFileRequest,
  UploadExternalPhoneIdsFileRequest,
  UploadFileRequest,
} from '../../types/requests/Files';
import {
  GetThresholdDialogsRequest,
} from '../../types/requests/Tresholds';
import { SendBotDialogMessageRequest } from '../../types/requests/BotDialog';
import {
  CancelCallRequest,
  DownloadCallRecordRequest,
  EditCallRequest,
  GetCallResultRequest,
  GetCallsDataRequest,
  GetCallStatisticsRequest,
} from '../../types/requests/Calls';
import {
  CreateProjectConfigRequest,
  CreateProjectRequest,
  GetProjectConfigRequest,
  GetProjectsRequest,
  UpdateProjectConfigRequest,
} from '../../types/requests/Projects';
import {
  CreateSamplingNewRequest,
  CreateSamplingRequest,
  DeleteSamplingNewRequest,
  DeleteSamplingRequest,
  GetSamplingsStatisticsRequest,
  GetSapmlingsNewRequest,
  GetSapmlingsRequest,
  MarkDialogResolutionRequest,
} from '../../types/requests/Samplings';
import {
  GetCustomConfigRequest,
  UpdateCustomConfigRequest,
} from '../../types/requests/CustomConfigs';
import { GetFullConfigurationRequest } from '../../types/requests/FullConfiguration';
import { GetChangeHistoryRequest } from '../../types/requests/EventsHistory';
import {
  ActivateVersionRequest,
  CreateVersionRequest,
  DeleteVersionRequest,
  GetVersionsRequest,
} from '../../types/requests/Versions';
import {
  CreateEntityRequest,
  DeleteEntityRequest,
  GetCustomExtractorsRequest,
  GetEntitiesRequest,
  UpdateEntityRequest,
} from '../../types/requests/Entities';
import {
  GetGraphDebugByChatRequest,
  GetGraphDebugByIdRequest,
} from '../../types/requests/Debug';
import i18n from "../i18n";
import ApiError from '../../types/models/ApiError';
import { GenerateTextsRequest } from '../../types/requests/Generate';
import {
  AddProjectModelRequest,
  GetModelPlotsRequest,
  UpdateModelTopicRequest,
} from '../../types/requests/ProjectModels';
import { getSelectedProject } from '../../redux/projects/selectors';
import { store } from '../../redux/store';
import {
  ProvideSuperUserRoleRequest,
} from '../../types/requests/Users';
import {
  CreateCommonModelRequest,
  DeleteCommonModelRequest,
} from '../../types/requests/CommonModels';
import {
  GetSlotsRequest, LockSlotRequest, UnlockSlotRequest,
} from '../../types/requests/Slots';
import {
  CloneScenarioGraphRequest,
} from '../../types/requests/GraphTopics';
import {
  CreateIntegrationLabelRequest,
  CreateIntegrationRequest,
  DeleteIntegrationLabelRequest,
  DeleteIntegrationRequest,
  GetIntegrationFeaturesRequest,
  GetIntegrationUsageRequest,
  TestIntegrationRequest,
  UpdateIntegrationLabelRequest,
  UpdateIntegrationRequest,
  UpdateLabelIntegrationsRequest,
} from '../../types/requests/Integrations';
import {
  CreateGraphRequest,
  DeleteGraphRequest,
  GetGraphicalScenariosRequest,
  GetGraphRequest,
  GetNodesStatisticsRequest,
  UpdateGraphRequest,
  ValidateGraphNodeRequest,
} from '../../types/requests/Graph';
import { ConvertPredicateRequest } from '../../types/requests/Predicate';
import { GetClusterDetailsRequest, GetClustersOverviewRequest } from '../../types/requests/Clustering';
import { CallsDetailsStatisticsRequest, CallsStatisticsRequest } from '../../types/requests/CallsStatistics';
import { TranslateRequest } from '../../types/requests/Languages';
import {
  CreateScenarioGraphLabelRequest,
  DeleteExamplesRequest,
  DeleteScenarioGraphLabelRequest,
  GetScenarioGraphsRequest,
  GetTrashPhrasesRequest,
  SwitchScenarioGraphAvailabilityRequest,
  ToggleExampleReferenceStatusRequest,
  ChangeLabelScenariosRequest,
  UpdateScenarioGraphLabelRequest,
} from '../../types/requests/ScenarioGraphs';
import {
  GetChatterboxMacrosRequest,
  GetMacrosListRequest,
} from '../../types/requests/Macros';

import * as IncomingIntegraions from '../../types/requests/IncomingIntegraions';

import {
  AddUserToProjectRequest,
  GetAllUsersRequest,
  GetProjectUsersListRequest,
  GetUserByIdRequest,
  RemoveUserFromProjectRequest,
  UpdateUserGroupRequest,
  CreateUserByProviderRequest,
} from '../../types/requests/UserAccesses';

import {
  CreateReleaseNoteRequest,
  DeleteReleaseNoteRequest,
  UpdateReleaseNoteRequest,
} from '../../types/requests/ReleaseNotes';

import {
  GetGameDataRequest,
  SendGameResultsRequest,
} from '../../types/requests/Games';

import {
  UploadMediaFileRequest,
  DeleteMediaFileRequest,
} from '../../types/requests/MediaFiles';

import {
  GetProjectAuthDataRequest,
  PutProjectAuthDataRequest,
} from '../../types/requests/ProjectAuthData';
import { MediaFileMetadata } from '../../types/models/MediaFiles';
import { CreateProviderRequest, DeleteProviderRequest, UpdateProviderRequest } from '../../types/requests/Authentication';
import { ChangeMessengerIntegrationEnableStatusRequest, CreateMessengerAccountRequest, DeleteMessengerAccountRequest, UpdateMessengerAccountRequest } from '../../types/requests/Account';

import {
  CreateClientRequest,
  GetClientDetailsRequest,
  LinkClientAndIntegrationRequest,
  LinkClientAndUserRequest,
  UpdateClientDetailsRequest,
  GetClientPersonRequest,
  CreatePersonRequest,
  UpdatePersonRequest,
} from "../../types/requests/Billing";
import {
  GetProjectVoiceConfigRequest,
  SwitchProjectVoiceConfigRequest,
  UpdateProjectVoiceConfigRequest,
} from '../../types/requests/ProjectVoice';
import { CreateAllowedDomainRequest, DeleteAllowedDomainRequest, UpdateAllowedDomainRequest } from '../../types/requests/Domains';
import {
  CreateMarkupRequest,
  DeleteMarkupRequest,
  GetMarkupScenariosRequest,
} from '../../types/requests/Markup';
import {
  CreateSecretRequest,
  DeleteSecretRequest,
  GetSecretsRequest,
  UpdateSecretDescriptionRequest,
  UpdateSecretRequest,
} from '../../types/requests/Secret';
import { ModelDraftBackend } from '../../types/backendModels/ProjectModelBackend';
import { getCurrentVersionToDisplay } from '../../redux/versions/selectors';
import { CreateGraphGroupRequest, DeleteGraphGroupRequest, EditGraphGroupRequest, EditGraphGroupsBulkRequest, GetGraphGroupsRequest } from '../../types/requests/GraphGroups';
import { GetQualityStatsByDayRequest, GetQualityStatsBySamplingRequest } from '../../types/requests/QualityStats';
import { CreateResolutionRequest, UpdateResolutionRequest } from '../../types/requests/Resolution';
import { UpdateScenariosPriorityRequest } from '../../types/requests/ScenarioPriorities';
import { GetScenarioUsageRequest, UpdateScenarioUsageRequest } from '../../types/requests/ScenarioUsage';
import { IterationAnalitycsRequest, LastAutomatedResponseNodeRequest, NotAutomatedIterationsRequest, PreviousAutomatedResponseNodesRequest } from '../../types/requests/IterationAnalitycs';

function addRequestAuthorization(config: AxiosRequestConfig) {
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `OAuth ${AuthorizationService.getSupportaiToken()}`,
  };
  // eslint-disable-next-line no-param-reassign
  config.params = {
    ...config.params,
  };
  return config;
}

function addProjectParams(config: AxiosRequestConfig) {
  const selectedProject = getSelectedProject(store.getState());
  // eslint-disable-next-line no-param-reassign
  config.params = {
    project_id: selectedProject.id,
    project_slug: selectedProject.slug,
    ...config.params,
  };

  return config;
}

function getVersionParams(config: AxiosRequestConfig) {
  const version = getCurrentVersionToDisplay(store.getState());
  config.params = {
    version,
    ...config.params,
  };
  return config;
}

function handleResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function handleCommonErrors(error: AxiosError) {
  const code = error.response?.status;

  if (code === 409) {
    NotificationManager.error(i18n.t("ERRORS.API.VERSION_CONFLICT"));
  }

  return Promise.reject(error);
}

function handleErrorAuthorizarion(error: AxiosError) {
  const unauthorizedHeaderReceived = error.response?.status === 401;

  if (unauthorizedHeaderReceived && error.config.url === "/authentication/passport/signup/manual") {
    NotificationManager.error(i18n.t(notificationErrors.noUserInPassport));
  } else if (unauthorizedHeaderReceived) {
    NotificationManager.error(i18n.t(notificationErrors.unauthorized));
    AuthorizationService.logout();
  }

  const blockedError = error.response?.status === 409;
  // 409 значит что версия обновляется. Не нужно показывать сообщение об ошибке,
  // а сама обработка запроса находится в миддлваре handleCommonErrors
  if (blockedError) {
    return "";
  }

  throw new ApiError(error);
}

const authorizedRequester = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

authorizedRequester.interceptors.request.use(addRequestAuthorization);
authorizedRequester.interceptors.response.use(
  handleResponse,
  handleErrorAuthorizarion,
);

const projectRequester = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

projectRequester.interceptors.request.use(addRequestAuthorization);
projectRequester.interceptors.request.use(addProjectParams);
projectRequester.interceptors.response.use(undefined, handleCommonErrors);
projectRequester.interceptors.response.use(undefined, handleErrorAuthorizarion);

const versionRequester = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

versionRequester.interceptors.request.use(addRequestAuthorization);
versionRequester.interceptors.request.use(addProjectParams);
versionRequester.interceptors.request.use(getVersionParams);
versionRequester.interceptors.response.use(undefined, handleCommonErrors);
versionRequester.interceptors.response.use(undefined, handleErrorAuthorizarion);

function getProjects(params?: GetProjectsRequest) {
  return authorizedRequester.get('/projects', {
    params,
  });
}

function getNewProjectStatistics(params: DialogsStatisticsRequest) {
  return projectRequester.get('/statistics-new', {
    params,
  });
}

function getCallsStatistics(params: CallsStatisticsRequest) {
  return projectRequester.get('/statistics-calls/grouped', {
    params,
  });
}

function getCallsDetailsStatistics(params: CallsDetailsStatisticsRequest) {
  return projectRequester.get('/statistics-calls/separated', {
    params,
  });
}

function getAnalyticsForProject({ query }: IterationAnalitycsRequest) {
  return projectRequester.get('/iteration-analytics', {
    params: query,
  });
}

function getNotAutomatedIterations({ query }: NotAutomatedIterationsRequest) {
  return projectRequester.get('/iteration-analytics/not-automated-iterations', {
    params: query,
  });
}

function getPreviousAutomatedResponseNodes({ query }: PreviousAutomatedResponseNodesRequest) {
  return projectRequester.get('/iteration-analytics/previous-automated-response-nodes', {
    params: query,
  });
}

function getLastAutomatedResponseNode({ query }: LastAutomatedResponseNodeRequest) {
  return projectRequester.get('iteration-analytics/last-automated-response-node', {
    params: query,
  });
}

function getTags(params: TagsRequest) {
  return authorizedRequester.get('/tags', {
    params,
  });
}

function getFeatures(params: FeaturesRequest) {
  return authorizedRequester.get('/features', {
    params,
  });
}

function createFeature(params: CreateFeatureRequest) {
  const { feature, ...queryParams } = params;
  return authorizedRequester.post<CreateFeatureResponse>('/features', {
    ...feature,
  }, {
    params: queryParams,
  });
}

function deleteFeature(params: DeleteFeatureRequest) {
  const { featureId, ...queryParams } = params;
  return authorizedRequester.delete(`/features/${featureId}`, {
    params: queryParams,
  });
}

function updateFeature(params: UpdateFeatureRequest) {
  const { feature, ...queryParams } = params;
  return authorizedRequester.put(`/features/${feature.id}`, {
    ...feature,
  }, {
    params: queryParams,
  });
}

function getFavoriteFeatures() {
  return projectRequester.get(`/favorite-features`);
}

function addFavoriteFeature(params: AddFavoriteFeatureRequest) {
  return projectRequester.post('/favorite-features', {
    ...params.body,
  });
}

function deleteFavoriteFeature(params: DeleteFavoriteFeatureRequest) {
  return projectRequester.delete(`/favorite-features`, { params });
}

function getLines(params: LinesRequest) {
  return authorizedRequester.get('/lines', {
    params,
  });
}

function createTag(params: CreateTagRequest) {
  const { tag, ...queryParams } = params;
  return authorizedRequester.post('/tags', {
    ...tag,
  }, {
    params: queryParams,
  });
}

function createLine(params: CreateLineRequest) {
  const { line, ...queryParams } = params;
  return authorizedRequester.post('/lines', {
    ...line,
  }, {
    params: queryParams,
  });
}

function getTasks(params: GetTasksRequest) {
  return projectRequester.get(`/tasks`, {
    params,
  });
}

function getTaskLogs(params: GetTaskLogsRequest) {
  return projectRequester.get(`/tasks/${params.path.task_id}/logs`);
}

function createTask(params: CreateTaskRequest) {
  return projectRequester.post('/tasks', {
    ...params,
  });
}

function createExportExcelTask(params: CreateTaskRequest) {
  return projectRequester.post('/export/excel', {
    ...params,
  });
}

function downloadFile(params: DownloadFileRequest) {
  return authorizedRequester.get(`/filesS3`, {
    params,
    responseType: 'blob',
  });
}

function uploadFileS3(params: UploadFileRequest) {
  const {
    project_id, project_slug, formData,
  } = params;
  return authorizedRequester.post(`/filesS3`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      project_id,
      project_slug,
    },
  });
}

function cloneDialog(params: CloneDialogRequest) {
  return projectRequester.post('/dialogs/clone', {
    ...params,
  });
}

function getDialogsHistory(params: DialogsHistoryRequest) {
  return projectRequester.get('/dialogs/history', {
    params,
  });
}

function getDialogsHistoryV3({ query, body }: SearchDialogsHistoryRequest) {
  return projectRequester.post('/dialogs/history-search',
    body,
    {
      params: query,
    });
}

function getChatInfo({ query }: GetChatInfoRequest) {
  return projectRequester.get(`/dialogs/dialog`, {
    params: query,
  });
}

function sendBotDialogMessage(params: SendBotDialogMessageRequest) {
  const { project_slug, ask_features, ...data } = params;

  const config = {
    params: {
      project_id: project_slug,
      ask_features,
    },
  };

  return authorizedRequester.post('/dialogs/live', data?.request, config);
}

function getThresholdDialogs(params: GetThresholdDialogsRequest) {
  return projectRequester.get('/thresholds/dialogs/examples', {
    params,
  });
}

function createRecordMarkup({ query, body }: CreateMessageMarkupRequest) {
  return projectRequester.post(
    `/dialogs/record/markup`,
    body,
    {
      params: query,
    },
  );
}

function updateRecordMarkup({ query, body }: UpdateMessageMarkupRequest) {
  return projectRequester.put(
    `/dialogs/record/markup`,
    body,
    {
      params: query,
    },
  );
}

function updateRecordMark({ query, body }: UpdateMessageMarkRequest) {
  return projectRequester.put(
    `/dialogs/record/mark`,
    body,
    {
      params: query,
    },
  );
}

function getCalls(params: GetCallsDataRequest) {
  return projectRequester.get('/calls', {
    params,
  });
}

function getCallResult(params: GetCallResultRequest) {
  return projectRequester.get(`/calls/${params.call_id}/result`, {
    params,
  });
}

function editCall(params: EditCallRequest) {
  return projectRequester.post(
    `/calls/${params.path.task_id}/edit`,
    params.body,
  );
}

function downloadCallRecord(params: DownloadCallRecordRequest) {
  return authorizedRequester.get(`/calls/${params.call_id}/record`, {
    params,
    responseType: 'blob',
  });
}

function getSamplings(params: GetSapmlingsRequest) {
  return authorizedRequester.get('/samplings', {
    params,
  });
}

function createSampling(params: CreateSamplingRequest) {
  const { project_id, project_slug, ...postParams } = params;
  return authorizedRequester.post('/samplings', {
    ...postParams,
  }, {
    params: { project_id, project_slug },
  });
}

function deleteSampling({ query }: DeleteSamplingRequest) {
  return projectRequester.delete('/samplings', {
    params: query,
  });
}

function getSamplingsNew(params: GetSapmlingsNewRequest) {
  return authorizedRequester.get('/samplings-new', {
    params,
  });
}

function createSamplingNew(params: CreateSamplingNewRequest) {
  return authorizedRequester.post('/samplings-new', {
    ...params,
  });
}

function deleteSamplingNew({ path }: DeleteSamplingNewRequest) {
  return projectRequester.delete(`/samplings-new/${path.sampling_id}`);
}

function getCustomConfig(params: GetCustomConfigRequest) {
  return authorizedRequester.get('/configs/custom', {
    params,
  });
}

function updateCustomConfig(params: UpdateCustomConfigRequest) {
  const { project_id, project_slug, ...body } = params;
  return authorizedRequester.post('/configs/custom', {
    ...body.config,
  }, {
    params: { project_id, project_slug },
  });
}

function getFullConfiguration(params: GetFullConfigurationRequest) {
  return authorizedRequester.get('/configs/full', {
    params,
  });
}

function getChaneHistory(params: GetChangeHistoryRequest) {
  return projectRequester.get('/changes', {
    params,
  });
}

function loadVersions(params: GetVersionsRequest) {
  return authorizedRequester.get('/versions', {
    params,
  });
}

function createVersion(params: CreateVersionRequest) {
  return authorizedRequester.post('/versions/draft/release', params.body, {
    params: params.query,
  });
}

function deleteVersion(params: DeleteVersionRequest) {
  return authorizedRequester.delete(`/versions/${params.version_id}`, {
    params,
  });
}

function activateVersion(params: ActivateVersionRequest) {
  return projectRequester.post('/versions/activate', params.body);
}

function getEntities(params: GetEntitiesRequest) {
  return authorizedRequester.get('/entities', {
    params,
  });
}

function createEntity(params: CreateEntityRequest) {
  return authorizedRequester.post('/entities', {
    ...params.body,
  }, {
    params: params.queryParams,
  });
}

function updateEntity(params: UpdateEntityRequest) {
  return authorizedRequester.put('/entities', {
    ...params.body,
  }, {
    params: params.queryParams,
  });
}

function deleteEntity(params: DeleteEntityRequest) {
  return authorizedRequester.delete('/entities', {
    params: params.queryParams,
  });
}

function getCustomExtractors(params: GetCustomExtractorsRequest) {
  return authorizedRequester.get('/entities/extractors/custom', {
    params,
  });
}

function getActionSuggest(params: GetActionSuggestRequest) {
  return projectRequester.get('/action/suggest', {
    params,
  });
}

function updateProjectConfig(params: UpdateProjectConfigRequest) {
  const { body, query } = params;
  return projectRequester.put('/projects/config', body, {
    params: query,
  });
}

function getGraphDebugByChat({ query }: GetGraphDebugByChatRequest) {
  return projectRequester.get('/debug/by/chat_id', {
    params: query,
  });
}

function getGraphDebugById({ query }: GetGraphDebugByIdRequest) {
  return projectRequester.get('/debug/by/debug_id', {
    params: query,
  });
}

function createProjectConfig(params: CreateProjectConfigRequest) {
  return authorizedRequester.post('/projects/config', params ? params.body : undefined, {
    params: params.query,
  });
}

function getProjectConfig(params?: GetProjectConfigRequest) {
  return projectRequester.get('/projects/config', {
    params,
  });
}

function cancelCall(params: CancelCallRequest) {
  return authorizedRequester.post('/calls/cancel', {}, {
    params,
  });
}

function getCallStatistics(params: GetCallStatisticsRequest) {
  return projectRequester.get('/calls/statistics', {
    params: params.query,
    responseType: "blob",
  });
}

function createProject(params: CreateProjectRequest) {
  return authorizedRequester.post('/projects', params.body);
}

// eslint-disable-next-line camelcase
function generateTexts({ text, generate_count }: GenerateTextsRequest) {
  return projectRequester.post(
    '/generate/texts',
    { text, generate_count },
  );
}

function deleteProjectModel() {
  return projectRequester.delete("/models-project");
}

function addProjectModel(params: AddProjectModelRequest) {
  return projectRequester.post("/models-project", params.body);
}

function getProjectModels() {
  return projectRequester.get('/models-project');
}

function getProjectCurrentModel() {
  return projectRequester.get('/models-project/current');
}

function getModelDraft() {
  return projectRequester.get('/models-project/online-model-config');
}

function udateModelConfig(params: ModelDraftBackend) {
  return projectRequester.put('/models-project/online-model-config', params);
}

function getProjectModelTopics() {
  return projectRequester.get('/model/topics');
}

function updateModelTopic(params: UpdateModelTopicRequest) {
  return projectRequester.put('/model/topics', params.body);
}

function getModelPlots(params: GetModelPlotsRequest) {
  return projectRequester.get('/model/topics/plots', {
    params: params.query,
  });
}

function getGraphicalScenarios(params: GetGraphicalScenariosRequest) {
  return versionRequester.get('/scenarios-graph', {
    params: params.query,
  });
}

function getGraph({ path, params }: GetGraphRequest) {
  return versionRequester.get(`/scenarios-graph/${path.scenario_id}`, {
    params,
  });
}

function deleteGraph({ params }: DeleteGraphRequest) {
  return projectRequester.delete(`/scenarios-graph/${params.scenario_id}`);
}

function createScenarioGraph(request: CreateGraphRequest) {
  return projectRequester.post(
    '/scenarios-graph',
    request.body,
  );
}

function updateScenarioGraph(request: UpdateGraphRequest) {
  return projectRequester.put(
    `/scenarios-graph/${request.params.scenario_id}`,
    request.body,
  );
}

function cloneGraphTopic(params: CloneScenarioGraphRequest) {
  return projectRequester.post(`/scenarios-graph/copy/${params.params.scenario_id}`, params.body);
}

function validateNode(params: ValidateGraphNodeRequest) {
  return projectRequester.post('/scenarios-graph/node/validate', params.body);
}

function getScenarioGraphExamples(params: GetMarkupScenariosRequest) {
  return projectRequester.get('/markup/scenarios', {
    params,
  });
}

function createScenarioGraphExample(params: CreateMarkupRequest) {
  return projectRequester.post('/markup/scenarios', params.body,
    {
      params: params.query,
    });
}

function deleteScenarioGraphExample({ id }: DeleteMarkupRequest) {
  return projectRequester.delete(`/markup/scenarios/${id}`);
}

function deleteMarkupExamples(params: DeleteExamplesRequest) {
  return projectRequester.post('/markup/scenarios/delete', params.body);
}

function toggleExampleReferenceStatus(request : ToggleExampleReferenceStatusRequest) {
  return projectRequester.put(`/scenarios-graph/${request.path.scenario_id}/examples/${request.path.example_id}/enable`,
    undefined,
    {
      params: request.params,
    });
}

function getAudioFilesMetadata() {
  return projectRequester.get('/files/metadata/audio');
}

function getAudioFile(filename: string) {
  return projectRequester.get(`/files/audio/${filename}`, {
    responseType: "arraybuffer",
  });
}

function uploadAudioFile(params: UploadAudioFileRequest) {
  return projectRequester.post('/files/audio', params.body.formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function updateAudioUserFilename(params: UpdateUserAudioFilenameRequest) {
  return projectRequester.put(`/files/metadata/user_custom/audio/${params.filename}`, params.body);
}

function getFilesMetaByTaskType(params: GetFilesMetaByTaskTypeRequest) {
  return projectRequester.get('/files/', { params });
}

function provideSuperUserRole({ userId }: ProvideSuperUserRoleRequest) {
  return authorizedRequester.post(`/users/make-superuser/${userId}`);
}

function getCommonModels() {
  return projectRequester.get('/models-all');
}

function createCommonModel(params: CreateCommonModelRequest) {
  return projectRequester.post("/models-all", params.body);
}

function deleteCommonModel({ params }: DeleteCommonModelRequest) {
  return projectRequester.delete(`/models-all/${params.model_id}`);
}

function getSlots(params: GetSlotsRequest) {
  return projectRequester.get('/slots', {
    params: params.query,
  });
}

function getFreeSlots() {
  return projectRequester.get('/slots/free');
}

function lockSlot(params: LockSlotRequest) {
  return projectRequester.post('/slots/lock', params.body);
}

function unlockSlot({ params }: UnlockSlotRequest) {
  return projectRequester.post(`/slots/${params.slot_id}/unlock`);
}

function getIntegrations() {
  return projectRequester.get('/integrations');
}

function createIntegration(params: CreateIntegrationRequest) {
  return projectRequester.post('/integrations', params.body);
}

function updateIntegration(params: UpdateIntegrationRequest) {
  return projectRequester.put(`/integrations/${params.params.integration_id}`, params.body);
}

function deleteIntegration(params: DeleteIntegrationRequest) {
  return projectRequester.delete(`/integrations/${params.params.integration_id}`);
}

function getIntegrationsLabels() {
  return projectRequester.get('/integrations/labels');
}

function createIntegrationLabel(params: CreateIntegrationLabelRequest) {
  return projectRequester.post('/integrations/labels', params.body);
}

function updateIntegrationLabel({ path, body }: UpdateIntegrationLabelRequest) {
  return projectRequester.put(`/integrations/labels/${path.label_id}`, body);
}

function deleteIntegrationLabel({ path }: DeleteIntegrationLabelRequest) {
  return projectRequester.delete(`/integrations/labels/${path.label_id}`);
}

function updateLabelIntegrations({ path, body }: UpdateLabelIntegrationsRequest) {
  return projectRequester.post(
    `/integrations/labels/${path.label_id}/integrations`,
    body,
  );
}

function getIntegrationInputFeatures(params: GetIntegrationFeaturesRequest) {
  return projectRequester.get(`/integrations/${params.path.integration_id}/features/input`);
}

function testIntegration(params: TestIntegrationRequest) {
  return projectRequester.post(
    `/integrations/${params.path.integration_id}/test`,
    params.body,
  );
}

function getButterflyAvailability() {
  return authorizedRequester.get('/butterfly-availability/');
}

function convertPredicate(params: ConvertPredicateRequest) {
  return projectRequester.post('/predicate/convert', params.body);
}

function uploadExternalPhoneIdsFile(params: UploadExternalPhoneIdsFileRequest) {
  return projectRequester.post(
    '/files/external-phone-ids',
    params.body.formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

function getCurrentVersion() {
  return projectRequester.get('/versions/draft');
}

function reloadDraftVersion() {
  return projectRequester.post('/versions/draft/reload');
}

function getClustersOverview(params: GetClustersOverviewRequest) {
  return projectRequester.get('/clustering', params);
}

function getClusterDetails(params: GetClusterDetailsRequest) {
  return projectRequester.get('/clustering/results', params);
}

function getLanguages() {
  return projectRequester.get('/languages');
}

function translateText(params: TranslateRequest) {
  return projectRequester.post('/translate', params);
}

function getScenarioGraphs(params: GetScenarioGraphsRequest) {
  return versionRequester.get('/scenario-graphs', params);
}

function switchScenarioAvailability(params: SwitchScenarioGraphAvailabilityRequest) {
  return projectRequester.put(
    `/scenario-switch/${params.query.scenario_id}`,
    params.body,
    {
      params: params.query,
    },
  );
}

function getCustomIntegrations(params?: IncomingIntegraions.GetIntegrationsRequest) {
  return projectRequester.get('/incoming-integrations', { params });
}

function createCustomIntegration(body: IncomingIntegraions.PostIntegrationRequest) {
  return projectRequester.post('/incoming-integrations', body);
}

function updateCustomIntegration(body: IncomingIntegraions.PutIntegrationRequest) {
  return projectRequester.put(`/incoming-integrations/${body.id}`, body);
}

function deleteCustomIntegration(id: IncomingIntegraions.DeleteIntegrationRequest) {
  return projectRequester.delete(`/incoming-integrations/${id}`);
}

function getCustomIntegrationActions({ integrationId }: IncomingIntegraions.GetActionsRequest) {
  return projectRequester.get(`/incoming-integrations/${integrationId}/actions`);
}

function createCustomIntegrationAction({ params, body }: IncomingIntegraions.PostActionRequest) {
  return projectRequester.post(`/incoming-integrations/${params.integrationId}/actions`, body);
}

function updateCustomIntegrationAction({ params, body }: IncomingIntegraions.PutActionRequest) {
  return projectRequester.put(`/incoming-integrations/${params.integrationId}/actions/${body.id}`, body);
}

function deleteCustomIntegrationAction(params: IncomingIntegraions.DeleteActionRequest) {
  const { integrationId, id } = params;
  return projectRequester.delete(`/incoming-integrations/${integrationId}/actions/${id}`);
}

function getCustomIntegrationCallbacks(params: IncomingIntegraions.GetCallbacksRequest) {
  const { integrationId, actionId } = params;
  return projectRequester.get(`/incoming-integrations/${integrationId}/actions/${actionId}/callbacks`);
}

function createCustomIntegrationCallback(
  { params, body }: IncomingIntegraions.PostCallbackRequest,
) {
  const { integrationId, actionId } = params;
  return projectRequester.post(`/incoming-integrations/${integrationId}/actions/${actionId}/callbacks`, body);
}

function updateCustomIntegrationCallback({ params, body }: IncomingIntegraions.PutCallbackRequest) {
  const { actionId, integrationId } = params;
  return projectRequester.put(`/incoming-integrations/${integrationId}/actions/${actionId}/callbacks/${body.id}`, body);
}

function deleteCustomIntegrationCallback(params: IncomingIntegraions.DeleteCallbackRequest) {
  const { integrationId, actionId, id } = params;
  return projectRequester.delete(`/incoming-integrations/${integrationId}/actions/${actionId}/callbacks/${id}`);
}

function validateIntegrationTemplate({ body }: IncomingIntegraions.PostValidationRequest) {
  return projectRequester.post('/incoming-integrations/validate', body);
}

function getProjectsLinkedToIntegration(id: IncomingIntegraions.GetLinkedProjectsRequest) {
  return projectRequester.get(`/incoming-integrations/linked-projects/${id}`);
}

function linkIntegrationAndProject(data: IncomingIntegraions.LinkIntegrationToProjectsRequest) {
  return projectRequester.post(`/incoming-integrations/project/${data.project_slug}`, data);
}

// eslint-disable-next-line max-len
function unlinkIntegrationFromProject(params: IncomingIntegraions.UnlinkIntegrationFromProjectsRequest) {
  return projectRequester.delete(`/incoming-integrations/project/${params.projectSlug}/integrations/${params.integrationId}`);
}

function getProjectUsersList(params: GetProjectUsersListRequest) {
  return projectRequester.get(`/user-accesses/project/${params.projectSlug}`);
}

function addUserToProject({ params, body }: AddUserToProjectRequest) {
  return projectRequester.post(`/user-accesses/project/${params.projectSlug}`, body);
}

function getAllUsers(params?: GetAllUsersRequest) {
  return projectRequester.get('/user-accesses/users/all', params);
}

function getUserById(params: GetUserByIdRequest) {
  return projectRequester.get(`/user-accesses/users/${params.userId}`);
}

function updateUserGroup({ params: { projectSlug, userId }, body }: UpdateUserGroupRequest) {
  return projectRequester.put(`/user-accesses/group-capabilities/${projectSlug}/users/${userId}`, body);
}

function removeUserFromProject({ projectSlug, userId }: RemoveUserFromProjectRequest) {
  return projectRequester.delete(`/user-accesses/group-capabilities/${projectSlug}/users/${userId}`);
}

function createUserByProvider(body: CreateUserByProviderRequest) {
  const { provider, ...rest } = body;
  return projectRequester.post(`/authentication${body.provider.path}/signup/manual`, rest);
}

function getReleaseNotes() {
  return projectRequester.get('/release-notes');
}

function createReleaseNote(body: CreateReleaseNoteRequest) {
  return projectRequester.post('/release-notes', body);
}

function getTheLatestReleaseNode() {
  return projectRequester.get('/release-notes/latest');
}

function updateReleaseNote({ body, params }: UpdateReleaseNoteRequest) {
  return projectRequester.put(`/release-notes/${params.id}`, body);
}

function deleteReleaseNote({ id }: DeleteReleaseNoteRequest) {
  return projectRequester.delete(`/release-notes/${id}`);
}

function getGamesList() {
  return projectRequester.get(`/games/list`);
}

function getGameData(params: GetGameDataRequest) {
  return projectRequester.get(`/games/data`, { params });
}

function sendGameResult({ params, body }: SendGameResultsRequest) {
  return projectRequester.post(`/games`, body, { params });
}

function uploadMediaFile({ params, body }: UploadMediaFileRequest) {
  const selectedProject = getSelectedProject(store.getState());

  return projectRequester.post(
    `/media-files`,
    body,
    {
      params: {
        ...params,
        project_id: selectedProject.slug,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

function deleteMediaFile({ params }: DeleteMediaFileRequest) {
  const selectedProject = getSelectedProject(store.getState());
  return projectRequester.delete(`/media-files/${params.file_id}`, {
    params: {
      project_id: selectedProject.slug,
    },
  });
}

function getMediaFile(id: MediaFileMetadata['id']) {
  const selectedProject = getSelectedProject(store.getState());
  return projectRequester.get(`/media-files/${id}`, {
    params: {
      project_id: selectedProject.slug,
    },
    responseType: "arraybuffer",
  });
}

function getProjectAuthData({ project_slug }: GetProjectAuthDataRequest) {
  return projectRequester.get(`/projects-auth-data/${project_slug}`);
}

function putProjectAuthData({ params, body }: PutProjectAuthDataRequest) {
  return projectRequester.put(`/projects-auth-data/${params.project_slug}`, body);
}

function getTrashPhrases(params: GetTrashPhrasesRequest) {
  return projectRequester.get(`/trash-phrases`, {
    params,
  });
}

function getProjectExamples() {
  return projectRequester.get('/markup/scenarios/aggregated');
}

function getProviders() {
  return authorizedRequester.get('/auth-providers');
}

function createProvider(params: CreateProviderRequest) {
  return authorizedRequester.post('/auth-providers', params.body);
}

function updateProvider(params: UpdateProviderRequest) {
  return authorizedRequester.put(`/auth-providers/${params.path.name}`, params.body);
}

function deleteProvider(params: DeleteProviderRequest) {
  return authorizedRequester.delete(`/auth-providers/${params.path.name}`);
}

function getMessengerAccounts() {
  return projectRequester.get('/messenger/account');
}

function createMessengerAccount(params: CreateMessengerAccountRequest) {
  return projectRequester.post('/messenger/account', params.body);
}

function updateMessengerAccount(params: UpdateMessengerAccountRequest) {
  return projectRequester.patch('/messenger/account', params.body);
}

function deleteMessengerAccount(params: DeleteMessengerAccountRequest) {
  return projectRequester.delete('/messenger/account', {
    params: params.query,
  });
}

function getClients() {
  return authorizedRequester.get(`/billing/clients`);
}

function createClient(params: CreateClientRequest) {
  return authorizedRequester.post(`/billing/clients`, params.body);
}

function getClientDetails(params: GetClientDetailsRequest) {
  return authorizedRequester.get(`/billing/clients/${params.path.client_id}`, {
    params: params.query,
  });
}

function updateClientDetails(params: UpdateClientDetailsRequest) {
  return authorizedRequester.put(`/billing/clients/${params.path.client_id}`);
}

function linkClientAndUser({ path, query }: LinkClientAndUserRequest) {
  return authorizedRequester.post(`/billing/clients/${path.client_id}/link-user`, undefined, {
    params: query,
  });
}

function linkClientAndIntegration(params: LinkClientAndIntegrationRequest) {
  return authorizedRequester.post(`/billing/clients/${params.path.client_id}`, null, {
    params: params.query,
  });
}

function changeMessengerEnableStatus({ query, path }: ChangeMessengerIntegrationEnableStatusRequest) {
  return projectRequester.post(
    `/messenger/account/${path.enable_value}`,
    undefined,
    {
      params: query,
    },
  );
}

function getClientPerson(params: GetClientPersonRequest) {
  return authorizedRequester.get(`/billing/clients/${params.path.client_id}/person`);
}

function createPerson(params: CreatePersonRequest) {
  return authorizedRequester.post(`/billing/clients/${params.path.client_id}/person`,
    params.body,
  );
}

function updatePerson(params: UpdatePersonRequest) {
  return authorizedRequester.put(`/billing/clients/${params.path.client_id}/person`,
    params.body,
  );
}

function getProjectVoiceConfig({ path }: GetProjectVoiceConfigRequest) {
  return authorizedRequester.get(`/projects/${path.project_slug}/voice-config`);
}

function updateProjectVoiceConfig({ path, body }: UpdateProjectVoiceConfigRequest) {
  return authorizedRequester.put(`/projects/${path.project_slug}/voice-config`, body);
}

function switchProjectVoiceConfig({ path, body }: SwitchProjectVoiceConfigRequest) {
  return authorizedRequester.post(`/projects/${path.project_slug}/voice-config/switch`, body);
}

function getScenarioLabels() {
  return projectRequester.get('/labels');
}

function createScenarioLabel(params: CreateScenarioGraphLabelRequest) {
  return projectRequester.post('/labels', params.body);
}

function deleteScenarioLabel(params: DeleteScenarioGraphLabelRequest) {
  return projectRequester.delete(`/labels/${params.path.label_id}`);
}

function updateScenarioLabel(params: UpdateScenarioGraphLabelRequest) {
  return projectRequester.put(`/labels/${params.path.label_id}`, params.body);
}

function updateLabelScenarios(params: ChangeLabelScenariosRequest) {
  return projectRequester.post(`/labels/${params.path.label_id}/scenarios`, params.body);
}

function getCountScenrarioNodesByTag(params: CountScenarioNodesRequest) {
  return projectRequester.get('/scenarios-graph/count-nodes-by-tag', { params });
}

function updateScenariosPriority(params: UpdateScenariosPriorityRequest) {
  return versionRequester.put(
    '/scenarios-graph-priority/change',
    params,
  );
}

function deleteTag(params: DeleteTagRequest) {
  return projectRequester.delete(`/tags/${params.path.tag_id}`);
}

function getTagInfo(params: GetTagInfoRequest) {
  return projectRequester.get(`/tags/${params.path.tag_id}`);
}

function markDialogResolution({ query, body }: MarkDialogResolutionRequest) {
  return projectRequester.post("/samplings/resolution", body, {
    params: query,
  });
}

function getSamplingsStatistics({ query }: GetSamplingsStatisticsRequest) {
  return projectRequester.get("/samplings/statistics", {
    params: query,
  });
}

function loadAllowedDomains() {
  return projectRequester.get('/allowed-domains');
}

function createAllowedDomain({ body }: CreateAllowedDomainRequest) {
  return projectRequester.post('/allowed-domains', body);
}

function updateAllowedDomain({ path, body }: UpdateAllowedDomainRequest) {
  return projectRequester.put(`/allowed-domains/${path.domain_id}`, body);
}

function deleteAllowedDomain({ path }: DeleteAllowedDomainRequest) {
  return projectRequester.delete(`/allowed-domains/${path.domain_id}`);
}

function getChatterboxMacros({ path }: GetChatterboxMacrosRequest) {
  return projectRequester.get(`/macros/${path.macros_id}`);
}

function getMacrosList({ body }: GetMacrosListRequest) {
  return projectRequester.post('/macros', body);
}

function getSecrets({ path }: GetSecretsRequest) {
  return authorizedRequester.get(`/secrets/${path.project_slug}`);
}

function createSecret({ path, body }: CreateSecretRequest) {
  return authorizedRequester.post(
    `/secrets/${path.project_slug}`,
    body,
  );
}

function updateSecret({ path, body }: UpdateSecretRequest) {
  return authorizedRequester.put(
    `/secrets/${path.project_slug}/${path.secret_slug}`,
    body,
  );
}

function updateSecretDescription({ path, body }: UpdateSecretDescriptionRequest) {
  return authorizedRequester.put(
    `/secrets/${path.project_slug}/${path.secret_slug}/description`,
    body,
  );
}

function deleteSecret({ path }: DeleteSecretRequest) {
  return authorizedRequester.delete(`/secrets/${path.project_slug}/${path.secret_slug}`);
}

function getNodesStatistics({ body }: GetNodesStatisticsRequest) {
  return projectRequester.post('/stat/nodes', body);
}

function getGraphGroups({ path }: GetGraphGroupsRequest) {
  return projectRequester.get(`/scenario-groups/${path.scenario_id}`);
}

function createGraphGroup({ path, body }: CreateGraphGroupRequest) {
  return projectRequester.post(
    `/scenario-groups/${path.scenario_id}`,
    body,
  );
}

function updateGraphGroupsBulk({ path, body }: EditGraphGroupsBulkRequest) {
  return projectRequester.put(
    `/scenario-groups/${path.scenario_id}`,
    body,
  );
}

function updateGraphGroup({ path, body }: EditGraphGroupRequest) {
  return projectRequester.put(
    `/scenario-groups/${path.scenario_id}/group/${path.group_id}`,
    body,
  );
}

function deleteGraphGroup({ path }: DeleteGraphGroupRequest) {
  return projectRequester.delete(
    `/scenario-groups/${path.scenario_id}/group/${path.group_id}`,
  );
}

function getProjectResolutions() {
  return projectRequester.get('/resolution_type');
}

function getQualityStatsByDay({ query }: GetQualityStatsByDayRequest) {
  return projectRequester.get('/quality-stats/by-day', {
    params: query,
  });
}

function getQualityStatsBySamplings({ query }: GetQualityStatsBySamplingRequest) {
  return projectRequester.get('/quality-stats/by-samplings', {
    params: query,
  });
}

function createResolution({ body }: CreateResolutionRequest) {
  return projectRequester.post('/resolution', body);
}

function updateResolution({ query, body }: UpdateResolutionRequest) {
  return projectRequester.patch('/resolution', body, {
    params: query,
  });
}

function getintegrationUsage({ path }: GetIntegrationUsageRequest) {
  return versionRequester.get(`/action-usage/${path.action_id}`);
}

function getScenarioUsage({ query }: GetScenarioUsageRequest) {
  return versionRequester.get(`/scenario-graph/nodes/select-scenario/ref-scenario-usage`, {
    params: query,
  });
}

function updateScenarioUsage({ query, path }: UpdateScenarioUsageRequest) {
  return versionRequester.put(
    `/scenario-graph/nodes/select-scenario/${path.node_id}/included-scenarios`,
    undefined,
    {
      params: query,
    });
}

const apiService = {
  getProjects,
  getNewProjectStatistics,
  getCallsStatistics,
  getTags,
  getFeatures,
  getLines,
  createTag,
  createLine,
  getTasks,
  getTaskLogs,
  createTask,
  createExportExcelTask,
  createFeature,
  deleteFeature,
  updateFeature,
  getFavoriteFeatures,
  addFavoriteFeature,
  deleteFavoriteFeature,
  downloadFile,
  uploadFileS3,
  getDialogsHistory,
  getDialogsHistoryV3,
  getChatInfo,
  sendBotDialogMessage,
  getThresholdDialogs,
  createRecordMarkup,
  updateRecordMarkup,
  updateRecordMark,
  cloneDialog,
  getCalls,
  getCallResult,
  editCall,
  getCallStatistics,
  getCallsDetailsStatistics,
  downloadCallRecord,
  getSamplings,
  createSampling,
  deleteSampling,
  getSamplingsNew,
  createSamplingNew,
  deleteSamplingNew,
  getCustomConfig,
  updateCustomConfig,
  getFullConfiguration,
  getChaneHistory,
  loadVersions,
  createVersion,
  deleteVersion,
  activateVersion,
  getEntities,
  createEntity,
  updateEntity,
  deleteEntity,
  getCustomExtractors,
  getActionSuggest,
  getGraphDebugByChat,
  getGraphDebugById,
  getProjectConfig,
  updateProjectConfig,
  cancelCall,
  createProject,
  generateTexts,
  getProjectModels,
  getProjectCurrentModel,
  getModelDraft,
  udateModelConfig,
  getProjectModelTopics,
  updateModelTopic,
  getModelPlots,
  createProjectConfig,
  getGraphicalScenarios,
  getGraph,
  deleteGraph,
  createScenarioGraph,
  updateScenarioGraph,
  validateNode,
  getScenarioGraphExamples,
  createScenarioGraphExample,
  deleteScenarioGraphExample,
  getAudioFilesMetadata,
  getAudioFile,
  uploadAudioFile,
  updateAudioUserFilename,
  provideSuperUserRole,
  getCommonModels,
  createCommonModel,
  deleteCommonModel,
  getSlots,
  getFreeSlots,
  lockSlot,
  unlockSlot,
  getIntegrations,
  createIntegration,
  updateIntegration,
  deleteIntegration,
  getIntegrationInputFeatures,
  testIntegration,
  getIntegrationsLabels,
  createIntegrationLabel,
  updateIntegrationLabel,
  deleteIntegrationLabel,
  updateLabelIntegrations,
  getButterflyAvailability,
  convertPredicate,
  uploadExternalPhoneIdsFile,
  getCurrentVersion,
  reloadDraftVersion,
  getClustersOverview,
  getClusterDetails,
  getLanguages,
  translateText,
  getScenarioGraphs,
  switchScenarioAvailability,
  cloneGraphTopic,
  getFilesMetaByTaskType,
  getCustomIntegrations,
  createCustomIntegration,
  updateCustomIntegration,
  deleteCustomIntegration,
  getCustomIntegrationActions,
  createCustomIntegrationAction,
  updateCustomIntegrationAction,
  deleteCustomIntegrationAction,
  getCustomIntegrationCallbacks,
  createCustomIntegrationCallback,
  updateCustomIntegrationCallback,
  deleteCustomIntegrationCallback,
  validateIntegrationTemplate,
  getProjectsLinkedToIntegration,
  linkIntegrationAndProject,
  unlinkIntegrationFromProject,
  getProjectUsersList,
  addUserToProject,
  getAllUsers,
  getUserById,
  updateUserGroup,
  removeUserFromProject,
  createUserByProvider,
  getReleaseNotes,
  createReleaseNote,
  getTheLatestReleaseNode,
  updateReleaseNote,
  deleteReleaseNote,
  getGamesList,
  getGameData,
  sendGameResult,
  uploadMediaFile,
  deleteMediaFile,
  getMediaFile,
  getProjectAuthData,
  putProjectAuthData,
  getTrashPhrases,
  getProjectExamples,
  deleteMarkupExamples,
  toggleExampleReferenceStatus,
  getProviders,
  createProvider,
  updateProvider,
  deleteProvider,
  getMessengerAccounts,
  createMessengerAccount,
  updateMessengerAccount,
  deleteMessengerAccount,
  changeMessengerEnableStatus,
  getClients,
  createClient,
  getClientDetails,
  updateClientDetails,
  linkClientAndUser,
  linkClientAndIntegration,
  getClientPerson,
  createPerson,
  updatePerson,
  getProjectVoiceConfig,
  updateProjectVoiceConfig,
  switchProjectVoiceConfig,
  getScenarioLabels,
  createScenarioLabel,
  deleteScenarioLabel,
  updateScenarioLabel,
  updateLabelScenarios,
  getCountScenrarioNodesByTag,
  deleteTag,
  getTagInfo,
  markDialogResolution,
  getSamplingsStatistics,
  loadAllowedDomains,
  createAllowedDomain,
  deleteAllowedDomain,
  updateAllowedDomain,
  getChatterboxMacros,
  getMacrosList,
  addProjectModel,
  deleteProjectModel,
  getSecrets,
  createSecret,
  updateSecret,
  updateSecretDescription,
  deleteSecret,
  getNodesStatistics,
  getGraphGroups,
  createGraphGroup,
  updateGraphGroup,
  updateGraphGroupsBulk,
  deleteGraphGroup,
  getQualityStatsByDay,
  getQualityStatsBySamplings,
  getProjectResolutions,
  createResolution,
  updateResolution,
  getintegrationUsage,
  updateScenariosPriority,
  getScenarioUsage,
  updateScenarioUsage,
  getAnalyticsForProject,
  getNotAutomatedIterations,
  getPreviousAutomatedResponseNodes,
  getLastAutomatedResponseNode,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(apiResponse: AxiosError<any>): string {
  if (apiResponse.code === "ERR_NETWORK") {
    return i18n.t("ERRORS.COMMON.ERR_NETWORK");
  }

  // eslint-disable-next-line no-prototype-builtins
  const hasDetails: boolean = (apiResponse.response?.data || {}).hasOwnProperty('details');
  if (apiResponse.message === 'Request failed with status code 404') {
    return i18n.t("ERRORS.COMMON.METHOD_NOT_FOUND");
  }

  if (apiResponse.message === 'Request failed with status code 504') {
    return i18n.t("ERRORS.COMMON.GATEWAY_TIMEOUT");
  }

  if (hasDetails) {
    const details = apiResponse.response?.data.details || "";
    const reason = details?.reason || "";

    return reason || details || i18n.t("ERRORS.COMMON.UNKNOWN");
  }

  let errorMessage;
  try {
    errorMessage = apiResponse.response?.data.message;
  } catch {
    errorMessage = i18n.t("ERRORS.COMMON.UNKNOWN") as string;
  }

  return errorMessage;
}

export type ApiResponse<T> = {
  data: T;
  status: number;
  message?: string;
  response?: {
    data: {
      details?: {
        reason: string;
      }
      message: string;
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any;
}

export default apiService;
