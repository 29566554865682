import { objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import { ScenarioGraphGroupBackend } from "../backendModels/ScenarioGraphGroupBackend";
import { ScenarioGraphGroup, isGroupNew } from "../models/ScenarioGraphGroup";

export function parseScenarioGraphGroup(
  value: ScenarioGraphGroupBackend,
): ScenarioGraphGroup {
  return {
    ...objSnakeToCamelDeep(value),
    id: String(value.id),
  };
}

export function parseScenarioGraphGroupToBackend(
  value: ScenarioGraphGroup,
): ScenarioGraphGroupBackend {
  return {
    ...objCamelToSnakeDeep(value),
    id: isGroupNew(value) ? 0 : Number(value.id),
  };
}
