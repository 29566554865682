import {
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import postYaMetric, { YaMetricsActions } from '../../services/ya-metrics';
import modalService, { ModalProps } from '../../services/modal';

import { ScenarioGraphsItem } from '../../redux/scenarioGraphs/types';
import { getScenarioGraphs, getScenarioGraphsLoading } from '../../redux/scenarioGraphs/selectors';
import * as actions from '../../redux/scenarioGraphs/actions';

import Select, { SelectConfig } from '../../components-new/select/configurable';

import Modal from '../components/ndModal';
import authorizedPages from '../../routes/authorized';
import navigationService from '../../services/navigation';

const CREATE_SCENARIO = 'createScenario';

export type ScenarioSelectModalProps = {
  title?: ReactNode,
  className?: string,
  defaultScenarioSlug?: string,
  onConfirm: (slug: string) => void,
  onCancel?: () => void,
};

export default function ScenarioSelectModal({
  title,
  className = '',
  defaultScenarioSlug,
  onConfirm,
  onCancel,
  close,
}: ScenarioSelectModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState<ScenarioGraphsItem['slug']>('');

  const scenarios: ScenarioGraphsItem[] = useSelector(
    getScenarioGraphs,
    shallowEqual,
  );

  const scenariosLoading: boolean = useSelector(getScenarioGraphsLoading);

  const handleConfirmClick = useCallback(
    () => {
      if (!selected) return;

      onConfirm(selected);
      close();
    },
    [onConfirm, selected, close],
  );

  const handleCloseClick = useCallback(
    () => {
      if (onCancel) {
        onCancel();
      }
      close();
    },
    [onCancel, close],
  );

  const selectConfig: SelectConfig = {
    options: scenarios,
    labelFunction: (scenario: ScenarioGraphsItem) => scenario.title,
    valueFunction: (scenario: ScenarioGraphsItem) => scenario.slug,
  };

  const extraOptions = useMemo<{ value: string; label: string; }[] | undefined>(
    () => ([{
      value: CREATE_SCENARIO,
      label: `+ ${t('COMMON.CREATE_SCENARIO')}`,
    }]),
    [t],
  );

  const handleCreateScenario = useCallback(
    () => {
      postYaMetric(YaMetricsActions.trainingClusteringCreateScenarioClick);
      modalService.openCreateScenario({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleChange = useCallback(
    (slug: string) => {
      if (slug === CREATE_SCENARIO) {
        handleCreateScenario();
      } else {
        setSelected(slug);
      }
    },
    [handleCreateScenario],
  );

  const selectedScenario = useMemo(
    () => (
      scenarios.find(s => s.slug === selected)
    ),
    [scenarios, selected],
  );

  useEffect(
    () => {
      if (!defaultScenarioSlug || scenariosLoading || scenarios.length === 0) return;

      const exist = scenarios.some(item => item.slug === defaultScenarioSlug);

      if (exist) {
        setSelected(defaultScenarioSlug);
      }
    },
    [defaultScenarioSlug, scenarios, scenariosLoading],
  );

  useEffect(() => {
    dispatch(actions.loadScenarios.request());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={className}
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.READY')}
      title={title}
      onCancel={handleCloseClick}
      onConfirm={handleConfirmClick}
      confirmDisabled={!selected}
    >
      <div className="column-2">
        <Select
          fluid
          placeholder={t('MODALS.SCENARIO_SELECT.SELECT_PLACEHOLDER')}
          extraOptions={extraOptions}
          config={selectConfig}
          value={selected}
          isLoading={scenariosLoading}
          usePortal
          onChange={handleChange}
        />
        {!!selectedScenario && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={navigationService.getPageNavigationLink(
              authorizedPages.editor,
              { scenario_id: selectedScenario.id },
            )}
            target="_blank"
          >
            {t('MODALS.SCENARIO_SELECT.GO_TO_SCENARIO')}
          </a>
        )}
      </div>
    </Modal>
  );
}
