/* eslint-disable camelcase */

import { IconTypes } from "../../components-new/icon";
import { ScenarioGroupName } from "../backendModels/ScenarioGraphBackend";
import { ScenarioGraphLabel } from "./ScenarioGraphLabel";

export const getScenarioTypeIcon = (name: ScenarioGroupName): IconTypes | undefined => {
  switch (name) {
    case ScenarioGroupName.greeting:
      return 'hand';
    case ScenarioGroupName.main:
      return 'curvedPath';
    case ScenarioGroupName.fallback:
      return 'lightning';
    case ScenarioGroupName.pre:
      return 'flasher';
    case ScenarioGroupName.feedback:
      return 'thumbUp';
    default:
      return undefined;
  }
};

export const sortScenarioTypes = (first: ScenarioGroupName, second: ScenarioGroupName) => {
  if (first === ScenarioGroupName.greeting) return -1;
  if (second === ScenarioGroupName.greeting) return 1;

  if (first === ScenarioGroupName.main) return -1;
  if (second === ScenarioGroupName.main) return 1;

  if (first === ScenarioGroupName.fallback) return -1;
  if (second === ScenarioGroupName.fallback) return 1;

  return 0;
};

export type ScenarioGraphMetaText = ScenarioGraphMeta & Partial<{
  totalDialogsCount: number;
  endedDialogsCount: number;
  interruptedDialogsCount: number;
}>;

export enum CallStatus {
  new = 'new',
  ended = 'ended',
  inProgress = 'inProgress',
  paused = 'paused',
}

export type ScenarioGraphMetaVoice = ScenarioGraphMeta & Partial<{
  totalPhonesCount: number,
  connectedCount: number,
  callStatus: CallStatus,
  date: string,
}>;

export type ScenarioStatus = 'new' | 'published' | 'changed';

export type ScenarioGraphMeta = {
  id: string;
  title: string;
  slug: string;
  topicId: string;
  topicSlug: string;
  valid: boolean;
  available: boolean;
  automatable: boolean;
  updatedAt?: Date; // format: date-time
  status?: ScenarioStatus;
  groupName?: ScenarioGroupName;
  totalAdditionExamples?: number;
  totalExceptionExamples?: number;
  labels?: ScenarioGraphLabel[];
}

export type ScenarioGraphs = {
  total: number;
  scenarios: ScenarioGraphMeta[];
}
