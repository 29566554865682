import { FC } from 'react';
import { SvgProps } from '../types';

const ChevronUpIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 14.5L12 10L16.5 14.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ChevronUpIcon;
