import { Reducer } from "redux";
import { createReducer, ActionType } from "typesafe-actions";
import { CacheItem } from "../../services/cache/cacheItem";
import cacheService from "../../services/cache/cacheService";

import * as actions from './actions';

export type SidebarState = {
  opened: boolean,
  openedItems: string[],
}

const initialState: SidebarState = {
  opened: true,
  openedItems: cacheService.getItem(CacheItem.SIDEBAR_OPENED_ITEMS, []),
};

export const sidebarReducer: Reducer<SidebarState> = createReducer(initialState)
  .handleAction(
    actions.openItem,
    (state: SidebarState, { payload: itemId }: ActionType<typeof actions.openItem>): SidebarState => {
      const itemOpened = state.openedItems.includes(itemId);

      if (!itemOpened || itemId) {
        return {
          ...state,
          openedItems: [...state.openedItems, itemId],
        };
      }
      return {
        ...state,
      };
    },
  )
  .handleAction(
    actions.closeItem,
    (state: SidebarState, { payload: itemId }: ActionType<typeof actions.closeItem>): SidebarState => {
      const itemOpened = state.openedItems.includes(itemId);

      if (itemOpened || itemId) {
        return {
          ...state,
          openedItems: state.openedItems.filter(id => id !== itemId),
        };
      }
      return {
        ...state,
      };
    },
  )
  .handleAction(
    actions.openSidebar,
    (state: SidebarState): SidebarState => ({
      ...state,
      opened: true,
    }),
  )
  .handleAction(
    actions.closeSidebar,
    (state: SidebarState): SidebarState => ({
      ...state,
      opened: false,
    }),
  );
