import React from 'react';
import { SvgProps } from '../types';

function SleepingRobot({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 1C16.4477 1 16 1.44772 16 2C16 2.55228 16.4477 3 17 3H19.4194L16.7191 6.37531C16.479 6.67548 16.4322 7.08672 16.5987 7.43319C16.7652 7.77965 17.1156 8 17.5 8H22C22.5523 8 23 7.55228 23 7C23 6.44772 22.5523 6 22 6H19.5806L22.2809 2.6247C22.521 2.32453 22.5678 1.91328 22.4013 1.56681C22.2348 1.22035 21.8844 1 21.5 1H17Z"
        fill={color}
      />
      <path
        d="M11.25 6C11.25 5.58579 11.5858 5.25 12 5.25H14.7C14.9883 5.25 15.2511 5.41526 15.376 5.67511C15.5009 5.93496 15.4658 6.24339 15.2857 6.46852L13.8605 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H12.3C12.0117 9.75 11.7489 9.58474 11.624 9.32489C11.4991 9.06504 11.5342 8.75661 11.7143 8.53148L13.1395 6.75H12C11.5858 6.75 11.25 6.41421 11.25 6Z"
        fill={color}
      />
      <path
        d="M5.44614 15.1676C5.90594 14.8616 6.5267 14.9863 6.83265 15.4461C6.93059 15.5933 7.08381 15.733 7.29104 15.8368C7.49851 15.9406 7.74334 15.9996 7.99947 16.0001C8.2556 16.0006 8.50086 15.9425 8.70908 15.8393C8.91707 15.7362 9.07126 15.5969 9.17015 15.4498C9.47822 14.9914 10.0996 14.8696 10.5579 15.1776C11.0163 15.4857 11.1381 16.107 10.8301 16.5654C10.5224 17.0233 10.0923 17.3859 9.59724 17.6313C9.10239 17.8766 8.55055 18.0011 7.99572 18.0001C7.4409 17.9991 6.88956 17.8724 6.39572 17.6252C5.90165 17.3778 5.47317 17.0134 5.16757 16.5541C4.86162 16.0943 4.98634 15.4735 5.44614 15.1676Z"
        fill={color}
      />
      <path
        d="M14.8326 15.4461C14.5267 14.9863 13.9059 14.8616 13.4461 15.1676C12.9863 15.4735 12.8616 16.0943 13.1676 16.5541C13.4732 17.0134 13.9017 17.3778 14.3957 17.6252C14.8896 17.8724 15.4409 17.9991 15.9957 18.0001C16.5505 18.0011 17.1024 17.8766 17.5972 17.6313C18.0923 17.3859 18.5224 17.0233 18.8301 16.5654C19.1381 16.107 19.0163 15.4857 18.5579 15.1776C18.0996 14.8696 17.4782 14.9914 17.1701 15.4498C17.0713 15.5969 16.9171 15.7362 16.7091 15.8393C16.5009 15.9425 16.2556 16.0006 15.9995 16.0001C15.7433 15.9996 15.4985 15.9406 15.291 15.8368C15.0838 15.733 14.9306 15.5933 14.8326 15.4461Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C3.79086 11 2 12.7909 2 15V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V15C22 12.7909 20.2091 11 18 11H6ZM6 13C4.89543 13 4 13.8954 4 15V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V15C20 13.8954 19.1046 13 18 13H6Z"
        fill={color}
      />
    </svg>
  );
}

export default SleepingRobot;
