import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import authService from '../../services/auth';

import Button from '../../components-new/button';

import './styles.scss';

export default function NoProjectsPage() {
  const { t } = useTranslation();
  const handleLogoutClick = useCallback(
    () => {
      authService.logout();
    },
    [],
  );

  return (
    <div className="no-projects-page">
      <div className="no-projects-page__message-container">
        <div className="column-2">
          <span>{t("PAGE_NO_PROJECTS.MSG_NO_PROJECTS")}</span>
          <Button
            className="autosize"
            bordered
            onClick={handleLogoutClick}
          >
            {t("PAGE_NO_PROJECTS.LOGOUT_FROM_ACCOUNT")}
          </Button>
        </div>
      </div>
    </div>
  );
}
