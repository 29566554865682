import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { block } from "bem-cn";

import { ModalProps } from "../../services/modal";

import { Model, ModelComparisonType } from "../../types/models/CommonModel";
import { NewSampling } from "../../types/models/Sampling";

import { getCommonModels, getComparisonCreatingStatus } from "../../redux/models/selectors";
import { getNewSamplingsState } from "../../redux/samplings/selectors";

import Select, { SelectConfig } from "../../components-new/select/configurable";
import Modal from "../components/ndModal";
import { FLOATING_PLACEHOLDER_COMPONENTS } from "../../components-new/select/configs/floatingPlaceholderComponents";

import { loadCommonModels } from "../../redux/models/actions";

import './styles.scss';

const b = block("compare-models-modal");

export type CompareModelsModalProps = {
  onSubmit: (params: {
    models: Model[],
    sampling?: NewSampling,
  }) => void;
};

function CompareModelsModal({
  onSubmit,
  close,
}: CompareModelsModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modelsList = useSelector(getCommonModels);
  const samplings = useSelector(getNewSamplingsState);
  const creating = useSelector(getComparisonCreatingStatus);

  const [models, setModels] = useState<Model[]>([]);
  const [comparisonType, setComparisonType] = useState<ModelComparisonType>(ModelComparisonType.sampling);
  const [sampling, setSampling] = useState<NewSampling | null>(null);

  const handleModelsChange = useCallback(
    (modelIds: Array<Model['id']>) => {
      const newModels = modelsList.list.filter(m => modelIds.includes(m.id));

      setModels(newModels);
    },
    [modelsList.list],
  );

  const handleSamplingChange = useCallback(
    (samplingId: NewSampling['id']) => {
      const newSampling = samplings.value.find(s => s.id === samplingId);

      if (!newSampling) return;

      setSampling(newSampling);
    },
    [samplings],
  );

  const handleSubmit = useCallback(
    () => {
      if (comparisonType === ModelComparisonType.sampling) {
        if (!sampling) return;

        onSubmit({
          models,
          sampling,
        });
      }
    },
    [comparisonType, models, onSubmit, sampling],
  );

  const modelsConfig: SelectConfig = {
    options: modelsList.list,
    valueFunction: (m: Model) => m.id,
    labelFunction: (m: Model) => `${m.title} (${t("COMMON.VERSION")} ${m.version})`,
  };

  const comparisonTypeConfig: SelectConfig = {
    options: [
      ModelComparisonType.sampling,
    ],
    labelFunction: (type: ModelComparisonType) => t(`MODALS.COMPARE_MODELS.ON_${type.toUpperCase()}`),
    valueFunction: (type: ModelComparisonType) => type,
  };

  const samplingsConfig: SelectConfig = {
    options: samplings.value,
    valueFunction: (s: NewSampling) => s.id,
    labelFunction: (s: NewSampling) => s.title,
  };

  const selectedModelsIds = useMemo(
    () => models.map(m => m.id),
    [models],
  );

  const isValid = useMemo(
    () => {
      if (models.length !== 2) return false;

      if (comparisonType === ModelComparisonType.sampling && !sampling) return false;

      return true;
    },
    [comparisonType, models.length, sampling],
  );

  useEffect(
    () => {
      dispatch(loadCommonModels.request());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      title={t("MODALS.COMPARE_MODELS.TITLE")}
      close={close}
      className={b()}
      confirmationText={t("MODALS.COMPARE_MODELS.COMPARE")}
      confirmDisabled={!isValid || creating}
      onConfirm={handleSubmit}
    >
      <div className="column-2">
        <Select
          isMulti
          isLoading={modelsList.loading}
          config={modelsConfig}
          value={selectedModelsIds}
          onChange={handleModelsChange}
          placeholder={t("MODALS.COMPARE_MODELS.MODELS_TO_COMPARE")}
          components={FLOATING_PLACEHOLDER_COMPONENTS}
          closeMenuOnSelect={false}
          usePortal
        />
        <Select
          config={comparisonTypeConfig}
          value={comparisonType}
          onChange={setComparisonType}
          placeholder={t("MODALS.COMPARE_MODELS.COMPARE_ON")}
          components={FLOATING_PLACEHOLDER_COMPONENTS}
          usePortal
          isDisabled
        />
        {comparisonType === ModelComparisonType.sampling && (
          <Select
            config={samplingsConfig}
            value={sampling?.id || ""}
            onChange={handleSamplingChange}
            placeholder={t("ENTITIES.SAMPLING")}
            components={FLOATING_PLACEHOLDER_COMPONENTS}
            usePortal
          />
        )}
      </div>
    </Modal>
  );
}

export default CompareModelsModal;
