import { SagaIterator } from 'redux-saga';
import {
  call, fork, put, takeLatest,
} from "redux-saga/effects";

import * as actions from './actions';
import Api, { ApiResponse } from '../../services/api';
import i18n from '../../services/i18n';
import { ErrorAction } from '../reducers';
import { GetAllUsersResponse } from '../../types/requests/UserAccesses';
import { objSnakeToCamelDeep } from '../../services/helpers/utilities';

function* loadUsers(): SagaIterator {
  yield takeLatest(actions.loadUsers.request, function* () {
    try {
      const response: ApiResponse<GetAllUsersResponse> = yield call(Api.getAllUsers);
      yield put(actions.loadUsers.success(response.data.users.map(u => objSnakeToCamelDeep(u))));
    } catch (err) {
      yield put(actions.loadUsers.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.USER.MULTIPLE") }))));
    }
  });
}

function* userSagas() {
  yield fork(loadUsers);
}

export default userSagas;
