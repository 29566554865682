import { Reducer } from "redux";
import { createReducer, ActionType } from "typesafe-actions";

import * as actions from './actions';
import * as projectActions from '../projects/actions';
import * as scenarioActions from '../scenarioGraphs/actions';

import { getEmptyScenarioGraph, ScenarioGraph } from "../../types/models/ScenarioGraph";
import { SelectScenarioAction } from "../../types/models/ScenarioGraphAction";
import { generateId } from "../../services/helpers/generateId";
import { ScenarioGraphNode } from "../../types/models/ScenarioGraphNode";
import { GraphStatisticsFilters, NodeStatistics } from "../../types/models/GraphStatistics";
import { changeVersionToDisplay } from "../versions/actions";
import { deleteTag } from "../tags/actions";
import { loadGraphGroups } from "../graphGroups/actions";

export type ScenarioGraphState = {
  activeGraph: ScenarioGraph,
  savedGraph: ScenarioGraph,
  highlightFreeLines: boolean,
  modifiedNodeId: string | null;
  graphList: {
    loading: boolean;
    value: ScenarioGraph[];
    total: number;
  },
  loading: boolean;
  searchResults: string[] | null;
  statistics: {
    nodes: Record<ScenarioGraphNode['id'], NodeStatistics>;
    filters: GraphStatisticsFilters,
    loading: boolean;
    enabled: boolean;
  }
}

const initialState: ScenarioGraphState = {
  activeGraph: getEmptyScenarioGraph(),
  savedGraph: getEmptyScenarioGraph(),
  highlightFreeLines: false,
  modifiedNodeId: null,
  graphList: {
    loading: false,
    value: [],
    total: 0,
  },
  loading: false,
  searchResults: null,
  statistics: {
    filters: {
      dateFrom: new Date(),
    },
    loading: false,
    enabled: false,
    nodes: {},
  },
};

export const scenarioGraphReducer: Reducer<ScenarioGraphState> = createReducer<ScenarioGraphState>(initialState)
  .handleAction(
    actions.changeActiveGraph,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof actions.changeActiveGraph>,
    ): ScenarioGraphState => ({
      ...state,
      activeGraph: payload,
    }),
  )
  .handleAction(
    projectActions.changeProject,
    (): ScenarioGraphState => initialState,
  )
  .handleAction(
    actions.highlightFreeLines,
    (state: ScenarioGraphState) => ({
      ...state,
      highlightFreeLines: true,
    }),
  )
  .handleAction(
    actions.cancelFreeLinesHighlight,
    (state: ScenarioGraphState) => ({
      ...state,
      highlightFreeLines: false,
    }),
  )
  .handleAction(
    actions.loadGraphList.request,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      graphList: {
        ...state.graphList,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadGraphList.success,
    (
      state: ScenarioGraphState,
      { payload: list }: ActionType<typeof actions.loadGraphList.success>,
    ): ScenarioGraphState => ({
      ...state,
      graphList: {
        ...state.graphList,
        value: list,
        total: list.length,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadGraphList.failure,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      graphList: {
        ...state.graphList,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createGraph.request,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.createGraph.success,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createGraph.failure,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.updateGraph.request,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.updateGraph.success,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.updateGraph.failure,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadGraphById.request,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadGraphById.success,
    (
      state: ScenarioGraphState,
      { payload: activeGraph }: ActionType<typeof actions.loadGraphById.success>,
    ): ScenarioGraphState => ({
      ...initialState,
      activeGraph,
      savedGraph: activeGraph,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadGraphById.failure,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.resetActiveGraph,
    (): ScenarioGraphState => ({
      ...initialState,
    }),
  )
  .handleAction(
    actions.createNestedGraph.success,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof actions.createNestedGraph.success>,
    ): ScenarioGraphState => ({
      ...state,
      activeGraph: {
        ...state.activeGraph,
        hash: generateId(),
        nodes: state.activeGraph.nodes.map(n => {
          if (n.id !== payload.nodeId) return n;

          const currentScenarios = (n.action as SelectScenarioAction).scenarios || [];

          return {
            ...n,
            action: {
              ...n.action,
              scenarios: currentScenarios.concat(payload.scenarioId),
            } as SelectScenarioAction,
          };
        }),
      },
    }),
  )
  .handleAction(
    actions.setSearchResults,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof actions.setSearchResults>,
    ): ScenarioGraphState => ({
      ...state,
      searchResults: payload,
    }),
  )
  .handleAction(
    actions.resetSearchResults,
    (state: ScenarioGraphState): ScenarioGraphState => ({
      ...state,
      searchResults: null,
    }),
  )
  .handleAction(
    scenarioActions.activateScenario.success,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      activeGraph: {
        ...state.activeGraph,
        available: true,
      },
    }),
  )
  .handleAction(
    scenarioActions.deactivateScenario.success,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      activeGraph: {
        ...state.activeGraph,
        available: false,
      },
    }),
  )
  .handleAction(
    actions.setGraphLoadingStatus,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof actions.setGraphLoadingStatus>,
    ): ScenarioGraphState => ({
      ...state,
      loading: payload,
    }),
  )
  .handleAction(
    actions.loadNodesStatistics.request,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      statistics: {
        ...state.statistics,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadNodesStatistics.success,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof actions.loadNodesStatistics.success>,
    ): ScenarioGraphState => {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          loading: false,
          nodes: payload.reduce<Record<ScenarioGraphNode['id'], NodeStatistics>>(
            (accum, statistics) => {
              return {
                ...accum,
                [statistics.nodeId]: statistics,
              };
            },
            state.statistics.nodes,
          ),
        },
      };
    },
  )
  .handleAction(
    actions.loadNodesStatistics.failure,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      statistics: {
        ...state.statistics,
        loading: false,
      },
    }),
  )
  .handleAction(
    changeVersionToDisplay,
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      statistics: {
        ...state.statistics,
        nodes: {},
      },
    }),
  )
  .handleAction(
    actions.setStatsEnabled,
    (
      state: ScenarioGraphState,
      { payload: statsEnabled }: ActionType<typeof actions.setStatsEnabled>,
    ): ScenarioGraphState => ({
      ...state,
      statistics: {
        ...state.statistics,
        nodes: statsEnabled ?
          state.statistics.nodes : {},
        enabled: statsEnabled,
      },
    }),
  )
  .handleAction(
    deleteTag.success,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof deleteTag.success>,
    ): ScenarioGraphState => ({
      ...state,
      activeGraph: {
        ...state.activeGraph,
        hash: generateId(),
        nodes: state.activeGraph.nodes.map(n => ({
          ...n,
          tags: n.tags.filter(t => t.id !== payload),
        })),
      },
    }),
  )
  .handleAction(
    [
      loadGraphGroups.request,
      loadGraphGroups.success,
    ],
    (
      state: ScenarioGraphState,
    ): ScenarioGraphState => ({
      ...state,
      activeGraph: {
        ...state.activeGraph,
        hash: generateId(),
      },
    }),
  )
  .handleAction(
    actions.setGraphStatisticsFilters,
    (
      state: ScenarioGraphState,
      { payload }: ActionType<typeof actions.setGraphStatisticsFilters>,
    ): ScenarioGraphState => ({
      ...state,
      statistics: {
        ...state.statistics,
        filters: payload,
        nodes: {},
      },
    }),
  );
