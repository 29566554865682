import { AppState } from "../reducers";

export function getModelSlots(state: AppState, modelId: string) {
  if (state.slots.slots.modelId !== modelId) {
    return {
      list: [],
      loading: false,
    };
  }

  return state.slots.slots;
}

export function getFreeSlots(state: AppState) {
  return state.slots.freeSlots;
}
