import { ScenarioGraphValidationResultBackend } from "../backendModels/ScenarioGraphValidationResultBackend";
import { ScenarioGraphValidationResult } from "../models/ScenarioGraphValidationResult";

export function parseScenarioGraphValidationResult(
  validationBackend: ScenarioGraphValidationResultBackend,
): ScenarioGraphValidationResult {
  return {
    description: validationBackend.description || "",
    isValid: validationBackend.is_valid,
  };
}

export function parseScenarioGraphValidationResultToBackend(
  value: ScenarioGraphValidationResult,
): ScenarioGraphValidationResultBackend {
  return {
    description: value.description,
    is_valid: value.isValid,
  };
}
