import { SagaIterator } from 'redux-saga';
import {
  takeLatest, call, put, fork, select,
} from 'redux-saga/effects';
import { endOfDay } from 'date-fns';

import {
  GetProjectConfigResponse,
  GetProjectsResponse,
  UpdateProjectConfigRequest,
  CreateProjectRequest,
  CreateProjectResponse,
  CreateProjectConfigRequest,
} from '../../types/requests/Projects';
import NotificationManager from "../../services/notifications";
import Access, { Capability } from '../../services/access';
import * as actions from './actions';
import * as graphScenariosActions from '../scenarioGraphs/actions';
import * as tagsActions from '../tags/actions';
import * as featuresActions from '../features/actions';
import * as linesActions from '../lines/actions';
import * as samplingsActions from '../samplings/actions';
import * as entitiesActions from '../entities/actions';
import * as langActions from '../languages/actions';
import * as userActions from '../user/actions';
import * as releaseNotesActions from '../releaseNotes/actions';
import * as configsActions from '../configs/actions';
import { getEmptyProject, isVoiceProject, Project, projectEmpty } from '../../types/models/Project';
import { getProjectStatisticsFilters, getProjects, getSelectedProject } from './selectors';
import { DialogsStatisticsRequest, DialogsStatisticsResponse } from '../../types/requests/ProjectStatistics';
import { getCurrentUser } from '../user/selectors';
import { User } from '../../types/models/User';
import { notificationErrors } from '../../messages/notifications';
import apiService, { ApiResponse } from '../../services/api';
import AuthService from '../../services/auth';
import { uploadFileS3 } from '../files/sagas';
import { objCamelToSnake, wrapErrorMessage } from '../../services/helpers/utilities';
import i18n from '../../services/i18n';
import { parseProjectConfig, parseProjectConfigToBackend } from '../../types/parsers/ProjectConfigParser';
import { ProjectConfigBackend } from '../../types/backendModels/ProjectConfigBackend';
import modalService from '../../services/modal';
import { ErrorAction } from '../reducers';
import { getEmptyProjectConfig } from '../../types/models/ProjectConfig';
import { parseDialogsStatisticsGroup, parseDialogsStatistics } from '../../types/parsers/AutomationStatistics';
import navigationService from '../../services/navigation';
import { CallsDetailsStatisticsRequest, CallsStatisticsResponse, CallsStatisticsDetailsResponse, CallsStatisticsRequest } from '../../types/requests/CallsStatistics';
import { parseCallsDetailedStatistics, parseCallsStatistics } from '../../types/parsers/CallsStatistics';
import { AccessesMap, UserAccessBackend } from '../../types/backendModels/UserAccessesBackend';
import { msToSeconds, parseUserAccesses, placeStatisticsIntoDates } from './helpers';
import { Filters } from '../../pages/account/components/filters';
import { FiltersWithDirection } from '../../pages/account/components/callsTab';
import { DetailedCallsStatisticPeriod } from '../../types/models/CallsStatistics';
import { dateLocalToMsc } from '../../services/helpers/dateUtils';

function* changeProject(): SagaIterator {
  yield takeLatest(actions.changeProject, function* (action) {
    yield put(actions.selectProject(action.payload));
  });
}

function* setSelectedProject(): SagaIterator {
  yield takeLatest(actions.selectProject, function* (action) {
    try {
      const project: Project = action.payload;
      const user: User = yield select(getCurrentUser);
      if (!user.id) {
        throw new Error(i18n.t(notificationErrors.noUser));
      }
      Access.setCapabilities(project.capabilities);
      if (!projectEmpty(project)) {
        try {
          navigationService.addSearchParam('project', project.slug);
          localStorage.setItem('selectedProject', JSON.stringify(project));
          yield put(langActions.loadLanguages.request());
          yield put(tagsActions.loadTags.request());
          yield put(featuresActions.loadFeatures.request());
          yield put(linesActions.loadLines.request());
          yield put(samplingsActions.loadSamplings.request());
          yield put(samplingsActions.loadNewSamplings.request());
          yield put(entitiesActions.loadEntities.request());
          yield put(graphScenariosActions.loadScenarios.request());
          yield put(releaseNotesActions.loadTheLastReleaseNote.request());
          yield put(configsActions.loadCustomConfig.request());

          if (isVoiceProject(project)) {
            yield put(actions.loadProjectConfig.request());
          }
        } catch (err) {
          NotificationManager.error(wrapErrorMessage(err, i18n.t("ERRORS.API.PROJECTS.DATA_LOAD")));
        }
      }
    } catch (err) {
      Access.setCapabilities([]);
    }
  });
}

function* loadProjects(): SagaIterator {
  yield takeLatest(actions.loadProjects.request, function* () {
    try {
      const projectsResponse: ApiResponse<GetProjectsResponse> = yield call(apiService.getProjects);
      const accessesResponse: {
        projects: UserAccessBackend[],
        accessesMap: AccessesMap,
      } = yield call(AuthService.retrieveUserAccesses);

      const userAccesses = parseUserAccesses(accessesResponse.projects);
      yield put(userActions.setAccesses(userAccesses));
      yield put(userActions.setAccessesMap(accessesResponse.accessesMap));

      const projects = projectsResponse.data.projects.map(project => {
        project.capabilities = (accessesResponse.projects
          .find(access => access.project_slug === project.slug)?.capabilities || []) as Capability[];

        return project;
      });

      yield put(actions.loadProjects.success(projects));

      const selectedProject: Project = yield select(getSelectedProject);
      if (projectEmpty(selectedProject)) {
        // Автовыбор проект
        yield autoSelectProject();
      }
    } catch (err) {
      yield put(actions.loadProjects.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.LOAD"),
      )));
    }
  });
}

function* autoSelectProject() {
  const projectList: Project[] = yield select(getProjects);

  const navigationProjectSlug = navigationService.getProjectFromNavigation();

  if (navigationProjectSlug) {
    const project: Project | undefined = projectList
      .find((p: Project) => p.slug === navigationProjectSlug);
    if (project && !projectEmpty(project)) {
      yield put(actions.selectProject(project));
      return;
    }
  }

  const localStorageProject = JSON.parse(localStorage.getItem('selectedProject') || JSON.stringify(getEmptyProject()));
  if (localStorageProject && !projectEmpty(localStorageProject)) {
    yield put(actions.selectProject(localStorageProject));
    return;
  }

  const firstProject = projectList[0];
  if (firstProject && !projectEmpty(firstProject)) {
    yield put(actions.selectProject(firstProject));
    return;
  }

  console.warn("Can't autoselect any project: project doesn't exist");
  // Нет проектов
}

function* loadSelectedProjectStatistics(): SagaIterator {
  yield takeLatest(actions.loadDialogsStatistics.request, function* () {
    try {
      const filters: Filters | FiltersWithDirection = yield select(getProjectStatisticsFilters);
      const {
        period,
        dateFrom,
        dateTo,
        tags,
        topics,
        scenarios,
        target,
      } = filters;

      const params: DialogsStatisticsRequest = {
        period_type: period,
        start: msToSeconds(+dateLocalToMsc(dateFrom)),
        ...(dateTo && { end: msToSeconds(+dateLocalToMsc(dateTo)) }),
        topic_slugs: topics.map(t => t.slug),
        scenario_slugs: scenarios.map(s => s.slug),
        split_by: target,
        tags,
      };

      const response: ApiResponse<DialogsStatisticsResponse> = yield call(
        apiService.getNewProjectStatistics,
        params,
      );

      const parsedTopicsStatistics = parseDialogsStatistics(
        response.data.aggregated,
        response.data.topics,
      );

      let chartStatistics = response.data.aggregated.periods
        .map(parseDialogsStatisticsGroup);

      chartStatistics = placeStatisticsIntoDates(
        dateFrom,
        dateTo || endOfDay(new Date()),
        chartStatistics,
        period,
      );

      yield put(actions.loadDialogsStatistics.success({
        topics: parsedTopicsStatistics,
        chart: chartStatistics,
      }));
    } catch (err) {
      // Дефолтное поведение обработки error событий само выводит сообщения об ошибках (
      // миддлвар redux/middlewares/errors: 11-21). Но в случае, если
      // сервер таймаутит, то мы скрываем ошибки, если нет capability
      // hide_internal_errors. Конкретно этот запрос сейчас таймаутит на сервере на проде
      // для некоторых проектов при выборе большого периода (около года).
      // Если прятать сообщение о таймауте (дефолтное поведение без специальной capability),
      // то пользователю может быть непонятно, что произошла ошибка загрузки статистики
      // Т.к. это страница метрик, выводим сообщение явно. В случае, если эта капабилитис есть,
      // то сообщение об ошибке покажется дважды (что, кажется, не так критично)
      NotificationManager.error(i18n.t("ERRORS.API.PROJECTS.STATISTICS_LOAD"));
      yield put(actions.loadDialogsStatistics.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.STATISTICS_LOAD"),
      )));
    }
  });
}

function* loadCallsStatistics(): SagaIterator {
  yield takeLatest(actions.loadCallsStatistics.request, function* (action) {
    try {
      const { groupId } = action.payload;
      const filters: FiltersWithDirection = yield select(getProjectStatisticsFilters);

      const groupBy = groupId ? DetailedCallsStatisticPeriod.BatchId : filters.period;

      const params: CallsStatisticsRequest = {
        group_by: groupBy,
        direction: filters.direction,
        start: msToSeconds(+dateLocalToMsc(filters.dateFrom)),
        ...(filters.dateTo ?
          { end: msToSeconds(+dateLocalToMsc(filters.dateTo)) } :
          { end: msToSeconds(dateLocalToMsc(new Date()).getTime()) }),
        tags: filters.tags,
      };

      const response: ApiResponse<CallsStatisticsResponse> = yield call(
        apiService.getCallsStatistics,
        params,
      );

      const { chartStatistics, data } = parseCallsStatistics(
        response.data.aggregated,
        groupBy,
        filters.direction,
        response.data.groups,
      );

      yield put(actions.loadCallsStatistics.success({ chartStatistics, data, groupId }));
    } catch (err) {
      yield put(actions.loadCallsStatistics.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.STATISTICS_LOAD"),
      )));
    }
  });
}

function* loadCallsDetailedStatistics(): SagaIterator {
  yield takeLatest(actions.loadCallsDetailsStatistics.request, function* (action) {
    try {
      const params: CallsDetailsStatisticsRequest = objCamelToSnake(action.payload);

      if (params.direction === 'incoming') {
        delete params.batch_id;
      } else {
        delete params.start;
        delete params.end;
      }

      const response: ApiResponse<CallsStatisticsDetailsResponse> = yield call(
        apiService.getCallsDetailsStatistics,
        params,
      );

      const parsed = parseCallsDetailedStatistics(response.data.calls);

      yield put(actions.loadCallsDetailsStatistics.success(parsed));
    } catch (err) {
      yield put(actions.loadCallsDetailsStatistics.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.STATISTICS_LOAD"),
      )));
    }
  });
}

function* updateProjectConfig(): SagaIterator {
  yield takeLatest(actions.updateProjectConfig.request, function* (action) {
    try {
      const { config, file } = action.payload;

      let fileId: string = '';

      if (file) {
        fileId = yield call(uploadFileS3, file);
      }

      const parsedConfig: ProjectConfigBackend = {
        ...parseProjectConfigToBackend(config),
        ...(fileId && { template_file_id: fileId }),
      };
      const {
        project_slug: projectSlug,
        ...parsedConfigWithoutProjectSlug
      } = parsedConfig; // Перед сохранением конфига нужно убрать из
      // тела запроса поле project_slug и передать его
      // через query-параметры (требование сервера)

      const params: UpdateProjectConfigRequest = {
        query: {
          project_slug: projectSlug,
        },
        body: parsedConfigWithoutProjectSlug,
      };

      yield call(apiService.updateProjectConfig, params);
      const savedConfig = parseProjectConfig(parsedConfig);
      yield put(actions.updateProjectConfig.success(savedConfig));
    } catch (err) {
      yield put(actions.updateProjectConfig.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.CONFIG_UPDATE"),
      )));
    }
  });
}

function* loadProjectConfig(): SagaIterator {
  yield takeLatest(actions.loadProjectConfig.request, function* () {
    try {
      type T = ApiResponse<GetProjectConfigResponse>;
      const response: T = yield call(apiService.getProjectConfig);

      if (response.status === 204) {
        const emptyConfig = getEmptyProjectConfig();
        yield put(actions.loadProjectConfig.success(emptyConfig));
      } else {
        const config = parseProjectConfig(response.data);
        yield put(actions.loadProjectConfig.success(config));
      }
    } catch (err) {
      yield put(actions.loadProjectConfig.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.CONFIG_LOAD"),
      )));
    }
  });
}

function* createProject(): SagaIterator {
  yield takeLatest(actions.createProject.request, function* (action) {
    try {
      const {
        slug,
        title,
        isChatterbox,
        isAsync,
        modalId,
        type,
        parentProjectSlug,
      } = action.payload;

      const params: CreateProjectRequest = {
        body: {
          slug,
          title,
          is_chatterbox: isChatterbox,
          is_async: isAsync,
          parent_project_slug: parentProjectSlug,
          type,
        },
      };

      const response: ApiResponse<CreateProjectResponse> = yield call(
        apiService.createProject,
        params,
      );

      const newProject = response.data;

      if (!newProject.capabilities) {
        newProject.capabilities = [];
      }

      const createConfigParams: CreateProjectConfigRequest = {
        query: {
          project_slug: newProject.slug,
        },
      };

      yield call(apiService.createProjectConfig, createConfigParams);
      yield put(actions.createProject.success(newProject));
      NotificationManager.info(i18n.t("PROJECTS.MSG_PROJECT_CREATED", { project: newProject.title }));
      modalService.close(modalId || '');
    } catch (err) {
      yield put(actions.createProject.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.PROJECTS.CREATE"),
      )));
    }
  });
}

function* loadStatistics(): SagaIterator {
  yield takeLatest(actions.updateFilters, function* () {
    yield put(actions.loadDialogsStatistics.request());
  });
}

export default function* projectsSagas(): SagaIterator {
  yield fork(changeProject);
  yield fork(setSelectedProject);
  yield fork(loadProjects);
  yield fork(loadSelectedProjectStatistics);
  yield fork(loadCallsStatistics);
  yield fork(loadCallsDetailedStatistics);
  yield fork(loadProjectConfig);
  yield fork(updateProjectConfig);
  yield fork(createProject);
  yield fork(loadStatistics);
}
