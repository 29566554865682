import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { LineBackend } from "../backendModels/LineBackend";
import { Line } from "../models/Line";

export function parseLine(line: LineBackend): Line {
  return objSnakeToCamel<Line>(line);
}

export function parseLineToBackend(line: Line): LineBackend {
  return objCamelToSnake<LineBackend>(line);
}
