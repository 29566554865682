import React from 'react';
import { block } from 'bem-cn';

import MenuWrapper from './menuWrapper';

import './dropdown.scss';

const b = block("project-selector-dropdown");

type DropdownProps = {
  children: unknown,
  isOpen: boolean,
  target: React.ReactChild,
  onClose: () => void,
}

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: DropdownProps) => (
  <div className={b()}>
    {target}
    {isOpen ? <MenuWrapper>{children}</MenuWrapper> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

export default React.memo(Dropdown);
