/* eslint-disable camelcase */
import { SagaIterator } from "@redux-saga/types";
import {
  call,
  fork,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { format, isValid } from "date-fns";

import NotificationManager from "../../services/notifications";
import apiService, { ApiResponse } from "../../services/api";
import i18n from '../../services/i18n';
import { ErrorAction } from "../reducers";

import * as actions from './actions';

import {
  GetClusterDetailsRequest,
  GetClustersDetailResponse,
  GetClustersOverviewRequest,
  GetClustersOverviewResponse,
} from "../../types/requests/Clustering";

import { parseClusters, parseClustersResults } from "../../types/parsers/Clustering";
import { TaskType } from "../../types/models/Task";
import { CreateTaskRequest } from "../../types/requests/Task";

import { loadClusteringLaunchTasks } from '../tasks/actions';
import { ClusteringType } from "../../types/models/Clustering";
import { User } from "../../types/models/User";
import { getCurrentUser } from "../user/selectors";
import { Project } from "../../types/models/Project";
import { getSelectedProject } from "../projects/selectors";

function* loadClustersOverview(): SagaIterator {
  yield takeLatest(actions.loadClusters.request, function* ({ payload }) {
    try {
      const params: GetClustersOverviewRequest = {
        params: {
          header_count: payload.limit || 5,
        },
      };

      type Response = ApiResponse<GetClustersOverviewResponse>;
      const response: Response = yield call(apiService.getClustersOverview, params);

      const { clusters, can_run, created_at } = response.data;
      const createdAt = created_at ? new Date(created_at) : null;

      yield put(
        actions.loadClusters.success({
          clusters: parseClusters(clusters),
          canRun: can_run || false,
          createdAt: createdAt && isValid(createdAt) ?
            format(createdAt, 'dd.MM.yyyy HH:mm') :
            null,
        }),
      );
    } catch (err) {
      const text = i18n.t("ERRORS.API.CLUSTERING.LOAD");
      yield put(actions.loadClusters.failure(new ErrorAction(err, text)));
    }
  });
}

function* launchClustering(): SagaIterator {
  yield takeLatest(actions.launchClustering.request, function* (action) {
    try {
      const { type, samplingId } = action.payload;

      const params: CreateTaskRequest = {
        type: TaskType.clustering,
        params: {
          ...(type === ClusteringType.selection && samplingId && {
            sampling_id: samplingId,
          }),
        },
      };

      yield call(apiService.createTask, params);

      yield put(actions.launchClustering.success());
      yield put(loadClusteringLaunchTasks.request({}));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CLUSTERING.LAUNCH");
      yield put(actions.launchClustering.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadClusterDetails(): SagaIterator {
  yield takeLatest(actions.loadClusterDetails.request, function* (action) {
    try {
      type Response = ApiResponse<GetClustersDetailResponse>;

      const { clusterNumber, limit, offset } = action.payload;

      const params: GetClusterDetailsRequest = {
        params: {
          cluster_number: clusterNumber,
          limit,
          offset,
        },
      };

      const response: Response = yield call(apiService.getClusterDetails, params);

      const clusterItems = parseClustersResults(response.data.cluster_items);
      yield put(actions.loadClusterDetails.success({ clusterNumber, clusterItems }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.CLUSTERING") });
      yield put(actions.loadClusters.failure(new ErrorAction(err, text)));
    }
  });
}

function* uploadMarkupScenarioExamples(): SagaIterator {
  yield takeLatest(actions.setPhrases.request, function* (action) {
    try {
      const user: User = yield select(getCurrentUser);
      const selectedProject: Project = yield select(getSelectedProject);
      const { texts, topicSlug } = action.payload;
      const params: CreateTaskRequest = {
        type: TaskType.uploadMarkup,
        params: {
          markup: texts.map(item => ({
            text: item,
            scenario_slug: topicSlug,
          })),
          user_id: user.id || '',
          project_slug: selectedProject.slug,
        },
      };

      yield call(apiService.createTask, params);
      NotificationManager.info(i18n.t("SCENARIO_GRAPHS.CLUSTERING_MODAL.SAVED"));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CLUSTERING.SAVE");
      yield put(actions.setPhrases.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* clusteringSagas() {
  yield fork(loadClustersOverview);
  yield fork(loadClusterDetails);
  yield fork(launchClustering);
  yield fork(uploadMarkupScenarioExamples);
}
