import React, { memo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import block from "bem-cn";

import { getSidebarOpenStatus } from '../../../redux/sidebar/selectors';
import { openReleaseNotesModal } from '../../../redux/releaseNotes/actions';

import { useForceUpdate } from "../../../hooks/useForceUpdate";

import Icon from "../../../components-new/icon";

const b = block("sidebar-item");

function ReleaseButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();

  const navHelperPositionTop = useRef<number>(0);

  const opened = useSelector(getSidebarOpenStatus);

  const handleOpenReleaseNotes = useCallback(
    () => {
      dispatch(openReleaseNotesModal());
    },
    [dispatch],
  );

  const adjustNavHelperPosition = useCallback(
    (event: React.MouseEvent) => {
      const item = event.currentTarget;
      const box = item.getBoundingClientRect();
      navHelperPositionTop.current = box.y - 6;
      forceUpdate();
    },
    [forceUpdate],
  );

  return (
    <button
      className={b()}
      onClick={handleOpenReleaseNotes}
      onMouseEnter={adjustNavHelperPosition}
    >
      <div className={b("icon-wrapper")}>
        <Icon className={b("icon")} type="bell" />
      </div>
      {opened ? t("SIDEBAR.RELEASES") : (
        <div
          style={{ top: navHelperPositionTop.current + "px" }}
          className={b("navigation-helper")}
        >
          {
            <div
              className={b("navigation-helper-item")}
              onClick={handleOpenReleaseNotes}
            >
              {t("SIDEBAR.RELEASES")}
            </div>
          }
        </div>
      )}
    </button>
  );
}

export default memo(ReleaseButton);
