import { RESOLUTION_TYPE_ERROR_TITLES, RESOLUTION_TYPE_OK_TITLES, getTotalMarkedCount } from "../../types/models/QualityStats";
import { ResolutionType } from "../../types/models/Resolution";
import { NewSampling, Sampling, isAutomaticSampling } from "../../types/models/Sampling";
import { AppState } from "../reducers";

export const getSamplingBySlug = (state: AppState, slug: string): Sampling | undefined => {
  return state.samplings.list.value.find(sampling => sampling.slug === slug);
};

export const getSamplingsState = ({ samplings }: AppState) => samplings.list;
export const getNewSamplingsState = ({ samplings }: AppState) => samplings.newList;
export const getNewSamplingsLoading = ({ samplings }: AppState) => samplings.newList.loading;
export const getCustomNewSamplings = ({ samplings }: AppState) => samplings.newList.value
  .filter(sampling => !isAutomaticSampling(sampling));

export const getSamplingsList = ({ samplings }: AppState) => samplings.list.value;

export const getMarkingModalOpenedStatus = ({ samplings }: AppState) => samplings.markingModal.opened;

export const getActiveSamplingDialogs = ({ samplings }: AppState) => samplings.markingModal.dialogs;

export const getActiveDialogToMark = ({ samplings }: AppState) => (
  samplings.markingModal.dialogs.find(d => d.id === samplings.markingModal.activeDialog.id)
);

export const getActiveSamplingSlug = ({ samplings }: AppState) => (
  samplings.markingModal.samplingId
);

export const getActiveSampling = ({ samplings }: AppState) => (
  samplings.newList.value.find(s => s.id === samplings.markingModal.samplingId)
);

export const getActiveSamplingMarkedDialogsCount = ({ samplings }: AppState): number => {
  const activeSampling = samplings.list.value.find(s => s.slug === samplings.markingModal.samplingId);

  if (!activeSampling) return 0;

  return activeSampling.statistics?.markedCount || 0;
};

export const getActiveSamplingTotalDialogsCount = (state: AppState): number => {
  const stats = getActiveSamplingStats(state);
  return stats ? stats.samplingSize : 0;
};

export const getIsActiveDialogLast = (state: AppState): boolean => {
  const activeDialog = getActiveDialogIndex(state);
  const totalDialogsCount = getActiveSamplingTotalDialogsCount(state);

  return totalDialogsCount <= activeDialog + 1;
};

export const getIsMarkingInProgress = ({ samplings }: AppState) => (
  samplings.markingModal.processing
);

export const getIsMarkDialogsLoading = ({ samplings }: AppState) => (
  samplings.markingModal.loading
);

export const getActiveSamplingStats = ({ samplings }: AppState) => (
  samplings.tableStats.statistics.find(s => s.samplingId === samplings.markingModal.samplingId)
);

export const getActiveDialogIndex = ({ samplings }: AppState) => (
  samplings.markingModal.activeDialog.originalIndex
);

export const getIsDialogRemarking = ({ samplings }: AppState) => (
  samplings.markingModal.remarking
);

export const getIsMarkingFinished = (state: AppState) => {
  const dialogsLoading = getIsMarkDialogsLoading(state);
  const statsItem = getActiveSamplingStats(state);

  if (!statsItem) return false;

  const totalMarked = getTotalMarkedCount(statsItem);

  return !dialogsLoading && totalMarked === statsItem.samplingSize;
};

export const getCreateAutomaticSamplingModalOpenedStatus = ({ samplings }: AppState) => (
  samplings.createAutomaticSamplingModal.opened
);

export const getIsCreatingAutomaticSampling = ({ samplings }: AppState) => (
  samplings.createAutomaticSamplingModal.creating
);

export const getIsAutomaticSamplingCreated = ({ samplings }: AppState) => (
  samplings.createAutomaticSamplingModal.success
);

export const getActiveDialogMeta = ({ samplings }: AppState) => (
  samplings.markingModal.activeDialog
);

export const getQualityStatsByDay = ({ samplings }: AppState) => (
  samplings.chartStats
);

export const getQualityStatsBySampling = ({ samplings }: AppState) => (
  samplings.tableStats
);

export const getQualityStatsBySamplingEndReached = ({ samplings }: AppState) => (
  typeof samplings.tableStats.total === 'number'
);

export const getResolutionTypes = ({ samplings }: AppState) => (
  samplings.resolutions.list
);

export const getErrorResolutionTypes = ({ samplings }: AppState) => (
  samplings.resolutions.list.filter(r => !RESOLUTION_TYPE_OK_TITLES.includes(r.title))
);

export const getPositiveResolutionTypeIds = ({ samplings }: AppState) => (
  samplings.resolutions.list
    .filter(r => RESOLUTION_TYPE_OK_TITLES.includes(r.title))
    .map(r => r.id)
);

export const getNegativeResolutionTypeIds = ({ samplings }: AppState) => (
  samplings.resolutions.list
    .filter(r => RESOLUTION_TYPE_ERROR_TITLES.includes(r.title))
    .map(r => r.id)
);

export const getResolutionTypesLoading = ({ samplings }: AppState) => (
  samplings.resolutions.loading
);

export const getNewSamplingById = (
  { samplings }: AppState,
  id: NewSampling['id'],
) => (
  samplings.newList.value.find(s => s.id === id)
);

export const getResolutionById = (
  state: AppState,
  id: ResolutionType['id'],
) => (
  getResolutionTypes(state).find(r => r.id === id)
);
