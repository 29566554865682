import { Reducer } from "redux";

import { ActionType, createReducer } from "typesafe-actions";

import { VersionStatus, Version } from "../../types/models/Version";
import navigationService from "../../services/navigation";

import * as projectActions from '../projects/actions';
import * as actions from './actions';

export const DRAFT_VERSION: Version = Object.freeze({
  id: 'draft',
  version: 0,
  draft: true,
  hidden: false,
  name: "SCENARIO_GRAPHS.EDITABLE_VERSION",
  created: "",
  createdDateFormatted: "",
  pending: false,
  isActive: false,
});

export type VersionsState = {
  list: {
    value: Version[],
    loading: boolean,
    wasLoaded?: boolean,
  };
  current: {
    status?: VersionStatus;
    loading: boolean;
    reloading: boolean;
  };
  versionToDisplay: Version['id'];
  creating: boolean;
  deploying: boolean;
}

const initialState: VersionsState = {
  list: {
    value: [],
    loading: false,
    wasLoaded: false,
  },
  current: {
    loading: false,
    reloading: false,
  },
  versionToDisplay: navigationService.getSearchParam('version') || DRAFT_VERSION.id,
  creating: false,
  deploying: false,
};

export const versionsReducer: Reducer<VersionsState> = createReducer<VersionsState>(initialState)
  .handleAction(
    actions.loadVersions.request,
    (state: VersionsState): VersionsState => ({
      ...state,
      list: {
        ...state.list,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadVersions.success,
    (state: VersionsState, {
      payload,
    }: ActionType<typeof actions.loadVersions.success>): VersionsState => ({
      ...state,
      list: {
        ...state.list,
        value: payload.sort(sortVersionsDesc),
        loading: false,
        wasLoaded: true,
      },
    }),
  )
  .handleAction(
    actions.loadVersions.failure,
    (state: VersionsState): VersionsState => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createVersion.request,
    (state: VersionsState): VersionsState => ({
      ...state,
      creating: true,
    }),
  )
  .handleAction(
    actions.createVersion.success,
    (state: VersionsState, {
      payload: newVersion,
    }: ActionType<typeof actions.createVersion.success>): VersionsState => ({
      ...state,
      list: {
        ...state.list,
        value: [newVersion, ...state.list.value].sort(sortVersionsDesc),
      },
      creating: false,
    }),
  )
  .handleAction(
    actions.createVersion.failure,
    (state: VersionsState): VersionsState => ({
      ...state,
      creating: false,
    }),
  )
  .handleAction(
    actions.deleteVersion.request,
    (state: VersionsState, {
      payload: versionId,
    }: ActionType<typeof actions.deleteVersion.request>): VersionsState => updateVersion(
      state,
      versionId,
      version => ({ ...version, pending: true }),
    ),
  )
  .handleAction(
    actions.deleteVersion.success,
    (state: VersionsState, {
      payload: versionId,
    }: ActionType<typeof actions.deleteVersion.request>): VersionsState => ({
      ...state,
      list: {
        ...state.list,
        value: state.list.value.filter(version => version.id !== versionId),
      },
    }),
  )
  .handleAction(
    actions.deleteVersion.failure,
    (state: VersionsState, {
      payload: error,
    }: ActionType<typeof actions.deleteVersion.failure>): VersionsState => {
      const { id } = error.payload;
      return updateVersion(state, id, version => ({ ...version, pending: false }));
    },
  )
  .handleAction(
    actions.saveVersions.request,
    (state: VersionsState): VersionsState => ({
      ...state,
      deploying: true,
    }),
  )
  .handleAction(
    actions.saveVersions.success,
    (
      state: VersionsState,
      { payload: activeVersion }: ActionType<typeof actions.saveVersions.success>): VersionsState => ({
      ...state,
      list: {
        ...state.list,
        value: state.list.value.map(v => v.id === activeVersion.id ?
          activeVersion :
          v,
        ),
      },
      deploying: false,
    }),
  )
  .handleAction(
    actions.saveVersions.failure,
    (state: VersionsState): VersionsState => ({
      ...state,
      deploying: false,
    }),
  )
  .handleAction(
    actions.changeActiveVersion,
    (state: VersionsState, {
      payload,
    }: ActionType<typeof actions.changeActiveVersion>): VersionsState => {
      const versionId = payload;
      return changeAvailableVersion(state, versionId.toString());
    },
  )
  .handleAction(
    projectActions.changeProject,
    (): VersionsState => ({
      ...initialState,
    }),
  )
  .handleAction(
    actions.reloadCurrentVersionStatus.request,
    (state: VersionsState): VersionsState => ({
      ...state,
      current: {
        ...state.current,
        reloading: true,
      },
    }),
  )
  .handleAction(
    actions.reloadCurrentVersionStatus.success,
    (state: VersionsState, {
      payload,
    }: ActionType<typeof actions.reloadCurrentVersionStatus.success>): VersionsState => ({
      ...state,
      current: {
        ...state.current,
        status: payload,
        reloading: false,
      },
    }),
  )
  .handleAction(
    actions.reloadCurrentVersionStatus.failure,
    (state: VersionsState): VersionsState => ({
      ...state,
      current: {
        ...state.current,
        reloading: false,
      },
    }),
  )
  .handleAction(
    actions.changeVersionToDisplay,
    (
      state: VersionsState,
      { payload }: ActionType<typeof actions.changeVersionToDisplay>,
    ): VersionsState => ({
      ...state,
      versionToDisplay: payload,
    }),
  );

function updateVersion(
  state: VersionsState,
  versionId: string,
  callback: (version: Version) => Version,
): VersionsState {
  const versionIndex = state.list.value.findIndex(version => version.id === versionId);
  const versionExist = versionIndex !== -1;
  if (versionExist) {
    const versionsCopy = [...state.list.value];
    versionsCopy[versionIndex] = callback(state.list.value[versionIndex]);
    return {
      ...state,
      list: {
        ...state.list,
        value: versionsCopy,
      },
    };
  }
  return state;
}

function changeAvailableVersion(
  state: VersionsState,
  versionId: string,
): VersionsState {
  const versionIndex = state.list.value.findIndex(version => version.id === versionId);
  const versionExist = versionIndex !== -1;
  if (versionExist) {
    const versionsCopy = state.list.value.map(val => (
      {
        ...val,
        isActive: false,
      }
    ));
    versionsCopy[versionIndex] = {
      ...state.list.value[versionIndex],
      isActive: true,
    };
    return {
      ...state,
      list: {
        ...state.list,
        value: versionsCopy,
      },
    };
  }
  return state;
}

function sortVersionsDesc(a: Version, b: Version) {
  return +new Date(b.created) - +new Date(a.created);
}
