import { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import tr from 'date-fns/locale/tr';

import i18n from '../../services/i18n';

enum Locale {
  RU = 'RU',
  EN = 'EN',
  TR = 'TR',
}

switch (i18n.language) {
  case 'ru':
    registerLocale(Locale.RU, ru);
    break;
  case 'en':
    registerLocale(Locale.EN, en);
    break;
  case 'tr':
    registerLocale(Locale.TR, tr);
    break;
  default:
    registerLocale(Locale.RU, ru);
    break;
}

export function getLocale() {
  switch (i18n.language) {
    case 'ru':
      return Locale.RU;
    case 'tr':
      return Locale.TR;
    case 'en':
      return Locale.EN;
    default:
      return Locale.RU;
  }
}

export function getDateFormatLocale() {
  switch (i18n.language) {
    case 'ru':
      return 'ru-RU';
    case 'tr':
      return 'tr-TR';
    case 'en':
      return 'en-US';
    default:
      return Locale.RU;
  }
}
