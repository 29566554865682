import { generateId } from "../../services/helpers/generateId";
import { hasWhiteSpace } from "../../services/helpers/utilities";
import { Editable } from "./Editable";

export type Feature = {
  id: string,
  slug: string,
  description: string,
  type: FeatureType,
  domain: string[],
  clarificationType?: FeatureClarificationType,
  extractType?: FeatureExtractType,
  forceQuestion?: boolean,
  clarifyingQuestion?: string,
  extractRules?: FeatureExtractRule[],
  defaultValue?: string,
  entityId?: string,
  entityExtractOrder?: number,
  isArray?: boolean,
  isCore?: boolean,
} & Editable;

export type FeatureShort = {
  key: string,
  value: string[] | number| boolean | string | null,
}

export enum FeatureType {
  int = 'int',
  float = 'float',
  bool = 'bool',
  str = 'str',
}

export function getFeatureTypeLabel(value: FeatureType) {
  switch (value) {
    case FeatureType.int:
      return "Integer";
    case FeatureType.str:
      return "String";
    case FeatureType.bool:
      return "Boolean";
    case FeatureType.float:
      return "Float";
    default:
      return "";
  }
}

export enum FeatureClarificationType {
  external = 'external',
  fromText = 'from_text',
}

export const featureClarificationTypeLabels = {
  [FeatureClarificationType.external]: "Внешний",
  [FeatureClarificationType.fromText]: "Из текста",
};

export enum FeatureExtractType {
  default = 'default',
  custom = 'custom',
}

export type FeatureExtractRule = {
  pattern: string,
  value: string,
  id?: string,
}

export enum FeatureEntity {
  date = 'date',
  time = 'time',
}

export function getEmptyFeature(): Feature {
  return {
    id: generateId(),
    slug: '',
    description: '',
    type: FeatureType.int,
    domain: [],
    clarificationType: FeatureClarificationType.external,
    clarifyingQuestion: '',
    pending: false,
  };
}

export function getEmptyFeatureExtractRule(): FeatureExtractRule {
  return {
    value: '',
    pattern: '',
    id: generateId(),
  };
}

export function isFeatureValid(value: Feature): boolean {
  if (!value.slug.trim()) return false;
  if (hasWhiteSpace(value.slug.trim())) return false;
  if (!/^[A-Za-z0-9-_]+$/g.test(value.slug)) return false;
  if (value.forceQuestion && !value.clarifyingQuestion?.trim()) return false;
  if (value.clarificationType === FeatureClarificationType.fromText && !value.entityId) return false;

  return true;
}

export function changeFeatureClarificationType(
  value: Feature,
  newType: FeatureClarificationType,
): Feature {
  if (newType === FeatureClarificationType.external) {
    const {
      forceQuestion,
      clarifyingQuestion,
      extractType,
      entityId,
      ...rest
    } = value;

    return {
      ...rest,
      clarificationType: newType,
    };
  }

  if (newType === FeatureClarificationType.fromText) {
    return {
      ...value,
      forceQuestion: false,
      clarifyingQuestion: "",
      entityId: "",
      entityExtractOrder: 1,
      clarificationType: newType,
    };
  }

  console.warn("Can't change feature type. Unknown type: ", newType);
  return value;
}

export function changeFeatureIsArray(
  feature: Feature,
  isArray: boolean,
): Feature {
  if (isArray) {
    const { entityExtractOrder, ...rest } = feature;

    return {
      ...rest,
      isArray,
    };
  }

  return {
    ...feature,
    isArray,
    entityExtractOrder: 1,
  };
}

//список обязательных для выгрузки полей
export const recordFields = [
  'chat_id',
  'session_id',
  'chat_user',
  'project_slug',
  'version',
  'created_at',
  'replied_at',
  'scenario_slug',
  'scenario_title',
  'scenario_path',
  'request_text',
  'response_text',
  'iteration',
  'tags',
  'close',
  'forward',
  'simulated',
  'with_errors',
  'replied',
  'defer',
  'csat',
];
