import { SagaIterator } from "redux-saga";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import apiService, { ApiResponse } from "../../services/api";
import { getEmptyCustomConfig } from "../../types/models/CustomConfig";
import { GetCustomConfigRequest, GetCustomConfigResponse, UpdateCustomConfigRequest, UpdateCustomConfigResponse } from "../../types/requests/CustomConfigs";
import { GetFullConfigurationRequest, GetFullConfigurationResponse } from "../../types/requests/FullConfiguration";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";
import i18n from '../../services/i18n';
import { ErrorAction } from "../reducers";

import * as actions from './actions';

function* loadCustomConfig(): SagaIterator {
  yield takeLatest(actions.loadCustomConfig.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: GetCustomConfigRequest = {
        ...general,
      };

      const response: ApiResponse<GetCustomConfigResponse> = yield call(apiService.getCustomConfig, params);
      yield put(actions.loadCustomConfig.success(response.data || getEmptyCustomConfig()));
    } catch (err) {
      yield put(actions.loadCustomConfig.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.CONFIGS.SINGLE") }))));
    }
  });
}

function* uploadCustomConfig(): SagaIterator {
  yield takeLatest(actions.updateCustomConfig.request, function* (action) {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: UpdateCustomConfigRequest = {
        ...general,
        config: action.payload,
      };

      const response: ApiResponse<UpdateCustomConfigResponse> = yield call(apiService.updateCustomConfig, params);
      yield put(actions.updateCustomConfig.success(response.data));
    } catch (err) {
      yield put(actions.updateCustomConfig.failure(new ErrorAction(err, i18n.t("ERRORS.API.UPDATE", { name: i18n.t("ERRORS.API.CONFIGS.SINGLE") }))));
    }
  });
}

function* loadFullConfiguration(): SagaIterator {
  yield takeLatest(actions.loadFullConfiguration.request, function* () {
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: GetFullConfigurationRequest = {
        ...general,
      };

      const response: ApiResponse<GetFullConfigurationResponse> = yield call(apiService.getFullConfiguration, params);

      yield put(actions.loadFullConfiguration.success(response.data));
    } catch (err) {
      yield put(actions.loadFullConfiguration.failure(new ErrorAction(err, i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.CONFIGS.SINGLE") }))));
    }
  });
}

export default function* callsSagas() {
  yield fork(loadCustomConfig);
  yield fork(uploadCustomConfig);
  yield fork(loadFullConfiguration);
}
