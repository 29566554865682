import apiService from "../../../../../../../../../../../services/api";
import { isOnlyNumbers } from "../../../../../../../../../../../services/helpers/utilities";
import { ChatterboxMacros, MacrosNew } from "../../../../../../../../../../../types/models/Macros";

export function matchOldMacrosFormat(value: string): boolean {
  return (value.trim().length === 5 || value.trim().length === 6) && isOnlyNumbers(value.trim());
}

export function matchNewMacrosFormat(value: string): boolean {
  return value.trim().startsWith('macro_var_') && value.length > 10;
}

export async function getNewMacrosesTexts(ids: Array<MacrosNew['slug']>): Promise<string> {
  return await apiService
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .getMacrosList({ body: { slugs: ids } }).then(res => res.data.macros.map((m: any) => m.text));
}

export function getOldMacrosText(id: ChatterboxMacros['id']): Promise<string> {
  return new Promise((resolve, reject) => {
    return apiService.getChatterboxMacros({
      path: {
        macros_id: Number(id),
      },
    })
      .then(res => resolve(res.data.comment))
      .catch(reject);
  });
}

export async function getMacrosText(value: string) {
  if (matchOldMacrosFormat(value)) {
    return getOldMacrosText(value).then(text => text);
  } else if (matchNewMacrosFormat(value)) {
    return getNewMacrosesTexts([value]).then(text => text);
  }

  return value;
}
