import { v4 as uuidv4 } from "uuid";
import { format, isValid } from "date-fns";

import { getPercents, objSnakeToCamel } from "../../services/helpers/utilities";
import { CallsStatisticsAggregatedBackend, CallsStatisticsDetailedBackend, CallStatusBackend } from "../backendModels/CallsStatisticsBackend";

import {
  CallsStatisticsItem,
  CallsStatisticsPeriod,
  DetailedCallsStatisticPeriod,
  CallsDetailsStatisticsItem,
  PercentMetrics,
} from "../models/CallsStatistics";

import { StatisticsPeriod } from "../models/StatisticsPeriod";
import { CallDirection } from "../models/OutgoingCall";
import { Tag } from "../models/Tag";
import { dateMscToLocal } from "../../services/helpers/dateUtils";

const getInitiatedTimeString = (isoStr: string): string => {
  try {
    const date = new Date(isoStr);

    if (!isValid(date)) return '';

    return format(dateMscToLocal(date), 'HH:mm \n dd MMMM yyyy');
  } catch {
    return '';
  }
};

const parseTitle = (value: string): string => {
  try {
    const date = new Date(value);

    if (date && !isValid(date)) return value;

    return dateMscToLocal(date).toString();
  } catch {
    return '';
  }
};

export function parseCallsStatisticsAggregated(
  value: CallsStatisticsAggregatedBackend,
  groupBy: CallsStatisticsPeriod,
  direction: CallDirection,
): CallsStatisticsItem {
  const result: CallsStatisticsItem = {
    title: parseTitle(value.title),
    startAt: getInitiatedTimeString(value.start_at || ""),
    totalSeconds: value.total_seconds,
    calls: value.calls,
    status: value.batch_status as CallStatusBackend,
    callAttempts: value.call_attempts,
    connectedFirstAttempt: {
      number: value.connected_first_attempt,
      percent: getPercents(value.call_attempts, value.connected_first_attempt),
    },
    connectedSecondAttempt: {
      number: value.connected_second_attempt,
      percent: getPercents(value.call_attempts, value.connected_second_attempt),
    },
    endedCount: {
      number: value.ended_count,
      percent: getPercents(value.call_attempts, value.ended_count),
    },
    forwardedCount: {
      number: value.forwarded_count,
      percent: getPercents(value.call_attempts, value.forwarded_count),
    },
    hangupCount: {
      number: value.hangup_count,
      percent: getPercents(value.call_attempts, value.hangup_count),
    },
    noAnswerCount: {
      number: value.no_answer_count,
      percent: getPercents(value.call_attempts, value.no_answer_count),
    },
    userBusyCount: {
      number: value.user_busy_count,
      percent: getPercents(value.call_attempts, value.user_busy_count),
    },
    errorCount: {
      number: value.error_count,
      percent: getPercents(value.call_attempts, value.error_count),
    },
    period: groupBy,
  };

  const isOutgoing = direction === CallDirection.outgoing;
  const groupByBatchId = groupBy === DetailedCallsStatisticPeriod.BatchId;
  const groupByHour = groupBy === StatisticsPeriod.Hour;

  if (value.tag_counters) {
    result.tagsCounter = value.tag_counters.reduce<Record<Tag['slug'], PercentMetrics>>((res, tagData) => {
      if (!tagData.tag_name || !tagData.count) return res;

      res[tagData.tag_name] = {
        number: tagData.count,
        percent: getPercents(value.call_attempts, tagData.count),
      };

      return res;
    }, {});
  }

  if (isOutgoing) {
    if (groupByBatchId) {
      result.id = value.title;
    } else {
      result.id = uuidv4();
      result.items = [];
    }
  } else {
    result.id = uuidv4();

    if (!groupByHour) {
      result.items = [];
    }
  }

  return result;
}

export function parseCallsStatistics(
  aggregated: CallsStatisticsAggregatedBackend,
  groupBy: CallsStatisticsPeriod,
  direction: CallDirection,
  groups?: CallsStatisticsAggregatedBackend[],
): {
  data: CallsStatisticsItem,
  chartStatistics?: CallsStatisticsItem[],
} {
  const items = groups && groups.map(
    item => parseCallsStatisticsAggregated(item, groupBy, direction),
  );

  return {
    data: {
      ...parseCallsStatisticsAggregated(aggregated, groupBy, direction),
      items,
    },
    chartStatistics: items,
  };
}

export function parseCallsDetailedStatistics(
  calls: CallsStatisticsDetailedBackend[],
): CallsDetailsStatisticsItem[] {
  return calls.map(item => objSnakeToCamel<CallsDetailsStatisticsItem>(item));
}
