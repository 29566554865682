import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import { history } from '../services/navigation';
import rootSaga from './sagas';
import { cacheMiddleware } from './middlewares/cache';
import { errorsMiddleware } from './middlewares/errors';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function configureStore(initialState: any) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history special
        cacheMiddleware,
        errorsMiddleware,
        sagaMiddleware,
      ),
    ),
  );

  return store;
}

const initialState = {};
const store = configureStore(initialState);

sagaMiddleware.run(rootSaga);

export { store };
