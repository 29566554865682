import React, { FC } from 'react';
import { SvgProps } from '../types';

const PlusIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 3C11.2239 3 11 3.22386 11 3.5V11H3.5C3.22386 11 3 11.2239 3 11.5V12.5C3 12.7761 3.22386 13 3.5 13H11V20.5C11 20.7761 11.2239 21 11.5 21H12.5C12.7761 21 13 20.7761 13 20.5V13H20.5C20.7761 13 21 12.7761 21 12.5V11.5C21 11.2239 20.7761 11 20.5 11H13V3.5C13 3.22386 12.7761 3 12.5 3H11.5Z"
      fill={color}
    />
  </svg>
);

export default PlusIcon;
