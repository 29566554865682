import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { block } from 'bem-cn';

import modalService, { ModalProps } from '../../services/modal';
import { AudioFileMetadata } from '../../types/models/Files';
import { getFilenameWithoutExtension, extractValidFilenameSymbols, renameFile } from '../../services/helpers/utilities';

import * as actions from '../../redux/files/actions';
import { getAudioFiles } from '../../redux/files/selectors';

import LoadingRow from '../../components/loadingRow';

import SideModal from '../../components-new/sideModal';
import Input from '../../components-new/input';
import Button from '../../components-new/button';
import UploadFileButton from '../../components-new/audioFiles/uploadFileButton';
import AudioList from '../../components-new/audioFiles/audioList';

import './styles.scss';

const b = block("select-audio-modal");

export type SelectAudioFileModalProps = {
  onSelect: (file: AudioFileMetadata) => void,
}

export default function SelectAudioFileModal({
  onSelect,
  close,
}: SelectAudioFileModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [activeFilename, setActiveFilename] = useState<string>('');
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const files = useSelector(getAudioFiles);

  const handleSearchFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement> | null) => {
    setSearchFilter(event ? event.target.value : '');
  }, []);

  const handleSubmit = useCallback(() => {
    const selectedAudio = files.list.find(meta => meta.filename === activeFilename);
    if (!selectedAudio) return;

    onSelect(selectedAudio);
    close();
  }, [files, activeFilename, onSelect, close]);

  const handleUploadFileClick = useCallback(() => {
    if (!uploadInputRef.current) return;

    uploadInputRef.current.value = '';
    uploadInputRef.current.click();
  }, [uploadInputRef]);

  const handleAudioFileUploaded = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file) {
      let generatedFilename = file.name;
      generatedFilename = getFilenameWithoutExtension(generatedFilename);
      generatedFilename = extractValidFilenameSymbols(generatedFilename);

      const timestamp = +(new Date());
      generatedFilename = `${generatedFilename}${timestamp}`;
      const renamedFile = renameFile(file, generatedFilename);
      const modalId = modalService.openCreateAudioRecord({
        name: file.name,
        onSubmit: props => {
          dispatch(actions.uploadAudioFile.request({
            file: renamedFile,
            name: props.name,
            comment: props.comment,
          }));
          modalService.close(modalId);
        },
      });
    }
  }, [dispatch]);

  const handleEditAudioClick = useCallback((filename: string) => {
    const audioFile = files.list.find(audio => audio.filename === filename);
    if (!audioFile) return;

    const { userFilename, userComment } = audioFile;

    const modalId = modalService.openUpdateAudioRecord({
      name: userFilename,
      comment: userComment,
      onSubmit: ({ name, comment = '' }) => {
        dispatch(actions.updateAudioUserFilename.request({
          filename,
          newUserFilename: name,
          newUserComment: comment,
        }));
        modalService.close(modalId);
      },
    });
  }, [dispatch, files.list]);

  const someFileSelected = activeFilename !== '';
  const filteredFiles = useMemo(() => {
    return files.list.filter(meta =>
      meta.filename.toUpperCase().includes(searchFilter.toUpperCase()));
  }, [searchFilter, files]);

  return (
    <SideModal
      open
      onClose={close}
      title={t("MODALS.SELECT_AUDIO.TITLE")}
      className={b()}
    >
      <div className={b("content")}>
        <Input
          fluid
          name="search"
          placeholder={`${t("COMMON.SEARCH_PLACEHOLDER")}...`}
          value={searchFilter}
          onChange={handleSearchFilterChange}
        />
        <UploadFileButton onClick={handleUploadFileClick} />
        <input
          ref={uploadInputRef}
          onChange={handleAudioFileUploaded}
          type="file"
          accept="audio/*"
          className="hidden"
        />
        {(files.loading || files.uploading) && <LoadingRow className={String(b("loading"))} />}
        <AudioList
          files={filteredFiles}
          activeFile={activeFilename}
          onActiveFileChange={setActiveFilename}
          onEditClick={handleEditAudioClick}
        />
      </div>
      <div className={b("actions")}>
        <Button onClick={close}>
          <span>{t("COMMON.CANCEL")}</span>
        </Button>
        <Button
          primary
          onClick={handleSubmit}
          disabled={!someFileSelected}
        >
          <span>{t("COMMON.SELECT")}</span>
        </Button>
      </div>
    </SideModal>
  );
}
