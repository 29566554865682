/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  memo,
  useMemo,
  useCallback,
} from 'react';
import { IconTypes } from '../icon';

import BaseSelect, { BaseSelectProps } from './index';

export type SelectConfig = {
  options: any[],
  labelFunction: (option: any) => string,
  valueFunction: (option: any) => string,
  subtitleFunction?: (option: any) => string | undefined,
  classNameFunction?: (option: any) => string,
  iconFunction?: (option: any) => IconTypes | undefined,
  onIconClick?: (event: React.MouseEvent, value: string) => void,
}

export type ConfigurableSelectProps = {
  config: SelectConfig;
  extraOptions?: {
    label: string;
    value: string;
  }[],
  value: string | string[];
  onChange: (value: any, operator?: MultiOperator) => void,
} & Omit<BaseSelectProps, 'config' | 'value' | 'label' | 'onChange'>;

export type ConfigurableSelectOption = {
  label: string;
  value: string;
  subtitle?: string;
  icon?: IconTypes;
  className?: string;
  checked: boolean;
  disabled: boolean;
};

export enum MultiOperator {
  or = "or",
  and = "and",
}

const ConfigurableSelect: FC<ConfigurableSelectProps> = ({
  config,
  extraOptions,
  value,
  onChange,
  ...baseProps
}) => {
  const options = useMemo(() => {
    const result: ConfigurableSelectOption[] = config.options.map(option => ({
      label: config.labelFunction(option),
      value: config.valueFunction(option),
      subtitle: config.subtitleFunction ? config.subtitleFunction(option) : undefined,
      icon: config.iconFunction ? config.iconFunction(option) : undefined,
      className: config.classNameFunction ? config.classNameFunction(option) : undefined,
      checked: option.isChecked || (baseProps.isMulti ?
        value?.includes(config.valueFunction(option)) :
        value === config.valueFunction(option)),
      disabled: !!option.isDisabled,
      onIconClick: config.onIconClick ? config.onIconClick : undefined,
    }));

    if (extraOptions) {
      return extraOptions.concat(result);
    }

    return result;
  }, [baseProps.isMulti, config, extraOptions, value]);

  const getSelectedOptions = useCallback(() => {
    if (baseProps.isMulti) {
      return options.filter((option: any) => value.includes(option.value));
    }

    return options.find((option: any) => option.value === value) || null;
  }, [options, value, baseProps.isMulti]);

  const selectedOption = getSelectedOptions();

  const handleChange = useCallback(
    (
      option: any | null,
      action,
    ) => {
      if (baseProps.isMulti) {
        onChange((option || []).map((o: any) => o.value), action.operator);
      } else {
        onChange(option === null ? null : option.value);
      }
    },
    [baseProps.isMulti, onChange],
  );

  return (
    <BaseSelect
      {...baseProps}
      options={options}
      value={selectedOption}
      onChange={handleChange}
    />
  );
};

export default memo(ConfigurableSelect);
