import { generateId } from "../../services/helpers/generateId";

export type ScenarioGraphGroup = {
  id: string;
  title: string;
  description: string;
  scenarioId: number;
  color: string;
  nodes: string[];
}

export function getScenarioGraphGroup(
  title: string = "",
  nodes: string[] = [],
  color: string = "",
  description: string = "",
  scenarioId: number = -1,
): ScenarioGraphGroup {
  return {
    id: getNewGroupId(),
    title,
    color,
    description,
    scenarioId,
    nodes,
  };
}

export function getNewGroupId(): string {
  return `front-${generateId()}`;
}

export function isGroupNew(group: ScenarioGraphGroup): boolean {
  return group.id.startsWith('front-');
}

export function toggleNodeInGroup(group: ScenarioGraphGroup, nodeId: string): ScenarioGraphGroup {
  return {
    ...group,
    nodes: group.nodes.includes(nodeId) ?
      group.nodes.filter(i => i !== nodeId) :
      [...group.nodes, nodeId],
  };
}

export function removeNodeFromGroups(
  groups: ScenarioGraphGroup[],
  nodeId: string,
): ScenarioGraphGroup[] {
  return groups.map(g => ({
    ...g,
    nodes: g.nodes.filter(id => id !== nodeId),
  }));
}

export function checkNodesChanged(
  groupToCheck: ScenarioGraphGroup,
  list: ScenarioGraphGroup[],
): boolean {
  return list.some(group => {
    if (group.id !== groupToCheck.id) return false;

    if (group.nodes.length !== groupToCheck.nodes.length) {
      return true;
    }

    return group.nodes.some(nodeId => !groupToCheck.nodes.includes(nodeId));
  });
}

export function checkMetaChanged(
  groupToCheck: ScenarioGraphGroup,
  list: ScenarioGraphGroup[],
): boolean {
  return list.some(group => {
    if (group.id !== groupToCheck.id) return false;

    return (
      group.title.trim() !== groupToCheck.title.trim() ||
      group.description.trim() !== groupToCheck.description.trim() ||
      group.color !== groupToCheck.color
    );
  });
}
