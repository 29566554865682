import {
  ReactNode,
  useCallback,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { ModalProps } from '../../services/modal';

import Modal from '../components/ndModal';

import Select, { SelectConfig } from '../../components-new/select/configurable';

export type CustomSelectModalProps = {
  title?: ReactNode,
  selectConfig: SelectConfig,
  placeholder: string,
  onConfirm: (id: string) => void,
  onCancel?: () => void,
};

export default function CustomSelectModal({
  title,
  selectConfig,
  placeholder,
  onConfirm,
  onCancel,
  close,
}: CustomSelectModalProps & ModalProps) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>('');

  const handleConfirmClick = useCallback(
    () => {
      if (!selected) return;

      onConfirm(selected);
      close();
    },
    [onConfirm, selected, close],
  );

  const handleCloseClick = useCallback(
    () => {
      if (onCancel) {
        onCancel();
      }
      close();
    },
    [onCancel, close],
  );

  const handleChange = useCallback(
    (slug: string) => {
      setSelected(slug);
    },
    [],
  );

  return (
    <Modal
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.READY')}
      title={title}
      onCancel={handleCloseClick}
      onConfirm={handleConfirmClick}
      confirmDisabled={!selected}
    >
      <Select
        fluid
        placeholder={placeholder}
        config={selectConfig}
        value={selected}
        usePortal
        onChange={handleChange}
      />
    </Modal>
  );
}
