import { createAsyncAction } from "typesafe-actions";
import { StatisticsUnit } from "../../types/models/StatisticsUnit";

import { StatsType, Task, TaskLog, TaskType } from "../../types/models/Task";
import { Pagination } from "../../types/models/Pagination";
import { ErrorAction } from "../reducers";
import { StatisticsPeriod } from "../../types/models/StatisticsPeriod";
import { MessageMetrics } from "../../types/models/AutomationStatistics";
import { VersionType } from "../../types/models/Version";
import { Tag } from "../../types/models/Tag";
import { CallDirection } from "../../types/models/OutgoingCall";
import { CreateTaskRequest } from "../../types/requests/Task";
import { CallParams } from "../../types/models/Calls";

import { Either } from "../../services/helpers/utilities";

import { StatisticsTarget } from "../projects/helpers";

import { CallsStatsColumns } from "../../pages/account/components/callsTab/columns";
import { DialogsHistorySearchFilter } from "../../types/models/Dialog";

export const loadImportExportTasks = createAsyncAction(
  'task/importExportLoad/requested',
  'task/importExportLoad/succeeded',
  'task/importExportLoad/failed',
)<{ types: TaskType[] } & Pagination, { tasks: Task[], pagination?: Pagination }, ErrorAction>();

export const createImportExportTask = createAsyncAction(
  'task/createImportExport/requested',
  'task/createImportExport/succeeded',
  'task/createImportExport/failed',
)<{ type: TaskType, file?: File, refTaskId?: string }, Task, ErrorAction>();

export const loadVoiceTasks = createAsyncAction(
  'task/voiceLoad/requested',
  'task/voiceLoad/succeeded',
  'task/voiceLoad/failed',
)<{ types: TaskType[] } & Pagination, { tasks: Task[], pagination?: Pagination }, ErrorAction>();

export type RunCallRequest = {
  title: string,
  numAttempts?: number;
  callBackAfter?: number;
  dateStart?: Date | null;
  dateEnd?: Date | null;
  topicSlug?: string;
  workStart?: string | null,
  workEnd?: string | null,
  weekendStart?: string | null,
  weekendEnd?: string | null,
  work?: boolean,
  weekend?: boolean,
} & Either<{ file: File }, { fileId: string }>;

export const runCall = createAsyncAction(
  'task/runCall/requested',
  'task/runCall/succeeded',
  'task/runCall/failed',
)<RunCallRequest, Task, ErrorAction>();

export const editCall = createAsyncAction(
  'tasks/editCall/requested',
  'tasks/editCall/succeeded',
  'tasks/editCall/failed',
)<CallParams, undefined, ErrorAction>();

export const loadStatsReportsTasks = createAsyncAction(
  'tasks/AccountOverview/requested',
  'tasks/AccountOverview/succeeded',
  'tasks/AccountOverview/failed',
)<
  Pagination & { statsType?: StatsType, startDate?: number, endDate?: number, },
  { tasks: Task[], pagination: Pagination, statsType?: StatsType },
  ErrorAction
>();

export const loadVoxExportTasks = createAsyncAction(
  'tasks/loadVoxExportTasks/requested',
  'tasks/loadVoxExportTasks/succeeded',
  'tasks/loadVoxExportTasks/failed',
)<Pagination, { tasks: Task[], pagination: Pagination }, ErrorAction>();

export type OverviewReportTask = {
  statsType: StatsType.dialogs;
  unit: StatisticsUnit;
  startDate?: number;
  endDate?: number;
  periodType?: StatisticsPeriod;
  columns?: MessageMetrics[];
  tags?: Tag['slug'][];
  callDirection?: CallDirection;
  splitBy?: StatisticsTarget
  userScores?: number[];
}

export type GroupedCallsReportTask = {
  statsType: StatsType.callsGrouped;
  startDate: number,
  endDate?: number,
  topicSlugs?: string[],
  periodType: StatisticsPeriod,
  columns: CallsStatsColumns[],
  tags?: Tag['slug'][],
  callDirection: CallDirection
}

export type SeparatedCallsReportTask = {
  statsType: StatsType.callsSeparated;
  callDirection: CallDirection
  batchId?: string;
  startDate?: number;
  endDate?: number;
}

export const createStatsReportTask = createAsyncAction(
  'task/createStatsReportTask/requested',
  'task/createStatsReportTask/succeeded',
  'task/createStatsReportTask/failed',
)<
  // eslint-disable-next-line func-call-spacing
  { name: string } & (OverviewReportTask | GroupedCallsReportTask | SeparatedCallsReportTask),
  Task & { statsType?: StatsType },
  ErrorAction
>();

export const exportDialogsStatistics = createAsyncAction(
  'task/exportDialogsStatistics/requested',
  'task/exportDialogsStatistics/succeeded',
  'task/exportDialogsStatistics/failed',
)<{ name: string }, Task, ErrorAction>();

export const cancelCall = createAsyncAction(
  'task/cancelCall/requested',
  'task/cancelCall/succeeded',
  'task/cancelCall/failed',
)<{ taskId: string, modalId?: string }, { taskId: string }, ErrorAction<{ taskId: string }>>();

export const uploadScenarioExamples = createAsyncAction(
  'tasks/uploadScenarioExamples/requested',
  'tasks/uploadScenarioExamples/succeeded',
  'tasks/uploadScenarioExamples/failed',
)<File, Task, ErrorAction>();

export const loadScenarioExamplesUploadings = createAsyncAction(
  'tasks/loadScenarioExamplesUploadings/requested',
  'tasks/loadScenarioExamplesUploadings/succeeded',
  'tasks/loadScenarioExamplesUploadings/failed',
)<Pagination, { pagination: Pagination, tasks: Task[] }, ErrorAction>();

export const launchCustomModelLearning = createAsyncAction(
  'tasks/launchCustomModelLearning/requested',
  'tasks/launchCustomModelLearning/succeeded',
  'tasks/launchCustomModelLearning/failed',
)<undefined, Task, ErrorAction>();

export const loadCustomModelLearningTasks = createAsyncAction(
  'tasks/loadCustomModelLearning/requested',
  'tasks/loadCustomModelLearning/succeeded',
  'tasks/loadCustomModelLearning/failed',
)<Pagination, { pagination: Pagination, tasks: Task[] }, ErrorAction>();

export const loadClusteringLaunchTasks = createAsyncAction(
  'tasks/loadClusteringLaunchTasks/requested',
  'tasks/loadClusteringLaunchTasks/succeeded',
  'tasks/loadClusteringLaunchTasks/failed',
)<Pagination, { pagination: Pagination, tasks: Task[] }, ErrorAction>();

export const loadExportExamplesTasks = createAsyncAction(
  'tasks/loadExportExamplesTasks/requested',
  'tasks/loadExportExamplesTasks/succeeded',
  'tasks/loadExportExamplesTasks/failed',
)<
  Pagination,
  { tasks: Task[], pagination: Pagination },
  ErrorAction
>();

export const exportExamples = createAsyncAction(
  'tasks/exportExamples/requested',
  'tasks/exportExamples/succeeded',
  'tasks/exportExamples/failed',
)<{ name: string, params?: CreateTaskRequest['params'] }, Task, ErrorAction>();

export const exportRepetitiveExamples = createAsyncAction(
  'tasks/exportRepetitiveExamples/requested',
  'tasks/exportRepetitiveExamples/succeeded',
  'tasks/exportRepetitiveExamples/failed',
)<
  { name: string },
  Task,
  ErrorAction
>();

export const processExamples = createAsyncAction(
  'tasks/processExamples/requested',
  'tasks/processExamples/succeeded',
  'tasks/processExamples/failed',
)<
  { file: File, samplingName: string, versionType: VersionType },
  Task,
  ErrorAction
>();

export const loadProcessExamplesTask = createAsyncAction(
  'tasks/loadProcessExamplesTask/requested',
  'tasks/loadProcessExamplesTask/succeeded',
  'tasks/loadProcessExamplesTask/failed',
)<
  Pagination,
  { tasks: Task[], pagination: Pagination },
  ErrorAction
>();

export const loadAllTasks = createAsyncAction(
  'tasks/loadAllTasks/requested',
  'tasks/loadAllTasks/succeeded',
  'tasks/loadAllTasks/failed',
)<
  Pagination,
  { tasks: Task[], pagination: Pagination },
  ErrorAction
>();

export const loadTaskLogs = createAsyncAction(
  'tasks/loadTaskLogs/requested',
  'tasks/loadTaskLogs/succeeded',
  'tasks/loadTaskLogs/failed',
)<
  { taskId: Task['id'] },
  { taskId: Task['id'], logs: TaskLog[] },
  ErrorAction<{ taskId: Task['id'] }>
>();

export const searchForDirtyExamples = createAsyncAction(
  'tasks/searchForDirtyExamples/requested',
  'tasks/searchForDirtyExamples/succeeded',
  'tasks/searchForDirtyExamples/failed',
)<
  undefined,
  Task,
  ErrorAction
>();

export const exportDialogs = createAsyncAction(
  'tasks/exportDialogs/requested',
  'tasks/exportDialogs/succeeded',
  'tasks/exportDialogs/failed',
)<
  {
    filters: DialogsHistorySearchFilter,
    columns: string[],
    title: string,
  },
  Task,
  ErrorAction
>();
