import { isAfter, subDays } from "date-fns";
import { SavedReleaseNote } from "../../types/models/ReleaseNotes";

// localstorage хранит массив из 3 id
const LAST_SHOWN_RELEASE_NOTES = 'lastShownReleaseNotes';

export const getLastShownNotes = (): string[] | null => {
  const prev = localStorage.getItem(LAST_SHOWN_RELEASE_NOTES);
  if (!prev) return null;

  try {
    return JSON.parse(prev);
  } catch {
    return null;
  }
};

export const setLastShownNote = (id: string) => {
  const lastShownNotes = (getLastShownNotes() || []).filter(savedId => savedId !== id);
  const newNotes = [id, ...lastShownNotes.slice(0, 2)];

  localStorage.setItem(LAST_SHOWN_RELEASE_NOTES, JSON.stringify(newNotes));
};

const getDateFromTimestamp = (timestamp: number) => new Date(timestamp * 1000);

export const checkTimestamp = (note: SavedReleaseNote | null) => {
  if (!note?.timestamp) return false;

  const m = getDateFromTimestamp(note.timestamp);
  return isAfter(m, subDays(new Date(), 3));
};

export const checkIfNoteShouldBeShown = (note: SavedReleaseNote) => {
  if (!note || !checkTimestamp(note)) return false;

  const lastShownNotes = getLastShownNotes();
  if (!lastShownNotes) return true;

  return !lastShownNotes.includes(String(note.id));
};
