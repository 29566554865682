import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { block } from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as actions from '../../redux/projects/actions';
import Select, { SelectConfig } from '../select/configurable';
import { getProjects, getProjectsLoading, getSelectedProject } from '../../redux/projects/selectors';
import { Project, isVoiceProject } from '../../types/models/Project';

import Menu from './menu';
import Dropdown from './dropdown';
import SelectedValue from './selectedValue';

import './styles.scss';
import Icon from '../icon';

export const b = block("new-project-selector");

const SearchIcon = () => <Icon type="search" />;

function ProjectSelector() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projects = useSelector(getProjects);
  const projectsLoading = useSelector(getProjectsLoading);
  const selectedProject = useSelector(getSelectedProject);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChangeProject = useCallback((id: string) => {
    const newProject = projects.find(project => String(project.id) === id);
    if (!newProject) return;

    dispatch(actions.changeProject(newProject));
    setIsOpen(false);
  }, [projects, dispatch]);

  const toggle = useCallback(
    () => setIsOpen(!isOpen),
    [isOpen],
  );

  const close = useCallback(
    () => setIsOpen(false),
    [],
  );

  const config: SelectConfig = useMemo(() => ({
    options: projects,
    labelFunction: (project: Project) => project.title,
    valueFunction: (project: Project) => String(project.id),
    iconFunction: (project: Project) => isVoiceProject(project) ?
      "phone" :
      "chat"
    ,
  }), [projects]);

  useEffect(() => {
    if (isOpen) dispatch(actions.loadProjects.request());
  }, [isOpen, dispatch]);

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={close}
      target={<SelectedValue project={selectedProject} onClick={toggle} isOpen={isOpen} />}
    >
      <Select
        className={b()}
        config={config}
        transparent
        autoFocus
        value={String(selectedProject.id)}
        controlShouldRenderValue={false}
        isLoading={projectsLoading}
        menuIsOpen
        placeholder={t("SIDEBAR.SEARCH_ROBOT_PLACEHOLDER")}
        onChange={handleChangeProject}
        usePortal={false}
        components={{
          Menu,
          IndicatorsContainer: SearchIcon,
        }}
      />
    </Dropdown>
  );
}

export default React.memo(ProjectSelector);
