import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ProjectConfigBackend } from "../backendModels/ProjectConfigBackend";
import { ProjectConfig } from "../models/ProjectConfig";

export function parseProjectConfig(value: ProjectConfigBackend): ProjectConfig {
  return objSnakeToCamel(value, true);
}

export function parseProjectConfigToBackend(value: ProjectConfig): ProjectConfigBackend {
  return objCamelToSnake(value, true);
}
