import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import { ModalProps } from '../../services/modal';
import { loadProjectAuthData, updateProjectAuthData } from '../../redux/projectsAuthData/actions';
import { getAuthData, getAuthDataLoading } from '../../redux/projectsAuthData/selectors';
import { AppState } from '../../redux/reducers';

import { AuthData, createIpAddress, IpAddress } from '../../types/models/ProjectAuthData';
import LoadingRow from '../../components/loadingRow';
import Button from '../../components-new/button';

import Modal from '../components/ndModal';

import IpAddressRow from './ipAddress';
import ApiKey from './apiKey';

import './styles.scss';

export const b = block('nd-project-auth-data-modal');

export type ProjectAuthDataModalProps = {
  projectSlug: string,
  className?: string,
  onCancel: () => void,
};

export default function ProjectAuthDataModal({
  projectSlug,
  className = '',
  onCancel,
  close,
}: ProjectAuthDataModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authData = useSelector((state: AppState) => getAuthData(state, projectSlug), shallowEqual);
  const loading = useSelector(getAuthDataLoading, shallowEqual);

  const [modified, setModified] = useState(false);

  const [ipAddresses, setIpAddresses] = useState<IpAddress[]>([]);
  const [ipAddressErrors, setIpAddressErrors] = useState<Record<string, boolean>>({});
  const [apiKey, setApiKey] = useState<string>(authData?.apiKey || "");

  const hasErrors = useMemo(
    () => Object.values(ipAddressErrors).some(item => !!item),
    [ipAddressErrors],
  );

  const firstIpIsEmpty = !!ipAddresses.length && !ipAddresses[0]?.value;

  const handleConfirm = useCallback(
    () => {
      const nonEmpty = ipAddresses.filter(item => !!item.value);

      const data: AuthData = {
        ipAddresses: nonEmpty,
        apiKey,
      };

      dispatch(updateProjectAuthData.request({ projectSlug, data }));

      if (onCancel) {
        onCancel();
      }
    },
    [ipAddresses, apiKey, dispatch, projectSlug, onCancel],
  );

  const handleAddIpAddress = useCallback(
    () => {
      setIpAddresses(current => [createIpAddress(), ...current]);
    },
    [],
  );

  const handleChangeApiKey = useCallback(
    (value: string) => {
      setApiKey(value);
      setModified(true);
    },
    [],
  );

  const handleChangeIpAddress = useCallback(
    (newValue: IpAddress, valid: boolean) => {
      setModified(true);

      setIpAddresses(current => (
        current.map(item => (item.id === newValue.id ? newValue : item))
      ));

      setIpAddressErrors(current => ({ ...current, [newValue.id]: !valid }));
    },
    [],
  );

  const handleDeleteIpAddress = useCallback(
    (id: string) => {
      setModified(true);

      setIpAddresses(current => (
        current.filter(item => item.id !== id)
      ));

      setIpAddressErrors(current => {
        const newErrors = { ...current };
        delete newErrors[id];

        return newErrors;
      });
    },
    [],
  );

  useEffect(
    () => {
      if (authData) return;

      dispatch(loadProjectAuthData.request(projectSlug));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectSlug],
  );

  useEffect(
    () => {
      if (!authData || loading) return;

      setIpAddresses(authData.ipAddresses);
      setApiKey(authData.apiKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authData],
  );

  return (
    <Modal
      className={`${b()} ${className}`}
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.READY')}
      title={t("MODALS.PROJECT_AUTH_DATA_MODAL.TITLE")}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      confirmDisabled={!modified || !!hasErrors}
    >
      {loading ?
        <LoadingRow /> :
        (
          <div>
            <div className={b('item')}>
              <span className={b('title')}>{t("MODALS.PROJECT_AUTH_DATA_MODAL.API_KEY")}</span>
              <ApiKey
                apiKey={apiKey}
                onChange={handleChangeApiKey}
              />
            </div>
            <div className={b('item')}>
              <div className={b('title')}>
                <span>
                  {t("MODALS.PROJECT_AUTH_DATA_MODAL.IP_ADDRESSES")}
                </span>
                <Button
                  primary
                  disabled={firstIpIsEmpty}
                  size="mini"
                  icon="plus"
                  onClick={handleAddIpAddress}
                />
              </div>
              {!!ipAddresses.length && (
                <div className={b('ip-addresses')}>
                  {ipAddresses.map((item, i) => (
                    <IpAddressRow
                      key={item.id}
                      data={item}
                      autoFocus={i === 0 && !item.value}
                      error={ipAddressErrors[item.id] || false}
                      onChange={handleChangeIpAddress}
                      onDelete={handleDeleteIpAddress}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
    </Modal>
  );
}
