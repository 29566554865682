import React, { MouseEventHandler } from 'react';
import ChevronDown from './icons/chevronDown';
import ChevronUp from './icons/chevronUp';
import ChevronLeft from './icons/chevronLeft';
import ChevronRight from './icons/chevronRight';
import ChevronRightThin from './icons/chevronRightThin';
import Trash from './icons/trash';
import ClearFilled from './icons/clearFilled';
import ArrowLeft from './icons/arrowLeft';
import ArrowRight from './icons/arrowRight';
import ArrowUp from './icons/arrowUp';
import ArrowDown from './icons/arrowDown';
import ArrowLeftSquare from './icons/arrowLeftSquare';
import ArrowRightSquare from './icons/arrowRightSquare';
import Close from './icons/close';
import Plus from './icons/plus';
import PlusSmall from './icons/plusSmall';
import Minus from './icons/minus';
import Pen from './icons/pen';
import PenOutline from './icons/penOutline';
import Drag from './icons/drag';
import Checkmark from './icons/checkmark';
import CheckmarkSmall from './icons/checkmarkSmall';
import Paperclip from './icons/paperclip';
import Search from './icons/search';
import Filters from './icons/filters';
import GraphNodeEntryIcon from './icons/graphNodes/entry';
import GraphNodeActionIcon from './icons/graphNodes/action';
import GraphNodeConditionIcon from './icons/graphNodes/condition';
import GraphNodeCloseIcon from './icons/graphNodes/close';
import GraphNodeOperatorIcon from './icons/graphNodes/operator';
import GraphNodeResponseIcon from './icons/graphNodes/response';
import GraphNodeSelectScenarioIcon from './icons/graphNodes/selectScenario';
import DocumentsIcon from './icons/documents';
import TableIcon from './icons/table';
import SortIcon from './icons/sort';
import HistoryIcon from './icons/history';
import DownloadIcon from './icons/download';
import UploadIcon from './icons/upload';
import CalendarIcon from './icons/calendar';
import CalendarOutlineIcon from './icons/calendarOutline';
import CopyIcon from './icons/copy';
import InfoIcon from './icons/info';
import MoveIcon from './icons/move';
import QuestionMarkIcon from './icons/questionMark';
import PlayIcon from './icons/play';
import PauseIcon from './icons/pause';
import PhoneIcon from './icons/phone';
import LoaderBracketsIcon from './icons/loaderBrackets';
import FileIcon from './icons/file';
import BurgerDotsIcon from './icons/burgerDots';
import UndoIcon from './icons/undo';
import RedoIcon from './icons/redo';
import RulerIcon from './icons/ruler';
import ListPlusIcon from './icons/listPlus';
import CurvedPathIcon from './icons/curvedPath';
import CurvedPathWithPlusIcon from './icons/curvedPathWithPlus';
import FlasherIcon from './icons/flasher';
import HandIcon from './icons/hand';
import LightningIcon from './icons/lightning';
import SettingsIcon from './icons/settings';
import BookshelfIcon from './icons/bookshelf';
import GridIcon from './icons/grid';
import ChatIcon from './icons/chat';
import UserIcon from './icons/user';
import UserAvatarIcon from './icons/userAvatar';
import ThumbUpIcon from './icons/thumbUp';
import ThumbDownIcon from './icons/thumbDown';
import ThumbUpFilledIcon from './icons/thumbUpFilled';
import ThumbDownFilledIcon from './icons/thumbDownFilled';
import RouteIcon from './icons/route';
import CommentOutlineIcon from './icons/commentOutline';
import ListCheckedIcon from './icons/listChecked';
import HeadphonesIcon from './icons/headphones';
import ArrowDashboardIcon from './icons/arrowDashboard';
import TimeOutlineIcon from './icons/timeOutline';
import BookIcon from './icons/book';
import CodeIcon from './icons/code';
import BellIcon from './icons/bell';
import BellOutlineIcon from './icons/bellOutline';
import BellOutlineNoticeIcon from './icons/bellOutlineNotice';
import LogoIcon from './icons/logo';
import LogoTextIcon from './icons/logoText';
import RefreshIcon from './icons/refresh';
import YaIcon from './icons/ya';
import StarIcon from './icons/star';
import StarStrokeIcon from './icons/starStroke';
import FileErrorIcon from './icons/fileError';
import SleepingRobotIcon from './icons/sleepingRobot';
import AIIcon from './icons/ai';
import CrossBan from './icons/crossBan';
import CrossIcon from './icons/cross';
import CheckmarkRounded from './icons/checkmarkRounded';
import ArrowCircleUpIcon from './icons/arrowCircleUp';
import TelegramIcon from './icons/telegram';
import WhatsAppIcon from './icons/whatsapp';
import CoinsPlusIcon from './icons/coinsPlus';
import SuitcaseIcon from './icons/suitcase';
import AlarmCircleIcon from './icons/alarmCircle';
import ExclamationIcon from './icons/exclamation';
import BoxOutlineIcon from './icons/boxOutline';
import SearchListIcon from './icons/searchList';
import RefreshAltIcon from './icons/refreshAlt';
import DocumentDownloadIcon from './icons/documentDownload';
import BugIcon from './icons/bug';
import NoteIcon from './icons/note';
import AttachIcon from './icons/attach';
import NewTabIcon from './icons/newTab';
import MapIcon from './icons/map';
import FrameIcon from './icons/frame';
import DocTextIcon from './icons/docText';
import CopyPlusIcon from './icons/copyPlus';
import EyeOutlineIcon from './icons/eyeOutline';
import EyeOffOutlineIcon from './icons/eyeOffOutline';
import WeightIcon from './icons/weight';

import { IconTypes, SvgProps } from './types';

import './styles.scss';

const getIcon = (type: string, props: SvgProps) => {
  switch (type) {
    case 'chevronDown': return ChevronDown(props);
    case 'chevronUp': return ChevronUp(props);
    case 'chevronRight': return ChevronRight(props);
    case 'chevronLeft': return ChevronLeft(props);
    case 'chevronRightThin': return ChevronRightThin(props);
    case 'trash': return Trash(props);
    case 'clearFilled': return ClearFilled(props);
    case 'arrowDown': return ArrowDown(props);
    case 'arrowLeft': return ArrowLeft(props);
    case 'arrowRight': return ArrowRight(props);
    case 'arrowUp': return ArrowUp(props);
    case 'squareArrowLeft': return ArrowLeftSquare(props);
    case 'squareArrowRight': return ArrowRightSquare(props);
    case 'close': return Close(props);
    case 'plus': return Plus(props);
    case 'plusSmall': return PlusSmall(props);
    case 'minus': return Minus(props);
    case 'drag': return Drag(props);
    case 'pen': return Pen(props);
    case 'penOutline': return PenOutline(props);
    case 'checkmark': return Checkmark(props);
    case 'checkmarkSmall': return CheckmarkSmall(props);
    case 'paperclip': return Paperclip(props);
    case 'search': return Search(props);
    case 'filters': return Filters(props);
    case 'graphNodeEntry': return GraphNodeEntryIcon(props);
    case 'graphNodeAction': return GraphNodeActionIcon(props);
    case 'graphNodeCondition': return GraphNodeConditionIcon(props);
    case 'graphNodeClose': return GraphNodeCloseIcon(props);
    case 'graphNodeOperator': return GraphNodeOperatorIcon(props);
    case 'graphNodeResponse': return GraphNodeResponseIcon(props);
    case 'graphNodeSelectScenario': return GraphNodeSelectScenarioIcon(props);
    case 'documents': return DocumentsIcon(props);
    case 'table': return TableIcon(props);
    case 'sort': return SortIcon(props);
    case 'history': return HistoryIcon(props);
    case 'download': return DownloadIcon(props);
    case 'upload': return UploadIcon(props);
    case 'calendar': return CalendarIcon(props);
    case 'calendarOutline': return CalendarOutlineIcon(props);
    case 'copy': return CopyIcon(props);
    case 'copyPlus': return CopyPlusIcon(props);
    case 'info': return InfoIcon(props);
    case 'move': return MoveIcon(props);
    case 'questionMark': return QuestionMarkIcon(props);
    case 'play': return PlayIcon(props);
    case 'pause': return PauseIcon(props);
    case 'phone': return PhoneIcon(props);
    case 'loaderBrackets': return LoaderBracketsIcon(props);
    case 'file': return FileIcon(props);
    case 'burgerDots': return BurgerDotsIcon(props);
    case 'undo': return UndoIcon(props);
    case 'redo': return RedoIcon(props);
    case 'ruler': return RulerIcon(props);
    case 'listPlus': return ListPlusIcon(props);
    case 'curvedPath': return CurvedPathIcon(props);
    case 'curvedPathWithPlus': return CurvedPathWithPlusIcon(props);
    case 'flasher': return FlasherIcon(props);
    case 'hand': return HandIcon(props);
    case 'lightning': return LightningIcon(props);
    case 'settings': return SettingsIcon(props);
    case 'bookshelf': return BookshelfIcon(props);
    case 'grid': return GridIcon(props);
    case 'chat': return ChatIcon(props);
    case 'user': return UserIcon(props);
    case 'userAvatar': return UserAvatarIcon(props);
    case 'thumbUp': return ThumbUpIcon(props);
    case 'thumbDown': return ThumbDownIcon(props);
    case 'thumbUpFilled': return ThumbUpFilledIcon(props);
    case 'thumbDownFilled': return ThumbDownFilledIcon(props);
    case 'route': return RouteIcon(props);
    case 'commentOutline': return CommentOutlineIcon(props);
    case 'listChecked': return ListCheckedIcon(props);
    case 'headphones': return HeadphonesIcon(props);
    case 'arrowDashboard': return ArrowDashboardIcon(props);
    case 'timeOutline': return TimeOutlineIcon(props);
    case 'book': return BookIcon(props);
    case 'code': return CodeIcon(props);
    case 'bell': return BellIcon(props);
    case 'logo': return LogoIcon(props);
    case 'logoText': return LogoTextIcon(props);
    case 'refresh': return RefreshIcon(props);
    case 'ya': return YaIcon(props);
    case 'star': return StarIcon(props);
    case 'starStroke': return StarStrokeIcon(props);
    case 'fileError': return FileErrorIcon(props);
    case 'sleepingRobot': return SleepingRobotIcon(props);
    case 'ai': return AIIcon(props);
    case 'cross': return CrossIcon(props);
    case 'crossBan': return CrossBan(props);
    case 'checkmarkRounded': return CheckmarkRounded(props);
    case 'arrowCircleUp': return ArrowCircleUpIcon(props);
    case 'telegram': return TelegramIcon(props);
    case 'whatsapp': return WhatsAppIcon(props);
    case 'coinsPlus': return CoinsPlusIcon(props);
    case 'suitcase': return SuitcaseIcon(props);
    case 'alarmCircle': return AlarmCircleIcon(props);
    case 'exclamation': return ExclamationIcon(props);
    case 'boxOutline': return BoxOutlineIcon(props);
    case 'bellOutline': return BellOutlineIcon(props);
    case 'bellOutlineNotice': return BellOutlineNoticeIcon(props);
    case 'refreshAlt': return RefreshAltIcon(props);
    case 'searchList': return SearchListIcon(props);
    case 'documentDownload': return DocumentDownloadIcon(props);
    case 'docText': return DocTextIcon(props);
    case 'bug': return BugIcon(props);
    case 'note': return NoteIcon(props);
    case 'attach': return AttachIcon(props);
    case 'newTab': return NewTabIcon(props);
    case 'map': return MapIcon(props);
    case 'frame': return FrameIcon(props);
    case 'eyeOutline': return EyeOutlineIcon(props);
    case 'eyeOffOutline': return EyeOffOutlineIcon(props);
    case 'weight': return WeightIcon(props);
    default: return '';
  }
};

type IconProps = {
  className?: string;
  type: IconTypes;
  svgProps?: SvgProps;
  iconSize?: IconSize;
  title?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export enum IconSize {
  tiny = "tiny",
  mini = "mini",
  small = "small",
  smallPlus = "smallPlus",
  medium = "medium",
  large = "large",
  giant = "giant",
}

export const SIZES = {
  [IconSize.giant]: "100",
  [IconSize.large]: "40",
  [IconSize.medium]: "24",
  [IconSize.smallPlus]: "20",
  [IconSize.small]: "16",
  [IconSize.mini]: "12",
  [IconSize.tiny]: "8",
};

function Icon({
  className = '',
  type,
  svgProps = {},
  iconSize,
  title,
  onClick,
}: IconProps) {
  return (
    <span title={title} className={`icon ${className}`} onClick={onClick}>
      {getIcon(
        type,
        {
          ...svgProps,
          ...(iconSize && { width: SIZES[iconSize], height: SIZES[iconSize] }),
        },
      )}
    </span>
  );
}

export type { IconTypes, SvgProps };
export default Icon;
