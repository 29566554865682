import React from 'react';

import { SvgProps } from '../types';

function RefreshIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.5L7 4L12 7.5V5C15.87 5 19 8.13 19 12C19 13.93 18.22 15.68 16.95 16.95L18.36 18.36C19.99 16.73 21 14.48 21 12C21 7.03 16.97 3 12 3V0.5ZM12 23.5L17 20L12 16.5V19C8.13 19 5 15.87 5 12C5 10.07 5.78 8.32 7.05 7.05L5.64 5.64C4.01 7.27 3 9.52 3 12C3 16.97 7.03 21 12 21V23.5Z"
        fill={color}
      />
    </svg>
  );
}

export default RefreshIcon;
