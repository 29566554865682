import { BalanceClient, getEmptyBalanceClient } from "../../types/models/Billing";
import { Project } from "../../types/models/Project";

const STORAGE_KEY = "clients";

type StorageType = Record<Project['slug'], BalanceClient['id']>;

export function getClientForProject(slug: Project['slug']): BalanceClient['id'] {
  try {
    const storedValue = localStorage.getItem(STORAGE_KEY) || "";
    const value: StorageType = JSON.parse(storedValue);

    if (value[slug]) {
      return value[slug];
    }

    return getEmptyBalanceClient().id;
  } catch {
    return getEmptyBalanceClient().id;
  }
}

export function setClientForProject(slug: Project['slug'], value: BalanceClient['id'] | null) {
  try {
    const storageExist = !!localStorage.getItem(STORAGE_KEY);

    if (!storageExist) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
    }

    const storage = JSON.parse(localStorage.getItem(STORAGE_KEY) || "") || {};

    if (value) {
      storage[slug] = value;
    } else {
      storage[slug] = null;
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));
  } catch {
    // eslint-disable-next-line no-console
    console.log("Error on getting client");
  }
}
