import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ModelBackend } from "../backendModels/CommonModelBackend";
import { Model } from "../models/CommonModel";
import { parseSlot, parseSlotToBackend } from "./SlotParser";

export function parseModel(value: ModelBackend): Model {
  return {
    ...objSnakeToCamel(value),
    slots: Array.isArray(value.slots) ?
      value.slots.map(parseSlot) :
      [],
    version: Number(value.version),
  };
}
export function parseModelToBackend(value: Model): ModelBackend {
  return {
    ...objCamelToSnake(value),
    slots: value.slots.map(parseSlotToBackend),
  };
}
