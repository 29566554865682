import { isValid, parseISO } from "date-fns";

import { dateMscToLocal } from "../../services/helpers/dateUtils";
import {
  DialogsStatisticsStatisticsGroupBackend,
  DialogsStatisticsBackend,
} from "../backendModels/AutomationStatisticsBackend";
import {
  DialogsStatisticsGroup,
  PercentMetrics,
} from "../models/AutomationStatistics";
import { getPercents } from "../../services/helpers/utilities";
import { generateId } from "../../services/helpers/generateId";
import i18n from "../../services/i18n";

export const COUNTER_PREFIX = 'counter';

export function parseDialogsStatisticsGroup(
  value: DialogsStatisticsStatisticsGroupBackend,
): DialogsStatisticsGroup {
  return {
    title: parseTitle(value.title),
    totalNumber: {
      number: value.total_number || 0,
      percent: (value.total_number || 0) > 0 ? 100 : 0,
    },
    automatedTemp: {
      number: value.automated_temp_number || 0,
      percent: getPercents(value.total_number || 0, value.automated_temp_number || 0),
    },
    automatedForwarded: {
      number: value.automated_forwarded_number || 0,
      percent: getPercents(value.total_number || 0, value.automated_forwarded_number || 0),
    },
    automatedNotForwarded: {
      number: value.automated_not_forwarded_number || 0,
      percent: getPercents(value.total_number || 0, value.automated_not_forwarded_number || 0),
    },
    notAutomated: {
      number: value.not_automated_number || 0,
      percent: getPercents(value.total_number || 0, value.not_automated_number || 0),
    },
    notAutomatedForwarded: {
      number: value.not_automated_forwarded_number || 0,
      percent: getPercents(value.total_number || 0, value.not_automated_forwarded_number || 0),
    },
    notAutomatedNotForwarded: {
      number: value.not_automated_not_forwarded_number || 0,
      percent: getPercents(value.total_number || 0, value.not_automated_not_forwarded_number || 0),
    },
    opened: {
      number: value.opened_number || 0,
      percent: getPercents(value.total_number || 0, value.opened_number || 0),
    },
    closed: {
      number: value.closed_number || 0,
      percent: getPercents(value.total_number || 0, value.closed_number || 0),
    },
    forwarded: {
      number: value.forwarded_number || 0,
      percent: getPercents(value.total_number || 0, value.forwarded_number || 0),
    },
    reopened: {
      number: value.reopened_number || 0,
      percent: getPercents(value.total_number || 0, value.reopened_number || 0),
    },
    messagesAutomatedNumber: {
      number: value.messages_automated_temp_number || 0,
      percent: getPercents((value.messages_number || 0), (value.messages_automated_temp_number || 0)),
    },
    messagesAutomatedTempNumber: {
      number: value.messages_automated_temp_number || 0,
      percent: getPercents(value.messages_number || 0, value.messages_automated_temp_number || 0),
    },
    messagesNumber: {
      number: value.messages_number || 0,
      percent: (value.messages_number || 0) > 0 ? 100 : 0,
    },
    tagCounters: (value.tag_counters || [])
      .reduce<Record<string, PercentMetrics>>((accum, tagStatistics) => {
        const { tag_name: tagName, count } = tagStatistics;

        if (!tagName) return accum;

        accum[tagName] = {
          number: count || 0,
        };

        return accum;
      }, {}),
    userScoreCounters: (value.user_score_counters || [])
      .reduce<Record<string, PercentMetrics>>(
        (accum, counter) => {
          // eslint-disable-next-line no-param-reassign
          accum[`${COUNTER_PREFIX}${counter.score}`] = {
            number: counter.count,
            percent: getPercents(value.user_scores_total_count || 0, counter.count),
          };

          return accum;
        },
        {},
      ),
    resolvedByBot: {
      number: value.resolved_by_bot_number || 0,
      percent: getPercents(value.total_number || 0, value.resolved_by_bot_number || 0),
    },
    csat: value.csat || 0,
    cdsat: value.cdsat || 0,
    nps: value.nps || 0,
    meanUserScore: value.mean_user_score || 0,
    userScoresTotalCount: value.user_scores_total_count || 0,
    minutes: Math.round((value.minutes || 0) * 10) / 10,
    id: generateId(),
  };
}

function parseTitle(value?: string): string {
  if (value === undefined || value === null) return '';

  if (isValid(parseISO(value))) {
    return dateMscToLocal(new Date(value)).toString();
  }

  if (value === "[EMPTY_TOPIC_TITLE]") {
    return i18n.t("TOPICS.WITHOUT_TOPIC");
  }

  if (value === "[UNKNOWN_TOPIC_TITLE]") {
    return i18n.t("PAGE_ACCOUNT.TOPICS.ARCHIVED");
  }

  if (value.length === 0) {
    return i18n.t("COMMON.IN_TOTAL");
  }

  return value;
}

export function parseDialogsStatistics(
  aggregated: DialogsStatisticsBackend,
  periods: DialogsStatisticsBackend[],
): DialogsStatisticsGroup[] {
  const result: DialogsStatisticsGroup[] = [];

  const totalStatistics: DialogsStatisticsGroup = {
    ...parseDialogsStatisticsGroup(aggregated.aggregated),
    items: aggregated.periods.map(parseDialogsStatisticsGroup),
    isTotals: true,
  };

  result.push(totalStatistics);

  periods.forEach(period => {
    const topicSummary = period.aggregated;

    result.push({
      ...parseDialogsStatisticsGroup(topicSummary),
      items: period.periods.map(parseDialogsStatisticsGroup),
    });
  });

  return result;
}
