import {
  FC, memo, Ref, useCallback,
} from 'react';

import { block } from 'bem-cn';
import { useTranslation } from 'react-i18next';

import Button, { ButtonClickEvent } from '../../button';
import { getHelpDatePeriod, HelpDateButton } from '../helpers';

import './styles.scss';

const b = block('nd-datepicker-date-time-inputs');

export type DateTimeInputsState = Date | [Date | null, Date | null] | null;

type Props = {
  className?: string;
  helpButtons?: HelpDateButton[];
  onChange: (date: DateTimeInputsState, event?: ButtonClickEvent) => void;
  datepickerRef?: Ref<HTMLElement>,
};

const DateHelpButtons: FC<Props> = ({
  className = '',
  helpButtons,
  onChange,
  datepickerRef,
}) => {
  const { t } = useTranslation();

  const showHelpButtons = Array.isArray(helpButtons) && helpButtons.length > 0;

  const helpButtonClick = useCallback(
    (button: HelpDateButton) => (event: ButtonClickEvent) => {
      onChange(getHelpDatePeriod(button), event);
      //@ts-ignore
      datepickerRef.current.calendar.instanceRef.changeMonthYear(new Date());
    },
    [onChange, datepickerRef],
  );

  return (
    <div className={`${b()} ${className}`}>
      {showHelpButtons && (
        <div className={b("help-buttons")}>
          { // @ts-ignore showHelpButtons это проверка на helpButtons, но ts её не понимает
          helpButtons.map(button => (
            <Button
              key={button}
              size="mini"
              name={`date-help-button_${button}`}
              className={b("date-help-button")}
              onClick={helpButtonClick(button)}
            >
              {t(`PAGE_ACCOUNT.DATE_BUTTONS.${button.toUpperCase()}`)}
            </Button>
          ))
          }
        </div>
      )}
    </div>
  );
};

export default memo(DateHelpButtons);
