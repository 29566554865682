import { SagaIterator } from 'redux-saga';
import {
  call, fork, put, takeLatest,
} from "redux-saga/effects";

import * as actions from './actions';
import Api, { ApiResponse } from '../../services/api';
import i18n from '../../services/i18n';
import { ErrorAction } from '../reducers';

import { ProvideSuperUserRoleRequest } from '../../types/requests/Users';
import { GetAllUsersRequest, GetAllUsersResponse } from '../../types/requests/UserAccesses';
import { objSnakeToCamelDeep } from '../../services/helpers/utilities';
import { SavedUser } from '../../types/models/UserAccesses';

function* loadSuperUsers(): SagaIterator {
  yield takeLatest(actions.loadSuperUsers.request, function* () {
    try {
      const params: GetAllUsersRequest = { params: { skipProvider: true } };
      const response: ApiResponse<GetAllUsersResponse> = yield call(Api.getAllUsers, params);
      const parsed = response.data.users.map(objSnakeToCamelDeep) as SavedUser[];
      yield put(actions.loadSuperUsers.success(parsed));
    } catch (err) {
      yield put(actions.loadSuperUsers.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.LOAD", { name: i18n.t("ERRORS.API.SUPERUSERS.MULTIPLE") }),
      )));
    }
  });
}

function* provideSuperUserRole(): SagaIterator {
  yield takeLatest(actions.provideSuperUserRole.request, function* ({ payload }) {
    try {
      const params: ProvideSuperUserRoleRequest = payload;
      yield call(Api.provideSuperUserRole, params);
      yield put(actions.loadSuperUsers.request());
    } catch (err) {
      yield put(actions.provideSuperUserRole.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.SUPERUSERS.PROVIDE_SUPERUSER_ROLE"),
      )));
    }
  });
}

function* superusersSagas() {
  yield fork(loadSuperUsers);
  yield fork(provideSuperUserRole);
}

export default superusersSagas;
