import { createAction, createAsyncAction } from "typesafe-actions";

import { ErrorAction } from "../reducers";
import { Feature } from '../../types/models/Feature';

export const loadFeatures = createAsyncAction(
  'features/load/requested',
  'features/load/succeeded',
  'features/load/failed',
)<undefined, Feature[], ErrorAction>();

export const createFeature = createAsyncAction(
  'features/create/requested',
  'features/create/succeeded',
  'features/create/failed',
)<
  { feature: Feature, modalId?: string },
  Feature,
  ErrorAction
>();

export const deleteFeature = createAsyncAction(
  'features/delete/requested',
  'features/delete/succeeded',
  'features/delete/failed',
)<string, string, ErrorAction>();

export const updateFeature = createAsyncAction(
  'features/update/requested',
  'features/update/succeeded',
  'features/update/failed',
)<Feature, Feature, ErrorAction<Feature>>();

export const loadFavoriteFeatures = createAsyncAction(
  'favoriteFeatures/load/requested',
  'favoriteFeatures/load/succeeded',
  'favoriteFeatures/load/failed',
)<undefined, string[], ErrorAction>();

export const addFavoriteFeature = createAsyncAction(
  'favoriteFeatures/update/requested',
  'favoriteFeatures/update/succeeded',
  'favoriteFeatures/update/failed',
)<string, string, ErrorAction<string>>();

export const deleteFavoriteFeature = createAsyncAction(
  'favoriteFeatures/delete/requested',
  'favoriteFeatures/delete/succeeded',
  'favoriteFeatures/delete/failed',
)<string, string, ErrorAction<string>>();

export const createNewFeature = createAction('features/new/create')<undefined>();
export const clearToCreateFeatures = createAction('features/new/clear')<undefined>();
export const deleteNewFeature = createAction('features/new/delete')<string>();

export const resetReducer = createAction('features/resetReducer')();
