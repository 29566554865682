import { AUDIO_FILE_TEXT_PREFIX } from "../../pages/graphEditor/components/nodeEditor/forms/response/helpers";
import { TAG_PLAY, TAG_SPEAK } from "../../pages/graphEditor/helpers";
import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ScenarioGraphActionBackend } from "../backendModels/ScenarioGraphActionBackend";
import { ScenarioGraphNodeBackend } from "../backendModels/ScenarioGraphNodeBackend";
import { Lang } from "../models/Languages";
import { ResponseAction, ScenarioGraphAction } from "../models/ScenarioGraphAction";
import { ScenarioGraphNode } from "../models/ScenarioGraphNode";
import { ScenarioTag } from "../models/ScenarioTag";
import { parseScenarioGraphAction, parseScenarioGraphActionToBackend } from "./ScenarioGraphActionParser";
import { parseScenarioGraphNodeMeta, parseScenarioGraphNodeMetaToBackend } from "./ScenarioGraphNodeMeta";

export function parseScenarioGraphNode(
  value: ScenarioGraphNodeBackend,
  mainLang?: Lang,
): ScenarioGraphNode {
  return {
    ...objSnakeToCamel(value),
    ...(value.action && { action: parseScenarioGraphAction(value, mainLang) }),
    ...(value.meta && { meta: parseScenarioGraphNodeMeta(value.meta) }),
  };
}

type CustomActionsBackend = Pick<ScenarioGraphNodeBackend, 'action' | 'actions'>;

function parseActionToBackend(
  action: ScenarioGraphAction,
): CustomActionsBackend | undefined {
  if (!action) return undefined;

  const parsedAction = parseScenarioGraphActionToBackend(action);

  if (Array.isArray(parsedAction)) {
    const result: CustomActionsBackend = { action: parsedAction[0] };

    if (parsedAction?.length > 1) {
      result.actions = parsedAction.slice(1);
    }

    return result;
  }

  return {
    action: parsedAction as ScenarioGraphActionBackend,
  };
}

export function parseScenarioGraphNodeToBackend(
  value: ScenarioGraphNode,
  mainLang: Lang,
  isVoiceProject: boolean,
): ScenarioGraphNodeBackend {
  const convertNodeTagsToBackend = (node: ScenarioGraphNode): ScenarioTag[] => {
    if (value.action?.type !== 'response') {
      return node.tags;
    }

    if (!isVoiceProject) {
      return value.tags;
    }

    const actionText = (((node.action as ResponseAction).texts || {})[mainLang] || [])[0] || '';
    const isAudioFileSelected = actionText.startsWith(AUDIO_FILE_TEXT_PREFIX);

    let { tags = [] } = node;

    // На всякий случай чистим оба тега, чтобы избежать дублирования
    tags = removeScenarioTag(tags, TAG_PLAY);
    tags = removeScenarioTag(tags, TAG_SPEAK);

    if (isAudioFileSelected) {
      tags.push(TAG_PLAY);
    } else {
      tags.push(TAG_SPEAK);
    }

    return tags;
  };

  return {
    ...objCamelToSnake(value),
    ...(value.action && parseActionToBackend(value.action)),
    ...(value.meta && { meta: parseScenarioGraphNodeMetaToBackend(value.meta) }),
    tags: convertNodeTagsToBackend(value),
  };
}

function removeScenarioTag(list: ScenarioTag[], tag: ScenarioTag): ScenarioTag[] {
  return list.filter(t => t.slug !== tag.slug);
}
