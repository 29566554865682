import React from 'react';

import { SvgProps } from '../types';

function DownloadIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0099 15.7358C12.2486 15.7358 12.4773 15.6584 12.716 15.426L16.0475 12.2988C16.2166 12.1342 16.316 11.9502 16.316 11.7178C16.316 11.2628 15.9381 10.9336 15.4807 10.9336C15.2519 10.9336 15.0232 11.0304 14.8641 11.2047L13.4718 12.6376L12.8354 13.3541L12.9348 11.9212V1.89073C12.9348 1.40664 12.5171 1 12.0099 1C11.4928 1 11.0751 1.40664 11.0751 1.89073V11.9212L11.1746 13.3541L10.5381 12.6376L9.14586 11.2047C8.98674 11.0304 8.74807 10.9336 8.51934 10.9336C8.06188 10.9336 7.69392 11.2628 7.69392 11.7178C7.69392 11.9502 7.79337 12.1342 7.96243 12.2988L11.3039 15.426C11.5425 15.6584 11.7613 15.7358 12.0099 15.7358ZM6.27182 22H17.7282C19.8862 22 21 20.9156 21 18.8437V9.32642C21 7.2545 19.8862 6.17012 17.7282 6.17012H15.0431V8.09682H17.5989C18.5039 8.09682 19.021 8.56155 19.021 9.49101V18.6694C19.021 19.5989 18.5039 20.0636 17.5989 20.0636H6.4011C5.48619 20.0636 4.97901 19.5989 4.97901 18.6694V9.49101C4.97901 8.56155 5.48619 8.09682 6.4011 8.09682H8.98674V6.17012H6.27182C4.12376 6.17012 3 7.24481 3 9.32642V18.8437C3 20.9156 4.12376 22 6.27182 22Z"
        fill={color}
      />
    </svg>
  );
}

export default DownloadIcon;
