import { createAction, createAsyncAction } from "typesafe-actions";
import { ReleaseNote, SavedReleaseNote } from "../../types/models/ReleaseNotes";
import { ErrorAction } from "../reducers";

export const loadReleaseNotes = createAsyncAction(
  'releaseNotes/load/requested',
  'releaseNotes/load/succeeded',
  'releaseNotes/load/failed',
)<undefined, SavedReleaseNote[], ErrorAction>();

export const loadTheLastReleaseNote = createAsyncAction(
  'releaseNotes/loadTheLastOne/requested',
  'releaseNotes/loadTheLastOne/succeeded',
  'releaseNotes/loadTheLastOne/failed',
)<undefined, SavedReleaseNote, ErrorAction>();

export const createReleaseNote = createAsyncAction(
  'releaseNotes/create/requested',
  'releaseNotes/create/succeeded',
  'releaseNotes/create/failed',
)<ReleaseNote, SavedReleaseNote, ErrorAction>();

export const updateReleaseNote = createAsyncAction(
  'releaseNotes/update/requested',
  'releaseNotes/update/succeeded',
  'releaseNotes/update/failed',
)<SavedReleaseNote, SavedReleaseNote, ErrorAction>();

export const deleteReleaseNote = createAsyncAction(
  'releaseNotes/delete/requested',
  'releaseNotes/delete/succeeded',
  'releaseNotes/delete/failed',
)<SavedReleaseNote['id'], undefined, ErrorAction>();

export const openReleaseNotesModal = createAction('releaseNotes/openList')();
export const closeReleaseNotesModal = createAction('releaseNotes/closeList')();
export const closeLastReleaseNote = createAction('releaseNotes/closeLast')<string>();

export const setNoteToEdit = createAction('releaseNotes/setNoteToEdit')<ReleaseNote>();
export const resetNoteToEdit = createAction('releaseNotes/resetNoteToEdit')();
