/* eslint-disable camelcase */
export type User = {
  id?: string;
  username?: string;
  isActive?: boolean;
  isSuperadmin?: boolean;
  createdAt?: string;
  provider?: string;
  providerId?: string;
};

export type SavedUser = User & Required<Pick<User, 'id' | 'username'>>;

export type UserAccess = {
  projectSlug: string;
  capabilities: string[];
  group: UserRole;
  permissions: string[];
};

export type UserAccesses = {
  user: User;
  accesses: UserAccess[];
};

export enum UserRole {
  guest = 'guest',
  standard = 'standard',
  admin = 'admin',
}

export const rolePriorities = {
  [UserRole.guest]: 0,
  [UserRole.standard]: 1,
  [UserRole.admin]: 2,
};

export type GroupCapabilities = {
  guest: string[];
  standard: string[];
  admin: string[];
};

export type EditableGroupCapabilities = Record<keyof GroupCapabilities, Record<string, boolean>>;

export type ProjectUser = {
  id: string;
  username?: string;
  group: UserRole;
}

export type ProjectUserList = {
  users: ProjectUser[];
}

export type GroupCapabilitiesPreset = {
  capabilities: string[];
}

export type UpdateUserGroupRequest = {
  params: {
    projectSlug: string;
    userId: string;
  },
  body: UserRole;
}

export type UpdateUserGroupResponse = ProjectUser;

export type RemoveUserFromProjectRequest = {
  projectSlug: string;
  userId: string;
};

export type Capability = {
  slug: string;
  suEnabled: boolean;
}

export type Preset = {
  id?: number;
  slug: string;
  title: string;
}
