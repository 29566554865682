import { OutgoingCallResult } from "./OutgoingCallResult";
import { OutgoingCallStatus } from "./OutgoingCallStatus";

export type OutgoingCall = {
  id: string,
  created: string,
  ended?: string,
  initiateAt?: string,
  initiated?: string,
  taskId: string,
  chatId: string,
  phone: string,
  status: OutgoingCallStatus,
  errorMessage?: string,
  result?: {
    value?: OutgoingCallResult,
    loading: boolean
  },
  length?: number,
  hasRecord?: boolean,
  direction?: CallDirection,
}

export enum CallDirection {
  incoming = "incoming",
  outgoing = "outgoing",
  default = "all",
}

export function outgoingCallCanBeDownloaded(value: OutgoingCall): boolean {
  return !!value.hasRecord;
}
