import { DialogsStatisticsStatisticsGroupBackend } from "../../types/backendModels/AutomationStatisticsBackend";
import { MessageMetrics } from "../../types/models/AutomationStatistics";
import { StatsType } from "../../types/models/Task";

type K = 'accountOverview' | 'callsGrouped' | 'callsSeparated';
export const getStatsKey = (statsType?: StatsType): K => {
  switch (statsType) {
    case StatsType.callsGrouped:
      return 'callsGrouped';
    case StatsType.callsSeparated:
      return 'callsSeparated';
    case StatsType.dialogs:
    default:
      return 'accountOverview';
  }
};

export function getDialogsStatisticsColumns(
  selectedMetrics: MessageMetrics[],
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<keyof DialogsStatisticsStatisticsGroupBackend | any> {
  const result: Array<keyof DialogsStatisticsStatisticsGroupBackend> = [
    'total_number',
    'opened_number',
  ];

  if (selectedMetrics.includes(MessageMetrics.automatizationNew)) {
    result.push(
      'automated_temp_number',
      'automated_forwarded_number',
      'automated_not_forwarded_number',
    );
  }

  if (selectedMetrics.includes(MessageMetrics.noAutomatization)) {
    result.push(
      'not_automated_number',
      'not_automated_forwarded_number',
      'not_automated_not_forwarded_number',
    );
  }

  if (selectedMetrics.includes(MessageMetrics.userRates)) {
    result.push(
      'csat',
      'cdsat',
      'nps',
      'mean_user_score',
      'user_scores_total_count',
    );
  }

  if (selectedMetrics.includes(MessageMetrics.byMessages)) {
    result.push('messages_number');
  }

  if (selectedMetrics.includes(MessageMetrics.messagesAutomatedNumber)) {
    result.push(
      'messages_automated_temp_number',
    );
  }

  if (selectedMetrics.includes(MessageMetrics.resolvedByBot)) {
    result.push(
      'resolved_by_bot_number',
    );
  }

  return result;
}
