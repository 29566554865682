export type Game = {
  slug: string;
  extra: string;
}

export type Games = {
  games: Game[]
};

export enum ExtendedGameType {
  Diagnostics = 'diagnostics',
  Marking = 'marking',
  Clustering = 'clustering', // кластеризация, не используется на бэке
}

export type GameType = ExtendedGameType.Diagnostics | ExtendedGameType.Marking;

export type GameDataDiagnostics = {
  type: ExtendedGameType.Diagnostics;
  records: GameDataDiagnosticsRecord[];
};

export type GameDataDiagnosticsRecord = {
  text: string;
  chatId?: string;
  iteration?: number;
}

export type GameDataMarking = {
  type: ExtendedGameType.Marking;
  records: string[];
};

export type GameData = GameDataDiagnostics | GameDataMarking;

export enum GameResultRecordType {
  Addition = 'addition',
  Exception = 'exception',
}

export type GameResultRecord = {
  text: string;
  scenarioSlug: string;
  type: GameResultRecordType;
}

export type GameResult = {
  markingRecords: GameResultRecord[];
  trashRecords: string[];
}
