const referenceConfig = {
  scenarios: "https://yandex.ru/support/supportai/scenario/text.html",
  versions: "https://yandex.ru/support/supportai/versions/settings.html",
  voice: "https://yandex.ru/support/supportai/voice/settings.html",
  statistics: "https://yandex.ru/support/supportai/statistics/settings.html",
  training: "https://yandex.ru/support/supportai/training/training.html",
  quality: "https://yandex.ru/support2/supportai/ru/scenario/quality-control",
  models: "https://yandex.ru/support2/supportai/ru/models",
  integrations: "https://yandex.ru/support2/supportai/ru/integrations/outgoing-integrations",
  dialogs: "https://yandex.ru/support2/supportai/ru/reports/dialogue-history",
};

export { referenceConfig };
