import React from 'react';

import { SvgProps } from '../types';

function UserIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87048 11.9452C6.56792 11.2016 6.51291 10.9232 6.43039 10.3051C6.34873 9.68712 6.60144 9.38456 6.79398 9.38456C6.96847 9.38456 7.26415 9.67165 7.36902 9.77222C7.47388 9.87364 7.60454 9.75417 7.60454 9.75417C7.53233 9.35877 7.56414 8.70379 7.41973 8.04796C7.12663 6.71222 6.84727 5.77617 7.24954 4.9553C7.83576 3.75966 8.83541 4.14818 8.83541 4.14818C8.83541 4.14818 8.61365 3.57658 9.61416 3.26026C11.0204 2.81588 12.7111 2.98091 13.8655 3.45108C15.4737 4.10778 15.8614 3.86195 15.8614 3.86195C15.8614 3.86195 16.5258 4.71118 16.2215 7.34742C16.0874 8.51297 16.0041 9.12841 16.0041 9.541C16.0041 9.60031 16.0677 9.60031 16.1313 9.60031C16.2593 9.48255 16.3909 9.38886 16.5791 9.42324C16.9659 9.49458 17.0493 10.4212 16.4734 11.7861C16.1536 12.4351 16.0238 12.8709 15.7092 12.8331C15.3955 12.7944 15.3525 12.6362 15.3525 12.6947C15.2279 13.3256 15.1084 13.6084 14.9803 13.9617C14.3408 15.4942 12.9664 16.5953 12.3578 16.732C11.9229 16.8308 11.6685 16.8652 11.2069 16.732C10.3852 16.4956 8.93168 15.1186 8.52082 13.9032C8.39102 13.5207 8.19247 12.7445 8.19247 12.7445C8.19247 12.7445 8.10308 12.8907 7.88131 12.9603C7.65869 13.0291 7.17304 12.6887 6.87048 11.9452Z"
        fill={color}
      />
      <path
        d="M12.0001 22C15.1058 22 17.8808 20.5842 19.715 18.3628L17.0001 17C17.0001 17 15 19 12.0001 19C9.00015 19 7.00012 17 7.00012 17L4.28516 18.3628C6.11932 20.5842 8.89436 22 12.0001 22Z"
        fill={color}
      />
    </svg>
  );
}

export default UserIcon;
