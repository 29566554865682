import { SvgProps } from "../types";

function PenOutlineIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5562 3.70699C17.5893 3.67393 17.6241 3.64367 17.6603 3.61621C18.1987 3.9206 18.7669 4.30792 19.2295 4.77048C19.7271 5.26811 20.1377 5.88801 20.4516 6.46156C20.4307 6.487 20.4084 6.51166 20.3846 6.53542L17.5165 9.40353C17.1794 8.85354 16.7562 8.27027 16.2428 7.75687C15.7646 7.27862 15.2257 6.87868 14.7095 6.55364L17.5562 3.70699ZM13.2511 8.01211L4.03278 17.2304L3.41406 20.6776L6.86121 20.0588L16.0532 10.8668C15.739 10.292 15.3276 9.67006 14.8286 9.17109C14.3637 8.70613 13.792 8.3172 13.2511 8.01211Z"
        fill={color}
      />
    </svg>
  );
}

export default PenOutlineIcon;
