import React, { FC } from 'react';
import { SvgProps } from '../types';

const ArrowRightIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      transform="rotate(180 12 12)"
      d="M10.5928 7.53948C10.6942 7.43812 10.7746 7.3178 10.8294 7.18537C10.8843 7.05294 10.9125 6.911 10.9125 6.76766C10.9125 6.62432 10.8843 6.48239 10.8294 6.34996C10.7746 6.21753 10.6942 6.0972 10.5928 5.99584C10.4915 5.89449 10.3711 5.81409 10.2387 5.75923C10.1063 5.70438 9.96435 5.67615 9.82101 5.67615C9.53152 5.67615 9.25389 5.79115 9.04919 5.99584L3.81501 11.2311C3.6105 11.4357 3.49561 11.7131 3.49561 12.0024C3.49561 12.2917 3.6105 12.5691 3.81501 12.7737L9.04919 18.0078C9.25389 18.2125 9.53152 18.3275 9.82101 18.3275C10.1105 18.3275 10.3881 18.2125 10.5928 18.0078C10.7975 17.8031 10.9125 17.5255 10.9125 17.236C10.9125 16.9465 10.7975 16.6689 10.5928 16.4642L9.05137 14.9238C8.47116 14.3443 7.82746 13.8319 7.13246 13.3966L6.31755 12.8838L6.36883 12.8337L7.41501 12.9831C7.92596 13.0559 8.44144 13.0924 8.95755 13.0922H19.6365C19.9258 13.0922 20.2033 12.9773 20.4079 12.7727C20.6124 12.5681 20.7274 12.2906 20.7274 12.0013C20.7274 11.712 20.6124 11.4345 20.4079 11.2299C20.2033 11.0253 19.9258 10.9104 19.6365 10.9104H8.95646C8.44032 10.9106 7.92485 10.9474 7.41392 11.0206L6.36664 11.17L6.31646 11.1188L7.13464 10.606C7.82846 10.1708 8.47105 9.65887 9.05028 9.07984L10.5928 7.53948Z"
      fill={color}
    />
  </svg>
);

export default ArrowRightIcon;
