import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { AuthData } from '../../types/models/ProjectAuthData';
import * as actions from './actions';

type State = {
  data: Record<string, AuthData>;
  loading: boolean;
}

const initialState: State = {
  data: {},
  loading: false,
};

export const projectsAuthDataReducer: Reducer<State> = createReducer<State>(initialState)
  .handleAction(
    actions.loadProjectAuthData.request,
    (state: State): State => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadProjectAuthData.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.loadProjectAuthData.success>,
    ): State => ({
      ...state,
      data: {
        ...state.data,
        [payload.projectSlug]: payload.data,
      },
      loading: false,
    }),
  )
  .handleAction(
    actions.loadProjectAuthData.failure,
    (state: State): State => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.updateProjectAuthData.request,
    (state: State): State => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.updateProjectAuthData.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.updateProjectAuthData.success>,
    ): State => ({
      ...state,
      data: {
        ...state.data,
        [payload.projectSlug]: payload.data,
      },
      loading: false,
    }),
  )
  .handleAction(
    actions.updateProjectAuthData.failure,
    (state: State): State => ({
      ...state,
      loading: false,
    }),
  );
