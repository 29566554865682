import { SvgProps } from "../types";

function MapIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 19V3.5L3 3L9 4.5L14.4791 3.13022C14.8203 3.04492 15.178 3.05111 15.5161 3.14816L21.9208 4.98691L22 20.5L21 21L15 19.5L9.51957 20.8701C9.17919 20.9552 8.82241 20.9493 8.48505 20.8529L2 19ZM16 17.6884L19.9907 18.6861L19.9285 6.49572L16 5.36788V17.6884ZM14 17.6884V5.31155L10 6.31155V18.6884L14 17.6884ZM4 5.31155L8 6.31155V18.6343L4 17.4914V5.31155Z"
        fill={color}
      />
    </svg>
  );
}

export default MapIcon;
