import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useForceUpdate } from '../../hooks/useForceUpdate';

export type HiddenAreaProps = {
  className?: string,
  show: boolean,
}

export default function HiddenArea({
  children,
  show,
  className = '',
}: React.PropsWithChildren<HiddenAreaProps>) {
  const [overflowVisible, setOverflowVisible] = useState<boolean>(show);
  const areaRef = useRef<HTMLDivElement>(null);
  const rerender = useForceUpdate();

  const handleInnerClick = useCallback(
    () => {
      setTimeout(() => {
        rerender();
      });
    },
    [rerender],
  );

  const handleTransitionEnd = useCallback(() => {
    setOverflowVisible(show);
    rerender();
  }, [rerender, show]);

  useEffect(() => {
    if (!show) {
      setOverflowVisible(false);
    }
  }, [show]);

  useEffect(() => {
    rerender();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <div
      ref={areaRef}
      onClick={handleInnerClick}
      className={`hidden-area ${className}`}
      onTransitionEnd={handleTransitionEnd}
      style={{
        maxHeight: show ? areaRef.current?.scrollHeight + 'px' : 0,
        transition: 'max-height 0.2s ease-out',
        overflow: overflowVisible ? 'visible' : 'hidden',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
}
