import { createAction, createAsyncAction } from "typesafe-actions";
import { Tag, TagInfo } from "../../types/models/Tag";
import { TagsResponse } from "../../types/requests/Tags";
import { ErrorAction } from "../reducers";

export const loadTags = createAsyncAction(
  'tags/load/requested',
  'tags/load/succeeded',
  'tags/load/failed',
)<undefined, TagsResponse, ErrorAction>();

export const createTag = createAsyncAction(
  'tags/create/requested',
  'tags/create/succeeded',
  'tags/create/failed',
)<string, Tag, ErrorAction>();

export const deleteTag = createAsyncAction(
  'tags/delete/requested',
  'tags/delete/succeeded',
  'tags/delete/failed',
)<Tag['id'], Tag['id'], ErrorAction>();

export const loadTagInfo = createAsyncAction(
  'tags/loadTagInfo/requested',
  'tags/loadTagInfo/succeeded',
  'tags/loadTagInfo/failed',
)<
  Tag['id'],
  TagInfo,
  ErrorAction
>();

export const openDeleteTagModal = createAction('tags/openDeleteTagModal')<Tag['id']>();
export const closeDeleteTagModal = createAction('tags/closeDeleteTagModal')();
