import { v4 as uuidv4 } from "uuid";

import { ResponseText } from "~/api-types/backend-py3/services/supportai/docs/yaml/definitions/scenario";

import { addAudioPrefix, AUDIO_FILE_TEXT_PREFIX, removeAudioPrefix } from "../../pages/graphEditor/components/nodeEditor/forms/response/helpers";
import { TAG_PLAY } from "../../pages/graphEditor/helpers";
import { UserResponseOption } from "../../pages/graphEditor/types";
import { generateId } from "../../services/helpers/generateId";
import {
  getFilenameWithoutExtension,
  objCamelToSnake, objCamelToSnakeDeep, objSnakeToCamel, objSnakeToCamelDeep, parseJsonSafely,
} from "../../services/helpers/utilities";

import {
  ChangeStateActionBackend,
  ConditionActionBackend,
  ConditionActionCaseBackend,
  CustomActionBackend,
  FormPredicateItemBackend,
  NotificationActionBackend,
  PredicateBackend,
  PredicateItemBackend,
  ResponseActionBackend,
  TextWithLang,
  ScenarioFeatureBackend,
  ScenarioGraphActionBackend,
  SendEmailParamKeys,
  OperatorActionBackend,
  GptSettingsBackend,
  FormPredicateItemBoolBackend,
} from "../backendModels/ScenarioGraphActionBackend";
import { ScenarioGraphBackend } from "../backendModels/ScenarioGraphBackend";
import { ScenarioGraphNodeBackend } from "../backendModels/ScenarioGraphNodeBackend";
import { Lang } from "../models/Languages";
import { ScenarioGraph } from "../models/ScenarioGraph";

import {
  ChangeStateAction,
  ConditionAction,
  CustomAction,
  FormPredicateItem,
  FormPredicateItemBool,
  getEmptyCustomAction,
  LogbrokerAction,
  NotificationAction,
  NotificationActionDefaultArgs,
  NotificationActionDefaultArgsItem,
  NotificationAttachments,
  OperatorAction,
  Predicate,
  PredicateItem,
  PredicateItemType,
  ResponseAction,
  ScenarioFeature,
  ScenarioGraphAction,
  ScenarioGraphActionType,
  TextType,
  TranslatedTexts,
} from "../models/ScenarioGraphAction";

/* eslint-disable camelcase */
export function parseScenarioGraphAction(node: ScenarioGraphNodeBackend, mainLang?: Lang): ScenarioGraphAction {
  const type = node.action?.type;

  switch (type) {
    case ScenarioGraphActionType.changeState:
      return parseChangeStateAction(node.action as ChangeStateActionBackend);
    case ScenarioGraphActionType.condition:
      return parseConditionAction(node.action as ConditionActionBackend);
    case ScenarioGraphActionType.response:
      return parseResponseAction(node, mainLang);
    case ScenarioGraphActionType.custom:
      return parseCustomAction(node);
    case ScenarioGraphActionType.operator:
      return parseOperatorAction(node.action as OperatorActionBackend);
    case ScenarioGraphActionType.integrationAction:
    case ScenarioGraphActionType.close:
      return objSnakeToCamel(node.action);
    default:
      return objSnakeToCamel(node.action);
  }
}

export function parseChangeStateAction(value: ChangeStateActionBackend): ChangeStateAction {
  return {
    ...objSnakeToCamel(value),
    // @ts-ignore #ASK. Непонятно, почему в схеме указано, что в features лежат полные признаки, хотя
    // по факту приходят объекты { key, value }
    ...(Array.isArray(value.features) && { features: value.features.map(parseScenarioFeature) }),
  };
}

export function parseScenarioFeature(value: ScenarioFeatureBackend): ScenarioFeature {
  return {
    id: generateId(),
    ...value,
  };
}

export function parseConditionAction(value: ConditionActionBackend): ConditionAction {
  return {
    ...objSnakeToCamel(value),
    type: value.type as ScenarioGraphActionType.condition,
    cases: value.predicate ?
      (
        [{
          id: generateId(),
          next: '',
          title: 'true',
          ...(typeof value.predicate === 'object' && { predicate: parsePredicate(value.predicate) }),
        }, {
          id: generateId(),
          next: '',
          title: 'false',
        }]
      ) :
      (value.cases || []).map(
        item => ({
          id: generateId(),
          next: item.next,
          title: item.title || (item.predicate ? 'untitled' : 'else'),
          ...(typeof item.predicate === 'object' && { predicate: parsePredicate(item.predicate) }),
        }),
      ),
  };
}

export function parsePredicate(value: PredicateBackend | string): Predicate {
  if (typeof value === "string") {
    return {
      // predicates: value.split('or').map((predicate) => ({
      //   id: generateId(),
      //   isActive: true,
      //   codePredicate: predicate.trim(),
      //   type: PredicateItemType.code,
      // })),
      predicates: [{
        id: generateId(),
        isActive: true,
        codePredicate: value.trim(),
        type: PredicateItemType.code,
      }],
    };
  }

  return {
    predicates: Array.isArray(value.predicates) ? value.predicates.map(parsePredicateItem) : [],
  };
}

export function parsePredicateItem(value: PredicateItemBackend): PredicateItem {
  return {
    ...objSnakeToCamel(value),
    id: generateId(),
    ...(Array.isArray(value.form_predicate) && {
      formPredicate: value.form_predicate.map(parseFormPredicateItem),
    }),
  };
}

export function parseFormPredicateItem(
  value: FormPredicateItemBackend | FormPredicateItemBoolBackend,
): FormPredicateItem | FormPredicateItemBool {
  return {
    ...objSnakeToCamel(value),
    id: generateId(),
  };
}

export function parseResponseAction(
  value: ScenarioGraphNodeBackend,
  mainLanguage?: Lang,
): ResponseAction {
  const { tags } = value;
  const isAudioFileSelected = tags.some(tag => tag.slug === TAG_PLAY.slug);

  const {
    texts = [''],
    clarify_texts = [''],
    buttons,
    texts_type,
    ...rest
  } = (value.action as ResponseActionBackend);

  const result = {
    ...objSnakeToCamelDeep<ResponseAction>(rest),
    type: ScenarioGraphActionType.response,
    deferSeconds: rest.defer_seconds || rest.defer_time_sec,
  } as ResponseAction;

  delete result.deferTimeSec;

  const newTexts = getTextsAsTranslated(texts, mainLanguage);
  const newClarifyTexts = getTextsAsTranslated(clarify_texts, mainLanguage);

  if (isAudioFileSelected) {
    Object.keys(newTexts).forEach(lang => {
      newTexts[lang as Lang] = newTexts[lang as Lang].map(addAudioPrefix);
    });

    Object.keys(newClarifyTexts).forEach(lang => {
      newClarifyTexts[lang as Lang] = newClarifyTexts[lang as Lang].map(addAudioPrefix);
    });
  }

  return {
    ...result,
    texts: newTexts,
    textsType: texts_type as TextType || TextType.unset,
    clarifyTexts: newClarifyTexts,
    ...(buttons && { userAnswerOptions: parseUserAnswerOptions(buttons, mainLanguage) }),
  };
}

function getTextsAsTranslated(
  texts: Array<string | ResponseText>,
  mainLang = Lang.ru,
): TranslatedTexts {
  if (typeof texts[0] === "string") return { [mainLang]: texts } as TranslatedTexts;

  return (texts as TextWithLang[]).reduce<TranslatedTexts>((
    res,
    { lang, text }: TextWithLang,
  ) => {
    res[lang] = res[lang] || [];

    res[lang]?.push(text);

    return res;
  }, {} as TranslatedTexts);
}

function parseUserAnswerOptions(
  buttons: Array<string | ResponseText>,
  mainLang: Lang = Lang.ru,
): Record<Lang, UserResponseOption[]> | undefined {
  if (buttons.length === 0) {
    return undefined;
  }

  if (typeof buttons[0] === "string") {
    return {
      [mainLang]: buttons.map(b => ({ id: generateId(), text: b })),
    } as Record<Lang, UserResponseOption[]>;
  }

  return (buttons as TextWithLang[]).reduce<Record<Lang, UserResponseOption[]>>(
    (res, b: TextWithLang) => {
      res[b.lang] = res[b.lang] || [];

      res[b.lang].push({ id: generateId(), text: b.text });

      return res as Record<Lang, UserResponseOption[]>;
    },
    {} as Record<Lang, UserResponseOption[]>,
  );
}

type EmailParams = { call_params: Record<string, unknown>[] };

export function parseEmailParameters(parameters: EmailParams): NotificationAction['sendMailParameters'] {
  const getObj = (key: SendEmailParamKeys) => (
    parameters?.call_params?.find(item => !!item[key])?.[key] || ''
  );

  const emails = (getObj('to_emails') || []) as string[];
  const pdid = (getObj('to_email_pd_ids') || []) as string[];
  const personalProvider = (getObj('personal_provider') || '') as string;
  const title = (getObj('title') || '') as string;
  const senderName = (getObj('from_name') || '') as string;
  const senderEmail = (getObj('from_email') || '') as string;
  const message = (getObj('message') || '') as string;
  const attachments = getObj('attachments');

  const defaultArgs = (getObj('default_args') || undefined) as Record<string, string>;
  let correctedDefaultArgs: NotificationActionDefaultArgs | undefined;

  if (defaultArgs) {
    correctedDefaultArgs = {};

    Object.keys(defaultArgs).forEach(key => {
      const value = defaultArgs[key];
      const id = uuidv4();
      (correctedDefaultArgs as NotificationActionDefaultArgs)[id] = { key, value };
    });
  }

  return {
    email: (emails as string[]).join(', '),
    pdid: (pdid as string[]).join(', '),
    personalProvider,
    title,
    defaultArgs: correctedDefaultArgs,
    senderName,
    senderEmail,
    message,
    ...(attachments && { attachments: objSnakeToCamelDeep<NotificationAttachments>(attachments) }) as Object,
  };
}

export function parseOperatorAction(value: OperatorActionBackend): OperatorAction {
  return {
    ...objSnakeToCamelDeep(value),
    isAutomated: value.is_automated ?? true,
  };
}

export function parseCustomAction(node: ScenarioGraphNodeBackend)
: CustomAction | NotificationAction | LogbrokerAction {
  if (!node.action) {
    return getEmptyCustomAction();
  }

  const {
    action_type,
    parameters,
  } = node.action as CustomActionBackend | NotificationActionBackend;

  const parsed = parseJsonSafely(parameters);

  if (['send_mail', 'custom'].includes(action_type) && parsed.call_params) {
    return {
      ...objSnakeToCamel(node.action),
      type: ScenarioGraphActionType.custom,
      actionType: 'send_mail',
      sendMailParameters: parseEmailParameters(parsed as EmailParams),
    } as NotificationAction;
  }

  if (Array.isArray(node.actions)) {
    return {
      type: ScenarioGraphActionType.custom,
      nestedActions: [
        {
          id: generateId(),
          actionType: action_type,
          parameters: JSON.stringify(parameters),
        },
        ...(node.actions as CustomActionBackend[]).map(item => ({
          id: generateId(),
          actionType: item.action_type,
          parameters: JSON.stringify(item.parameters),
        })),
      ],
    } as CustomAction;
  }

  if (
    [ScenarioGraphActionType.logbrokerWriteAction]
      .includes(action_type as ScenarioGraphActionType)
  ) {
    const topic = (parsed?.call_params || [])[0]?.topic;
    const message = (parsed?.call_params || [])[0]?.message;
    return {
      type: ScenarioGraphActionType.logbrokerWriteAction,
      topic,
      message: JSON.stringify(message, null, "\t"),
      counter: node.counter || 1,
    };
  }

  return {
    type: ScenarioGraphActionType.custom,
    nestedActions: [{
      id: generateId(),
      actionType: action_type,
      parameters: JSON.stringify(parameters, null, "\t"),
    }],
  } as CustomAction;
}

export function parseScenarioGraphActionToBackend(value: ScenarioGraphAction)
: ScenarioGraphActionBackend | ScenarioGraphActionBackend[] {
  switch (value.type) {
    case ScenarioGraphActionType.changeState:
      return parseChangeStateActionToBackend(value as ChangeStateAction);
    case ScenarioGraphActionType.condition:
      return parseConditionActionToBackend(value as ConditionAction);
    case ScenarioGraphActionType.response:
      return parseResponseActionToBackend(value as ResponseAction);
    case ScenarioGraphActionType.custom:
    case ScenarioGraphActionType.logbrokerWriteAction:
      return parseCustomActionToBackend(value as CustomAction);
    case ScenarioGraphActionType.operator:
      return parseOperatorActionToBackend(value as OperatorAction);
    case ScenarioGraphActionType.selectScenario:
    case ScenarioGraphActionType.integrationAction:
    case ScenarioGraphActionType.close:
    default:
      return objCamelToSnake(value);
  }
}

export function parseChangeStateActionToBackend(value: ChangeStateAction)
: ChangeStateActionBackend {
  // @ts-ignore #ASK. Непонятно, почему в схеме указано, что в features лежат полные признаки, хотя
  // по факту приходят объекты { key, value }
  return {
    ...objCamelToSnake(value),
    ...(value.features && { features: value.features.map(parseScenarioFeatureToBackend) }),
  };
}

export function parseResponseActionToBackend(
  action: ResponseAction,
): ResponseActionBackend {
  let textsWithLang: TextWithLang[] = [];
  let clarifyingTextsWithLang: TextWithLang[] = [];
  let identifiedTextType: TextType | undefined = undefined;

  const {
    texts,
    clarifyTexts,
    userAnswerOptions,
    textsType,
    ...rest
  } = action;

  textsWithLang = parseTranslatedTextsToArray(texts as TranslatedTexts)
    .map((value: TextWithLang) => ({
      ...value,
      text: parseResponseTextToBackend(value.text),
    }));

  clarifyingTextsWithLang = parseTranslatedTextsToArray(clarifyTexts as TranslatedTexts)
    .map((value: TextWithLang) => ({
      ...value,
      text: parseResponseTextToBackend(value.text),
    }));

  if (textsType !== TextType.unset) {
    identifiedTextType = textsType;
  }

  let buttons: TextWithLang[] = [];
  if (userAnswerOptions) {
    buttons = Object.keys(userAnswerOptions).flatMap(lang => (
      userAnswerOptions[lang as Lang]
        .map(option => ({ text: option.text, lang: lang as Lang }))
    ));
  }

  if (!rest.gptSettings?.isEnabled) {
    delete rest.gptSettings;
  }

  const gptSettings: GptSettingsBackend = {
    ...objCamelToSnakeDeep(rest.gptSettings),
    timeout: Number(rest.gptSettings?.timeout),
  };

  return {
    ...objCamelToSnakeDeep(rest),
    texts: textsWithLang,
    clarify_texts: clarifyingTextsWithLang,
    buttons,
    texts_type: identifiedTextType,
    ...(rest.gptSettings?.isEnabled && { gpt_settings: gptSettings }),
  };
}

export function parseResponseTextToBackend(value: string) {
  if (value?.startsWith(AUDIO_FILE_TEXT_PREFIX)) {
    return getFilenameWithoutExtension(removeAudioPrefix(value));
  }

  return value;
}

export function parseTranslatedTextsToArray(value: TranslatedTexts): TextWithLang[] {
  return Object.keys(value).flatMap(lang => (
    value[lang as Lang].map(text => ({ lang: lang as Lang, text }))
  ));
}

export function parseScenarioFeatureToBackend(value: ScenarioFeature): ScenarioFeatureBackend {
  const { id, ...rest } = value;
  return {
    ...objCamelToSnake(rest),
  };
}

export function parseConditionActionToBackend(value: ConditionAction): ConditionActionBackend {
  return {
    type: value.type,
    cases: value.cases.map(
      ({ next, title, predicate }) => ({
        next,
        title,
        ...(typeof predicate === 'object' && { predicate: parsePredicateToBackend(predicate) }),
      } as ConditionActionCaseBackend),
    ),
  };
}

export function parsePredicateToBackend(value: ScenarioGraph['predicate']): ScenarioGraphBackend['predicate'] {
  if (!value) return value;

  const predicates = (value as Predicate)?.predicates;
  // to skip "predicate: { predicates: []}"
  if (predicates && Array.isArray(predicates) && predicates.length === 0) {
    return undefined;
  }

  if (typeof value === "string") return value;

  return {
    predicates: value.predicates.map(parsePredicateItemToBackend),
  };
}

export function parsePredicateItemToBackend(value: PredicateItem): PredicateItemBackend {
  const copy = { ...value };
  if (value.type === PredicateItemType.code) {
    delete copy.formPredicate;
  } else if (value.type === PredicateItemType.form) {
    delete copy.codePredicate;
  }
  return objCamelToSnake(copy);
}

/*
  { to_emails: string[] }
  { message: string }
  { default_args: Record<string, string> }
  { attachments: Attachment[] } // пока игнорируем
  { from_email: string }
  { from_name: string };
*/

export function parseEmailParametersToBackend(parameters: NotificationAction['sendMailParameters']): NotificationActionBackend['parameters'] {
  /* eslint-disable camelcase */
  const call_params: Record<string, unknown>[] = [];

  type DefaultArgs = NotificationActionDefaultArgs;
  type DefaultArgsItem = NotificationActionDefaultArgsItem;

  const parseDefaultArgs = (defaultArgs: DefaultArgs) => {
    if (!defaultArgs) return undefined;

    const result: Record<string, string> = {};

    Object.values(defaultArgs as DefaultArgs).forEach(({ key, value }: DefaultArgsItem) => {
      result[key] = value;
    });

    return result;
  };

  let record: Record<string, unknown> = {};

  Object.keys(parameters).forEach(key => {
    type K = keyof NotificationAction['sendMailParameters'];
    const value = (parameters as NotificationAction['sendMailParameters'])[key as K];
    if (!value) return;

    switch (key) {
      case 'email':
        record.to_emails = (value as string).split(',').map(item => item.trim());
        break;

      case 'pdid':
        record.to_email_pd_ids = (value as string).split(',').map(item => item.trim());
        break;

      case 'senderName':
        record.from_name = value;
        break;

      case 'senderEmail':
        record.from_email = value;
        break;

      case 'defaultArgs':
        record.default_args = parseDefaultArgs(value as DefaultArgs);
        break;

      case 'attachments':
        record.attachments = objCamelToSnakeDeep(value);
        break;

      case 'personalProvider':
        record.personal_provider = value;
        break;

      default:
        record[key] = value;
        break;
    }
  });

  call_params.push(record);

  return {
    version: "1",
    call_params,
  };
}

export function parseOperatorActionToBackend(action: OperatorAction): OperatorActionBackend {
  return {
    ...objCamelToSnakeDeep(action),
    is_automated: action.isAutomated ?? true,
  };
}

export function parseCustomActionToBackend(action: CustomAction | NotificationAction | LogbrokerAction)
: NotificationActionBackend | CustomActionBackend[] | CustomActionBackend {
  if ((action as NotificationAction).actionType === 'send_mail') {
    const emailData = parseEmailParametersToBackend((action as NotificationAction).sendMailParameters);

    return {
      type: action.type,
      action_type: 'send_mail',
      parameters: emailData,
    } as NotificationActionBackend;
  }

  if ((action as LogbrokerAction).type === ScenarioGraphActionType.logbrokerWriteAction) {
    return {
      type: ScenarioGraphActionType.custom,
      action_type: ScenarioGraphActionType.logbrokerWriteAction,
      parameters: {
        call_params: [
          {
            topic: (action as LogbrokerAction).topic,
            message: parseJsonSafely((action as LogbrokerAction).message),
          },
        ],
      },
    };
  }

  return (action as CustomAction).nestedActions.map(
    nestedAction => ({
      type: action.type,
      action_type: nestedAction.actionType,
      parameters: parseJsonSafely(nestedAction.parameters),
    }),
  ) as CustomActionBackend[];
}
