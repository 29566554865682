import { FC, memo, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { block } from 'bem-cn';

import { closeLastReleaseNote, openReleaseNotesModal, loadTheLastReleaseNote } from '../../../redux/releaseNotes/actions';
import { getLastReleaseNote } from '../../../redux/releaseNotes/selectors';

import Item from './item';

const b = block('release-notes-last');

const Last: FC<{}> = () => {
  const dispatch = useDispatch();

  const note = useSelector(getLastReleaseNote, shallowEqual);

  const handleClose = useCallback(
    () => {
      dispatch(closeLastReleaseNote(String(note?.id ?? '')));
    },
    [dispatch, note?.id],
  );

  const handleOpenModal = useCallback(
    () => {
      handleClose();
      dispatch(openReleaseNotesModal());
    },
    [dispatch, handleClose],
  );

  useEffect(
    () => {
      dispatch(loadTheLastReleaseNote.request());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!note) return null;

  return (
    <div className={b()}>
      <Item
        releaseNote={note}
        last
        onClose={handleClose}
        onExpand={handleOpenModal}
      />
    </div>
  );
};

export default memo(Last);
