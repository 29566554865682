import { Editable } from "./Editable";
import { MessageAuthor } from "./Message";
import { Sampling, ResolutionStatus as SamplingResolutionStatus, NewSampling } from "./Sampling";
import { ScenarioGraphMeta } from "./ScenarioGraphs";
import { MediaFileMetadata } from "./MediaFiles";
import { TextType } from "./ScenarioGraphAction";
import { ErrorType } from "../backendModels/DialogBackend";
import { MultiOperator } from "../../components-new/select/configurable";
import { ResolutionType } from "./Resolution";

export type Action = {
  created: string,
  features: DialogFeature[],
}

export type Attachment = MediaFileMetadata & { type: 'link' | 'file' }

export type DialogFeature = {
  key: string,
  value: string | number | boolean | Array<string | number | boolean>,
}

export type TopicProbability = {
  topicName: string,
  probability: number,
}

export type Suggest = {
  texts: string[], // min length = 1,
}

export type Reply = {
  text: string, // TODO deprecated (07 oct 2022)
  texts: string[],
}

export type Forward = {
  line: string;
}

export type DialogTag = {
  add: string[], // min length = 1
}

export enum ResolutionStatus {
  good = 'good',
  bad = 'bad',
  critical = 'critical',
}

export enum ResolutionError {
  Integration = SamplingResolutionStatus.integrationError,
  Model = SamplingResolutionStatus.modelError,
  Rule = SamplingResolutionStatus.predicateError,
  Other = SamplingResolutionStatus.otherError,
}

export type DialogMessage = RequestMessage | ResponseMessage;

export type Dialog = {
  id: string,
  externalId: string,
  createdAt: string,
  messages: DialogMessage[];
  sessionId: string;
  replied?: boolean;
  scenarioSlug?: string;
  viewed?: boolean;
  iterations?: number;
  scenariosList?: string[];
  scenarioTitle?: string;
  scenarioNode?: {
    id?: string;
    title?: string;
    type?: string;
  };
  resolution?: {
    value?: ResolutionType['id'];
    messageId?: string;
  };
  tags?: {
    name: string;
    count: number;
  }[];
  favoriteFeatures?: {
    key: string,
    value: string | number | boolean | Array<string | number | boolean>,
  }[];
  errorsTypes?: ErrorType[];
}

export type ScenarioData = {
  scenarioId: string;
  scenarioSlug: string;
  scenarioTitle: string;
};

export type RequestMessage = {
  id?: string,
  recordId?: string,
  author?: MessageAuthor,
  text: string,
  texts?: string[],
  metaId?: string;
  chatId?: string,
  features?: DialogFeature[],
  createdAt?: string,
  attachments?: Attachment[];
  scenarioData?: ScenarioData;
}

export type ScenariosWayPoint = {
  scenarioSlug?: string;
  scenarioId?: string;
  scenarioTitle?: string;
  nodeId?: string;
  nodeTitle?: string;
  nodeType?: string;
  metric?: ResponseMetric;
  withError?: boolean; //это чисто фронтовый флаг,
                      //нужен для того, что бы в Дебаге в ИД подсвечивать ноды, вызвавшие ошибку
}

export type ResponseMessage = {
  id: string,
  chatId?: string,
  debugId?: string,
  recordId?: string,
  saiRequestId?: string,
  author: MessageAuthor,
  metaId?: string;
  text?: string, // fallback
  texts?: string[],
  textType?: TextType;
  macrosTexts?: string[];
  meta?: {
    time?: number,
    closed?: boolean,
    operator?: boolean,
    line?: string,
    tags?: string[],
  },
  resolution?: {
    status: { value?: SamplingResolutionStatus } & Editable,
    comment: { value?: string } & Editable,
  },
  requestFeatures?: DialogFeature[],
  responseFeatures?: DialogFeature[],
  converged?: boolean,
  probabilities?: TopicProbability[],
  createdAt?: string,
  iterationNumber?: number,
  explanation?: ResponseExplanation,
  isSent?: boolean,
  attachments?: Attachment[];
  scenarioData?: ScenarioData;
  scenarioPath?: ScenariosWayPoint[];
  metric?: ResponseMetric;
  buttons?: ResponseButtonsBlock;
  error?: {
    type?: ErrorType;
    message?: string;
    predicate?: {
      lineId?: string;
      predicate?: string;
    }
    nodeId?: string;
    scenarioSlug?: string;
  },
  version?: string;
}

export type ResponseExplanation = {
  topicTitle?: string,
  topicExplanation?: string,
  policyExplanation?: string,
  policyTitles?: string[]
}

export type ResponseButtonsBlock = Button[];

export type Button = {
  id: string,
  text: string,
}

export type DialogsHistorySearchFilter = {
  scenarios: ScenarioGraphMeta[], // TODO поисследовать, почему тут храним полностью сценарии,
                                  // а не слаги/айдишники
  dateFrom: number, // time in seconds
  dateTo: number | null, // time in seconds
  resolution: ResolutionType['id'] | null,
  resolutionOld: SamplingResolutionStatus | null, // TODO удалить после перехода на новую ИД
  mode: boolean | null,
  status: DialogStatus | null,
  chatId: string | null,
  chatIds: string[] | null,
  searchText: string | null,
  searchTextLocation: SearchTextLocation[],
  chatUser: string | null,
  samplingDeprecated: Sampling | null,
  newSampling: NewSampling | null,
  iterations: string | null,
  version: string | null,
  tags: string[] | null,
  minUserScore: number | null,
  maxUserScore: number | null,
  tagsOperator?: MultiOperator,
}

export type ResponseMetric = {
  totalTime?: number;
}

export enum TagsOperator {
  or = "or",
  and = "and"
}

export enum SearchTextLocation {
  request = "request",
  response = "response",
  both = "request_response",
}

export enum DialogStatus {
  default = "default",
  withErrors = "with_errors",
  notAnswered = "not_answered",
}

export const resolutionLabels = {
  [SamplingResolutionStatus.ok]: 'PAGE_QUALITY_CONTROL.SAMPLING_METRICS.OK',
  [SamplingResolutionStatus.integrationError]: 'PAGE_QUALITY_CONTROL.SAMPLING_METRICS.INTEGRATION_ERROR',
  [SamplingResolutionStatus.modelError]: 'PAGE_QUALITY_CONTROL.SAMPLING_METRICS.MODEL_ERROR',
  [SamplingResolutionStatus.predicateError]: 'PAGE_QUALITY_CONTROL.SAMPLING_METRICS.RULE_ERROR',
  [SamplingResolutionStatus.otherError]: 'PAGE_QUALITY_CONTROL.SAMPLING_METRICS.OTHER',
};

export function isRequestMessage(message: RequestMessage | ResponseMessage): boolean {
  return message.author !== MessageAuthor.Ai;
}

export function isAiMessage(message: RequestMessage | ResponseMessage): boolean {
  return message.author === MessageAuthor.Ai;
}

type Message = RequestMessage | ResponseMessage | Array<RequestMessage | ResponseMessage>;

export type DialogResolution = {
  badMessageId?: string;
  status: SamplingResolutionStatus;
}

export function clearResolution(value: Message): Message {
  if (Array.isArray(value)) {
    return value.map(message => {
      if (!isRequestMessage(message)) {
        const { resolution, ...rest } = message as ResponseMessage;
        return rest;
      }
      return message;
    });
  }
  if (!isRequestMessage(value)) {
    const { resolution, ...rest } = value as ResponseMessage;
    return rest;
  }
  return value;
}

export function clearMeta(value: Message): Message {
  if (Array.isArray(value)) {
    return value.map(message => {
      if (!isRequestMessage(message)) {
        const { meta, ...rest } = message as ResponseMessage;
        return rest;
      }
      return message;
    });
  }
  if (!isRequestMessage(value)) {
    const { meta, ...rest } = value as ResponseMessage;
    return rest;
  }
  return value;
}

export function clearResolutionsInDialogs(dialogs: Dialog[]): Dialog[] {
  return dialogs.map(dialog => ({
    ...dialog,
    messages: clearResolution(dialog.messages) as Array<ResponseMessage | RequestMessage>,
  }));
}
