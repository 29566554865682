import { createAction, createAsyncAction } from "typesafe-actions";
import { Feature } from "../../types/models/Feature";
import { Integration, IntegrationId, IntegrationLabel, IntegrationTest } from "../../types/models/Integrations";
import { Editable, ErrorAction } from "../reducers";
import { NEW_INTEGRATION_ID_TYPE } from "./reducer";
import { NodeInfo } from "../../types/models/NodeInfo";

export const loadIntegrations = createAsyncAction(
  'integrations/loadIntegrations/requested',
  'integrations/loadIntegrations/succeeded',
  'integrations/loadIntegrations/failed',
)<undefined, Integration[], ErrorAction>();

export const createIntegration = createAsyncAction(
  'integrations/createIntegration/requested',
  'integrations/createIntegration/succeeded',
  'integrations/createIntegration/failed',
)<Integration, Integration, ErrorAction<Integration>>();

export const updateIntegration = createAsyncAction(
  'integrations/updateIntegration/requested',
  'integrations/updateIntegration/succeeded',
  'integrations/updateIntegration/failed',
)<Integration, Integration, ErrorAction<Integration>>();

export const deleteIntegration = createAsyncAction(
  'integrations/deleteIntegration/requested',
  'integrations/deleteIntegration/succeeded',
  'integrations/deleteIntegration/failed',
)<IntegrationId, IntegrationId, ErrorAction<IntegrationId>>();

export const addIntegration = createAction('integrations/addIntegration')<Editable<Integration>>();
export const removeIntegration = createAction('integrations/removeIntegration')<IntegrationId>();

export const setActiveIntegrationId = createAction('integrations/setActiveIntegrationId')<IntegrationId | NEW_INTEGRATION_ID_TYPE>();

export const openPanel = createAction('integrations/openPanel')();
export const closePanel = createAction('integrations/closePanel')();

export const openTestingModal = createAction('integrations/openTestingModal')();
export const closeTestingModal = createAction('integrations/closeTestingModal')();

export const loadIntegrationFeatures = createAsyncAction(
  'integrations/loadIntegrationFeatures/requested',
  'integrations/loadIntegrationFeatures/succeeded',
  'integrations/loadIntegrationFeatures/failed',
)<Integration['id'], Array<Feature['slug']>, ErrorAction>();

export const testIntegration = createAsyncAction(
  'integrations/testIntegration/requested',
  'integrations/testIntegration/succeeded',
  'integrations/testIntegration/failed',
)<{ state: IntegrationTest['state'] }, IntegrationTest, ErrorAction>();

export const setIntegrationIdToTest = createAction(
  'integrations/setIntegrationToTestNew',
)<IntegrationId>();

export const loadIntegrationsLabels = createAsyncAction(
  'integrations/loadIntegrationsLabels/requested',
  'integrations/loadIntegrationsLabels/succeeded',
  'integrations/loadIntegrationsLabels/failed',
)<
  undefined,
  IntegrationLabel[],
  ErrorAction
>();

export const createIntegrationLabel = createAsyncAction(
  'integrations/createIntegrationLabel/requested',
  'integrations/createIntegrationLabel/succeeded',
  'integrations/createIntegrationLabel/failed',
)<
  IntegrationLabel['title'],
  IntegrationLabel,
  ErrorAction
>();

export const addIntegrationsToLabel = createAsyncAction(
  'integrations/addIntegrationsToLabel/requested',
  'integrations/addIntegrationsToLabel/succeeded',
  'integrations/addIntegrationsToLabel/failed',
)<{
    label: IntegrationLabel,
    integrations: Array<Integration['id']>,
  }, {
    label: IntegrationLabel,
    integrations: Array<Integration['id']>,
  },
  ErrorAction
>();

export const updateIntegrationLabel = createAsyncAction(
  'integrations/updateIntegrationLabel/requested',
  'integrations/updateIntegrationLabel/succeeded',
  'integrations/updateIntegrationLabel/failed',
)<
  { label: IntegrationLabel, integrations: Array<Integration['id']> },
  IntegrationLabel,
  ErrorAction
>();

export const deleteIntegrationLabel = createAsyncAction(
  'integrations/deleteIntegrationLabel/requested',
  'integrations/deleteIntegrationLabel/succeeded',
  'integrations/deleteIntegrationLabel/failed',
)<
  IntegrationLabel['id'],
  IntegrationLabel['id'],
  ErrorAction<IntegrationLabel['id']>
>();

export const openAddIntegrationsToLabelModal = createAction('integrations/openAddIntegrationsToLabelModal')<{
  initIntegrations: Array<Integration['id']>,
}>();
export const closeAddIntegrationsToLabelModal = createAction('integrations/closeAddIntegrationsToLabelModal')();

export const openEditLabelModal = createAction('integrations/openEditLabelModal')<IntegrationLabel['id']>();
export const closeEditLabelModal = createAction('integrations/closeEditLabelModal')();

export const openDeleteLabelModal = createAction('integrations/openDeleteLabelModal')<IntegrationLabel['id']>();
export const closeDeleteLabelModal = createAction('integrations/closeDeleteLabelModal')();

export const openUsageModal = createAction('integrations/openUsageModal')<Integration['id']>();
export const closeUsageModal = createAction('integrations/closeUsageModal')();

export const loadIntegrationUsage = createAsyncAction(
  'integrations/loadIntegrationUsage/requested',
  'integrations/loadIntegrationUsage/succeeded',
  'integrations/loadIntegrationUsage/failed',
)<
  undefined,
  NodeInfo[],
  ErrorAction
>();
