import React, { FC } from 'react';
import { SvgProps } from '../../types';

const GraphNodeSelectScenarioIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  id,
}) => (
  <svg
    width={width}
    height={height}
    id={id}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="19.5" y="19.5" width="6" height="6" rx="1" transform="rotate(180 19.5 19.5)" stroke={color} strokeWidth="2" />
    <rect x="19.5" y="10.5" width="6" height="6" rx="1" transform="rotate(180 19.5 10.5)" stroke={color} strokeWidth="2" />
    <rect x="10.5" y="19.5" width="6" height="6" rx="1" transform="rotate(180 10.5 19.5)" stroke={color} strokeWidth="2" />
    <rect x="10.5" y="10.5" width="6" height="6" rx="1" transform="rotate(180 10.5 10.5)" stroke={color} strokeWidth="2" />
  </svg>
);

export default GraphNodeSelectScenarioIcon;
