import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { unique } from "../../services/helpers/utilities";
import { Model as CommonModel } from "../../types/models/CommonModel";
import { Dialog } from "../../types/models/Dialog";
import { Metric } from "../../types/models/Metric";

import {
  getEmptyModel, getEmptyModelTopic, Model as ProjectModel, ModelDraft, ModelTopic, PlotsPoint,
} from "../../types/models/ProjectModel";
import { Task } from "../../types/models/Task";
import { Editable } from "../reducers";
import * as actions from "./actions";

type ModelsState = {
  projectModels: {
    list: ProjectModel[],
    loading: boolean,
  },
  current: {
    value: Editable<ProjectModel>,
    useOldThresholds?: boolean,
    topic: ModelTopic,
    thresholds: Array<{
      metric: Metric,
      value: number,
    }>,
    loading: boolean,
  },
  projectModelTopics: {
    list: ModelTopic[],
    loading: boolean,
  },
  plots: {
    list: PlotsPoint[],
    loading: boolean,
  },
  dialogs: {
    list: Dialog[],
    loading: boolean,
  },
  commonModels: {
    list: Editable<CommonModel>[],
    loading: boolean,
  },
  extraModeltopics: {
    list: ModelTopic[],
    loading: boolean,
  },
  comparisonModelOpened: boolean;
  comparisonTasks: {
    creatingComparison: boolean;
    loading: boolean;
    value: Task[];
    total?: number;
  },
  onlineModel:{
    value: ModelDraft,
    loading: boolean,
    updating: boolean,
  }
}

const initialState: ModelsState = {
  projectModels: {
    list: [],
    loading: false,
  },
  current: {
    value: getEmptyModel(),
    topic: getEmptyModelTopic(),
    thresholds: [],
    loading: false,
  },
  projectModelTopics: {
    list: [],
    loading: false,
  },
  plots: {
    list: [],
    loading: false,
  },
  dialogs: {
    list: [],
    loading: false,
  },
  commonModels: {
    list: [],
    loading: false,
  },
  extraModeltopics: {
    list: [],
    loading: false,
  },
  comparisonModelOpened: false,
  comparisonTasks: {
    creatingComparison: false,
    loading: false,
    value: [],
    total: 0,
  },
  onlineModel: {
    value: {
      threshold: 0,
      negativeThreshold: 0,
      translate: false,
      isEnabled: false,
    },
    loading: false,
    updating: false,
  },
};

export const modelsReducer: Reducer<ModelsState> = createReducer(initialState)
  .handleAction(
    actions.loadProjectModels.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      projectModels: {
        ...state.projectModels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadProjectModels.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadProjectModels.success>): ModelsState => {
      const uniqueModels = unique(payload, model => model.slug + model.version);
      return {
        ...state,
        projectModels: {
          ...state.projectModels,
          list: uniqueModels.sort(sortModels),
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadProjectModels.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      projectModels: {
        ...state.projectModels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.deleteProjectCurrentModel.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.deleteProjectCurrentModel.success,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        value: initialState.current.value,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.deleteProjectCurrentModel.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentModelTopics.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      projectModelTopics: {
        ...state.projectModelTopics,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentModelTopics.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadCurrentModelTopics.success>): ModelsState => ({
      ...state,
      projectModelTopics: {
        ...state.projectModelTopics,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentModelTopics.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      projectModelTopics: {
        ...state.projectModelTopics,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadOnlineModelDraft.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      onlineModel: {
        ...state.onlineModel,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadOnlineModelDraft.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadOnlineModelDraft.success>): ModelsState => ({
      ...state,
      onlineModel: {
        ...state.onlineModel,
        value: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadOnlineModelDraft.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      onlineModel: {
        ...state.onlineModel,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.updateOnlineModelDraft.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      onlineModel: {
        ...state.onlineModel,
        updating: true,
      },
    }),
  )
  .handleAction(
    actions.updateOnlineModelDraft.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.updateOnlineModelDraft.success>): ModelsState => ({
      ...state,
      onlineModel: {
        ...state.onlineModel,
        value: payload,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.updateOnlineModelDraft.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      onlineModel: {
        ...state.onlineModel,
        updating: false,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentModel.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentModel.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadCurrentModel.success>): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        value: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCurrentModel.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadModelPlots.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      plots: {
        ...state.plots,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadModelPlots.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadModelPlots.success>): ModelsState => ({
      ...state,
      plots: {
        ...state.plots,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadModelPlots.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      plots: {
        ...state.plots,
        list: [],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.setCurrentThresholds,
    (state: ModelsState, { payload }: ActionType<typeof actions.setCurrentThresholds>): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        thresholds: payload,
      },
    }),
  )
  .handleAction(
    actions.loadThresholdDialogs.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadThresholdDialogs.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadThresholdDialogs.success>): ModelsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadThresholdDialogs.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.updateThresholds.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.updateThresholds.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.updateThresholds.success>): ModelsState => {
      const modelTopics = state.projectModelTopics.list.map(topic => {
        if (topic.slug === payload.slug) {
          return {
            ...topic,
            keyMetric: payload.keyMetric,
          };
        }
        return topic;
      });

      return {
        ...state,
        current: {
          ...state.current,
          loading: false,
        },
        projectModelTopics: {
          ...state.projectModelTopics,
          list: modelTopics,
        },
      };
    },
  )
  .handleAction(
    actions.updateThresholds.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.addProjectModel.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.addProjectModel.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.addProjectModel.success>): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        value: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.addProjectModel.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.clearThresholdsVersionSetting,
    (state: ModelsState): ModelsState => {
      let newCurrent = state.current;
      delete state.current.useOldThresholds;
      return ({
        ...state,
        current: newCurrent,
      });
    },
  )
  .handleAction(
    actions.deleteProjectCurrentModel.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.deleteProjectCurrentModel.success,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        value: getEmptyModel(),
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.deleteProjectCurrentModel.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      current: {
        ...state.current,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCommonModels.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      commonModels: {
        ...state.commonModels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCommonModels.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.loadCommonModels.success>): ModelsState => ({
      ...state,
      commonModels: {
        ...state.commonModels,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCommonModels.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      commonModels: {
        ...state.commonModels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createCommonModel.request,
    (state: ModelsState, { payload }: ActionType<typeof actions.createCommonModel.request>): ModelsState => ({
      ...state,
      commonModels: {
        ...state.commonModels,
        list: [{ ...payload, pending: true }, ...state.commonModels.list],
      },
    }),
  )
  .handleAction(
    actions.createCommonModel.success,
    (
      state: ModelsState,
      { payload }: ActionType<typeof actions.createCommonModel.success>,
    ): ModelsState => updateCommonModel(state, payload.id, () => payload),
  )
  .handleAction(
    actions.createCommonModel.failure,
    (state: ModelsState, { payload: error }: ActionType<typeof actions.createCommonModel.failure>): ModelsState => {
      const { payload } = error;
      return {
        ...state,
        commonModels: {
          ...state.commonModels,
          list: state.commonModels.list.filter(model => model.id !== payload.id),
        },
      };
    },
  )
  .handleAction(
    actions.deleteCommonModel.request,
    (
      state: ModelsState,
      { payload }: ActionType<typeof actions.deleteCommonModel.request>,
    ): ModelsState => updateCommonModel(state, payload.modelId, model => ({ ...model, pending: false })),
  )
  .handleAction(
    actions.deleteCommonModel.success,
    (state: ModelsState, { payload }: ActionType<typeof actions.deleteCommonModel.success>): ModelsState => ({
      ...state,
      commonModels: {
        ...state.commonModels,
        list: state.commonModels.list.filter(model => model.id !== payload.modelId),
      },
    }),
  )
  .handleAction(
    actions.deleteCommonModel.failure,
    (state: ModelsState, { payload: error }: ActionType<typeof actions.deleteCommonModel.failure>): ModelsState => {
      const { payload } = error;
      return updateCommonModel(state, payload.modelId, model => ({ ...model, pending: false }));
    },
  )
  .handleAction(
    actions.openComparisonModal,
    (state: ModelsState): ModelsState => ({
      ...state,
      comparisonModelOpened: true,
    }),
  )
  .handleAction(
    actions.closeComparisonModal,
    (state: ModelsState): ModelsState => ({
      ...state,
      comparisonModelOpened: false,
    }),
  )
  .handleAction(
    actions.loadModelsComparisons.request,
    (
      state: ModelsState,
      { payload }: ActionType<typeof actions.loadModelsComparisons.request>,
    ): ModelsState => ({
      ...state,
      comparisonTasks: {
        ...state.comparisonTasks,
        value: payload.olderThan ? [...state.comparisonTasks.value] : [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadModelsComparisons.success,
    (
      state: ModelsState,
      { payload }: ActionType<typeof actions.loadModelsComparisons.success>,
    ): ModelsState => {
      const lastChunk = payload.pagination?.olderThan && payload.tasks.length === 0;
      const newList = payload.pagination?.olderThan ?
        [...state.comparisonTasks.value, ...payload.tasks] :
        payload.tasks;

      return {
        ...state,
        comparisonTasks: {
          ...state.comparisonTasks,
          value: newList,
          loading: false,
          total: lastChunk ? newList.length : undefined,
        },
      };
    },
  )
  .handleAction(
    actions.loadModelsComparisons.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      comparisonTasks: {
        ...state.comparisonTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.compareModels.request,
    (state: ModelsState): ModelsState => ({
      ...state,
      comparisonTasks: {
        ...state.comparisonTasks,
        creatingComparison: true,
      },
    }),
  )
  .handleAction(
    actions.compareModels.success,
    (
      state: ModelsState,
      { payload }: ActionType<typeof actions.compareModels.success>,
    ): ModelsState => ({
      ...state,
      comparisonTasks: {
        ...state.comparisonTasks,
        creatingComparison: false,
        value: [payload, ...state.comparisonTasks.value],
      },
    }),
  )
  .handleAction(
    actions.compareModels.failure,
    (state: ModelsState): ModelsState => ({
      ...state,
      comparisonTasks: {
        ...state.comparisonTasks,
        creatingComparison: false,
      },
    }),
  );

function sortModels(a: ProjectModel, b: ProjectModel): number {
  return a.title.localeCompare(b.title, undefined, { sensitivity: 'base' });
}

function updateCommonModel(
  state: ModelsState,
  modelId: string,
  callback: (value: Editable<CommonModel>) => Editable<CommonModel>,
): ModelsState {
  return {
    ...state,
    commonModels: {
      ...state.commonModels,
      list: state.commonModels.list
        .map(model => (model.id === modelId ? callback(model) : model)),
    },
  };
}
