import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { block } from 'bem-cn';

import { ModalProps } from '../../services/modal';

import HiddenArea from '../../components/hiddenArea';
import Toggle from '../../components/toggle';

import TimeInputRange from '../../components-new/timeInput/range';
import SideModal from '../../components-new/sideModal';
import Input from '../../components-new/input';
import Button from '../../components-new/button';
import Card from '../../components-new/card';

import { updateProjectVoiceConfig } from '../../redux/projectVoiceConfig/actions';
import { getVoiceConfig } from '../../redux/projectVoiceConfig/selectors';

import './styles.scss';

const b = block("calls-settings-modal");

export default function CallsSettingsModal({
  close,
}: ModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const config = useSelector(getVoiceConfig, shallowEqual);
  const initalValue = useMemo(() => config.separateCallsScheduleSettings, [config]);

  const [setupWorkTime, setSetupWorkTime] = useState<boolean>(!!initalValue.schedule && "workingDays" in initalValue.schedule);
  const [timeWork, setTimeWork] = useState<[string, string]>([
    initalValue.schedule?.workingDays?.from || '00:00',
    initalValue.schedule?.workingDays?.to || '23:59',
  ]);

  const [setupWeekendTime, setSetupWeekendTime] = useState<boolean>(!!initalValue.schedule && "weekEnd" in initalValue.schedule);
  const [timeWeekend, setTimeWeekend] = useState<[string, string]>([
    initalValue.schedule?.weekEnd?.from || '00:00',
    initalValue.schedule?.weekEnd?.to || '23:59',
  ]);

  const [callBack, setCallBack] = useState<boolean>(!!initalValue.callbackAfterInMinutes);
  const [callBackAfter, setCallBackAfter] = useState<number>(initalValue.callbackAfterInMinutes || 30);

  const handleSubmitClick = useCallback(
    () => {
      dispatch(updateProjectVoiceConfig.request({ ...config,
        separateCallsScheduleSettings: {
          ...(callBack && { callbackAfterInMinutes: callBackAfter }),
          ...((setupWeekendTime || setupWorkTime) && { schedule: {
            ...(setupWorkTime && { workingDays: {
              from: timeWork[0],
              to: timeWork[1],
            } }),
            ...(setupWeekendTime && { weekEnd: {
              from: timeWeekend[0],
              to: timeWeekend[1],
            } }),
          } }),
        },
      }));
      close();
    },
    [
      config,
      callBack,
      callBackAfter,
      setupWeekendTime,
      setupWorkTime,
      timeWeekend,
      timeWork,
      dispatch,
      close,
    ],
  );

  const handleIntervalChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | null) => {
      if (!event) {
        setCallBackAfter(0);
        return;
      }
      setCallBackAfter(+event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (timeWeekend[0] > timeWeekend[1]) {
      setTimeWeekend([timeWeekend[1], timeWeekend[1]]);
    }
    if (timeWork[0] > timeWork[1]) {
      setTimeWork([timeWork[1], timeWork[1]]);
    }
  }, [timeWork, timeWeekend],
  );

  return (
    <SideModal
      open
      title={t("MODALS.CALL_SETTINGS.TITLE")}
      onClose={close}
      size="normal"
      className={b()}
    >
      <div className={b("content")}>
        <span className={b("comment")}>{t("MODALS.CALL_SETTINGS.TITLE_COMMENT")}</span>
        <span className={b("heading")}>{t("MODALS.CALL_SETTINGS.CALL_TIME")}</span>
        <Card className={b("details-wrapper")}>
          <div className={b("time-inputs")}>
            <div>
              <div className={b("toggle-label")}>
                <span>{t("MODALS.CALL.TIME_SETUP_WORKING")}</span>
                <Toggle
                  value={setupWorkTime}
                  onChange={setSetupWorkTime}
                />
              </div>
              <HiddenArea className={b("time")} show={setupWorkTime}>
                <TimeInputRange
                  fluid
                  placeholder={t("MODALS.CALL.CALL_TIME")}
                  value={timeWork}
                  onChange={setTimeWork}
                />
              </HiddenArea>
            </div>
            <div>
              <div className={b("toggle-label")}>
                <span>{t("MODALS.CALL.TIME_SETUP_WEEKEND")}</span>
                <Toggle
                  value={setupWeekendTime}
                  onChange={setSetupWeekendTime}
                />
              </div>
              <HiddenArea className={b("time")} show={setupWeekendTime}>
                <TimeInputRange
                  fluid
                  placeholder={t("MODALS.CALL.CALL_TIME")}
                  value={timeWeekend}
                  onChange={setTimeWeekend}
                />
              </HiddenArea>
            </div>
          </div>
          <span className={b("comment")}>{t("MODALS.CALL_SETTINGS.SUBTITLE_COMMENT")}</span>
        </Card>
        <div>
          <div className={b("toggle-label")}>
            <span className={b("heading")}>{t("MODALS.CALL.CALL_BACK")}</span>
            <Toggle
              value={callBack}
              onChange={setCallBack}
          />
          </div>
          <HiddenArea show={callBack}>
            <Card className={b("details-wrapper")}>
              <Input
                min={1}
                type="number"
                value={callBackAfter}
                name="callBackAfter"
                floatingPlaceholder
                placeholder={t("MODALS.CALL.CALL_BACK_AFTER")}
                onChange={handleIntervalChange}
              />
            </Card>
          </HiddenArea>
        </div>
      </div>
      <div className={b("footer")}>
        <Button
          primary
          onClick={handleSubmitClick}
        >
          <span>{t("COMMON.SAVE")}</span>
        </Button>
      </div>
    </SideModal>
  );
}
