import { createAsyncAction, createAction } from "typesafe-actions";
import { AudioFileMetadata, FileMeta } from "../../types/models/Files";
import { TaskType } from "../../types/models/Task";

import { ErrorAction } from "../reducers";

export const downloadFile = createAsyncAction(
  'files/download/requested',
  'files/download/succeeded',
  'files/download/failed',
)<{ fileId: string, service?: 'calls' | 'default' }, Blob, ErrorAction>();

export const startUploadCallFiles = createAction('files/calls/start')<undefined>();
export const endUploadCallFiles = createAction('files/calls/end')<string>();
export const setCallFilesCountToUpload = createAction('files/calls/setCountToUpload')<number>();
export const setCallFilesUploaded = createAction('files/calls/setUploaded')<number>();

export const loadAudioFiles = createAsyncAction(
  'files/audioFiles/requested',
  'files/audioFiles/succeeded',
  'files/audioFiles/failed',
)<undefined, AudioFileMetadata[], ErrorAction>();

export const uploadAudioFile = createAsyncAction(
  'files/uploadAudioFile/requested',
  'files/uploadAudioFile/succeeded',
  'files/uploadAudioFile/failed',
)<{ file: File, name?: string, comment?: string }, AudioFileMetadata, ErrorAction<{ name: string }>>();

export const updateAudioUserFilename = createAsyncAction(
  'files/updateAudioUserFilename/requested',
  'files/updateAudioUserFilename/succeeded',
  'files/updateAudioUserFilename/failed',
)<
  { filename: string, newUserFilename: string, newUserComment?: string },
  { filename: string, newUserFilename: string, newUserComment?: string },
  ErrorAction<{ filename: string }>
>();

export const uploadExternalPhoneIdsFile = createAsyncAction(
  'files/uploadExternalPhoneIdsFile/requested',
  'files/uploadExternalPhoneIdsFile/succeeded',
  'files/uploadExternalPhoneIdsFile/failed',
)<{ file: File }, undefined, undefined>();

export type GetFilesMetaSuccess = { total: number, list: FileMeta[] };

export const getFilesMeta = createAsyncAction(
  'files/getFilesMeta/requested',
  'files/getFilesMeta/succeeded',
  'files/getFilesMeta/failed',
)<{ taskType: TaskType, limit?: number, offset?: number }, GetFilesMetaSuccess, ErrorAction>();

export const lockFile = createAction(
  'files/lockFile',
)<string>();
export const unlockFile = createAction(
  'files/unlockFile',
)<string>();
