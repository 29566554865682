export type Language = {
  slug: Lang,
  name: string;
};

export enum Lang {
  ru = "ru",
  en = "en",
  pl = "pl",
  hu = "hu",
  uk = "uk",
  de = "de",
  fr = "fr",
  be = "be",
  sq = "sq",
  es = "es",
  it = "it",
  hy = "hy",
  da = "da",
  pt = "pt",
  sk = "sk",
  sl = "sl",
  nl = "nl",
  bg = "bg",
  ca = "ca",
  hr = "hr",
  cs = "cs",
  el = "el",
  no = "no",
  mk = "mk",
  sv = "sv",
  fi = "fi",
  et = "et",
  lv = "lv",
  lt = "lt",
  tr = "tr",
  ro = "ro",
  sr = "sr",
  az = "az",
  ka = "ka",
  ar = "ar",
  he = "he",
  vi = "vi",
  ms = "ms",
  id = "id",
  bs = "bs",
  mt = "mt",
  is = "is",
  zh = "zh",
  th = "th",
  ja = "ja",
  ko = "ko",
  fa = "fa",
  tt = "tt",
  kk = "kk",
  ky = "ky",
  tg = "tg",
  af = "af",
  eu = "eu",
  cy = "cy",
  ga = "ga",
  gl = "gl",
  ht = "ht",
  la = "la",
  mg = "mg",
  mn = "mn",
  sw = "sw",
  tl = "tl",
  uz = "uz",
  ba = "ba",
  ur = "ur",
  hi = "hi",
  sjn = "sjn",
  udm = "udm",
  bn = "bn",
  gu = "gu",
  kn = "kn",
  pa = "pa",
  si = "si",
  ta = "ta",
  ceb = "ceb",
  eo = "eo",
  gd = "gd",
  jv = "jv",
  mi = "mi",
  ml = "ml",
  mr = "mr",
  ne = "ne",
  su = "su",
  te = "te",
  yi = "yi",
  mrj = "mrj",
  mhr = "mhr",
  pap = "pap",
  am = "am",
  xh = "xh",
  lb = "lb",
  my = "my",
  km = "km",
  lo = "lo",
}
