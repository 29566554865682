import React, { FC } from 'react';
import { SvgProps } from '../types';

const Document: FC<SvgProps> = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20.8987C2 22.9442 3.05577 24 5.07306 24H13.8963C15.9136 24 16.9694 22.9348 16.9694 20.8987V19.1548H18.5719C20.5892 19.1548 21.6449 18.0896 21.6449 16.0534V8.59701C21.6449 7.35271 21.381 6.56088 20.6363 5.79733L15.9419 1.00864C15.2255 0.282797 14.3771 0 13.293 0H9.74863C7.73134 0 6.67557 1.0652 6.67557 3.11076V4.84525H5.07306C3.05577 4.84525 2 5.91987 2 7.95601V20.8987ZM16.0173 10.9819L10.9081 5.79733C10.1917 5.07148 9.57895 4.87353 8.55145 4.85467V3.22388C8.55145 2.36606 9.00393 1.88531 9.90888 1.88531H13.8397V6.36292C13.8397 7.53181 14.4619 8.14454 15.6214 8.14454H19.7596V15.9309C19.7596 16.7981 19.3071 17.2789 18.4022 17.2789H16.9694V13.6591C16.9694 12.3865 16.8091 11.7926 16.0173 10.9819ZM15.3951 6.12726V2.64886L19.2694 6.58916H15.857C15.5271 6.58916 15.3951 6.45719 15.3951 6.12726ZM3.87588 20.7761V8.07855C3.87588 7.21131 4.32836 6.73056 5.24273 6.73056H8.11783V11.8586C8.11783 13.2255 8.78712 13.8853 10.1445 13.8853H15.0935V20.7761C15.0935 21.6434 14.641 22.1241 13.7266 22.1241H5.23331C4.32836 22.1241 3.87588 21.6434 3.87588 20.7761ZM10.3236 12.2357C9.93716 12.2357 9.76748 12.0754 9.76748 11.6795V7.08877L14.839 12.2357H10.3236Z"
        fill={color}
      />
    </svg>
  );
};

export default Document;
