import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { SavedUser } from '../../types/models/UserAccesses';
import { getNonSuperusers } from '../../redux/superusers/selectors';

import Select, { SelectConfig } from '../../components-new/select/configurable';

import Modal from '../components/ndModal';

import { ModalProps } from '../../services/modal';

export type AddSuperUserModalProps = {
  onSubmit: (user: SavedUser) => void,
}

export default function AddSuperUserModal({
  onSubmit,
  close,
}: AddSuperUserModalProps & ModalProps) {
  const { t } = useTranslation();

  const { list, loading } = useSelector(getNonSuperusers, shallowEqual);

  const [user, setUser] = useState<SavedUser | null>(null);

  const usersConfig: SelectConfig = {
    options: list,
    labelFunction: (item: SavedUser) => item.username,
    valueFunction: (item: SavedUser) => item.id,
  };

  const handleUserChange = useCallback(
    (userId: string) => {
      const selectedUser = list.find(({ id }) => id === userId);
      if (!selectedUser) return;

      setUser(selectedUser);
    },
    [list],
  );

  const handleSubmit = useCallback(
    () => {
      if (!user) return;
      onSubmit(user);
    },
    [onSubmit, user],
  );

  return (
    <Modal
      title={t("MODALS.ADD_SUPERUSER.TITLE")}
      className="modal-add-superuser"
      cancelText={t("COMMON.CANCEL")}
      confirmationText={t("COMMON.ADD")}
      close={close}
      onConfirm={handleSubmit}
    >
      <div className="column-2">
        <Select
          isLoading={loading}
          config={usersConfig}
          onChange={handleUserChange}
          floatingPlaceholder={false}
          value={user?.id || ''}
          usePortal
        />
      </div>
    </Modal>
  );
}
