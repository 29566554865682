import { createAction, createAsyncAction } from 'typesafe-actions';

import { User as UserExtended, UserRole } from '../../types/models/UserAccesses';
import { User } from '../../types/models/User';
import { UserConfigs, UserExperiments } from '../../types/codegen/experiments';
import { ErrorAction } from '../reducers';
import { Project } from '../../types/models/Project';
import { AccessesMap } from '../../types/backendModels/UserAccessesBackend';

export const setUser = createAction('user/set')<User>();
export const setConfigs = createAction('user/setConfigs')<UserConfigs>();
export const setExperiments = createAction('user/setExperiments')<UserExperiments>();
export const clearUser = createAction('user/clear')<undefined>();

export const loadUsers = createAsyncAction(
  'user/loadUsers/requested',
  'user/loadUsers/succeeded',
  'user/loadUsers/failed',
)<undefined, UserExtended[], ErrorAction>();

export const setAccesses = createAction('user/setAccesses')<
  Record<Project['slug'], { group: UserRole, capabilities: string[] }>
>();

export const setAccessesMap = createAction('user/setAccessesMap')<
  AccessesMap
>();
