import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";
import { Language, Lang } from "../../types/models/Languages";

import * as actions from './actions';

export type NEW_INTEGRATION_ID_TYPE = "NEW_INTEGRATION";
export const NEW_INTEGRATION_ID: NEW_INTEGRATION_ID_TYPE = "NEW_INTEGRATION";

type LanguagesState = {
  mainLanguage: Lang;
  list: Language[];
  loading: boolean;
}

const initialState: LanguagesState = {
  mainLanguage: Lang.ru,
  list: [],
  loading: false,
};

export const languagesReducer: Reducer<LanguagesState> = createReducer(initialState)
  .handleAction(
    actions.loadLanguages.request,
    (state: LanguagesState): LanguagesState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadLanguages.success,
    (
      state: LanguagesState,
      { payload }: ActionType<typeof actions.loadLanguages.success>,
    ): LanguagesState => ({
      ...state,
      loading: false,
      mainLanguage: payload.mainLanguage,
      list: payload.list,
    }),
  )
  .handleAction(
    actions.loadLanguages.failure,
    (state: LanguagesState): LanguagesState => ({
      ...state,
      loading: false,
    }),
  );
