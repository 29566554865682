import { generateId } from "../../services/helpers/generateId";
import {
  deepClone,
  objCamelToSnake,
  objCamelToSnakeDeep,
  objSnakeToCamel,
  objSnakeToCamelDeep,
  removeEmptyFields,
} from "../../services/helpers/utilities";
import {
  ApiParameterBackend,
  HttpRequestBackend,
  BodyFormat,
  IntegrationBackend,
  IntegrationTestBackend,
  KeyValueBackend,
  ApiRequestBackend,
  GRPCRequestBackend,
  IntegrationFileFieldBackend,
  TvmAuthBackend,
} from "../backendModels/IntegrationsBackend";
import {
  ApiParameter,
  HttpRequest,
  AuthorizationType,
  BodyKey,
  getEmptyIntegrationFileField,
  Integration,
  IntegrationHttpAuth,
  IntegrationFileField,
  IntegrationTest,
  KeyValue,
  HttpRequestType,
  ApiRequest,
  GRPCRequest,
  IntegrationProtocol,
  IntegrationType,
  BasicAuth,
  TvmAuth,
  IntegrationGRPCAuth,
  IntegrationLabel,
  IntegrationFileAuthorization,
  ExtractOperator,
} from "../models/Integrations";

export function parseApiRequest(value: ApiRequestBackend): ApiRequest {
  switch (value.protocol) {
    case IntegrationProtocol.GRPC:
      return parseGRPCRequest(value as GRPCRequestBackend);
    case IntegrationProtocol.HTTP:
    default:
      return parseHttpRequest(value as HttpRequestBackend);
  }
}

function parseHttpRequest(value: HttpRequestBackend): HttpRequest {
  const result: HttpRequest = {
    ...objSnakeToCamelDeep(value),
    ...(Array.isArray(value.query_params) && {
      queryParams: parseKeyValues(value.query_params),
    }),
    ...(Array.isArray(value.headers) && {
      headers: parseHeaders(value.headers),
    }),
    ...(Array.isArray(value.response_mapping) && {
      responseMapping: parseKeyValues(value.response_mapping),
    }),
    protocol: IntegrationProtocol.HTTP,
    authorization: parseAuthorization(value.authorization),
    ...parseApiRequestBody(value),
  };

  // Legacy
  if (
    BodyFormat.BYTES === value.body_format &&
    value.file
  ) {
    const { body, file } = parseBytesBody(value.file);
    result.file = file;
    result.body = body;
  }

  // Legacy
  if (
    BodyFormat.FORM_DATA === value.body_format &&
    value.file
  ) {
    const { body, file } = parseFormDataBody(value.file);
    result.body = body;
    result.file = file;
  }

  return result;
}

function parseGRPCRequest(value: GRPCRequestBackend): GRPCRequest {
  return {
    ...objSnakeToCamelDeep(value),
    ...(Array.isArray(value.metadata) && { metadata: parseKeyValues(value.metadata) }),
    ...(Array.isArray(value.response_mapping) && { responseMapping: parseKeyValues(value.response_mapping) }),
    authorization: parseAuthorization(value.authorization) as IntegrationGRPCAuth,
    protocol: IntegrationProtocol.GRPC,
  };
}

function parseApiRequestBody(request: HttpRequestBackend): {
  body: HttpRequest['body'],
  file: HttpRequest['file'],
} {
  switch (request.body_format) {
    case BodyFormat.X_WWW_FORM_URLENCODED:
      return {
        body: parseXFormUrlencodedBody(request.body),
        file: undefined,
      };
    case BodyFormat.FORM_DATA:
      return parseFormDataBody(request.body || "{}");
    case BodyFormat.BYTES:
      return parseBytesBody(request.body || "{}");
    case BodyFormat.JSON:
    case BodyFormat.XML:
      return {
        body: request.body,
        file: undefined,
      };
    default:
      try {
        return {
          body: (request.body && request.body !== "{}") ? JSON.parse(request.body) : undefined,
          file: undefined,
        };
      } catch {
        return {
          body: String(request.body),
          file: undefined,
        };
      }
  }
}

function parseXFormUrlencodedBody(body: HttpRequestBackend['body']): KeyValue[] {
  let result: KeyValue[] = [];

  try {
    const parsedData = JSON.parse(body || "{}") as BodyKey;

    result = Object.keys(parsedData).reduce<KeyValue[]>((res, key) => {
      if (Array.isArray(parsedData[key])) {
        parsedData[key].forEach(value => {
          res.push({
            id: generateId(),
            key,
            value: value as string,
          });
        });
      }

      return res;
    }, []);
  } finally {
    if (result.length === 0) {
      result.push({
        id: generateId(),
        key: "",
        value: "",
      });
    }

    return result;
  }
}

function parseFormDataBody(value: string): {
  body: KeyValue[],
  file: IntegrationFileField,
} {
  let resultBody: KeyValue[] = [];
  let resultFile: IntegrationFileField = {};

  try {
    const parsedData: BodyKey = JSON.parse(value);

    Object.keys(parsedData).forEach(key => {
      const firstField = parsedData[key][0];
      if (isObjectFileProperty(firstField)) {
        const fileField = firstField as IntegrationFileFieldBackend;

        // Legacy, support old scheme without "type" field
        if (!!fileField.file_auth &&
          !fileField.file_auth?.type
        ) {
          (fileField.file_auth as TvmAuthBackend).type = AuthorizationType.tvm;
        }

        resultFile = {
          ...objSnakeToCamelDeep(parsedData[key][0]) as IntegrationFileField,
          fieldName: key,
        };
      } else {
        parsedData[key].forEach(v => {
          resultBody.push({
            id: generateId(),
            key,
            value: v as string,
          });
        });
      }
    });
  } catch (err) {
    console.error("Error on parsing integration: ", err);
  }

  return {
    body: resultBody,
    file: resultFile,
  };
}

function isObjectFileProperty(value: unknown) {
  return typeof value === "object" &&
         Object.keys(value as Object).includes("file_download_url");
}

function parseBytesBody(value: string): {
  file: IntegrationFileField,
  body: undefined,
 } {
  try {
    const parsed = JSON.parse(value);
    if (parsed.file_auth && !parsed.file_auth.type) {
      parsed.file_auth.type = AuthorizationType.tvm;
    }

    return {
      body: undefined,
      file: objSnakeToCamelDeep(parsed),
    };
  } catch {
    return {
      body: undefined,
      file: getEmptyIntegrationFileField(),
    };
  }
}

export function parseAuthorizationToBackend(
  authorization: IntegrationHttpAuth | IntegrationGRPCAuth,
): IntegrationHttpAuth | IntegrationGRPCAuth {
  const type = authorization.type;
  if (type === AuthorizationType.basic) {
    return {
      ...authorization,
      encoding: 'latin1',
    } as BasicAuth;
  }

  if (type === AuthorizationType.tvm) {
    const copy = {
      ...authorization,
    } as TvmAuth;

    // @ts-expect-error Legacy
    delete copy.service;

    if (!copy.dstServiceName?.trim()) {
      delete copy.dstServiceName;
    }

    if (!copy.userOauthToken?.trim()) {
      delete copy.userOauthToken;
    }

    return objCamelToSnakeDeep(copy);
  }

  return authorization;
}

export function parseAuthorization(
  authorization: ApiRequestBackend['authorization'],
): IntegrationHttpAuth | IntegrationGRPCAuth {
  if (!authorization) {
    return {
      type: AuthorizationType.noAuth,
    };
  }

  return objSnakeToCamelDeep(authorization);
}

export function parseIntegration(value: IntegrationBackend): Integration {
  return {
    ...objSnakeToCamel(value),
    apiRequest: parseApiRequest(value.api_request),
    ...(value.api_parameters && { apiParameters: value.api_parameters.map(parseApiParameter) }),
    labels: Array.isArray(value.labels) ?
      value.labels.map<IntegrationLabel>(objSnakeToCamelDeep) :
      [],
  };
}

export function parseIntegrationToBackend(value: Integration): IntegrationBackend {
  return {
    ...objCamelToSnake(value),
    api_request: parseApiRequestToBackend(value.apiRequest),
    integration_type: IntegrationType.request,
    ...(Array.isArray(value.apiParameters) && {
      api_parameters: value.apiParameters.filter(isApiParameterValid),
    }),
  };
}

export function parseApiRequestToBackend(value: ApiRequest): ApiRequestBackend {
  switch (value.protocol) {
    case IntegrationProtocol.GRPC:
      return parseGRPCRequestToBackend(value as GRPCRequest);
    case IntegrationProtocol.HTTP:
    default:
      return parseHttpRequestToBackend(value as HttpRequest);
  }
}

export function parseHttpRequestToBackend(value: HttpRequest): HttpRequestBackend {
  const result: HttpRequestBackend = {
    ...objCamelToSnake(value),
    body: parseApiRequestBodyToBackend(value),
    ...(value.authorization && { authorization: parseAuthorizationToBackend(value.authorization) }),
    headers: parseHeadersToBackend(value.headers),
    query_params: value.queryParams,
    response_mapping: value.responseMapping,
  };
  delete result.file;

  if (
    result.method === HttpRequestType.GET &&
    !result.body?.trim()
  ) {
    delete result.body;
  }

  return result;
}

export function parseGRPCRequestToBackend(value: GRPCRequest): GRPCRequestBackend {
  return {
    ...objCamelToSnakeDeep(value),
    ...(value.authorization && {
      authorization: parseAuthorizationToBackend(value.authorization) as IntegrationGRPCAuth,
    }),
    ...(value.metadata && {
      metadata: value.metadata
        .filter(isKeyNotEmpty)
        .map(parseKeyValueToBackend),
    }),
    ...(value.responseMapping && {
      response_mapping: value.responseMapping
        .filter(isKeyNotEmpty)
        .map(parseKeyValueToBackend),
    }),
  };
}

function parseApiRequestBodyToBackend(value: HttpRequest): HttpRequestBackend['body'] {
  const { body } = value;
  switch (value.bodyFormat) {
    case BodyFormat.FORM_DATA:
      return parseFormDataBodyToBackend(value);
    case BodyFormat.X_WWW_FORM_URLENCODED:
      return parseXFormUrlencodedBodyToBackend(body as KeyValue[]);
    case BodyFormat.BYTES:
      return parseBytesBodyToBackend(value);
    default:
      //eslint-disable-next-line  no-nested-ternary
      return typeof body === 'string' ? ((body && body?.length > 2) ? body : undefined) : JSON.stringify(body);
  }
}

export function parseXFormUrlencodedBodyToBackend(body: KeyValue[]): HttpRequestBackend['body'] {
  return JSON.stringify(
    body.reduce<BodyKey>((res, item: KeyValue) => {
      if (item.key && !res[item.key]) {
        res[item.key] = [];
      }

      if (item.key && item.value) {
        res[item.key].push(item.value);
      }

      return res;
    }, {}),
  );
}

function parseFormDataBodyToBackend(value: HttpRequest): HttpRequestBackend['body'] {
  const safeCopy = deepClone(value);
  const { file } = safeCopy;
  let { body } = safeCopy;

  if (!Array.isArray(body)) {
    body = [];
  }

  let { fieldName } = file as IntegrationFileField;

  if (file && fieldName) {
    const fileObject = deepClone(file) as IntegrationFileField;
    delete fileObject.fieldName;

    if (!!fileObject.fileAuth && !fileObject.fileAuth.type) {
      (fileObject.fileAuth as TvmAuth).type = AuthorizationType.tvm;
    }

    if (fileObject.fileAuth) {
      fileObject.fileAuth = parseAuthorizationToBackend(fileObject.fileAuth) as IntegrationFileAuthorization;
    }

    body.push({
      id: generateId(),
      key: fieldName,
      // Приведение типов ниже неверное, но парсинг в строку будет при возврате ответа
      value: objCamelToSnakeDeep(removeEmptyFields(fileObject as IntegrationFileField)) as string,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {};

  body.forEach(pair => {
    if (!result[pair.key]) {
      result[pair.key] = [];
    }

    result[pair.key].push(pair.value);
  });

  return JSON.stringify(result);
}

function parseBytesBodyToBackend(value: HttpRequest): HttpRequestBackend['body'] {
  const safeCopy = deepClone(value);
  delete safeCopy.file?.contentType;
  delete safeCopy.file?.fieldName;
  delete safeCopy.file?.filename;

  if (safeCopy.file?.fileAuth) {
    safeCopy.file.fileAuth = parseAuthorizationToBackend(safeCopy.file.fileAuth) as IntegrationFileAuthorization;
  }

  return JSON.stringify(objCamelToSnakeDeep(safeCopy.file));
}

export function isKeyNotEmpty(value: KeyValue): boolean {
  return !!value.key.trim();
}

export function isApiParameterValid(value: ApiParameter): boolean {
  return !!(
    value.slug ||
    value.title ||
    value.type
  );
}

export function parseKeyValues(values: KeyValueBackend[]): KeyValue[] {
  return values.flatMap(parseKeyValue);
}

export function parseKeyValue(value: KeyValueBackend): KeyValue[] {
  if (Array.isArray(value.value)) {
    return value.value.map(v => ({
      id: generateId(),
      key: value.key,
      value: v,
      source: value.source as ExtractOperator,
    }));
  }

  return [{
    id: generateId(),
    key: value.key,
    value: value.value.toString(),
    source: value.source as ExtractOperator,
  }];
}

export function parseHeaders(list: HttpRequestBackend['headers']): HttpRequest['headers'] {
  return list?.map(item => ({
    id: generateId(),
    key: item.key,
    value: Array.isArray(item.value) ? item.value[0] : item.value.toString(),
  }));
}

export function parseKeyValuesToBackend(list: KeyValue[]): KeyValueBackend[] {
  const mappedValues = list.reduce<Record<KeyValue['key'], KeyValue['value'][]>>((result, keyValue: KeyValue) => {
    if (!result[keyValue.key]) {
      result[keyValue.key] = [];
    }

    result[keyValue.key].push(keyValue.value);

    return result;
  }, {});

  // @ts-ignore Непонятный формат Feature, несоответствуют схемы с бека и что по факту приходит
  return Object.keys(mappedValues).map(key => ({
    key,
    value: mappedValues[key],
  }));
}

export function parseKeyValueToBackend({ value, key }: KeyValue): KeyValueBackend {
  return {
    key,
    value,
  };
}

export function parseHeadersToBackend(list: HttpRequest['headers']): HttpRequestBackend['headers'] {
  return list?.map(item => ({
    key: item.key,
    value: item.value,
  }));
}

export function parseApiParameter(value: ApiParameterBackend): ApiParameter {
  return {
    id: generateId(),
    ...value,
  };
}

export function parseIntegrationTest(value: IntegrationTestBackend): IntegrationTest {
  try {
    return {
      // @ts-ignore Непонятный формат Feature, несоответствуют схемы с бека и что по факту приходит
      state: value.state,
      debugInfo: value.debug_info ? JSON.parse(value.debug_info) : "",
    };
  } catch {
    return {
      // @ts-ignore Непонятный формат Feature, несоответствуют схемы с бека и что по факту приходит
      state: value.state,
      debugInfo: value.debug_info,
    };
  }
}
