import React from "react";
import { SvgProps } from "../types";

function DocTextIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1H10C11.1046 1 12 1.89543 12 3C14.2091 3 16 4.79086 16 7H17C19.2091 7 21 8.79086 21 11V20C21 22.2091 19.2091 24 17 24H12C9.79086 24 8 22.2091 8 20H7C4.79086 20 3 18.2091 3 16V7C3 4.79086 4.79086 3 7 3C7 1.89543 7.89543 1 9 1ZM12 5H7C5.89543 5 5 5.89543 5 7V16C5 17.1046 5.89543 18 7 18H8V11C8 8.79086 9.79086 7 12 7H14C14 5.89543 13.1046 5 12 5ZM17 9H12C10.8954 9 10 9.89543 10 11V20C10 21.1046 10.8954 22 12 22H17C18.1046 22 19 21.1046 19 20V11C19 9.89543 18.1046 9 17 9ZM11 13L18 13V11L11 11V13ZM18 17H11V15L18 15V17Z"
        fill={color}
      />
    </svg>
  );
}

export default DocTextIcon;
