export enum CallService {
  ivr = 'ivr_framework',
  voximplant = 'voximplant',
  ya = 'ya_telephony',
}

export type ProjectConfig = {
  projectSlug: string;
  dispatcherParams: DispatcherParams;
  templateFileId?: string;
  authToken?: string;
  authAllowedIps?: string[];
};

type IVRDispatcherParams = {
  callService: CallService.ivr;
};

type VoximplantDispatcherParams = {
  callService: CallService.voximplant;
  accountId: number;
  apiKey: string
  ruleId: number;
};

type YaTelephonyDispatcherParams = {
  callService: CallService.voximplant;
};

type DispatcherParams =
  IVRDispatcherParams
  | VoximplantDispatcherParams
  | YaTelephonyDispatcherParams;

export const getEmptyProjectConfig = ():ProjectConfig => ({
  projectSlug: '',
  dispatcherParams: {
    callService: CallService.ivr,
  },
});
