import { block } from 'bem-cn';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/selectors';

import postYaMetric, { YaMetricsActions } from '../../services/ya-metrics';

import Icon, { IconSize } from '../icon';
import { referenceConfig } from './config';

import './styles.scss';

const b = block("reference-link");

type ReferenceLinkProps = {
  href: string;
  className?: string;
  label?: string;
}

function ReferenceLink({
  href,
  className,
  label,
}: ReferenceLinkProps) {
  const selectedProject = useSelector(getSelectedProject);

  const sendMetrics = useCallback(
    () => {
      postYaMetric(
        YaMetricsActions.referenceLinkClick,
        {
          project: selectedProject.slug,
          href,
        },
      );
    },
    [selectedProject, href],
  );

  return (
    <a
      href={href}
      type="link"
      rel="noreferrer"
      target="_blank"
      onClick={sendMetrics}
      className={`${b()} ${className || ''}`}
    >
      <Icon iconSize={IconSize.small} type="info" />
      {label}
    </a>
  );
}

export default ReferenceLink;
export { referenceConfig };
