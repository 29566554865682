import { store } from "../../redux/store";
import { getAccessesMap, getCurrentUser, getCurrentUserRole } from "../../redux/user/selectors";
import { UserRole, rolePriorities } from "../../types/models/UserAccesses";

let accesses: Capability[] = [];

export const EVENT_ACCESS_CHANGED = 'EVENT_ACCESS_CHANGED';

export enum Capability {
  hideInternalErrors = 'hide_internal_errors',
}

export enum AccessType {
  write = "write",
  read = "read"
}

export enum Access {
  version = "versions",
  models = "models",
  modelsSlots = "models/slots",
  oldScenarios = "old_scenarios",
  scenariosScenario = "scenarios/scenario",
  dialogsHistory = "dialogs/history",
  featuresEntity = "features/entity",
  featuresFeature = "features/feature",
  scenarioExamples = "scenarios/scenario/examples",
  scenarios = "scenarios/scenario",
  topics = "topics",
  configCustom = "config/custom",
  scenariosAutomation = "scenarios/scenario/automation",
  integrationsOutgoing = "integrations/outgoing",
  testingLive = "testing/live",
  voice = "voice",
  tasks = "tasks",
  statistics = "statistics",
  changes = "changes",
  integrationsIncoming = "integrations/incoming",
  integrationsIncomingAdd = "integrations/incoming/add",
  releases = "releases",
  accessesUsers = "accesses/users",
  projectsAuth = "projects/auth",
  integrationsIncomingMessenger = "integrations/incoming/messenger",
  widget = "widget",
  voiceConfig = "voice/config",
  dialogsMarkup = "dialogs/markup",
  games = "games",
  whitelists = "whitelists",
}

const hasAccessTo = (capability: Capability): boolean => accesses.includes(capability);

const setCapabilities = (value: Capability[]) => {
  accesses = value;
  window.dispatchEvent(new CustomEvent(EVENT_ACCESS_CHANGED, { detail: { capability: value } }));
};

const checkIfCurrentRoleNotLessThan = (role: UserRole): boolean => {
  if (accessService.checkIfCurrentUserSuperadmin()) return true;

  const state = store.getState();

  const currentRole = getCurrentUserRole(state);

  return rolePriorities[currentRole] >= rolePriorities[role];
};

const checkIfCurrentUserSuperadmin = (): boolean => {
  const currentUser = getCurrentUser();

  return currentUser.isSuperUser === true &&
    currentUser.superadminGroup === UserRole.admin;
};

const currentUserHasAccessTo = (access: Access, accessType: AccessType = AccessType.read) => {
  if (accessService.checkIfCurrentUserSuperadmin()) return true;

  const state = store.getState();
  const role = getCurrentUserRole(state);
  const accessesMap = getAccessesMap(state);

  const roleAccesses = accessesMap[role] || {};
  const specificAccesses = roleAccesses[access] || [];

  return specificAccesses.includes(accessType);
};

const accessService = {
  hasAccessTo,
  setCapabilities,
  checkIfCurrentRoleNotLessThan,
  checkIfCurrentUserSuperadmin,
  currentUserHasAccessTo,
};

export default accessService;
