import { createAction, createAsyncAction } from "typesafe-actions";
import { BalanceClient, Person } from "../../types/models/Billing";
import { ErrorAction } from "../reducers";
import { ModalClientSteps } from "./reducer";

export const loadClients = createAsyncAction(
  'billing/loadClients/requested',
  'billing/loadClients/succeeded',
  'billing/loadClients/failed',
)<
  undefined,
  BalanceClient[],
  ErrorAction
>();

export const setActiveClient = createAction('billing/setActiveClient')<BalanceClient['id'] | null>();

export const openActiveClientEditModal = createAction('billing/openActiveClientEditModal')<ModalClientSteps | undefined>();
export const closeActiveClientEditModal = createAction('billing/closeActiveClientEditModal')();

export const setActiveClientStep = createAction('billing/setActiveClientStep')<ModalClientSteps>();

export const loadActiveClientPerson = createAsyncAction(
  'billing/loadActiveClientPerson/requested',
  'billing/loadActiveClientPerson/succeeded',
  'billing/loadActiveClientPerson/failed',
)<undefined, Person, ErrorAction>();

export const submitClient = createAction('billing/submitClient')<BalanceClient>();
export const submitManager = createAction('billing/submitManager')<string>();
export const submitPerson = createAction('billing/submitPerson')<Person>();

export const setModalLoadingStatus = createAction('billing/setModalLoadingStatus')<boolean>();

export const updateClient = createAction('billing/updateClient')<BalanceClient>();
export const updatePerson = createAction('billing/updatePerson')<Person>();

export const nextModalStep = createAction('billing/nextModalStep')();
