// Хук-обёртка над useEffect, пропускающий первый вызов. Удобно использовать, когда в useEffect
// передаются зависимости вторым аргументом, но нам нужно запустить коллбек только при изменении
// этих зависимостей, а не при инициализации

import { useEffect, useRef } from "react";

export default function useEffectWithoutInitial(callback: Function, deps: React.DependencyList) {
  let initCallRaised = useRef<boolean>();
  useEffect(() => {
    if (initCallRaised.current) {
      callback();
    }
    initCallRaised.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
