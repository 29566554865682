import React from 'react';

import { ColumnConfig } from '../types';
import { b } from '../index';

type TableCellProps = {
  config: ColumnConfig,
  className?: string,
}

function TableCell({
  className,
  config,
  children,
}: React.PropsWithChildren<TableCellProps>) {
  let cellClassName = config.className || 'default';

  if (config._groupStart) {
    cellClassName += " group-start";
  }

  if (config._groupEnd) {
    cellClassName += " group-end";
  }

  if (config.loading) {
    return (
      <div className={b("cell").mix(cellClassName)}>
        <span className={b("cell-loader")} />
      </div>
    );
  }

  return (
    <div
      className={b("cell")
        .mix(cellClassName)
        .mix(config._group)
        .mix(className)}
    >
      {children}
    </div>
  );
}

export default React.memo(TableCell);
