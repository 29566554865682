import { createAction, createAsyncAction } from "typesafe-actions";
import { Secret } from "../../types/models/Secret";
import { ErrorAction } from "../reducers";

export const loadSecrets = createAsyncAction(
  'secrets/loadSecrets/requested',
  'secrets/loadSecrets/succeeded',
  'secrets/loadSecrets/failed',
)<
  undefined,
  Secret[],
  ErrorAction
>();

export const createSecret = createAsyncAction(
  'secrets/createSecret/requested',
  'secrets/createSecret/succeeded',
  'secrets/createSecret/failed',
)<
  Secret,
  Secret,
  ErrorAction
>();

export const updateSecret = createAsyncAction(
  'secrets/updateSecret/requested',
  'secrets/updateSecret/succeeded',
  'secrets/updateSecret/failed',
)<
  Secret,
  Secret,
  ErrorAction
>();

export const deleteSecret = createAsyncAction(
  'secrets/deleteSecret/requested',
  'secrets/deleteSecret/succeeded',
  'secrets/deleteSecret/failed',
)<
  Secret['slug'],
  Secret['slug'],
  ErrorAction
>();

export const setSecretToEdit = createAction(
  'secrets/setSecretToEdit',
)<Secret>();
export const closeEditModal = createAction(
  'secrets/closeEditModal',
)();

export const openNewSecretModal = createAction(
  'secrets/openNewSecretModal',
)();
export const closeNewSecretModal = createAction(
  'secrets/closeNewSecretModal',
)();
