import React, { FC, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';

import cx from '../../services/helpers/classNames';

import ReferenceLink from '../referenceLink';
import Button from '../button';
import Input, { OnChangeFunc } from '../input';

import './styles.scss';

const b = block('nd-side-modal');

export type SideModalSize = "slim" | "normal" | "medium" | "large" | "wide";

type Props = {
  className?: string;
  open: boolean;
  low?: boolean;
  size?: SideModalSize;
  islands?: boolean;
  roundedHeader?: boolean;
  setFocusAtTitle?: boolean;
  title: ReactNode;
  titleName?: string;
  titlePlaceholder?: string;
  titleReadonly?: boolean;
  placement?: 'left' | 'right' | 'center';
  // disableEsc?: boolean;
  hideOverlay?: boolean;
  defaultShadow?: boolean;
  zIndex?: number;
  children: ReactNode;
  footer?: ReactNode;
  contentId?: string;
  scrollableContentId?: string;
  contrastBackground?: boolean;
  onChangeTitle?: OnChangeFunc;
  onConfirmClose?: () => void;
  onReset?: () => void;
  onClose: () => void;
};

export const SideModalTitle: FC<{ className?: string }> = ({ className = '', children }) => (
  <h2 className={`${b('title')} ${className}`}>{children}</h2>
);

export const SideModalReferenceHeader: FC<{ label: string, href: string }> = ({ label, href }) => (
  <div className="reference-header">
    <SideModalTitle>{label}</SideModalTitle>
    <ReferenceLink
      className={b("reference-link")}
      href={href}
    />
  </div>
);

const SideModal: FC<Props> = ({
  className = '',
  open,
  low = false,
  size = "normal",
  setFocusAtTitle = false,
  title,
  titleName = 'title',
  titlePlaceholder,
  titleReadonly = false,
  hideOverlay = false,
  contentId = '',
  placement = 'right',
  scrollableContentId,
  zIndex = 1000,
  defaultShadow = false,
  contrastBackground = false,
  islands = false,
  roundedHeader = false,
  children,
  footer,
  onChangeTitle,
  onConfirmClose,
  onReset,
  onClose,
}) => {
  const { t } = useTranslation();
  const defaultFocusElementRef = useRef(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const resizeObserver = useRef<ResizeObserver | null>(null);

  const [scrollable, setScrollable] = useState(false);

  const handleClose = useCallback(
    () => {
      if (onConfirmClose) {
        onConfirmClose();
      } else {
        onClose();
      }
    },
    [onConfirmClose, onClose],
  );

  const handleCatchEsc = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.repeat || e.key !== 'Escape') return;

      e.preventDefault();
      e.stopPropagation();
      handleClose();
    },
    [handleClose],
  );

  const handleSetDefaultFocus = useCallback(
    () => {
      const el = defaultFocusElementRef.current;

      if (el) {
        (el as HTMLDivElement).focus();
      }
    },
    [],
  );

  const updateResizeObserver = useCallback(
    () => {
      if (!contentId) return;

      const content = document.getElementById(contentId);
      if (!content) return;

      const observer = new ResizeObserver(() => {
        const header = headerRef.current;
        if (!header || !content) return;

        const { scrollHeight, clientHeight } = content;

        if (scrollHeight > clientHeight) {
          setScrollable(true);
        } else {
          setScrollable(false);
        }
      });

      observer.observe(content);
      resizeObserver.current = observer;
    },
    [contentId],
  );

  useEffect(
    () => {
      if (setFocusAtTitle) {
        handleSetDefaultFocus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFocusAtTitle],
  );

  useEffect(
    () => {
      if (!contentId) return;

      if (open) {
        updateResizeObserver();
      } else if (resizeObserver.current) {
        resizeObserver.current.disconnect();
        resizeObserver.current = null;
      }

      return () => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  if (!open) return null;

  return (
    <>
      {!hideOverlay && (
        <div
          className={b('overlay')}
          style={{ zIndex }}
          onClick={handleClose}
        />
      )}
      <div
        className={cx(
          String(b({
            low,
            placement,
            size,
            'contrast-background': contrastBackground || islands,
          })),
          className,
        )}
        style={{ zIndex }}
        onKeyDown={handleCatchEsc}
      >
        <div className={b('header', { 'with-shadow': scrollable || defaultShadow, rounded: !!roundedHeader })} ref={headerRef}>
          {onReset && (
            <Button
              className={b('reset-button')}
              icon="arrowLeft"
              simple
              onClick={onReset}
            />
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {!!onChangeTitle && typeof title === 'string' ?
            (
              <Input
                ref={defaultFocusElementRef}
                fluid
                visualStyle="default"
                name={titleName}
                value={title}
                readOnly={titleReadonly}
                placeholder={titlePlaceholder || t('COMMON.INPUT_PLACEHOLDER')}
                autoFocus={setFocusAtTitle}
                onChange={onChangeTitle ?? (() => {})}
              />
            ) :
            typeof title === 'string' ?
              <SideModalTitle>{title}</SideModalTitle> :
              title}
          <Button
            ref={onChangeTitle ? undefined : defaultFocusElementRef}
            className={b('close-button')}
            icon="close"
            name="close-modal"
            simple
            onClick={handleClose}
          />
        </div>
        <div id={scrollableContentId} className={b('content', { islands })}>
          {children}
        </div>
        {footer && (
          <div className={b('footer')}>
            {footer}
          </div>
        )}
      </div>
      <div
        className={cx(String(b('focus-catcher')), 'visually-hidden')}
        tabIndex={0}
        onFocus={handleSetDefaultFocus}
      />
    </>
  );
};

export default memo(SideModal);
