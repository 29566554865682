import React from "react";

export function getMenuPortalStyles(base: React.CSSProperties): React.CSSProperties {
  return {
    ...base,
    zIndex: 1000,
  };
}

export function getOptionStyles(base: React.CSSProperties): React.CSSProperties {
  return {
    ...base,
    display: 'flex',
    alignItems: 'center',
    minHeight: 'var(--control-height-l)',
    color: 'var(--text-icons-light-primary)',
    wordBreak: 'break-word',
    cursor: 'pointer',
  };
}

export function getControlStyles(base: React.CSSProperties): React.CSSProperties {
  return {
    ...base,
    outline: 'initial',
  };
}
