import React from 'react';

import { SidebarItem as SidebarItemModel } from '../config';
import SidebarItem from '../sidebarItem';

type PagesListProps = {
  items: SidebarItemModel[];
  activeItem?: SidebarItemModel['id'];
}

function PagesList({
  activeItem,
  items,
}: PagesListProps) {
  return (
    <>
      {items.map(item => (
        <SidebarItem
          key={item.id}
          activeItemId={activeItem}
          value={item}
        />
      ))}
    </>
  );
}

export default React.memo(PagesList);
