import React, { FC, memo, ReactNode } from 'react';
import { block } from 'bem-cn';

import Icon, { IconTypes } from '../icon';

import './styles.scss';

type Props = {
  icon?: IconTypes;
  className?: string;
  children: ReactNode;
  error?: boolean;
};

const b = block('nd-message');

const Message: FC<Props> = ({ icon, error, className = '', children }) => (
  <div className={`${b({ error })} ${className}`}>
    {children}
    {icon && (
      <Icon type={icon} />
    )}
  </div>
);

export default memo(Message);
