import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ScenarioGraphNodeMetaBackend } from "../backendModels/ScenarioGraphNodeMetaBackend";
import { ScenarioGraphNodeMeta } from "../models/ScenarioGraphNodeMeta";
import {
  parseScenarioGraphValidationResult,
  parseScenarioGraphValidationResultToBackend,
} from "./ScenarioValidationParser";

export function parseScenarioGraphNodeMeta(
  value: ScenarioGraphNodeMetaBackend,
): ScenarioGraphNodeMeta {
  return {
    ...objSnakeToCamel(value),
    ...(value.validation_result && {
      validationResult: parseScenarioGraphValidationResult(value.validation_result),
    }),
  };
}

export function parseScenarioGraphNodeMetaToBackend(
  value: ScenarioGraphNodeMeta,
): ScenarioGraphNodeMetaBackend {
  return {
    ...objCamelToSnake(value),
    ...(value.validationResult && {
      validation_result: parseScenarioGraphValidationResultToBackend(value.validationResult),
    }),
  };
}
