import React from 'react';
import { SvgProps } from '../types';

function RouteIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 8.97994L22.2321 15.6723L20.1396 13.8868L16.5816 18.1985C14.9467 20.1798 12.2083 20.59 10.4777 19.1134C8.74717 17.6368 8.66895 14.8233 10.3038 12.8421L12.6758 9.9676C13.6566 8.779 13.6729 7.14456 12.711 6.32383C11.7491 5.5031 10.169 5.80318 9.18819 6.99178L6.1141 10.7171C6.68042 11.7061 6.61255 12.994 5.83928 13.9311C4.85669 15.1218 3.12352 15.2876 1.96771 14.3014C0.811913 13.3153 0.671528 11.5508 1.65412 10.3601C2.42739 9.42301 3.66292 9.1314 4.71905 9.52678L7.79314 5.80145C9.42803 3.82022 12.1664 3.40999 13.897 4.88659C15.6275 6.36319 15.7058 9.17669 14.0709 11.1579L11.6989 14.0324C10.7181 15.221 10.7018 16.8554 11.6637 17.6762C12.6256 18.4969 14.2057 18.1968 15.1865 17.0082L18.7445 12.6965L16.6519 10.911L23 8.97994Z"
        fill={color}
      />
    </svg>
  );
}

export default RouteIcon;
