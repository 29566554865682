import { createReducer, ActionType } from "typesafe-actions";
import { Reducer } from 'redux';

import { Task, TaskLog, TaskStatus } from "../../types/models/Task";
import { Pagination } from "../../types/models/Pagination";

import * as projectActions from '../projects/actions';
import * as actions from './actions';
import { getStatsKey } from "./helpers";

type TasksList = {
  list: Task[],
  loading: boolean,
  total: number | undefined, // TODO подумать над тем, чтобы сделать поле необязательным /
                 // добавить пустое значение. Если не знаем, какое точное количество задач будет в списке,
                 // логичнее устанаваливать undefined чем 0
};

type TasksState = {
  importExport: TasksList,
  voice: TasksList,
  accountOverview: TasksList,
  callsGrouped: TasksList,
  callsSeparated: TasksList,
  vox: TasksList,
  scenarioExamplesUploadings: TasksList,
  customModelLearning: TasksList,
  clusteringLaunchTasks: TasksList,
  exportExamplesTasks: TasksList,
  processExamplesTasks: TasksList,
  allTasks: TasksList,
  tasksMeta: Record<Task['id'], {
    loading: boolean;
    loaded: boolean;
    logs: TaskLog[];
  }>,
}

function getEmptyTasksList(): TasksList {
  return {
    list: [],
    loading: false,
    total: undefined,
  };
}

const initialTasksState: TasksState = {
  importExport: getEmptyTasksList(),
  voice: getEmptyTasksList(),
  accountOverview: getEmptyTasksList(),
  callsGrouped: getEmptyTasksList(),
  callsSeparated: getEmptyTasksList(),
  vox: getEmptyTasksList(),
  scenarioExamplesUploadings: getEmptyTasksList(),
  customModelLearning: getEmptyTasksList(),
  clusteringLaunchTasks: getEmptyTasksList(),
  exportExamplesTasks: getEmptyTasksList(),
  processExamplesTasks: getEmptyTasksList(),
  allTasks: getEmptyTasksList(),
  tasksMeta: {},
};

export const tasksReducer: Reducer<TasksState> = createReducer<TasksState>(initialTasksState)
  .handleAction(
    actions.loadImportExportTasks.request,
    (
      state: TasksState,
      { payload: { olderThan } }: ActionType<typeof actions.loadImportExportTasks.request>,
    ): TasksState => ({
      ...state,
      importExport: {
        ...state.importExport,
        list: olderThan ? [...state.importExport.list] : [],
        loading: true,
        total: olderThan ? state.importExport.total : 0,
      },
    }),
  )
  .handleAction(
    actions.loadImportExportTasks.success,
    (
      state: TasksState,
      { payload: { tasks, pagination } }: ActionType<typeof actions.loadImportExportTasks.success>,
    ): TasksState => {
      const currentTasks = pagination?.olderThan ? [...state.importExport.list, ...tasks] : tasks;
      const noMoreTasks = isLastChunk(pagination, tasks);
      const sorted = sortTasks(currentTasks);
      return ({
        ...state,
        importExport: {
          ...state.importExport,
          list: sorted,
          loading: false,
          total: noMoreTasks ? sorted.length : state.importExport.total,
        },
      });
    },
  )
  .handleAction(
    actions.loadImportExportTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      importExport: {
        ...state.importExport,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createImportExportTask.request,
    (state: TasksState): TasksState => ({
      ...state,
      importExport: {
        ...state.importExport,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.createImportExportTask.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.createImportExportTask.success>,
    ): TasksState => ({
      ...state,
      importExport: {
        ...state.importExport,
        list: [payload, ...state.importExport.list],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createImportExportTask.failure,
    (state: TasksState): TasksState => ({
      ...state,
      importExport: {
        ...state.importExport,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadVoiceTasks.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadVoiceTasks.request>,
    ): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        list: [...state.voice.list],
        loading: true,
        total: payload.olderThan ? state.voice.total : 0,
      },
    }),
  )
  .handleAction(
    actions.loadVoiceTasks.success,
    (
      state: TasksState,
      { payload: { tasks = [], pagination } }: ActionType<typeof actions.loadVoiceTasks.success>,
    ): TasksState => {
      const currentTasks = pagination?.olderThan ? [...state.voice.list, ...tasks] : tasks;

      const sortedList = currentTasks.sort(
        (taskA, taskB) => +(new Date(taskB.created)) - +(new Date(taskA.created)),
      );

      const noMoreTasks = isLastChunk(pagination, tasks);

      return {
        ...state,
        voice: {
          ...state.voice,
          list: sortedList,
          loading: false,
          total: noMoreTasks ? sortedList.length : state.voice.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadVoiceTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadStatsReportsTasks.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadStatsReportsTasks.request>,
    ): TasksState => {
      const { olderThan, statsType } = payload;
      const key = getStatsKey(statsType);

      return {
        ...state,
        [key]: {
          ...state[key],
          list: olderThan ? state[key].list : [],
          loading: true,
        },
      };
    },
  )
  .handleAction(
    actions.loadStatsReportsTasks.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadStatsReportsTasks.success>,
    ): TasksState => {
      const { tasks, pagination, statsType } = payload;
      const noMoreTasks = isLastChunk(pagination, tasks);
      const key = getStatsKey(statsType);

      const sortedTasks = pagination.olderThan ?
        sortTasks([...state[key].list, ...tasks]) :
        sortTasks(tasks);

      return {
        ...state,
        [key]: {
          ...state[key],
          list: sortedTasks,
          loading: false,
          total: noMoreTasks ? sortedTasks.length : state[key].total,
        },
      };
    },
  )
  .handleAction(
    actions.loadStatsReportsTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      accountOverview: {
        ...state.accountOverview,
        loading: false,
      },
      callsGrouped: {
        ...state.callsGrouped,
        loading: false,
      },
      callsSeparated: {
        ...state.callsSeparated,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadVoxExportTasks.request,
    (
      state: TasksState,
      { payload: { olderThan: offset } }: ActionType<typeof actions.loadVoxExportTasks.request>,
    ): TasksState => ({
      ...state,
      vox: {
        ...state.vox,
        list: offset ? state.vox.list : [],
        loading: true,
        total: offset ? state.vox.total : 0,
      },
    }),
  )
  .handleAction(
    actions.loadVoxExportTasks.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadVoxExportTasks.success>,
    ): TasksState => {
      const { tasks, pagination } = payload;
      const noMoreTasks = isLastChunk(pagination, tasks);

      const sortedTasks = pagination.olderThan ?
        sortTasks([...state.vox.list, ...tasks]) :
        sortTasks(tasks);

      return {
        ...state,
        vox: {
          list: sortedTasks,
          loading: false,
          total: noMoreTasks ? sortedTasks.length : state.vox.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadVoxExportTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      vox: {
        total: 0,
        list: [],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createStatsReportTask.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.createStatsReportTask.request>,
    ): TasksState => {
      const key = getStatsKey(payload.statsType);

      return {
        ...state,
        [key]: {
          ...state[key],
          loading: true,
        },
      };
    },
  )
  .handleAction(
    actions.createStatsReportTask.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.createStatsReportTask.success>,
    ): TasksState => {
      const key = getStatsKey(payload.statsType);
      const list = [payload, ...state[key].list];

      return {
        ...state,
        [key]: {
          list,
          total: list.length,
          loading: false,
        } as TasksList,
      };
    },
  )
  .handleAction(
    actions.createStatsReportTask.failure,
    (state: TasksState): TasksState => ({
      ...state,
      accountOverview: {
        ...state.accountOverview,
        loading: false,
      },
      callsGrouped: {
        ...state.callsGrouped,
        loading: false,
      },
      callsSeparated: {
        ...state.callsSeparated,
        loading: false,
      },
    }),
  )
  .handleAction(
    projectActions.selectProject,
    (state: TasksState): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        list: [],
      },
    }),
  )
  .handleAction(
    actions.cancelCall.request,
    (
      state: TasksState,
      { payload: { taskId } }: ActionType<typeof actions.cancelCall.request>,
    ): TasksState => updateVoiceTask(state, taskId, task => ({
      ...task,
      cancelling: true,
    })),
  )
  .handleAction(
    actions.cancelCall.success,
    (
      state: TasksState,
      { payload: { taskId } }: ActionType<typeof actions.cancelCall.success>,
    ): TasksState => updateVoiceTask(state, taskId, task => ({
      ...task,
      cancelling: false,
      status: TaskStatus.completed,
    })),
  )
  .handleAction(
    actions.cancelCall.failure,
    (
      state: TasksState,
      { payload: { payload: { taskId } } }: ActionType<typeof actions.cancelCall.failure>,
    ): TasksState => updateVoiceTask(state, taskId, task => ({
      ...task,
      cancelling: false,
    })),
  )
  .handleAction(
    actions.runCall.request,
    (state: TasksState): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.runCall.success,
    (
      state: TasksState,
      { payload: task }: ActionType<typeof actions.runCall.success>,
    ): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        list: [task, ...state.voice.list],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.runCall.failure,
    (state: TasksState): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.editCall.request,
    (state: TasksState): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.editCall.success,
    (
      state: TasksState,
    ): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.editCall.failure,
    (state: TasksState): TasksState => ({
      ...state,
      voice: {
        ...state.voice,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadScenarioExamplesUploadings.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadScenarioExamplesUploadings.request>,
    ): TasksState => ({
      ...state,
      scenarioExamplesUploadings: {
        ...state.scenarioExamplesUploadings,
        list: payload.olderThan ?
          [...state.scenarioExamplesUploadings.list] :
          [],
        loading: true,
        total: payload.olderThan ?
          state.scenarioExamplesUploadings.total :
          0,
      },
    }),
  )
  .handleAction(
    actions.loadScenarioExamplesUploadings.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadScenarioExamplesUploadings.success>,
    ): TasksState => {
      const lastChunk = isLastChunk(payload.pagination, payload.tasks);

      const newlist = payload.pagination.olderThan ?
        state.scenarioExamplesUploadings.list.concat(payload.tasks) :
        payload.tasks;

      return {
        ...state,
        scenarioExamplesUploadings: {
          ...state.scenarioExamplesUploadings,
          list: newlist,
          loading: false,
          total: lastChunk ?
            newlist.length :
            state.scenarioExamplesUploadings.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadScenarioExamplesUploadings.failure,
    (state: TasksState): TasksState => ({
      ...state,
      scenarioExamplesUploadings: {
        ...state.scenarioExamplesUploadings,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCustomModelLearningTasks.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadCustomModelLearningTasks.request>,
    ): TasksState => ({
      ...state,
      customModelLearning: {
        ...state.customModelLearning,
        loading: true,
        total: payload.olderThan ?
          state.customModelLearning.total :
          0,
      },
    }),
  )
  .handleAction(
    actions.loadCustomModelLearningTasks.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadCustomModelLearningTasks.success>,
    ): TasksState => {
      const lastChunk = isLastChunk(payload.pagination, payload.tasks);

      const newList = payload.pagination.olderThan ?
        state.customModelLearning.list.concat(payload.tasks) :
        payload.tasks;

      return {
        ...state,
        customModelLearning: {
          ...state.customModelLearning,
          list: newList,
          loading: false,
          total: lastChunk ?
            newList.length :
            state.customModelLearning.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadCustomModelLearningTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      customModelLearning: {
        ...state.customModelLearning,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadClusteringLaunchTasks.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadClusteringLaunchTasks.request>,
    ): TasksState => ({
      ...state,
      clusteringLaunchTasks: {
        ...state.clusteringLaunchTasks,
        loading: true,
        total: payload.olderThan ?
          state.clusteringLaunchTasks.total :
          0,
      },
    }),
  )
  .handleAction(
    actions.loadClusteringLaunchTasks.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadClusteringLaunchTasks.success>,
    ): TasksState => {
      const lastChunk = isLastChunk(payload.pagination, payload.tasks);

      const newList = payload.pagination.olderThan ?
        state.clusteringLaunchTasks.list.concat(payload.tasks) :
        payload.tasks;

      return {
        ...state,
        clusteringLaunchTasks: {
          ...state.clusteringLaunchTasks,
          list: newList,
          loading: false,
          total: lastChunk ?
            newList.length :
            state.clusteringLaunchTasks.total,
        },
      };
    },
  )
  .handleAction(
    actions.loadClusteringLaunchTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      clusteringLaunchTasks: {
        ...state.clusteringLaunchTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadExportExamplesTasks.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadExportExamplesTasks.request>,
    ): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        list: payload.olderThan ?
          state.exportExamplesTasks.list :
          [],
        loading: true,
        total: payload.olderThan ?
          state.exportExamplesTasks.total :
          0,
      },
    }),
  )
  .handleAction(
    actions.loadExportExamplesTasks.success,
    (
      state: TasksState,
      { payload: { pagination, tasks } }: ActionType<
        typeof actions.loadExportExamplesTasks.success
      >,
    ): TasksState => {
      const lastChunk = isLastChunk(pagination, tasks);
      const newList = pagination.olderThan ?
        [...state.exportExamplesTasks.list, ...tasks] :
        tasks;

      return {
        ...state,
        exportExamplesTasks: {
          ...state.exportExamplesTasks,
          list: newList,
          total: lastChunk ? newList.length : 0,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadExportExamplesTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.exportExamples.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.exportExamples.success>,
    ): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        list: [payload, ...state.exportExamplesTasks.list],
      },
    }),
  )
  .handleAction(
    actions.exportRepetitiveExamples.request,
    (state: TasksState): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.exportRepetitiveExamples.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.exportRepetitiveExamples.success>,
    ): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        list: [payload, ...state.exportExamplesTasks.list],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.exportRepetitiveExamples.failure,
    (state: TasksState): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadProcessExamplesTask.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadProcessExamplesTask.request>,
    ): TasksState => ({
      ...state,
      processExamplesTasks: {
        ...state.processExamplesTasks,
        loading: true,
        list: payload.olderThan ? state.processExamplesTasks.list : [],
      },
    }),
  )
  .handleAction(
    actions.loadProcessExamplesTask.success,
    (
      state: TasksState,
      { payload: { pagination, tasks } }: ActionType<typeof actions.loadProcessExamplesTask.success>,
    ): TasksState => {
      const lastChunk = isLastChunk(pagination, tasks);
      const newList = pagination.olderThan ?
        [...state.processExamplesTasks.list, ...tasks] :
        tasks;

      return {
        ...state,
        processExamplesTasks: {
          ...state.exportExamplesTasks,
          list: newList,
          total: lastChunk ? newList.length : 0,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadProcessExamplesTask.failure,
    (
      state: TasksState,
    ): TasksState => ({
      ...state,
      processExamplesTasks: {
        ...state.processExamplesTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.processExamples.request,
    (state: TasksState): TasksState => ({
      ...state,
      processExamplesTasks: {
        ...state.processExamplesTasks,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.processExamples.success,
    (state: TasksState): TasksState => ({
      ...state,
      processExamplesTasks: {
        ...state.processExamplesTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.processExamples.failure,
    (state: TasksState): TasksState => ({
      ...state,
      processExamplesTasks: {
        ...state.processExamplesTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadAllTasks.request,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.loadAllTasks.request>,
    ): TasksState => ({
      ...state,
      allTasks: {
        ...state.allTasks,
        loading: true,
        list: payload.olderThan ? state.allTasks.list : [],
      },
    }),
  )
  .handleAction(
    actions.loadAllTasks.success,
    (
      state: TasksState,
      { payload: { pagination, tasks } }: ActionType<typeof actions.loadAllTasks.success>,
    ): TasksState => {
      const lastChunk = isLastChunk(pagination, tasks);
      const newList = pagination.olderThan ?
        [...state.allTasks.list, ...tasks] :
        tasks;

      return {
        ...state,
        allTasks: {
          ...state.allTasks,
          list: newList,
          total: lastChunk ? newList.length : 0,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadAllTasks.failure,
    (state: TasksState): TasksState => ({
      ...state,
      allTasks: {
        ...state.allTasks,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadTaskLogs.request,
    (
      state: TasksState,
      { payload: { taskId } }: ActionType<typeof actions.loadTaskLogs.request>,
    ): TasksState => {
      let meta = state.tasksMeta[taskId];

      if (!meta) {
        meta = {
          loading: true,
          loaded: false,
          logs: [],
        };
      } else {
        meta.loading = true;
      }

      return {
        ...state,
        tasksMeta: {
          ...state.tasksMeta,
          [taskId]: meta,
        },
      };
    },
  )
  .handleAction(
    actions.loadTaskLogs.success,
    (
      state: TasksState,
      { payload: { taskId, logs } }: ActionType<typeof actions.loadTaskLogs.success>,
    ): TasksState => {
      return {
        ...state,
        tasksMeta: {
          ...state.tasksMeta,
          [taskId]: {
            ...state.tasksMeta[taskId],
            loading: false,
            loaded: true,
            logs,
          },
        },
      };
    },
  )
  .handleAction(
    actions.loadTaskLogs.failure,
    (
      state: TasksState,
      { payload: { payload: { taskId } } }: ActionType<typeof actions.loadTaskLogs.failure>,
    ): TasksState => {
      return {
        ...state,
        tasksMeta: {
          ...state.tasksMeta,
          [taskId]: {
            ...state.tasksMeta[taskId],
            loading: false,
          },
        },
      };
    },
  )
  .handleAction(
    actions.searchForDirtyExamples.request,
    (state: TasksState): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.searchForDirtyExamples.success,
    (
      state: TasksState,
      { payload }: ActionType<typeof actions.searchForDirtyExamples.success>,
    ): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        list: [payload, ...state.exportExamplesTasks.list],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.searchForDirtyExamples.failure,
    (state: TasksState): TasksState => ({
      ...state,
      exportExamplesTasks: {
        ...state.exportExamplesTasks,
        loading: false,
      },
    }),
  );

// Сорирует задачи по дате создания. Свежие задачи помещаются в начало массива
function sortTasks(list: Task[]): Task[] {
  return list.sort((a, b) => +(new Date(b.created)) - +(new Date(a.created)));
}

function updateVoiceTask(
  state: TasksState,
  taskId: string,
  callback: (task: Task) => Task,
): TasksState {
  return {
    ...state,
    voice: {
      ...state.voice,
      list: state.voice.list.map(task => (task.id === taskId ? callback(task) : task)),
    },
  };
}

// TODO Можно будет удалить, как на сервере выкатится фикс
export function isLastChunk<T = Task>(pagination?: Pagination, chunk?: T[]): boolean {
  if (!pagination || !chunk) return false;

  return typeof pagination.olderThan === 'number' &&
         pagination.olderThan > 0 &&
         chunk.length === 0;
}
