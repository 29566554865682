import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { block } from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ReleaseNote, SavedReleaseNote } from '../../../types/models/ReleaseNotes';
import modalService from '../../../services/modal';
import { createReleaseNote, resetNoteToEdit, updateReleaseNote } from '../../../redux/releaseNotes/actions';

import SideModal from '../../../components-new/sideModal';
import Button from '../../../components-new/button';
import Input from '../../../components-new/input';
import MarkdownEditor from '../../../components-new/markdownEditor';
import Checkbox from '../../../components-new/checkbox';
import { getReleaseNoteToEdit } from '../../../redux/releaseNotes/selectors';

const b = block('release-notes-editor');

const Editor: FC<{}> = () => {
  const { t } = useTranslation();

  const [state, setState] = useState<ReleaseNote | null>(null);
  const [modified, setModified] = useState(false);

  const dispatch = useDispatch();

  const noteToEdit = useSelector(getReleaseNoteToEdit, shallowEqual);

  const closeForSure = useCallback(
    () => {
      dispatch(resetNoteToEdit());
    },
    [dispatch],
  );

  const handleClose = useCallback(
    () => {
      if (modified) {
        modalService.openConfirmModal({
          message: t("COMMON.CLOSE_UNSAVED_CONFIRM"),
          onConfirm: closeForSure,
        });
      } else {
        closeForSure();
      }
    },
    [closeForSure, modified, t],
  );

  const handleChange = useCallback(
    (_, { name, value, checked }) => {
      if (!state) return;

      setModified(true);
      setState(s => (s && { ...s, [name]: checked ?? value }));
    },
    [state],
  );

  const handeToggleIsActive = useCallback(
    e => {
      if (!state) return;

      setModified(true);
      setState(s => (s && { ...s, enabled: e.target.checked }));
    },
    [state],
  );

  const handleSave = useCallback(
    () => {
      if (!state) return;

      if (state.id) {
        dispatch(updateReleaseNote.request(state as SavedReleaseNote));
      } else {
        dispatch(createReleaseNote.request(state));
      }
    },
    [dispatch, state],
  );

  useEffect(
    () => {
      setState(noteToEdit);
    },
    [noteToEdit],
  );

  if (!state) return null;

  return (
    <SideModal
      open
      title={state.id ?
        t("RELEASE_NOTES.EDIT_RELEASE_ITEM") :
        t('RELEASE_NOTES.NEW_RELEASE_ITEM')}
      onClose={handleClose}
    >
      <div className={b('content')}>
        <div className={b('row')}>
          <span>{t('RELEASE_NOTES.RELEASE_NUMBER')}</span>
          <div>
            <Input
              fluid={false}
              name="title"
              value={state.title}
              placeholder="0.0.1"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={b('row')}>
          <span>{t('RELEASE_NOTES.DESCRIPTION')}</span>
          <div>
            <MarkdownEditor
              value={state.description}
              onChange={handleChange}
              name="description"
              placeholder={t('RELEASE_NOTES.DESCRIPTION_PLACEHOLDER')}
            />
          </div>
        </div>
        <div className={b('row')}>
          <span> </span>
          <div>
            <Checkbox
              name="isEnabled"
              value={state.enabled}
              label={t('RELEASE_NOTES.IS_ACTIVE')}
              onChange={handeToggleIsActive}
            />
          </div>
        </div>
      </div>
      <div className={b('action-buttons')}>
        <Button onClick={handleClose}>
          {t('COMMON.CLOSE')}
        </Button>
        {!!modified && !!state?.title && !!state?.description && (
          <Button primary onClick={handleSave}>
            {t('COMMON.SAVE')}
          </Button>
        )}
      </div>
    </SideModal>
  );
};

export default memo(Editor);
