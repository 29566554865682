import React from 'react';
import Page from '../../components/page';

const LoadingPage = () => (
  <Page>
    Loading...
  </Page>
);

export default LoadingPage;
