import { SagaIterator } from "redux-saga";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import i18n from "../../services/i18n";

import { parseNewDialog } from "../../types/parsers/DialogParser";
import { PreviousAnswer, PreviousAnswerFilters } from "../../types/models/PreviousAnswer";

import { ErrorAction } from "../reducers";
import * as actions from "./actions";
import { getPreviousAnswerFilters } from "./selectors";
import { LastAutomatedResponseNodeRequest, LastAutomatedResponseNodeResponse, PreviousAutomatedResponseNodesRequest, PreviousAutomatedResponseNodesResponse } from "../../types/requests/IterationAnalitycs";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";
import apiService, { ApiResponse } from "../../services/api";
import { NodeTypes } from "../../pages/graphEditor/types";
import { msToSeconds } from "../projects/helpers";
import { SearchRequest } from "~/api-types/backend-py3/services/supportai-history/docs/yaml/definitions";
import { SearchDialogsHistoryRequest, SearchDialogsHistoryResponse } from "../../types/requests/Dialogs";

function* loadPreviousAnswers(): SagaIterator {
  yield takeLatest(actions.loadPreviousAnswers.request, function* () {
    try {
      const filters: PreviousAnswerFilters = yield select(getPreviousAnswerFilters);

      const request: PreviousAutomatedResponseNodesRequest = {
        query: {
          limit: 10,
          offset: 0,
          scenario_slug: filters.scenario,
          start: msToSeconds(+dateLocalToMsc(filters.dateFrom)),
          end: msToSeconds(+dateLocalToMsc(filters.dateTo)),
        },
      };

      const response: ApiResponse<PreviousAutomatedResponseNodesResponse> = yield call(
        apiService.getPreviousAutomatedResponseNodes,
        request,
      );

      const list: PreviousAnswer[] = response.data.previous_nodes.map(node => ({
        id: node.node_id,
        nodeTitle: NodeTypes.response,
        nodeType: NodeTypes.response,
        totalLaunches: node.not_automated_count,
        reopenCount: 0,
        reopenPercent: 0,
      }));

      yield put(actions.loadPreviousAnswers.success(list));
    } catch (err) {
      const text = i18n.t("ERRORS.COMMON.ERROR");
      yield put(actions.loadPreviousAnswers.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadPreviousAnswerDialogs(): SagaIterator {
  yield takeLatest(actions.loadPreviousAnswerDialogs.request, function* (action) {
    try {
      const { id, offset } = action.payload;

      const filters: PreviousAnswerFilters = yield select(getPreviousAnswerFilters);

      const request: LastAutomatedResponseNodeRequest = {
        query: {
          start: msToSeconds(+dateLocalToMsc(filters.dateFrom)),
          end: msToSeconds(+dateLocalToMsc(filters.dateTo)),
          limit: 10,
          offset: offset || 0,
          node_id: id,
        },
      };

      const response: ApiResponse<LastAutomatedResponseNodeResponse> = yield call(
        apiService.getLastAutomatedResponseNode,
        request,
      );

      const chatIds = response.data.iterations.map(i => i.chat_id);

      const searchRequest: SearchRequest = {
        chat_ids: chatIds,
      };

      const dialogsRequest: SearchDialogsHistoryRequest = {
        query: {
        },
        body: searchRequest,
      };

      const dialogsResponse: ApiResponse<SearchDialogsHistoryResponse> = yield call(
        apiService.getDialogsHistoryV3,
        dialogsRequest,
      );

      const dialogs = dialogsResponse.data.dialogs.map(parseNewDialog);

      yield put(actions.loadPreviousAnswerDialogs.success(dialogs.map(d => ({
        ...d,
        answerId: id,
      }))));
    } catch (err) {
      const text = i18n.t("ERRORS.COMMON.ERROR");
      yield put(actions.loadPreviousAnswerDialogs.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* previousAnswerSagas(): SagaIterator {
  yield fork(loadPreviousAnswers);
  yield fork(loadPreviousAnswerDialogs);
}
