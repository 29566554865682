import { Reducer } from "redux";
import { createReducer, ActionType } from 'typesafe-actions';
import { CustomConfig } from "../../types/models/CustomConfig";

import * as actions from './actions';
import * as projectsActions from '../projects/actions';

type ConfigsState = {
  custom: {
    value: CustomConfig,
    loading: boolean,
  },
  fullConfiguration: {
    value: Object,
    loading: boolean,
  },
}

const initialState: ConfigsState = {
  custom: {
    value: {
      id: '',
      value: {},
    },
    loading: false,
  },
  fullConfiguration: {
    value: {},
    loading: false,
  },
};

export const configsReducer: Reducer<ConfigsState> = createReducer(initialState)
  .handleAction(
    actions.loadCustomConfig.request,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      custom: {
        ...state.custom,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadCustomConfig.success,
    (state: ConfigsState, { payload }: ActionType<typeof actions.loadCustomConfig.success>): ConfigsState => ({
      ...state,
      custom: {
        ...state.custom,
        value: {
          ...state.custom.value,
          ...payload,
        },
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadCustomConfig.failure,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      custom: {
        ...state.custom,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.updateCustomConfig.request,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      custom: {
        ...state.custom,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.updateCustomConfig.success,
    (state: ConfigsState, { payload }: ActionType<typeof actions.updateCustomConfig.success>): ConfigsState => ({
      ...state,
      custom: {
        ...state.custom,
        value: {
          ...state.custom.value,
          ...payload,
        },
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.updateCustomConfig.failure,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      custom: {
        ...state.custom,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadFullConfiguration.request,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      fullConfiguration: {
        ...state.fullConfiguration,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadFullConfiguration.success,
    (state: ConfigsState, { payload }: ActionType<typeof actions.loadFullConfiguration.success>): ConfigsState => ({
      ...state,
      fullConfiguration: {
        ...state.fullConfiguration,
        value: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadFullConfiguration.failure,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      fullConfiguration: {
        ...state.fullConfiguration,
        loading: false,
      },
    }),
  )
  .handleAction(
    projectsActions.selectProject,
    (state: ConfigsState): ConfigsState => ({
      ...state,
      fullConfiguration: {
        ...state.fullConfiguration,
        value: {},
      },
    }),
  );
