import { NotificationConfig, NotificationMode, NotificationModel, NotificationType } from "../../types/models/Notification";
import { generateId } from "../helpers/generateId";

export const notificationEventId = 'onNotification';

export function info(
  text: string,
  config: NotificationConfig = defaultConfig) {
  const data: NotificationModel = {
    id: generateId(),
    type: NotificationType.info,
    text,
    config: mergeConfigs(config, defaultInfoConfig),
  };

  dispatchEvent(new CustomEvent(notificationEventId, { detail: data }));
}

export function success(
  text: string,
  config: NotificationConfig = defaultConfig,
) {
  const data: NotificationModel = {
    id: generateId(),
    type: NotificationType.success,
    text,
    config: mergeConfigs(config, defaultSuccessConfig),
  };

  dispatchEvent(new CustomEvent(notificationEventId, { detail: data }));
}

export function error(
  text: string,
  config: NotificationConfig = defaultConfig,
) {
  const data: NotificationModel = {
    id: generateId(),
    type: NotificationType.error,
    text,
    config: mergeConfigs(config, defaultErrorConfig),
  };

  dispatchEvent(new CustomEvent(notificationEventId, { detail: data }));
}

const defaultConfig: NotificationConfig = {
  mode: NotificationMode.default,
};

const defaultErrorConfig: NotificationConfig = {
  mode: NotificationMode.default,
  durationMs: 3000,
};

const defaultInfoConfig: NotificationConfig = {
  mode: NotificationMode.default,
  durationMs: 2000,
};

const defaultSuccessConfig: NotificationConfig = {
  mode: NotificationMode.default,
  durationMs: 2000,
};

function mergeConfigs(
  primary: NotificationConfig,
  additional: NotificationConfig,
): NotificationConfig {
  return {
    ...additional,
    ...primary,
  };
}

const NotificationService = {
  info,
  success,
  error,
};

export default NotificationService;
