import React, { FC } from 'react';
import { SvgProps } from '../types';

const UserWithLaptop: FC<SvgProps> = ({
  width = 300,
  height = 300,
}) => (
  <svg width={width} height={height} viewBox="0 0 1280 1280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1280" height="1280" rx="100" fill="white" />
    <path d="M509.62 610.77C449 680.09 458.8 747.61 458.8 747.61C458.8 747.61 580.45 729.36 671.67 734.61C737.55 738.34 778.53 745 778.53 745C778.53 745 703 898.77 710.77 893.56C718.54 888.35 1044 1018.9 1106.93 891C1185.12 732 953.15 575.64 953.15 575.64C953.15 575.64 1022.33 535.58 967.03 489.64C894.9 429.69 771.56 477.91 709.03 501.37C670.81 515.63 546.11 569.07 509.62 610.77Z" fill="#FFDD40" />
    <path d="M710.34 469.07C722.231 469.07 731.87 459.431 731.87 447.54C731.87 435.649 722.231 426.01 710.34 426.01C698.449 426.01 688.81 435.649 688.81 447.54C688.81 459.431 698.449 469.07 710.34 469.07Z" fill="#FB482D" />
    <path d="M641.8 386C614.93 439.74 632.8 484.52 665.86 492.71C714.43 504.71 740.67 462.27 740.67 462.27C740.67 462.27 755.75 468.1 762.22 459.14C768.69 450.18 764.71 441.23 758.22 436.75" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M774.66 414.32C792.57 408.32 808.57 383.76 800.04 369.54C786.6 347.14 762.71 360.54 762.71 360.54C762.71 360.54 776.15 309.78 746.29 302.32C721.39 296.09 708.97 343 717.85 351.88C726.73 360.76 744.79 335.15 701.51 291.88C650.75 241.12 578.51 298.15 626.87 341.14C653.74 365.03 671.65 353.08 641.8 342.63C611.95 332.18 591 356.1 598.51 378.49C609.7 412.07 671.65 396.41 694.05 382.97C707.22 375.06 678.38 383.07 688.05 396.41C700 412.83 729.85 393.41 729.85 393.41" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M670.93 397.57L656.99 435.61L674.74 441.95" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M673.47 467.31C693.76 471.11 705.17 460.97 708.97 454.63" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M760.29 436.15C927.09 382.72 989.36 510.89 863.16 551.53C736.96 592.17 668.52 551.53 743.38 542.97C818.24 534.41 1045.68 608.57 1082.76 825.31C1106.56 964.45 911.65 899.46 911.65 899.46" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M206.87 788.85C213.25 769.7 503.48 766.66 516.75 774.96C530.02 783.26 579.85 950.58 579.85 950.58L747.25 959.94C747.25 959.94 759.87 1001.41 710.1 1001.41H295.17C275.26 1001.41 196.92 818.71 206.87 788.85Z" fill="#E7EBEC" />
    <path d="M231.87 806.22H556.12L603.89 958.48" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M637.76 981.51H767.16C767.16 981.51 747.25 1008.05 722.37 1008.05H402.12" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M423.602 925.207C435.968 916.698 437.335 897.215 426.653 881.691C415.972 866.167 397.287 860.48 384.921 868.989C372.554 877.497 371.188 896.98 381.87 912.504C392.551 928.028 411.235 933.716 423.602 925.207Z" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M292.08 455.32C292.08 455.32 255.89 366.32 236.46 343.85C217.03 321.38 205.31 351.85 219.86 394.85C234.41 437.85 247.11 494.21 257.09 501.05C267.07 507.89 234.94 429.05 216.27 445.57C197.6 462.09 215.68 524.01 231.43 538.48C247.18 552.95 243.43 521.01 229.89 495.25C216.35 469.49 192.22 456.59 186.55 472.87C180.88 489.15 185.06 523.2 199.5 542.26C213.94 561.32 207.66 527.68 197.43 507.83C187.2 487.98 165.13 474.7 159.33 501.89C153.53 529.08 188.49 601.69 283.49 682.69" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M320.28 513.15C320.28 513.15 312.28 551.65 285.28 558.85C258.28 566.05 273.48 530.59 286.51 495.52C299.54 460.45 319 448.11 335 475.59C346.17 494.75 361.75 536.1 392.58 631.38C423.95 728.38 435.36 779.68 415.39 788.24C395.42 796.8 381.03 757.24 429.65 685.57C483.84 605.72 545.91 563.49 639.07 538.45C639.07 538.45 644.28 574.28 690.55 578.19" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M905.94 765.42C794.72 714.09 627.05 715.42 618.49 729.65C609.93 743.88 640.71 751.16 669.23 759.72" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M700.6 751.16C700.6 751.16 609.34 805.35 589.38 836.72C569.42 868.09 603.64 865.24 629.31 850.98C654.98 836.72 700.6 805.35 706.31 793.98C712.02 782.61 681.71 793.63 664.6 821.43C647.49 849.23 692.41 843.89 692.41 843.89C708.41 841.75 721.28 826.78 721.28 826.78C721.28 826.78 695.61 840.68 697.75 855.65C699.89 870.62 750.16 847.1 750.16 847.1C750.16 847.1 729.84 857.79 737.32 868.49C744.8 879.19 768.32 861 801.49 863.14" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
    <path d="M645.61 578.85C645.61 578.85 678.19 539.75 671.67 494.79" stroke="#231F20" strokeWidth="6" strokeLinejoin="round" />
  </svg>
);

export default UserWithLaptop;
