import { ScenariosWayPoint } from "../../../../types/models/Dialog";

export type ScenarioData = Pick<ScenariosWayPoint, 'scenarioId' | 'scenarioSlug' | 'scenarioTitle'>;
export type NodeData = Pick<ScenariosWayPoint, 'nodeId' | 'nodeTitle' | 'nodeType' | 'withError' | 'metric'>;
export type PhantomNode = Pick<ScenariosWayPoint, 'nodeTitle' | 'metric'> & { type: StepType.phantom };
export type ScenarioStep = ScenarioData & { nodes: NodeData[]; } & { type: StepType.step } & Pick<ScenariosWayPoint, 'metric'>;

export enum StepType {
  step = "step",
  phantom = "phantom",
}

export type GroupedSteps = ScenarioStep | PhantomNode;
