import React, { FC } from 'react';
import { SvgProps } from '../types';

const ChevronRightThinIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7.5L14.5 12L10 16.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default ChevronRightThinIcon;
