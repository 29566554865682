import { createAsyncAction } from "typesafe-actions";
import { ChangeHistorySearchFilter, Event } from "../../types/models/Event";
import { ErrorAction } from "../reducers";

export const loadChangeHistory = createAsyncAction(
  'changeHistory/load/requested',
  'changeHistory/load/succeeded',
  'changeHistory/load/failed',
)<
  { filters?: ChangeHistorySearchFilter, offset?: number },
  { offset: number, events: Event[] },
  ErrorAction
>();
