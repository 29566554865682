import { bodyAllowed } from "../../pages/integrations/helpers";
import { generateId } from "../../services/helpers/generateId";
import { deepClone } from "../../services/helpers/utilities";
import { BodyFormat, DEFAULT_BODY_FORMAT, IntegrationFileFieldBackend } from "../backendModels/IntegrationsBackend";
import { FeatureShort } from "./Feature";
import { getEmptyProject } from "./Project";

export type Integration = {
  id?: IntegrationId;
  isActive: boolean;
  title: string;
  description?: string;
  projectSlug: string;
  inputFeatures: string[];
  outputFeatures: string[];
  apiParameters: ApiParameter[];
  integrationType: IntegrationType;
  apiRequest: ApiRequest;
  isTemp?: boolean; // Legacy
  labels: IntegrationLabel[];
}

export type ApiRequest = HttpRequest | GRPCRequest;

export type HttpRequest = {
  url: string;
  method: HttpRequestType;
  protocol: IntegrationProtocol.HTTP;
  authorization: IntegrationHttpAuth;
  queryParams?: KeyValue[];
  headers?: KeyValue[];
  body?: string | KeyValue[];
  responseMapping?: KeyValue[];
  timeousS?: number;
  retries?: number;
  backoffFactor?: number;
  bodyFormat?: BodyFormat;
  file?: IntegrationFileField;
}

export type GRPCRequest = {
  protocol: IntegrationProtocol.GRPC;
  requestType: GRPCRequestType.unary;
  authorization: IntegrationGRPCAuth;
  url: string;
  service: string;
  method: string;
  stubInfo: ReflectionStub | DescriptorStub;
  metadata?: Array<KeyValue>;
  body: string;
  responseMapping?: Array<KeyValue>;
}

export type ApiParameter = {
  id: string;
  title: string;
  type: string;
  slug: string;
  examples?: string[];
}

export type IntegrationHttpAuth = BasicAuth | TokenAuth | TvmAuth | NoAuth;
export type IntegrationGRPCAuth = TvmAuth | NoAuth;

export type NoAuthorization = {
  authorizationType: "no_auth";
}

export type BasicAuth = {
  login: string;
  password: string;
  encoding: string;
  type: AuthorizationType.basic;
}

export type TokenAuth = {
  header: string;
  token: string;
  type: AuthorizationType.token;
}

export type TvmAuth = {
  dstServiceName?: string;
  userOauthToken?: string;
  type: AuthorizationType.tvm;
}

export type NoAuth = {
  type: AuthorizationType.noAuth;
}

export type KeyValue = {
  id: string;
  key: string;
  value: string;
  source?: ExtractOperator;
}

export enum ExtractOperator {
  body = "body",
  header = "headers",
}

export type ReflectionStub = {
  source: StubType.reflection;
}

export type DescriptorStub = {
  source: StubType.descriptor;
  file: string;
}

export type BodyKey = {
  [key: string]: (string | IntegrationFileFieldBackend)[];
}

export type IntegrationFileField = {
  fileDownloadUrl?: string;
  contentType?: string;
  filename?: string;
  fieldName?: string;
  fileAuth?: IntegrationFileAuthorization;
}

export type IntegrationFileAuthorization = TvmAuth | TokenAuth | undefined

export enum AuthorizationType {
  basic = "basic",
  token = "api_token",
  noAuth = "no_auth",
  tvm = "tvm",
}

export enum IntegrationProtocol {
  HTTP = "http",
  GRPC = "grpc",
}

export enum GRPCRequestType {
  unary = "unary_unary"
}

export enum StubType {
  reflection = "reflection",
  descriptor = "descriptor",
}

export enum HttpRequestType {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD'
}

export enum IntegrationType {
  request = "request",
  change = "change"
}

export type IntegrationId = string;

export type IntegrationTest = {
  state: {
    features: Array<FeatureShort>;
  },
  debugInfo?: string;
}

export type IntegrationLabel = {
  id: string;
  title: string;
  slug?: string;
}

export function getEmptyIntegration(): Integration {
  return {
    id: generateId(),
    isActive: true,
    title: '',
    projectSlug: getEmptyProject().slug,
    inputFeatures: [],
    outputFeatures: [],
    apiParameters: [],
    apiRequest: getEmptyHttpRequest(),
    integrationType: IntegrationType.request,
    labels: [],
  };
}

export function getEmptyHttpRequest(): HttpRequest {
  return {
    url: '',
    method: HttpRequestType.GET,
    authorization: getDefaultAuthorization(),
    protocol: IntegrationProtocol.HTTP,
  };
}

export function getEmptyGRPCRequest(): GRPCRequest {
  return {
    protocol: IntegrationProtocol.GRPC,
    requestType: GRPCRequestType.unary,
    authorization: getNoAuth(),
    url: "",
    service: "",
    method: "",
    stubInfo: getReflectionStub(),
    body: "",
  };
}

export function getNoAuth(): NoAuth {
  return {
    type: AuthorizationType.noAuth,
  };
}

export function getTvmAuth(): TvmAuth {
  return {
    type: AuthorizationType.tvm,
    dstServiceName: "",
    userOauthToken: "",
  };
}

export function getTokenAuth(): TokenAuth {
  return {
    type: AuthorizationType.token,
    header: "",
    token: "",
  };
}

export function getReflectionStub(): ReflectionStub {
  return {
    source: StubType.reflection,
  };
}

export function getEmptyApiParameter(): ApiParameter {
  return {
    id: generateId(),
    title: '',
    type: '',
    slug: '',
    examples: [],
  };
}

export function getEmptyIntegrationFileField(): IntegrationFileField {
  return {
    fileDownloadUrl: "",
    contentType: "",
    filename: "",
    fieldName: "",
  };
}

export function getLastMessageFileField(): IntegrationFileField {
  return {
    fileDownloadUrl: "{{last_user_files_urls[-1]}}",
    contentType: "{{last_user_files_content_types[-1]}}",
  };
}

export function getDefaultAuthorization(): BasicAuth {
  return {
    login: '',
    password: '',
    encoding: 'latin1',
    type: AuthorizationType.basic,
  };
}

export function getEmptyKeyValue(): KeyValue {
  return {
    id: generateId(),
    key: '',
    value: '',
  };
}

export function getEmptyReflectionStub(): ReflectionStub {
  return {
    source: StubType.reflection,
  };
}

export function getEmptyDescriptorStub(): DescriptorStub {
  return {
    source: StubType.descriptor,
    file: "",
  };
}

export function isKeyValueValid(pair: KeyValue): boolean {
  return !!pair.key || (!pair.key && !pair.value);
}

export function isKeyValueEmpty(pair: KeyValue): boolean {
  return !pair.key && !pair.value;
}

export function changeIntegrationProtocol(
  integration: Integration,
  newProtocol: IntegrationProtocol,
): Integration {
  switch (newProtocol) {
    case IntegrationProtocol.GRPC:
      const emptyGrpcRequest = getEmptyGRPCRequest();
      return {
        ...integration,
        apiRequest: emptyGrpcRequest,
      };
    case IntegrationProtocol.HTTP:
    default:
      const emptyHttpRequest = getEmptyHttpRequest();
      return {
        ...integration,
        apiRequest: emptyHttpRequest,
      };
  }
}

export function changeIntegrationAuth(
  integration: Integration,
  newAuth: AuthorizationType,
): Integration {
  switch (newAuth) {
    case AuthorizationType.basic:
      if (isGRPCIntegration(integration)) return integration;

      const basicAuth: BasicAuth = {
        login: '',
        password: '',
        encoding: 'latin1',
        type: AuthorizationType.basic,
      };
      return {
        ...integration,
        apiRequest: {
          ...integration.apiRequest,
          authorization: basicAuth,
        } as HttpRequest,
      };
    case AuthorizationType.token:
      if (isGRPCIntegration(integration)) return integration;

      const tokenAuth: TokenAuth = {
        header: '',
        token: '',
        type: AuthorizationType.token,
      };
      return {
        ...integration,
        apiRequest: {
          ...integration.apiRequest,
          authorization: tokenAuth,
        } as HttpRequest,
      };
    case AuthorizationType.tvm:
      const tvmAuth: TvmAuth = {
        dstServiceName: '',
        userOauthToken: '',
        type: AuthorizationType.tvm,
      };
      return {
        ...integration,
        apiRequest: {
          ...integration.apiRequest,
          authorization: tvmAuth,
        },
      };
    case AuthorizationType.noAuth:
    default:
      const noAuth: NoAuth = {
        type: AuthorizationType.noAuth,
      };
      return {
        ...integration,
        apiRequest: {
          ...integration.apiRequest,
          authorization: noAuth,
        },
      };
  }
}

export function changeHttpRequestMethod(
  value: HttpRequest,
  method: HttpRequestType,
): HttpRequest {
  value = deepClone(value) as HttpRequest;

  value.method = method;

  if (!bodyAllowed(method)) {
    const { body, ...rest } = value;
    return rest;
  }

  if (!value.bodyFormat) {
    value.bodyFormat = DEFAULT_BODY_FORMAT;
  }

  if (method === HttpRequestType.GET) {
    value.body = undefined;
  }

  return value;
}

export function changeGRPCRequestStubType(
  value: GRPCRequest,
  newStubType: StubType,
): GRPCRequest {
  switch (newStubType) {
    case StubType.descriptor:
      return {
        ...value,
        stubInfo: getEmptyDescriptorStub(),
      };
    case StubType.reflection:
    default:
      return {
        ...value,
        stubInfo: getEmptyReflectionStub(),
      };
  }
}

export function isGRPCIntegration(integration: Integration) {
  return integration.apiRequest.protocol === IntegrationProtocol.GRPC;
}

export function isHttpIntegration(integration: Integration) {
  return integration.apiRequest.protocol === IntegrationProtocol.HTTP;
}

export function isKeyValueListValid(list: Array<KeyValue>) {
  if (list.length === 0) return true;

  return list.every(isKeyValueValid);
}

export function isIntegrationValid(
  integration: Integration,
): boolean {
  if (!integration.title) return false;

  if (isHttpIntegration(integration)) {
    if (!isHttpRequestValid(integration.apiRequest as HttpRequest)) return false;
  } else if (isGRPCIntegration(integration)) {
    if (!isGRPCRequestValid(integration.apiRequest as GRPCRequest)) return false;
  }

  return true;
}

export function isHttpRequestValid(value: HttpRequest): boolean {
  if (!value.url) return false;
  if (!value.method) return false;
  if (!isAuthorizationValid(value.authorization)) return false;
  if (Array.isArray(value.queryParams) && !isKeyValueListValid(value.queryParams)) return false;
  if (Array.isArray(value.headers) && !isKeyValueListValid(value.headers)) return false;
  if (Array.isArray(value.responseMapping) && !isKeyValueListValid(value.responseMapping)) return false;

  if (value.file && value.file.fileAuth) {
    const authValid = isAuthorizationValid(value.file.fileAuth);

    if (!authValid) return false;
  }

  return true;
}

export function isGRPCRequestValid(value: GRPCRequest): boolean {
  if (!value.url) return false;
  if (!value.service) return false;
  if (!value.method) return false;
  if (!value.body) return false;
  if (!isAuthorizationValid(value.authorization)) return false;
  if (!isStubInfoValid(value.stubInfo)) return false;
  if (Array.isArray(value.metadata) && !isKeyValueListValid(value.metadata)) return false;
  if (Array.isArray(value.responseMapping && !isKeyValueListValid(value.responseMapping))) return false;

  return true;
}

export function isStubInfoValid(value: ReflectionStub | DescriptorStub) {
  if (value.source === StubType.descriptor) {
    return !!value.file;
  }

  return true;
}

export function isAuthorizationValid(
  value: IntegrationHttpAuth | IntegrationGRPCAuth,
): boolean {
  const type = value.type;
  switch (type) {
    case AuthorizationType.basic:
      value = value as BasicAuth;
      return !!value.encoding && !!value.login && !!value.password;
    case AuthorizationType.token:
      value = value as TokenAuth;
      return !!value.header && !!value.token;
    case AuthorizationType.tvm:
      value = value as TvmAuth;
      return !!value.dstServiceName?.trim() || !!value.userOauthToken?.trim();
    default:
      return true;
  }
}

export function getEmptyIntegrationFileAuthorization(): IntegrationFileAuthorization {
  return {
    type: AuthorizationType.tvm,
    dstServiceName: "",
    userOauthToken: "",
  };
}
