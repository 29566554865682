import { ActionType, createReducer } from "typesafe-actions";
import { Reducer } from "redux";
import { subDays } from "date-fns";

import * as actions from "./actions";
import { AutomationRiseFilters, IterationAnalyticsDataForProject, IterationDialog } from "../../types/models/IterationAnalyticsData";

type RiseOfAutomationState = {
  filters: AutomationRiseFilters;
  analyticsData: {
    data: IterationAnalyticsDataForProject;
    loading: boolean;
  };
  iterations: {
    value: IterationDialog[];
    loading: boolean;
  };
};

const defaultFilters: AutomationRiseFilters = {
  dateFrom: subDays(new Date(), 7),
  dateTo: new Date(),
  scenarios: [],
  periodType: "days",
  iterations: [],
};

const initialState: RiseOfAutomationState = {
  analyticsData: {
    data: {
      grouping: {
        periods: [],
        scenarios: [],
      },
      automatedCount: 0,
      notAutomatedCount: 0,
      totalCount: 0,
    },
    loading: false,
  },
  iterations: {
    value: [],
    loading: false,
  },
  filters: defaultFilters,
};

export const riseOfAutomationReducer: Reducer<RiseOfAutomationState> = createReducer(initialState)
  .handleAction(
    actions.loadIterationAnalyticsData.request,
    (
      state: RiseOfAutomationState,
    ): RiseOfAutomationState => ({
      ...state,
      analyticsData: {
        ...state.analyticsData,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadIterationAnalyticsData.success,
    (
      state: RiseOfAutomationState,
      { payload }: ActionType<typeof actions.loadIterationAnalyticsData.success>,
    ): RiseOfAutomationState => ({
      ...state,
      analyticsData: {
        ...state.analyticsData,
        data: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadIterationAnalyticsData.failure,
    (
      state: RiseOfAutomationState,
    ): RiseOfAutomationState => ({
      ...state,
      analyticsData: {
        ...state.analyticsData,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadNotAutomatedDialogs.request,
    (
      state: RiseOfAutomationState,
    ): RiseOfAutomationState => ({
      ...state,
      iterations: {
        ...state.iterations,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadNotAutomatedDialogs.success,
    (
      state: RiseOfAutomationState,
      { payload }: ActionType<typeof actions.loadNotAutomatedDialogs.success>,
    ): RiseOfAutomationState => ({
      ...state,
      iterations: {
        ...state.iterations,
        value: payload.offset ? [...state.iterations.value, ...payload.dialogs] : payload.dialogs,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadNotAutomatedDialogs.failure,
    (
      state: RiseOfAutomationState,
    ): RiseOfAutomationState => ({
      ...state,
      iterations: {
        ...state.iterations,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.setFilters,
    (
      state: RiseOfAutomationState,
      { payload }: ActionType<typeof actions.setFilters>,
    ): RiseOfAutomationState => ({
      ...state,
      filters: payload,
    }),
  )
  .handleAction(
    actions.resetFilters,
    (state: RiseOfAutomationState): RiseOfAutomationState => ({
      ...state,
      filters: defaultFilters,
    }),
  );
