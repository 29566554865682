import React, {
  ReactElement, Suspense, useEffect, useState,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router';
import { connect } from 'react-redux';

import { Project, isVoiceProject, projectEmpty } from '../types/models/Project';

import accessService, { Access, EVENT_ACCESS_CHANGED } from '../services/access';
import lazyWithReload from '../services/helpers/lazyWithReload';

import { AppState } from '../redux/reducers';
import * as projectActions from '../redux/projects/actions';

import authorziedRoutes from '../routes/authorized';

import { useEnv } from '../hooks/useEnv';

import LoadingPage from '../pages/loading';
import NoProjectsPage from '../pages/noProjects';

import ProjectPageWrapper from '../components-new/projectPageWrapper';
import WindowPlug from '../components-new/windowPlug';

import NotificationContainer from '../services/notifications/components/container';

import { useUserConfig } from '../hooks/useUserConfigs';

import Sidebar from '../components/sidebar';
import ReleaseNotes from '../components/releaseNotes';
import ErrorBoundary from '../components/errorBoundary';

import { refreshUser } from './helpers';

import './styles.scss';

const AccountPage = lazyWithReload(() => import('../pages/account'));
const FeaturesPage = lazyWithReload(() => import('../pages/features'));
const TresholdsPage = lazyWithReload(() => import('../pages/tresholds'));
const NewDialogsHistoryPage = lazyWithReload(() => import('../pages/dialogsHistoryNew'));
const dialogsHistory = lazyWithReload(() => import('../pages/dialogsHistory-v3.0'));
const VoicePage = lazyWithReload(() => import('../pages/voice'));
const VoiceDetailsPage = lazyWithReload(() => import('../pages/voiceDetails'));
const AccessesPageNew = lazyWithReload(() => import('../pages/accessesNew'));
const AdditionalPage = lazyWithReload(() => import('../pages/additional'));
const ChangeHistoryPage = lazyWithReload(() => import('../pages/changeHistory'));
const ManageVersionsPage = lazyWithReload(() => import('../pages/manageVersions'));
const EntitiesPage = lazyWithReload(() => import('../pages/entities'));
const GraphEditorPage = lazyWithReload(() => import('../pages/graphEditor'));
const ProjectsListPage = lazyWithReload(() => import('../pages/projectsList'));
const SuperUsersPage = lazyWithReload(() => import('../pages/superusers'));
const IntegrationsPage = lazyWithReload(() => import('../pages/integrations'));
const ScenarioGraphs = lazyWithReload(() => import('../pages/scenarioGraphs'));
const WidgetPanelPage = lazyWithReload(() => import('../pages/widgetPanel'));
const AuthProvidersPage = lazyWithReload(() => import('../pages/authProviders'));
const QualityControlPage = lazyWithReload(() => import('../pages/qualityControl'));
const TasksPage = lazyWithReload(() => import('../pages/tasks'));
const AutomationRisePage = lazyWithReload(() => import('../pages/automationRise'));

const AuthorizedLayout = ({
  projects,
  selectedProject,
  loadProjects,
}: ReduxProps): ReactElement => {
  const [accessLoading, setAccessLoading] = useState<boolean>(true);

  const showOldDialogsHistory = useEnv("REACT_APP_SHOW_OLD_DIALOGS_HISTORY");
  const automationRiseConfig = useUserConfig("supportai-frontend:rise-of-automation");

  useEffect(() => {
    if (projects.list.length === 0 && !projects.loading) {
      refreshUser().then(() => {
        loadProjects();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = () => {
      setAccessLoading(false);
    };
    window.addEventListener(EVENT_ACCESS_CHANGED, handler);

    return () => window.removeEventListener(EVENT_ACCESS_CHANGED, handler);
  }, []);

  if (projects.list.length === 0 && !projects.loading) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <NoProjectsPage />
      </Suspense>
    );
  }

  if (accessLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="layout">
      <Sidebar />
      <ReleaseNotes />
      <NotificationContainer />
      <Suspense fallback={<LoadingPage />}>
        <ErrorBoundary>
          {!projectEmpty(selectedProject) &&
          (
          <ProjectPageWrapper>
            <Switch>
              <Route exact path="/"><Redirect to={authorziedRoutes.account} /></Route>
              { accessService.currentUserHasAccessTo(Access.statistics) && (
                <Route exact path={authorziedRoutes.account} component={AccountPage} />
              )}
              { accessService.currentUserHasAccessTo(Access.featuresFeature) && (
                <Route exact path={authorziedRoutes.features} component={FeaturesPage} />
              )}
              { accessService.currentUserHasAccessTo(Access.models) && (
                <Route exact path={authorziedRoutes.tresholds} component={TresholdsPage} />
              )}
              { accessService.currentUserHasAccessTo(Access.dialogsHistory) &&
                showOldDialogsHistory &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.newDialogsHistory}
                    component={NewDialogsHistoryPage}
                  />
                )
              }
              {
                accessService.currentUserHasAccessTo(Access.dialogsHistory) &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.dialogsHistory}
                    component={dialogsHistory}
                  />
                )
              }
              { isVoiceProject(selectedProject) &&
                accessService.currentUserHasAccessTo(Access.voice) &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.voiceDetails}
                    component={VoiceDetailsPage}
                  />
                ) }
              { isVoiceProject(selectedProject) &&
                accessService.currentUserHasAccessTo(Access.voice) &&
                <Route exact path={authorziedRoutes.voice} component={VoicePage} /> }
              { accessService.currentUserHasAccessTo(Access.accessesUsers) &&
                <Route exact path={authorziedRoutes.accessesNew} component={AccessesPageNew} /> }
              { accessService.currentUserHasAccessTo(Access.configCustom) &&
                <Route exact path={authorziedRoutes.additional} component={AdditionalPage} /> }
              { accessService.currentUserHasAccessTo(Access.changes) &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.changeHistory}
                    component={ChangeHistoryPage}
                  />
                ) }
              { accessService.currentUserHasAccessTo(Access.models) && (
                <Route
                  exact
                  path={authorziedRoutes.manageVersions}
                  component={ManageVersionsPage}
                />
              )}
              { accessService.currentUserHasAccessTo(Access.featuresEntity) && (
                <Route exact path={authorziedRoutes.entities} component={EntitiesPage} />
              )}
              { accessService.currentUserHasAccessTo(Access.scenarios) && (
                <Route exact path={authorziedRoutes.editor} component={GraphEditorPage} />
              )}
              { accessService.currentUserHasAccessTo(Access.scenarios) && (
                <Route exact path={authorziedRoutes.graphSelect} component={ScenarioGraphs} />
              )}
              { accessService.checkIfCurrentUserSuperadmin() &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.projectsList}
                    component={ProjectsListPage}
                  />
                )}
              { accessService.checkIfCurrentUserSuperadmin() &&
                <Route exact path={authorziedRoutes.superusers} component={SuperUsersPage} />}
              { accessService.currentUserHasAccessTo(Access.integrationsOutgoing) && (
                <Route
                  exact
                  path={authorziedRoutes.integrations}
                  component={IntegrationsPage}
                />
              )}
              { accessService.currentUserHasAccessTo(Access.widget) &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.widgetPanel}
                    component={WidgetPanelPage}
                  />
                )}
              {/* { accessService.checkIfCurrentUserSuperadmin() &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.billing}
                    component={BillingPage}
                  />
                )
              } */}
              { accessService.checkIfCurrentUserSuperadmin() &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.authProviders}
                    component={AuthProvidersPage}
                  />
                )
              }
              {automationRiseConfig?.enabled && (
                <Route
                  exact
                  path={authorziedRoutes.automationRise}
                  component={AutomationRisePage}
                />
              )}
              {
                accessService.currentUserHasAccessTo(Access.dialogsMarkup) &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.qualityControl}
                    component={QualityControlPage}
                  />
                )
              }
              {
                accessService.checkIfCurrentUserSuperadmin() &&
                (
                  <Route
                    exact
                    path={authorziedRoutes.tasks}
                    component={TasksPage}
                  />
                )
              }
              <Route path="*"><Redirect to={authorziedRoutes.account} /></Route>
            </Switch>
          </ProjectPageWrapper>
          )}
        </ErrorBoundary>
        <WindowPlug />
      </Suspense>
    </div>
  );
};

type MapStateToProps = {
  selectedProject: Project,
  projects: {
    list: Project[],
    loading: boolean,
  },
}

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedProject: state.projects.selectedProject,
  projects: state.projects,
});

type MapDispatchToProps = {
  loadProjects: typeof projectActions.loadProjects.request,
}

const mapDispatchToProps: MapDispatchToProps = {
  loadProjects: projectActions.loadProjects.request,
};

type ReduxProps = MapStateToProps & MapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedLayout);
