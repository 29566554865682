import React, { FC, memo, useCallback, useMemo } from 'react';
import { block } from 'bem-cn';

import PopupWithTrigger from '../../popupWithTrigger';
import Icon from '../../icon';

import { CustomSortingCellData, SortingConfig, Sorting } from '../types';
import Button from '../../button';
import SortSign from '../../sortSign';
import { SortDirection } from '../../../types/models/Sorting';
import { preventDefaultEvent } from '../../../services/helpers/utilities';
import { getNextSimpleSorting } from '../helpers';

type Props = {
  headerCellClassName: string;
  column: CustomSortingCellData;
  sortingId?: string;
  onSortingChange: (sorting: Sorting | undefined) => void;
};

export const b = block('nd-table-sorting-options-list');

const CustomSortingHeaderCell: FC<Props> = ({
  column,
  headerCellClassName,
  sortingId,
  onSortingChange,
}) => {
  const sorting = column.availableSortings.find(s => s.id === sortingId);

  const simpleSorting = useMemo(
    () => (
      column.availableSortings.length === 2 &&
      column.availableSortings.every(s => !s.label)
    ),
    [column.availableSortings],
  );

  const handleSortClick = useCallback(
    (item: SortingConfig) => () => {
      if (item.id === sortingId) {
        onSortingChange(undefined);
      } else {
        onSortingChange({
          column: column.id,
          sorting: item.id,
        });
      }

      // Полукостыль, чтобы закрыть material popup
      document.body.click();
    },
    [column.id, onSortingChange, sortingId],
  );

  const handleSimpleSortingClick = useCallback(
    () => {
      const nextSorting = getNextSimpleSorting(column.availableSortings, column.id, column.activeSorting);

      onSortingChange(nextSorting);
    },
    [
      column.activeSorting,
      column.availableSortings,
      column.id,
      onSortingChange,
    ],
  );

  return (
    <PopupWithTrigger
      placement="bottom-start"
      disableHoverListener
      disableClickListener={simpleSorting}
      buttonProps={{
        children: (
          <>
            {column.renderer(column.title)}
            <span className={b("sort-button-wrapper")}>
              <SortSign direction={sorting?.direction || SortDirection.DEFAULT} />
            </span>
          </>
        ),
        simple: true,
        contrast: true,
        className: headerCellClassName,
      }}
      onTriggerClick={simpleSorting ? handleSimpleSortingClick : undefined}
    >
      <ul onMouseDown={preventDefaultEvent} className={b()}>
        {column.availableSortings.map(item => {
          const active = item.id === sortingId;

          return (
            <li className={b('item')} key={item.id}>
              <Button
                simple
                contrast
                className={b('button', { active })}
                onClick={handleSortClick(item)}
              >
                {item.label}
                <span>
                  {active && (
                    <Icon type="checkmarkSmall" />
                  )}
                </span>
              </Button>
            </li>
          );
        })}
      </ul>
    </PopupWithTrigger>
  );
};

export default memo(CustomSortingHeaderCell);
