import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { getEmptyProjectVoiceConfig, ProjectVoice } from '../../types/models/ProjectVoice';

import {
  loadProjectVoiceConfig,
  openProjectVoiceModal,
  closeProjectVoiceModal,
  updateProjectVoiceConfig,
} from './actions';

type ProjectVoiceConfigState = {
  loading: boolean;
  config: ProjectVoice;
  updatingConfig: boolean;
  modalOpened: boolean;
}

const initialState: ProjectVoiceConfigState = {
  loading: false,
  config: getEmptyProjectVoiceConfig(),
  updatingConfig: false,
  modalOpened: false,
};

export const projectVoiceConfigReducer: Reducer<ProjectVoiceConfigState> = createReducer<ProjectVoiceConfigState>(
  initialState,
)
  .handleAction(
    loadProjectVoiceConfig.request,
    (state: ProjectVoiceConfigState): ProjectVoiceConfigState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    loadProjectVoiceConfig.success,
    (
      state: ProjectVoiceConfigState,
      { payload }: ActionType<typeof loadProjectVoiceConfig.success>,
    ): ProjectVoiceConfigState => ({
      ...state,
      loading: false,
      config: payload,
    }),
  )
  .handleAction(
    loadProjectVoiceConfig.failure,
    (state: ProjectVoiceConfigState): ProjectVoiceConfigState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    openProjectVoiceModal,
    (state: ProjectVoiceConfigState): ProjectVoiceConfigState => ({
      ...state,
      modalOpened: true,
    }),
  )
  .handleAction(
    closeProjectVoiceModal,
    (state: ProjectVoiceConfigState): ProjectVoiceConfigState => ({
      ...state,
      modalOpened: false,
    }),
  )
  .handleAction(
    updateProjectVoiceConfig.request,
    (state: ProjectVoiceConfigState): ProjectVoiceConfigState => ({
      ...state,
      updatingConfig: true,
    }),
  )
  .handleAction(
    updateProjectVoiceConfig.success,
    (
      state: ProjectVoiceConfigState,
      { payload }: ActionType<typeof updateProjectVoiceConfig.success>,
    ): ProjectVoiceConfigState => ({
      ...state,
      updatingConfig: false,
      config: payload,
    }),
  )
  .handleAction(
    updateProjectVoiceConfig.failure,
    (state: ProjectVoiceConfigState): ProjectVoiceConfigState => ({
      ...state,
      updatingConfig: false,
    }),
  );
