import React from 'react';
import { SvgProps } from '../types';

export default function TableIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.5C2.34315 3.5 1 4.84315 1 6.5V17.5C1 19.1569 2.34315 20.5 4 20.5H20C21.6569 20.5 23 19.1569 23 17.5V6.5C23 4.84315 21.6569 3.5 20 3.5H4ZM3 6.5C3 5.94772 3.44772 5.5 4 5.5H11V8.5H3V6.5ZM11 10.5H3V13.5H11V10.5ZM13 13.5V10.5H21V13.5H13ZM11 15.5H3V17.5C3 18.0523 3.44772 18.5 4 18.5H11V15.5ZM13 18.5V15.5H21V17.5C21 18.0523 20.5523 18.5 20 18.5H13ZM13 8.5V5.5H20C20.5523 5.5 21 5.94772 21 6.5V8.5H13Z"
        fill={color}
      />
    </svg>
  );
}
