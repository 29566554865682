import { ProjectUser } from "./ProjectUser";

import i18n from '../../services/i18n';

export enum StatsType {
  dialogs = 'dialogs',
  callsGrouped = 'calls_grouped',
  callsSeparated = 'calls_separated',
}

export type Task = {
  id: string,
  projectSlug: string,
  type: TaskType,
  status: TaskStatus,
  created: string, // format: date-time
  ended?: string, // format: date-time
  fileId?: string,
  s3FileId?: string,
  stqId?: string,
  errorMessage?: string,
  progress?: number,
  scheduledOn?: string,
  // features удалились с переходом на Tasks v2
  params?: Record<string, unknown>
    & Partial<{ errors: { rowNumber: string, error: string }[] }>,
  name?: string,
  user?: ProjectUser,
  cancelling?: boolean,
  processingState?: TaskProcessingState,
  description?: string; // Сейчас используется как имя таски, отображаемое пользователю
}

export enum TaskStatus {
  created = 'created',
  processing = 'processing',
  completed = 'completed',
  error = 'error',
}

export enum TaskProcessingState {
  started = 'started',
  processing = 'processing',
  error = 'error',
}

export enum TaskType {
  validation = 'validation',
  export = 'export',
  import = 'import',
  callResults = 'outgoing_calls_results',
  callInit = 'outgoing_calls_init',
  demoStatsImport = 'demo_stats_import',
  demoDialogsImport = 'demo_dialogs_import',
  exportPersonalAccountOverview = 'export_personal_account_overview',
  exportDialogHistoryContext = 'export_dialog_history_context',
  exportVoximplantResourcesUsage = 'export_voximplant_resources_usage',
  learningModel = 'local_learn',
  clustering = 'clustering',
  excelUploadMarkup = 'markup_excel_upload_markup_scenarios',
  uploadMarkup = 'markup_upload_markup_scenarios',
  customModelLearn = 'custom_model_learning',
  markupExcelDump = 'dump_markup_to_excel',
  excelUploadHistory = 'excel_upload_history',
  applyIntentDetectors = 'apply_intent_detectors',
  findLabelIssues = 'find_label_issues',
  autoSamplingCreation = 'auto_sampling_creation',
  excelDownloadDirtyMarkup = 'excel_download_dirty_markup',
  downloadHistoryFromYt = 'download_history_from_yt',
}

export type TaskLog = {
  id: string;
  taskId: string;
  message: string;
  status?: TaskLogStatus;
  timestamp?: string; // format = date-time
}

export enum TaskLogStatus {
  OK = "OK",
  IN_ROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
}

export function getTaskTypeName(type: TaskType): string {
  switch (type) {
    case TaskType.export:
      return i18n.t("TASKS.TYPE_EXPORT");
    case TaskType.import:
      return i18n.t("TASKS.TYPE_IMPORT");
    case TaskType.callResults:
      return i18n.t("TASKS.TYPE_CALL_RESULTS");
    case TaskType.callInit:
      return i18n.t("TASKS.TYPE_CALL_INIT");
    case TaskType.demoDialogsImport:
      return i18n.t("TASKS.TYPE_DEMO_DIALOGS_IMPORT");
    case TaskType.demoStatsImport:
      return i18n.t("TASKS.TYPE_DEMO_STATS_IMPORT");
    case TaskType.exportPersonalAccountOverview:
      return i18n.t("TASKS.TYPE_EXPORT_PERSONAL_ACCOUNT_OVERVIEW");
    case TaskType.exportDialogHistoryContext:
      return i18n.t("TASKS.TYPE_EXPORT_DIALOGS_HISTORY");
    case TaskType.learningModel:
      return i18n.t("TASKS.TYPE_LEARNING_MODEL");
    case TaskType.exportVoximplantResourcesUsage:
      return i18n.t("TASKS.TYPE_EXPORT_CALLS_PROVIDER_RESOURCES_USAGE");
    case TaskType.excelUploadMarkup:
      return i18n.t("TASKS.TYPE_EXCEL_UPLOAD_MARKUP");
    case TaskType.applyIntentDetectors:
      return i18n.t("TASKS.TYPE_MODELS_COMPARISON_EXPORT");
    case TaskType.excelDownloadDirtyMarkup:
      return i18n.t("SCENARIO_GRAPHS.EXAMPLES_MODAL.REPETITIVE_EXAMPLES_EXPORT");
    case TaskType.findLabelIssues:
      return i18n.t("SCENARIO_GRAPHS.EXAMPLES_MODAL.SEARCH_REPETITIONS");
    case TaskType.clustering:
      return i18n.t("TASKS.TYPE_CLUSTERING");
    case TaskType.markupExcelDump:
      return i18n.t("SCENARIO_GRAPHS.EXAMPLES_MODAL.EXAMPLES_EXPORT");
    default:
      return type;
  }
}
