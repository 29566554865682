import { SagaIterator } from 'redux-saga';
import { fork } from 'redux-saga/effects';

import projectsSagas from './projects/sagas';
import dialogsSagas from './dialogs/sagas';
import tagsSagas from './tags/sagas';
import featuresSagas from './features/sagas';
import linesSagas from './lines/sagas';
import userSagas from './user/sagas';
import tasksSagas from './tasks/sagas';
import filesSagas from './files/sagas';
import callsDetailsSagas from './callDetails/sagas';
import samplingsSagas from './samplings/sagas';
import configSagas from './configs/sagas';
import changeHistorySagas from './changeHistory/sagas';
import versionsSagas from './versions/sagas';
import entitiesSagas from './entities/sagas';
import modelsSagas from './models/sagas';
import scenarioGraphSagas from './graph/sagas';
import graphChatSagas from './graphChat/sagas';
import slotsSagas from './slots/sagas';
import scenariosExamplesSagas from './scenariosExamples/sagas';
import integrationsSagas from './integrations/sagas';
import butterflySagas from './butterfly/sagas';
import callsSagas from './calls/sagas';
import clusteringSagas from './clustering/sagas';
import languagesSagas from './languages/sagas';
import scenarioGraphsSagas from './scenarioGraphs/sagas';
import incomingIntegrationSagas from './incomingIntegrations/sagas';
import accessesSagas from './accesses/sagas';
import superusersSagas from './superusers/sagas';
import authProvidersSagas from './authProviders/sagas';
import releaseNotesSagas from './releaseNotes/sagas';
import graphDebugSagas from './graphDebug/sagas';
import projectsAuthDataSagas from './projectsAuthData/sagas';
import providersSagas from './providers/sagas';
import MessengerIntegrationsSagas from './messengerIntegrations/sagas';
import billingSagas from './billing/sagas';
import projectVoiceConfigSagas from './projectVoiceConfig/sagas';
import domainsSagas from './domains/sagas';
import secretsSagas from './secrets/sagas';
import graphGroupsSagas from './graphGroups/sagas';
import previousAnswerSagas from './previousAnswer/sagas';
import iterationAnalyticSagas from './riseOfAutomation/sagas';

export default function* rootSaga(): SagaIterator {
  yield fork(projectsSagas);
  yield fork(dialogsSagas);
  yield fork(tagsSagas);
  yield fork(featuresSagas);
  yield fork(linesSagas);
  yield fork(userSagas);
  yield fork(tasksSagas);
  yield fork(filesSagas);
  yield fork(callsDetailsSagas);
  yield fork(samplingsSagas);
  yield fork(configSagas);
  yield fork(changeHistorySagas);
  yield fork(versionsSagas);
  yield fork(entitiesSagas);
  yield fork(modelsSagas);
  yield fork(scenarioGraphSagas);
  yield fork(graphChatSagas);
  yield fork(slotsSagas);
  yield fork(scenariosExamplesSagas);
  yield fork(integrationsSagas);
  yield fork(butterflySagas);
  yield fork(callsSagas);
  yield fork(clusteringSagas);
  yield fork(languagesSagas);
  yield fork(scenarioGraphsSagas);
  yield fork(incomingIntegrationSagas);
  yield fork(accessesSagas);
  yield fork(superusersSagas);
  yield fork(releaseNotesSagas);
  yield fork(graphDebugSagas);
  yield fork(authProvidersSagas);
  yield fork(projectsAuthDataSagas);
  yield fork(providersSagas);
  yield fork(MessengerIntegrationsSagas);
  yield fork(billingSagas);
  yield fork(projectVoiceConfigSagas);
  yield fork(domainsSagas);
  yield fork(secretsSagas);
  yield fork(graphGroupsSagas);
  yield fork(previousAnswerSagas);
  yield fork(iterationAnalyticSagas);
}
