import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';

import block from 'bem-cn';

import { ModalProps } from '../../services/modal';

import Modal from '../components/ndModal';
import Input from '../../components-new/input';
import LoadingRow from '../../components/loadingRow';

import * as actions from "../../redux/domains/actions";
import { getIsDomainsLoading } from "../../redux/domains/selectors";
import { getSelectedProject } from '../../redux/projects/selectors';

import { AllowedDomain } from '../../types/models/Domains';

import './styles.scss';

const b = block('nd-create-domain-modal');

const domainReg = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;

export function isDomainValid(value: string): boolean {
  return domainReg.test(value);
}

export type CreateDomainModalProps = {
  className?: string,
};

export default function CreateDomainModal({
  className = '',
  close,
}: CreateDomainModalProps & ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProject = useSelector(getSelectedProject);
  const loading = useSelector(getIsDomainsLoading);

  const [domain, setDomain] = useState<AllowedDomain['domain']>("");
  const [tvmName, setTvmName] = useState<AllowedDomain['tvmName']>("");

  const handleDomainChange = useCallback(
    (_, { value }) => {
      setDomain(value);
    },
    [],
  );

  const handleTvmChange = useCallback(
    (_, { value }) => {
      setTvmName(value);
    },
    [],
  );

  const isValid = useMemo(
    () => {
      return domain && isDomainValid(domain);
    },
    [domain],
  );

  const handleCreateClick = useCallback(
    () => {
      dispatch(actions.createAllowedDomain.request({
        projectSlug: selectedProject.slug,
        tvmName,
        domain,
      }));
    },
    [dispatch, domain, selectedProject.slug, tvmName],
  );

  return (
    <Modal
      className={`${b()} ${className}`}
      close={close}
      cancelText={t('COMMON.CANCEL')}
      confirmationText={t('COMMON.CREATE')}
      title={t("PAGE_WHITELISTS.CREATE_DOMAIN")}
      onConfirm={handleCreateClick}
      confirmDisabled={!isValid || loading}
    >
      <div className={b("content")}>
        <Input
          name="domain"
          placeholder={t("PAGE_WHITELISTS.DOMAIN")}
          onChange={handleDomainChange}
          value={domain}
          floatingPlaceholder
        />
        {domain && !isValid && (
          <span className={b("error")}>{t("PAGE_WHITELISTS.INVALID_DOMAIN")}</span>
        )}
        <Input
          name="tvmName"
          placeholder={t("PAGE_WHITELISTS.TVM_NAME")}
          onChange={handleTvmChange}
          value={String(tvmName)}
          floatingPlaceholder
        />
        {loading &&
          <div className={b("loading-row")}>
            <LoadingRow />
          </div>
        }
      </div>
    </Modal>
  );
}
