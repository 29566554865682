import React, { FC } from 'react';
import { SvgProps } from '../types';

const CheckmarkSmallIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1654 15.1798L18.5229 6.82234C18.9134 6.43182 19.5465 6.43182 19.9371 6.82234L20.2929 7.17816C20.6834 7.56869 20.6834 8.20185 20.2929 8.59238L10.1654 18.7198L4.70711 13.2615C4.31658 12.871 4.31658 12.2378 4.70711 11.8473L5.06293 11.4915C5.45345 11.1009 6.08662 11.1009 6.47714 11.4915L10.1654 15.1798Z"
      fill={color}
    />
  </svg>

);

export default CheckmarkSmallIcon;
