import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { AccountStatus, AccountType, TelegramAccountData, WhatsappAccountData } from "../../types/models/Accounts";

import * as actions from "./actions";

type MessengerIntegrationsState = {
  loading: boolean;
  [AccountType.telegram]: {
    status: AccountStatus;
    data: TelegramAccountData | null;
  },
  [AccountType.whatsapp]: {
    status: AccountStatus;
    data: WhatsappAccountData | null;
  }
  messengerToEdit: AccountType | null;
  editing: boolean;
}

const initialState: MessengerIntegrationsState = {
  loading: false,
  [AccountType.telegram]: {
    status: AccountStatus.notCreated,
    data: null,
  },
  [AccountType.whatsapp]: {
    status: AccountStatus.notCreated,
    data: null,
  },
  messengerToEdit: null,
  editing: false,
};

export const messengerIntegrationsReducer: Reducer<MessengerIntegrationsState> = createReducer(
  initialState,
)
  .handleAction(
    actions.loadMessengerIntegrations.request,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      loading: true,
    }),
  )
  .handleAction(
    actions.loadMessengerIntegrations.success,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.loadMessengerIntegrations.success>,
    ): MessengerIntegrationsState => ({
      ...state,
      loading: false,
      [AccountType.telegram]: {
        ...state[AccountType.telegram],
        status: payload.telegram.status,
        data: payload.telegram.data as TelegramAccountData,
      },
      [AccountType.whatsapp]: {
        ...state[AccountType.whatsapp],
        status: payload.whatsapp.status,
        data: payload.whatsapp.data as WhatsappAccountData,
      },
    }),
  )
  .handleAction(
    actions.loadMessengerIntegrations.failure,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.setMessengerToEdit,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.setMessengerToEdit>,
    ): MessengerIntegrationsState => ({
      ...state,
      messengerToEdit: payload,
    }),
  )
  .handleAction(
    actions.createMessengerIntegration.request,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      editing: true,
    }),
  )
  .handleAction(
    actions.createMessengerIntegration.success,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.createMessengerIntegration.success>,
    ): MessengerIntegrationsState => ({
      ...state,
      editing: false,
      [payload.type]: {
        ...state[payload.type],
        data: payload,
      },
    }),
  )
  .handleAction(
    actions.createMessengerIntegration.failure,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      editing: false,
    }),
  )
  .handleAction(
    actions.updateMessengerIntegration.request,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      editing: true,
    }),
  )
  .handleAction(
    actions.updateMessengerIntegration.success,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.updateMessengerIntegration.success>,
    ): MessengerIntegrationsState => ({
      ...state,
      editing: false,
      [payload.type]: {
        ...state[payload.type],
        data: payload,
      },
    }),
  )
  .handleAction(
    actions.updateMessengerIntegration.failure,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      editing: false,
    }),
  )
  .handleAction(
    actions.deleteMessengerIntegration.request,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      editing: true,
    }),
  )
  .handleAction(
    actions.deleteMessengerIntegration.success,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.deleteMessengerIntegration.success>,
    ): MessengerIntegrationsState => ({
      ...state,
      editing: false,
      [payload]: {
        ...initialState[payload],
      },
    }),
  )
  .handleAction(
    actions.deleteMessengerIntegration.failure,
    (state: MessengerIntegrationsState): MessengerIntegrationsState => ({
      ...state,
      editing: false,
    }),
  )
  .handleAction(
    actions.enableMessengerIntegration.success,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.enableMessengerIntegration.success>,
    ): MessengerIntegrationsState => ({
      ...state,
      [payload]: {
        ...state[payload],
        status: AccountStatus.enabled,
      },
    }),
  )
  .handleAction(
    actions.disableMessengerIntegration.success,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.enableMessengerIntegration.success>,
    ): MessengerIntegrationsState => ({
      ...state,
      [payload]: {
        ...state[payload],
        status: AccountStatus.disabled,
      },
    }),
  )
  .handleAction(
    actions.setMessengerStatus,
    (
      state: MessengerIntegrationsState,
      { payload }: ActionType<typeof actions.setMessengerStatus>,
    ): MessengerIntegrationsState => ({
      ...state,
      [payload.type]: {
        ...state[payload.type],
        status: payload.status,
      },
    }),
  );
