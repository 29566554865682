export type ClassName = string | { [key: string]: boolean } | undefined | null;

export default function classNames(...classes: ClassName[]): string {
  let result = '';
  for (let className of classes) {
    if (typeof className === 'string') {
      result += className + ' ';
    } else if (typeof className === 'object' && className !== null) {
      for (const [key, value] of Object.entries(className)) {
        if (value) {
          result += key + ' ';
        }
      }
    }
  }

  return result.trim();
}
