import { UserBackend } from "../backendModels/UserBackend";
import { User } from "../models/User";
import { UserRole } from "../models/UserAccesses";

export function parseUser(value: UserBackend): User {
  return {
    id: String(value.id),
    name: value.username || "",
    login: "",
    pending: false,
    isSuperUser: value.is_superadmin || false,
    superadminGroup: value.superadmin_group as UserRole || UserRole.guest,
  };
}
