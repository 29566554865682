/* eslint-disable operator-linebreak */
import apiService, { ApiResponse } from "../../../../../../services/api";
import i18n from "../../../../../../services/i18n";
import { generateId } from "../../../../../../services/helpers/generateId";

import {
  PredicateBackend,
  PredicateItemBackend,
} from "../../../../../../types/backendModels/ScenarioGraphActionBackend";

import {
  FormPredicateBoolStatus,
  FormPredicateItem,
  FormPredicateItemBool,
  Predicate,
  PredicateItem,
  PredicateItemType,
  PredicateOperator,
} from "../../../../../../types/models/ScenarioGraphAction";
import { ScenarioGroupName } from "../../../../../../types/backendModels/ScenarioGraphBackend";
import {
  parsePredicateItem,
  parsePredicateToBackend,
} from "../../../../../../types/parsers/ScenarioGraphActionParser";

import {
  ConvertPredicateRequest,
  ConvertPredicateResponse,
} from "../../../../../../types/requests/Predicate";

import {
  FeatureAction,
  FeatureRule,
  RulePredicate,
  RuleWord,
  ScenarioGraphRules,
  SymbolsRule,
} from './types';

import { trim } from '../../../../../../services/helpers/utilities';

import {
  ALWAYS_RULE, ALWAYS_RULE_ID,
  getDefaultValues,
  isAlwaysRule,
  RulesSelectValue,
} from "./components/ruleCard/components/rulesList/helpers";

export const ALLOWED_FEATURE_SLUGS = [
  "last_user_message_length",
  "last_user_message",
  "sure_topic",
];

export const FEATURE_SLUGS_WITH_SYMBOL_FORM = [
  "last_user_message_length",
];

export function getEmptySymbolsRule(): SymbolsRule {
  return {
    action: PredicateOperator.EQUALS,
    count: 5,
  };
}

export function getEmptyFeatureRule(
  scenarioSlug: string,
  groupName: ScenarioGroupName,
): FeatureRule {
  if ([
    ScenarioGroupName.fallback,
    ScenarioGroupName.greeting,
  ].includes(groupName)) {
    return {
      ...ALWAYS_RULE,
      id: generateId(),
    };
  }
  return {
    id: generateId(),
    ...getDefaultValues(RulesSelectValue.ai, scenarioSlug),
  };
}

export function getEmptyRulePredicate(
  scenarioSlug: string,
  groupName: ScenarioGroupName = ScenarioGroupName.main,
): RulePredicate {
  return {
    id: generateId(),
    type: PredicateItemType.form,
    isActive: true,
    rules: [
      getEmptyFeatureRule(scenarioSlug, groupName),
    ],
  };
}

export function getCodRulePredicate(
  code: string,
): RulePredicate {
  return {
    id: generateId(),
    type: PredicateItemType.code,
    isActive: true,
    code,
  };
}

export async function readClipboard(): Promise<string> {
  const data = await navigator.clipboard.readText();

  if (!data.trim()) {
    throw new Error(i18n.t("GRAPH.ACTION.ACTION_CONDITION.EMPTY_PASTE_TEXT"));
  }

  return data;
}

export function getEmptyScenarioGraphRule(
  group: ScenarioGroupName,
  scenarioSlug: string,
): ScenarioGraphRules {
  return [getEmptyRulePredicate(scenarioSlug, group)];
}

export function parsePredicateToScenarioRules(
  data: { value?: Predicate, scenarioSlug: string, groupName: ScenarioGroupName },
): ScenarioGraphRules {
  const { value, scenarioSlug, groupName } = data;
  if (!value?.predicates?.length) return getEmptyScenarioGraphRule(groupName, scenarioSlug);

  return value.predicates.map(predicate => {
    const {
      codePredicate, formPredicate, id, isActive, type,
    } = predicate;

    const result: RulePredicate = { id, isActive, type };

    if (result.type === PredicateItemType.form) {
      result.rules = formPredicate && formPredicate?.length > 0 ?
        formPredicate.map(parseFormPredicateItemToFeatureRule) :
        [getEmptyFeatureRule(scenarioSlug, groupName)];
    } else {
      result.code = codePredicate;
    }

    return result;
  });
}

function parseFormPredicateItemToFeatureRule(predicate: FormPredicateItem | FormPredicateItemBool): FeatureRule {
  const getValue = () => {
    switch (true) {
      case !!(predicate as FormPredicateItemBool).always:
        return ALWAYS_RULE_ID;
      case (predicate as FormPredicateItem).feature === 'last_user_message' &&
        (predicate as FormPredicateItem).operator === 'matches':
      case (predicate as FormPredicateItem).feature === 'sure_topic':
        return trim((predicate as FormPredicateItem).value as string, "'\"`");
      case FEATURE_SLUGS_WITH_SYMBOL_FORM.includes((predicate as FormPredicateItem).feature):
        return parseSymbolsRule(predicate as FormPredicateItem);
      default:
        return parsePredicateValueToWords((predicate as FormPredicateItem).value);
    }
  };

  if ((predicate as FormPredicateItemBool).always) {
    return {
      ...ALWAYS_RULE,
      id: predicate.id,
    };
  }

  return {
    id: predicate.id,
    feature: (predicate as FormPredicateItem).feature,
    action: (predicate as FormPredicateItem).operator as unknown as FeatureAction,
    value: getValue(),
  };
}

function parseSymbolsRule(item: FormPredicateItem): SymbolsRule {
  return {
    action: item.operator as PredicateOperator,
    count: +item.value,
  };
}

function parsePredicateValueToWords(value: FormPredicateItem['value']): RuleWord[] {
  if (Array.isArray(value)) {
    return value.map(text => ({
      text: trim(text, "'\"`"),
      id: generateId(),
    }));
  }

  return [{ text: trim(value, "'\"`"), id: generateId() }];
}

export function parseRulePredicate(value: RulePredicate): PredicateItem {
  return {
    id: value.id,
    isActive: value.isActive,
    type: value.type,
    ...value.type === PredicateItemType.form && {
      formPredicate: (value.rules as FeatureRule[])
        .filter(rule => !isEmptyFeatureRule(rule))
        .map(parseFeatureRuleToPredicateItem),
    },
    ...value.type === PredicateItemType.code && {
      codePredicate: value.code,
    },
  };
}

function parseFeatureRuleToPredicateItem(rule: FeatureRule): FormPredicateItem | FormPredicateItemBool {
  if (isAlwaysRule(rule)) {
    return {
      id: ALWAYS_RULE_ID,
      always: FormPredicateBoolStatus.enabled,
    };
  }
  let parsedValue: string | string[] = "";
  let operator: PredicateOperator | null = null;

  if (Array.isArray(rule.value)) {
    parsedValue = rule.value.map(word => {
      const withoutBracets = trim(word.text, "\"'");

      return `"${withoutBracets}"`;
    });
    operator = rule.action as unknown as PredicateOperator;
  } else if (rule.action === FeatureAction.equals && typeof rule.value === 'string') {
    operator = rule.action as unknown as PredicateOperator;
    const withoutBracets = trim(rule.value, "\"'");
    parsedValue = `"${withoutBracets}"`;
  } else if (typeof rule.value === 'string') {
    operator = rule.action as unknown as PredicateOperator;
    parsedValue = rule.value;
  } else {
    parsedValue = rule.value.count.toString();
    operator = rule.value.action;
  }

  return {
    id: generateId(),
    feature: rule.feature,
    operator: operator as PredicateOperator,
    value: parsedValue,
  };
}

export async function convertInterfaceFormToCode(predicate: RulePredicate): Promise<string> {
  const predicatItem = parseRulePredicate(predicate);
  if (predicatItem.formPredicate?.length === 0) return '';

  const rulesAsPredicate: Predicate = { predicates: [predicatItem] };
  const parsedPredicate = parsePredicateToBackend(rulesAsPredicate) as PredicateBackend;

  const params: ConvertPredicateRequest = { body: parsedPredicate };

  const response: ApiResponse<ConvertPredicateResponse> = await apiService.convertPredicate(params);
  const convertedPredicate: PredicateItem = parsePredicateItem(response.data?.predicates[0]);

  // Ошибка при парсинге - когда мы отправили код, а вернулась
  // пустая форма. Этот сценарий считаем как "ошибка при парсинге"
  const parsingError = (
    (
      convertedPredicate.codePredicate !== ''
      && (!convertedPredicate.formPredicate || convertedPredicate.formPredicate?.length === 0)
    )
    || response.status === 400
  );

  if (parsingError) {
    throw new Error(i18n.t("ERRORS.API.GRAPH.CANT_CONVERT_PREDICATE"));
  }

  return response.data?.predicates.map(p => `(${p.code_predicate})` || "").join(" or ");
}

function isEmptyFeatureRule(rule: FeatureRule): boolean {
  return Array.isArray(rule.value) && rule.value.length === 0;
}

export async function convertCodeToInterface(code: string, scenarioSlug: string): Promise<FeatureRule[]> {
  const fallbackResult = [getEmptyFeatureRule(scenarioSlug, ScenarioGroupName.main)];
  if (!code) return fallbackResult;

  const predicates = code.split(/ OR /g);

  const items: PredicateItemBackend[] = predicates.map(predicate => (
    {
      is_active: true,
      type: PredicateItemType.code,
      code_predicate: predicate,
    }
  ));

  const params: ConvertPredicateRequest = {
    body: {
      predicates: items,
    },
  };

  const response: ApiResponse<
    ConvertPredicateResponse
  > = await apiService.convertPredicate(params);
  const convertedPredicate: PredicateItem = parsePredicateItem({
    ...response.data?.predicates[0],
    type: PredicateItemType.form, // с бэка приходит 'code'
  });

  const containsUnknownFeatures = convertedPredicate.formPredicate?.some(item => (
    !ALLOWED_FEATURE_SLUGS.includes((item as FormPredicateItem).feature)
  ));

  // Ошибка при парсинге - когда мы отправили код, а вернулась
  // пустая форма. Этот сценарий считаем как "ошибка при парсинге"
  const parsingError = (
    (convertedPredicate.codePredicate !== '' && (!convertedPredicate.formPredicate || convertedPredicate.formPredicate?.length === 0))
    || response.status === 400
    || containsUnknownFeatures
  );

  if (parsingError) {
    throw new Error(i18n.t("ERRORS.API.GRAPH.CANT_CONVERT_PREDICATE"));
  }

  return convertedPredicate.formPredicate
    ? convertedPredicate.formPredicate.map(parseFormPredicateItemToFeatureRule)
    : fallbackResult;
}
