import { createAsyncAction, createAction } from 'typesafe-actions';
import { Dialog, RequestMessage, ResponseMessage, DialogsHistorySearchFilter, Attachment } from '../../types/models/Dialog';
import { GraphDebugBlock } from '../../types/models/GraphDebugBlock';
import { SendBotDialogMessageResponse } from '../../types/requests/BotDialog';
import { ErrorAction } from '../reducers';

export const loadDialogsHistory = createAsyncAction(
  'dialogs/history/requested',
  'dialogs/history/succeeded',
  'dialogs/history/failed',
)<
  DialogsHistorySearchFilter & { limit?: number, olderThan?: number },
  { olderThan?: number, dialogs: Dialog[], total: number },
  ErrorAction
>();

export const searchDialogsHistory = createAsyncAction(
  'dialogs/history-search/requested',
  'dialogs/history-search/succeeded',
  'dialogs/history-search/failed',
)<
  DialogsHistorySearchFilter & { limit?: number, offset?: number },
  { offset?: number, dialogs: Dialog[], total: number },
  ErrorAction
>();

export const loadDialogBySessionId = createAsyncAction(
  'dialogs/load-dialog/requested',
  'dialogs/load-dialog/succeeded',
  'dialogs/load-dialog/failed',
)<
  { sessionId: string },
  { dialog: Dialog },
  ErrorAction
>();

export const openDialog = createAction(
  'dialogs/openDialog',
)<{ sessionId: string }>();
export const closeDialog = createAction(
  'dialogs/closeDialog',
)();

export const setLiveChatMessages = createAction('liveDialog/setHistory')<Array<RequestMessage | ResponseMessage>>();
export const clearLiveChat = createAction('liveDialog/clear')();
export const appendLiveMessage = createAction('liveDialog/appendMessage')<RequestMessage | ResponseMessage>();
export const deleteLastLiveMessage = createAction('liveDialog/deleteLastLiveMessage')();
export const saveUserMessage = createAction('liveDialog/saveUserMessage')<RequestMessage | null>();
export const setLiveChatId = createAction('liveDialog/setChatId')<string>();

export type FeatureDispatchValue = { featureSlug: string, dispatchValue: string | number };
export const setFeatureDispatchValues = createAction('liveDialog/setFeatureDispatchValues')<FeatureDispatchValue[]>();
export const setFeatureDispatchValue = createAction('liveDialog/setFeatureDispatchValue')<FeatureDispatchValue>();
export const resetFeatures = createAction('liveDialog/resetFeatures')();

export const sendLiveMessage = createAsyncAction(
  'botDialog/sendMessage/requested',
  'botDialog/sendMessage/succeeded',
  'botDialog/sendMessage/failed',
)<string | {
  text: string,
  attachments?: Attachment[],
  askFeatures?: boolean,
  skipRequestMessage?: boolean,
  checkVersionInterval?: number,
}, SendBotDialogMessageResponse, ErrorAction>();

export const preventLiveMessageSending = createAction(
  'botDialog/preventLiveMessageSending',
)();

export type CloneDialogRequest = {
  chatId: string,
  newChatId: string,
  lastRecordId?: string,
  cloneCount?: number,
  skipRouting?: boolean,
  // useLocalDialog?: boolean, TODO доработать клонирование чата с учетом локальных сообщений - сборщиков фичей
};

export const cloneDialog = createAsyncAction(
  'dialogs/clone/requested',
  'dialogs/clone/succeeded',
  'dialogs/clone/failed',
)<CloneDialogRequest, Array<RequestMessage | ResponseMessage>, ErrorAction>();

export const setDialogsHistoryFilter = createAction('dialogs/history/setFilters')<DialogsHistorySearchFilter & { load?: boolean }>();
export const setSearchDialogsHistoryFilter = createAction('dialogs/searchHistory/setFilters')<DialogsHistorySearchFilter & { load?: boolean }>();

export const exportDialogsHistory = createAsyncAction(
  'dialogs/exportDialogsHistory/requested',
  'dialogs/exportDialogsHistory/succeeded',
  'dialogs/exportDialogsHistory/failed',
)<{ filters: DialogsHistorySearchFilter, title: string, modalId?: string }, undefined, ErrorAction>();

export const loadDebugBlocksById = createAsyncAction(
  'dialogs/loadDebugBlocksById/requested',
  'dialogs/loadDebugBlocksById/succeeded',
  'dialogs/loadDebugBlocksById/failed',
)<
  { debugId: string, isLinkToGraph: boolean },
  { debugId: string, debugBlocks: GraphDebugBlock[] | null },
  ErrorAction<{ debugId: string }>
>();

export const resetDialogs = createAction('dialogs/resetDialogs')();

export const resetDebugBlocks = createAction('dialogs/createAction')();

export const openProcessExamplesModal = createAction('dialogs/openProcessExamplesModal')();
export const closeProcessExamplesModal = createAction('dialogs/closeProcessExamplesModal')();

export const openHistoryExportDialogsModal = createAction('dialogs/openHistoryExportDialogsModal')();
export const closeHistoryExportDialogsModal = createAction('dialogs/closeHistoryExportDialogsModal')();
export const openExportDialogsModal = createAction('dialogs/openExportDialogsModal')();
export const closeExportDialogsModal = createAction('dialogs/closeExportDialogsModal')();

export const setDialogViewed = createAction('dialogs/setDialogViewed')<string>();

export const setShowDialogTiming = createAction('dialogs/setShowDialogTiming')<boolean>();

export const resetReducer = createAction('dialogs/resetReducer')();
