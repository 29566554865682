import { createAction, createAsyncAction } from "typesafe-actions";
import { SelectedClusters } from "../../pages/scenarioGraphs/components/trainingModal/components/clusteringModal/types";
import { Cluster, ClusterItem, ClusteringType } from "../../types/models/Clustering";
import { ErrorAction } from "../reducers";

export const loadClusters = createAsyncAction(
  'clustering/load/requested',
  'clustering/load/succeeded',
  'clustering/load/failed',
)<
  { limit: number },
  { clusters: Cluster[], canRun: boolean, createdAt: string | null },
  ErrorAction
>();

export const loadClusterDetails = createAsyncAction(
  'clustering/loadDetails/requested',
  'clustering/loadDetails/succeeded',
  'clustering/loadDetails/failed',
)<
  { clusterNumber: number, limit: number, offset: number},
  { clusterNumber: number, clusterItems: ClusterItem[] },
  ErrorAction
>();

export const setPhrases = createAsyncAction(
  'clustering/setPhrases/requested',
  'clustering/setPhrases/succeeded',
  'clustering/setPhrases/failed',
)<
  { topicSlug: string, texts: string[], grouped: SelectedClusters },
  Cluster[],
  ErrorAction
>();

export const closeModal = createAction('clustering/closeModal')();
export const openModal = createAction('clustering/openModal')<string>();
export const closeClusterDetails = createAction('clustering/closeDetails')();

export const launchClustering = createAsyncAction(
  'task/launchClustering/requested',
  'task/launchClustering/succeeded',
  'task/launchClustering/failed',
)<
  {
    type: ClusteringType;
    samplingId?: string;
  },
  undefined,
  ErrorAction<{ taskId: string }>
>();
