import { memo, useCallback, useState } from "react";
import { block } from "bem-cn";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { isCurrentVersionRelease } from "../../../../redux/versions/selectors";

import modalService from "../../../../services/modal";

import { ScenarioGraphLabel } from "../../../../types/models/ScenarioGraphLabel";
import { ScenarioGroupName } from "../../../../types/backendModels/ScenarioGraphBackend";

import { createScenarioLabel, openEditLabelModal, setSearchScenariosFilter } from "../../../../redux/scenarioGraphs/actions";
import { getScenarioGraphs, getScenarioLabels } from "../../../../redux/scenarioGraphs/selectors";

import { ScenariosFilters, formatCreateLabelOption } from "../../helpers";

import Select, { MultiOperator, SelectConfig } from "../../../../components-new/select/configurable";
import { getMultiMenu } from "../../../../components-new/select/components/multiMenu";
import VersionSelector from "../../../../components-new/versionSelector";
import Button from "../../../../components-new/button";
import Input from "../../../../components-new/input";
import Icon, { IconSize } from "../../../../components-new/icon";

import './styles.scss';

const b = block("scenarios-filters");

enum FilterType {
  search = "search",
  group = "group",
  type = "type",
}

export const scenarioGroupNames = [
  ScenarioGroupName.greeting,
  ScenarioGroupName.pre,
  ScenarioGroupName.main,
  ScenarioGroupName.feedback,
  ScenarioGroupName.fallback,
];

const Menu = getMultiMenu(
  false,
  true,
);

const components = {
  Menu,
};

type Props = {
  filters: ScenariosFilters
  onResetFiltersClick: () => void;
  newFilters?: boolean;
  handleUnsavedClick?: (cb: () => void) => void;
}

function Filters({
  filters,
  onResetFiltersClick,
  newFilters,
  handleUnsavedClick,
}: Props) {
  const {
    search = "",
    labels = [],
    scenarioTypes = [],
  } = filters;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { list: labelsList } = useSelector(getScenarioLabels);
  const scenarios = useSelector(getScenarioGraphs);
  const isRelease = useSelector(isCurrentVersionRelease);

  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>(() => {
    let hasValue = [];
    if (filters.search !== undefined) hasValue.push(FilterType.search);
    if (filters.labels !== undefined) hasValue.push(FilterType.group);
    if (filters.scenarioTypes !== undefined) hasValue.push(FilterType.type);
    return hasValue;
  });

  const changeSearchText = useCallback(
    (_, { value }) => {
      dispatch(setSearchScenariosFilter({
        ...filters,
        search: value,
      }));
    },
    [filters, dispatch],
  );

  const changeLabels = useCallback(
    (
      newLabels: Array<ScenarioGraphLabel['id']>,
      operator?: MultiOperator,
    ) => {
      dispatch(setSearchScenariosFilter({
        ...filters,
        labels: newLabels,
        labelsOperator: operator || filters.labelsOperator,
      }));
    },
    [filters, dispatch],
  );

  const changeFilters = useCallback(
    (
      types: FilterType[],
    ) => {
      const changedFilters = filters;
      changedFilters.search = types.includes(FilterType.search) ? (filters.search || '') : undefined;
      changedFilters.labels = types.includes(FilterType.group) ? (filters.labels || []) : undefined;
      changedFilters.scenarioTypes = types.includes(FilterType.type) ? (filters.scenarioTypes || []) : undefined;

      setSelectedFilters(types);
      dispatch(setSearchScenariosFilter({ ...changedFilters }));
    },
    [dispatch, filters],
  );

  const changeScenarioTypes = useCallback(
    (
      types: ScenarioGroupName[],
    ) => {
      dispatch(setSearchScenariosFilter({
        ...filters,
        scenarioTypes: types,
      }));
    },
    [filters, dispatch],
  );

  const handleCreateLabel = useCallback(
    (input: string) => {
      dispatch(createScenarioLabel.request(input));
    },
    [dispatch],
  );

  const exportScenariosClick = useCallback(
    () => {
      modalService.openExportScenarios({ scenarios });
    },
    [scenarios],
  );

  const handleEditLabelClick = useCallback(
    (event: React.MouseEvent, labelId: string) => {
      event.preventDefault();

      if (!labelId) return;

      dispatch(openEditLabelModal({ labelId }));
    },
    [dispatch],
  );

  const labelsConfig: SelectConfig = {
    options: labelsList,
    labelFunction: (label: ScenarioGraphLabel) => label.title,
    valueFunction: (label: ScenarioGraphLabel) => label.id,
    iconFunction: () => "pen",
    onIconClick: handleEditLabelClick,
  };

  const filtersConfig: SelectConfig = {
    options: [
      FilterType.search,
      FilterType.type,
      ...(!isRelease ? [FilterType.group] : []),
    ],
    labelFunction: (value: FilterType) => t(`SCENARIO_GRAPHS.FILTERS_SELECTOR.${value.toUpperCase()}`),
    valueFunction: (value: FilterType) => value,
  };

  const scenarioGroupConfig: SelectConfig = {
    options: scenarioGroupNames,
    labelFunction: (value: ScenarioGroupName) => t(`GRAPH.TYPE_SELECT.${value.toUpperCase()}.TITLE`),
    valueFunction: (value: ScenarioGroupName) => value,
  };

  return (
    <div className={b()}>
      {newFilters ?
        <>
          <div className={b("grid")}>
            <VersionSelector onClick={handleUnsavedClick} />
            {selectedFilters.includes(FilterType.search) &&
              <Input
                value={search}
                name="search"
                icon="search"
                iconPosition="right"
                placeholder={t("SCENARIO_GRAPHS.SEARCH_PLACEHOLDER")}
                floatingPlaceholder
                onChange={changeSearchText}
                clearable
              />}
            {(!isRelease && selectedFilters.includes(FilterType.group)) &&
              <Select
                config={labelsConfig}
                value={labels}
                isMulti
                floatingPlaceholder
                components={components}
                operator={filters.labelsOperator}
                formatCreateLabel={formatCreateLabelOption}
                placeholder={t("COMMON.GROUP")}
                onChange={changeLabels}
                onCreateOption={handleCreateLabel}
              />}
            {selectedFilters.includes(FilterType.type) &&
              <Select
                config={scenarioGroupConfig}
                value={scenarioTypes}
                isMulti
                usePortal
                floatingPlaceholder
                components={{
                  Menu: getMultiMenu(true),
                }}
                placeholder={t("SCENARIO_GRAPHS.FILTERS_SELECTOR.TYPE")}
                onChange={changeScenarioTypes}
              />}
            <Button
              className={b('select-filters')}
              icon="plusSmall"
              iconSize={IconSize.medium}
            >
              <Select
                className={b("filter-title-select")}
                config={filtersConfig}
                value={selectedFilters}
                onChange={changeFilters}
                isMulti
                floatingPlaceholder
                components={{
                  Menu: getMultiMenu(),
                  DropdownIndicator: () => null,
                  Placeholder: () => null,
                }}
                isClearable={false}
              />
            </Button>
          </div>
          <div className={b("actions")}>
            <Button
              className={b("export-button")}
              bordered
              onClick={exportScenariosClick}
            >
              <Icon type="documentDownload" />
              {t("SCENARIO_GRAPHS.SCENARIOS_TO_JSON")}
            </Button>
          </div>
        </> :
        <>
          <div className={b("grid")}>
            <Input
              value={search}
              name="search"
              icon="search"
              iconPosition="right"
              placeholder={t("SCENARIO_GRAPHS.SEARCH_PLACEHOLDER")}
              floatingPlaceholder
              onChange={changeSearchText}
            />
            <Select
              config={labelsConfig}
              value={labels}
              isMulti
              floatingPlaceholder
              components={components}
              operator={filters.labelsOperator}
              formatCreateLabel={formatCreateLabelOption}
              placeholder={t("COMMON.GROUP")}
              onChange={changeLabels}
              onCreateOption={handleCreateLabel}
            />
            <VersionSelector />
            <Button
              simple
              onClick={onResetFiltersClick}
              icon="refreshAlt"
            >
              {t("COMMON.RESET")}
            </Button>
          </div>
          <div className={b("actions")}>
            <Button
              className={b("export-button")}
              bordered
              onClick={exportScenariosClick}
            >
              <Icon type="documentDownload" />
              {t("SCENARIO_GRAPHS.SCENARIOS_TO_JSON")}
            </Button>
          </div>
        </>}
    </div>
  );
}

export default memo(Filters);
