import React, { FC } from 'react';
import { SvgProps } from '../types';

const CloseIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3536 6.04652C19.5489 5.85126 19.5489 5.53468 19.3536 5.33942L18.6607 4.64652C18.4655 4.45126 18.1489 4.45126 17.9536 4.64652L12.0001 10.6001L6.04652 4.64652C5.85126 4.45126 5.53468 4.45126 5.33942 4.64652L4.64652 5.33942C4.45126 5.53468 4.45126 5.85126 4.64652 6.04652L10.6001 12.0001L4.64652 17.9536C4.45126 18.1489 4.45126 18.4655 4.64652 18.6607L5.33942 19.3536C5.53468 19.5489 5.85126 19.5489 6.04652 19.3536L12.0001 13.4001L17.9536 19.3536C18.1489 19.5489 18.4655 19.5489 18.6607 19.3536L19.3536 18.6607C19.5489 18.4655 19.5489 18.1489 19.3536 17.9536L13.4001 12.0001L19.3536 6.04652Z" fill={color} />
  </svg>
);

export default CloseIcon;
