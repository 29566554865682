import { createAsyncAction } from "typesafe-actions";

import { CustomConfig } from "../../types/models/CustomConfig";
import { ErrorAction } from "../reducers";

export const loadCustomConfig = createAsyncAction(
  'config/load/requested',
  'config/load/succeeded',
  'config/load/failed',
)<undefined, CustomConfig | undefined, ErrorAction>(); // Сервер может ответить 204,
// в этом случае в success будет передано undefined

export const updateCustomConfig = createAsyncAction(
  'config/update/requested',
  'config/update/succeeded',
  'config/update/failed',
)<CustomConfig, CustomConfig, ErrorAction>();

export const loadFullConfiguration = createAsyncAction(
  'config/full/requested',
  'config/full/succeeded',
  'config/full/failed',
)<undefined, Object, ErrorAction>();
