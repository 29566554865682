import React from 'react';
import { SvgProps } from '../types';

function HistoryIcon({
  width = '24',
  height = '24',
  color = 'currentColor',
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C12.5523 8 13 8.44772 13 9V12.4648L15 13.8321C15.4595 14.1384 15.5837 14.7593 15.2774 15.2188C14.971 15.6783 14.3501 15.8025 13.8906 15.4962L11.4453 13.8321C11.1671 13.6466 11 13.3344 11 13V9C11 8.44772 11.4477 8 12 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C8.13401 5 5 8.13401 5 12H3C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C9.42625 21 7.10351 19.9183 5.46443 18.1876L6.91657 16.8124C8.19367 18.1609 9.99803 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5Z"
        fill={color}
      />
      <path
        d="M8 11H0L4 16L8 11Z"
        fill={color}
      />
    </svg>
  );
}

export default HistoryIcon;
