import React from 'react';

import { SvgProps } from '../types';

function HeadphonesIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98372 19.9952C5.98592 19.9968 5.98812 19.9983 5.99032 19.9999L6.01737 19.9689L9.05966 17.5022C9.46816 16.7897 8.86016 14.8137 8.10966 13.1322C7.35916 11.4507 6.23816 9.91174 5.45916 9.75024L3.71908 10.0911C3.97157 9.20777 4.37075 8.3501 4.92296 7.55295C7.63231 3.64181 12.9993 2.66757 16.9105 5.37695C17.6292 5.87481 18.2487 6.46242 18.7645 7.11424C19.469 8.00467 19.9798 9.01493 20.2851 10.08C20.2863 10.0842 20.2875 10.0884 20.2887 10.0926L18.5412 9.75024C17.7622 9.91174 16.6412 11.4507 15.8907 13.1322C15.1402 14.8137 14.5322 16.7897 14.9407 17.5022L17.1905 19.3264C15.9975 20.2283 14.5625 20.8268 13.0001 21.0074L12.0002 22.9348C15.5704 22.9348 18.7238 21.1504 20.6173 18.4248L20.6238 18.4293C22.4078 15.8538 22.8795 12.7597 22.1828 9.93981C21.9717 9.08071 21.6548 8.26322 21.2466 7.50198C19.4793 4.20624 16.0015 1.96484 12.0002 1.96484C6.44923 1.96484 1.90643 6.27799 1.53918 11.7364L1.543 11.7367C1.31956 15.0203 2.6482 18.3325 5.32302 20.5395L5.98372 19.9952Z"
        fill={color}
      />
    </svg>
  );
}

export default HeadphonesIcon;
