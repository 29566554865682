import React from 'react';

import { SvgProps } from '../types';

function ListCheckedIcon({
  width = "24",
  height = "24",
  color = "currentColor",
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.93945 15.0684C9.67383 15.0684 11.9688 12.7832 11.9688 10.0293C11.9688 7.27539 9.70312 5 6.93945 5C4.18555 5 1.91016 7.27539 1.91016 10.0293C1.91016 12.8027 4.18555 15.0684 6.93945 15.0684ZM14.2832 8.26172H21.207C21.6953 8.26172 22.0859 7.88086 22.0859 7.39258C22.0859 6.91406 21.6953 6.52344 21.207 6.52344H14.2832C13.8047 6.52344 13.4141 6.91406 13.4141 7.39258C13.4141 7.88086 13.8047 8.26172 14.2832 8.26172ZM6.35352 12.998C6.16797 12.998 5.93359 12.9297 5.79688 12.7637L3.95117 10.7617C3.85352 10.6641 3.78516 10.4688 3.78516 10.293C3.78516 9.87305 4.11719 9.58984 4.48828 9.58984C4.72266 9.58984 4.89844 9.67773 5.01562 9.81445L6.31445 11.2305L8.79492 7.7832C8.93164 7.60742 9.13672 7.48047 9.39062 7.48047C9.77148 7.48047 10.0938 7.7832 10.0938 8.17383C10.0938 8.30078 10.0449 8.45703 9.94727 8.58398L6.93945 12.7344C6.82227 12.9004 6.59766 12.998 6.35352 12.998ZM14.2832 13.3789H21.207C21.6953 13.3789 22.0859 12.998 22.0859 12.5098C22.0859 12.0215 21.6953 11.6406 21.207 11.6406H14.2832C13.8047 11.6406 13.4141 12.0215 13.4141 12.5098C13.4141 12.998 13.8047 13.3789 14.2832 13.3789ZM2.76953 18.4961H21.207C21.6953 18.4961 22.0859 18.1152 22.0859 17.627C22.0859 17.1387 21.6953 16.7578 21.207 16.7578H2.76953C2.28125 16.7578 1.90039 17.1387 1.90039 17.627C1.90039 18.1152 2.28125 18.4961 2.76953 18.4961Z"
        fill={color}
      />
    </svg>
  );
}

export default ListCheckedIcon;
