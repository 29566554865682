import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { subDays } from 'date-fns';

import {
  Dialog, DialogsHistorySearchFilter, RequestMessage, ResponseMessage, SearchTextLocation,
} from '../../types/models/Dialog';
import * as actions from './actions';
import * as projectActions from '../projects/actions';
import * as graphChatActions from '../graphChat/actions';
import cacheService from '../../services/cache/cacheService';
import { CacheItem } from '../../services/cache/cacheItem';
import { WithLoading } from '../reducers';
import { GraphDebugBlock } from '../../types/models/GraphDebugBlock';

export type GraphDebugBlockWithLoading = Required<WithLoading<GraphDebugBlock[] | null>>;

export type DialogsState = {
  searchFilter: DialogsHistorySearchFilter,
  newSearchFilter: DialogsHistorySearchFilter,
  debugBlocksById: Record<Dialog['id'], GraphDebugBlockWithLoading> // new debug
  history: {
    value: Dialog[],
    loading: boolean,
    total: number,
  },
  currentDialog: {
    value: Dialog,
    loading: boolean,
  },
  liveDialog: LiveDialog,
  liveSending: boolean,
  dialogsHistoryExportProcessing: boolean,
  markLoading: boolean,
  processExamplesModalOpened: boolean,
  historyExportDialogsModalOpened: boolean,
  exportDialogsModalOpened: boolean,
  showDialogTiming: boolean,
}

export type DialogMessage = RequestMessage | ResponseMessage;

type LiveFeatureValues = { [featureSlug: string]: string | number | boolean };

export type LiveDialog = {
  id: string,
  messages: DialogMessage[],
  features: LiveFeatureValues,
  savedUserMessage: RequestMessage | null,
}

export const defaultFilters: DialogsHistorySearchFilter = {
  scenarios: [],
  dateTo: null,
  dateFrom: +subDays(new Date(), 7),
  resolution: null,
  resolutionOld: null,
  mode: null,
  status: null,
  chatId: null,
  samplingDeprecated: null,
  newSampling: null,
  iterations: null,
  version: null,
  tags: null,
  chatUser: null,
  maxUserScore: null,
  minUserScore: null,
  chatIds: null,
  searchText: null,
  searchTextLocation: [SearchTextLocation.request, SearchTextLocation.response],
};

const cachedFilter: DialogsHistorySearchFilter = cacheService.getItem<DialogsHistorySearchFilter>(
  CacheItem.DIALOGS_HISTORY_FILTER,
  defaultFilters,
);

const cachedNewFilter: DialogsHistorySearchFilter = cacheService.getItem<DialogsHistorySearchFilter>(
  CacheItem.DIALOGS_HISTORY_NEW_FILTER,
  defaultFilters,
);

const initialDialog: Dialog = {
  id: '',
  externalId: '',
  createdAt: '',
  messages: [],
  sessionId: '',
};

const initialState: DialogsState = {
  searchFilter: { ...defaultFilters, ...cachedFilter },
  newSearchFilter: { ...defaultFilters, ...cachedNewFilter },
  debugBlocksById: {},
  history: {
    value: [],
    total: 0,
    loading: false,
  },
  currentDialog: {
    value: initialDialog,
    loading: false,
  },
  liveDialog: {
    id: '',
    messages: [],
    features: cacheService.getItem(CacheItem.DISPATCH_FEATURES, {}),
    savedUserMessage: null,
  },
  liveSending: false,
  dialogsHistoryExportProcessing: false,
  markLoading: false,
  processExamplesModalOpened: false,
  historyExportDialogsModalOpened: false,
  exportDialogsModalOpened: false,
  // Пока выключаем кеширование, чтобы собрать метрику, нажимают ли вообще для просмотра таймингов
  // showDialogTiming: cacheService.getItem(CacheItem.SHOW_DIALOG_TIMING, false),
  showDialogTiming: false,
};
export const dialogsReducer: Reducer<DialogsState> = createReducer<DialogsState>(initialState)
  .handleAction(
    actions.loadDialogsHistory.request,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.loadDialogsHistory.request>): DialogsState => ({
      ...state,
      history: {
        ...state.history,
        value: payload.olderThan ? [...state.history.value] : [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadDialogsHistory.success,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.loadDialogsHistory.success>): DialogsState => {
      const sortedDialogs = payload.dialogs.sort((dialogA, dialogB) => {
        const dateA: number = +(new Date(dialogA.createdAt));
        const dateB: number = +(new Date(dialogB.createdAt));
        const valid = typeof dateA === 'number' && !Number.isNaN(dateA) && typeof dateB === 'number' && !Number.isNaN(dateB);
        if (!valid) return 0;

        return dateB - dateA;
      });
      return {
        ...state,
        history: {
          ...state.history,
          value: payload.olderThan ? state.history.value.concat(sortedDialogs) : sortedDialogs,
          loading: false,
          total: payload.olderThan ? state.history.total : payload.total,
          // Поле total отвечает за хранение общего количества диалогов.
          // Если отсылать olderThan, сервер возвращает сколько осталось "оставшихся" диалогов.
          // Поэтому если догружали диалоги, то не обновляем поле total
        },
      };
    },
  )
  .handleAction(
    actions.loadDialogsHistory.failure,
    (state: DialogsState): DialogsState => ({
      ...state,
      history: {
        ...state.history,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.searchDialogsHistory.request,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.searchDialogsHistory.request>): DialogsState => ({
      ...state,
      history: {
        ...state.history,
        value: payload.offset ? [...state.history.value] : [],
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.searchDialogsHistory.success,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.searchDialogsHistory.success>): DialogsState => ({
      ...state,
      history: {
        ...state.history,
        value: payload.offset ? state.history.value.concat(payload.dialogs) : payload.dialogs,
        loading: false,
        total: payload.total,
      },
    }),
  )
  .handleAction(
    actions.searchDialogsHistory.failure,
    (state: DialogsState): DialogsState => ({
      ...state,
      history: {
        ...state.history,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadDialogBySessionId.request,
    (state: DialogsState): DialogsState => {
      return {
        ...state,
        currentDialog: {
          ...state.currentDialog,
          value: initialDialog,
          loading: true,
        },
      };
    },
  )
  .handleAction(
    actions.loadDialogBySessionId.success,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.loadDialogBySessionId.success>): DialogsState => {
      return {
        ...state,
        currentDialog: {
          value: payload.dialog,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.loadDialogBySessionId.failure,
    (state: DialogsState): DialogsState => ({
      ...state,
      currentDialog: {
        value: initialDialog,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.setLiveChatMessages,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.setLiveChatMessages>): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        messages: payload,
      },
    }),
  )
  .handleAction(
    actions.clearLiveChat,
    (state: DialogsState): DialogsState => ({
      ...state,
      liveDialog: {
        ...initialState.liveDialog,
        features: {
          ...state.liveDialog.features,
        },
      },
      debugBlocksById: {},
    }),
  )
  .handleAction(
    actions.appendLiveMessage,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.appendLiveMessage>): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        messages: [...state.liveDialog.messages, payload],
      },
    }),
  )
  .handleAction(
    actions.deleteLastLiveMessage,
    (state: DialogsState): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        messages: state.liveDialog.messages.slice(0, -1),
      },
    }),
  )
  .handleAction(
    actions.sendLiveMessage.request,
    (state: DialogsState): DialogsState => ({
      ...state,
      liveSending: true,
    }),
  )
  .handleAction(
    actions.sendLiveMessage.success,
    (state: DialogsState): DialogsState => ({
      ...state,
      liveSending: false,
    }),
  )
  .handleAction(
    actions.sendLiveMessage.failure,
    (state: DialogsState): DialogsState => ({
      ...state,
      liveSending: false,
    }),
  )
  .handleAction(
    actions.saveUserMessage,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.saveUserMessage>): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        savedUserMessage: payload,
      },
    }),
  )
  .handleAction(
    projectActions.selectProject,
    (state: DialogsState): DialogsState => ({
      ...state,
      history: {
        ...initialState.history,
      },
      liveDialog: {
        ...initialState.liveDialog,
      },
    }),
  )
  .handleAction(
    actions.setLiveChatId,
    (state: DialogsState, { payload }: ActionType<typeof actions.setLiveChatId>): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        id: payload,
      },
    }),
  )
  .handleAction(
    actions.setFeatureDispatchValues,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.setFeatureDispatchValues>): DialogsState => {
      const liveFeaturesDispatchValues: LiveFeatureValues = payload
        .reduce<LiveFeatureValues>((accum, featureInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[featureInfo.featureSlug] = featureInfo.dispatchValue;

          return accum;
        }, {});

      return {
        ...state,
        liveDialog: {
          ...state.liveDialog,
          features: liveFeaturesDispatchValues,
        },
      };
    },
  )
  .handleAction(
    actions.setFeatureDispatchValue,
    (state: DialogsState, {
      payload: action,
    }: ActionType<typeof actions.setFeatureDispatchValue>): DialogsState => {
      const liveDialogFeaturesCopy = { ...state.liveDialog.features };

      if (action.featureSlug) {
        liveDialogFeaturesCopy[action.featureSlug] = action.dispatchValue;
      }

      return {
        ...state,
        liveDialog: {
          ...state.liveDialog,
          features: liveDialogFeaturesCopy,
        },
      };
    },
  )
  .handleAction(
    actions.resetFeatures,
    (state: DialogsState): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        features: {},
      },
    }),
  )
  .handleAction(
    actions.setDialogsHistoryFilter,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.setDialogsHistoryFilter>): DialogsState => {
      // eslint-disable-next-line prefer-const
      let { dateFrom, dateTo, ...restFilter } = payload;

      if (typeof dateTo === 'number' && dateFrom > dateTo) {
        const weekSeconds = 1 * 60 * 60 * 24 * 7;
        dateTo = dateFrom + (weekSeconds);
      }

      return {
        ...state,
        searchFilter: {
          dateFrom,
          dateTo,
          ...restFilter,
        },
      };
    },
  )
  .handleAction(
    actions.setSearchDialogsHistoryFilter,
    (state: DialogsState, {
      payload,
    }: ActionType<typeof actions.setSearchDialogsHistoryFilter>): DialogsState => {
      // eslint-disable-next-line prefer-const
      let { dateFrom, dateTo, ...restFilter } = payload;

      if (typeof dateTo === 'number' && dateFrom > dateTo) {
        const weekSeconds = 1 * 60 * 60 * 24 * 7;
        dateTo = dateFrom + (weekSeconds);
      }

      return {
        ...state,
        newSearchFilter: {
          dateFrom,
          dateTo,
          ...restFilter,
        },
      };
    },
  )
  .handleAction(
    actions.exportDialogsHistory.request,
    (state: DialogsState): DialogsState => ({
      ...state,
      dialogsHistoryExportProcessing: true,
    }),
  )
  .handleAction(
    actions.exportDialogsHistory.success,
    (state: DialogsState): DialogsState => ({
      ...state,
      dialogsHistoryExportProcessing: false,
    }),
  )
  .handleAction(
    actions.exportDialogsHistory.failure,
    (state: DialogsState): DialogsState => ({
      ...state,
      dialogsHistoryExportProcessing: false,
    }),
  )
  .handleAction(
    actions.loadDebugBlocksById.request,
    (state: DialogsState, {
      payload: { debugId },
    }: ActionType<typeof actions.loadDebugBlocksById.request>): DialogsState => ({
      ...state,
      debugBlocksById: {
        ...state.debugBlocksById,
        [debugId]: { loading: true, value: null },
      },
    }),
  )
  .handleAction(
    actions.loadDebugBlocksById.success,
    (state: DialogsState, {
      payload: { debugBlocks, debugId },
    }: ActionType<typeof actions.loadDebugBlocksById.success>): DialogsState => ({
      ...state,
      debugBlocksById: {
        ...state.debugBlocksById,
        [debugId]: { loading: false, value: debugBlocks },
      },
    }),
  )
  .handleAction(
    actions.loadDebugBlocksById.failure,
    (state: DialogsState, {
      payload: { payload: { debugId } },
    }: ActionType<typeof actions.loadDebugBlocksById.failure>): DialogsState => ({
      ...state,
      debugBlocksById: {
        ...state.debugBlocksById,
        [debugId]: { loading: false, value: null },
      },
    }),
  )
  .handleAction(
    actions.resetDebugBlocks,
    (state: DialogsState): DialogsState => ({
      ...state,
      debugBlocksById: {},
    }),
  )
  .handleAction(
    graphChatActions.closeGraphTestingChat,
    (state: DialogsState): DialogsState => ({
      ...state,
      debugBlocksById: {},
    }),
  )
  .handleAction(
    actions.openProcessExamplesModal,
    (state: DialogsState): DialogsState => ({
      ...state,
      processExamplesModalOpened: true,
    }),
  )
  .handleAction(
    actions.closeProcessExamplesModal,
    (state: DialogsState): DialogsState => ({
      ...state,
      processExamplesModalOpened: false,
    }),
  )
  .handleAction(
    actions.openHistoryExportDialogsModal,
    (state: DialogsState): DialogsState => ({
      ...state,
      historyExportDialogsModalOpened: true,
    }),
  )
  .handleAction(
    actions.closeHistoryExportDialogsModal,
    (state: DialogsState): DialogsState => ({
      ...state,
      historyExportDialogsModalOpened: false,
    }),
  )
  .handleAction(
    actions.openExportDialogsModal,
    (state: DialogsState): DialogsState => ({
      ...state,
      exportDialogsModalOpened: true,
    }),
  )
  .handleAction(
    actions.closeExportDialogsModal,
    (state: DialogsState): DialogsState => ({
      ...state,
      exportDialogsModalOpened: false,
    }),
  )
  .handleAction(
    actions.setDialogViewed,
    (
      state: DialogsState,
      { payload }: ActionType<typeof actions.setDialogViewed>,
    ): DialogsState => ({
      ...state,
      history: {
        ...state.history,
        value: state.history.value.map(dialog => dialog.id === payload ? {
          ...dialog,
          viewed: true,
        } : dialog),
      },
    }),
  )
  .handleAction(
    actions.setFeatureDispatchValues,
    (
      state: DialogsState,
      { payload }: ActionType<typeof actions.setFeatureDispatchValues>,
    ): DialogsState => {
      const featuresMap = payload.reduce<Record<string, number | string>>((result, feature) => {
        result[feature.featureSlug] = feature.dispatchValue;

        return result;
      }, {});
      return {
        ...state,
        liveDialog: {
          ...state.liveDialog,
          features: featuresMap,
        },
      };
    },
  )
  .handleAction(
    actions.setFeatureDispatchValue,
    (
      state: DialogsState,
      { payload }: ActionType<typeof actions.setFeatureDispatchValue>,
    ): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        features: {
          ...state.liveDialog.features,
          [payload.featureSlug]: payload.dispatchValue,
        },
      },
    }),
  )
  .handleAction(
    actions.resetFeatures,
    (
      state: DialogsState,
    ): DialogsState => ({
      ...state,
      liveDialog: {
        ...state.liveDialog,
        features: {},
      },
    }),
  )
  .handleAction(
    actions.closeDialog,
    (
      state: DialogsState,
    ): DialogsState => ({
      ...state,
      currentDialog: {
        ...state.currentDialog,
        value: initialDialog,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.resetDialogs,
    (state: DialogsState): DialogsState => ({
      ...state,
      history: {
        ...initialState.history,
      },
    }),
  )
  .handleAction(
    actions.setShowDialogTiming,
    (
      state: DialogsState,
      { payload }: ActionType<typeof actions.setShowDialogTiming>,
    ): DialogsState => ({
      ...state,
      showDialogTiming: payload,
    }),
  )
  .handleAction(
    actions.resetReducer,
    (): DialogsState => ({
      ...initialState,
    }),
  );
