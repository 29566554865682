import { ActionType } from "typesafe-actions";
import {
  isEqual,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMonth,
  isValid,
  addDays,
  addMonths,
  addHours,
} from "date-fns";
import { CallsStatisticsItem } from "../../types/models/CallsStatistics";
import { loadCallsStatistics } from './actions';
import { ProjectsState } from "./reducer";
import { UserAccessBackend } from "../../types/backendModels/UserAccessesBackend";
import { Project } from "../../types/models/Project";
import { UserRole } from "../../types/models/UserAccesses";
import { DialogsStatisticsGroup, getEmptyDialogsStatisticsGroup } from "../../types/models/AutomationStatistics";
import { StatisticsPeriod } from "../../types/models/StatisticsPeriod";

type Payload = ActionType<typeof loadCallsStatistics.success>;
export const addItemsToGroup = (state: ProjectsState, { payload }: Payload): ProjectsState => {
  const result = {
    ...state,
    callsStatisticsLoading: false,
  };

  const { groupId } = payload;

  // Первичный запрос
  if (!result.callsTableStatistics?.items ||
    !groupId ||
    result.callsTableStatistics.id === groupId
  ) {
    return {
      ...result,
      callsTableStatistics: payload.data,
      callsChartStatistics: payload.chartStatistics || [],
    };
  }

  // дозапрос - ищем по id группу и обновляем объекты по цепочке
  const findPathToItem = (item: CallsStatisticsItem, chain: string[]): string[] | null => {
    if (item.id && item.id === groupId) {
      return chain.concat(item.id);
    }

    const { items } = item;

    if (items?.length) {
      for (let i = 0; i < items.length; i += 1) {
        const ids = findPathToItem(items[i], chain);

        if (ids) return item.id ? [item.id, ...ids] : ids;
      }
    }

    return null;
  };

  const itemIds = findPathToItem(result.callsTableStatistics, []);

  if (!itemIds) return result;

  const updateItems = (item: CallsStatisticsItem, ids: string[]): CallsStatisticsItem => ({
    ...item,
    items: item.id === groupId ?
      payload.data.items :
      item.items?.map(
        subitem => (ids[0] && subitem.id === ids[0] ?
          updateItems(subitem, ids.slice(1)) :
          subitem),
      ),
  });

  result.callsTableStatistics = updateItems(result.callsTableStatistics, itemIds.slice(1));

  return result;
};

export type StatisticsTarget = "scenario" | "topic";
export enum StatisticsTab {
  Dialogs = "dialogs",
  Calls = "calls"
}

export function parseUserAccesses(list: UserAccessBackend[]): Record<
  Project['slug'],
  { group: UserRole, capabilities: string[] }
> {
  return list.reduce<Record<
  Project['slug'],
  { group: UserRole, capabilities: string[] }
>>(
  (result, access) => {
    if (!access.project_slug) return result;

    // @ts-ignore
    result[access.project_slug] = {
      group: access.group as UserRole,
      capabilities: access.capabilities || [],
    };
    return result;
  },
  {},
);
}

export function msToSeconds(milliseconds: number): number {
  return Math.round(milliseconds / 1000);
}

export function placeStatisticsIntoDates(
  dateFrom: Date,
  dateTo: Date,
  statistics: DialogsStatisticsGroup[],
  period: StatisticsPeriod = StatisticsPeriod.Day,
): DialogsStatisticsGroup[] {
  const result: DialogsStatisticsGroup[] = [];

  let compareFunction: (leftDate: Date, rightDate: Date) => boolean;
  let addFunction: (date: Date, amount: number) => Date;

  switch (period) {
    case StatisticsPeriod.Hour:
      compareFunction = isSameHour;
      addFunction = addHours;
      break;
    case StatisticsPeriod.Month:
      compareFunction = isSameMonth;
      addFunction = addMonths;
      break;
    default:
      compareFunction = isSameDay;
      addFunction = addDays;
      break;
  }

  let currentDate = new Date(dateFrom);

  while (isBefore(currentDate, dateTo) || isEqual(currentDate, dateTo)) {
    // eslint-disable-next-line no-loop-func
    const targetStatistics = statistics.find(s => {
      const date = new Date(s.title);

      if (!isValid(date)) return false;

      return compareFunction(currentDate, date);
    });

    if (targetStatistics) {
      result.push(targetStatistics);
    } else {
      result.push(getEmptyDialogsStatisticsGroup(currentDate.toString()));
    }

    currentDate = addFunction(currentDate, 1);
  }

  return result;
}
