import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { DialogsHistorySearchFilter } from '../../types/models/Dialog';

import FilterItem from './filterItem';

import './styles.scss';

type DialogsHistorySearchFilterProps = {
  filters: DialogsHistorySearchFilter,
  hide?: Array<keyof DialogsHistorySearchFilter>,
}

export default function DialogsSearchFilterOverview({
  filters,
  hide = [],
}: DialogsHistorySearchFilterProps) {
  const { t } = useTranslation();
  const {
    dateFrom,
    dateTo,
    mode,
    status,
    samplingDeprecated,
    chatId,
    tags,
    scenarios,
    minUserScore,
    maxUserScore,
    iterations,
    searchText,
  } = filters;

  return (
    <div className="filters-list">
      {!hide.includes('dateFrom') && (
        <FilterItem
          label={t("COMMON.PERIOD_FROM")}
          value={format(new Date(dateFrom), 'dd MMM yyyy')}
        />
      )}
      {!hide.includes('dateTo') && (
        <FilterItem
          label={t("COMMON.PERIOD_TO")}
          value={dateTo ? format(new Date(dateTo), 'dd MMM yyyy') : "-"}
        />
      )}
      {!hide.includes('mode') && (
        <FilterItem
          label={t("PAGE_DIALOGS_HISTORY.SIMULATED")}
          value={typeof mode === 'boolean' ?
            (t(mode ? 'COMMON.YES' : 'COMMON.NO')) :
            "-"}
        />
      )}
      {!hide.includes('status') && (
        <FilterItem
          label={t("NEW_PAGE_DIALOGS_HISTORY.FILTERS.DIALOGS")}
          value={status ? t(`NEW_PAGE_DIALOGS_HISTORY.FILTERS.DIALOG_STATUSES.${status.toUpperCase()}`) : '-'}
        />
      )}
      {!hide.includes('samplingDeprecated') && (
        <FilterItem
          label={t("ENTITIES.SAMPLING")}
          value={samplingDeprecated ? samplingDeprecated.name : "-"}
        />
      )}
      {!hide.includes('chatId') && (
        <FilterItem
          label={t("PAGE_DIALOGS_HISTORY.DIALOG_ID")}
          value={chatId || "-"}
        />
      )}
      {!hide.includes('tags') && (
        <FilterItem
          label={t("ENTITIES.TAGS")}
          value={tags?.join(', ') || "-"}
        />
      )}
      {!hide.includes('scenarios') && (
        <FilterItem
          label={t("PAGE_DIALOGS_HISTORY.SCENARIOS")}
          value={(scenarios || [])?.map(s => s.title).join(', ') || "-"}
        />
      )}
      {!hide.includes('minUserScore') && (
        <FilterItem
          label={t("NEW_PAGE_DIALOGS_HISTORY.FILTERS.MIN_USER_RATING")}
          value={typeof minUserScore === 'number' ? String(minUserScore) : "-"}
        />
      )}
      {!hide.includes('maxUserScore') && (
        <FilterItem
          label={t("NEW_PAGE_DIALOGS_HISTORY.FILTERS.MAX_USER_RATING")}
          value={typeof maxUserScore === 'number' ? String(maxUserScore) : "-"}
        />
      )}
      {!hide.includes('iterations') && (
        <FilterItem
          label={t("NEW_PAGE_DIALOGS_HISTORY.FILTERS.ITERATIONS")}
          value={iterations || "-"}
        />
      )}
      {!hide.includes('searchText') && (
        <FilterItem
          label={t("NEW_PAGE_DIALOGS_HISTORY.FILTERS.SEARCH_TEXT")}
          value={searchText || "-"}
        />
      )}
    </div>
  );
}
