import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  setHours,
  setMinutes,
  startOfDay,
  subDays,
  subMonths,
  subYears,
} from "date-fns";

import { StatisticsPeriod } from "../../types/models/StatisticsPeriod";

export enum HelpDateButton {
  yesterday = 'yesterday',
  week = 'week',
  tenDays = '10d',
  month = 'month',
  threeMonths = '3months',
  year = 'year',
}

export function getHelpDateButtons(period: StatisticsPeriod): HelpDateButton[] {
  switch (period) {
    case StatisticsPeriod.Hour:
      return [
        HelpDateButton.yesterday,
      ];
    case StatisticsPeriod.Day:
      return [
        HelpDateButton.yesterday,
        HelpDateButton.week,
        HelpDateButton.tenDays,
        HelpDateButton.month,
      ];
    case StatisticsPeriod.Week:
    case StatisticsPeriod.Month:
      return [
        HelpDateButton.month,
        HelpDateButton.threeMonths,
        HelpDateButton.year,
      ];
    default:
      return [];
  }
}

export function getHelpDatePeriod(button: HelpDateButton): [Date, Date] {
  const from = startOfDay(new Date());
  const to = startOfDay(new Date());

  switch (button) {
    case HelpDateButton.yesterday:
      return [subDays(from, 1), to];
    case HelpDateButton.week:
      return [subDays(from, 7), to];
    case HelpDateButton.tenDays:
      return [subDays(from, 10), to];
    case HelpDateButton.month:
      return [subMonths(from, 1), to];
    case HelpDateButton.threeMonths:
      return [subMonths(from, 3), to];
    case HelpDateButton.year:
      return [subYears(from, 1), to];
    default:
      return [from, to];
  }
}

export function formatDateWithTime(date: Date) {
  return format(date, "dd MMM HH:mm");
}

function formatSingleDate(
  date: Date,
): string {
  return format(date, "dd MMMM");
}

export function formatPeriodDate({
  dateFrom,
  dateTo,
  fullText,
} :{
  dateFrom: Date;
  dateTo?: Date;
  fullText?: boolean;
}) {
  if (!dateFrom) return "";

  if (typeof dateFrom === 'string') {
    dateFrom = new Date(dateFrom);
  }

  if (dateTo) {
    return formatRangeDate(dateFrom, dateTo, false, fullText);
  }

  return formatSingleDate(dateFrom || dateTo);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDateToInputValue(value: any): string {
  try {
    if (!value) return "";

    const date = new Date(value);

    let month = String(date.getMonth() + 1);
    month = month.length === 1 ? `0${month}` : month;

    let days = String(date.getDate());
    days = days.length === 1 ? `0${days}` : days;
    return `${date.getFullYear()}.${month}.${days}`;
  } catch {
    return "";
  }
}

export function isDatesDifferent(
  dateA: Date | null,
  dateB: Date | null,
): boolean {
  if (typeof dateA !== typeof dateB) return true;

  if (dateA === null && dateB === null) return false;

  if (isSameDay(dateA as Date, dateB as Date)) return false;

  return true;
}

export function isValidInputValue(inputValue: string): boolean {
  let [dateFrom = "", dateTo = ""] = inputValue.split('-');

  const from = new Date(dateFrom.trim());
  const to = new Date(dateTo.trim());

  const fromValid = dateFrom.length === 0 || isValid(from);
  const toValid = dateTo.length === 0 || isValid(to);

  return fromValid && toValid;
}

export function parseInputValueToDate(inputValue: string): Date | null {
  try {
    const date = new Date(inputValue.trim());
    if (isValid(date)) {
      return date;
    }

    return null;
  } catch {
    return null;
  }
}

export function changeTime(
  originalDate: Date | null,
  targetDate: Date | null,
): Date | null {
  if (originalDate === null || targetDate === null) return null;

  const newDate = new Date(targetDate);
  setHours(newDate, originalDate.getHours());
  setMinutes(newDate, originalDate.getMinutes());

  return newDate;
}

export function getInitialInputValue(
  dateFrom: Date | null,
  dateTo: Date | null,
  value: Date | null,
  selectsRange: boolean,
) {
  if (selectsRange) {
    if (!dateFrom) return "";
    return `${parseDateToInputValue(dateFrom)} - ${parseDateToInputValue(dateTo)}`;
  }

  if (!value) return "";

  return parseDateToInputValue(value);
}

function formatRangeDate(
  dateFrom: Date,
  dateTo: Date,
  withTime?: boolean,
  fullText?: boolean,
): string {
  const from = dateFrom;
  const to = dateTo;

  const sameYear: boolean = isSameYear(from, to);
  const sameMonth: boolean = isSameMonth(from, to);
  const sameDay: boolean = isSameDay(from, to);

  if (fullText) {
    return withTime ?
      `${format(from, "dd MMMM yyyy")} — ${format(to, "dd MMMM yyyy HH:mm")}` :
      `${format(from, "dd MMMM yyyy")} — ${format(to, "dd MMMM yyyy")}`;
  }

  if (sameDay) {
    return withTime ?
      `${format(from, "dd MMM yyyy HH:mm")} — ${format(to, 'HH:mm')}` :
      `${format(from, "dd MMM yyyy")}`;
  }

  if (sameMonth) {
    return `${format(from, 'dd')} — ${format(to, 'dd MMM yyyy')}`;
  }

  if (sameYear) {
    return `${format(from, 'dd MMM')} — ${format(to, 'dd MMM yyyy')}`;
  }

  return `${format(from, "dd MMM yy")} — ${format(to, "dd MMM yy")}`;
}

export function validateDates(
  dateFrom: Date | null,
  dateTo: Date | null,
  minDate?: Date | null,
  maxDate?: Date | null,
): [Date | null, Date | null] {
  if (!minDate && !maxDate) return [dateFrom, dateTo];

  let validDateFrom = dateFrom;
  let validDateTo = dateTo;

  if (maxDate) {
    if (validDateTo) {
      validDateTo = isAfter(validDateTo, maxDate) ?
        maxDate :
        validDateTo;
    } else {
      const now = endOfDay(new Date());
      validDateTo = isAfter(now, maxDate) ?
        maxDate :
        validDateTo;
    }
  }

  if (minDate && validDateFrom && isBefore(validDateFrom, minDate)) {
    validDateFrom = minDate;
  }

  if (validDateFrom && validDateTo && isAfter(validDateFrom, validDateTo)) {
    validDateFrom = validDateTo;
  }

  return [validDateFrom, validDateTo];
}
