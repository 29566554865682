import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { block } from "bem-cn";
import { Trans, useTranslation } from "react-i18next";
import { format, isSameYear } from "date-fns";

import { Task } from "../../types/models/Task";

import { ModalProps } from "../../services/modal";

import { AppState } from "../../redux/reducers";
import { getVoiceTaskById } from "../../redux/tasks/selectors";

import UserWithLaptop from "../../components-new/icon/images/userWithLaptop";
import SideModal from "../../components-new/sideModal";
import Button from "../../components-new/button";
import Icon from "../../components-new/icon";

import './styles.scss';

const b = block('cancel-call-modal');

export type CancelCallModalProps = {
  taskId: Task['id'],
  onSubmit: (taskId: Task['id']) => void,
}

function CancelCallModal({
  taskId,
  onSubmit,
  close,
}: CancelCallModalProps & ModalProps) {
  const { t } = useTranslation();

  const task = useSelector((state: AppState) => getVoiceTaskById(state, taskId));

  const handleCancelCallClick = useCallback(
    () => {
      if (!task) return;

      onSubmit(task.id);
    },
    [onSubmit, task],
  );

  const {
    dateFrom,
    dateTo,
  } = useMemo(
    () => {
      if (!task || !task.params?.beginCallsAt) {
        return {
          dateFrom: "",
          dateTo: "",
        };
      }

      const start = new Date(task.params.beginCallsAt as string);
      let end = task.params?.endCallsAt;

      if (!end) {
        return {
          dateFrom: format(start, "dd MMM"),
          dateTo: format(start, "dd MMM yyyy"),
        };
      }

      end = new Date(end as string);

      const sameYear = isSameYear(start, end as Date);

      if (sameYear) {
        return {
          dateFrom: format(start, "dd MMM"),
          dateTo: format(end as Date, "dd MMM yyyy"),
        };
      }

      return {
        dateFrom: format(start, "dd MMM yyyy"),
        dateTo: format(end as Date, "dd MMM yyyy"),
      };
    },
    [task],
  );

  const loading = !!task?.cancelling;

  return (
    <SideModal
      open
      title={t(`MODALS.CANCEL_CALL.TITLE_${task?.status.toUpperCase()}`)}
      onClose={close}
      footer={(
        <>
          <Button
            fluid
            size="large"
            onClick={close}
          >
            {t("COMMON.CLOSE")}
          </Button>
          <Button
            fluid
            primary
            size="large"
            disabled={loading}
            onClick={handleCancelCallClick}
          >
            {loading && <Icon type="loaderBrackets" />}
            <span className="font-bold">{t("MODALS.CANCEL_CALL.CANCEL")}</span>
          </Button>
        </>
      )}
    >
      <div className={b("wrapper")}>
        <UserWithLaptop />
        <span>
          <Trans
            i18nKey={`MODALS.CANCEL_CALL.MSG_CONFIRM_${task?.status.toUpperCase()}`}
            components={{
              b: <span className="font-bold" />,
            }}
            tOptions={{
              dateFrom: dateFrom || `{${t("COMMON.NOT_SET")}}`,
              dateTo: dateTo || `{${t("COMMON.NOT_SET")}}`,
              callName: task?.name,
            }}
          />
        </span>
      </div>
    </SideModal>
  );
}

export default memo(CancelCallModal);
