export enum AccountType {
  whatsapp = "whatsapp",
  telegram = "telegram",
}

export enum EnableValue {
  enable = "enable",
  disable = "disable",
}

export enum AccountStatus {
  enabled = "enabled",
  disabled = "disabled",
  notCreated = "not_created",
}

export type TelegramAccountData = {
  type: AccountType.telegram;
  token: string;
  owners?: Array<string>;
  description?: string;
  usersTtl?: number;
  botInfo?: BotInfo;
}

export type WhatsappAccountData = {
  type: AccountType.whatsapp;
  owners?: Array<string>;
  apikey?: string;
  description?: string;
  phone?: string;
  baseUrlPrefix?: string;
}

export type AccountUpdate = TelegramAccountData
  | WhatsappAccountData;

export type TelegramAccountUpdate = {
  type: AccountType.telegram;
  token?: string;
  owners?: Array<string>;
  description?: string;
  usersTtl?: number;
  botInfo?: BotInfo;
}

export type WhatsappAccountUpdate = {
  type: AccountType.whatsapp;
  owners?: Array<string>;
  apikey?: string;
  description?: string;
  phone?: string;
  baseUrlPrefix?: string;
}

export type AccountStatusModel = {
  status: AccountStatus;
  data: TelegramAccountData | WhatsappAccountData;
}

export type AccountStatuses = {
  telegram: AccountStatusModel;
  whatsapp: AccountStatusModel;
}

export type AccountData = TelegramAccountData | WhatsappAccountData;

export type BotInfo = {
  firstName: string;
  username: string;
  canJoinGroups: boolean;
  canReadAllGroupMessages: boolean;
  supportsInlineQueries: boolean;
}

export function getEmptyTelegramAccountData(): TelegramAccountData {
  return {
    type: AccountType.telegram,
    token: "",
    owners: [],
    description: "",
    usersTtl: undefined,
  };
}
