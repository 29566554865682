import { Reducer } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import { AuthProvider } from "../../types/models/AuthProvider";

import * as actions from './actions';

type ProvidersState = {
  list: AuthProvider[];
  loading: boolean;
  updating: boolean;
  providerToEdit: AuthProvider['name'] | null;
  providerOpened: boolean;
}

const initialState: ProvidersState = {
  list: [],
  loading: false,
  updating: false,
  providerToEdit: null,
  providerOpened: false,
};

export const providersReducer: Reducer<ProvidersState> = createReducer(initialState)
  .handleAction(
    actions.loadProviders.request,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      list: [],
      loading: true,
    }),
  )
  .handleAction(
    actions.loadProviders.success,
    (
      state: ProvidersState,
      { payload }: ActionType<typeof actions.loadProviders.success>,
    ): ProvidersState => ({
      ...state,
      list: payload,
      loading: false,
    }),
  )
  .handleAction(
    actions.loadProviders.failure,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      loading: false,
    }),
  )
  .handleAction(
    actions.createProvider.request,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: true,
    }),
  )
  .handleAction(
    actions.createProvider.success,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: false,
    }),
  )
  .handleAction(
    actions.createProvider.failure,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: false,
    }),
  )
  .handleAction(
    actions.updateProvider.request,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: true,
    }),
  )
  .handleAction(
    actions.updateProvider.success,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: false,
    }),
  )
  .handleAction(
    actions.updateProvider.failure,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: false,
    }),
  )
  .handleAction(
    actions.deleteProvider.request,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: true,
    }),
  )
  .handleAction(
    actions.deleteProvider.success,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: false,
    }),
  )
  .handleAction(
    actions.deleteProvider.failure,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      updating: false,
    }),
  )
  .handleAction(
    actions.setProviderToEdit,
    (
      state: ProvidersState,
      { payload }: ActionType<typeof actions.setProviderToEdit>,
    ): ProvidersState => ({
      ...state,
      providerToEdit: payload,
      providerOpened: true,
    }),
  )
  .handleAction(
    actions.closeEditor,
    (state: ProvidersState): ProvidersState => ({
      ...state,
      providerToEdit: null,
      providerOpened: false,
    }),
  );
