import { format, isValid } from "date-fns";

import { objSnakeToCamel, removeNullFields } from "../../services/helpers/utilities";
import { VersionBackend } from "../backendModels/VersionBackend";
import { Version } from "../models/Version";

export function parseVersion(value: VersionBackend): Version {
  const createdDate = new Date(value.created);

  return {
    ...removeNullFields(objSnakeToCamel(value)),
    createdDateFormatted: isValid(createdDate) ?
      format(createdDate, 'HH:mm, dd MMMM yyyy') :
      '',
    pending: false,
  };
}
