import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { v4 as uuidv4 } from "uuid";

import { ScenarioGraphMeta } from '../../types/models/ScenarioGraphs';
import { FileMeta } from '../../types/models/Files';

import * as actions from './actions';
import { ScenarioGraphsList, TrainingItem } from './types';
import { GameDataDiagnosticsRecord } from '../../types/models/Games';
import { ScenarioGraphLabel } from '../../types/models/ScenarioGraphLabel';
import { ActionIdentifierSuggest } from '../../types/models/ActionSuggest';
import { ScenarioGraphParent, ScenarioGraphParentNode } from '../../types/models/ScenarioGraphParent';
import { ScenariosFilters } from '../../pages/scenarioGraphs/helpers';
import cacheService from '../../services/cache/cacheService';
import { CacheItem } from '../../services/cache/cacheItem';
import { ScenarioGroupName } from '../../types/backendModels/ScenarioGraphBackend';
import { scenarioGroupNames } from '../../pages/scenarioGraphs/components/filters';

export const defaultFilters: ScenariosFilters = {
  search: undefined,
  labels: undefined,
  labelsOperator: undefined,
  scenarioTypes: undefined,
};

const cachedFilter: ScenariosFilters = cacheService.getItem<ScenariosFilters>(
  CacheItem.SCENARIOS_FILTER,
  defaultFilters,
);

export type ScenarioGraphsState = {
  list: ScenarioGraphsList;
  total: number,
  loading: boolean;
  filters: ScenariosFilters;
  currentDragGroup?: ScenarioGroupName;
  loadingStat: boolean;
  uploadingCalling: boolean;
  uploadingCallingError: boolean;
  callingTopicSlug: string | null;
  uploadedFiles: FileMeta[];
  scenarioEditorOpened: boolean;
  parentsEditor: {
    loading: boolean;
    nodes: ScenarioGraphParentNode[];
    selected: Array<ScenarioGraphParent['id']>;
  },
  training: {
    scenarioSlug: ScenarioGraphMeta['slug'];
    loading: boolean;
    diagnostics: {
      showModal: boolean;
      showResultsModal: boolean;
      resultsCount: number;
      items: TrainingItem[];
      itemsToDelete: TrainingItem['text'][];
    },
    marking: {
      showModal: boolean;
      showResultsModal: boolean;
      resultsCount: number;
      items: TrainingItem[];
      itemsToDelete: TrainingItem['text'][];
    }
  },
  labels: {
    list: ScenarioGraphLabel[];
    loading: boolean;
  },
  actionIdentifierSuggests: {
    loading: boolean,
    values: ActionIdentifierSuggest[],
  },
  exportScenarios: {
    processing: boolean,
    completedCount: number;
    successScenarios: Array<ScenarioGraphMeta['title']>;
    errorScenarios: Array<ScenarioGraphMeta['title']>;
    zipLink: string;
  },
  addLabelScenariosModal: {
    initScenarios: Array<ScenarioGraphMeta['id']>,
    opened: boolean;
  },
  editLabelModal: {
    labelId: ScenarioGraphLabel['id'];
    opened: boolean;
  },
  deleteLabelModal: {
    labelId: ScenarioGraphLabel['id'];
    opened: boolean;
  },
  priorities: {
    currentPriorities: ScenarioGraphsList;
    loading: boolean;
  }
}

const initialState: ScenarioGraphsState = {
  list: [],
  total: 0,
  loading: false,
  currentDragGroup: undefined,
  loadingStat: true,
  uploadingCalling: false,
  uploadingCallingError: false,
  callingTopicSlug: null,
  uploadedFiles: [],
  scenarioEditorOpened: false,
  parentsEditor: {
    loading: false,
    nodes: [],
    selected: [],
  },
  training: {
    scenarioSlug: '',
    loading: false,
    diagnostics: {
      showModal: false,
      showResultsModal: false,
      resultsCount: 0,
      items: [],
      itemsToDelete: [],
    },
    marking: {
      showModal: false,
      showResultsModal: false,
      resultsCount: 0,
      items: [],
      itemsToDelete: [],
    },
  },
  labels: {
    list: [],
    loading: false,
  },
  actionIdentifierSuggests: {
    loading: false,
    values: [],
  },
  exportScenarios: {
    processing: false,
    completedCount: 0,
    successScenarios: [],
    errorScenarios: [],
    zipLink: "",
  },
  addLabelScenariosModal: {
    initScenarios: [],
    opened: false,
  },
  editLabelModal: {
    labelId: "",
    opened: false,
  },
  deleteLabelModal: {
    labelId: "",
    opened: false,
  },
  priorities: {
    currentPriorities: [],
    loading: false,
  },
  filters: { ...defaultFilters, ...cachedFilter },
};

type T = Reducer<ScenarioGraphsState>;
export const scenarioGraphsReducer: T = createReducer<ScenarioGraphsState>(initialState)
  .handleAction(
    actions.loadScenarios.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      loading: true,
      loadingStat: true,
    }),
  )
  .handleAction(
    actions.loadScenarios.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.loadScenarios.success>,
    ): ScenarioGraphsState => {
      const sortedScenarios = scenarioGroupNames
        .map(group => payload.scenarios.filter(scenario => scenario?.groupName === group))
        .flat();
      return {
        ...state,
        list: sortedScenarios,
        total: payload.total,
        loading: false,
        priorities: {
          ...state.priorities,
          currentPriorities: sortedScenarios,
        },
      };
    },
  )
  .handleAction(
    actions.loadScenarios.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      list: [],
      total: 0,
      loading: false,
    }),
  )
  .handleAction(
    actions.updateScenariosList,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.updateScenariosList>,
    ): ScenarioGraphsState => ({
      ...state,
      list: payload,
    }),
  )
  .handleAction(
    actions.setCurrentDragGroup,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.setCurrentDragGroup>,
    ): ScenarioGraphsState => ({
      ...state,
      currentDragGroup: payload,
    }),
  )
  .handleAction(
    actions.setSearchScenariosFilter,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.setSearchScenariosFilter>,
    ): ScenarioGraphsState => ({
      ...state,
      filters: payload,
    }),
  )
  .handleAction(
    actions.updateScenariosPriority.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      priorities: {
        ...state.priorities,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.updateScenariosPriority.success,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      priorities: {
        loading: false,
        currentPriorities: state.list,
      },
    }),
  )
  .handleAction(
    actions.updateScenariosPriority.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      priorities: {
        ...state.priorities,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.deleteScenario.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.deleteScenario.success>,
    ): ScenarioGraphsState => ({
      ...state,
      list: payload,
      total: payload.length,
      loading: false,
    }),
  )
  .handleAction(
    actions.createCalling.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      uploadingCallingError: false,
      uploadingCalling: true,
    }),
  )
  .handleAction(
    actions.createCalling.success,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      uploadingCalling: false,
      callingTopicSlug: null,
    }),
  )
  .handleAction(
    actions.createCalling.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      uploadingCallingError: true,
      uploadingCalling: false,
    }),
  )
  .handleAction(
    actions.openCallingModal,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.openCallingModal>,
    ): ScenarioGraphsState => ({
      ...state,
      callingTopicSlug: payload,
    }),
  )
  .handleAction(
    actions.closeCallingModal,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      callingTopicSlug: null,
    }),
  )
  .handleAction(
    actions.updateFilesList.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      uploadedFiles: [],
    }),
  )
  .handleAction(
    actions.updateFilesList.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.updateFilesList.success>,
    ): ScenarioGraphsState => ({
      ...state,
      uploadedFiles: payload.list,
    }),
  )
  .handleAction(
    actions.activateScenario.success,
    (
      state: ScenarioGraphsState,
      { payload: scenarioId }: ActionType<typeof actions.activateScenario.success>,
    ): ScenarioGraphsState => ({
      ...state,
      list: state.list.map(scenario => {
        if (scenario.id === scenarioId) {
          return {
            ...scenario,
            status: scenario.status === "new" ? "new" : "changed",
            available: true,
          };
        }

        return scenario;
      }),
    }),
  )
  .handleAction(
    actions.deactivateScenario.success,
    (
      state: ScenarioGraphsState,
      { payload: scenarioId }: ActionType<typeof actions.deactivateScenario.success>,
    ): ScenarioGraphsState => ({
      ...state,
      list: state.list.map(scenario => {
        if (scenario.id === scenarioId) {
          return {
            ...scenario,
            status: scenario.status === "new" ? "new" : "changed",
            available: false,
          };
        }

        return scenario;
      }),
    }),
  )
  .handleAction(
    actions.changeTrainingScenario,
    (
      state: ScenarioGraphsState,
      { payload: scenarioId }: ActionType<typeof actions.changeTrainingScenario>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...initialState.training,
        scenarioSlug: scenarioId,
      },
    }),
  )
  .handleAction(
    actions.loadGamesData.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadGamesData.success,
    (
      state: ScenarioGraphsState,
      { payload: { diagnostics, marking } }: ActionType<typeof actions.loadGamesData.success>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        loading: false,
        diagnostics: {
          ...state.training.diagnostics,
          items: (diagnostics as GameDataDiagnosticsRecord[])
            .map(item => ({
              id: uuidv4(),
              text: item.text,
              iteration: item.iteration,
              chatId: item.chatId,
            })),
        },
        marking: {
          ...state.training.marking,
          items: (marking as string[])
            .map(text => ({ id: uuidv4(), text })),
        },
      },
    }),
  )
  .handleAction(
    actions.loadGamesData.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.changeGamesData,
    (
      state: ScenarioGraphsState,
      { payload: { data, type } }: ActionType<typeof actions.changeGamesData>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          ...state.training[type],
          items: data,
        },
      },
    }),
  )
  .handleAction(
    actions.addPhraseToDelete,
    (
      state: ScenarioGraphsState,
      { payload: { phrase, type } }: ActionType<typeof actions.addPhraseToDelete>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          ...state.training[type],
          itemsToDelete: [...state.training[type].itemsToDelete, phrase],
        },
      },
    }),
  )
  .handleAction(
    actions.saveGamesData.success,
    (
      state: ScenarioGraphsState,
      { payload: { type, resultsCount } }: ActionType<typeof actions.saveGamesData.success>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          ...state.training[type],
          showModal: false,
          resultsCount,
          showResultsModal: true,
        },
      },
    }),
  )
  .handleAction(
    actions.openTrainingModal,
    (
      state: ScenarioGraphsState,
      { payload: type }: ActionType<typeof actions.openTrainingModal>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          ...state.training[type],
          showModal: true,
        },
      },
    }),
  )
  .handleAction(
    actions.closeTrainingModal,
    (
      state: ScenarioGraphsState,
      { payload: type }: ActionType<typeof actions.closeTrainingModal>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          ...state.training[type],
          showModal: false,
        },
      },
    }),
  )
  .handleAction(
    actions.openTrainingResultsModal,
    (
      state: ScenarioGraphsState,
      { payload: type }: ActionType<typeof actions.openTrainingResultsModal>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          ...state.training[type],
          showResultsModal: true,
        },
      },
    }),
  )
  .handleAction(
    actions.closeTrainingResultsModal,
    (
      state: ScenarioGraphsState,
      { payload: type }: ActionType<typeof actions.closeTrainingModal>,
    ): ScenarioGraphsState => ({
      ...state,
      training: {
        ...state.training,
        [type]: {
          showModal: false,
          showResultsModal: false,
          items: [],
          itemsToDelete: [],
        },
      },
    }),
  )
  .handleAction(
    actions.toggleScenarioAutomatable.success,
    (
      state: ScenarioGraphsState,
      { payload: { id } }: ActionType<typeof actions.toggleScenarioAutomatable.success>,
    ): ScenarioGraphsState => ({
      ...state,
      list: state.list.map(s => s.id === id ? ({
        ...s,
        automatable: !s.automatable,
      }) : s),
    }),
  )
  .handleAction(
    actions.openScenarioEditor,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      scenarioEditorOpened: true,
    }),
  )
  .handleAction(
    actions.closeScenarioEditor,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      scenarioEditorOpened: false,
    }),
  )
  .handleAction(
    actions.loadScenariosLabels.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadScenariosLabels.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.loadScenariosLabels.success>,
    ): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        list: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadScenariosLabels.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createScenarioLabel.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.createScenarioLabel.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.createScenarioLabel.success>,
    ): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        list: [payload, ...state.labels.list],
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.createScenarioLabel.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.deleteScenarioLabel.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.deleteScenarioLabel.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.deleteScenarioLabel.success>,
    ): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        list: state.labels.list.filter(l => l.id !== payload),
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.deleteScenarioLabel.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.updateScenarioLabel.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.updateScenarioLabel.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.updateScenarioLabel.success>,
    ): ScenarioGraphsState => {
      const {
        label: updatedLabel,
        scenarios: updatedScenariosIds,
      } = payload;

      const nextLabelsList = state.labels.list
        .map(label => label.id === updatedLabel.id ? updatedLabel : label);

      const nextScenariosList = state.list.map(scenario => {
        const alreadyInGroup = (scenario.labels || []).some(label => label.id === updatedLabel.id);
        const needToRemoveFromGroup = alreadyInGroup && !updatedScenariosIds.includes(scenario.id);
        if (needToRemoveFromGroup) {
          return {
            ...scenario,
            labels: Array.isArray(scenario.labels) ?
              scenario.labels.filter(l => l.id !== updatedLabel.id) :
              undefined,
          };
        }

        const needToAddToGroup = !alreadyInGroup && updatedScenariosIds.includes(scenario.id);
        if (needToAddToGroup) {
          return {
            ...scenario,
            labels: [...(scenario.labels || []), updatedLabel],
          };
        }

        return scenario;
      });

      return {
        ...state,
        labels: {
          ...state.labels,
          list: nextLabelsList,
          loading: false,
        },
        list: nextScenariosList,
      };
    },
  )
  .handleAction(
    actions.updateScenarioLabel.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.addScenariosToLabel.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.addScenariosToLabel.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.addScenariosToLabel.success>,
    ): ScenarioGraphsState => {
      const { label: labelId, scenarios } = payload;
      const label = state.labels.list.find(l => l.id === labelId);

      if (!label) return state;

      const nextScenariosList = state.list.map(scenario => {
        const alreadyInGroup = (scenario.labels || []).some(l => l.id === labelId);
        const needToAddToGroup = !alreadyInGroup && scenarios.includes(scenario.id);
        if (needToAddToGroup) {
          return {
            ...scenario,
            labels: [...(scenario.labels || []), label],
          };
        }

        return scenario;
      });

      return {
        ...state,
        list: nextScenariosList,
        labels: {
          ...state.labels,
          loading: false,
        },
      };
    },
  )
  .handleAction(
    actions.addScenariosToLabel.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.addScenariosToLabel.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.openAddScenariosToLabelModal,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.openAddScenariosToLabelModal>,
    ): ScenarioGraphsState => ({
      ...state,
      addLabelScenariosModal: {
        ...state.addLabelScenariosModal,
        opened: true,
        initScenarios: payload.initScenarios,
      },
    }),
  )
  .handleAction(
    actions.closeAddScenariosToLabelModal,
    (
      state: ScenarioGraphsState,
    ): ScenarioGraphsState => ({
      ...state,
      addLabelScenariosModal: {
        ...state.addLabelScenariosModal,
        opened: false,
        initScenarios: [],
      },
    }),
  )
  .handleAction(
    actions.openEditLabelModal,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.openEditLabelModal>,
    ): ScenarioGraphsState => ({
      ...state,
      editLabelModal: {
        ...state.editLabelModal,
        opened: true,
        labelId: payload.labelId,
      },
    }),
  )
  .handleAction(
    actions.closeEditLabelModal,
    (
      state: ScenarioGraphsState,
    ): ScenarioGraphsState => ({
      ...state,
      editLabelModal: {
        ...state.editLabelModal,
        opened: false,
        labelId: "",
      },
    }),
  )
  .handleAction(
    actions.openDeleteLabelModal,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.openDeleteLabelModal>,
    ): ScenarioGraphsState => ({
      ...state,
      deleteLabelModal: {
        ...state.deleteLabelModal,
        opened: true,
        labelId: payload.labelId,
      },
    }),
  )
  .handleAction(
    actions.closeDeleteLabelModal,
    (
      state: ScenarioGraphsState,
    ): ScenarioGraphsState => ({
      ...state,
      deleteLabelModal: {
        ...state.deleteLabelModal,
        opened: false,
        labelId: "",
      },
    }),
  )
  .handleAction(
    actions.removeScenarioFromLabel.request,
    (
      state: ScenarioGraphsState,
    ): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.removeScenarioFromLabel.success,
    (
      state: ScenarioGraphsState,
      { payload: {
        scenario: scenarioId,
        label: labelId,
      } }: ActionType<typeof actions.removeScenarioFromLabel.success>,
    ): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
      list: state.list.map(scenario => {
        if (scenario.id !== scenarioId) return scenario;

        return {
          ...scenario,
          labels: (scenario.labels || []).filter(label => label.id !== labelId),
        };
      }),
    }),
  )
  .handleAction(
    actions.removeScenarioFromLabel.failure,
    (
      state: ScenarioGraphsState,
    ): ScenarioGraphsState => ({
      ...state,
      labels: {
        ...state.labels,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadActionIdentifierSuggestions.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      actionIdentifierSuggests: {
        ...state.actionIdentifierSuggests,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadActionIdentifierSuggestions.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.loadActionIdentifierSuggestions.success>,
    ): ScenarioGraphsState => ({
      ...state,
      actionIdentifierSuggests: {
        values: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.loadActionIdentifierSuggestions.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      actionIdentifierSuggests: {
        ...state.actionIdentifierSuggests,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.startExportingScenarios,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      exportScenarios: {
        ...initialState.exportScenarios,
        processing: true,
      },
    }),
  )
  .handleAction(
    actions.stopExportingScenarios,
    (state: ScenarioGraphsState): ScenarioGraphsState => {
      if (state.exportScenarios.zipLink) {
        window.URL.revokeObjectURL(state.exportScenarios.zipLink);
      }
      return {
        ...state,
        exportScenarios: {
          ...initialState.exportScenarios,
          processing: false,
        },
      };
    },
  )
  .handleAction(
    actions.updateExportingScenarioCount,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.updateExportingScenarioCount>,
    ): ScenarioGraphsState => ({
      ...state,
      exportScenarios: {
        ...state.exportScenarios,
        completedCount: payload,
      },
    }),
  )
  .handleAction(
    actions.updateExportinScenariosInfo,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.updateExportinScenariosInfo>,
    ): ScenarioGraphsState => ({
      ...state,
      exportScenarios: {
        ...state.exportScenarios,
        successScenarios: payload.successList,
        errorScenarios: payload.errorList,
        zipLink: payload.zipLink,
        processing: false,
      },
    }),
  )
  .handleAction(
    actions.loadActiveScenarioUsage.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      parentsEditor: {
        ...state.parentsEditor,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.loadActiveScenarioUsage.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.loadActiveScenarioUsage.success>,
    ): ScenarioGraphsState => ({
      ...state,
      parentsEditor: {
        ...state.parentsEditor,
        loading: false,
        nodes: payload.nodes,
        selected: payload.selected,
      },
    }),
  )
  .handleAction(
    actions.loadActiveScenarioUsage.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      parentsEditor: {
        ...state.parentsEditor,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.saveActiveScenarioUsage.request,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      parentsEditor: {
        ...state.parentsEditor,
        loading: true,
      },
    }),
  )
  .handleAction(
    actions.saveActiveScenarioUsage.success,
    (
      state: ScenarioGraphsState,
      { payload }: ActionType<typeof actions.saveActiveScenarioUsage.success>,
    ): ScenarioGraphsState => ({
      ...state,
      parentsEditor: {
        ...state.parentsEditor,
        selected: payload,
        loading: false,
      },
    }),
  )
  .handleAction(
    actions.saveActiveScenarioUsage.failure,
    (state: ScenarioGraphsState): ScenarioGraphsState => ({
      ...state,
      parentsEditor: {
        ...state.parentsEditor,
        loading: false,
      },
    }),
  );
