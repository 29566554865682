import { createAction, createAsyncAction } from "typesafe-actions";
import { AutomationRiseFilters, IterationAnalyticsDataForProject, IterationDialog } from "../../types/models/IterationAnalyticsData";
import { ErrorAction } from "../reducers";

export const loadIterationAnalyticsData = createAsyncAction(
  "riseOfAutomation/loadIterationAnalyticsData/requested",
  "riseOfAutomation/loadIterationAnalyticsData/succeeded",
  "riseOfAutomation/loadIterationAnalyticsData/failed",
)<
  undefined,
  IterationAnalyticsDataForProject,
  ErrorAction
>();

export const loadNotAutomatedDialogs = createAsyncAction(
  "riseOfAutomation/loadNotAutomatedDialogs/requested",
  "riseOfAutomation/loadNotAutomatedDialogs/succeeded",
  "riseOfAutomation/loadNotAutomatedDialogs/failed",
)<
  { offset?: number },
  { dialogs: IterationDialog[], offset?: number },
  ErrorAction
>();

export const setFilters = createAction(
  "riseOfAutomation/setFilters",
)<AutomationRiseFilters>();

export const resetFilters = createAction(
  "riseOfAutomation/resetFilters",
)();
