import { objSnakeToCamel } from "../../services/helpers/utilities";
import { ScenarioGraphLinkMetaBackend } from "../backendModels/ScenarioGraphLinkMetaBackend";
import { ScenarioGraphLinkMeta } from "../models/ScenarioGraphLinkMeta";
import { parseScenarioGraphValidationResult } from "./ScenarioValidationParser";

export function parseScenarioGraphLinkMeta(
  value: ScenarioGraphLinkMetaBackend,
): ScenarioGraphLinkMeta {
  return {
    ...objSnakeToCamel(value),
    ...(value.validation_result && {
      validationResult: parseScenarioGraphValidationResult(value.validation_result),
    }),
  };
}

export function parseScenarioGraphLinkMetaToBackend(
  value: ScenarioGraphLinkMeta,
): ScenarioGraphLinkMetaBackend {
  return {
    path: value.path,
  };
}
