import React, { useContext } from 'react';

import { ColumnConfig, DataItem } from '../types';
import Cell from './cell';
import { getColumnValue, isFolder } from '../helpers';
import { b, TableContext } from '../index';

type TableRowProps = {
  item: DataItem;
  columns: ColumnConfig[];
  className?: string;
}

function TableRow({
  item,
  columns,
  className,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: TableRowProps, ref: any) {
  const { onItemClick } = useContext(TableContext);

  return (
    <div
      ref={ref}
      className={b("row", { clickable: !!onItemClick })}
      {...(!isFolder(item) && onItemClick ?
        { tabIndex: 0, onClick: (event: React.MouseEvent) => onItemClick(item, event) } :
        {})}
    >
      {
        columns.map(config => (
          <Cell className={className} key={config.id} config={config as ColumnConfig}>
            {(config as ColumnConfig).cellRenderer ?
              //@ts-ignore
              (config as ColumnConfig).cellRenderer(getColumnValue(item, config as ColumnConfig)) :
              <span>{getColumnValue(item, config as ColumnConfig)}</span>}
          </Cell>
        ))
      }
    </div>
  );
}

export default React.memo(React.forwardRef(TableRow));
