import React, { ReactElement, useCallback, useEffect, useRef } from 'react';

import './styles.scss';

type Props = {
  children: React.ReactElement;
  onCancel: () => void;
};

const ModalOverlay = ({ children, onCancel }: Props): ReactElement => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        onCancel();
      }
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return (): void => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target === overlayRef.current) {
        onCancel();
      }
    },
    [onCancel],
  );

  return (
    <div
      ref={overlayRef}
      className="modal-overlay"
      onMouseDown={handleClick}
    >
      {children}
    </div>
  );
};

export default ModalOverlay;
