import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MomentUtils from "@date-io/moment";
import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { store } from './redux/store';
import { history } from './services/navigation';
import Routes from './services/navigation/routes';
import reportWebVitals from './reportWebVitals';
import i18n from './services/i18n';
import ErrorBoundary from './components/errorBoundary';

import './styles.scss';

ReactDOM.render(
  // <React.StrictMode>
  <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
    <DndProvider backend={HTML5Backend}>
      <ErrorBoundary>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Provider>
        <div id="modal-container" />
      </ErrorBoundary>
    </DndProvider>
  </MuiPickersUtilsProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
