import React, {
  ChangeEvent, DetailedHTMLProps, TextareaHTMLAttributes, useCallback, useEffect, useRef,
} from 'react';
import block from 'bem-cn';

import cx from '../../services/helpers/classNames';

import Button from '../button';
import { triggerChangeEvent } from './helpers';

import './styles.scss';

export type OnChangeData = { name: string, value: string };
export type TextAreaOnChangeFunc = (
  e: ChangeEvent<HTMLTextAreaElement>,
  data: OnChangeData,
) => void;

type DefaultTextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type TextAreaProps = Omit<DefaultTextAreaProps, 'name' | 'onChange'> & {
  name: string;
  resizeOnInput?: boolean;
  fluid?: boolean;
  contrast?: boolean;
  clearable?: boolean;
  onChange?: TextAreaOnChangeFunc;
}

const b = block('nd-textarea');

function TextArea({
  className,
  resizeOnInput,
  name,
  fluid,
  contrast = false,
  clearable = false,
  onInput,
  onChange,
  ...rest
}: TextAreaProps) {
  const myRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onInput) onInput(event);
    },
    [onInput],
  );

  useEffect(() => {
    if (myRef.current && resizeOnInput) {
      myRef.current.style.height = "inherit";
      myRef.current.style.height = `${myRef.current.scrollHeight}px`;
    }
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!onChange) return;

      const { name: inputName, value } = e.target;
      onChange(e, { name: inputName, value });
    },
    [onChange],
  );

  const handleReset = useCallback(
    () => {
      if (!onChange || !myRef.current) return;

      triggerChangeEvent(myRef.current, "");
    },
    [onChange],
  );

  return (
    <div
      className={cx(String(className), String(b({
        fluid,
        contrast,
        disabled: rest.disabled,
        'resize-on-input': resizeOnInput,
        'with-max-length': !!rest.maxLength,
      })))}
    >
      <textarea
        rows={1}
        ref={myRef}
        name={name}
        onInput={handleInput}
        onChange={handleChange}
        {...rest}
      />
      {typeof rest.maxLength === 'number' && (
        <span className={b('max-label')}>{String(rest.value).length} / {rest.maxLength}</span>
      )}
      {rest.value && clearable && (
        <Button
          className={b("clear-button")}
          icon="cross"
          size="mini"
          onClick={handleReset}
        />
      )}
    </div>
  );
}

export default TextArea;
