import { createReducer, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { ReleaseNote, SavedReleaseNote } from '../../types/models/ReleaseNotes';
import * as actions from './actions';

type State = {
  list: SavedReleaseNote[];
  listLoading: boolean;
  showList: boolean;
  last: SavedReleaseNote | null;
  noteToEdit: ReleaseNote | null;
}

const initialState: State = {
  list: [],
  listLoading: false,
  showList: false,
  last: null,
  noteToEdit: null,
};

export const releaseNotesReducer: Reducer<State> = createReducer<State>(initialState)
  .handleAction(
    actions.loadReleaseNotes.request,
    (state: State): State => ({
      ...state,
      listLoading: true,
    }),
  )
  .handleAction(
    actions.loadReleaseNotes.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.loadReleaseNotes.success>,
    ): State => ({
      ...state,
      list: payload,
      listLoading: false,
    }),
  )
  .handleAction(
    actions.loadReleaseNotes.failure,
    (state: State): State => ({
      ...state,
      listLoading: false,
    }),
  )
  .handleAction(
    actions.loadTheLastReleaseNote.success,
    (
      state: State,
      { payload }: ActionType<typeof actions.loadTheLastReleaseNote.success>,
    ): State => ({
      ...state,
      last: payload,
    }),
  )
  .handleAction(
    actions.openReleaseNotesModal,
    (state: State): State => ({
      ...state,
      showList: true,
    }),
  )
  .handleAction(
    actions.closeReleaseNotesModal,
    (state: State): State => ({
      ...state,
      showList: false,
    }),
  )
  .handleAction(
    actions.closeLastReleaseNote,
    (state: State): State => ({
      ...state,
      last: null,
    }),
  )
  .handleAction(
    actions.setNoteToEdit,
    (
      state: State,
      { payload }: ActionType<typeof actions.setNoteToEdit>,
    ): State => ({
      ...state,
      noteToEdit: payload,
    }),
  )
  .handleAction(
    actions.resetNoteToEdit,
    (state: State): State => ({
      ...state,
      noteToEdit: null,
    }),
  );
