import { objCamelToSnake, objSnakeToCamel } from "../../services/helpers/utilities";
import { ModelDraftBackend, ModelTopicBackend, PlotsPointBackend } from "../backendModels/ProjectModelBackend";
import { ModelDraft, ModelTopic, PlotsPoint } from "../models/ProjectModel";

export function parseModelTopic(value: ModelTopicBackend): ModelTopic {
  return {
    ...objSnakeToCamel(value),
  };
}

export function parseModelTopicToBackend(value: ModelTopic): ModelTopicBackend {
  return {
    ...objCamelToSnake(value),
  };
}

export function parsePlots(value: PlotsPointBackend): PlotsPoint {
  return {
    ...value,
  };
}

export function parseDraft(value: ModelDraftBackend): ModelDraft {
  return {
    ...objSnakeToCamel(value),
  };
}
