import { getCurrentUser } from "../../redux/user/selectors";
import { User } from "../../types/models/User";

type MetricsData = {
  username: User['name'],
  login: User['login'],
} & Record<string, unknown>;

export enum YaMetricsActions {
  onMessagesTabClickOnMetrics = 'metrics-messages-click',
  onChatTabClickOnMetrics = 'metrics-chats-click',
  onOverviewTabClickOnMetrics = 'metrics-overview-click',
  onTopicsTabClickOnMetrics = 'metrics-topics-click',
  onCallsTabClickOnMetrics = 'metrics-calls-click',
  onDownloadReportOnMetrics = 'metrics-report-download',
  onSearchOnDialogsHistory = 'dialogs-history-request',
  onExportOnDialogsHistory = 'dialogs-history-export',
  onAboveNewTresholdsClick = 'tresholds-above-click',
  onBelowNewTresholdsClick = 'tresholds-below-click',
  onZelibobaClick = 'graph-zeliboba-click',
  onTopicsStatisticsClick = 'metrics-statistics-topics-click',
  onSidebarPageClick = 'sidebar-click-', // После дефиса добавляется url страницы. Например, sidebar-click-graph-select
  referenceLinkClick = 'reference-link-click',
  trainingModalButtonClick = 'training-modal-button-click',
  trainingGameClick = 'training-game-click',
  trainingStartClick = 'training-start-click',
  trainingClusteringCreateScenarioClick = 'training-clustering-create-scenario-click',
  trainingResultDiagnostics = 'training-result-diagnostics',
  trainingResultMarking = 'training-result-marking',
  onFiltersClick = 'dialogs-history-filter-', // После дефиса идёт имя фильтра
  graphEnableStatistics = 'graph-enable-statistics'
}

const postYaMetric = (key: YaMetricsActions, data?: Record<string, unknown>) => {
  const user: User | null = getCurrentUser();
  if (!user) return;

  const { login, name: username } = user;

  type ymFunc = (id: string, action: string, key: YaMetricsActions, params: MetricsData) => void;
  type windowWithYM = typeof window & { ym?: ymFunc };

  const { ym } = window as windowWithYM;
  if (!ym) return;

  ym(process.env.REACT_APP_YANDEX_METRICS_ID || '', 'reachGoal', key, { login, username, ...(data || {}) });
};

export default postYaMetric;
