import authorizedPages from '../../../routes/authorized';
import navigationService from '../../../services/navigation';

const openGraphById = (id: string, openInNewWindow?: boolean) => {
  const params = new URLSearchParams(window.location.search);
  const version = params.get("version");

  const newParams = new URLSearchParams();

  if (version) {
    newParams.set("version", version);
  }

  newParams.set("scenario_id", id);

  if (openInNewWindow) {
    window.open(navigationService.getPageNavigationLink(
      authorizedPages.editor,
      { scenario_id: id },
    ), '_blank');
  } else navigationService.navigateTo('/editor', `?${newParams.toString()}`);
};

export default openGraphById;
