/* eslint-disable max-classes-per-file */
import { combineReducers } from "redux";

import { connectedHistory } from '../services/navigation';
import { projectsReducer as projects } from './projects/reducer';
import { userReducer as user } from './user/reducer';
import { dialogsReducer as dialogs } from './dialogs/reducer';
import { tagsReducer as tags } from './tags/reducer';
import { featuresReducer as features } from './features/reducer';
import { linesReducer as lines } from './lines/reducer';
import { tasksReducer as tasks } from './tasks/reducer';
import { filesReducer as files } from './files/reducer';
import { callsDetailsReducer as callDetails } from './callDetails/reducer';
import { samplingsReducer as samplings } from './samplings/reducer';
import { configsReducer as configs } from './configs/reducer';
import { changeHistoryReducer as changeHistory } from "./changeHistory/reducer";
import { sidebarReducer as sidebar } from "./sidebar/reducer";
import { versionsReducer as versions } from "./versions/reducer";
import { entitiesReducer as entities } from "./entities/reducer";
import { modelsReducer as models } from "./models/reducer";
import { scenarioGraphReducer as scenarioGraph } from "./graph/reducer";
import { graphChatReducer as graphChat } from "./graphChat/reducers";
import { slotsReducer as slots } from "./slots/reducer";
import { integrationsReducer as integrations } from "./integrations/reducer";
import { butterflyReducer as butterfly } from "./butterfly/reducer";
import { callsReducer as calls } from "./calls/reducer";
import { clusteringReducer as clustering } from "./clustering/reducer";
import { languagesReducer as languages } from "./languages/reducer";
import { scenarioGraphsReducer as scenarioGraphs } from "./scenarioGraphs/reducer";
import { incomingIntegrationsReducer as incomingIntegrations } from "./incomingIntegrations/reducer";
import { accessesReducer as accesses } from "./accesses/reducer";
import { superusersReducer as superusers } from "./superusers/reducer";
import { releaseNotesReducer as releaseNotes } from "./releaseNotes/reducer";
import { graphDebugReducer as graphDebug } from './graphDebug/reducer';
import { authProviderReducer as authProviders } from "./authProviders/reducer";
import { projectsAuthDataReducer as projectsAuthData } from "./projectsAuthData/reducer";
import { scenariosExamplesReducer as scenariosExamples } from './scenariosExamples/reducer';
import { providersReducer as providers } from "./providers/reducer";
import { messengerIntegrationsReducer as messengerIntegrations } from "./messengerIntegrations/reducer";
import { billingReducer as billing } from "./billing/reducer";
import { projectVoiceConfigReducer as projectVoiceConfig } from "./projectVoiceConfig/reducer";
import { domainsReducer as domains } from './domains/reducer';
import { secretsReducer as secrets } from './secrets/reducer';
import { graphGroupsReducer as graphGroups } from "./graphGroups/reducer";
import { previousAnswerReducer as previousAnswer } from "./previousAnswer/reducer";
import { riseOfAutomationReducer as riseOfAutomation } from "./riseOfAutomation/reducer";

import i18n from "../services/i18n";

const appReducer = combineReducers({
  router: connectedHistory,
  projects,
  user,
  dialogs,
  tags,
  features,
  lines,
  tasks,
  files,
  callDetails,
  samplings,
  configs,
  changeHistory,
  sidebar,
  versions,
  entities,
  models,
  scenarioGraph,
  graphChat,
  slots,
  integrations,
  butterfly,
  calls,
  clustering,
  languages,
  scenarioGraphs,
  incomingIntegrations,
  accesses,
  superusers,
  authProviders,
  releaseNotes,
  graphDebug,
  projectsAuthData,
  scenariosExamples,
  providers,
  messengerIntegrations,
  billing,
  projectVoiceConfig,
  domains,
  secrets,
  graphGroups,
  previousAnswer,
  riseOfAutomation,
});

export type AppState = ReturnType<typeof appReducer>

export type ActionType<T> = {
  payload: T,
  type: string
}
export class ErrorAction<T = unknown> {
  public error: unknown;
  public payload: T;
  public intent: string;

  public constructor(error: unknown, intent: string = i18n.t("ERRORS.API.COMMON.ERROR"), payload?: T) {
    this.error = error;
    this.intent = intent;
    this.payload = payload as T;
  }
}

export class ClientError {
  public error: unknown;
  public intent: string;

  public constructor(error: unknown, intent: string = i18n.t("ERRORS.COMMON.ERROR")) {
    this.error = error;
    this.intent = intent;
  }
}

export type Editable<T> = { pending?: boolean } & T;

export type WithLoading<T> = { loading: boolean, value?: T };

export default appReducer;
