import React, { useMemo } from 'react';

import HeaderCell from './headerCell';

import {
  HeaderConfiguration,
  Sorting,
} from '../types';

import { b } from '../index';

export type TableHeaderProps = {
  items: HeaderConfiguration;
  sortingClick?: (sorting: Sorting | undefined) => void;
}

function TableHeader({
  items,
  sortingClick,
}: TableHeaderProps) {
  const groupsPlacement = useMemo(
    () => {
      return items.reduce<Record<string, { start: number, end: number}>>((result, item, index) => {
        if (item.group) {
          if (!result[item.group]) {
            result[item.group] = {
              start: index,
              end: index,
            };
          }

          result[item.group].end = index;
        }

        return result;
      }, {});
    },
    [items],
  );

  return (
    <div className={b("header").mix()}>
      {items.map(item => {
        return (
          <React.Fragment key={item.id}>
            {item.group && item.groupStart && (
              <div
                style={{
                  gridColumn: `${groupsPlacement[item.group as string].start + 1} / ${groupsPlacement[item.group as string].end + 2}`,
                }}
                className={b("group-title").mix("group-start group-end")}
              >
                {item.group}
              </div>
            )}
            <HeaderCell
              key={item.id}
              item={item}
              short={!!item.group}
              onSortingChange={sortingClick}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default React.memo(TableHeader);
