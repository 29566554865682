import { createAction, createAsyncAction } from "typesafe-actions";
import { AllowedDomain } from "../../types/models/Domains";
import { ErrorAction } from "../reducers";

export const loadAllowedDomains = createAsyncAction(
  'domains/loadAllowedDomains/requested',
  'domains/loadAllowedDomains/succeeded',
  'domains/loadAllowedDomains/failed',
)<undefined, AllowedDomain[], ErrorAction>();

export const createAllowedDomain = createAsyncAction(
  'domains/createAllowedDomain/requested',
  'domains/createAllowedDomain/succeeded',
  'domains/createAllowedDomain/failed',
)<AllowedDomain, AllowedDomain, ErrorAction>();

export const updateAllowedDomain = createAsyncAction(
  'domains/updateAllowedDomain/requested',
  'domains/updateAllowedDomain/succeeded',
  'domains/updateAllowedDomain/failed',
)<AllowedDomain, AllowedDomain, ErrorAction>();

export const deleteAllowedDomain = createAsyncAction(
  'domains/deleteAllowedDomain/requested',
  'domains/deleteAllowedDomain/succeeded',
  'domains/deleteAllowedDomain/failed',
)<AllowedDomain['id'], AllowedDomain['id'], ErrorAction>();

export const setDomainToEdit = createAction(
  'domains/setDomainToEdit',
)<AllowedDomain['id']>();
