import React from "react";

export enum NotificationType {
  error = "error",
  info = "info",
  success = "success"
}

export enum NotificationMode {
  default = "default",
  link = "link",
  button = "button",
  buttonFooter = "button-footer",
}

export type NotificationModel = {
  id: string;
  type: NotificationType;
  text: string;
  config?: NotificationConfig;
}

export type NotificationConfig = {
  mode: NotificationMode;
  durationMs?: number | null;
  action?: {
    text: string;
    onClick: (event: React.MouseEvent) => void;
  }
}
