// У инпута есть пропс clearable. Он добавляет крестик, по нажатию на который
// нужно сбросить введенный текст. Хочется сделать это через триггер события onChange на событии,
// а не какими-то кастомными способами. Для этого нужно решить 2 задачи:
// 1. Получить элемент input'a
// 2. Затриггерить на этом элементе событие onChange c пустой строкой
// функция getInputElementFromClearButton решает первую задачу
// функция triggerChangeEvent - вторую

export function getInputElementFromClearButton(buttonEl: HTMLElement) {
  let element;
  buttonEl.parentElement?.childNodes.forEach(elem => elem.nodeName === "DIV" ? element = elem : '');
  //@ts-ignore
  return element ? element.lastChild : '';
}

export function triggerChangeEvent(
  inputElement: HTMLInputElement,
  value: string,
) {
  //@ts-ignore
  let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
  //@ts-ignore
  nativeInputValueSetter.call(inputElement, value);

  let ev2 = new Event('input', { bubbles: true });
  inputElement.dispatchEvent(ev2);
}
