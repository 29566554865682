import { UserResponseOption } from "../../pages/graphEditor/types";
import { generateId } from "../../services/helpers/generateId";
import { IntegrationId, ApiParameter } from "./Integrations";
import { ScenarioGraphLink } from "./ScenarioGraphLink";
import { Line } from "./Line";
import { Lang } from "./Languages";
import { MediaFileMetadata } from "./MediaFiles";
import { ScenarioGraph } from "./ScenarioGraph";
import { ScenarioGroupName } from "../backendModels/ScenarioGraphBackend";

export enum ScenarioGraphActionType {
  response = 'response',
  changeState = 'change_state',
  custom = 'custom',
  operator = 'operator',
  close = 'close',
  condition = 'condition',
  integrationAction = 'integration_action',
  selectScenario = 'select_scenario',
  logbrokerWriteAction = 'logbroker_write_action',
}

export enum TextType {
  text = 'text',
  macro = 'macro',
  unset = 'unset',
  audio = 'audio'
}

export type ScenarioGraphAction = ResponseAction |
  ChangeStateAction | IntegrationAction |
  CloseAction | OperatorAction |
  ConditionAction | NotificationAction | CustomAction | SelectScenarioAction |
  LogbrokerAction;

export type TranslatedTexts = Record<Lang, string[]>;

export type TranslatedUserAnswerOptions = Record<string, UserResponseOption[]>;

export type ResponseActionAttachments = MediaFileMetadata & { type: "file"; }

export type ResponseAction = {
  type: ScenarioGraphActionType.response;
  counter: number;
  texts?: TranslatedTexts;
  textsType?: TextType;
  deferSeconds?: number;
  deferFeature?: string;
  deferTimeSec?: number, //выпилить после удаления с бека
  close?: boolean;
  forwardLine?: string;
  clarifyTexts?: TranslatedTexts;
  userAnswerOptions?: Record<Lang, UserResponseOption[]>;
  clarifyFeatures?: string[];
  useElseAnswer?: boolean;
  withFallbackLink?: boolean;
  isFinal?: boolean;
  attachments?: ResponseActionAttachments[];
  gptSettings?: GptSettings;
}

export type GptSettings = {
  isEnabled?: boolean;
  prompt?: string;
  timeout?: number;
  validationPredicate?: {};
}

export type ChangeStateAction = {
  type: ScenarioGraphActionType.changeState;
  features: ScenarioFeature[];
  counter: number;
}

export type IntegrationAction = {
  type: ScenarioGraphActionType.integrationAction;
  integrationId: IntegrationId;
  version: string;
  counter: number;
  parameters?: Record<ApiParameter['slug'], string>;
}
export type IntegrationParameterId = string;
export type IntegrationParameterValue = string;

export type ScenarioFeature = {
  id: string;
  key: string;
  value: string | number | boolean;
}

export type OperatorAction = {
  type: ScenarioGraphActionType.operator;
  forwardLine?: Line['slug'];
  isFinal: boolean;
  isAutomated: boolean;
}

export type CloseAction = {
  type: ScenarioGraphActionType.close;
  isFinal: boolean;
}

export type ConditionCase = {
  id: string;
  title?: string;
  predicate?: string | Predicate;
  next?: ScenarioGraphLink['toId'];
}

export type ConditionAction = {
  type: ScenarioGraphActionType.condition;
  cases: ConditionCase[];
  counter: number;
}

export type CustomNestedAction = {
  id: string;
  actionType: string;
  parameters: string;
}

export type CustomAction = {
  type: ScenarioGraphActionType.custom;
  nestedActions: CustomNestedAction[];
  counter: number;
}

export type NotificationActionDefaultArgsItem = { key: string, value: string };
export type NotificationActionDefaultArgs = Record<string, NotificationActionDefaultArgsItem>;

export type NotificationAction = {
  type: ScenarioGraphActionType.custom;
  actionType: 'send_mail';
  sendMailParameters: {
    email: string;
    title?: string;
    message: string;
    defaultArgs?: NotificationActionDefaultArgs;
    senderName: string;
    senderEmail: string;
    attachments?: NotificationAttachments,
    pdid?: string,
    personalProvider?: string,
  };
  counter: number;
}

export type NotificationAttachments = {
  source: NotificationAttachmentSource,
  select: "all",
}

export enum NotificationAttachmentSource {
  lastUserFiles = "last_user_files",
  allUserFiles = "all_user_files",
}

export type SelectScenarioAction = {
  type: ScenarioGraphActionType.selectScenario;
  scenarios: Array<ScenarioGraph['id']>;
}

export type LogbrokerAction = {
  type: ScenarioGraphActionType.logbrokerWriteAction,
  message: string;
  topic: string;
  counter: number;
}

export type Predicate = {
  predicates: PredicateItem[];
}

export type PredicateItem = {
  id: string;
  isActive: boolean;
  type: PredicateItemType;
  codePredicate?: string;
  formPredicate?: Array<FormPredicateItem | FormPredicateItemBool>;
}

export enum PredicateItemType {
  form = 'form',
  code = 'code',
}

export type FormPredicateItem = {
  id: string;
  feature: string;
  operator: PredicateOperator;
  value: string | string[];
}

export type FormPredicateItemBool = {
  id: string;
  always: FormPredicateBoolStatus;
}

export enum FormPredicateBoolStatus {
  enabled = "enabled",
  disabled = "disabled"
}

export enum PredicateOperator {
  CONTAINS = 'contains',
  NOT_CONTAINTS = 'not_contains',
  MATCHES = 'matches',
  MORE_THAN = '>',
  MORE_OR_EQUAL_THAN = '>=',
  LESS_THAN = '<',
  LESS_OR_EQUAL_THAN = '<=',
  EQUALS = '=',
  NOT_EQUALS = '!=',
}

export const PREDICATE_LIST: PredicateOperator[] = [
  PredicateOperator.CONTAINS,
  PredicateOperator.NOT_CONTAINTS,
  PredicateOperator.MATCHES,
  PredicateOperator.MORE_THAN,
  PredicateOperator.MORE_OR_EQUAL_THAN,
  PredicateOperator.LESS_THAN,
  PredicateOperator.LESS_OR_EQUAL_THAN,
  PredicateOperator.EQUALS,
  PredicateOperator.NOT_EQUALS,
];

export function getDefaultPredicate(
  groupName?: ScenarioGroupName,
): Predicate {
  return {
    predicates: [getDefaultPredicateItem(groupName)],
  };
}

export function getDefaultPredicateItem(
  groupName?: ScenarioGroupName,
): PredicateItem {
  const itemBoolDefault = [
    ScenarioGroupName.greeting,
    ScenarioGroupName.fallback,
  ].includes(groupName as ScenarioGroupName);

  return {
    id: generateId(),
    isActive: true,
    type: PredicateItemType.form,
    formPredicate: [itemBoolDefault ?
      getDefaultFormPredicateItemBool() :
      getDefaultFormPredicateItem(),
    ],
  };
}

export function getDefaultFormPredicateItem(): FormPredicateItem {
  return {
    id: generateId(),
    feature: '',
    operator: PredicateOperator.CONTAINS,
    value: "",
  };
}

export function getDefaultFormPredicateItemBool(): FormPredicateItemBool {
  return {
    id: "always",
    always: FormPredicateBoolStatus.enabled,
  };
}

export function getEmptyIntegrationAction(): IntegrationAction {
  return {
    type: ScenarioGraphActionType.integrationAction,
    version: '1',
    integrationId: '',
    parameters: {},
    counter: 1,
  };
}

export function getEmptyResponseAction(): ResponseAction {
  return {
    type: ScenarioGraphActionType.response,
    counter: 1,
    isFinal: true,
    texts: {} as TranslatedTexts,
    textsType: TextType.unset,
    clarifyTexts: {} as TranslatedTexts,
    attachments: [],
    gptSettings: {
      isEnabled: false,
      prompt: '',
      timeout: 0,
    },
  };
}

export function getEmptyChangeStateAction(): ChangeStateAction {
  return {
    type: ScenarioGraphActionType.changeState,
    features: [],
    counter: 1,
  };
}

export function getEmptyNotificationAction(): NotificationAction {
  return {
    type: ScenarioGraphActionType.custom,
    actionType: 'send_mail',
    sendMailParameters: {
      email: '',
      title: '',
      message: '',
      defaultArgs: undefined,
      senderName: '',
      senderEmail: '',
    },
    counter: 1,
  };
}

export function getEmptyNotificationAttachments(): NotificationAttachments {
  return {
    select: "all",
    source: NotificationAttachmentSource.lastUserFiles,
  };
}

export function getEmptyCustomNestedAction(): CustomNestedAction {
  return {
    id: generateId(),
    actionType: '',
    parameters: '',
  };
}

export function getEmptyCustomAction(): CustomAction {
  return {
    type: ScenarioGraphActionType.custom,
    nestedActions: [getEmptyCustomNestedAction()],
    counter: 1,
  };
}

export function getEmptyCloseAction(): CloseAction {
  return {
    type: ScenarioGraphActionType.close,
    isFinal: false,
  };
}

export function getEmptyOperatorAction(): OperatorAction {
  return {
    type: ScenarioGraphActionType.operator,
    isFinal: false,
    isAutomated: true,
  };
}

export function getEmptyLogbrokerAction(): LogbrokerAction {
  return {
    type: ScenarioGraphActionType.logbrokerWriteAction,
    message: "",
    topic: "",
    counter: 1,
  };
}

export function getEmptyConditionActionCase(): ConditionCase {
  return {
    id: generateId(),
    title: '',
    next: '',
    predicate: getDefaultPredicate(),
  };
}

export function getEmptyConditionActionElseCase(): ConditionCase {
  return {
    id: generateId(),
    title: 'else',
    next: '',
  };
}

export function getEmptyConditionAction(): ConditionAction {
  return {
    type: ScenarioGraphActionType.condition,
    cases: [getEmptyConditionActionCase(), getEmptyConditionActionElseCase()],
    counter: 1,
  };
}

export function isPredicateItemEmpty(value: PredicateItem): boolean {
  return (value.codePredicate === "") ||
    (Array.isArray(value.formPredicate) &&
        value.formPredicate.every(item => isFormPredicateItemEmpty(item))
    );
}

export function isFormPredicateItemEmpty(item: FormPredicateItem | FormPredicateItemBool): boolean {
  if ((item as FormPredicateItemBool).always) return false;

  item = item as FormPredicateItem;
  return !item.operator && !item.feature && !item.value;
}
