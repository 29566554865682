import { SagaIterator } from "redux-saga";
import {
  call, fork, put, select, takeLatest,
} from "redux-saga/effects";
import { format } from "date-fns";

import { StatsType, Task, TaskLog, TaskType } from "../../types/models/Task";
import {
  CreateTaskRequest,
  CreateTaskResponse,
  GetTaskLogsRequest,
  GetTaskLogsResponse,
  GetTasksRequest,
  GetTasksResponse,
} from "../../types/requests/Task";
import Api, { ApiResponse } from '../../services/api';
import { parseTask } from '../../types/parsers/TaskParser';
import { uploadFileS3 } from "../files/sagas";
import { ProjectRequestParams } from "../../types/requests/Projects";
import { getGeneralRequestParams } from "../selectors";

import * as dialogActions from "../dialogs/actions";
import * as actions from './actions';
import * as projectsActions from '../projects/actions';
import i18n from "../../services/i18n";
import { CancelCallRequest, EditCallRequest } from "../../types/requests/Calls";
import { ErrorAction } from "../reducers";
import { objCamelToSnake, objCamelToSnakeDeep, objSnakeToCamelDeep } from "../../services/helpers/utilities";
import apiService from "../../services/api";
import { VersionType } from "../../types/models/Version";
import { OverviewReportTask } from "./actions";
import { Filters } from "../../pages/account/components/filters";
import { FiltersWithDirection } from "../../pages/account/components/callsTab";
import { getProjectStatisticsFilters, getSelectedProject, getUserRatesRange } from "../projects/selectors";
import { StatisticsUnit } from "../../types/models/StatisticsUnit";
import { getDialogsStatisticsColumns } from "./helpers";
import { msToSeconds } from "../projects/helpers";
import { dateLocalToMsc } from "../../services/helpers/dateUtils";
import { parseFiltersToBackend } from "../../types/parsers/FilterParser";
import { recordFields } from "../../types/models/Feature";
import modalService from "../../services/modal";
import { cacheColumns } from "../dialogs/helpers";
import { Project } from "../../types/models/Project";

export const OVERVIEW_TASKS_CHUNK_SIZE = 100;
export const VOICE_TASKS_CHUNK_SIZE = 100;
export const IMPORT_EXPORT_TASKS_CHUNK_SIZE = 50;

const allTasksTypes: TaskType[] = (() => {
  const typesMap: Record<TaskType, true> = {
    [TaskType.applyIntentDetectors]: true,
    [TaskType.autoSamplingCreation]: true,
    [TaskType.callInit]: true,
    [TaskType.callResults]: true,
    [TaskType.clustering]: true,
    [TaskType.customModelLearn]: true,
    [TaskType.demoDialogsImport]: true,
    [TaskType.demoStatsImport]: true,
    [TaskType.excelUploadHistory]: true,
    [TaskType.excelUploadMarkup]: true,
    [TaskType.export]: true,
    [TaskType.exportDialogHistoryContext]: true,
    [TaskType.exportPersonalAccountOverview]: true,
    [TaskType.exportVoximplantResourcesUsage]: true,
    [TaskType.findLabelIssues]: true,
    [TaskType.import]: true,
    [TaskType.learningModel]: true,
    [TaskType.markupExcelDump]: true,
    [TaskType.validation]: true,
    [TaskType.excelDownloadDirtyMarkup]: true,
    [TaskType.uploadMarkup]: true,
    [TaskType.downloadHistoryFromYt]: true,
  };

  return Object.keys(typesMap) as TaskType[];
})();

function* getImportExportTasks(): SagaIterator {
  yield takeLatest(actions.loadImportExportTasks.request, function* (action) {
    try {
      const { olderThan, limit = IMPORT_EXPORT_TASKS_CHUNK_SIZE } = action.payload;
      const params: GetTasksRequest = {
        ...(action && action.payload && action.payload.types && { types: action.payload.types }),
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);
      const parsedTasks = response.data.tasks.map(bTask => parseTask(bTask));
      yield put(actions.loadImportExportTasks.success({
        tasks: parsedTasks,
        pagination: { ...action.payload },
      }));
    } catch (err) {
      yield put(actions.loadImportExportTasks.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* createImportExportTask(): SagaIterator {
  yield takeLatest(actions.createImportExportTask.request, function* (action) {
    try {
      const { type, file, refTaskId } = action.payload;
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);

      let fileIdS3 = '';

      if (file) {
        fileIdS3 = yield call(uploadFileS3, file);
      }

      const params: CreateTaskRequest = {
        ...general,
        type,
        ...(fileIdS3 && { s3_file_id: fileIdS3 }),
        ...(refTaskId && { params: { ref_task_id: refTaskId } }),
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(Api.createTask, params);
      const parsedTask: Task = parseTask(response.data);
      yield put(actions.createImportExportTask.success(parsedTask));
    } catch (err) {
      yield put(actions.createImportExportTask.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE"))));
    }
  });
}

function* loadVoiceTasks(): SagaIterator {
  yield takeLatest(actions.loadVoiceTasks.request, function* (action) {
    try {
      const { olderThan, limit = VOICE_TASKS_CHUNK_SIZE } = action.payload;
      const params: GetTasksRequest = {
        calls: true,
        limit,
        ...(action && action.payload && action.payload.types && { types: action.payload.types }),
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);

      const voiceTasks = response.data.tasks.map(task => parseTask(task));
      yield put(actions.loadVoiceTasks.success({
        tasks: voiceTasks,
        pagination: {
          olderThan,
          limit,
        },
      }));
    } catch (err) {
      yield put(actions.loadVoiceTasks.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.VOICE_LOAD"))));
    }
  });
}

function* loadStatsReportsTasks(): SagaIterator {
  yield takeLatest(actions.loadStatsReportsTasks.request, function* (action) {
    try {
      const { olderThan = 0, statsType, limit, startDate, endDate } = action.payload;
      const params: GetTasksRequest = {
        types: [TaskType.exportPersonalAccountOverview],
        limit: OVERVIEW_TASKS_CHUNK_SIZE,
        stats_type: statsType,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
        ...(startDate && { start_date: +dateLocalToMsc(new Date(startDate)) }),
        ...(endDate && { end_date: +dateLocalToMsc(new Date(endDate)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);
      const parsedTasks = response.data.tasks.map(task => parseTask(task));
      yield put(actions.loadStatsReportsTasks.success({
        tasks: parsedTasks,
        pagination: { olderThan, limit },
        statsType,
      }));
    } catch (err) {
      yield put(actions.loadStatsReportsTasks.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* loadVoxExportTasks(): SagaIterator {
  yield takeLatest(actions.loadVoxExportTasks.request, function* (action) {
    try {
      const { limit = 10, olderThan = 0 } = action.payload;
      const params: GetTasksRequest = {
        types: [TaskType.exportVoximplantResourcesUsage],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);
      const parsedTasks = response.data.tasks.map(task => parseTask(task));
      yield put(actions.loadVoxExportTasks.success({
        tasks: parsedTasks,
        pagination: action.payload,
      }));
    } catch (err) {
      yield put(actions.loadVoxExportTasks.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* createOverviewTask(): SagaIterator {
  yield takeLatest(actions.createStatsReportTask.request, function* (action) {
    try {
      const {
        endDate,
        name,
        ...rest
      } = action.payload;

      const params: CreateTaskRequest = {
        type: TaskType.exportPersonalAccountOverview,
        description: name,
        params: {
          ...objCamelToSnake(rest),
          ...(endDate && { end_date: endDate }),
        },
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(Api.createTask, params);
      const parsedTask = parseTask(response.data);
      yield put(
        actions.createStatsReportTask.success({ ...parsedTask, statsType: rest.statsType }),
      );
    } catch (err) {
      yield put(actions.createStatsReportTask.failure(
        new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE")),
      ));
    }
  });
}

function* cancelCall(): SagaIterator {
  yield takeLatest(actions.cancelCall.request, function* (action) {
    const {
      taskId,
      modalId,
    } = action.payload;
    try {
      const general: ProjectRequestParams = yield select(getGeneralRequestParams);
      const params: CancelCallRequest = {
        ...general,
        task_id: taskId,
      };

      yield call(Api.cancelCall, params);
      yield put(actions.cancelCall.success({ taskId }));
      if (modalId) {
        modalService.close(modalId);
      }
    } catch (err) {
      yield put(actions.cancelCall.failure(new ErrorAction(
        err,
        i18n.t("ERRORS.API.CALLS.CALL_CANCEL"),
        { taskId },
      )));
    }
  });
}

function* runCall(): SagaIterator {
  yield takeLatest(actions.runCall.request, function* (action) {
    try {
      const {
        title,
        numAttempts,
        callBackAfter,
        file,
        dateEnd,
        dateStart,
        topicSlug,
        workStart,
        workEnd,
        weekendStart,
        weekendEnd,
        work,
        weekend,
      } = action.payload;

      if (!file) return;

      const fileIdS3: string = yield call(uploadFileS3, file);

      const params: CreateTaskRequest = {
        name: title,
        type: TaskType.callInit,
        s3_file_id: fileIdS3,
        task_description: title,
        ...({
          params: {
            num_attempts: numAttempts,
            callback_after: callBackAfter,
            topic_slug: topicSlug,
            timezone_shift: 3,
            ...(dateStart && { begin_calls_at: format(dateStart, 'yyyy-M-d HH:mm:ss') }),
            ...(dateEnd && { end_calls_at: format(dateEnd, 'yyyy-M-d HH:mm:ss') }),
            schedule: {
              ...(work && {
                working_days: {
                  ...(workStart && { from: workStart }),
                  ...(workEnd && { to: workEnd }),
                },
              }),
              ...(weekend && {
                week_end: {
                  ...(weekendStart && { from: weekendStart }),
                  ...(weekendEnd && { to: weekendEnd }),
                },
              }),
            },
          },
        }),
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(Api.createTask, params);
      const parsedTask: Task = parseTask(response.data);
      yield put(actions.runCall.success(parsedTask));
    } catch (err) {
      yield put(actions.runCall.failure(new ErrorAction(err, i18n.t("ERRORS.API.CALLS.RUN"))));
    }
  });
}

function* editCall(): SagaIterator {
  yield takeLatest(actions.editCall.request, function* (action) {
    try {
      const { id, ...params } = action.payload;

      if (!id) {
        throw new Error("No id");
      }

      const hasSchedule = params.work || params.weekend;

      const request: EditCallRequest = {
        path: {
          task_id: id,
        },
        body: {
          callback_after: params.callBackAfter,
          num_attempts: params.numAttempts,
          begin_calls_at: params.dateStart ?
            format(params.dateStart, 'yyyy-M-d HH:mm:ss') :
            undefined,
          end_calls_at: params.dateEnd ?
            format(params.dateEnd, 'yyyy-M-d HH:mm:ss') :
            undefined,
          ...(hasSchedule && {
            schedule: {
              ...(params.work && {
                working_days: {
                  from: params.workStart as string,
                  to: params.workEnd as string,
                },
              }),
              ...(params.weekend && {
                week_end: {
                  from: params.weekendStart as string,
                  to: params.weekendEnd as string,
                },
              }),
            },
          }),
        },
      };
      yield call(apiService.editCall, request);
      yield put(actions.editCall.success());
      yield put(actions.loadVoiceTasks.request({
        types: [TaskType.callInit],
        limit: VOICE_TASKS_CHUNK_SIZE,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CALLS.RUN");
      yield put(actions.runCall.failure(new ErrorAction(err, text)));
    }
  });
}

function* uploadScenarioExamples(): SagaIterator {
  yield takeLatest(actions.uploadScenarioExamples.request, function* ({ payload: file }) {
    try {
      const fileIdS3: string = yield call(uploadFileS3, file);

      const params: CreateTaskRequest = {
        type: TaskType.excelUploadMarkup,
        s3_file_id: fileIdS3,
      };

      const { data }: ApiResponse<CreateTaskResponse> = yield call(Api.createTask, params);
      const parsedTask: Task = parseTask(data);
      yield put(actions.uploadScenarioExamples.success(parsedTask));
      yield put(actions.loadScenarioExamplesUploadings.request({}));
    } catch (err) {
      yield put(actions.uploadScenarioExamples.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE"))));
    }
  });
}

function* loadScenarioExamplesUploadings(): SagaIterator {
  yield takeLatest(actions.loadScenarioExamplesUploadings.request, function* ({ payload }) {
    try {
      const { limit = 10, olderThan = 0 } = payload;
      const params: GetTasksRequest = {
        types: [TaskType.excelUploadMarkup],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };
      const { data }: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);

      const tasks = data.tasks.map(task => parseTask(task));
      yield put(actions.loadScenarioExamplesUploadings.success({
        tasks,
        pagination: payload,
      }));
    } catch (err) {
      yield put(actions.loadScenarioExamplesUploadings.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* launchCustomModelLearning(): SagaIterator {
  yield takeLatest(actions.launchCustomModelLearning.request, function* () {
    try {
      const params: CreateTaskRequest = {
        type: TaskType.customModelLearn, //
      };

      const { data }: ApiResponse<CreateTaskResponse> = yield call(Api.createTask, params);
      const parsedTask: Task = parseTask(data);
      yield put(actions.launchCustomModelLearning.success(parsedTask));
      yield put(actions.loadCustomModelLearningTasks.request({}));
    } catch (err) {
      yield put(actions.launchCustomModelLearning.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.CREATE"))));
    }
  });
}

function* loadCustomModelLearning(): SagaIterator {
  yield takeLatest(actions.loadCustomModelLearningTasks.request, function* ({ payload }) {
    try {
      const { limit = 10, olderThan = 0 } = payload;
      const params: GetTasksRequest = {
        types: [TaskType.customModelLearn],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };
      const { data }: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);

      const tasks = data.tasks.map(task => parseTask(task));
      yield put(actions.loadCustomModelLearningTasks.success({
        tasks,
        pagination: payload,
      }));
    } catch (err) {
      yield put(actions.loadCustomModelLearningTasks.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* loadClusteringLaunchTasks(): SagaIterator {
  yield takeLatest(actions.loadClusteringLaunchTasks.request, function* ({ payload }) {
    try {
      const { limit = 10, olderThan = 0 } = payload;
      const params: GetTasksRequest = {
        types: [TaskType.clustering],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };
      const { data }: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);

      const tasks = data.tasks.map(task => parseTask(task));
      yield put(actions.loadClusteringLaunchTasks.success({
        tasks,
        pagination: payload,
      }));
    } catch (err) {
      yield put(actions.loadClusteringLaunchTasks.failure(new ErrorAction(err, i18n.t("ERRORS.API.TASKS.LOAD"))));
    }
  });
}

function* loadExportExamplesTasks(): SagaIterator {
  yield takeLatest(actions.loadExportExamplesTasks.request, function* (action) {
    try {
      const { limit = 10, olderThan } = action.payload;
      const params: GetTasksRequest = {
        types: [
          TaskType.markupExcelDump,
          TaskType.excelDownloadDirtyMarkup,
          TaskType.findLabelIssues,
        ],
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };
      const { data }: ApiResponse<GetTasksResponse> = yield call(Api.getTasks, params);

      const tasks = data.tasks.map(parseTask);

      yield put(actions.loadExportExamplesTasks.success({
        tasks,
        pagination: action.payload,
      }));
    } catch (err) {
      const text = i18n.t("ERROR.API.TASKS.LOAD");
      yield put(actions.loadExportExamplesTasks.failure(new ErrorAction(err, text)));
    }
  });
}

function* exportExamples(): SagaIterator {
  yield takeLatest(actions.exportExamples.request, function* ({ payload }) {
    try {
      const { name, params: p } = payload;
      const params: CreateTaskRequest = {
        type: TaskType.markupExcelDump,
        description: name,
        params: p,
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(Api.createTask, params);

      const task = parseTask(response.data);

      yield put(actions.exportExamples.success(task));
    } catch (err) {
      const text = i18n.t("ERRORS.API.CREATE");
      yield put(actions.exportExamples.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadProcessExamplesTask(): SagaIterator {
  yield takeLatest(actions.loadProcessExamplesTask.request, function* (action) {
    try {
      const { limit = 10, olderThan } = action.payload;

      const request: GetTasksRequest = {
        types: [TaskType.excelUploadHistory],
        older_than: olderThan ?
          +dateLocalToMsc(new Date(olderThan)) :
          undefined,
        limit,
      };

      const response: ApiResponse<GetTasksResponse> = yield call(apiService.getTasks, request);

      yield put(actions.loadProcessExamplesTask.success({
        tasks: response.data.tasks.map(parseTask),
        pagination: action.payload,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.LOAD");
      yield put(actions.loadProcessExamplesTask.failure(new ErrorAction(err, text)));
    }
  });
}

function* processExamples(): SagaIterator {
  yield takeLatest(actions.processExamples.request, function* (action) {
    try {
      const {
        file,
        samplingName,
        versionType,
      } = action.payload;

      const fileIdS3: string = yield call(uploadFileS3, file);

      const request: CreateTaskRequest = {
        type: TaskType.excelUploadHistory,
        s3_file_id: fileIdS3,
        params: {
          sampling_name: samplingName,
          version: versionType === VersionType.draft ? VersionType.draft : undefined,
        },
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(apiService.createTask, request);

      yield put(actions.processExamples.success(parseTask(response.data)));
      yield put(actions.loadProcessExamplesTask.request({}));
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.CREATE");
      yield put(actions.processExamples.failure(new ErrorAction(err, text)));
    }
  });
}

function* exportDialogsStatistics(): SagaIterator {
  yield takeLatest(actions.exportDialogsStatistics.request, function *(action) {
    try {
      const { name } = action.payload;
      const {
        dateFrom,
        dateTo,
        metrics,
        target,
        ...filters
      }: Filters | FiltersWithDirection = yield select(getProjectStatisticsFilters);
      const userRatesRange: number[] | undefined = yield select(getUserRatesRange);

      const columns = getDialogsStatisticsColumns(metrics || []);

      const params: OverviewReportTask = {
        statsType: StatsType.dialogs,
        unit: StatisticsUnit.chat,
        columns,
        userScores: userRatesRange,
        startDate: msToSeconds(+dateLocalToMsc(dateFrom)),
        splitBy: target,
        ...(dateTo && { endDate: msToSeconds(+dateLocalToMsc(dateTo)) }),
        ...filters,
      };

      const request: CreateTaskRequest = {
        type: TaskType.exportPersonalAccountOverview,
        params: objCamelToSnakeDeep(params),
        description: name,
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(apiService.createTask, request);

      yield put(actions.exportDialogsStatistics.success(parseTask(response.data)));
      yield put(projectsActions.openExportHistory());
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.CREATE");
      yield put(actions.exportDialogsStatistics.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadAllTasks(): SagaIterator {
  yield takeLatest(actions.loadAllTasks.request, function* (action) {
    try {
      const { olderThan, limit = 10 } = action.payload;
      const request: GetTasksRequest = {
        types: allTasksTypes,
        limit,
        ...(olderThan && { older_than: +dateLocalToMsc(new Date(olderThan)) }),
      };

      const response: ApiResponse<GetTasksResponse> = yield call(apiService.getTasks, request);

      yield put(actions.loadAllTasks.success({
        tasks: response.data.tasks.map(parseTask),
        pagination: action.payload,
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.LOAD");
      yield put(actions.loadAllTasks.failure(new ErrorAction(err, text)));
    }
  });
}

function* loadTaskLogs(): SagaIterator {
  yield takeLatest(actions.loadTaskLogs.request, function* (action) {
    const { taskId } = action.payload;
    try {
      const request: GetTaskLogsRequest = {
        path: {
          task_id: taskId,
        },
      };

      const response: ApiResponse<GetTaskLogsResponse> = yield call(apiService.getTaskLogs, request);
      yield put(actions.loadTaskLogs.success({
        taskId,
        logs: response.data.logs.map<TaskLog>(objSnakeToCamelDeep),
      }));
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.LOAD_LOGS");
      yield put(actions.loadTaskLogs.failure(new ErrorAction(
        err,
        text,
        { taskId },
      )));
    }
  });
}

function* exportRepetitiveExamples(): SagaIterator {
  yield takeLatest(actions.exportRepetitiveExamples.request, function* (action) {
    try {
      const { name } = action.payload;

      const request: CreateTaskRequest = {
        type: TaskType.excelDownloadDirtyMarkup,
        description: name,
        name: "supportai_markup_processing",
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(
        apiService.createTask,
        request,
      );

      yield put(actions.exportRepetitiveExamples.success(parseTask(response.data)));
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.CREATE");
      yield put(actions.exportRepetitiveExamples.failure(new ErrorAction(err, text)));
    }
  });
}

function* searchForDirtyExamples(): SagaIterator {
  yield takeLatest(actions.searchForDirtyExamples.request, function* () {
    try {
      const request: CreateTaskRequest = {
        type: TaskType.findLabelIssues,
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(apiService.createTask, request);
      yield put(actions.searchForDirtyExamples.success(parseTask(response.data)));
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.CREATE");
      yield put(actions.searchForDirtyExamples.failure(new ErrorAction(err, text)));
    }
  });
}

function* exportDialogs(): SagaIterator {
  yield takeLatest(actions.exportDialogs.request, function* (action) {
    try {
      const project: Project = yield select(getSelectedProject);

      const { filters, title, columns } = action.payload;
      const { ...rest } = filters;

      const request: CreateTaskRequest = {
        type: TaskType.downloadHistoryFromYt,
        name: "supportai_history_processing",
        params: {
          columns: [...recordFields, ...(columns || [])],
          limit: 25000,
          fill_unchanged_columns: false,
          filters: {
            ...parseFiltersToBackend(rest),
          },
        },
        ...(title && { description: title }),
      };

      const response: ApiResponse<CreateTaskResponse> = yield call(apiService.createTask, request);
      yield put(dialogActions.closeExportDialogsModal());
      yield put(dialogActions.openHistoryExportDialogsModal());
      yield put(actions.exportDialogs.success(parseTask(response.data)));
      cacheColumns(project.slug, columns);
    } catch (err) {
      const text = i18n.t("ERRORS.API.TASKS.CREATE");
      yield put(actions.exportDialogs.failure(new ErrorAction(err, text)));
    }
  });
}

export default function* projectsSagas(): SagaIterator {
  yield fork(createImportExportTask);
  yield fork(getImportExportTasks);
  yield fork(loadVoiceTasks);
  yield fork(loadStatsReportsTasks);
  yield fork(loadVoxExportTasks);
  yield fork(createOverviewTask);
  yield fork(runCall);
  yield fork(editCall);
  yield fork(cancelCall);
  yield fork(loadScenarioExamplesUploadings);
  yield fork(uploadScenarioExamples);
  yield fork(loadCustomModelLearning);
  yield fork(launchCustomModelLearning);
  yield fork(loadClusteringLaunchTasks);
  yield fork(loadExportExamplesTasks);
  yield fork(exportExamples);
  yield fork(loadProcessExamplesTask);
  yield fork(processExamples);
  yield fork(exportDialogsStatistics);
  yield fork(loadAllTasks);
  yield fork(loadTaskLogs);
  yield fork(exportRepetitiveExamples);
  yield fork(searchForDirtyExamples);
  yield fork(exportDialogs);
}
