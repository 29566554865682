import {
  call,
  fork,
  put,
  takeLatest,
} from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/types";

import apiService, { ApiResponse } from "../../services/api";
import { parseChangesHistory } from "../../types/parsers/EventParser";
import { GetChangeHistoryRequest, GetChangeHistoryResponse } from "../../types/requests/EventsHistory";
import i18n from '../../services/i18n';
import { ErrorAction } from "../reducers";

import * as actions from './actions';
import { dateLocalToMsc } from "../../services/helpers/dateUtils";

export const CHUNK_SIZE = 20;

function* loadChangeHistory(): SagaIterator {
  yield takeLatest(actions.loadChangeHistory.request, function* (action) {
    try {
      const { filters, offset = 0 } = action.payload;

      const params: GetChangeHistoryRequest = {
        limit: CHUNK_SIZE,
        offset,
        ...(filters?.userLogin && { user_login: filters.userLogin }),
        ...(filters?.objectDescription && { object_description: filters.objectDescription }),
        ...(filters?.dateFrom && { from_date: +dateLocalToMsc(filters.dateFrom) }),
        ...(filters?.dateTo && { to_date: +dateLocalToMsc(filters.dateTo) }),
      };

      const response: ApiResponse<GetChangeHistoryResponse> = yield call(
        apiService.getChaneHistory,
        params,
      );

      const eventsHistory = parseChangesHistory(response.data);

      yield put(actions.loadChangeHistory.success({
        events: eventsHistory,
        offset,
      }));
    } catch (err) {
      yield put(actions.loadChangeHistory.failure(new ErrorAction(err, i18n.t("ERRORS.API.CHANGE_HISTORY.LOAD"))));
    }
  });
}

export default function* changeHistorySagas() {
  yield fork(loadChangeHistory);
}
