export type ScenarioGraphExample = {
  id: string;
  type: ScenarioGraphExampleType;
  text: string;
  textHash: string;
  isUsedInOnlineModel: boolean;
  supportaiRequestId: string | null;
  userId: string;
  isCorrect?: boolean;
}

// eslint-disable-next-line no-shadow
export enum ScenarioGraphExampleType {
  exception = "exception",
  addition = "addition",
}

export type TrashPhrase = {
  id: string;
  phrase: string;
}

export type MarkupUnit = {
  markupId: string;
  connectionId: string;
  text: string;
  scenarioSlug: string;
  type: string;
  userId?: string;
  isCorrect?: boolean;
  isUsedInRefPhrases?: boolean;
}
