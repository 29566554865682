import i18n from "../../services/i18n";

import { ScenarioGraphLabel } from "../../types/models/ScenarioGraphLabel";
import { ScenarioGraphParent, ScenarioGraphParentGroup } from "../../types/models/ScenarioGraphParent";
import { ScenarioGraphMeta } from "../../types/models/ScenarioGraphs";
import { AppState } from "../reducers";
import { ScenarioGraphsState } from "./reducer";
import { ScenarioGraphsItem, TrainingItem } from "./types";

export const getScenarioGraphs = (
  state: AppState,
): ScenarioGraphsState['list'] => (
  state.scenarioGraphs.list
);

export const getScenarioFilters = (state: AppState) => (
  state.scenarioGraphs.filters
);

export const getCurrentDragGroup = (state: AppState) => (
  state.scenarioGraphs.currentDragGroup
);

export const getScenarioById = (state: AppState, id: ScenarioGraphsItem['id']) => (
  state.scenarioGraphs.list.find(s => s.id === id)
);

export const getScenarioBySlug = (state: AppState, slug: ScenarioGraphsItem['slug']) => (
  state.scenarioGraphs.list.find(s => s.slug === slug)
);

export const getScenariosByIds = (
  state: AppState,
  ids: Array<ScenarioGraphsItem['id']>,
) => {
  return state.scenarioGraphs.list.filter(scenario => ids.includes(scenario.id));
};

export const getScenarioGraphsSlugsToIds = (state: AppState): Record<string, string> => {
  const scenarioGraphs = getScenarioGraphs(state);

  return scenarioGraphs.reduce(
    (result, item) => ({
      ...result,
      [item.slug]: item.id,
    }),
    {},
  );
};

export const getScenarioGraphsLoading = ({ scenarioGraphs }: AppState): boolean => (
  scenarioGraphs.loading
);

export const getPrioritiesLoading = ({ scenarioGraphs }: AppState): boolean => (
  scenarioGraphs.priorities.loading
);

export const getCurrentPrioritiesIds = ({ scenarioGraphs }: AppState): string[] => (
  scenarioGraphs.priorities.currentPriorities.map(scenario => scenario.id)
);

export const getScenarioGraphsCount = (state: AppState): ScenarioGraphsState['total'] => (
  state.scenarioGraphs.total
);

export const getStatsLoading = (state: AppState): ScenarioGraphsState['loadingStat'] => (
  state.scenarioGraphs.loadingStat
);

export const getUploadingCalling = (state: AppState): ScenarioGraphsState['uploadingCalling'] => (
  state.scenarioGraphs.uploadingCalling
);

export const getUploadingCallingError = (state: AppState): ScenarioGraphsState['uploadingCallingError'] => (
  state.scenarioGraphs.uploadingCallingError
);

export const getCallingTopicSlug = (state: AppState): ScenarioGraphsState['callingTopicSlug'] => (
  state.scenarioGraphs.callingTopicSlug
);

export const getUploadedFiles = (state: AppState): ScenarioGraphsState['uploadedFiles'] => (
  state.scenarioGraphs.uploadedFiles
);

export const graphSearchIsAvailable = (state: AppState): boolean => (
  !state.scenarioGraphs.scenarioEditorOpened &&
  !state.graphChat.show
);

type Training = ScenarioGraphsState['training'];
export const getTrainingData = ({ scenarioGraphs }: AppState): Training => (
  scenarioGraphs.training
);

export const getTrainingScenarioSlug = ({ scenarioGraphs }: AppState): Training['scenarioSlug'] => (
  scenarioGraphs.training.scenarioSlug
);

export const getTrainingDiagnostics = ({ scenarioGraphs }: AppState): Training['diagnostics'] => (
  scenarioGraphs.training.diagnostics
);

export const getTrainingDiagnosticsItems = ({ scenarioGraphs }: AppState): TrainingItem[] => (
  scenarioGraphs.training.diagnostics.items
);

export const getTrainingMarking = ({ scenarioGraphs }: AppState): Training['marking'] => (
  scenarioGraphs.training.marking
);

export const getTrainingMarkingItems = ({ scenarioGraphs }: AppState): TrainingItem[] => (
  scenarioGraphs.training.marking.items
);

export const getTrainingScenarioName = (state: AppState): ScenarioGraphMeta['title'] => {
  const slug = getTrainingScenarioSlug(state);
  const scenario = getScenarioGraphs(state).find(item => item.slug === slug);

  return scenario?.title || '';
};

export const getTrainingLoading = ({ scenarioGraphs }: AppState): boolean => (
  !!scenarioGraphs.training.loading
);

export const checkIfDiagnosticsIsAvailable = ({ scenarioGraphs }: AppState): boolean => (
  !!scenarioGraphs.training.diagnostics.items.length
);

export const checkIfMarkingIsAvailable = ({ scenarioGraphs }: AppState): boolean => (
  !!scenarioGraphs.training.marking.items.length
);

export const getScenarioEditorOpened = ({ scenarioGraphs }: AppState): boolean => (
  scenarioGraphs.scenarioEditorOpened
);

export const getScenarioLabels = ({ scenarioGraphs }: AppState) => (
  scenarioGraphs.labels
);

export const getScenarioLabelById = (
  { scenarioGraphs }: AppState,
  id: ScenarioGraphLabel['id'],
) => (
  scenarioGraphs.labels.list.find(l => l.id === id)
);

export const getLabelScenarios = (
  { scenarioGraphs }: AppState,
  id: ScenarioGraphLabel['id'],
) => (
  scenarioGraphs.list
    .filter(scenario => scenario.labels?.some(l => l.id === id))
);

export const getActionIdentifierSuggests = (state: AppState) => (
  state.scenarioGraphs.actionIdentifierSuggests
);

export const getScenarioExportStatus = (state: AppState) => (
  state.scenarioGraphs.exportScenarios.processing
);

export const getScenarioExportCount = (state: AppState) => (
  state.scenarioGraphs.exportScenarios.completedCount
);

export const getScenarioExportResulst = (state: AppState) => {
  const { exportScenarios } = state.scenarioGraphs;

  return {
    successList: exportScenarios.successScenarios,
    errorList: exportScenarios.errorScenarios,
    zipLink: exportScenarios.zipLink,
  };
};

export const getAddScenariosModalOpenedStatus = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.addLabelScenariosModal.opened;
};
export const getDefaultScenariosToAdd = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.addLabelScenariosModal.initScenarios;
};

export const getEditLabelModalOpenedStatus = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.editLabelModal.opened;
};
export const getEditLabelId = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.editLabelModal.labelId;
};

export const getDeleteLabelModalOpenedStatus = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.deleteLabelModal.opened;
};
export const getDeleteLabelId = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.deleteLabelModal.labelId;
};

export const getParentNodesLoading = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.parentsEditor.loading;
};

export const getSelectedParentNodes = ({
  scenarioGraphs,
}: AppState) => {
  return scenarioGraphs.parentsEditor.selected;
};

export const getMappedParentScenarios = ({
  scenarioGraphs,
  scenarioGraph,
}: AppState): ScenarioGraphParentGroup[] => {
  const activeScenarioId = scenarioGraph.activeGraph.id;
  const parentNodes = scenarioGraphs.parentsEditor.nodes;
  const scenarioListIds = parentNodes.map(n => n.scenarioId);
  const scenarioList = scenarioGraphs.list.filter(s => scenarioListIds.includes(s.id))
    .filter(s => s.id !== activeScenarioId);

  const groups = scenarioList
    .reduce<ScenarioGraphLabel[]>((res, scenario) => {
      const scenarioGroups = scenario.labels;

      scenarioGroups?.forEach(group => {
        if (!res.some(g => g.id === group.id)) {
          res.push(group);
        }
      });

      return res;
    }, []);
  groups.unshift({
    id: "none",
    slug: "none",
    title: i18n.t("GRAPH.TYPE_SELECT.WITHOUT_GROUP"),
  });
  return groups.map(group => {
    return {
      id: group.id,
      title: group.title,
      scenarios: scenarioList
        .filter(scenario => {
          if (group.id === "none") {
            return !scenario.labels || scenario.labels.length === 0;
          }

          return scenario.labels?.some(label => label.id === group.id);
        })
        .map(scenario => ({
          id: scenario.id,
          title: scenario.title,
          active: scenario.available,
          nodes: parentNodes
            .filter(node => node.scenarioId === scenario.id)
            .map(node => node.node),
        })) as ScenarioGraphParent[],
    };
  });
};
