import React, { FC } from 'react';
import { SvgProps } from '../types';

const MinusIcon: FC<SvgProps> = ({
  width = '24',
  height = '24',
  color = 'currentColor',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 12.5C6 12.7761 6.22386 13 6.5 13H17.5C17.7761 13 18 12.7761 18 12.5V11.5C18 11.2239 17.7761 11 17.5 11H6.5C6.22386 11 6 11.2239 6 11.5V12.5Z" fill={color} />
  </svg>
);

export default MinusIcon;
