export function generateId(): string {
  return Math.random().toString(36).substr(2, 9) + (+new Date());
}

export function wrapWithId<T>(values: Array<T> = []): Array<{ value: T, id: string }> {
  return values.map((value: T) => ({
    value,
    id: generateId(),
  }));
}

export type WithId<T> = {
  value: T,
  id: string,
}
