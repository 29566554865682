import { OutgoingCallRecordBackend } from "../backendModels/OutgoingCallRecordBackend";
import { OutgoingCallRecord } from "../models/OutgoingCallRecord";

export function parseOutgoingCallRecord(value: OutgoingCallRecordBackend): OutgoingCallRecord {
  return {
    questionCode: value.question_code,
    question: value.question,
    answer: value.answer,
    ...(value.error && { error: value.error }),
  };
}

export function parseOutgoingCallRecordToBackend(value: OutgoingCallRecord): OutgoingCallRecordBackend {
  return {
    question_code: value.questionCode,
    question: value.question,
    answer: value.answer,
    ...(value.error && { error: value.error }),
  };
}
