import { Secret } from "../../types/models/Secret";
import { AppState } from "../reducers";

export function getSecrets(state: AppState): Secret[] {
  return state.secrets.list;
}

export function getSecretsLoadingStatus(state: AppState): boolean {
  return state.secrets.loading;
}

export function getSecretsProcessingStatus(state: AppState): boolean {
  return state.secrets.processing;
}

export function getNewSecretModalOpened(state: AppState): boolean {
  return state.secrets.newSecretModal.opened;
}

export function getEditSecretModalOpened(state: AppState): boolean {
  return state.secrets.editSecretModal.opened;
}

export function getActiveSecretToEdit(state: AppState): Secret | null {
  return state.secrets.editSecretModal.activeSecret;
}

export function getSecretBySlug(
  state: AppState,
  slug: Secret['slug'],
) {
  return state.secrets.list.find(s => s.slug === slug);
}
