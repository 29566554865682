import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';

import { block } from 'bem-cn';

import { preventDefaultEvent } from '../../services/helpers/utilities';

import cx from '../../services/helpers/classNames';
import Icon, { IconSize, IconTypes } from '../icon';
import Button from '../button';

import {
  getInputElementFromClearButton,
  triggerChangeEvent,
} from "./helpers";

import './styles.scss';

export type InputVisualStyle = "default" | "contrast" | "underline";

export type OnChangeFunc = (
  e: ChangeEvent<HTMLInputElement>,
  data: { name: string, value: string },
) => void;

// @ts-ignore
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  clearable?: boolean;
  fluid?: boolean;
  value: string | number;
  name: string;
  placeholder: string;
  visualStyle?: InputVisualStyle;
  icon?: IconTypes;
  iconPosition?: "left" | "right",
  size?: 'tiny' | 'small' | 'medium' | 'large';
  shiftSpacing?: boolean;
  numbersOnly?: boolean;
  onChange: OnChangeFunc;
  error?: boolean;
  floatingPlaceholder?: boolean;
}

const b = block('nd-input');

const Input = forwardRef<HTMLInputElement, InputProps>(({
  className = '',
  name,
  value,
  placeholder,
  size = 'small',
  fluid = false,
  clearable = false,
  shiftSpacing = false,
  numbersOnly = false,
  error = false,
  onChange,
  floatingPlaceholder = false,
  visualStyle = 'default',
  icon,
  iconPosition,
  ...rest
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}, ref: any) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name: inputName, value: inputValue } = e?.target || {};

      onChange(e, {
        name: inputName,
        value: numbersOnly ? inputValue.replace(/[^0-9.]/gi, '') : inputValue,
      });
    },
    [numbersOnly, onChange],
  );

  const handleCheckMin = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (!numbersOnly) {
        if (rest.onBlur) {
          rest.onBlur(e);
        }

        return;
      }

      if (rest.onBlur) {
        rest.onBlur(e);
      }

      const { name: inputName, value: inputValue } = e?.target || {};
      const numbers = +inputValue.replace(/[^0-9]/gi, '');

      if (numbers < Number(rest.min)) {
        onChange(e, {
          name: inputName,
          value: String(rest.min),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rest.min,
      numbersOnly,
      rest.onBlur,
      onChange,
    ],
  );

  const handleClear = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const input = getInputElementFromClearButton(event.currentTarget as HTMLElement);
      triggerChangeEvent(input as HTMLInputElement, "");
    },
    [],
  );

  const iconSize = useMemo(
    () => {
      switch (size) {
        case "tiny":
          return IconSize.smallPlus;
        case "small":
        case "medium":
        case "large":
          return IconSize.medium;
        default:
          return IconSize.medium;
      }
    },
    [size],
  );

  return (
    <label
      className={
      cx(
        String(className),
        String(b({
          size,
          fluid,
          clearable,
          'shift-spacing': shiftSpacing,
          disabled: rest.disabled,
          error,
          floating: floatingPlaceholder,
          'visual-style': visualStyle,
          'with-value': value !== null && value !== undefined && value !== "",
        })),
      )}
    >
      {icon && (
        <Icon
          iconSize={iconSize}
          className={b('icon', { position: iconPosition })}
          type={icon}
        />
      )}
      <div className={b("input-wrapper")}>
        {floatingPlaceholder && <span className={b("placeholder")}>{placeholder}</span>}
        <input
          ref={ref}
          autoComplete="off"
          name={name}
          value={value}
          placeholder={placeholder}
          onBlur={handleCheckMin}
          onChange={handleChange}
          {...rest}
        />
      </div>
      {!!clearable && !!value && (
        <Button
          icon="cross"
          size="mini"
          iconSize={iconSize}
          simple
          className={b("clear-button")}
          onClick={handleClear}
          onMouseDown={preventDefaultEvent}
        />
      )}
      {!!error && (
        <Icon
          iconSize={iconSize}
          className={b("error-icon")}
          svgProps={{ color: "var(--error)" }}
          type="exclamation"
        />
      )}
    </label>
  );
});

export default Input;
